import React, { useState } from 'react';
import { Layout, Tabs } from '@clarke-energia/foton';
import { HOME_PATH } from '@routers/constants';
import { SalesContractTableContainer } from '@components/organisms/sales-contracts/sales-contract-table-container';
import { IManagementContractUser } from '@contexts/management-contract/types';

export type IContractStatus = 'WAITING_FOR_SIGNATURE' | 'SIGNED';
export type IContractProduct = 'PRODUCT_ONE' | 'PRODUCT_TWO';

interface ILead {
  id: string;
  name: string;
}

interface IMetadata {
  externalUrl: string;
  externalId: string;
  downloadUrl: string;
}

export interface IContractBase {
  id?: string;
  metadata: IMetadata;
  lead: ILead;
  createdAt: string;
  user: IManagementContractUser;
  type: 'STANDARD_MANAGEMENT' | 'THIRTY_DAY_TRIAL';
  product: IContractProduct;
}

export interface IContracts {
  data: Array<IContractBase>;
  page: number;
  limit: number;
  total: number;
}

interface ISalesContracts {
  product: IContractProduct;
}

const SalesContracts = ({ product }: ISalesContracts): JSX.Element => {
  const [activeTab, setActiveTab] = useState<'UNSIGNED' | 'SIGNED'>('UNSIGNED');

  return (
    <Layout
      title="Contratos de vendas"
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos de Vendas',
          url: '#',
        },
        {
          label: product === 'PRODUCT_ONE' ? 'Clarke gestão' : 'Clarke varejo',
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="col-span-full w-full h-full">
        <div className="flex w-full">
          <Tabs
            className="w-full"
            onClick={(e) => {
              const targetElement = e.target as HTMLElement;
              if (targetElement.textContent === 'Não assinado') {
                setActiveTab('UNSIGNED');
              } else {
                setActiveTab('SIGNED');
              }
            }}
            tabs={[
              {
                label: 'Não assinado',
                children: <SalesContractTableContainer product={product} status="WAITING_FOR_SIGNATURE" />,
              },
              {
                label: 'Assinado',
                children: (
                  <>
                    <SalesContractTableContainer product={product} status="SIGNED" />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
};

export default SalesContracts;

import { gql } from '@apollo/client';

export const QUERY_GET_GROUP_FILES = gql`
  query Files($commercialGroupId: ID) {
    files(commercialGroupId: $commercialGroupId) {
      url
      unitId
      id
      filePath
      documentType
    }
  }
`;

export const GET_GROUP_QUERY = gql`
  query Groups($groupId: ID!) {
    group(id: $groupId) {
      id
      name
    }
  }
`;

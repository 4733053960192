export const LOGIN_PATH = '/login';

// Private paths
export const HOME_PATH = '/clientes';
export const CREATE_PROPOSAL_PATH = '/criar-proposta';
export const COMMERCIAL_GROUP_PATH = '/cliente/:groupId';
export const UNITS_PATH = 'unidades';
export const COMMERCIAL_GROUPS_USERS_PATH = 'usuarios';
export const KICK_OFF_PATH = 'kick-off';
export const TRADERS_PATH = '/fornecedoras';
export const DETAILED_TRADER_PATH = '/fornecedoras/:traderId';
export const USERS_PATH = '/usuarios';
export const USERS_EMAIL_LOG_PATH = ':userId/emails';
export const CONTRACTS_PATH = '/contratos';
export const MANAGEMENT_CONTRACTS_PATH = '/contratos-de-vendas';
export const CREATE_ENERGRY_CONTRACT_PATH = '/novo-contrato-energia';
export const EDIT_ENERGRY_CONTRACT_PATH = 'editar-contrato-energia/:contractId';
export const CREATE_CCEE_CONTRACT_PATH = '/novo-contrato-cce';
export const EDIT_CCEE_CONTRACT_PATH = 'editar-contrato-ccee/:id';
export const CREATE_CLARKE_CONTRACT_PATH = '/novo-contrato-clarke';
export const EDIT_CLARKE_CONTRACT_PATH = 'editar-contrato-clarke/:id';
export const CREATE_PROINFA_CONTRACT_PATH = '/novo-contrato-proinfa';
export const CCEE_PROFILES_PATH = '/perfis';
export const ECONOMY_REPORT_PATH = 'relatorios';
export const DOCUMENTS_PATH = 'documentos';
export const UNIT_REPORT_PATH = '/relatorio-unidade/:reportId';
export const GROUP_REPORT_PATH = '/relatorio-grupo/:id';
export const FINANCIAL_EVENTS_PATH = '/eventos-financeiros';
export const NEW_FINANCIAL_EVENT_PATH = '/eventos-financeiros/novo';
export const ENERGY_AUDIT_PATH = '/balanco-energetico';
export const DETAILED_ENERGY_AUDIT_PATH = '/balanco-energetico/:energyAuditId';
export const MEASUREMENT_BY_UNIT_PATH = '/balanco-energetico/:energyAuditId/medicoes/:unitId';
export const UPSERT_UNIT_PATH = 'unidades/:upsertUnit/:unitId';
export const MIGRATION_REPORT_PATH = 'relatorios-migracao';

// PRODUCTS TYPES PATHS
export const PRODUCT_ONE_PATH = '/clarke-gestao';
export const PRODUCT_TWO_PATH = '/clarke-varejo';
export const UPDATE_MANAGEMENT_CONTRACT_PATH = '/atualizar';

// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';

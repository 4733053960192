import { ColumnOptions } from '@clarke-energia/foton';
import { UnitsReportsTableData } from '@contexts/economy-reports/types';
import React from 'react';
import HighLightCards, { highlightsCard } from '../highLight-container';
import GroupReportTable from './table';

interface SecondSectionProps {
  highlightsInfo: highlightsCard;
  tableData: {
    columns: ColumnOptions<UnitsReportsTableData>[];
    data: UnitsReportsTableData[];
  };
  isFetchingData: boolean;
}

const SecondSection = ({
  tableData: { columns, data },
  isFetchingData,
  highlightsInfo,
}: SecondSectionProps): JSX.Element => {
  return (
    <div className="col-span-1 xl:col-span-2">
      <HighLightCards {...highlightsInfo} isFetchingData={isFetchingData} />
      <GroupReportTable columns={columns} data={data} isFetchingReports={isFetchingData} />
    </div>
  );
};

export default SecondSection;

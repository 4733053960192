import { gql } from '@apollo/client';

export const CREATE_USER_TRADER = gql`
  mutation CreateUserTrader($input: UpsertUserTraderInput) {
    createUserTrader(input: $input) {
      createdAt
    }
  }
`;

export const DELETE_USER_TRADER = gql`
  mutation ($input: UpsertUserTraderInput) {
    deleteUserTrader(input: $input)
  }
`;

export const CATEGORIZE_TRADERS_USER_ROLES = gql`
  mutation ($input: categorizeTraderUserRolesInput) {
    categorizeTraderUserRoles(input: $input)
  }
`;

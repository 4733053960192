import React from 'react';
import { Skeleton } from '@clarke-energia/foton';

const KickOffPageSkeleton = () => (
  <div className="flex flex-col col-span-full gap-6 pb-12 h-full">
    <Skeleton className="mb-5 w-full h-[15rem]" />
    <div className="flex flex-col gap-5 w-full h-full md:flex-row">
      <Skeleton className="w-1/2 h-full" />
      <Skeleton className="w-1/2 h-full" />
    </div>
  </div>
);

export default KickOffPageSkeleton;

import moment from 'moment';

import { ProcessProinfaFormData } from '@contexts/proinfa-contract/types';
import { UploadTraderLogoData } from '@contexts/trader/types';
import { IFinancialEventsBatchPayload } from '@contexts/financial-events';
import { IEnergyContractAttachmentsPayload } from '@contexts/energy-contract/types';
import { ClarkeContractAttachmentsPayload } from '@contexts/clarke-contract/types';

const financialEventsBatchDataParser = (data: IFinancialEventsBatchPayload) => {
  const formData = new FormData();
  data.spreadsheetToUpload && formData.append('financial_events_spreadsheet', data.spreadsheetToUpload as Blob);
  data.attachmentsToUpload &&
    data.attachmentsToUpload.forEach((file: File) => {
      formData.append('attachments', file, file.name);
    });

  formData.append('type', data.type ?? '');
  formData.append('reference_period', moment(data.referencePeriod).format() ?? '');
  formData.append('payment_deadline', moment(data.paymentDeadline ?? '').format());

  return formData;
};

export const energyContractAttachmentsDataParser = (data: IEnergyContractAttachmentsPayload) => {
  const formData = new FormData();
  data.energyContractAttachments &&
    data.energyContractAttachments.forEach((file: File) => {
      formData.append('energy_contract_attachments', file, file.name);
    });

  formData.append('energy_contract_id', data.energyContractId ?? '');

  return formData;
};

export const uploadTraderLogoParser = (data: UploadTraderLogoData) => {
  const formData = new FormData();
  formData.append('entity_id', data.entityId);
  formData.append('entity', data.entity);
  formData.append('label', data.label);
  formData.append('file', data.file as Blob);
  formData.append('doc_type', data.docType);

  return formData;
};

export const proinfaDataParser = ({ referenceYear, file }: ProcessProinfaFormData) => {
  const formData = new FormData();
  formData.append('proinfa_spreadsheet', file as Blob);
  formData.append('reference_year', referenceYear);

  return formData;
};

export const clarkeContractAttachmentsDataParser = (data: ClarkeContractAttachmentsPayload) => {
  const formData = new FormData();
  data.clarkeContractAttachments.forEach((file: File) => {
    formData.append('clarke_contract_attachments', file, file.name);
  });

  formData.append('clarke_contract_id', data.clarkeContractId);

  return formData;
};

export default financialEventsBatchDataParser;

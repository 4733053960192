import React from 'react';
import Select from 'react-select';

import { EnergyAuditStatus } from '@contexts/energy-audit';

export interface Filters {
  group?: any;
  date?: string;
  status?: EnergyAuditStatus;
  month?: number;
  year?: number;
  searchTerm?: string;
}

export interface ICommercialGroupPopUpFilter extends React.HTMLAttributes<HTMLDivElement> {
  setFilters: (value: React.SetStateAction<Filters | undefined>) => void;
  setSomeFilterUsed: (value: boolean) => void;
  setOpenGroupFilterPopUp: (value: boolean) => void;
  groupFilterElements: any[] | undefined;
  show?: boolean;
}

const CommercialGroupPopUpFilter = ({
  setFilters,
  setSomeFilterUsed,
  setOpenGroupFilterPopUp,
  groupFilterElements,
  show,
  ...attrs
}: ICommercialGroupPopUpFilter) => {
  const { className } = attrs;
  const inputOptions = groupFilterElements
    ? groupFilterElements.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return (
    <div
      className={`flex flex-col gap-2 absolute shadow-3 p-5 h-fit bg-white z-10 border-black border-[1px] rounded-medium w-fit ${className} ${
        !show && 'hidden'
      }`}
    >
      <Select
        value={''}
        placeholder={'Selecione o grupo'}
        options={inputOptions as []}
        onChange={(option: any) => {
          setOpenGroupFilterPopUp(false);
          setFilters((prev) => ({ ...prev, group: { id: option.value, name: option.label, cnpj: '' } }));
          setSomeFilterUsed(true);
        }}
        maxMenuHeight={300}
        isDisabled={false}
        styles={{
          control: (base) => ({
            ...base,
            '&:hover': { borderColor: 'black' },
            border: '1px solid black',
            boxShadow: 'none',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#00DF7C' : 'none',
            '&:hover': { backgroundColor: state.isSelected ? 'none' : '#F1FFEB' },
          }),
        }}
      />
    </div>
  );
};

export default CommercialGroupPopUpFilter;

import React from 'react';

import Section from '@components/atoms/economy-report/section';
import ReportTables from './unit-report-table';
import { FreeMarketTableData } from '@contexts/unit-economy-report/types';
import { freeMarketTablesGenerator } from './report-helpers-method';
import Skeleton from 'react-loading-skeleton';

interface FreeMktSecitonProps {
  freeMarketTableData: FreeMarketTableData;
  isFetchingData: boolean;
}

const SectionTableSkeleton: React.FC = () => (
  <div>
    <Skeleton height="40rem" />
  </div>
);

const FreeMktSection = ({ isFetchingData, freeMarketTableData }: FreeMktSecitonProps): JSX.Element => {
  const tables = freeMarketTablesGenerator(freeMarketTableData);
  return (
    <div className={`mt-10 xl:mt-0 ${isFetchingData && 'xl:grow'} 2xl:grow`}>
      <Section sectionNumber="01" title="Custos Mercado Livre" isLoading={isFetchingData} />
      {isFetchingData ? (
        <SectionTableSkeleton />
      ) : (
        <ReportTables
          type="ACL"
          tables={tables}
          totals={{
            totalValueWithoutTax: freeMarketTableData?.totalValueWithoutTax,
            totalValue: freeMarketTableData?.totalValue,
          }}
        />
      )}
    </div>
  );
};

export default FreeMktSection;

import { EnergyContract } from '@contexts/energy-audit';
import React from 'react';

interface IEnergyAuditsContractSummary {
  energyContract: EnergyContract;
}

const EnergyAuditsContractSummary: React.FC<IEnergyAuditsContractSummary> = ({
  energyContract,
}: IEnergyAuditsContractSummary) => {
  const contents = [
    { title: 'Flexibilidade mínima', value: `-${energyContract?.lowerFlexibility}` },
    { title: 'Flexibilidade máxima', value: energyContract?.upperFlexibility },
    { title: 'Volume contratado do mês', value: energyContract?.seasonality },
    { title: 'Energia', value: energyContract?.energyType },
  ];

  return (
    <div>
      {contents.map(({ title, value }) => (
        <div key={title} className="flex justify-between text-paragraph-medium px-4 py-1 bg-[#FAFAFA]">
          <span className="font-bold">{title}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
};

export default EnergyAuditsContractSummary;

import { gql } from '@apollo/client';

export const CREATE_COMMERCIAL_GROUP = gql`
  mutation CreateCommercialGroup($input: CreateCommercialGroupInput) {
    createCommercialGroup(input: $input) {
      id
    }
  }
`;

export const UPDATE_COMMERCIAL_GROUP = gql`
  mutation UpdateCommercialGroup($input: UpdateCommercialGroupInput) {
    updateCommercialGroup(input: $input)
  }
`;

export const DELETE_COMMERCIAL_GROUP = UPDATE_COMMERCIAL_GROUP;

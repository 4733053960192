import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormField,
  SelectFormField,
  NumericFormField,
  MultiSelectFormField,
  FormSection,
} from '@components/atoms/form';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { orderListbyParameter } from '@utils/index';
import { useEnergyContracts } from '@hooks/use-energy-contract';

interface ContractLikSectionProps {
  profiles: {
    value: string;
    label: string;
  }[];
}

const FormContractLinkSection = ({ profiles }: ContractLikSectionProps): JSX.Element => {
  const { groups } = useEnergyContracts();
  const { watch } = useFormContext();
  const groupId = watch('groupId');

  const groupOptions = useMemo(() => {
    return orderListbyParameter(groups, 'name').map((group) => ({
      value: group.id,
      optionLabel: group.name,
    }));
  }, [groups]);

  const groupUnits = useMemo(() => {
    return groupId ? groups.find((group) => group.id === groupId)?.units : [];
  }, [groupId]);

  const unitOptions = useMemo(() => {
    return groupUnits?.length
      ? orderListbyParameter(groupUnits, 'name').map((unit) => ({
          value: unit.id,
          label: unit.name,
        }))
      : [];
  }, [groupUnits]);

  return (
    <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
      <FormSection
        title="Vínculo do contrato"
        subtitle="Quais são as unidades que estão sob esse contrato de energia?"
      />
      <div className="gap-x-6 gap-y-6 lg:grid lg:grid-cols-2 lg:col-span-2 xl:grid-cols-3 xl:col-span-3">
        <SelectFormField<CreateEnergyContractPayload>
          id="groupId"
          label="Grupo Comercial"
          field="groupId"
          inputOptions={groupOptions}
          options={{ required: { value: true, message: 'O cliente é obrigatório' } }}
        />

        <MultiSelectFormField<CreateEnergyContractPayload>
          id="unitsId"
          inputOptions={unitOptions}
          placeholder="Selecione as unidades "
          label="Unidades consumidoras"
          field="unitsId"
          options={{ required: { value: true, message: 'Selecione uma unidade' } }}
        />

        <MultiSelectFormField<CreateEnergyContractPayload>
          id="cceeProfiles"
          inputOptions={profiles}
          placeholder="Selecione os perfis"
          label="Perfis CCEE"
          field="cceeProfiles"
        />

        <FormField<CreateEnergyContractPayload> id="cceeAgent" label="Agente CCEE" field="cceeAgent" />

        <NumericFormField<CreateEnergyContractPayload>
          id="idCceeTrader"
          label="ID Fornecedora CCEE"
          field="idCceeTrader"
          formatProps={{
            placeholder: 'Insira o id do perfil da CCEE.',
            format: '#######',
            mask: '',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
          }}
          options={{
            valueAsNumber: false,
          }}
        />
        <FormField<CreateEnergyContractPayload>
          id="perfilCceeTrader"
          label="Perfil Fornecedora CCEE"
          field="perfilCceeTrader"
        />
      </div>
    </div>
  );
};

export default FormContractLinkSection;

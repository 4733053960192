import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { transformObjectKeysToCamelCase } from '@src/common';
import ContractLayoutBase from '@components/organisms/general/management-contract/layout-base';
import ContractContent from '@components/organisms/general/management-contract/content';
import { IManagementContractPayload, IManagementContractProduct } from '@contexts/management-contract/types';
import { useAuth0 } from '@auth0/auth0-react';
import useManagementContract from '@hooks/use-management-contract';
import { initializeFormWithDefaults } from '@contexts/management-contract/parse';
import { parseAdditionalServicesResponse } from './parser';

interface IManagementContractForm {
  product: IManagementContractProduct;
}

const ManagementContractForm = ({ product }: IManagementContractForm): JSX.Element => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');
  const { getManagementContractById } = useManagementContract();
  const { user } = useAuth0();
  const idProperty = 'https://clarke.com.br/uuid';

  const formRef = React.useRef<HTMLFormElement>(null);
  const [formDefaultValues, setFormDefaultValues] = React.useState<IManagementContractPayload>();

  const setProposalIdAndUserAtForm = () => {
    if (user) {
      setValue('user.id', user[idProperty]);
      setValue('user.name', user.name);
      setValue('user.email', user.email);
      setValue('user.pictureUrl', user.picture);
    }
    if (contractId) {
      setValue('id', contractId);
    }
  };

  const formController = useForm<IManagementContractPayload>({
    defaultValues: formDefaultValues,
    mode: 'all',
  });
  const {
    setValue,
    formState: { errors },
  } = formController;

  React.useEffect(() => {
    const fetchData = async () => {
      const { data: response } = await getManagementContractById(contractId!);
      const camelCaseData = transformObjectKeysToCamelCase(response.data);
      const previousContract = parseAdditionalServicesResponse(camelCaseData as IManagementContractPayload);
      setFormDefaultValues(previousContract);
    };
    fetchData();
    setProposalIdAndUserAtForm();
  }, []);

  React.useEffect(() => {
    initializeFormWithDefaults(formDefaultValues, setValue);
  }, [formDefaultValues]);

  return (
    <ContractLayoutBase title="Editar Contrato" contractType={product} contractFlow="CONTRACT_UPDATE_FORM">
      <ContractContent formRef={formRef} formController={formController} contractProduct={product} />
    </ContractLayoutBase>
  );
};

export default ManagementContractForm;

import React, { useState, useEffect, useMemo } from 'react';
import uniqby from 'lodash.uniqby';

import { CreateCceeContractPayload } from '@contexts/ccee-contract/types';
import TradersProvider from '@contexts/trader';
import { useCceeContract } from '@hooks/use-cce-contract';
import { FormSection, SelectFormField } from '@components/atoms/form';
import LongDurationFields from './long-durationfields';
import ShortDurationFields from './short-duration-fields';

interface Form {
  durationType: 'SHORT' | 'LONG' | undefined;
}

const FormContainer = ({ durationType }: Form) => {
  const {
    energyContracts: { totalPage, energyContracts },
    loadingEnergyContracts,
    getEnergyContractsHandler,
  } = useCceeContract();

  const [contractPage, setContracPage] = useState<number>(0);

  useEffect(() => {
    if (contractPage <= totalPage) {
      getEnergyContractsHandler({ page: contractPage, term: null });
    }
  }, [contractPage, totalPage]);

  const handleChangeComboBox = (serachItem: string | null) => {
    if (serachItem) getEnergyContractsHandler({ page: contractPage, term: serachItem });
  };

  const energyContractsSorted = useMemo(() => {
    const contratcts = uniqby(energyContracts, 'id');
    return contratcts.sort((contractA, contractB) => (contractA.group.name >= contractB.group.name ? 1 : -1));
  }, [energyContracts]);

  const renderDurationFields = () => {
    if (!durationType) return null;

    if (durationType === 'LONG')
      return (
        <LongDurationFields
          loadingEnergyContracts={loadingEnergyContracts}
          contracts={energyContractsSorted}
          handleScrollComboBox={() => setContracPage((prevPage) => prevPage + 1)}
          handleChangeComboBox={handleChangeComboBox}
        />
      );

    return (
      <TradersProvider>
        <ShortDurationFields
          loadingEnergyContracts={loadingEnergyContracts}
          contracts={energyContractsSorted}
          handleScrollComboBox={() => setContracPage((prevPage) => prevPage + 1)}
          handleChangeComboBox={handleChangeComboBox}
        />
      </TradersProvider>
    );
  };

  return (
    <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
      <FormSection title="Informações do contrato" subtitle="Dados do contrato CCEE de longo ou curto prazo." />
      <SelectFormField<CreateCceeContractPayload>
        label="Duração do contrato"
        field="duration"
        id="duration"
        inputOptions={[
          { optionLabel: 'Curto Prazo', value: 'SHORT' },
          { optionLabel: 'Longo Prazo', value: 'LONG' },
        ]}
        options={{ required: { value: true, message: 'Tipo de contrato é obrigatório' } }}
      />
      {renderDurationFields()}
    </div>
  );
};

export default FormContainer;

import React from 'react';

import { Modal } from '@clarke-energia/foton';
import { ModalType } from '@pages/commercial-groups/helper';
import FormSyncGroupAsana, { IFormSyncGroupAsana } from '../form/form-sync-group-asana';
import SyncMigrationModalContent, { SyncMigrationModalContentProps } from '@components/atoms/migration-report/modal';

type Content = SyncMigrationModalContentProps | IFormSyncGroupAsana;

interface MigrationModalProps {
  type: ModalType | null;
  content: Content;
  confirmationOnClick: () => void;
  cancelOnClick: () => void;
}

const MigrationModal = ({ type, confirmationOnClick, cancelOnClick, content }: MigrationModalProps): JSX.Element => {
  const isCreationTaskModalType = type !== null && type === 'CREATE_TASK';

  const title = isCreationTaskModalType ? 'Vincular Asana' : 'Sincronizar e atualizar o progresso do cliente';
  const description = isCreationTaskModalType
    ? 'Para sincronizar as tarefas de migração ao grupo comercial, é necessário inserir o ID do cliente do Asana. '
    : 'Além de sincronizar a plataforma com o Asana, é possível também notificar o cliente de atualizações via Área do Cliente ou email, selecionando as opções abaixo. Se não quiser notificar, não selecione nada.';

  return (
    <Modal
      title={title}
      description={description}
      open={type !== null}
      setOpen={() => cancelOnClick()}
      modalButtons={
        !isCreationTaskModalType
          ? {
              primary: {
                kind: 'primary',
                label: 'Atualizar',
                onClick: () => {
                  confirmationOnClick();
                },
              },
              secondary: {
                kind: 'secondary',
                label: 'Cancelar',
                onClick: () => {
                  cancelOnClick();
                },
              },
            }
          : undefined
      }
    >
      {type === 'CREATE_TASK' ? (
        <FormSyncGroupAsana {...(content as IFormSyncGroupAsana)} />
      ) : (
        <SyncMigrationModalContent {...(content as SyncMigrationModalContentProps)} />
      )}
    </Modal>
  );
};

export default MigrationModal;

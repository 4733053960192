import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeedbackNotification, Layout } from '@clarke-energia/foton';

import { FinancialEventsFilters } from '@contexts/financial-events/types';
import useFinancialEvents from '@hooks/use-financial-events';
import { NEW_FINANCIAL_EVENT_PATH } from '@routers/constants';

import FinancialEventsListCore from '@components/organisms/financial-events/list-core';

import {
  financialEventsListPageNavigationHistoryLinks as navigationHistoryLinks,
  getSendFinancialEventsByBatchIDFeedbackContent,
} from './static-data';

const FinancialEventsListPage: React.FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FinancialEventsFilters>();
  const { sendFinancialEventsStatus, openNotificationFeedBack, setOpenNotificationFeedBack } = useFinancialEvents();

  const sendFinancialEventsFeedbackContent = getSendFinancialEventsByBatchIDFeedbackContent(() =>
    setOpenNotificationFeedBack(false),
  );

  return (
    <Layout
      title="Eventos Financeiros"
      navigationHistoryLinks={navigationHistoryLinks}
      searchBar={{
        onTypedTermChangedCallback: (term) => setFilters((previous) => ({ ...previous, searchTerm: term })),
        placeholder: 'Buscar',
      }}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Novo evento financeiro',
          onClick: () => {
            navigate(NEW_FINANCIAL_EVENT_PATH);
          },
          size: 'small',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 z-20 my-6 mx-6 max-w-full lg:w-fit">
        {openNotificationFeedBack && sendFinancialEventsStatus && (
          <FeedbackNotification
            label={sendFinancialEventsFeedbackContent[sendFinancialEventsStatus].label}
            message={sendFinancialEventsFeedbackContent[sendFinancialEventsStatus].message}
            kind={sendFinancialEventsFeedbackContent[sendFinancialEventsStatus].kind}
            onCloseNotification={sendFinancialEventsFeedbackContent[sendFinancialEventsStatus].onCloseNotification}
          />
        )}
      </div>
      <FinancialEventsListCore filters={filters} setFilters={setFilters} />
    </Layout>
  );
};

export default FinancialEventsListPage;

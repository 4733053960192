import { ICreateKickOffByGroupIDMutationData, IGetKickOffByGroupIDQueryData, IKickOff } from './types';

export class GetKickOffByGroupIDManager {
  rawData: IGetKickOffByGroupIDQueryData;
  kickOff: IKickOff;

  constructor(rawData: IGetKickOffByGroupIDQueryData) {
    this.rawData = rawData;
    this.kickOff = rawData.getKickOffByGroupID;
  }
}

export class CreateKickOffByGroupIDManager {
  rawData: ICreateKickOffByGroupIDMutationData;
  kickOff: IKickOff;

  constructor(rawData: ICreateKickOffByGroupIDMutationData) {
    this.rawData = rawData;
    this.kickOff = rawData.createKickOffByGroupID;
  }
}

import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, NavigationLinkProps } from '@clarke-energia/foton';
import { getWindowDimensions } from '@utils/graphics';
import ClarkeLogo from '@assets/clarke-logo.svg';
import ClarkeLogoHorizontal from '@assets/logo-clarke-horizontal-green.png';
import MobileMenuControllerButton from '@components/atoms/sidebar/mobile-menu-controller-button';
import MobileNavigationLinks from '@components/atoms/sidebar/mobile-navigation-links';
import { useAuth } from '@src/ApolloWrapper';
import {
  CCEE_PROFILES_PATH,
  CONTRACTS_PATH,
  FINANCIAL_EVENTS_PATH,
  HOME_PATH,
  TRADERS_PATH,
  ENERGY_AUDIT_PATH,
  MANAGEMENT_CONTRACTS_PATH,
  PRODUCT_ONE_PATH,
  PRODUCT_TWO_PATH,
  MIGRATION_REPORT_PATH,
} from '@routers/constants';

interface ISidebar {
  isMenuCollapsed: boolean;
}

interface IDesktopSideBar extends ISidebar {
  setIsMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const baseNavigationLinks = (isMenuCollapsed: boolean): NavigationLinkProps[] => {
  return [
    { label: 'Clientes', path: HOME_PATH, iconName: 'HomeIcon' },
    { label: 'Fornecedoras', path: TRADERS_PATH, iconName: 'CurrencyDollarIcon' },
    {
      iconName: 'DocumentChartBarIcon',
      label: 'Relatórios de Migração',
      path: MIGRATION_REPORT_PATH,
    },
    { label: 'Contratos', path: CONTRACTS_PATH, iconName: 'NewspaperIcon' },
    {
      label: 'Contratos de Vendas',
      iconName: 'PaperClipIcon',
      sublinks: [
        {
          label: 'Clarke gestão',
          path: `${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_ONE_PATH}`,
          iconName: 'BuildingOffice2Icon',
        },
        {
          label: 'Clarke varejo',
          path: `${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_TWO_PATH}`,
          iconName: 'BuildingStorefrontIcon',
        },
      ],
      isMenuCollapsed,
    },
    { label: 'Perfis CCEE', path: CCEE_PROFILES_PATH, iconName: 'AtSymbolIcon' },
    { label: 'Eventos Financeiros', path: FINANCIAL_EVENTS_PATH, iconName: 'CurrencyDollarIcon' },
    { label: 'Balanço energético', path: ENERGY_AUDIT_PATH, iconName: 'ScaleIcon' },
  ];
};

const DesktopSideBar = ({ isMenuCollapsed, setIsMenuCollapsed }: IDesktopSideBar): JSX.Element => {
  const { user } = useAuth();
  const { logout } = useAuth0();

  return (
    <div className="h-full w-[15.5rem]">
      <Menu
        adminLogo={user.pictureURL}
        userLogo={user.pictureURL}
        companyLogo={ClarkeLogo}
        name={user.name}
        isAdmin={user.roles?.find((role) => role === 'admin') != undefined}
        adminLinks={baseNavigationLinks(isMenuCollapsed)}
        userLinks={baseNavigationLinks(isMenuCollapsed)}
        logoutAction={() => logout()}
        menuCollapseControl={{
          isMenuCollapsed,
          setIsMenuCollapsed: () => setIsMenuCollapsed((previous) => !previous),
        }}
      />
    </div>
  );
};

export const MobileSidebar = ({ isMenuCollapsed }: ISidebar): JSX.Element => {
  const { user } = useAuth();
  const { logout } = useAuth0();

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false);
  const closeSidebar = () => setMobileSidebarOpen(false);

  return (
    <div className="flex">
      <div className="flex relative">
        <MobileMenuControllerButton setMobileSidebarOpen={setMobileSidebarOpen} mobileSidebarOpen={mobileSidebarOpen} />
        <div className="flex justify-center items-center w-screen h-10">
          <img src={ClarkeLogoHorizontal} className="w-fit h-[1.5rem]" />
        </div>
      </div>
      <Transition show={mobileSidebarOpen} as={React.Fragment}>
        <Dialog open={mobileSidebarOpen} onClose={closeSidebar} className="relative z-50">
          <div className="flex fixed bottom-0 top-10 w-full p-l-0">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="flex overflow-y-auto relative flex-col gap-6 justify-between px-4 pt-6 pr-6 w-full bg-white overflow-x-clip">
                <MobileNavigationLinks links={baseNavigationLinks(isMenuCollapsed)} closeSidebar={closeSidebar} />
                <div className="flex p-5 w-full h-12 gap-[12px]">
                  <img src={user.pictureURL} className="w-8 h-8 border-black border-[1px] rounded-pill" />
                  <div className="w-full">
                    <div className="w-full text-paragraph-medium truncate">{user.email}</div>
                    <div onClick={() => logout()} className="text-paragraph-small">
                      Sair
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

const SideBar = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState<boolean>(false);
  if (windowDimensions.width <= 768) return <MobileSidebar isMenuCollapsed={isMenuCollapsed} />;
  else return <DesktopSideBar isMenuCollapsed={isMenuCollapsed} setIsMenuCollapsed={setIsMenuCollapsed} />;
};

export default SideBar;

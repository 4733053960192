import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { ButtonInline } from '@clarke-energia/foton';
import { NumericFormField } from '@components/atoms/form';
import { DatetimeMeasurement } from '@contexts/energy-audit-consumptions';
import useEnergyAuditConsumption from '@hooks/use-energy-audit-consumptions';

interface IFormUpdateMeasurementHour {
  onFormSuccess: () => void;
  onFormFail: () => void;
  entry: DatetimeMeasurement;
  setMeasurementByHourToEdit: (value: React.SetStateAction<DatetimeMeasurement | undefined>) => void;
}

export interface UpdateMeasurementHourForm {
  hour: string;
  datetime?: string;
  consumption: string;
}

const FormUpdateMeasurementHour = ({
  onFormSuccess,
  onFormFail,
  entry,
  setMeasurementByHourToEdit,
}: IFormUpdateMeasurementHour) => {
  const methods = useForm<UpdateMeasurementHourForm>({
    defaultValues: {},
  });
  const { updateMeasurementManuallyHandler } = useEnergyAuditConsumption();
  const timeStr = entry.datetime.substring(11, 19);

  const onSubmit: SubmitHandler<UpdateMeasurementHourForm> = async (values) => {
    if (values.consumption) {
      updateMeasurementManuallyHandler(values.consumption, timeStr)
        .then(() => {
          onFormSuccess();
        })
        .catch(() => {
          onFormFail();
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex gap-4">
        <div className="-mt-3 w-[8rem]">
          <NumericFormField<UpdateMeasurementHourForm>
            id="consumption"
            field="consumption"
            label=""
            className="self-center"
            formatProps={{
              placeholder: '',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 5,
              isNumericString: true,
            }}
          />
        </div>
        <ButtonInline className="self-center w-[1.6rem]" label={'OK'} kind={'secondary'} size={'small'} />
        <ButtonInline
          className="self-center w-[1.6rem]"
          kind={'secondary'}
          size={'small'}
          icon="XMarkIcon"
          type="button"
          onClick={() => setMeasurementByHourToEdit(undefined)}
        />
      </form>
    </FormProvider>
  );
};

export default FormUpdateMeasurementHour;

import { gql } from '@apollo/client';

export const GET_ALL_CLARKE_CONTRACTS_QUERY = gql`
  query GetAllClarkeContracts($input: GetClarkeContractInput) {
    getAllClarkeContracts(input: $input) {
      limit
      total
      data {
        id
        clarkeMagmentAmount
        signatureDate
        group {
          name
          id
        }
      }
    }
  }
`;
export const GET_CLARKE_CONTRACT_BY_ID_QUERY = gql`
  query GetClarkeContractById($contractId: ID!) {
    getClarkeContractById(contractId: $contractId) {
      id
      clarkeMagmentAmount
      signatureDate
      readjustmentIndex
      readjustmentFrequency
      readjustmentBaseDate
      attachments {
        id
        filename
        url
        fileSize
      }
      group {
        id
        name
      }
      energyContracts {
        id
        group {
          id
          name
        }
      }
      units {
        id
        name
      }
    }
  }
`;

export const GET_CLARKE_GROUPS_FILTER_QUERY = gql`
  query GetCceeContracts($input: GetClarkeContractInput) {
    getAllClarkeContracts(input: $input) {
      total
      limit
      data {
        group {
          name
          id
        }
      }
    }
  }
`;

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        units {
          id
          name
        }
      }
    }
  }
`;

export const GET_ENERGY_CONTRACTS_QUERY = gql`
  query EnergyContracts($input: GetEnergyContractsInput) {
    energyContracts(input: $input) {
      total
      limit
      data {
        id
        group {
          id
          name
        }
      }
    }
  }
`;

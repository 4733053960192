import axios from 'axios';
import uploadAttachmentFileParser from './parser';
import configuration from '../../config';
import { UpsertFileForm } from '@components/molecules/form/form-upsert-document';

export const filesApi = axios.create({
  baseURL: configuration.FILES_API_HOST,
});

export async function uploadFile(data: UpsertFileForm, accessToken: string) {
  const formData = uploadAttachmentFileParser(data);
  return filesApi.post('/api/documents/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

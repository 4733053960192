import {
  MonthlyControlMeasurementByUnitIdGraphQLData,
  MonthlyControlMeasurementByUnitId,
  UpdateMeasurementAutomaticallyManagerGraphQLData,
  UpdateDailyConsumptionOfUnitsManuallyManagerGraphQLData,
  ConsolidatedMeasurementByIdGraphQLData,
  ConsolidatedMeasurement,
  UpdateMeasurementManuallyManagerGraphQLData,
  UnitConsumptionMeasuresPerHourGraphQLData,
  UnitConsumptionMeasuresPerHour,
} from './types';

export class ConsolidatedMeasurementByIdManager {
  rawData: ConsolidatedMeasurementByIdGraphQLData;
  consolidateMeasurementById: ConsolidatedMeasurement;

  constructor(rawData: ConsolidatedMeasurementByIdGraphQLData) {
    this.rawData = rawData;
    this.consolidateMeasurementById = rawData.getConsolidatedMeasurementById;
  }
}

export class MonthlyControlMeasurementByUnitIdManager {
  rawData: MonthlyControlMeasurementByUnitIdGraphQLData;
  monthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId>;

  constructor(rawData: MonthlyControlMeasurementByUnitIdGraphQLData) {
    this.rawData = rawData;
    this.monthlyControlMeasurementByUnitId = rawData.getMonthlyControlMeasurementByUnitId;
  }
}

export class UnitConsumptionMeasuresPerHourManager {
  rawData: UnitConsumptionMeasuresPerHourGraphQLData;
  unitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour>;

  constructor(rawData: UnitConsumptionMeasuresPerHourGraphQLData) {
    this.rawData = rawData;
    this.unitConsumptionMeasuresPerHour = rawData.getUnitConsumptionMeasuresPerHour;
  }
}
export class UpdateMeasurementAutomaticallyManager {
  rawData: UpdateMeasurementAutomaticallyManagerGraphQLData;
  success: boolean;

  constructor(rawData: UpdateMeasurementAutomaticallyManagerGraphQLData) {
    this.rawData = rawData;
    this.success = rawData.updateMeasurementAutomatically;
  }
}

export class UpdateMeasurementManuallyManager {
  rawData: UpdateMeasurementManuallyManagerGraphQLData;
  consolidatedMeasurementUpdated: ConsolidatedMeasurement;

  constructor(rawData: UpdateMeasurementManuallyManagerGraphQLData) {
    this.rawData = rawData;
    this.consolidatedMeasurementUpdated = rawData.updateTimeManually;
  }
}

export class UpdateDailyConsumptionOfUnitsManuallyManager {
  rawData: UpdateDailyConsumptionOfUnitsManuallyManagerGraphQLData;
  success: boolean;

  constructor(rawData: UpdateDailyConsumptionOfUnitsManuallyManagerGraphQLData) {
    this.rawData = rawData;
    this.success = rawData.updateDailyConsumptionOfUnitsManually;
  }
}

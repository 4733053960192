import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateCceeContractPayload, EnergyContract } from '@contexts/ccee-contract/types';
import { NumericFormField, SelectFormField } from '@components/atoms/form';
import { ComboBoxFormField } from '../form/combobox';

interface CotractFieldsProps {
  contractTypeOptions: { optionLabel: string; value: string }[];
  loadingEnergyContracts: boolean;
  handleScrollComboBox: () => void;
  handleChangeComboBox: (serachItem: string | null) => void;
  contracts: EnergyContract[];
}

const ContractFields = ({
  contractTypeOptions,
  loadingEnergyContracts,
  contracts,
  handleChangeComboBox,
  handleScrollComboBox,
}: CotractFieldsProps): JSX.Element => {
  const { watch, setValue } = useFormContext<CreateCceeContractPayload>();
  const contractId = watch('energyContractId');
  const contract = watch('contractType');
  const targetContract = contracts.find(({ id }) => id === contractId);

  const energyContractOptions = contracts.length
    ? contracts.map(({ group, id }) => ({ label: group?.name, value: id }))
    : [];
  const loadingContractsOption = [{ label: 'Buscando contratos...', value: '' }];

  return (
    <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
      <SelectFormField<CreateCceeContractPayload>
        label="Tipo de contrato"
        field="contractType"
        id="contractType"
        inputOptions={contractTypeOptions}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
      <NumericFormField<CreateCceeContractPayload>
        label="Numero do contrato CCEE"
        id="contractCceeId"
        field="contractCceeId"
        formatProps={{
          placeholder: '123459',
          format: '#######',
          mask: '',
          allowNegative: false,
          fixedDecimalScale: false,
          decimalSeparator: undefined,
          thousandSeparator: undefined,
          decimalScale: 0,
        }}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />

      {contract === 'SHORT_DURATION_SALES' ? (
        <ComboBoxFormField<CreateCceeContractPayload>
          label="CCEAL vinculado"
          field="energyContractId"
          id="energyContractId"
          inputOptions={loadingEnergyContracts ? loadingContractsOption : energyContractOptions}
          isLoading={loadingEnergyContracts}
          onMenuScrollToBottom={handleScrollComboBox}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
          onInputChange={(value) => {
            if (value) handleChangeComboBox(value);

            if (targetContract) {
              setValue('groupId', targetContract.group.id);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default ContractFields;

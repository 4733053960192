import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, FeedbackNotification, Layout } from '@clarke-energia/foton';

import { CONTRACTS_PATH, HOME_PATH } from '@routers/constants';

import CceeProfileProvider from '@contexts/ccee-profile';
import TradersProvider from '@contexts/trader';
import { CreateEnergyContractPayload, EMPTY_CONTRACT } from '@contexts/energy-contract/types';
import { parseContractToCreate } from '@contexts/energy-contract/parser';
import FormEnergyContract from '@components/molecules/form/form-energy-contract';
import { useEnergyContracts } from '@hooks/use-energy-contract';
import { useNotification } from '@hooks/use-notification';
import { ClarkeContractNotificationFeedbackContents } from '@utils/constants/common';

const EnergyContractFormPage: React.FC = () => {
  const {
    createEnergyContractHandler,
    updateEnergyContractHandler,
    mutationLoading,
    getGroupsListHandler,
    getEnergyContractByIdHandler,
    energyContractToUpdate,
    setFeedbackType,
    createEnergyContractAttachmentsHandler,
    feedbackType,
  } = useEnergyContracts();
  const {
    openNotificationFeedBack,
    setOpenNotificationFeedback,
    messageNotificationFeedBack,
    setNotificationResponse,
  } = useNotification();

  const navigate = useNavigate();
  const { contractId } = useParams<string>();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>();

  useEffect(() => {
    getGroupsListHandler();
  }, []);

  useEffect(() => {
    if (contractId) {
      getEnergyContractByIdHandler(contractId);
    }
  }, []);

  const methods = useForm<CreateEnergyContractPayload>({
    defaultValues: EMPTY_CONTRACT,
    mode: 'all',
  });

  const onSubmit: SubmitHandler<CreateEnergyContractPayload> = async (energyContract) => {
    const energyContractPayload = parseContractToCreate(energyContract);

    const energyContractUpdated: CreateEnergyContractPayload = {
      id: contractId,
      ...energyContractPayload,
    };

    const upsertResponse = contractId
      ? await updateEnergyContractHandler(energyContractUpdated)
      : await createEnergyContractHandler(energyContractPayload);

    if (uploadedFiles && upsertResponse.id) {
      createEnergyContractAttachmentsHandler({
        energyContractAttachments: uploadedFiles,
        energyContractId: upsertResponse.id,
      });
    }

    navigate(CONTRACTS_PATH, { replace: true, state: upsertResponse.feedback });
  };

  React.useEffect(() => {
    const isfeedbackAttachmentType =
      feedbackType === 'DELETING_CLARKE_CONTRACT_ATTACHMENT_ERROR' ||
      feedbackType === 'DELETING_CLARKE_CONTRACT_ATTACHMENT_SUCCESS';
    if (isfeedbackAttachmentType) {
      setNotificationResponse(ClarkeContractNotificationFeedbackContents[feedbackType]);
    }
  }, [feedbackType]);

  return (
    <Layout
      title={`${contractId ? 'Editar' : 'Criar'} CCEAL`}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos',
          url: CONTRACTS_PATH,
        },
        {
          label: `${contractId ? 'Editar' : 'Criar'} CCEAL`,
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full z-100 lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => {
              setOpenNotificationFeedback(false);
              setFeedbackType(null);
            }}
          />
        )}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full pt-7 h-full">
          <TradersProvider>
            <CceeProfileProvider>
              <FormEnergyContract
                dataToUpdate={contractId ? energyContractToUpdate : undefined}
                setUploadedFiles={setUploadedFiles}
              />
            </CceeProfileProvider>
          </TradersProvider>
          <div className="flex gap-5 mb-5 w-fit">
            <Button
              loading={mutationLoading}
              label={`${contractId ? 'Atualizar' : 'Criar'} contrato`}
              type="submit"
              kind={'primary'}
            />
            <Link to={CONTRACTS_PATH}>
              <Button label="Cancelar" type="button" onClick={() => setFeedbackType(null)} kind={'secondary'} />
            </Link>
          </div>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default EnergyContractFormPage;

export enum ContractTypeEnum {
  GUARANTEED_SAVING = 'Economia Garantida',
  FIXED_PRICE = 'Preço Fixo Escalonado',
}

export const MonthTranslationMapper = {
  january: 'janeiro',
  february: 'fevereiro',
  march: 'março',
  april: 'abril',
  may: 'maio',
  june: 'junho',
  july: 'julho',
  august: 'agosto',
  september: 'setembro',
  october: 'outubro',
  november: 'novembro',
  december: 'dezembro',
};

export enum ProposalTypeEnum {
  INDICATIVE = 'Indicativa',
  FIRM = 'Firme',
}
export enum GuaranteeTypeEnum {
  NO_GUARANTEE = 'Sem garantia',
  SURETY_BOND = 'Seguro garantia',
  ADVANCE_PAYMENT = 'Pagamento antecipado',
  BANK_GUARANTEE = 'Carta fiança bancária',
  GUARANTEE_LETTER = 'Nota promissória',
  REGISTRATION_AGAINST_PAYMENT = 'Registro contra pagamento',
  OTHER = 'Outro',
}

export enum UnitTypeEnum {
  COMMERCIAL = 'Comercial',
  RURAL = 'Rural',
  INDUSTRIAL = 'Industrial',
}

export enum TariffModeEnum {
  GREEN = 'Verde',
  BLUE = 'Azul',
}

export enum EnergyTypeEnum {
  CONVENTIONAL = 'Convencional',
  INCENTIVIZED_0 = 'Incentivada 0%',
  INCENTIVIZED_50 = 'Incentivada 50%',
  INCENTIVIZED_80 = 'Incentivada 80%',
  INCENTIVIZED_100 = 'Incentivada 100%',
}

export enum SubmarketEnum {
  NORTH = 'Norte',
  NORTHEAST = 'Nordeste',
  SOUTH = 'Sul',
  SOUTHEAST = 'Sudeste',
}

export enum CoverCeeeCostEnum {
  NONE = 'Não',
  FULL = 'Sim',
  PARTIAL = 'Parcial',
}

export enum DocumentTypeEnum {
  FINAL_PROPOSAL = 'Proposta final',
  SIGNED_PROPOSAL = 'Proposta final assinada',
  OTHERS = 'Outros',
}

export enum CommissionTypeEnum {
  PERCENTAGE = '%',
  REALS_PER_MONTH = 'R$/Mês',
  REALS_PER_MEGAWATT = 'R$/MWh',
}

export enum PartialCCEECoverEnum {
  ESS = 'ESS',
  EER = 'EER',
  FINANCIAL_LIQUIDATIONS = 'Liquidações financeiras',
  CCCE_CONTRIBUITION = 'Contribuição CCEE',
}

export enum ClarkeCommissionTypeEnum {
  LUMP_SUM = 'À vista',
  MONTHLY = 'Mensalmente',
  NO_PREFERENCE = 'Sem preferência',
}

import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import HeroIcon from '@components/atoms/hero-icon';

interface AccordionItemProps {
  children: React.ReactNode;
  label: string;
  labelSubscript?: string;
  initiallyOpen?: boolean;
  testId?: string;
}
const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  label,
  labelSubscript,
  initiallyOpen = true,
  testId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen);
  const iconBaseClass = 'cursor-pointer w-6 h-6 transition-transform ease-linear duration-300';
  const rotateClass = isOpen ? '-rotate-180' : '-rotate-0';
  const iconClass = `${iconBaseClass} ${rotateClass}`;

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-4 space-x-8">
        <button type="button" data-cy={testId} onClick={() => setIsOpen(!isOpen)}>
          <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
        </button>
        <div className="flex space-x-3">
          <p className="text-heading-medium">{label}</p>
          {labelSubscript && <p className="text-paragraph-small">{labelSubscript}</p>}
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
};

export default AccordionItem;

import React from 'react';

interface PageSection extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: JSX.Element;
}

const PageSection = ({ title, children, ...attrs }: PageSection): JSX.Element => {
  const { className } = attrs;
  return (
    <div className={`col-span-full ${className}`}>
      <div className="mb-5 text-heading-medium">{title}</div>
      {children}
    </div>
  );
};

export default PageSection;

import React from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Accordion, Button } from '@clarke-energia/foton';

import { SyncMutationReportsInput } from '@contexts/migration-report/types';
import { useMigrationReport } from '@hooks/use-migration-report';
import { useNotification } from '@hooks/use-notification';

import { ModalType } from '@pages/commercial-groups/helper';
import video from '@assets/asana_tutorial_gif.gif';
import { FormField } from '@components/atoms/form';
import { ComboBoxFormField } from '@components/atoms/form/combobox';
import { migrationReportFeedbackContent } from '@components/molecules/migration-progress/helper';

export type Option = {
  label: string;
  value: string | number;
};
export interface IFormSyncGroupAsana {
  closeAction: React.Dispatch<React.SetStateAction<ModalType | null>>;
  options: Array<Option>;
}

function FormSyncGroupAsana({ closeAction, options }: IFormSyncGroupAsana) {
  const { createTaskHandler, mutationLoading } = useMigrationReport();
  const { setNotificationResponse } = useNotification();

  const methods = useForm<SyncMutationReportsInput>({
    defaultValues: {
      thirdPartyId: '',
      groupId: '',
    },
  });

  const onSubmit: SubmitHandler<SyncMutationReportsInput> = async ({ groupId, thirdPartyId }) => {
    setNotificationResponse(migrationReportFeedbackContent.CREATING_TASK);
    try {
      const response = await createTaskHandler({ groupId, thirdPartyId });

      const message = response
        ? migrationReportFeedbackContent.CREATE_TASK_SUCCESS
        : migrationReportFeedbackContent.ERROR;

      closeAction(null);
      setNotificationResponse(message);
    } catch (error) {
      closeAction(null);
      setNotificationResponse(migrationReportFeedbackContent.ERROR);
    }
  };

  return (
    <>
      <Accordion label="Onde encontrar esse número?">
        <div className="flex justify-center">
          <img src={video} alt="gif-asana-id" />
        </div>
      </Accordion>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-4 mt-4">
          <ComboBoxFormField<SyncMutationReportsInput>
            field="groupId"
            id="groupId"
            label="Grupo Comercial"
            inputOptions={options}
            options={{ required: { value: true, message: 'Campo obrigatório' } }}
          />
          <FormField<SyncMutationReportsInput>
            label="Task Id"
            field="thirdPartyId"
            id="thirdPartyId"
            options={{ required: { value: true, message: 'Campo obrigatório' } }}
          />

          <div className="flex gap-5">
            <Button label="Vincular" type="submit" loading={mutationLoading} />
            <Button label="Cancelar" kind="secondary" onClick={() => closeAction(null)} />
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default FormSyncGroupAsana;

import React from 'react';
import { capitalize } from '@utils/text';
import { MonthTranslationMapper } from '@utils/translators/proposal';
import { useFormContext } from 'react-hook-form';
import { UnitPayload } from '@contexts/unit';
import { NumericFormField } from '@components/atoms/form';

interface HistoryMonthRowProps {
  month: keyof UnitPayload['history'];
}
const HistoryMonthRow: React.FC<HistoryMonthRowProps> = ({ month }) => {
  const { watch } = useFormContext<UnitPayload>();
  const unitWatch = watch();
  const powerGenerator = unitWatch.powerGenerator;

  return (
    <div className={'grid grid-cols-9 gap-x-5 items-center'}>
      <p className="text-paragraph-medium">{capitalize(MonthTranslationMapper[month])}</p>
      <div className={`grid col-span-8 ${powerGenerator ? 'grid-cols-3' : 'grid-cols-2'} gap-x-5 items-center`}>
        {powerGenerator && (
          <NumericFormField<UnitPayload>
            label={''}
            id={`unit-history-${month}-generator`}
            field={`history.${month}.generator`}
            formatProps={{
              placeholder: 'kWh',
              mask: '_',
              allowNegative: false,
              fixedDecimalScale: false,
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 10,
              suffix: ' kWh',
            }}
          />
        )}
        <NumericFormField<UnitPayload>
          label={''}
          id={`unit-history-${month}-peak`}
          field={`history.${month}.peak`}
          formatProps={{
            placeholder: 'kWh',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 10,
            suffix: ' kWh',
          }}
        />
        <NumericFormField<UnitPayload>
          label={''}
          id={`unit-history-${month}-offPeak`}
          field={`history.${month}.offPeak`}
          formatProps={{
            placeholder: 'kWh',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 10,
            suffix: ' kWh',
          }}
        />
      </div>
    </div>
  );
};

const UnitHistoryInputTable: React.FC = () => {
  const { watch } = useFormContext<UnitPayload>();
  const powerGenerator = watch('powerGenerator');
  return (
    <div className="flex flex-col gap-5">
      <div className={`grid grid-cols-9 gap-x-5`}>
        <p className="text-paragraph-medium">Mês</p>
        <div className={`grid col-span-8 ${powerGenerator ? 'grid-cols-3' : 'grid-cols-2'} gap-x-5 items-center`}>
          {powerGenerator && <p className="text-paragraph-medium">Gerador</p>}
          <p className="text-paragraph-medium">Energia Ponta</p>
          <p className="text-paragraph-medium">Energia Fora Ponta</p>
        </div>
      </div>
      <HistoryMonthRow month="january" />
      <HistoryMonthRow month="february" />
      <HistoryMonthRow month="march" />
      <HistoryMonthRow month="april" />
      <HistoryMonthRow month="may" />
      <HistoryMonthRow month="june" />
      <HistoryMonthRow month="july" />
      <HistoryMonthRow month="august" />
      <HistoryMonthRow month="september" />
      <HistoryMonthRow month="october" />
      <HistoryMonthRow month="november" />
      <HistoryMonthRow month="december" />
    </div>
  );
};

export default UnitHistoryInputTable;

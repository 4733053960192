import React from 'react';

import Skeleton from 'react-loading-skeleton';
import Section from '@components/atoms/economy-report/section';
import ReportTables from './unit-report-table';
import { conventionalMarketTablesGenerator } from './report-helpers-method';
import { ConventionalMarketTableData } from '@contexts/unit-economy-report/types';

interface ConventionalMktSecitonProps {
  conventionalMarketTableData: ConventionalMarketTableData;
  isFetchingData: boolean;
}

const SectionTableSkeleton: React.FC = () => (
  <div>
    <Skeleton height="40rem" />
  </div>
);

const ConventionalMarketSection = ({
  isFetchingData,
  conventionalMarketTableData,
}: ConventionalMktSecitonProps): JSX.Element => {
  const tables = conventionalMarketTablesGenerator(conventionalMarketTableData);
  return (
    <div className={`mt-10 mb-12 xl:mt-0 ${isFetchingData && 'xl:grow'}2xl:grow`}>
      <Section sectionNumber="02" title="Simulação Mercado Cativo" isLoading={isFetchingData} />
      {isFetchingData ? (
        <SectionTableSkeleton />
      ) : (
        <ReportTables
          type="ACR"
          tables={tables}
          totals={{
            totalValueWithoutTax: conventionalMarketTableData?.totalValueWithoutTax,
            totalValue: conventionalMarketTableData?.totalValue,
          }}
        />
      )}
    </div>
  );
};

export default ConventionalMarketSection;

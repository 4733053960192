import React, { useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

import { Button, Modal } from '@clarke-energia/foton';

import { EconomyNotificationFeedbackContents } from '@utils/constants/common';
import { EconomyReportPayload, GroupEconomyReportPayload } from '@contexts/economy-reports/types';
import useEconomyReportForm from '@hooks/use-economy-report-form';
import useGroupEconomyReports from '@hooks/use-economy-report';

import FormConfirmation from '../form-confirmation';

interface GroupEconomyReportFormProps {
  disabledBtn: boolean;
  groupId: string;
  date: string;
  dataToUpdate: EconomyReportPayload | GroupEconomyReportPayload | null;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateGroupEconomyReport = ({
  groupId,
  date,
  disabledBtn,
  dataToUpdate,
  setShowForm,
}: GroupEconomyReportFormProps): JSX.Element => {
  const { clearErrors } = useFormContext();
  const { upsertGroupEconomyReportHandler } = useEconomyReportForm();
  const { setMessageNotificationFeedback, setOpenNotificationFeedback } = useGroupEconomyReports();
  const [showDialog, setShowDialog] = useState(false);

  const messaReportNotificationFeedback = dataToUpdate
    ? EconomyNotificationFeedbackContents.UPDATING_GROUP_ECONOMY_REPORT
    : EconomyNotificationFeedbackContents.CREATING_GROUP_ECONOMY_REPORT;

  const handleGroupReportSubmit = async () => {
    return upsertGroupEconomyReportHandler({
      ...(dataToUpdate && { id: dataToUpdate.id }),
      groupId: dataToUpdate ? dataToUpdate.groupId : groupId,
      date: moment(dataToUpdate ? dataToUpdate.date : date, 'MMYYYY').format('YYYY-MM-DD'),
    })
      .then((res) => {
        window.open(`/relatorio-grupo/${res.data.createGroupEconomyReport.id}`, '_target');
        setOpenNotificationFeedback(true);
        setMessageNotificationFeedback(messaReportNotificationFeedback);
        setShowDialog(false);
        setShowForm(false);
      })
      .catch(() => {
        throw new Error('Ocorreu um erro ao criar Relatorio.');
      });
  };

  return (
    <>
      <div>
        <Button
          disabled={disabledBtn}
          kind="secondary"
          label="Gerar relatório do grupo comercial"
          type="button"
          onClick={() => {
            if (groupId && date) clearErrors();
            setShowDialog(true);
          }}
        />
      </div>
      <Modal
        title="Gerar Relatório para grupo comercial?"
        description="Confirme as informações inseridas antes de gerar o relatório"
        open={showDialog}
        setOpen={() => setShowDialog(!showDialog)}
      >
        <FormConfirmation
          text="Gerar Relatório"
          onConfirmation={() => handleGroupReportSubmit()}
          onCancel={() => setShowDialog(false)}
        />
      </Modal>
    </>
  );
};

export default CreateGroupEconomyReport;

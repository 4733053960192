import { useContextSelector } from 'use-context-selector';
import { EnergyAuditsConsumptionContext, EnergyAuditConsumptionContextType } from '@contexts/energy-audit-consumptions';

const useEnergyAuditConsumption = (): EnergyAuditConsumptionContextType => {
  const getMonthlyControlMeasurementByUnitIdHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.getMonthlyControlMeasurementByUnitIdHandler,
  );
  const getUnitConsumptionMeasuresPerHourHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.getUnitConsumptionMeasuresPerHourHandler,
  );

  const getAllControlMeasurementByUnitIdHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.getAllControlMeasurementByUnitIdHandler,
  );

  const monthlyControlMeasurementByUnitId = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.monthlyControlMeasurementByUnitId,
  );
  const setMonthlyControlMeasurementByUnitId = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.setMonthlyControlMeasurementByUnitId,
  );

  const updateMeasurementAutomaticallyHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.updateMeasurementAutomaticallyHandler,
  );

  const setConsolidatedMeasurementDocId = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.setConsolidatedMeasurementDocId,
  );
  const consolidatedMeasurementById = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.consolidatedMeasurementById,
  );
  const updateMeasurementManuallyHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.updateMeasurementManuallyHandler,
  );
  const loading = useContextSelector(EnergyAuditsConsumptionContext, (EnergyAudits) => EnergyAudits.loading);
  const mutationLoading = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.mutationLoading,
  );

  const unitConsumptionMeasuresPerHour = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.unitConsumptionMeasuresPerHour,
  );
  const updateDailyConsumptionOfUnitsManuallyHandler = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.updateDailyConsumptionOfUnitsManuallyHandler,
  );

  const handleDownloadExcel = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.handleDownloadExcel,
  );

  const consumptionsPeriods = useContextSelector(
    EnergyAuditsConsumptionContext,
    (EnergyAudits) => EnergyAudits.consumptionsPeriods,
  );

  return {
    getAllControlMeasurementByUnitIdHandler,
    updateMeasurementManuallyHandler,
    getMonthlyControlMeasurementByUnitIdHandler,
    getUnitConsumptionMeasuresPerHourHandler,
    updateMeasurementAutomaticallyHandler,
    updateDailyConsumptionOfUnitsManuallyHandler,
    setConsolidatedMeasurementDocId,
    consolidatedMeasurementById,
    monthlyControlMeasurementByUnitId,
    setMonthlyControlMeasurementByUnitId,
    loading,
    mutationLoading,
    unitConsumptionMeasuresPerHour,
    handleDownloadExcel,
    consumptionsPeriods,
  };
};

export default useEnergyAuditConsumption;

import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Button } from '@clarke-energia/foton';

import { COMMERCIAL_GROUP_PATH, UNITS_PATH } from '@routers/constants';
import { StateSymbols } from '@utils/constants/common';
import { formatDateFromString } from '@utils/dayjs';
import { SubmarketEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';

import { EMPTY_UNIT, UnitDocTypeEnum, UnitPayload } from '@contexts/unit';
import { useEdcs } from '@hooks/edcs';
import { useCreateUnitConsumptionHistory, useEditUnitConsumptionHistory, useUnits } from '@hooks/use-units';

import {
  FormField,
  FormSection,
  GroupSelectInputFormField,
  NumericFormField,
  RadioFormField,
  SelectFormField,
} from '@components/atoms/form';
import UnitHistoryInputTable from '@components/molecules/form/form-upsert-unit/unit-consumption-history';

import { checkHistoryToUpdateIsEmpty } from './unit-history-helper-methods';
import { unitPayloadSchema } from '@contexts/unit/schema';
import { zodResolver } from '@hookform/resolvers/zod';

interface IForm {
  dataToUpdate: UnitPayload | null;
  onFormSuccess: (failToUpsertUnit: boolean) => void;
  onFormFail: () => void;
}

function FormUpsertUnit({ onFormSuccess, onFormFail, dataToUpdate }: IForm) {
  const navigate = useNavigate();
  const methods = useForm<UnitPayload>({
    resolver: zodResolver(unitPayloadSchema),
    defaultValues: dataToUpdate
      ? {
          ...dataToUpdate,
          docType: dataToUpdate.docType === 2 ? 'CNPJ' : 'CPF',
          migrationDate:
            dataToUpdate.migrationDate && formatDateFromString(dataToUpdate.migrationDate, 'YYYY-MM-DD', 'DDMMYYYY'),
          expectedMigrationDate:
            dataToUpdate.expectedMigrationDate &&
            formatDateFromString(dataToUpdate.expectedMigrationDate, 'YYYY-MM-DD', 'DDMMYYYY'),

          energyType: 'CONVENTIONAL',
        }
      : EMPTY_UNIT,
  });

  const checkHistoryToUpdate = dataToUpdate && checkHistoryToUpdateIsEmpty(Object.values(dataToUpdate?.history));

  const docType = methods.watch('docType');
  const tariffMode = methods.watch('tariffModality');

  const docOptions = [
    { value: UnitDocTypeEnum.CNPJ, label: 'CNPJ' },
    { value: UnitDocTypeEnum.CPF, label: 'CPF' },
  ];

  const { createUnitHandler, updateUnitHandler, upsertMeasuringPointHandler, units } = useUnits();
  const [loading, setLoading] = useState(false);

  const createUnitConsumptionHistory = useCreateUnitConsumptionHistory();
  const editUnitConsumptionHistory = useEditUnitConsumptionHistory();
  const edcs = useEdcs();

  const demandBeforeMigrationTooltipContent =
    'Nós aumentamos a demanda de alguns cliente para levá-los ao ACL. Esse campo é para esse cenário, informar qual era a demanda dele antes, no ACR. Caso não tenha sido necessário mudar a sua demanda, insira o mesmo valor da Demanda Contratada.';

  const onSubmit: SubmitHandler<UnitPayload> = async (unit) => {
    try {
      setLoading(true);
      const NOW = new Date();
      const parsedUnit: UnitPayload = {
        ...unit,
        docType: unit.docType === 'CNPJ' ? 2 : 1,
        migrationDate: unit.migrationDate && formatDateFromString(unit.migrationDate, 'DDMMYYYY', 'YYYY-MM-DD'),
        expectedMigrationDate:
          unit.expectedMigrationDate && formatDateFromString(unit.expectedMigrationDate, 'DDMMYYYY', 'YYYY-MM-DD'),
      };
      if (unit.measuringPoint) {
        const measuringPointUnit: UnitPayload = {
          ...unit,
        };
        await upsertMeasuringPointHandler(measuringPointUnit);
      }
      const dataHandler = dataToUpdate ? updateUnitHandler : createUnitHandler;
      const { id: unitId } = await dataHandler(parsedUnit);
      if (unitId)
        checkHistoryToUpdate && dataToUpdate
          ? await editUnitConsumptionHistory(parsedUnit, NOW, parsedUnit.id, parsedUnit.history)
          : await createUnitConsumptionHistory(parsedUnit, NOW, unitId);
      setLoading(false);
      onFormSuccess(!unitId);
    } catch (error) {
      setLoading(false);
      onFormFail();
    }
  };

  const handleCancelClick = () => {
    if (units[0].group) {
      const commercialGroupHubPath = COMMERCIAL_GROUP_PATH.replace(':groupId', units[0].group.id as string);
      navigate(`${commercialGroupHubPath}/${UNITS_PATH}`);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3">
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-4 xl:mt-0">
          <FormSection title="Informações da unidade" subtitle="Preencha os dados referente à unidade." />
          <div className="grid grid-cols-1 col-span-3 gap-x-6 gap-y-6 w-full xl:grid-cols-3">
            <FormField<UnitPayload>
              id="name"
              label="Nome"
              field="name"
              placeholder="Unidade Exemplo"
              options={{ required: { value: true, message: 'O nome é obrigatório' } }}
            />
            <FormField<UnitPayload>
              id="legalName"
              label="Razão Social"
              field="legalName"
              placeholder="Unidade Exemplo LTDA"
              options={{ required: { value: true, message: 'A razão social é obrigatória' } }}
            />
            <GroupSelectInputFormField<UnitPayload>
              firstField="docNumber"
              secondField="docType"
              id="docNumber"
              selectOptions={docOptions}
              label="Documento"
              type="number"
              formatProps={{
                placeholder: docType === UnitDocTypeEnum.CNPJ ? '00.000.000/0000-00' : '000.000.000-00',
                format: docType === UnitDocTypeEnum.CNPJ ? '##.###.###/####-##' : '###.###.###-##',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: undefined,
                thousandSeparator: undefined,
                decimalScale: 0,
                isNumericString: true,
              }}
              options={{
                required: { value: true, message: 'O documento é obrigatório' },
                validate: (value) => {
                  if (typeof value === 'string') {
                    if (docType === UnitDocTypeEnum.CNPJ && value.length !== 14) {
                      return 'O CNPJ deve conter 14 dígitos.';
                    }
                    if (docType === UnitDocTypeEnum.CPF && value.length !== 11) {
                      return 'O CPF deve conter 11 dígitos.';
                    }
                  }

                  return true;
                },
                onChange: (e) => {
                  const value = e.target.value;
                  if (value === 'CNPJ' || value === 'CPF') methods.setValue('docType', value);
                },
              }}
            />
            <SelectFormField<UnitPayload>
              id="unitType"
              label="Classificação"
              field="unitType"
              inputOptions={Object.entries(UnitTypeEnum).map((unitTypeEntry) => ({
                value: unitTypeEntry[0],
                optionLabel: unitTypeEntry[1],
              }))}
              options={{ required: { value: true, message: 'O tipo da unidade é obrigatório' } }}
            />
            <SelectFormField<UnitPayload>
              id="submarket"
              label="Submercado"
              field="submarket"
              inputOptions={Object.entries(SubmarketEnum).map((unitTypeEntry) => ({
                value: unitTypeEntry[0],
                optionLabel: unitTypeEntry[1],
              }))}
              options={{
                required: { value: true, message: 'Submercado é obrigatório.' },
              }}
            />
            <SelectFormField
              id="state"
              label="Estado"
              field="state"
              inputOptions={StateSymbols.map((state) => ({
                value: state,
                optionLabel: state,
              }))}
              transform={{
                output: (value) => {
                  return value;
                },
              }}
            />
            <SelectFormField<UnitPayload>
              id="edc"
              label="Distribuidora"
              field="edc"
              inputOptions={edcs
                .map((edc) => ({
                  value: edc.id,
                  optionLabel: edc.name,
                }))
                .sort((a, b) =>
                  a.optionLabel.localeCompare(b.optionLabel, 'pt-BR', { numeric: true, sensitivity: 'base' }),
                )}
              transform={{
                input: (value) => (isNaN(value as number) || value === 0 ? '' : value.toString()),
                output: (value) => {
                  const output = parseInt(value as string, 10);
                  return isNaN(output) ? 0 : output;
                },
              }}
              options={{
                required: { value: true, message: 'A distribuidora é obrigatória' },
              }}
            />
            <SelectFormField<UnitPayload>
              id="tariffSubgroup"
              label="Subgrupo Tarifário"
              field="tariffSubgroup"
              inputOptions={['A2', 'A3', 'A4', 'AS'].map((tariffSubgroup) => ({
                value: tariffSubgroup,
                optionLabel: tariffSubgroup,
              }))}
              options={{ required: { value: true, message: 'O subgrupo tarifário é obrigatório' } }}
            />
            <SelectFormField<UnitPayload>
              id="tariffModality"
              label="Modalidade Tarifária"
              field="tariffModality"
              inputOptions={Object.entries(TariffModeEnum).map((tariffModeEntry) => ({
                value: tariffModeEntry[0],
                optionLabel: tariffModeEntry[1],
              }))}
              options={{ required: { value: true, message: 'A modalidade tarifária é obrigatória' } }}
            />
            <NumericFormField<UnitPayload>
              id="contractedDemandOffPeak"
              label="Demanda Contratada FP"
              field="contractedDemandOffPeak"
              formatProps={{
                placeholder: 'kW',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                suffix: ' kW',
              }}
              options={{
                valueAsNumber: true,
                required: { value: true, message: 'A Demanda Contratada FP é obrigatória' },
              }}
            />
            {tariffMode === 'BLUE' && (
              <NumericFormField<UnitPayload>
                id="contractedDemandPeak"
                label="Demanda Contratada P"
                field="contractedDemandPeak"
                formatProps={{
                  placeholder: 'kW',
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: 10,
                  suffix: ' kW',
                }}
                options={{
                  valueAsNumber: true,
                }}
              />
            )}
            <NumericFormField<UnitPayload>
              id="contractedDemandOffPeakBeforeMigration"
              label="Demanda FP no ACR"
              field="contractedDemandOffPeakBeforeMigration"
              tooltipContent={demandBeforeMigrationTooltipContent}
              formatProps={{
                placeholder: 'kW',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                suffix: ' kW',
              }}
              options={{
                valueAsNumber: true,
              }}
            />
            {tariffMode === 'BLUE' && (
              <NumericFormField<UnitPayload>
                id="contractedDemandPeakBeforeMigration"
                label="Demanda P no ACR"
                field="contractedDemandPeakBeforeMigration"
                tooltipContent={demandBeforeMigrationTooltipContent}
                formatProps={{
                  placeholder: 'kW',
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: 10,
                  suffix: ' kW',
                }}
                options={{
                  valueAsNumber: true,
                }}
              />
            )}
            <NumericFormField<UnitPayload>
              id="averageInvoiceAmount"
              field="averageInvoiceAmount"
              label="Valor mensal da fatura"
              formatProps={{
                placeholder: 'R$',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              options={{ required: { value: true, message: 'O valor médio da conta de luz é obrigatório' } }}
            />
            <NumericFormField<UnitPayload>
              id="migrationDate"
              field="migrationDate"
              label="Data de Migração"
              formatProps={{
                placeholder: 'dd/mm/aaaa',
                format: '##/##/####',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: undefined,
                thousandSeparator: undefined,
                decimalScale: 0,
                isNumericString: true,
              }}
            />
            <NumericFormField<UnitPayload>
              id="expectedMigrationDate"
              field="expectedMigrationDate"
              label="Data de Migração Prevista"
              formatProps={{
                placeholder: 'dd/mm/aaaa',
                format: '##/##/####',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: undefined,
                thousandSeparator: undefined,
                decimalScale: 0,
                isNumericString: true,
              }}
            />

            <RadioFormField<UnitPayload>
              label="Tem gerador?"
              field="powerGenerator"
              inputOptions={[
                { id: 'true', optionLabel: 'Sim', value: 'true', defaultChecked: dataToUpdate?.powerGenerator },
                { id: 'false', optionLabel: 'Não', value: 'false', defaultChecked: !dataToUpdate?.powerGenerator },
              ]}
              valueAsBoolean
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-11 xl:grid-cols-4">
          <FormSection
            title="Histórico / Projeção de Consumo"
            subtitle="Preencha as informações conforme a conta de luz da unidade."
            tooltipContent="Não é necessário preencher todos os meses. Caso pelo menos 1 ou mais meses sejam preenchidos, o sistema irá preencher o restante com a média dos meses preenchidos."
          />
          <div className="col-span-3">
            <UnitHistoryInputTable />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-10 mb-11 xl:grid-cols-4">
          <FormSection title="Informações extras" subtitle="Dados extras da unidade" />
          <div className="grid grid-cols-1 col-span-3 gap-x-6 gap-y-6 w-full xl:grid-cols-3">
            <FormField<UnitPayload> id="assetCode" label="Código do ativo" field="assetCode" placeholder="" />
            <FormField<UnitPayload>
              id="measuringPoint"
              label="Ponto de Medição"
              field="measuringPoint"
              placeholder=""
            />
          </div>
        </div>
        <div className="flex gap-4 pb-8">
          <Button label={`${dataToUpdate ? 'Atualizar' : 'Criar'} unidade`} kind={'primary'} loading={loading} />
          <Button label={'Cancelar'} kind={'secondary'} type="button" onClick={handleCancelClick} />
        </div>
      </form>
    </FormProvider>
  );
}

export default FormUpsertUnit;

import React from 'react';
import { Button } from '@clarke-energia/foton';

interface IKickOffPageEmpty {
  createKickOffButtonClickHandler: () => void;
}

const KickOffPageEmpty = ({ createKickOffButtonClickHandler }: IKickOffPageEmpty) => (
  <div className="flex flex-col col-span-full gap-6 pb-12 ml-4 w-full md:ml-0 md:w-1/2">
    <div className="text-heading-small md:text-heading-large">O kickoff ainda não foi iniciado.</div>
    <div className="text-paragraph-small md:text-paragraph-medium">
      Clique no botão abaixo para iniciar o processo de kickoff e definir o produto a ser contratado. Lembrando que esse
      processo somente é necessário para os clientes que estão migrando para o mercado livre de energia.
    </div>
    <Button
      kind="primary"
      label="Iniciar Kick Off"
      onClick={createKickOffButtonClickHandler}
      size="small"
      className="w-fit"
    />
  </div>
);

export default KickOffPageEmpty;

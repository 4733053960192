import React from 'react';
import { Button } from '@clarke-energia/foton';
import moment from 'moment';

import { FinancialEvent, FinancialEventType } from '@contexts/financial-events';
import { FinancialEventTypeTranslated, formatMonthAndYearDate } from '@utils/text';
import useFinancialEvents from '@hooks/use-financial-events';

import FinancialEventSendFormUpdatingTargetUsers from '@components/atoms/financial-events/list/send-form-updating-target-users';

interface IFormConfirmationSendFinancialEventsByIdsList {
  onCancel: () => void;
  onConfirmation: () => void;
  financialEvent: FinancialEvent;
}

const FormConfirmationSendFinancialEventsByIdsList: React.FC<IFormConfirmationSendFinancialEventsByIdsList> = ({
  onConfirmation,
  onCancel,
  financialEvent,
}: IFormConfirmationSendFinancialEventsByIdsList) => {
  const { loading, updateFinancialEventTargetUsersHandler } = useFinancialEvents();
  const [updatedFinancialEvent, setUpdatedFinancialEvent] = React.useState<FinancialEvent>(financialEvent);

  const getUpdatedFinancialEvent = async () => {
    const response = await updateFinancialEventTargetUsersHandler(financialEvent.id as string);
    const updatedFinancialEvent = response.data.updateFinancialEventTargetUsers as FinancialEvent;
    setUpdatedFinancialEvent(updatedFinancialEvent);
  };

  React.useEffect(() => {
    financialEvent.id && getUpdatedFinancialEvent();
  }, [financialEvent]);

  if (loading) {
    return <FinancialEventSendFormUpdatingTargetUsers />;
  }

  return (
    <div>
      <div className="py-4 border-y-[1px] border-neutral-30 text-paragraph-medium">
        <div className="flex gap-2 font-bold">
          <span>Evento:</span>
          <span>{FinancialEventTypeTranslated[updatedFinancialEvent.type as FinancialEventType]}</span>
        </div>
        <div className="flex gap-2">
          <span>Referência:</span>
          <span>{formatMonthAndYearDate(updatedFinancialEvent.referencePeriod as string)}</span>
        </div>
        <div className="flex gap-2">
          <span>Validade:</span>
          <span>{moment(updatedFinancialEvent.paymentDeadline).format('DD/MM/YYYY')}</span>
        </div>
        <div className="flex gap-2">
          <span>Grupo Comercial:</span>
          <span>{updatedFinancialEvent.commercialGroup?.name}</span>
        </div>
        <div className="flex flex-col">
          <span>Usuários notificados:</span>
          {updatedFinancialEvent.targetUsers?.map((targetUser, index) => (
            <span key={index} className="ml-2">
              • {targetUser.name}: {targetUser.email}
            </span>
          ))}
        </div>
      </div>
      <div className="flex gap-4 justify-between pt-10 -mb-8">
        <Button kind="primary" label={'Enviar evento'} className="w-full" onClick={onConfirmation} loading={loading} />
        <Button kind="secondary" label="Cancelar" onClick={onCancel} className="w-full" />
      </div>
    </div>
  );
};

export default FormConfirmationSendFinancialEventsByIdsList;

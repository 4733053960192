import config from '@src/config';
import { amplitudeInit, amplitudeTrackEvent } from './amplitude';

export const initEventTrackingSystem = () => {
  return amplitudeInit(true, true);
};

export const trackEvent = async (eventLabel: string, eventProperties?: Record<string, string | number>) => {
  if (config.AMPLITUDE_INITIALIZED) {
    return amplitudeTrackEvent(eventLabel, eventProperties);
  }
};

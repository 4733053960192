import { formatAsPercent, formatDecimal, formatMonthAndYearDate, formatNumber } from '@utils/text';
import { TariffModeEnum } from '@utils/translators/proposal';
import numeral from '@utils/numeral';
import { UnitEconomyReportDataResponse, UnitReportParsed } from './types';

const optionDecimal = { style: 'decimal', useGrouping: true, maximumFractionDigits: 3 };

export const unitReportDataParsed = ({
  date,
  unit: { name: unitName, group, edc, tariffModality, tariffSubgroup, contractedDemandOffPeak, contractedDemandPeak },
  currentSavings,
  savingsPercent,
  energyTotalConsumption,
  energyTotalCost,
  trader,
  freeMarket: { energyDistribution, energyProvided, others, totalValue, totalValueWithoutTax },
  conventionalMarket,
  devecDeclaration,
}: UnitEconomyReportDataResponse): UnitReportParsed => ({
  date: formatMonthAndYearDate(date) || '',
  groupName: group?.name,
  unitName,
  edcName: edc?.name,
  cityAndState: edc?.state,
  subgroup: tariffSubgroup,
  tariffModality: TariffModeEnum[tariffModality],
  contractedDemandPeak: contractedDemandPeak ? `${contractedDemandPeak} kW` : '-',
  contractedDemandOffPeak: contractedDemandOffPeak ? `${contractedDemandOffPeak} kW` : '-',
  currentSavings: numeral(currentSavings).format('$ 0,0.00'),
  savingsPercent: savingsPercent ? `${savingsPercent.toFixed(2).replace('.', ',')}%` : '',
  energyTotalCost: numeral(energyTotalCost || 0).format('$ 0,0.00'),
  energyTotalConsumption: energyTotalConsumption ? `${formatDecimal(energyTotalConsumption, 3)} MWh` : `${0} MWh`,

  freeMarketTableData: {
    energyDistribution: {
      edcName: edc.name,
      acrBilledDemandOffPeakAmount: formatDecimal(energyDistribution.acrBilledDemandOffPeakAmount, 2),
      acrBilledDemandOffPeakCost: formatDecimal(energyDistribution.acrBilledDemandOffPeakCost, 2),
      acrBilledDemandOffPeakTotal: numeral(energyDistribution.acrBilledDemandOffPeakTotal).format('$ 0,0.00'),
      acrBilledDemandPeakAmount: formatDecimal(energyDistribution.acrBilledDemandPeakAmount, 2),
      acrBilledDemandPeakCost: formatDecimal(energyDistribution.acrBilledDemandPeakCost, 2),
      acrBilledDemandPeakTotal: numeral(energyDistribution.acrBilledDemandPeakTotal).format('$ 0,0.00'),
      acrTeOffPeakAmount: formatDecimal(energyDistribution.acrTeOffPeakAmount, 2),
      acrTeOffPeakCost: formatDecimal(energyDistribution.acrTeOffPeakCost, 2),
      acrTeOffPeakTotal: numeral(energyDistribution.acrTeOffPeakTotal).format('$ 0,0.00'),
      acrTePeakAmount: formatDecimal(energyDistribution.acrTePeakAmount, 2),
      acrTePeakCost: formatDecimal(energyDistribution.acrTePeakCost, 2),
      acrTePeakTotal: numeral(energyDistribution.acrTePeakTotal).format('$ 0,0.00'),
      billedDemandOffPeakAmount: formatDecimal(energyDistribution.billedDemandOffPeakAmount, 2),
      billedDemandOffPeakCost: formatDecimal(energyDistribution.billedDemandOffPeakCost, 2),
      billedDemandOffPeakTotal: numeral(energyDistribution.billedDemandOffPeakTotal).format('$ 0,0.00'),
      billedDemandPeakAmount: formatDecimal(energyDistribution.billedDemandPeakAmount, 2),
      billedDemandPeakCost: formatDecimal(energyDistribution.billedDemandPeakCost, 2),
      billedDemandPeakTotal: numeral(energyDistribution.billedDemandPeakTotal).format('$ 0,0.00'),
      tusdPeakAmount: formatDecimal(energyDistribution.tusdPeakAmount, 2),
      tusdPeakCost: formatDecimal(energyDistribution.tusdPeakCost, 2),
      tusdPeakTotal: numeral(energyDistribution.tusdPeakTotal).format('$ 0,0.00'),
      tusdOffPeakAmount: formatDecimal(energyDistribution.tusdOffPeakAmount, 2),
      tusdOffPeakCost: formatDecimal(energyDistribution.tusdOffPeakCost, 2),
      tusdOffPeakTotal: numeral(energyDistribution.tusdOffPeakTotal).format('$ 0,0.00'),
      covidTariffAmount: formatDecimal(energyDistribution.covidTariffAmount, 2),
      covidTariffCost: formatDecimal(energyDistribution.covidTariffCost, 2),
      covidTariffTotal: numeral(energyDistribution.covidTariffTotal).format('$ 0,0.00'),
      overtakingAmount: formatDecimal(energyDistribution.overtakingAmount || 0, 2),
      overtakingCost: formatDecimal(energyDistribution.overtakingCost || 0, 2),
      overtakingTotal: numeral(energyDistribution.overtakingTotal).format('$ 0,0.00'),
      reactivesTotal: energyDistribution.reactivesTotal
        ? numeral(energyDistribution.reactivesTotal).format('$ 0,0.00')
        : '-',
      taxesTotal: numeral(energyDistribution.taxesTotal).format('$ 0,0.00'),
      others: numeral(energyDistribution.others).format('$ 0,0.00'),
      totalValue: numeral(energyDistribution.totalValue).format('$ 0,0.00'),
    },
    energyProvided: {
      traderName: trader.name,
      devec: devecDeclaration,
      energyProvidedAmount: formatNumber(energyProvided.energyProvidedAmount, optionDecimal),
      energyProvidedCost: numeral(energyProvided.energyProvidedCost).format('0.00'),
      energyProvidedTotal: numeral(energyProvided.energyProvidedTotal).format('$ 0,0.00'),
      energyProvidedAdjustmentValue: numeral(energyProvided.energyProvidedAdjustmentValue).format('$ 0,0.00'),
      highlightPisCofinsCost: formatAsPercent(energyProvided.highlightPisCofinsCost),
      highlightPisCofinsTotal: numeral(energyProvided.highlightPisCofinsTotal).format('$ 0,0.00'),
      icmsCost: energyProvided.icmsCost ? formatAsPercent(energyProvided.icmsCost) : '-',
      icmsTotal: numeral(energyProvided.icmsTotal).format('$ 0,0.00'),
      totalValue: numeral(energyProvided.totalValue).format('$ 0,0.00'),
    },
    others: {
      clarkeManagementCost: numeral(others.clarkeManagementCost).format('$ 0,0.00'),
      cceeCost: numeral(others.cceeCost).format('$ 0,0.00'),
      eerCost: numeral(others.eerCost).format('$ 0,0.00'),
      ercapCost: numeral(others.ercapCost).format('$ 0,0.00'),
      essCost: numeral(others.essCost).format('$ 0,0.00'),
      totalValue: numeral(others.totalValue).format('$ 0,0.00'),
    },
    totalValue: numeral(totalValue).format('$ 0,0.00'),
    totalValueWithoutTax: numeral(totalValueWithoutTax).format('$ 0,0.00'),
  },

  conventionalMarketTableData: {
    edcName: edc.name,
    billedDemandOffPeakAmount: formatDecimal(conventionalMarket.billedDemandOffPeakAmount, 2),
    billedDemandOffPeakCost: formatDecimal(conventionalMarket.billedDemandOffPeakCost, 2),
    billedDemandOffPeakTotal: numeral(conventionalMarket.billedDemandOffPeakTotal).format('$ 0,0.00'),
    billedDemandPeakAmount: formatDecimal(conventionalMarket.billedDemandPeakAmount, 2),
    billedDemandPeakCost: formatDecimal(conventionalMarket.billedDemandPeakCost, 2),
    billedDemandPeakTotal: numeral(conventionalMarket.billedDemandPeakTotal).format('$ 0,0.00'),
    tusdPeakAmount: formatDecimal(conventionalMarket.tusdPeakAmount, 2),
    tusdPeakCost: formatDecimal(conventionalMarket.tusdPeakCost, 2),
    tusdPeakTotal: numeral(conventionalMarket.tusdPeakTotal).format('$ 0,0.00'),
    tusdOffPeakAmount: formatDecimal(conventionalMarket.tusdOffPeakAmount, 2),
    tusdOffPeakCost: formatDecimal(conventionalMarket.tusdOffPeakCost, 2),
    tusdOffPeakTotal: numeral(conventionalMarket.tusdOffPeakTotal).format('$ 0,0.00'),
    tePeakAmount: formatDecimal(conventionalMarket.tePeakAmount, 2),
    tePeakCost: formatDecimal(conventionalMarket.tePeakCost, 2),
    tePeakTotal: numeral(conventionalMarket.tePeakTotal).format('$ 0,0.00'),
    teOffPeakAmount: formatDecimal(conventionalMarket.teOffPeakAmount, 2),
    teOffPeakCost: formatDecimal(conventionalMarket.teOffPeakCost, 2),
    teOffPeakTotal: numeral(conventionalMarket.teOffPeakTotal).format('$ 0,0.00'),
    overtakingAmount: formatDecimal(conventionalMarket.overtakingAmount || 0, 2),
    overtakingCost: formatDecimal(conventionalMarket.overtakingCost || 0, 2),
    overtakingTotal: numeral(conventionalMarket.overtakingTotal).format('$ 0,0.00'),
    reactivesTotal: conventionalMarket.reactivesTotal
      ? numeral(conventionalMarket.reactivesTotal).format('$ 0,0.00')
      : '-',
    tariffFlagCost: formatDecimal(conventionalMarket.tariffFlagCost, 2),
    tariffFlagTotal: numeral(conventionalMarket.tariffFlagTotal).format('$ 0,0.00'),
    taxesTotal: numeral(conventionalMarket.taxesTotal).format('$ 0,0.00'),
    others: numeral(conventionalMarket.others).format('$ 0,0.00'),
    totalValueWithoutTax: numeral(conventionalMarket.totalValueWithoutTax).format('$ 0,0.00'),
    totalValue: numeral(conventionalMarket.totalValue).format('$ 0,0.00'),
  },
});

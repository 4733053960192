import { useFormContext } from 'react-hook-form';
import { SelectFormField } from '@components/atoms/form';
import { CheckboxFormFieldContract } from '@components/atoms/form/fields/checkbox-form-field-contract';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractSectionFieldsContainer from '@components/atoms/form/contract/contract-section-fields-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { IManagementContractPayload, IManagementContractProduct } from '@contexts/management-contract/types';
interface IContractFormContractTypeSection {
  contractProduct: IManagementContractProduct;
}

export enum ManagementeContractTypeEnum {
  MANAGEMENT_WHOLESALE = 'Atacadista',
  MANAGEMENT_RETAILER = 'Varejista',
  RETAIL_RETAILER = 'Varejista',
}

const ContractFormContractTypeSection = ({ contractProduct }: IContractFormContractTypeSection): JSX.Element => {
  const contractOptions: { value: string; optionLabel: string }[] = [];
  if (contractProduct === 'PRODUCT_ONE') {
    contractOptions.push(
      { value: 'MANAGEMENT_WHOLESALE', optionLabel: ManagementeContractTypeEnum.MANAGEMENT_WHOLESALE },
      { value: 'MANAGEMENT_RETAILER', optionLabel: ManagementeContractTypeEnum.MANAGEMENT_RETAILER },
    );
  } else if (contractProduct === 'PRODUCT_TWO') {
    contractOptions.push({ value: 'RETAIL_RETAILER', optionLabel: ManagementeContractTypeEnum.RETAIL_RETAILER });
  }
  const { watch } = useFormContext<IManagementContractPayload>();
  const thirtyDayTrial = watch('thirtyDayTrial');

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection title={'Tipos de Contratos'} subtitle={'Dados sobre a gestão da Clarke.'} />
        <ContractSectionFieldsContainer>
          <div className="grid grid-cols-2 col-span-2 gap-6 items-baseline">
            <SelectFormField<IManagementContractPayload>
              id="type"
              field="type"
              label="Tipo de contrato"
              inputOptions={contractOptions}
              options={{ required: { value: true, message: 'Tipo de contrato é obrigatório' } }}
            />
            <CheckboxFormFieldContract<IManagementContractPayload>
              inputOptions={[
                {
                  id: 'thirtyDayTrial',
                  optionLabel: '',
                  value: 'thirtyDayTrial',
                  defaultChecked: thirtyDayTrial && Object.values(thirtyDayTrial) ? true : false,
                },
              ]}
              field="thirtyDayTrial"
              label="Teste de 30 dias"
            />
          </div>
        </ContractSectionFieldsContainer>
      </>
    </ContractSectionContainer>
  );
};

export default ContractFormContractTypeSection;

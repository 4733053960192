import { gql } from '@apollo/client';

export const MUTATION_UPDATE_MEASUREMENT_AUTOMATICALLY = gql`
  mutation UpdateMeasurementAutomatically($input: ConsolidatedMeasurementInput!) {
    updateMeasurementAutomatically(input: $input)
  }
`;

export const MUTATION_UPDATE_DAILY_CONSUMPTION_OF_UNITS_MANUALLY = gql`
  mutation UpdateDailyConsumptionOfUnitsManually($input: ConsolidatedMeasurementInput!) {
    updateDailyConsumptionOfUnitsManually(input: $input)
  }
`;

export const MUTATION_UPDATE_MEASUREMENT_MANUALLY = gql`
  mutation UpdateTimeManually($input: UpdateTimeManuallyInput!) {
    updateTimeManually(input: $input) {
      id
      profileCcee
      measuringPoint
      weekday
      monthYear
      datetimeMeasurement {
        datetime
        subType
        status
        activeGeneration
        reactiveGeneration
        activeConsumption
        reactiveConsumption
        source
      }
      createdAt
    }
  }
`;

export const MUTATION_PREPARE_ENERGY_AUDIT_TO_BE_SENT = gql`
  mutation PrepareEnergyAuditToBeSent($input: PrepareAuditInput!) {
    prepareAuditToBeSent(input: $input) {
      errors
    }
  }
`;

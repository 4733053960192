import { useContextSelector } from 'use-context-selector';
import { KickOffContext } from '@contexts/kick-off';

const useKickOff = () => {
  const kickOff = useContextSelector(KickOffContext, (kickOff) => kickOff.kickOff);
  const loading = useContextSelector(KickOffContext, (kickOff) => kickOff.loading);
  const setGroupId = useContextSelector(KickOffContext, (kickOff) => kickOff.setGroupId);

  const getKickOffByGroupIDHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.getKickOffByGroupIDHandler,
  );
  const createKickOffByGroupIDHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.createKickOffByGroupIDHandler,
  );
  const updateKickOffQuizAvailabilityHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.updateKickOffQuizAvailabilityHandler,
  );
  const sendKickOffQuizEmailHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.sendKickOffQuizEmailHandler,
  );
  const updateKickOffDiagnosisHandler = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.updateKickOffDiagnosisHandler,
  );

  const notificationFeedBackContent = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.notificationFeedBackContent,
  );
  const setNotificationFeedBackContent = useContextSelector(
    KickOffContext,
    (kickOff) => kickOff.setNotificationFeedBackContent,
  );

  return {
    loading,
    kickOff,
    setGroupId,
    getKickOffByGroupIDHandler,
    createKickOffByGroupIDHandler,
    updateKickOffQuizAvailabilityHandler,
    sendKickOffQuizEmailHandler,
    updateKickOffDiagnosisHandler,
    notificationFeedBackContent,
    setNotificationFeedBackContent,
  };
};

export default useKickOff;

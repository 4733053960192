import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { LayoutProps } from '@clarke-energia/foton';
import { ContractFormFooter } from '@components/molecules/contracts/contrat-form-footer';
import { IManagementContractPayload, IManagementContractProduct } from '@contexts/management-contract/types';
import ContractFormCore from './form-core';
import GeneratedContractModal from '@components/molecules/contracts/sections/generated-contract-modal';

interface IContractContent extends LayoutProps {
  formRef: React.RefObject<HTMLFormElement>;
  formController: UseFormReturn<IManagementContractPayload, any>;
  contractProduct: IManagementContractProduct;
}

const ContractContent = ({ formRef, formController, contractProduct }: IContractContent) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  return (
    <div className="flex flex-col col-span-full p-7 w-full lg:w-3/4">
      <FormProvider {...formController}>
        <ContractFormCore
          {...{
            formRef,
            contractProduct,
          }}
        />
        <ContractFormFooter setIsModalOpen={setIsModalOpen} />
        {isModalOpen && <GeneratedContractModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
      </FormProvider>
    </div>
  );
};

export default ContractContent;

import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button } from '@clarke-energia/foton';

import {
  IKickOffDiagnosis,
  IKickOffSuggestedProduct,
  KickOffTraderType,
  KickOffWarrantyType,
} from '@contexts/kick-off/types';
import useKickOff from '@hooks/use-kick-off';

import { isOnMobileScreen } from '@utils/graphics';

import { NumericFormField, SelectFormField } from '@components/atoms/form';

import './style.css';

interface IForm {
  dataToUpdate?: IKickOffSuggestedProduct;
  onFormSuccess: () => void;
  onFormFail: () => void;
  onFormCancel: () => void;
}

function FormUpdateKickOffDiagnosis({ onFormSuccess, onFormFail, onFormCancel, dataToUpdate }: IForm) {
  const parseDataToUpdate = (): IKickOffSuggestedProduct | undefined =>
    dataToUpdate && {
      ...dataToUpdate,
      upperFlexibility: dataToUpdate.upperFlexibility ? dataToUpdate.upperFlexibility * 100 : undefined,
      lowerFlexibility: dataToUpdate.lowerFlexibility ? dataToUpdate.lowerFlexibility * 100 : undefined,
    };
  const methods = useForm<IKickOffSuggestedProduct>({
    defaultValues: parseDataToUpdate(),
  });
  const { kickOff, loading, getKickOffByGroupIDHandler, updateKickOffDiagnosisHandler } = useKickOff();

  const parseDiagnosisData = (values: IKickOffSuggestedProduct): IKickOffDiagnosis => ({
    profileType: kickOff?.diagnosis?.profileType,
    managementType: kickOff?.diagnosis?.managementType,
    suggestedProduct: {
      productType: values.productType,
      traderType: values.traderType,
      warrantyType: values.warrantyType,
      contractTermInYears: values.contractTermInYears,
      lowerFlexibility: (Number(values.lowerFlexibility) / 100) * -1,
      upperFlexibility: Number(values.upperFlexibility) / 100,
    },
  });

  const onSubmit: SubmitHandler<IKickOffSuggestedProduct> = async (values) => {
    const diagnosisData: IKickOffDiagnosis = parseDiagnosisData(values);
    updateKickOffDiagnosisHandler(diagnosisData)
      .then((response) => {
        if (response.data?.updateKickOffDiagnosis.id) {
          getKickOffByGroupIDHandler();
          onFormSuccess();
        } else onFormFail();
      })
      .catch(() => {
        onFormFail();
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        <SelectFormField<IKickOffSuggestedProduct>
          id="traderType"
          field="traderType"
          label="Modalidade de contratação"
          inputOptions={Object.entries(KickOffTraderType).map((traderTypeEntry) => ({
            value: traderTypeEntry[0],
            optionLabel: traderTypeEntry[1],
          }))}
        />
        <NumericFormField<IKickOffSuggestedProduct>
          id="upperFlexibility"
          field="upperFlexibility"
          label="Flexibilidade máxima"
          formatProps={{
            placeholder: '10%',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalScale: 0,
            suffix: '%',
            prefix: '+',
          }}
        />
        <NumericFormField<IKickOffSuggestedProduct>
          id="lowerFlexibility"
          field="lowerFlexibility"
          label="Flexibilidade mínima"
          formatProps={{
            placeholder: '10%',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalScale: 0,
            suffix: '%',
            prefix: '-',
          }}
        />
        <SelectFormField<IKickOffSuggestedProduct>
          id="contractTermInYears"
          field="contractTermInYears"
          label="Duração"
          transform={{ output: (value) => Number(value) }}
          inputOptions={[2, 3, 4, 5, 6].map((contractTermInYears) => ({
            value: contractTermInYears,
            optionLabel: `${contractTermInYears} anos`,
          }))}
        />
        <SelectFormField<IKickOffSuggestedProduct>
          id="warrantyType"
          field="warrantyType"
          label="Garantia"
          inputOptions={Object.entries(KickOffWarrantyType).map((warrantyTypeEntry) => ({
            value: warrantyTypeEntry[0],
            optionLabel: warrantyTypeEntry[1],
          }))}
        />
        <div className="flex flex-col gap-5 mt-8 md:flex-row">
          <Button
            loading={loading}
            label="Salvar edições"
            kind="primary"
            className="w-full md:w-fit"
            size={isOnMobileScreen() ? 'small' : 'small'}
          />
          <Button
            disabled={loading}
            label="Cancelar"
            kind="secondary"
            className="w-full md:w-fit"
            size={isOnMobileScreen() ? 'small' : 'small'}
            type="button"
            onClick={onFormCancel}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default FormUpdateKickOffDiagnosis;

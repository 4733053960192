import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonIcon, ColumnOptions, FeedbackNotification, Layout, Modal } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { HOME_PATH } from '@routers/constants';
import { INotificationFeedbackContent, TraderNotificationFeedbackContents } from '@utils/constants/common';
import { Trader, TraderTypeEnum } from '@contexts/trader';
import useTraders from '@hooks/use-trader';

import { TradersTable } from '@components/molecules/trader/traders-table';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import FormUpsertTrader from '@components/molecules/form/form-upsert-trader';

type renderFunction = ColumnOptions<Trader>['renderAsElement'];

const renderTraderName: renderFunction = (entry) => (
  <Link to={`/fornecedoras/${entry.id}`} className="text-blue-500 underline">
    {entry.name}
  </Link>
);
const renderTraderType: renderFunction = (entry) => TraderTypeEnum[entry.traderType];
const renderTraderAssets: renderFunction = (entry) => entry.assets;
const renderTraderFoundationYear: renderFunction = (entry) => entry.companyFoundationYear?.toString();

const TradersListPage: React.FC = () => {
  const { traders, getTradersHandler, deleteTraderHandler, loading } = useTraders();
  const [selectedTrader, setSelectedTrader] = React.useState<Trader | null>(null);
  const [modalCreateTraderOpen, setModalCreateTraderOpen] = React.useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();

  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const tradersTableColumns: ColumnOptions<Trader>[] = [
    { accessor: 'name', header: 'Nome', renderAsElement: renderTraderName },
    { accessor: 'traderType', header: 'Tipo', renderAsElement: renderTraderType },
    { accessor: 'assets', header: 'Total de Ativos', renderAsElement: renderTraderAssets },
    { accessor: 'companyFoundationYear', header: 'Ano de Fundação', renderAsElement: renderTraderFoundationYear },
    {
      accessor: 'website',
      header: '',
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            className={!entry.website ? 'opacity-50' : ''}
            kind={'ghost'}
            icon={'GlobeAltIcon'}
            onClick={() => {
              location.assign(entry.website);
              trackEvent(eventLabels.VISIT_TRADER_SITE_BUTTON_CLICKED);
            }}
          />
        );
      },
    },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'PencilIcon'}
            onClick={() => {
              setSelectedTrader(entry);
              setModalCreateTraderOpen(true);
              setOpenNotificationFeedback(false);
            }}
          />
        );
      },
    },
    {
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'TrashIcon'}
            onClick={() => {
              setSelectedTrader(entry);
              setOpenConfirmationModal(true);
              setOpenNotificationFeedback(false);
            }}
          />
        );
      },
    },
  ];

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  React.useEffect(() => {
    trackEvent(eventLabels.TRADERS_PAGE_VIEWED);
  }, []);

  return (
    <Layout
      title="Fornecedoras"
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Fornecedoras',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Nova Fornecedora',
          onClick: () => {
            setSelectedTrader(null);
            setModalCreateTraderOpen(true);
          },
          size: 'small',
        },
      ]}
      searchBar={{
        onBlur: () => trackEvent(eventLabels.TRADERS_PAGE_SEARCH_TRADER),
        onTypedTermChangedCallback: (term) => setTypedSearchTerm(term),
        placeholder: 'Filtrar por nome da fornecedora',
      }}
      className="relative"
      darkerBackground
    >
      <div className="absolute right-0 bottom-0 z-10 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full mb-8 h-full">
        <TradersTable
          data={
            typedSearchTerm.length > 0
              ? traders.filter((trader) => trader.name.toLowerCase().includes(typedSearchTerm.toLowerCase()))
              : traders
          }
          isFetchingItems={loading}
          columnDefinitions={tradersTableColumns}
        />
      </div>
      <Modal
        className="h-screen"
        open={modalCreateTraderOpen}
        setOpen={() => setModalCreateTraderOpen(false)}
        title={`${selectedTrader ? 'Atualizar' : 'Criar'} fornecedora`}
        description={`Formulário de ${selectedTrader ? 'edição' : 'criação'} fornecedora`}
      >
        <FormUpsertTrader
          dataToUpdate={selectedTrader && selectedTrader}
          onFormSuccess={() => {
            getTradersHandler();
            setNotificationResponse(
              TraderNotificationFeedbackContents[
                selectedTrader ? 'UPDATING_TRADER_SUCCESS' : 'CREATING_TRADER_SUCCESS'
              ],
            );
            setModalCreateTraderOpen(false);
            trackEvent(
              selectedTrader ? eventLabels.UPDATE_TRADER_BUTTON_CLICKED : eventLabels.CREATE_TRADER_BUTTON_CLICKED,
            );
          }}
          onFormFail={() =>
            setNotificationResponse(
              TraderNotificationFeedbackContents[selectedTrader ? 'UPDATING_TRADER_ERROR' : 'CREATING_TRADER_ERROR'],
            )
          }
        />
      </Modal>
      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(false)}
        title="Deletar fornecedora"
        description="Tem certeza que deseja excluir essa fornecedora?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedTrader &&
              deleteTraderHandler(selectedTrader.id)
                .then(() => {
                  getTradersHandler();
                  setNotificationResponse(TraderNotificationFeedbackContents['DELETING_TRADER_SUCCESS']);
                  setOpenConfirmationModal(false);
                  trackEvent(eventLabels.DELETE_TRADER_BUTTON_CLICKED);
                })
                .catch(() => {
                  setOpenConfirmationModal(false);
                  setNotificationResponse(TraderNotificationFeedbackContents['DELETING_TRADER_ERROR']);
                });
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default TradersListPage;

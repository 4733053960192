import { gql } from '@apollo/client';

export const GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY = gql`
  query getMonthlyControlMeasurementByUnitId($input: GetControlMeasurementInput!) {
    getMonthlyControlMeasurementByUnitId(input: $input) {
      id
      unit {
        name
        energyContract {
          id
        }
        group {
          name
        }
      }
      consolidatedMeasurementDocId
      monthYear
      date
      totalMissingHours
      totalConsumption
      createdAt
    }
  }
`;

export const GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY = gql`
  query GetConsolidatedMeasurementById($getConsolidatedMeasurementByIdId: ID!) {
    getConsolidatedMeasurementById(id: $getConsolidatedMeasurementByIdId) {
      id
      profileCcee
      measuringPoint
      weekday
      monthYear
      datetimeMeasurement {
        datetime
        subType
        status
        activeGeneration
        reactiveGeneration
        activeConsumption
        reactiveConsumption
        source
      }
      createdAt
    }
  }
`;

export const GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY = gql`
  query GetUnitConsumptionMeasuresPerHour($input: GetMonthlyConsumptionMeasuresPerUnitInput!) {
    getUnitConsumptionMeasuresPerHour(input: $input) {
      id
      datetimeMeasurement {
        datetime
        subType
        status
        activeConsumption
        source
      }
    }
  }
`;

export const GET_ALL_CONTROL_MEASUREMENT_BY_UNIT = gql`
  query GetAllControlMeasurementByUnitId($unitId: ID!) {
    getAllControlMeasurementByUnitId(unitId: $unitId) {
      monthYear
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_USER_TRADERS_QUERY = gql`
  query UsersTrader($traderId: ID!) {
    trader(id: $traderId) {
      id
      name
      users {
        id
        roles
      }
    }
  }
`;

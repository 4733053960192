import React from 'react';
import { GroupSelectInput } from '@clarke-energia/foton';
import { Controller, Path, FieldValues, RegisterOptions, useFormContext, FieldPath } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';

interface SelectOptions {
  value: string;
  label: string;
}

export interface GroupSelectInputFieldProps<T extends FieldValues> {
  firstField: Path<T>;
  secondField: Path<T>;
  id: string;
  label: string;
  type: 'text' | 'number';
  disabled?: boolean;
  selectOptions: SelectOptions[];
  formatProps?: Pick<
    NumberFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'format'
    | 'mask'
    | 'isNumericString'
  >;
  options?: RegisterOptions<T, FieldPath<T>>;
  className?: string;
}
export function GroupSelectInputFormField<T extends FieldValues>({
  firstField,
  secondField,
  id,
  label,
  type,
  formatProps,
  options,
  disabled,
  selectOptions,
}: GroupSelectInputFieldProps<T>) {
  const {
    control,
    formState: { errors },
    register,
    getValues,
  } = useFormContext<T>();

  register(secondField);
  const secondFieldValue = getValues(secondField);

  return (
    <Controller
      control={control}
      name={firstField}
      rules={options}
      render={({ field: { onChange, onBlur, value } }) => (
        <div>
          <GroupSelectInput
            type={type}
            label={label}
            disabled={disabled || false}
            options={selectOptions}
            required={(options?.required as boolean) || false}
            selectProps={{
              id: `${id}-select`,
              name: `${secondField}-select`,
              value: secondFieldValue,
              onBlur: onBlur,
              onChange: onChange,
            }}
            inputTextProps={
              type === 'text'
                ? {
                    id: `${id}-text`,
                    name: `${firstField}-text`,
                    value: value,
                    onBlur: onBlur,
                    onChange: onChange,
                  }
                : undefined
            }
            inputNumberProps={
              type === 'number'
                ? {
                    id: `${id}-numb`,
                    name: `${firstField}-numb`,
                    formatProps: {
                      placeholder: formatProps?.placeholder,
                      thousandSeparator: formatProps?.thousandSeparator,
                      decimalSeparator: formatProps?.decimalSeparator,
                      decimalScale: formatProps?.decimalScale,
                      fixedDecimalScale: formatProps?.fixedDecimalScale,
                      allowNegative: formatProps?.allowNegative,
                      prefix: formatProps?.prefix,
                      suffix: formatProps?.suffix,
                      format: formatProps?.format,
                      mask: formatProps?.mask,
                    },
                    value: isNaN(value) || value === null || value === undefined ? '' : value.toString(),
                    onBlur: onBlur,
                    onChange: {
                      valueArgType: formatProps?.isNumericString ? 'string' : 'number',
                      callback: (value) => onChange(value),
                    },
                  }
                : undefined
            }
            error={errors[firstField]?.message as string | undefined}
          />
        </div>
      )}
    />
  );
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import SideBar from '@components/molecules/general/sidebar';
import LoadingPage from './loading-page';

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col h-screen md:flex-row">
      <div className="bg-white w-[15.5rem]">
        <SideBar />
      </div>
      <div className="overflow-auto h-full w-full bg-[#FAFAFA] relative">
        <Outlet />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Layout, {
  onRedirecting: () => <LoadingPage />,
});

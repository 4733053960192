import React, { useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';
import { AxiosPromise } from 'axios';
import { useAuth } from '@src/ApolloWrapper';
import { createEnergyContractAttachments } from '@services/customerAPI';

import { ContractType, FeedbackType } from '@pages/contracts/helper';
import {
  CreateEnergyContractPayload,
  EnergyContractAttachment,
  EnergyContractTable,
  AttachmentFeedbackType,
  Groups,
  IEnergyContractAttachmentsPayload,
  GetEnergyContractsParams,
} from './types';
import {
  GET_ENERGY_CONTRACTS_QUERY,
  GET_ENERGY_CONTRACT_BY_ID_QUERY,
  GET_GROUPS_DATA_QUERY,
  GET_PROPOSALS_QUERY,
} from './queries';
import {
  CREATE_ENERGY_CONTRACT,
  DELETE_ENERGY_CONTRACT,
  DELETE_ENERGY_CONTRACT_ATTACHMENT,
  UPDATE_ENERGY_CONTRACT,
} from './mutation';
import {
  finalProposals,
  parseContractToUpdate,
  parserEnergyContractsTableData,
  proposalsFormatedToEnergyContractForm,
} from './parser';

export type EnergyContractContextType = {
  createEnergyContractHandler: (energyContract: CreateEnergyContractPayload) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  updateEnergyContractHandler: (energyContract: CreateEnergyContractPayload) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  deleteEnergyContractHandler: (energyContractId: string | null) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  deleteEnergyContractAttachmentsHandler: (energyContractId: string) => void;
  createEnergyContractAttachmentsHandler: (financialEvent: IEnergyContractAttachmentsPayload) => AxiosPromise<any>;
  energyContracts: EnergyContractTable[];
  loading: boolean;
  mutationLoading: boolean;
  groups: Array<Groups>;
  getGroupsListHandler(): Promise<QueryResult<any, OperationVariables>>;
  getEnergyContractByIdHandler(id: string): Promise<QueryResult<any, OperationVariables>>;
  energyContractToUpdate: CreateEnergyContractPayload;
  proposalsFormated: CreateEnergyContractPayload[];
  proposalsSelectField: inputOptions[];
  getProposalsHandler(page: number): Promise<QueryResult<any, OperationVariables>>;
  getEnergyContractsHandler(params: GetEnergyContractsParams): Promise<QueryResult<any, OperationVariables>>;
  loadingProposals: boolean;
  pagination: Record<string, number>;
  feedbackType: AttachmentFeedbackType | null;
  setFeedbackType: React.Dispatch<React.SetStateAction<AttachmentFeedbackType | null>>;
  storedFiles: EnergyContractAttachment[] | undefined;
  totalProposalsPage: number;
};

export const EnergyContractContext = createContext({} as EnergyContractContextType);

interface Provider {
  children: React.ReactNode;
}

export type inputOptions = {
  label: string;
  value: string;
};

const EnergyContractProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const [energyContracts, setEnergyContracts] = useState<Array<EnergyContractTable>>([]);
  const [totalProposalsPage, setTotalProposalsPage] = useState<number>(0);
  const [pagination, setPagination] = useState<Record<string, number>>({ total: 0, limit: 0 });
  const [energyContractToUpdate, setEnergyContractToUpdate] = useState<CreateEnergyContractPayload>(
    {} as CreateEnergyContractPayload,
  );
  const [groups, setGroups] = useState<Array<Groups>>([]);
  const [proposalsSelectField, setProposalsSelectField] = useState<Array<inputOptions>>([]);
  const [proposalsFormated, setProposalsFormated] = useState<Array<any>>([]);
  const [feedbackType, setFeedbackType] = useState<AttachmentFeedbackType | null>(null);
  const [storedFiles, setStoredFiles] = useState<EnergyContractAttachment[] | undefined>();
  const [isDeletingStoredFile, setIsDeletingStoredFile] = useState<boolean>(false);

  const [getEnergyContracts, { loading: loadingList }] = useLazyQuery(GET_ENERGY_CONTRACTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dataParsed = parserEnergyContractsTableData(data.energyContracts.data);
      setEnergyContracts(dataParsed);
      setPagination({ total: data.energyContracts.total, limit: data.energyContracts.limit });
    },
    onError: () => {
      setEnergyContracts([]);
    },
  });

  const [getEnergyContract, { loading: loadingContract }] = useLazyQuery(GET_ENERGY_CONTRACT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const parsedData = parseContractToUpdate(data.energyContract);

      setEnergyContractToUpdate(parsedData as CreateEnergyContractPayload);
      setStoredFiles(parsedData.energyContractAttachments);
    },
    onError: () => {
      setEnergyContractToUpdate({} as CreateEnergyContractPayload);
    },
  });

  const [getGroups, { loading: loadingGroups }] = useLazyQuery(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setGroups(data.groups.data);
    },
    onError: () => {
      setEnergyContracts([]);
    },
  });

  const [getProposals, { loading: loadingProposals }] = useLazyQuery(GET_PROPOSALS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const totalPage = data.proposals.total / data.proposals.limit;
      setTotalProposalsPage(Math.ceil(totalPage));

      const finalProposalsList = finalProposals(data.proposals.data);
      const proposalsFormated = proposalsFormatedToEnergyContractForm(finalProposalsList);
      const proposalsSelectField = finalProposalsList.map(({ group, bids, id }) => {
        const { trader } = bids[0];
        return { label: `${group.name} | ${trader.name}`, value: id };
      });
      setProposalsSelectField((prevValue) => [...prevValue, ...proposalsSelectField]);
      setProposalsFormated((prevValue) => [...prevValue, ...proposalsFormated]);
    },
    onError: () => {
      setProposalsSelectField([]);
      setProposalsFormated([]);
    },
  });

  const [createEnergyContract, { loading: loadingCreateEnergyContract }] = useMutation(CREATE_ENERGY_CONTRACT, {
    fetchPolicy: 'network-only',
  });
  const [updateEnergyContract, { loading: loadingUpdateEnergyContract }] = useMutation(UPDATE_ENERGY_CONTRACT, {
    fetchPolicy: 'network-only',
  });
  const [deleteEnergyContract] = useMutation(DELETE_ENERGY_CONTRACT, {
    fetchPolicy: 'network-only',
  });
  const [deleteEnergyContractAttachments] = useMutation(DELETE_ENERGY_CONTRACT_ATTACHMENT, {
    fetchPolicy: 'network-only',
  });

  function createEnergyContractHandler(energyContract: CreateEnergyContractPayload) {
    return createEnergyContract({
      variables: {
        input: {
          ...energyContract,
        },
      },
    })
      .then((response) => {
        return {
          id: response.data['createEnergyContract']['id'],
          feedback: { message: 'CREATING_CONTRACT_SUCCESS' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'CREATING_CONTRACT_ERROR' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      });
  }

  function updateEnergyContractHandler(energyContract: CreateEnergyContractPayload) {
    delete energyContract.energyContractAttachments;
    return updateEnergyContract({
      variables: {
        input: {
          ...energyContract,
        },
      },
    })
      .then((response) => {
        return {
          id: response.data['createEnergyContract']['id'],
          feedback: { message: 'UPDATING_CONTRACT_SUCCESS' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'UPDATING_CONTRACT_ERROR' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      });
  }

  function deleteEnergyContractHandler(energyContractId: string | null) {
    return deleteEnergyContract({
      variables: {
        input: {
          id: energyContractId,
        },
      },
      refetchQueries: [GET_ENERGY_CONTRACTS_QUERY],
    })
      .then((response) => {
        return {
          id: response.data['deleteEnergyContract']['id'],
          feedback: { message: 'DELETING_CONTRACT_SUCCESS' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'DELETING_CONTRACT_ERROR' as FeedbackType, tab: 'ENERGY' as ContractType },
        };
      });
  }

  const deleteEnergyContractAttachmentsHandler = (attachmentId: string) => {
    setFeedbackType(null);
    setIsDeletingStoredFile(true);
    return deleteEnergyContractAttachments({
      variables: {
        input: {
          attachmentsIds: [attachmentId],
        },
      },
      refetchQueries: [GET_ENERGY_CONTRACTS_QUERY],
    })
      .then(() => {
        setFeedbackType('DELETING_CLARKE_CONTRACT_ATTACHMENT_SUCCESS');
        if (storedFiles) {
          setStoredFiles(removeDeletedStoredFiles(storedFiles, attachmentId));
        }
        setIsDeletingStoredFile(false);
      })
      .catch(() => {
        setFeedbackType('DELETING_CLARKE_CONTRACT_ATTACHMENT_ERROR');
        setIsDeletingStoredFile(false);
      });
  };

  const removeDeletedStoredFiles = (storedFiles: EnergyContractAttachment[], attachmentId: string) => {
    return storedFiles.filter((item) => item.id !== attachmentId);
  };

  const createEnergyContractAttachmentsHandler = (energyContractAttachments: IEnergyContractAttachmentsPayload) => {
    return createEnergyContractAttachments(energyContractAttachments, accessToken);
  };

  async function getGroupsListHandler() {
    return await getGroups();
  }

  async function getEnergyContractByIdHandler(energyContractId: string) {
    return await getEnergyContract({
      variables: {
        energyContractId,
      },
    });
  }

  async function getEnergyContractsHandler(params: GetEnergyContractsParams) {
    return await getEnergyContracts({
      variables: { input: params },
    });
  }

  async function getProposalsHandler(currentPage: number) {
    return await getProposals({
      variables: {
        page: currentPage,
      },
    });
  }

  return (
    <EnergyContractContext.Provider
      value={{
        getProposalsHandler,
        proposalsFormated,
        proposalsSelectField,
        getEnergyContractByIdHandler,
        energyContractToUpdate,
        getGroupsListHandler,
        groups,
        createEnergyContractHandler,
        deleteEnergyContractHandler,
        updateEnergyContractHandler,
        energyContracts,
        loadingProposals,
        mutationLoading: loadingCreateEnergyContract || loadingUpdateEnergyContract,
        loading: loadingList || loadingContract || loadingGroups || isDeletingStoredFile,
        pagination,
        getEnergyContractsHandler,
        feedbackType,
        setFeedbackType,
        createEnergyContractAttachmentsHandler,
        deleteEnergyContractAttachmentsHandler,
        storedFiles,
        totalProposalsPage,
      }}
    >
      {children}
    </EnergyContractContext.Provider>
  );
};

export default EnergyContractProvider;

import React, { useState } from 'react';
import { FetchResult, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';
import { CREATE_USER_TRADER, DELETE_USER_TRADER, CATEGORIZE_TRADERS_USER_ROLES } from './mutation';
import { GET_USER_TRADERS_QUERY } from './queries';
import { UserInfo } from '@contexts/user/types';
import { Trader } from '@contexts/trader';
import { User } from '@auth0/auth0-react';
import { useAuth } from '@src/ApolloWrapper';

export * from './types';

export type TraderUserContextType = {
  trader: Trader | undefined;
  traderUsers: UserInfo[];
  loading: boolean;
  loadingGetTrader: boolean;
  setTraderId: React.Dispatch<React.SetStateAction<string>>;
  getTraderHandler: () => Promise<QueryResult<any, { traderId: string }>>;
  createTraderUserHandler: (usersId: string[]) => Promise<any>;
  deleteTraderUserHandler: (userId: string) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  categorizeTradersUserRolesHandler: (
    userId: string,
    roles: string[],
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  loadingRolesForTradersUsers: boolean;
};

export const TraderUserContext = createContext({} as TraderUserContextType);

interface Provider {
  children: React.ReactNode;
}

const TraderUsersProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [traderId, setTraderId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [traderUsers, setTraderUsers] = useState<Array<UserInfo>>([]);
  const [trader, setTrader] = useState<Trader>();
  const { auth0ApiService } = useAuth();

  const getTraderOnCompletedHandler = (response: Record<string, any>) => {
    const trader = response.trader as Trader;
    setTrader(trader);
    const traderUsers = [...trader.users];
    const traderUsersId: string[] = traderUsers.map((user) => user.id);

    if (traderUsersId?.length > 0) {
      auth0ApiService?.getUsersByIdList(traderUsersId).then((auth0Response) => {
        const parsedUsersList: User[] = auth0Response.data;

        const parsedTraderUsers = parsedUsersList.map((user) => {
          const traderUser = traderUsers.find((traderUser) => traderUser.id == (user.app_metadata.uuid as string));
          return {
            id: user.app_metadata.uuid as string,
            email: user.email,
            isAdmin: /.*@clarke.com(.br)?/.test(user.email as string),
            name: user.name,
            phone: user.phone_number,
            trader: trader.id,
            categoryRoles: traderUser?.roles,
            pictureUrl: user.picture,
          } as UserInfo;
        });
        setTraderUsers(parsedTraderUsers);
        setLoading(false);
      });
    } else setLoading(false);
  };
  const [getTrader, { called: calledGetTraders, loading: loadingGetTrader }] = useLazyQuery(GET_USER_TRADERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: getTraderOnCompletedHandler,
    onError: () => {
      setTraderUsers([]);
    },
    variables: {
      traderId,
    },
  });

  const [createTraderUser] = useMutation(CREATE_USER_TRADER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [deleteTraderUser] = useMutation(DELETE_USER_TRADER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [categorizeTradersUserRoles, { loading: loadingRolesForTradersUsers }] = useMutation(
    CATEGORIZE_TRADERS_USER_ROLES,
    {
      fetchPolicy: 'network-only',
    },
  );

  function getTraderHandler() {
    setLoading(true);
    return getTrader();
  }

  function createTraderUserHandler(usersId: string[]) {
    setLoading(true);
    const createTradersUsersPromises = usersId.map((userId) => {
      return createTraderUser({
        variables: {
          input: {
            retailTraderId: traderId,
            userId: userId,
          },
        },
      });
    });
    return Promise.all(createTradersUsersPromises);
  }

  function deleteTraderUserHandler(userId: string) {
    setLoading(true);
    return deleteTraderUser({
      variables: {
        input: {
          retailTraderId: traderId,
          userId: userId,
        },
      },
    });
  }

  function categorizeTradersUserRolesHandler(userId: string, roles: string[]) {
    return categorizeTradersUserRoles({
      variables: {
        input: {
          userId: userId,
          roles: roles,
        },
      },
    });
  }

  if (!calledGetTraders && traderId) getTraderHandler();

  React.useEffect(() => {
    traderId && getTraderHandler();
  }, [traderId]);

  return (
    <TraderUserContext.Provider
      value={{
        traderUsers,
        trader,
        loading,
        setTraderId,
        createTraderUserHandler,
        deleteTraderUserHandler,
        getTraderHandler,
        categorizeTradersUserRolesHandler,
        loadingRolesForTradersUsers,
        loadingGetTrader,
      }}
    >
      {children}
    </TraderUserContext.Provider>
  );
};

export default TraderUsersProvider;

import React, { useEffect, useState } from 'react';

import { PROINFA_LIST_ITEMS_BY_PAGE } from '@utils/constants/common';
import { useProinfa } from '@hooks/use-proinfa';
import ProinfaTable from '../proinfa/table';
import FiltersProinfa from './filters-table';
import { currentYearStr } from './helper';
interface ProinfaProps {
  typedSearchTerm: string;
}

const ProinfaList: React.FC<ProinfaProps> = ({ typedSearchTerm }) => {
  const {
    proinfaList,
    totalOfProinfas,
    getProinfaListHandler,
    getProinfaYearOptionsListHandler,
    loading,
    commercialGroups,
  } = useProinfa();
  const [page, setPage] = useState<number>(1);
  const [selectedYearFilter, setSelectedYearFilter] = useState<string>(currentYearStr);
  const [openYearFilterOptions, setOpenYearFilterOptions] = useState<boolean>(false);
  const [selectedGroupFilter, setSelectedGroupFilter] = useState<string | null>(null);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [openGroupFilterOptions, setOpenGroupFilterOptions] = useState<boolean>(false);

  useEffect(() => {
    getProinfaListHandler({
      page,
      targetYear: selectedYearFilter,
      groupId,
      term: typedSearchTerm,
    });
  }, [page, groupId, selectedYearFilter, typedSearchTerm]);

  const yearsRange = ['2023', '2024', '2025', '2026', '2027'];
  const [yearsList, setYearsList] = useState<Array<string>>([]);

  const fetchData = async () => {
    const promises = yearsRange.map((year) => {
      return getProinfaYearOptionsListHandler({ targetYear: year }).then((data) => {
        const response = data.data.getProinfas.data;
        return response.length >= 1 ? year : null;
      });
    });
    const results = await Promise.all(promises);
    const filteredResults = results.filter((year) => year !== null);
    setYearsList(filteredResults as string[]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const groupList = commercialGroups.filter(({ units }) => units?.some((unit) => unit.assetCode));

  return (
    <>
      <div className="col-span-full h-full">
        <FiltersProinfa
          filters={[
            {
              label: selectedYearFilter,
              filterSelected: selectedYearFilter ? true : false,
              options: yearsList,
              openOptions: () => {
                if (!yearsList.length) return;
                setOpenYearFilterOptions(!openYearFilterOptions);
              },
              onClick: (option: string) => {
                setSelectedYearFilter(option);
                setOpenYearFilterOptions(false);
              },
              showOptions: openYearFilterOptions,
            },
            {
              label: selectedGroupFilter || 'Grupo Comercial',
              filterSelected: selectedGroupFilter ? true : false,
              options: groupList.map(({ name }) => name),
              openOptions: () => {
                if (!groupList.length) return;
                setOpenGroupFilterOptions(!openGroupFilterOptions);
              },
              onClick: (option: string) => {
                const groupId = commercialGroups.find(({ name }) => option === name)?.id || null;
                setGroupId(groupId);
                setSelectedGroupFilter(option);
                setOpenGroupFilterOptions(false);
              },
              showOptions: openGroupFilterOptions,
            },
          ]}
          clearFilter={() => {
            setGroupId(null);
            setSelectedYearFilter(currentYearStr);
            setSelectedGroupFilter(null);
          }}
        />
        <ProinfaTable
          isFetchingContracts={loading}
          data={proinfaList}
          totalItems={totalOfProinfas}
          currentPage={page}
          setCurrentPage={setPage}
          itemsByPage={PROINFA_LIST_ITEMS_BY_PAGE}
        />
      </div>
    </>
  );
};

export default ProinfaList;

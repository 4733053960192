import React from 'react';

import { CreateCceeContractPayload } from '@contexts/ccee-contract/types';
import { FormField, NumericFormField } from '@components/atoms/form';
import { ComboBoxFormField } from '../form/combobox';

interface TraderFieldsProps {
  traderOptions: { label: string; value: any }[];
}

const TraderFields = ({ traderOptions }: TraderFieldsProps): JSX.Element => {
  return (
    <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
      <ComboBoxFormField<CreateCceeContractPayload>
        label="Fornecedora"
        field="retailTraderId"
        id="retailTraderId"
        inputOptions={traderOptions}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
      <NumericFormField<CreateCceeContractPayload>
        id="cceeTraderId"
        label="ID Fornecedora CCEE"
        field="cceeTraderId"
        formatProps={{
          placeholder: 'Insira o id do perfil da CCEE.',
          format: '#######',
          mask: '',
          allowNegative: false,
          fixedDecimalScale: false,
          decimalSeparator: undefined,
          thousandSeparator: undefined,
          decimalScale: 0,
        }}
        options={{
          valueAsNumber: false,
        }}
      />
      <FormField<CreateCceeContractPayload>
        id="cceeTraderProfile"
        label="Perfil Fornecedora CCEE"
        field="cceeTraderProfile"
      />
    </div>
  );
};

export default TraderFields;

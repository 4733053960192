import { useFormContext } from 'react-hook-form';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractSectionFieldsContainer from '@components/atoms/form/contract/contract-section-fields-container';
import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import { NumericFormField } from '@components/atoms/form';
import { isValidEmail } from '@src/common';

export enum IProductTwoSimulationStatus {
  IN_QUEUE = 'Aguardando na fila',
  IN_PROGRESS = 'Em andamento',
  DONE = 'Concluído',
  FAILED = 'Falha',
}
export interface IProposalUser {
  id: string;
  name: string;
  pictureUrl: string;
}
export interface LatestProposalMetadata {
  proposalId: string;
  description?: string;
  seller?: IProposalUser;
  author: IProposalUser;
  status?: keyof typeof IProductTwoSimulationStatus;
  commercialDealerComission?: number;
  createdAt: string;
}
export interface ISimulatorIndexedMetadata {
  latestProposalMetadata?: LatestProposalMetadata;
  proposalsNumber?: number;
}
export interface ILeadSimulatorsIndexedMetadata {
  productOne?: ISimulatorIndexedMetadata;
  productTwo?: ISimulatorIndexedMetadata;
  comparisonBill?: ISimulatorIndexedMetadata;
  feasibilityAnalysis?: ISimulatorIndexedMetadata;
  lowTension?: ISimulatorIndexedMetadata;
}

export interface IProposalLead {
  id: string;
  name: string;
  createdAt: string;
  deletedAt?: string | null;
  simulatorsIndexedMetadata?: ILeadSimulatorsIndexedMetadata;
}
const ContractFormContractBondSection = (): JSX.Element => {
  const {
    formState: { defaultValues },
  } = useFormContext<IManagementContractPayload>();
  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection
          title="Cliente e Vínculo do Contrato"
          subtitle="Quem é o cliente? Qual é a unidade que está sob esse contrato de energia?"
        />
        <ContractSectionFieldsContainer>
          <>
            <GenericFormField<IManagementContractPayload>
              id="inputs.customer.name"
              field="inputs.customer.name"
              label="Grupo Comercial"
              disabled={true}
            />
            <NumericFormField<IManagementContractPayload>
              id="inputs.customer.docNumber"
              field="inputs.customer.docNumber"
              label="CNPJ"
              formatProps={{ format: '##.###.###/####-##', isNumericString: true }}
            />
            <NumericFormField<IManagementContractPayload>
              id="inputs.customer.headOfficeZipCode"
              field="inputs.customer.headOfficeZipCode"
              label="CEP da Matriz"
              formatProps={{ format: '#####-###', isNumericString: true }}
            />
            <GenericFormField<IManagementContractPayload>
              id="inputs.customer.headOfficeAddress"
              field="inputs.customer.headOfficeAddress"
              label="Endereço da Matriz"
            />
            <GenericFormField<IManagementContractPayload>
              id="inputs.customer.financeDepartmentEmail"
              field="inputs.customer.financeDepartmentEmail"
              label="E-mail do Departamento Financeiro"
              options={{
                validate: (value) => isValidEmail(value as string) || 'Insira um E-mail válido',
              }}
            />
          </>
        </ContractSectionFieldsContainer>
      </>
    </ContractSectionContainer>
  );
};
export default ContractFormContractBondSection;

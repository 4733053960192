import React from 'react';
import numeral from 'numeral';

import { ColumnOptions, NuggetInfo, NuggetInfoProps, Table } from '@clarke-energia/foton';
import { ParsedConsumptionHistoryEntry, UnitPayload } from '@contexts/unit';
import { capitalize, formatNumberToStringWithSuffix } from '@utils/text';
import { formatDate, parseStringToDate } from '@utils/dayjs';

export interface UnitHistoricSectionProps {
  selectedUnit: UnitPayload;
}

const UnitHistoricSection = ({ selectedUnit }: UnitHistoricSectionProps) => {
  const unitHistoryData: ParsedConsumptionHistoryEntry[] = Object.values(selectedUnit.history);

  type renderFunction = ColumnOptions<ParsedConsumptionHistoryEntry>['renderAsElement'];

  const renderMonth: renderFunction = (entry) =>
    capitalize(formatDate(parseStringToDate(entry.yearMonth ?? ''), 'MMMM'));
  const renderGeneratorValue: renderFunction = (entry) =>
    formatNumberToStringWithSuffix(entry.generator ?? 0, 'kWh', 0);
  const renderEnergyPeakValue: renderFunction = (entry) => formatNumberToStringWithSuffix(entry.peak ?? 0, 'kWh', 0);
  const renderEnergyOffPeakValue: renderFunction = (entry) =>
    formatNumberToStringWithSuffix(entry.offPeak ?? 0, 'kWh', 0);

  const unitHistoricTableColumns: ColumnOptions<ParsedConsumptionHistoryEntry>[] = [
    { accessor: 'yearMonth', header: 'Mês', renderAsElement: renderMonth, fixedWidthInRem: 18 },
    { accessor: 'offPeak', header: 'Energia FP', renderAsElement: renderEnergyOffPeakValue, fixedWidthInRem: 10 },
    { accessor: 'peak', header: 'Energia P', renderAsElement: renderEnergyPeakValue, fixedWidthInRem: 6 },
  ];

  if (selectedUnit.powerGenerator) {
    unitHistoricTableColumns.splice(1, 0, {
      accessor: 'generator',
      header: 'Gerador',
      renderAsElement: renderGeneratorValue,
      fixedWidthInRem: 10,
    });
  }

  const sumYearTotalForConsumption = (history: UnitPayload['history']) => {
    return Object.values(history).reduce(
      (acc, entry) => ({
        peak: (acc.peak || 0) + (entry.peak || 0),
        offPeak: (acc.offPeak || 0) + (entry.offPeak || 0),
        generator: (acc.generator || 0) + (entry.generator || 0),
      }),
      { peak: 0, offPeak: 0, generator: 0 },
    );
  };
  const { peak, offPeak, generator } = sumYearTotalForConsumption(selectedUnit.history);
  const totalConsumptionVolumeInMegaWattsHour = peak + offPeak + generator;

  const averageConsumption: number = totalConsumptionVolumeInMegaWattsHour / 24 / 365;

  const nuggetInfoData: NuggetInfoProps[] = [
    {
      id: '1',
      label: 'Volume total',
      textInfo: formatNumberToStringWithSuffix(totalConsumptionVolumeInMegaWattsHour / 1000, 'MWh', 0),
    },
    {
      id: '2',
      label: 'Volume médio',
      textInfo: numeral(averageConsumption / 1000)
        .format('0.000')
        .concat(' MWm'),
    },
  ];

  return (
    <div className="col-span-full">
      <h1 className="mb-5">Histórico / Projeção de Consumo</h1>
      <div className="mb-5">
        {selectedUnit && (
          <Table
            tableColumns={unitHistoricTableColumns}
            data={unitHistoryData}
            firstColumBold
            stripedClassName="even:bg-white odd:bg-[#FAFAFA] border-none"
          />
        )}
      </div>
      <div className="flex">
        {nuggetInfoData.map((item) => (
          <NuggetInfo key={item.id} label={item.label} textInfo={item.textInfo} />
        ))}
      </div>
    </div>
  );
};

export default UnitHistoricSection;

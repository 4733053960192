import React from 'react';
import { HeroIcon } from '@clarke-energia/foton';

interface StepTabProps {
  step: number;
  label: string;
  isActive: boolean;
  isConcluded?: boolean;
}

const StepTab: React.FC<StepTabProps> = ({ step, label, isActive, isConcluded }) => {
  const parsedStep = step < 10 ? `0${step}` : step;
  return (
    <div
      className={`w-full flex items-center px-5 pb-7 h-[1.75rem]  mb-8 text-paragraph-medium gap-[0.75rem] border-b-[1px] border-black ${
        isActive ? 'border-b-2 border-primary-60 text-primary-60' : ''
      }`}
    >
      <div
        className={`px-[9px] border-[1px] border-black rounded-full ${
          isActive ? 'border-primary-60 py-[7px]' : isConcluded ? 'bg-primary-60 py-[9px]' : 'py-[7px]'
        }`}
      >
        {isConcluded ? (
          <div className="w-5">
            <HeroIcon icon="CheckIcon" />
          </div>
        ) : (
          parsedStep
        )}
      </div>
      <div>
        <span
          className={`text-paragraph-small ${isActive ? 'text-primary-60' : 'text-neutral-50'}`}
        >{`Passo ${parsedStep}`}</span>
        <h2>{label}</h2>
      </div>
    </div>
  );
};

export default StepTab;

interface IContractTitleAndSubtitleSubsection {
  title: string;
  subtitle: string;
}

const ContractTitleAndSubtitleSubsection = ({ title, subtitle }: IContractTitleAndSubtitleSubsection) => {
  return (
    <div className="xl:w-[25rem]">
      <h1 className="font-bold text-heading-xsmall">{title}</h1>
      <h2 className="font-normal text-paragraph-medium text-neutral-60">{subtitle}</h2>
    </div>
  );
};

export default ContractTitleAndSubtitleSubsection;

import React, { useState } from 'react';
import dayjs from 'dayjs';
import { FetchResult, OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { EnergyAuditStatus } from '@contexts/energy-audit';

import { parseDataToReportForm, reportGroupParsed, reportsTableData } from './parser';
import {
  GET_ALL_GROUP_ECONOMY_REPORTS,
  GET_CLARKE_CONTRACT_BY_GROUP_ID,
  GET_GROUP_BY_ID,
  GET_GROUP_ECONOMY_REPORT_BY_ID,
  GET_ENERGY_CONTRACTS_BY_GROUP_ID,
  GET_ENERGY_AUDITS_BY_GROUP,
  GET_LIQUIDATION_FEE_BY_GROUP,
  GET_CCEE_CONTRIBUTIONS_BY_GROUP,
  GET_EER_BY_GROUP,
  GET_SUPPLY_INVOICE,
} from './queries';
import {
  UPSERT_ECONOMY_REPORT_MUTATION,
  UPSERT_GROUP_ECONOMY_REPORT_MUTATION,
  UPDATE_BATCH_GROUP_ECONOMY_REPORTS_STATUS,
  UPDATE_BATCH_UNIT_ECONOMY_REPORTS_STATUS,
  NOTIFY_UNIT_REPORT_BY_EMAIL,
  NOTIFY_GROUP_REPORT_BY_EMAIL,
} from './mutation';
import {
  ClarkeContractGraphqlResponse,
  EMPTY_REPORT,
  EconomyReport,
  EconomyReportPayload,
  EnergyContractGraphqlResponse,
  EnergyAuditGraphqlResponse,
  FinancialEventGraphqlResponse,
  FinancialEventsInput,
  GroupEconomyReportPayload,
  GroupFormData,
  GroupGraphqlResponse,
  GroupReport,
  IHandleUpdateReportsStatus,
  NotifyReportInput,
  ReportsDataTable,
  SupplyInvoiceGraphqlResponse,
  SupplyInvoice,
} from './types';
import { INotificationFeedbackContent } from '@utils/constants/common';
import { FinancialEventType } from '@contexts/financial-events';

export type GroupEconomyReportContextType = {
  groupName: string;
  setGroupId: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  reportsTable: ReportsDataTable[];
  getGroupEconomyReportByReportId: (reportId: string) => Promise<QueryResult<any, OperationVariables>> | undefined;
  upsertEconomyReportHandler: (
    values: EconomyReportPayload,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  upsertGroupEconomyReportHandler: (
    values: GroupEconomyReportPayload,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  handleUpdateReportsStatus({ status, reports }: IHandleUpdateReportsStatus): Promise<boolean>;
  loadingGroupReport: boolean;
  loadingGroupData: boolean;
  groupReport: GroupReport;
  groupReportFormData: GroupFormData;
  openNotificationFeedBack: boolean;
  setOpenNotificationFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  messageNotificationFeedBack: INotificationFeedbackContent | undefined;
  setMessageNotificationFeedback: React.Dispatch<React.SetStateAction<INotificationFeedbackContent | undefined>>;
  handleNotifyReportsByEmail: (input: NotifyReportInput, notifyGroupReport: boolean) => Promise<boolean>;
  getClarkeContractByGroupIdHandler: (groupId: string) => Promise<QueryResult<any, OperationVariables>>;
  getEnergyContractByGroupIdHandler: (groupId: string) => Promise<QueryResult<any, OperationVariables>>;
  getEnergyAuditsByGroupIdHandler: (id: string, date: string) => Promise<QueryResult<any, OperationVariables>>;
  getAllFinancialEventsByGroupIdHandler: (
    id: string,
    date: string,
  ) => Promise<
    [QueryResult<any, OperationVariables>, QueryResult<any, OperationVariables>, QueryResult<any, OperationVariables>]
  >;
  getGroupDataHandler: (id: string) => Promise<QueryResult<GroupGraphqlResponse, OperationVariables>>;
  getSupplyInvoiceHandler: (
    date: string,
    unitDoc: string,
  ) => Promise<QueryResult<SupplyInvoiceGraphqlResponse, OperationVariables>>;
  initFormData: EconomyReportPayload;
  unitsConsumptionFinancialEvent: FinancialEventsInput;
  supplySection: SupplyInvoice;
};

export const GroupEconomyReportContext = createContext({} as GroupEconomyReportContextType);

interface Provider {
  children: React.ReactNode;
}

const GroupEconomyReportProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [groupName, setGroupName] = useState<string>('');
  const [groupId, setGroupId] = useState<string>('');
  const [reportsTable, setReportsTable] = useState<ReportsDataTable[]>([]);
  const [groupReportFormData, setGroupReportFormData] = useState<GroupFormData>({} as GroupFormData);
  const [groupReport, setGroupReport] = useState<GroupReport>({} as GroupReport);
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<
    INotificationFeedbackContent | undefined
  >(undefined);
  const [initFormData, setInitFormData] = useState<EconomyReportPayload>(EMPTY_REPORT);
  const [supplySection, setSupplySection] = useState<SupplyInvoice>({
    supplyAmountMwh: null,
    price: null,
  });
  const [unitsConsumptionFinancialEvent, setUnitsConsumptionFinancialEvent] = useState<FinancialEventsInput>({
    units: [],
    totalConsumption: 0,
    totalLiquidationFee: 0,
    totalCceeFee: 0,
    totalEerFeeByProfile: {},
  });

  const [upsertEconomyReport] = useMutation(UPSERT_ECONOMY_REPORT_MUTATION, {
    fetchPolicy: 'network-only',
  });
  const [upsertGroupEconomyReport] = useMutation(UPSERT_GROUP_ECONOMY_REPORT_MUTATION, {
    fetchPolicy: 'network-only',
  });
  const [updateBatchGroupEconomyReportsStatus] = useMutation(UPDATE_BATCH_GROUP_ECONOMY_REPORTS_STATUS, {
    fetchPolicy: 'network-only',
  });
  const [updateBatchUnitEconomyReportsStatus] = useMutation(UPDATE_BATCH_UNIT_ECONOMY_REPORTS_STATUS, {
    fetchPolicy: 'network-only',
  });
  const [notifyUnitReportByEmail] = useMutation(NOTIFY_UNIT_REPORT_BY_EMAIL, {
    fetchPolicy: 'network-only',
  });
  const [notifyGroupReportByEmail] = useMutation(NOTIFY_GROUP_REPORT_BY_EMAIL, {
    fetchPolicy: 'network-only',
  });

  const [getGroupEconomyReportById, { loading: loadingGroupReport }] = useLazyQuery(GET_GROUP_ECONOMY_REPORT_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const report = reportGroupParsed(data.groupEconomyReport);
      setGroupReport(report);
    },
    onError: () => {
      setGroupName('');
      setReportsTable([]);
      setGroupReport({} as GroupReport);
    },
  });

  const [getGroup, { loading: loadingGroupData }] = useLazyQuery<GroupGraphqlResponse>(GET_GROUP_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setGroupName(data.group.name);
      const reportFormData = parseDataToReportForm(data.group);
      setGroupReportFormData(reportFormData);
      setInitFormData((previousValue) => ({ ...previousValue, groupId: reportFormData.groupId }));
    },
    onError: () => {
      setGroupName('');
      setGroupReportFormData({} as GroupFormData);
    },
  });

  const [getAllEconomyReportByGroupId, { loading }] = useLazyQuery(GET_ALL_GROUP_ECONOMY_REPORTS, {
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      (data.unitsEconomyReportsByGroupId as EconomyReport[])?.length > 0 &&
        setReportsTable(reportsTableData(data.unitsEconomyReportsByGroupId, groupName));
    },
    onError: () => {
      setReportsTable([]);
    },
    variables: {
      groupId,
    },
  });

  const [getContractClakeByGroupId] = useLazyQuery<ClarkeContractGraphqlResponse>(GET_CLARKE_CONTRACT_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const managmentAmount = data['getClarkeContractByGroupId']['clarkeMagmentAmount'];
      setInitFormData((prevValue) => ({ ...prevValue, clarkeManagementCost: managmentAmount }));
    },
    onError: () => {
      return null;
    },
  });

  const [getEnergyContractByGroupId] = useLazyQuery<EnergyContractGraphqlResponse>(GET_ENERGY_CONTRACTS_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const trader = data.energyContractsByGroupId[0]?.trader;
      setInitFormData((prevValue) => ({ ...prevValue, traderId: trader?.id ?? '' }));
    },
    onError: () => {
      return null;
    },
  });

  const [getEnergyAuditsByGroupId] = useLazyQuery<EnergyAuditGraphqlResponse>(GET_ENERGY_AUDITS_BY_GROUP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getAllEnergyAudits.data.length === 0) {
        return;
      }

      const units = data.getAllEnergyAudits.data.flatMap(({ units }) =>
        units.map(({ unit, consumptionAmount }) => ({
          id: unit.id,
          consumptionAmount,
          profileName: unit.cceeProfile?.name,
        })),
      );
      const totalConsumption = data.getAllEnergyAudits.data.reduce((accumulator, current) => {
        if (current && typeof current.consumptionTotal === 'number') {
          return accumulator + current.consumptionTotal;
        } else {
          return accumulator;
        }
      }, 0);

      setUnitsConsumptionFinancialEvent((previousValue) => ({
        ...previousValue,
        totalConsumption: totalConsumption,
        units: units,
      }));
    },
    onError: () => {
      return null;
    },
  });

  const [getSupplyInvoice] = useLazyQuery<SupplyInvoiceGraphqlResponse>(GET_SUPPLY_INVOICE, {
    fetchPolicy: 'network-only',

    onCompleted: ({ getSupplyInvoiceByDocNumberAndDate: { price, supplyAmountMwh } }) => {
      setSupplySection({
        price,
        supplyAmountMwh,
      });
    },

    onError: () => {
      return null;
    },
  });

  const [getLiquidationFeeByGroup] = useLazyQuery<FinancialEventGraphqlResponse>(GET_LIQUIDATION_FEE_BY_GROUP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const liquidationAmount = data.getFinancialEvents.data.length
        ? data.getFinancialEvents.data[0].liquidationFee
        : 0;
      setUnitsConsumptionFinancialEvent((previousVal) => ({
        ...previousVal,
        totalLiquidationFee: liquidationAmount as number,
      }));
    },
    onError: () => {
      return null;
    },
  });
  const [getCceeContributionByGroup] = useLazyQuery<FinancialEventGraphqlResponse>(GET_CCEE_CONTRIBUTIONS_BY_GROUP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const cceeAmount = data.getFinancialEvents.data.length ? data.getFinancialEvents.data[0].amountToPay : 0;
      setUnitsConsumptionFinancialEvent((previousVal) => ({ ...previousVal, totalCceeFee: cceeAmount as number }));
    },
    onError: () => {
      return null;
    },
  });
  const [getEERRFeeByGroup] = useLazyQuery<FinancialEventGraphqlResponse>(GET_EER_BY_GROUP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const eerrAmount = data.getFinancialEvents.data?.[0]?.eerFeePerProfiles || {};
      setUnitsConsumptionFinancialEvent((previousVal) => ({ ...previousVal, totalEerFeeByProfile: eerrAmount }));
    },
    onError: () => {
      return null;
    },
  });

  function getGroupEconomyReportByReportId(reportId: string) {
    return getGroupEconomyReportById({
      variables: {
        groupEconomyReportId: reportId,
      },
    });
  }

  function upsertEconomyReportHandler(values: EconomyReportPayload) {
    return upsertEconomyReport({
      variables: {
        input: {
          ...values,
        },
      },
      refetchQueries: [GET_ALL_GROUP_ECONOMY_REPORTS],
    });
  }

  function upsertGroupEconomyReportHandler(values: GroupEconomyReportPayload) {
    return upsertGroupEconomyReport({
      variables: {
        input: {
          ...values,
        },
      },
      refetchQueries: [GET_ALL_GROUP_ECONOMY_REPORTS],
    });
  }

  async function handleUpdateReportsStatus({ status, reports }: IHandleUpdateReportsStatus) {
    const unitReportsIdsList = reports
      .filter((report) => report.reportType === 'UNIT')
      .map((report) => report.reportId);
    const groupReportsIdsList = reports
      .filter((report) => report.reportType === 'GROUP')
      .map((report) => report.reportId);

    let updateOperationSuccess = true;
    if (unitReportsIdsList?.length > 0) {
      await updateBatchUnitEconomyReportsStatus({
        variables: {
          input: {
            economyReportsIdsList: unitReportsIdsList,
            status: status,
          },
        },
        refetchQueries: [GET_ALL_GROUP_ECONOMY_REPORTS],
      }).then((response) => {
        if (response.data.updateBatchEconomyReportsStatus === false) updateOperationSuccess = false;
      });
    }

    if (groupReportsIdsList?.length > 0) {
      await updateBatchGroupEconomyReportsStatus({
        variables: {
          input: {
            groupEconomyReportsIdsList: groupReportsIdsList,
            status: status,
          },
        },
        refetchQueries: [GET_ALL_GROUP_ECONOMY_REPORTS],
      }).then((response) => {
        if (response.data.updateGroupBatchEconomyReportsStatus === false) updateOperationSuccess = false;
      });
    }

    return updateOperationSuccess;
  }

  const handleNotifyReportsByEmail = async (input: NotifyReportInput, notifyGroupReport: boolean) => {
    const variables = { input };

    let response;

    try {
      if (notifyGroupReport) {
        const {
          data: { notifyGroupEconomyReport },
        } = await notifyGroupReportByEmail({
          variables,
        });

        response = notifyGroupEconomyReport;
      }

      if (!notifyGroupReport) {
        const {
          data: { notifyUnitEconomyReport },
        } = await notifyUnitReportByEmail({
          variables,
        });

        response = notifyUnitEconomyReport;
      }

      if (response.errors === null) return true;

      return false;
    } catch (error) {
      return false;
    }
  };

  const getClarkeContractByGroupIdHandler = async (id: string) => {
    return await getContractClakeByGroupId({
      variables: {
        groupId: id,
      },
    });
  };

  const getEnergyContractByGroupIdHandler = async (id: string) => {
    return await getEnergyContractByGroupId({
      variables: {
        groupId: id,
      },
    });
  };

  const getEnergyAuditsByGroupIdHandler = async (id: string, date: string) => {
    return await getEnergyAuditsByGroupId({
      variables: {
        page: 1,
        date,
        status: EnergyAuditStatus.SENT,
        groupId: id,
      },
    });
  };

  const getSupplyInvoiceHandler = async (date: string, unitDoc: string) => {
    return await getSupplyInvoice({
      variables: {
        date,
        docNumber: unitDoc,
      },
    });
  };

  const getAllFinancialEventsByGroupIdHandler = async (id: string, date: string) => {
    const referenceDate = dayjs(date);
    const month = referenceDate.month() + 1;
    const year = referenceDate.year();
    const liquidation = getLiquidationFeeByGroup({
      variables: {
        input: {
          page: 1,
          referencePeriod: `${year}-${month - 2}-01`,
          commercialGroupId: id,
          type: FinancialEventType.FINANCIAL_SETTLEMENT,
        },
      },
    });
    const ccee = getCceeContributionByGroup({
      variables: {
        input: {
          page: 1,
          referencePeriod: `${year}-${month}-01`,
          commercialGroupId: id,
          type: FinancialEventType.ASSOCIATIVE_CONTRIBUTION,
        },
      },
    });
    const eer = getEERRFeeByGroup({
      variables: {
        input: {
          page: 1,
          referencePeriod: `${year}-${month - 1}-01`,
          commercialGroupId: id,
          type: FinancialEventType.RESERVE_ENERGY_CHARGE,
        },
      },
    });
    return await Promise.all([eer, ccee, liquidation]);
  };

  const getGroupDataHandler = async (id: string) => {
    return await getGroup({
      variables: {
        groupId: id,
      },
    });
  };

  React.useEffect(() => {
    if (groupName) getAllEconomyReportByGroupId();
  }, [groupName]);

  return (
    <GroupEconomyReportContext.Provider
      value={{
        reportsTable,
        setGroupId,
        groupName,
        loading,
        getGroupEconomyReportByReportId,
        loadingGroupReport,
        groupReport,
        loadingGroupData,
        groupReportFormData,
        upsertEconomyReportHandler,
        upsertGroupEconomyReportHandler,
        handleUpdateReportsStatus,
        openNotificationFeedBack,
        setOpenNotificationFeedback,
        messageNotificationFeedBack,
        setMessageNotificationFeedback,
        handleNotifyReportsByEmail,
        getClarkeContractByGroupIdHandler,
        getEnergyContractByGroupIdHandler,
        getEnergyAuditsByGroupIdHandler,
        getAllFinancialEventsByGroupIdHandler,
        getGroupDataHandler,
        getSupplyInvoiceHandler,
        initFormData,
        unitsConsumptionFinancialEvent,
        supplySection,
      }}
    >
      {children}
    </GroupEconomyReportContext.Provider>
  );
};

export default GroupEconomyReportProvider;

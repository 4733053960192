import { UpsertFileForm } from '@components/molecules/form/form-upsert-document';

const uploadAttachmentFileParser = (data: UpsertFileForm) => {
  const formData = new FormData();
  data.fileToUpload && formData.append('files', data.fileToUpload[0] as Blob);
  formData.append('commercial_group_id', data.commercialGroupId ?? '');
  formData.append('document_type', data.documentType ?? '');
  formData.append('unit_id', data.unitId ?? '');
  formData.append('file_path', data.filePath ?? '');

  return formData;
};

export default uploadAttachmentFileParser;

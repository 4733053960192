import React, { useState } from 'react';
import FileSaver from 'file-saver';
import { createContext } from 'use-context-selector';
import { useLazyQuery } from '@apollo/client';

import {
  EnergyAuditReportFormated,
  EnergyAuditsReportContextType,
  AllEnergyAuditsReportsGraphQLData,
  EnergyAuditReportFilters,
  ParsedEnergyAuditReports,
  DownloadExcelPayload,
} from './types';
import { GET_ALL_ENERGY_AUDITS_REPORTS_QUERY } from './queries';
import { formatEnergyAuditReports } from './parser';
import { useAuth } from '@src/ApolloWrapper';
import { donwloadExcel } from '@services/report-api';

export const EnergyAuditsReportContext = createContext({} as EnergyAuditsReportContextType);

interface Provider {
  children: React.ReactNode;
}

const EnergyAuditReportsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const [energyAuditReports, setEnergyAuditReports] = useState<ParsedEnergyAuditReports>({
    page: 1,
    total: 0,
    limit: 0,
    reports: [] as Array<EnergyAuditReportFormated>,
  });

  const [getAllEnergyAuditsReports, { loading: loadingGetAllEnergyAuditReports }] =
    useLazyQuery<AllEnergyAuditsReportsGraphQLData>(GET_ALL_ENERGY_AUDITS_REPORTS_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: ({ getAllEnergyAuditReports }) => {
        const parsedData = formatEnergyAuditReports(getAllEnergyAuditReports.data);
        setEnergyAuditReports({
          page: getAllEnergyAuditReports.page,
          limit: getAllEnergyAuditReports.limit,
          total: getAllEnergyAuditReports.total,
          reports: parsedData,
        });
      },
      onError: () => {
        setEnergyAuditReports({
          page: 1,
          total: 0,
          limit: 0,
          reports: [] as Array<EnergyAuditReportFormated>,
        });
      },
    });

  const getAllEnergyReports = (params: EnergyAuditReportFilters) => {
    return getAllEnergyAuditsReports({
      variables: {
        input: { ...params },
      },
    });
  };

  const handleDownloadExcel = async (data: DownloadExcelPayload) => {
    try {
      const response = await donwloadExcel(data, accessToken);
      const contentDisposition = response.headers['content-disposition'];
      const match = contentDisposition.match(/filename=(.+)/);
      const fileName = match ? match[1] : 'unknown';
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      FileSaver.saveAs(blob, fileName);
      return 'EXCEL_DONWLOAD_SUCCESS';
    } catch (error) {
      return 'EXCEL_DONWLOAD_ERROR';
    }
  };

  return (
    <EnergyAuditsReportContext.Provider
      value={{
        handleDownloadExcel,
        getAllEnergyReports,
        loading: loadingGetAllEnergyAuditReports,
        energyAuditReports,
      }}
    >
      {children}
    </EnergyAuditsReportContext.Provider>
  );
};

export default EnergyAuditReportsProvider;

import React from 'react';

import { FileField } from '@components/atoms/form/file/file-input';
import { NumericFormField } from '@components/atoms/form';
import { ProcessProinfaFormData } from '@contexts/proinfa-contract/types';

interface FirstStepProps {
  setUploadedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const FirstStep: React.FC<FirstStepProps> = ({ setUploadedFile }) => {
  return (
    <div>
      <div className="flex gap-6 mb-10">
        <div className="max-w-[16.5rem]">
          <h1 className="font-bold text-heading-xsmall">1. Enviar dados</h1>
          <span className="text-paragraph-medium text-neutral-60">
            Selecione o ano de referencia para o PROINFA e envie a tabela de dados.
          </span>
        </div>
        <div>
          <div className="flex gap-6 mb-6 -mt-4">
            <NumericFormField<ProcessProinfaFormData>
              id="referenceYear"
              field="referenceYear"
              label="Ano referente"
              formatProps={{
                placeholder: 'AAAA',
                format: '####',
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: undefined,
                thousandSeparator: undefined,
                decimalScale: 0,
                isNumericString: true,
              }}
              options={{ required: { value: true, message: 'O ano de referência é obrigatório' } }}
            />
          </div>
          <div className="w-[34.5rem]">
            <FileField<ProcessProinfaFormData>
              field="file"
              label="Importar dados"
              id="file"
              description="Envie a tabela com valores do PROINFA em XLSX ou XLS."
              maximumFileSizeInMB={8}
              onChangeFiles={(file) => setUploadedFile(file as File)}
              allowedFileTypes={['.xlsx', '.xls']}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;

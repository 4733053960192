/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);
import { Line } from 'react-chartjs-2';

interface IProjectionChartProps {
  labels: string[];
  datasets: any[];
  options: any;
  plugins?: any;
}

const LineChart: React.FC<IProjectionChartProps> = ({ labels, datasets, options, plugins }) => (
  <Line data={{ labels: labels, datasets: datasets }} options={options} plugins={plugins} />
);

export default LineChart;

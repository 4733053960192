import { OperationVariables, QueryResult } from '@apollo/client';
import { EnergyTypeEnum } from '@contexts/unit';

export type EnergyAuditConsumptionContextType = {
  getMonthlyControlMeasurementByUnitIdHandler: (
    unitId: string,
    monthYear: string,
  ) => Promise<QueryResult<MonthlyControlMeasurementByUnitId>>;
  getUnitConsumptionMeasuresPerHourHandler: (
    unitId: string,
    monthYear: string,
  ) => Promise<QueryResult<any, OperationVariables>>;
  getAllControlMeasurementByUnitIdHandler: (unitId: string) => Promise<QueryResult<any, OperationVariables>>;
  updateMeasurementAutomaticallyHandler: (unitId: string, monthYear: string) => Promise<MesaurementsFeedbackType>;
  updateDailyConsumptionOfUnitsManuallyHandler: (
    unitId: string,
    monthYear: string,
  ) => Promise<MesaurementsFeedbackType>;
  updateMeasurementManuallyHandler: (measurementValue: string, hour: string) => Promise<any>;
  setConsolidatedMeasurementDocId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMonthlyControlMeasurementByUnitId: React.Dispatch<
    React.SetStateAction<Array<MonthlyControlMeasurementByUnitId> | undefined>
  >;
  monthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId> | undefined;
  unitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour> | undefined;
  consolidatedMeasurementById: ConsolidatedMeasurement | undefined;
  loading: boolean;
  mutationLoading: boolean;
  handleDownloadExcel: (data: DownloadExcelPayload) => Promise<MesaurementsFeedbackType>;
  consumptionsPeriods: string[];
};

export type MesaurementsFeedbackType =
  | 'UPDATING_MISSING_HOURS'
  | 'MISSING_HOURS_UPDATED_FAILED'
  | 'MISSING_HOURS_UPDATED_SUCCESS'
  | 'UPDATING_DAILY_CONSUMPTIONS'
  | 'DAILY_CONSUMPTIONS_UPDATED_FAILED'
  | 'DAILY_CONSUMPTIONS_UPDATED_SUCCESS'
  | 'EXCEL_DONWLOAD_SUCCESS'
  | 'EXCEL_DONWLOAD_ERROR';

export interface ConsolidatedMeasurementByIdGraphQLData {
  getConsolidatedMeasurementById: ConsolidatedMeasurement;
}

export interface UpdateMeasurementAutomaticallyManagerGraphQLData {
  updateMeasurementAutomatically: boolean;
}

export interface UpdateDailyConsumptionOfUnitsManuallyManagerGraphQLData {
  updateDailyConsumptionOfUnitsManually: boolean;
}
export interface UpdateMeasurementManuallyManagerGraphQLData {
  updateTimeManually: ConsolidatedMeasurement;
}

export interface MonthlyControlMeasurementByUnitIdGraphQLData {
  getMonthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId>;
}
export interface UnitConsumptionMeasuresPerHourGraphQLData {
  getUnitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour>;
}

export interface EnergyAuditUnits {
  consumptionAmount: number;
  freeMarketContractsAmount: number;
  lostsAmount: number;
  neededAmount: number;
  overplusOrExposureAmount: number;
  proinfa: number;
  missingHours?: number;
  unit: {
    id: string;
    name: string;
    cceeProfile: {
      name: string;
      idCcee: string;
    };
  };
}

export enum ConsumptionReadingStatus {
  NOT_COMPLETE = 'NOT_COMPLETE',
  COMPLETE = 'COMPLETE',
}

export interface EnergyContract {
  id: string;
  energyType: EnergyTypeEnum | null;
  idCceeTrader?: string;
  perfilCceeTrader?: string;
  lowerFlexibility: string;
  upperFlexibility: string;
  upperFlexTotal: string;
  lowerFlexTotal: string;
  seasonality?: string;
  billedFlex?: string;
  trader: EnergyAuditTrader;
}

export interface EnergyAuditTrader {
  id?: string;
  name?: string;
  profileCcee?: string;
  idCcee?: string;
}

export interface MonthlyControlMeasurementByUnitId {
  id: string;
  unit: {
    group: {
      name: string;
    };
    energyContract: {
      id: string;
    };
    name: string;
  };
  consolidatedMeasurementDocId: string;
  monthYear: string;
  totalMissingHours: number;
  totalConsumption: number;
  date: string;
  createdAt?: string;
}

export enum EmailsTabTypes {
  TRADER = 'TRADER',
  CLIENT = 'CLIENT',
  RECIPIENTS = 'RECIPIENTS',
}

export interface ConsolidatedMeasurement {
  id: string;
  profileCcee: string;
  measuringPoint: string;
  weekday: number;
  monthYear: string;
  datetimeMeasurement: Array<DatetimeMeasurement>;
  createdAt: string;
}
export interface UnitConsumptionMeasuresPerHour {
  id: string;
  datetimeMeasurement: Array<DatetimeMeasurement>;
}

export interface DatetimeMeasurement {
  datetime: string;
  subType: string;
  status: DatetimeMeasurementStatus;
  activeGeneration: string;
  reactiveGeneration: string;
  activeConsumption: string;
  reactiveConsumption: string;
  source: Source;
}

export enum Source {
  ccee = 'ccee',
  clarke = 'clarke',
}

export enum DatetimeMeasurementStatus {
  HCC = 'HCC',
  HCI = 'HCI',
  HEC = 'HEC',
  HEI = 'HEI',
  HIF = 'HIF',
  HE = 'HE',
  HEE = 'HEE',
  HEA = 'HEA',
  HR = 'HR',
}

export interface DownloadExcelPayload {
  unitId: string;
  unitName: string;
  monthYear: string;
}

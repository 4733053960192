import { UserInfo } from '@contexts/user';
import React from 'react';

interface AvatarWithTextProps {
  entry: UserInfo | undefined;
}

const AvatarWithText: React.FC<AvatarWithTextProps> = ({ entry }) => {
  return (
    <div className="flex gap-3 items-center font-normal text-paragraph-medium">
      <img className="w-5 rounded-full" src={entry?.pictureUrl} />
      {entry?.name}
    </div>
  );
};

export default AvatarWithText;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import AccordionItem from '@components/atoms/commercial-groups/accordion-item';
import { Table, ColumnOptions } from '@clarke-energia/foton';
import { Trader } from '@contexts/trader';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface TradersTableProps {
  isFetchingItems: boolean;
  data: Trader[];
  columnDefinitions: ColumnOptions<Trader>[];
}

export const TradersTable: React.FC<TradersTableProps> = ({ isFetchingItems, data, columnDefinitions }) => {
  return (
    <div className="flex flex-col space-y-10">
      <AccordionItem label="Fornecedoras" labelSubscript={`${data.length}`} testId="data-accordion">
        <div className="overflow-x-auto">
          {isFetchingItems ? <RowSkeleton /> : <Table data={data} tableColumns={columnDefinitions} />}
        </div>
      </AccordionItem>
    </div>
  );
};

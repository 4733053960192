import { Option } from '@components/molecules/form/form-sync-group-asana';
import {
  CommercialGroup,
  MigrationReport,
  SendBatchMutationReportsInput,
  SyncMutationReportsInput,
  SelectedFilter,
} from './types';

export const parseGroupsOptions = (groups: CommercialGroup[]): Option[] =>
  groups.map(({ id, name }) => ({ label: name, value: id }));

export const parsePayloadSyncBatch = (migrationBatch: MigrationReport[]): SyncMutationReportsInput[] =>
  migrationBatch
    .filter(({ group }) => group?.id)
    .map(({ thirdPartyId, group }) => ({ groupId: group.id || '', thirdPartyId }));

export const parsePayloadSendBatch = (migrationBatch: MigrationReport[]): SendBatchMutationReportsInput[] =>
  migrationBatch.map(({ id, emails, group: { type } }) => ({ id, recipients: emails, groupMigrationType: type }));

export const parseSelectedGroupsFilter = (groupsFilter: SelectedFilter[]): string[] =>
  groupsFilter.map(({ value }) => value);

import { gql } from '@apollo/client';

export const GET_TRADERS_QUERY = gql`
  query {
    traders {
      data {
        id
        name
        description
        createdAt
        traderType
        assets
        website
        companyFoundationYear
        score
        documents {
          label
          uri
          docType
        }
      }
    }
  }
`;

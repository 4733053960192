import React from 'react';

import { CreateCceeContractPayload } from '@contexts/ccee-contract/types';
import { SelectFormField } from '@components/atoms/form';
import { ComboBoxFormField } from '../form/combobox';

interface Options {
  optionLabel: string;
  value: string;
}

interface GroupProfileFieldsProps {
  groupOptions: { label: string; value: string }[];
  profileOptions: Array<Options>;
  energyTypeOptions: Array<Options>;
}

const GroupProfileFields = ({
  profileOptions,
  groupOptions,
  energyTypeOptions,
}: GroupProfileFieldsProps): JSX.Element => {
  return (
    <div className="col-end-5 gap-x-6 gap-y-6 content-end w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
      <ComboBoxFormField<CreateCceeContractPayload>
        label="Grupo Comercial"
        field="groupId"
        id="groupId"
        inputOptions={groupOptions}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
      <SelectFormField<CreateCceeContractPayload>
        label="Perfil CCEE"
        field="idProfileCcee"
        id="idProfileCcee"
        inputOptions={profileOptions}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
      <SelectFormField<CreateCceeContractPayload>
        label="Tipo de energia"
        field="energyType"
        id="energyType"
        inputOptions={energyTypeOptions}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
    </div>
  );
};

export default GroupProfileFields;

import React from 'react';
import { User } from '@auth0/auth0-react';
import { useLazyQuery, FetchResult, useMutation, QueryResult } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { UserInfo } from '@contexts/user/types';
import { CommercialGroup } from '@contexts/commercial-group';

import { useAuth } from '@src/ApolloWrapper';

import { CREATE_GROUPS_USER, DELETE_GROUPS_USER, CATEGORIZE_GROUP_USER_ROLES } from './mutations';
import { GET_USER_GROUPS_QUERY } from './queries';
export * from './types';

export type GroupUserContextType = {
  group: CommercialGroup | undefined;
  groupUsers: UserInfo[];
  loading: boolean;
  setCommercialGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  getGroupHandler: () => Promise<QueryResult<any, { groupId?: string }>>;
  createGroupsUserHandler: (usersId: string[]) => Promise<any>;
  deleteGroupsUserHandler: (userId: string) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  categorizeGroupsUserRolesHandler: (
    userId: string,
    roles: string[],
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  loadingRolesForClientUsers: boolean;
  loadingGetGroup: boolean;
};

export const GroupUserContext = createContext({} as GroupUserContextType);

interface Provider {
  children: React.ReactNode;
}

const GroupUsersProvider: React.FC<Provider> = ({ children }) => {
  const { auth0ApiService } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(true);

  const [groupId, setCommercialGroupId] = React.useState<string>();
  const [group, setGroup] = React.useState<CommercialGroup>();

  const [groupUsers, setGroupUsers] = React.useState<Array<UserInfo>>([]);
  const getGroupOnCompletedHandler = (response: Record<string, any>) => {
    const group = response.group;
    setGroup(group);
    const groupUsers = [...group.users];

    const groupUsersId: string[] = groupUsers.map((user) => user.user.id);

    if (groupUsersId?.length > 0) {
      auth0ApiService?.getUsersByIdList(groupUsersId).then((auth0Response) => {
        const parsedUsersList: User[] = auth0Response.data;

        const parsedGroupUsers = parsedUsersList.map((user) => {
          const customerUser = groupUsers.find(
            (customerUser) => customerUser.user.id == (user.app_metadata.uuid as string),
          );
          return {
            id: user.app_metadata.uuid as string,
            email: user.email,
            isAdmin: /.*@clarke.com(.br)?/.test(user.email as string),
            name: user.name,
            phone: user.phone_number,
            trader: null,
            categoryRoles: customerUser?.roles,
            pictureUrl: user.picture,
          } as UserInfo;
        });

        setGroupUsers(parsedGroupUsers);
        setLoading(false);
      });
    } else setLoading(false);
  };

  const [getGroup, { loading: loadingGetGroup }] = useLazyQuery<any, { groupId?: string }>(GET_USER_GROUPS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: getGroupOnCompletedHandler,
    onError: () => {
      setGroupUsers([]);
    },
    variables: {
      groupId,
    },
  });

  const [createGroupsUser] = useMutation(CREATE_GROUPS_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [deleteGroupsUser] = useMutation(DELETE_GROUPS_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [categorizeGroupsUserRoles, { loading: loadingRolesForClientUsers }] = useMutation(
    CATEGORIZE_GROUP_USER_ROLES,
    {
      fetchPolicy: 'network-only',
    },
  );

  function getGroupHandler() {
    setLoading(true);
    return getGroup();
  }

  function createGroupsUserHandler(usersId: string[]) {
    const createGroupUsersPromises = usersId.map((userId) => {
      return createGroupsUser({
        variables: {
          input: {
            groupId: groupId,
            userId: userId,
          },
        },
      });
    });

    return Promise.all(createGroupUsersPromises);
  }

  function deleteGroupsUserHandler(userId: string) {
    setLoading(true);
    return deleteGroupsUser({
      variables: {
        input: {
          groupId: groupId,
          userId: userId,
        },
      },
    });
  }

  function categorizeGroupsUserRolesHandler(userId: string, roles: string[]) {
    return categorizeGroupsUserRoles({
      variables: {
        input: {
          userId: userId,
          roles: roles,
        },
      },
    });
  }

  React.useEffect(() => {
    if (groupId) {
      getGroup();
    }
  }, [groupId]);

  return (
    <GroupUserContext.Provider
      value={{
        groupUsers,
        getGroupHandler,
        group,
        loading,
        setCommercialGroupId,
        createGroupsUserHandler,
        deleteGroupsUserHandler,
        categorizeGroupsUserRolesHandler,
        loadingRolesForClientUsers,
        loadingGetGroup,
      }}
    >
      {children}
    </GroupUserContext.Provider>
  );
};

export default GroupUsersProvider;

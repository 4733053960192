import React from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { ButtonIcon, ColumnOptions, HeroIcon, Layout, Modal, SearchBar, Table } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { HOME_PATH, COMMERCIAL_GROUP_PATH } from '@routers/constants';
import { formatFilePath, getFileNameFromPath } from '@utils/text';
import { File, FileData } from '@contexts/files/types';
import { useFile } from '@hooks/use-file';

import FormUpsertFile from '@components/molecules/form/form-upsert-document';
import FolderNavigationTree from '@components/molecules/folder-navigation-tree';

const CommercialGroupFiles: React.FC = () => {
  const { groupId } = useParams();
  const { groupFiles, isFetchingFiles, groupName, setGroupId } = useFile();

  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [modalUpsertFileOpen, setModalUpsertFileOpen] = React.useState<boolean>(false);

  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');

  const [isCreatingFolder, setIsCreatingFolder] = React.useState<boolean>(false);
  const [treeMode, setTreeMode] = React.useState<boolean>(true);
  const [localFilePath, setLocalFilePath] = React.useState<string>('documentos/');
  const [tableData, setTableData] = React.useState<File[]>([]);
  const filesTableColumns: ColumnOptions<File>[] = [
    {
      header: 'Nome',
      renderAsElement: (entry: File) => {
        return (
          <HeroIcon
            icon={entry.filePath.includes('.') ? 'DocumentTextIcon' : 'FolderIcon'}
            className="flex-shrink-0 w-5 h-5 text-neutral-70"
            aria-hidden="true"
            color="primary-60"
          />
        );
      },
    },
    { accessor: 'filePath', fixedWidthInRem: 100, renderAsElement: (entry: File) => entry.filePath },
    {
      renderAsElement: (entry) => {
        return (
          <>
            {entry.filePath.includes('.') && (
              <ButtonIcon
                kind="ghost"
                icon="PencilIcon"
                onClick={() => {
                  setSelectedFile(entry);
                  setModalUpsertFileOpen(true);
                  setIsCreatingFolder(false);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      fixedWidthInRem: 5,
      renderAsElement: (entry: File) => {
        return (
          <button
            onClick={() => {
              entry.filePath.includes('.') ? window.open(entry.url) : handleRowInteraction(entry.filePath);
            }}
          >
            {entry.filePath.includes('.') ? 'Baixar' : 'Abrir'}
          </button>
        );
      },
    },
  ];

  const handleRowInteraction = (item: string) => {
    if (item.includes('.')) {
      return;
    } else if (parseInt(item)) {
      setLocalFilePath(`${localFilePath}${item}/`);
    } else {
      setLocalFilePath(`${localFilePath}${item.replaceAll(' ', '_')}/`);
    }
  };

  const removeDuplicated = (data: File[] | undefined) => {
    const uniqueData: FileData[] = [];
    data?.forEach((item) => {
      if (!uniqueData.some((file) => file.filePath === item.filePath)) {
        uniqueData.push({ filePath: item.filePath, url: item.url });
      }
    });
    return uniqueData;
  };

  const handleSearchFileName = (filesList: File[]) => {
    const searchFilteredTableData = filesList
      .filter((item) => {
        return typedSearchTerm ? getFileNameFromPath(item.filePath).includes(typedSearchTerm.toLowerCase()) : item;
      })
      .filter((item) => {
        return getFileNameFromPath(item.filePath).includes('.') as boolean;
      })
      .map((item) => ({
        ...item,
        filePath: getFileNameFromPath(item.filePath),
      }));

    setTableData(searchFilteredTableData);
  };

  const folderNavigationHandler = () => {
    const filesFromLocalPath = groupFiles.filter((item) => item.filePath.includes(localFilePath));
    const parsedFilesFromLocalPath: File[] = removeDuplicated(
      filesFromLocalPath.map((item) => {
        return {
          ...item,
          filePath: formatFilePath(item.filePath.replace(localFilePath, '')),
          url: item.url,
        };
      }),
    ).map((item, index) => {
      return {
        id: index.toString(),
        filePath: item.filePath,
        url: item.url,
      };
    });
    if (parsedFilesFromLocalPath) setTableData(parsedFilesFromLocalPath);
  };

  React.useEffect(() => {
    trackEvent(eventLabels.GROUP_FILES_PAGE_VIEWED);
  }, []);

  React.useEffect(() => {
    if (groupId) setGroupId(groupId);
  }, [groupId]);

  React.useEffect(() => {
    if (typedSearchTerm) {
      setTreeMode(false);
      handleSearchFileName(groupFiles);
    } else {
      setTreeMode(true);
      folderNavigationHandler();
    }
  }, [localFilePath, groupFiles, typedSearchTerm, treeMode]);

  return (
    <Layout
      title="Documentos"
      loading={isFetchingFiles}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: groupName,
          url: COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string),
        },
        {
          label: 'Documentos',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          label: 'Nova Pasta',
          kind: 'secondary',
          onClick: () => {
            setModalUpsertFileOpen(true);
            setIsCreatingFolder(true);
          },
          size: 'small',
        },
        {
          label: 'Novo Documento',
          kind: 'primary',
          onClick: () => {
            setModalUpsertFileOpen(true);
            setIsCreatingFolder(false);
          },
          size: 'small',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="col-span-full h-full">
        <div className="mb-8 w-full md:w-1/2 lg:w-1/4">
          <SearchBar
            label=""
            placeholder="Filtrar por nome do arquivo"
            onTypedTermChangedCallback={(term) => setTypedSearchTerm(term)}
            onBlur={() => trackEvent(eventLabels.DETAILED_GROUP_PAGE_SEARCH_TRADER_USER)}
            className="bg-white w-[20rem]"
          />
        </div>
        {isFetchingFiles ? (
          <div className="flex flex-col gap-6 w-full h-12">
            <Skeleton height={24} count={7} />
          </div>
        ) : (
          tableData && (
            <div className="flex flex-col col-span-full gap-6 mx-5 h-screen lg:mx-0 min-h-[20rem]">
              {treeMode && (
                <div className="flex items-start">
                  {localFilePath.split('/').map((item, index) => {
                    return (
                      <FolderNavigationTree
                        key={index}
                        index={index}
                        localFilePath={localFilePath}
                        setFilePath={setLocalFilePath}
                        title={item}
                      />
                    );
                  })}
                </div>
              )}
              <Table data={tableData} tableColumns={filesTableColumns} />
            </div>
          )
        )}
      </div>
      <Modal
        open={modalUpsertFileOpen}
        setOpen={() => {
          setModalUpsertFileOpen(!modalUpsertFileOpen);
          !modalUpsertFileOpen && setSelectedFile(undefined);
        }}
        title={selectedFile ? 'Editar documento' : isCreatingFolder ? 'Criar Pasta' : 'Enviar documento'}
        description={
          !isCreatingFolder
            ? `Formulário para ${selectedFile ? 'a edição' : 'o envio'} de um documento pertencente ao grupo comercial.`
            : ''
        }
      >
        <FormUpsertFile
          dataToUpdate={selectedFile}
          localFilePath={localFilePath}
          isCreatingFolder={isCreatingFolder}
          tableData={tableData}
          setTableData={setTableData}
          setModalUpsertFileOpen={setModalUpsertFileOpen}
          onFormSuccess={() => {
            setModalUpsertFileOpen(false);
            location.reload();
          }}
        />
      </Modal>
    </Layout>
  );
};

export default CommercialGroupFiles;

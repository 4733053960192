import { useFormContext } from 'react-hook-form';

import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { CheckboxFormFieldContract } from '@components/atoms/form/fields/checkbox-form-field-contract';
import { IManagementContractPayload } from '@contexts/management-contract/types';

const ContractAdditionalServicesSection = (): JSX.Element => {
  const contractOptions: { value: string; optionLabel: string }[] = [
    { value: 'changeOfOwnership', optionLabel: 'Mudança de Propriedade' },
    { value: 'demandAdjustment', optionLabel: 'Ajuste de Demanda' },
    { value: 'tariffModalityChange', optionLabel: 'Mudança de Modalidade Tarifária' },
  ];

  const { watch } = useFormContext<IManagementContractPayload>();
  const additionalServices = watch('additionalServices');
  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection
          title="Serviços Adicionais"
          subtitle="Selecione os serviços adicionais que deseja incluir no contrato."
        />

        <div className="grid grid-cols-2 col-span-1 gap-6 items-baseline">
          {contractOptions.map((option) => (
            <CheckboxFormFieldContract<IManagementContractPayload>
              key={option.value}
              inputOptions={[
                {
                  id: option.value,
                  optionLabel: option.optionLabel,
                  value: option.value,
                  defaultChecked: Boolean(
                    (additionalServices && additionalServices![option.value as any]) ?? undefined,
                  ),
                },
              ]}
              label=""
              field={`additionalServices.${option.value as keyof IManagementContractPayload['additionalServices']}`}
            />
          ))}
        </div>
      </>
    </ContractSectionContainer>
  );
};

export default ContractAdditionalServicesSection;

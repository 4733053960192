import { Button } from '@clarke-energia/foton';
import { IContractFormUnitSection } from '@components/molecules/contracts/sections/contract-form-units-section/contract-form-unit-section';

type IContractUnitControlsSubsection = IContractFormUnitSection;

export function ContractUnitControlsSubsection({
  unitNum,
  removeUnit,
  appendUnit,
  unitsLength = 1,
}: IContractUnitControlsSubsection) {
  const isLastUnit = unitsLength - 1 === unitNum;
  const isOnlyOneUnit = unitsLength === 1;

  return (
    <div className="relative mt-3 md:col-span-2">
      <div className="flex absolute inset-0 items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="flex relative gap-8 justify-center">
        {!isOnlyOneUnit && removeUnit && (
          <Button kind="chip" label="Remover Unidade" size="small" type="button" onClick={() => removeUnit(unitNum)} />
        )}
        {isLastUnit && (
          <Button kind="secondary" label="+ Adicionar unidade" size="small" type="button" onClick={appendUnit} />
        )}
      </div>
    </div>
  );
}

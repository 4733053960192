import React from 'react';
import { ButtonIcon, HeroIcon } from '@clarke-energia/foton';

import { FinancialEvent, FinancialEventAttachmentOption } from '@contexts/financial-events';
import useFinancialEvents from '@hooks/use-financial-events';

interface IFinancialEventAttachmentRelationRow {
  attachments?: Array<FinancialEventAttachmentOption> | undefined;
  financialEvent: FinancialEvent;
  onChangeAttachment: (financialEventId: string, attachmentId?: string) => void;
}

function FinancialEventAttachmentRelationRow({
  attachments,
  financialEvent,
  onChangeAttachment,
}: IFinancialEventAttachmentRelationRow) {
  const { loading } = useFinancialEvents();

  const financialEventRelationedAttachmentId = financialEvent.attachment?.id;

  return (
    <div key={`financial-event-attachment-update`} className="flex items-center w-full">
      <div className="flex gap-4 items-center py-4 px-5 bg-white border-solid w-[264px] h-[40px] border-[1px] border-neutral-30 rounded-small">
        <HeroIcon icon="BuildingOfficeIcon" className="w-5 h-5" />
        <p className="w-full text-paragraph-medium truncate">{financialEvent.commercialGroup?.name}</p>
      </div>
      <div className="w-6 h-[1px] bg-neutral-30" />
      <select
        name="attachments-select"
        className="border-solid focus:border focus:outline-none text-paragraph-medium truncate w-[264px] h-[40px] border-[1px] border-neutral-30 rounded-small focus:border-primary-60 focus:ring-primary-60"
        onChange={(e) => financialEvent.id && onChangeAttachment(financialEvent.id, e.target.value)}
        disabled={loading}
      >
        <option hidden disabled selected={financialEventRelationedAttachmentId === undefined}>
          Selecione o anexo
        </option>
        {attachments?.map((attachment, index) => (
          <option
            key={`financial-event-attachment-option-${index}`}
            value={attachment.id}
            selected={financialEventRelationedAttachmentId === attachment.id}
            disabled={attachment.isRelationed}
          >
            {attachment.filename}
          </option>
        ))}
      </select>
      {financialEventRelationedAttachmentId !== undefined && (
        <ButtonIcon
          className="ml-4"
          icon="XMarkIcon"
          color="green"
          type="button"
          kind="ghost"
          disabled={loading}
          onClick={() => financialEvent.id && onChangeAttachment(financialEvent.id, undefined)}
        />
      )}
    </div>
  );
}

export default FinancialEventAttachmentRelationRow;

import React from 'react';

import { HorizontalGadget, HorizontalGadgetProps } from '@clarke-energia/foton';

interface ConsumptionsGagdegtsProps {
  hasMissingHours: boolean;
  gadgets: Array<HorizontalGadgetProps>;
}

const ConsumptionsGagdegts: React.FC<ConsumptionsGagdegtsProps> = ({ hasMissingHours, gadgets }) => {
  return (
    <div className={`mb-6 grid col-span-full gap-6 ${hasMissingHours ? 'grid-cols-2' : ''}`}>
      {gadgets.map((gadget) => (
        <HorizontalGadget key={gadget.title} {...gadget} />
      ))}
    </div>
  );
};

export default ConsumptionsGagdegts;

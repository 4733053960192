import React from 'react';

import LoadedFileDisplay from './loaded-file-display';
import FieldHeader, { FieldHeaderProps } from './field-header';
import { HeroIcon } from '@clarke-energia/foton';

const parseFiles = (rawFiles: FileList | null): File[] => {
  const validFiles: File[] = [];

  if (rawFiles === null) {
    return validFiles;
  }

  for (let i = 0; i < rawFiles.length; i++) {
    const file = rawFiles[i];
    validFiles.push(file);
  }
  return validFiles;
};

const onFileChange = (e: React.FormEvent<HTMLInputElement>, addFilesForUpload: (files: File[]) => void) => {
  e.preventDefault();
  e.stopPropagation();
  const parsedFiles = parseFiles(e.currentTarget.files);
  addFilesForUpload(parsedFiles);
};

interface FileStorage {
  [name: string]: File;
}

export interface FileInputFieldProps {
  id: string;
  name: string;
  label: FieldHeaderProps['label'];
  sublabel: FieldHeaderProps['sublabel'];
  innerDisplayProps: { label: string; sublabel: string };
  initialFiles: File[];
  onChange: (values: any) => void;
  inputRef: any;
  multiple?: React.InputHTMLAttributes<HTMLInputElement>['multiple'];
  fileSizeLimit: number;
  required?: boolean;
}
const FileInputField: React.FC<FileInputFieldProps> = ({
  id,
  name,
  label,
  sublabel,
  fileSizeLimit,
  innerDisplayProps,
  initialFiles,
  inputRef,
  onChange,
  multiple,
  required,
}) => {
  const initialState: FileStorage = {};
  initialFiles?.forEach((file) => {
    initialState[file.name] = file;
  });

  const [filesToUpload, setFilesToUpload] = React.useState<FileStorage>(initialState);
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  const addFilesForUpload = (files: File[]): void => {
    const newFiles: FileStorage = {};
    files?.forEach((file) => {
      newFiles[file.name] = file;
    });
    const updatedFiles = { ...filesToUpload, ...newFiles };
    setFilesToUpload(updatedFiles);
    onChange(Object.values(updatedFiles));
  };
  const deleteFileForUpload = (file: File): void => {
    if (Object.keys(filesToUpload).includes(file.name)) {
      const { [file.name]: _, ...remainingFiles } = filesToUpload;
      setFilesToUpload(remainingFiles);
      onChange(Object.values(remainingFiles));
    }
  };

  const redirectClickEventToInput = (e: React.SyntheticEvent) => {
    if (e.currentTarget === e.target) {
      /**
       * Any click inside this container must trigger the open file dialog event.
       * Remember that any click on any of the container children bubbles back here.
       * That's why this redirection must occur only when the event target and
       * currentTarget are the same.
       */
      inputFileRef.current?.click();
    }
  };

  return (
    <div className="flex flex-col pt-4">
      <FieldHeader {...{ label, sublabel, required }} />
      <div className="order-last">
        <div
          className="flex justify-center p-6 rounded-md border-2 border-dashed cursor-pointer border-neutral-60"
          onClick={redirectClickEventToInput}
        >
          <div className="space-y-1 text-center">
            <HeroIcon
              onClick={redirectClickEventToInput}
              icon="DocumentPlusIcon"
              extraClassNames="mx-auto h-12 w-12 text-neutral-40"
            />
            <div className="flex text-paragraph-medium text-neutral-60">
              <label htmlFor={id} className="relative w-full font-medium bg-white rounded-md">
                <span className="font-medium cursor-pointer text-primary-60">{innerDisplayProps.label}</span>
                <input
                  name={name}
                  type="file"
                  id={id}
                  data-cy={id}
                  className="sr-only"
                  onChangeCapture={(e) => onFileChange(e, addFilesForUpload)}
                  ref={(e) => {
                    inputRef(e);
                    inputFileRef.current = e;
                  }}
                  multiple={multiple}
                />
              </label>
            </div>
            <p className="text-paragraph-small text-neutral-50" onClick={redirectClickEventToInput}>
              {innerDisplayProps.sublabel}
            </p>
          </div>
        </div>
        {Object.keys(filesToUpload).map((name, index) => (
          <LoadedFileDisplay
            key={index}
            file={filesToUpload[name]}
            sizeLimit={fileSizeLimit}
            onDeleteHandler={() => deleteFileForUpload(filesToUpload[name])}
          />
        ))}
      </div>
    </div>
  );
};

export default FileInputField;

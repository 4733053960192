import React from 'react';

import { useFormContext } from 'react-hook-form';

import { CommissionModelType, CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { FormSection, SelectFormField, CheckboxFormField } from '@components/atoms/form';
import EnergyContractCommissionFields from '@components/atoms/energy-contract/energy-contract-commission-fields';

interface EnergyContractCommissionSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
}

const FormEnergyContractCommissionSection = ({
  startYear,
  yearsFields,
}: EnergyContractCommissionSectionProps): JSX.Element => {
  const { setValue, watch } = useFormContext();
  const commissionModel = watch('commissionModel');

  return (
    <div>
      <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
        <FormSection title="Comissão da Clarke" subtitle="Valores da Comissão da Clarke." />
        <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
          <CheckboxFormField<CreateEnergyContractPayload>
            field="advanceCommission"
            label="Comissão antecipada"
            id="advanceCommission"
          />
          <SelectFormField<CreateEnergyContractPayload>
            label="Comissão varia ano a ano?"
            field="commissionModel"
            id="commissionModel"
            inputOptions={[
              { optionLabel: 'Sim, há variação de comissão', value: CommissionModelType.VARIABLE },
              { optionLabel: 'Não', value: CommissionModelType.FIXED },
            ]}
            options={{ required: { value: true, message: 'Comissão é obrigatória' } }}
          />
          {commissionModel && (
            <EnergyContractCommissionFields
              yearsFields={yearsFields}
              startYear={startYear}
              setValue={setValue}
              commissionModel={commissionModel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormEnergyContractCommissionSection;

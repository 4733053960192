import React from 'react';

import CustomerInfo from '../customer-data';
import HighLightCards, { highlightsCard } from '../highLight-container';
import { customer, displayUnitCustomerInfo } from './report-helpers-method';

interface CustomerAndHighlightsSectionProps {
  customerInfo: customer;
  highlightsInfo: highlightsCard;
  isFetchingData: boolean;
}

const CustomerAndHighlightsSection = ({
  highlightsInfo,
  customerInfo,
  isFetchingData,
}: CustomerAndHighlightsSectionProps): JSX.Element => {
  return (
    <div className="col-span-1">
      <CustomerInfo infos={displayUnitCustomerInfo(customerInfo)} isFetchingData={isFetchingData} />
      <div className="mt-6">
        <HighLightCards {...highlightsInfo} isFetchingData={isFetchingData} />
      </div>
    </div>
  );
};

export default CustomerAndHighlightsSection;

export interface CceeProfile {
  id: string;
  name: string;
  idCcee: number;
  units: {
    id: string;
    name: string;
    group: {
      name: string;
      id: string;
    };
  }[];
}

export interface CceeProfileGraphQlResponse {
  cceeProfiles: { data: CceeProfile[] };
}

export interface CreateCceeProfilePayload {
  groupId?: string;
  name: string;
  idCcee: number;
  units?: string[];
  id: string;
}

export interface DeleteCceeProfilePayload {
  id: string;
}

export const EMPTY_CCEE_PROFILE: CreateCceeProfilePayload = {
  id: '',
  idCcee: NaN,
  name: '',
  units: [],
  groupId: '',
};

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ColumnOptions, Modal } from '@clarke-energia/foton';

import { ClarkeContractNotificationFeedbackContents } from '@utils/constants/common';
import { formatUuid } from '@utils/text';

import { EnergyContractTable as EnergyContractTableType } from '@contexts/energy-contract/types';
import { useEnergyContracts } from '@hooks/use-energy-contract';
import { useNotification } from '@hooks/use-notification';

import { contractFeedbackMessage, overflowMenuIcons } from '@pages/contracts/helper';
import OverflowMenuTable from '@components/atoms/contracts/overflow-table';
import EnergyContractTable from '@components/molecules/energy-contract/table';
import FormConfirmation from '@components/molecules/form/form-confirmation';

interface EnergyContractProps {
  typedSearchTerm: string;
}

const EnergyContractsList: React.FC<EnergyContractProps> = ({ typedSearchTerm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedback = location?.state;
  const {
    energyContracts,
    loading,
    deleteEnergyContractHandler,
    pagination,
    getEnergyContractsHandler,
    feedbackType,
    setFeedbackType,
  } = useEnergyContracts();
  const { setNotificationResponse, setOpenNotificationFeedback } = useNotification();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedEnergyContract, setSelectedEnergyContract] = useState<EnergyContractTableType | null>(null);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  React.useEffect(() => {
    const params = {
      page,
      term: typedSearchTerm,
    };
    getEnergyContractsHandler(params);
  }, [page, typedSearchTerm]);

  React.useEffect(() => {
    const feedbackMessage = feedback || feedbackType;
    if (feedbackMessage) setNotificationResponse(ClarkeContractNotificationFeedbackContents[feedbackMessage]);
    navigate(location.pathname, { state: null, replace: true });
  }, [feedback, navigate, feedbackType]);

  const energyContractsTableColumns: ColumnOptions<EnergyContractTableType>[] = [
    { accessor: 'id', header: 'Id do Contrato', renderAsElement: (entry) => formatUuid(entry.id) || '-' },
    { accessor: 'groupName', header: 'Cliente', renderAsElement: (entry) => entry.groupName },
    { accessor: 'contractedVolume', header: 'Volume contratado', renderAsElement: (entry) => entry.contractedVolume },
    { accessor: 'gmvAmount', header: 'GMV', renderAsElement: (entry) => entry.gmvAmount },
    { accessor: 'takeRateAmount', header: 'Take Rate', renderAsElement: (entry) => entry.takeRateAmount },
    { accessor: 'carbon', header: 'tCO2', renderAsElement: (entry) => entry.carbon },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <OverflowMenuTable
            show={openTableMenu && selectedEnergyContract?.id === entry.id}
            itens={overflowMenuIcons}
            buttonClick={() => {
              setOpenTableMenu(!openTableMenu);
              setSelectedEnergyContract(entry as EnergyContractTableType);
              setOpenNotificationFeedback(false);
              setFeedbackType(null);
            }}
            menuClick={(selectedIdx) => {
              if (selectedIdx === 0) return navigate(`/editar-contrato-energia/${entry.id}`);
              setOpenNotificationFeedback(false);
              setOpenConfirmationModal(true);
              setFeedbackType(null);
            }}
          />
        );
      },
    },
  ];

  const filteredEnergyContracts: EnergyContractTableType[] =
    energyContracts?.filter(({ groupName }) =>
      groupName != '-' ? groupName.toLowerCase().includes(typedSearchTerm.toLowerCase()) : [],
    ) || energyContracts;

  const confirmationDelete = async (selectedId: string) => {
    const {
      feedback: { message, tab },
    } = await deleteEnergyContractHandler(selectedId);
    setNotificationResponse(contractFeedbackMessage(tab, message));
    setOpenConfirmationModal(false);
    setOpenTableMenu(false);
  };

  return (
    <>
      <div className="col-span-full">
        <EnergyContractTable
          columns={energyContractsTableColumns}
          isFetchingContracts={loading}
          data={filteredEnergyContracts}
          pagination={{
            page,
            total: pagination.total,
            setCurrentPage: setPage,
            limit: pagination.limit,
          }}
        />
      </div>
      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar contrato de energia"
        description="Tem certeza que deseja excluir esse contrato de energia?"
      >
        <FormConfirmation
          onConfirmation={() => {
            if (selectedEnergyContract) {
              confirmationDelete(selectedEnergyContract.id);
            }
          }}
          onCancel={() => {
            setSelectedEnergyContract(null);
            setOpenConfirmationModal(false);
            setOpenTableMenu(false);
          }}
        />
      </Modal>
    </>
  );
};

export default EnergyContractsList;

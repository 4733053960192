import React from 'react';
import uniqby from 'lodash.uniqby';

import { useFormContext } from 'react-hook-form';
import useTraders from '@hooks/use-trader';
import { useCceeContract } from '@hooks/use-cce-contract';

import { CreateCceeContractPayload, EnergyContract } from '@contexts/ccee-contract/types';
import { EnergyTypeEnum } from '@utils/translators/proposal';

import { FormSection } from '@components/atoms/form';
import DatesFields from '@components/atoms/contract-ccee/dates-fields';
import VolumeFields from '@components/atoms/contract-ccee/volume-fields';
import TraderFields from '@components/atoms/contract-ccee/trader-fields';
import GroupProfileFields from '@components/atoms/contract-ccee/group-profile-fields';
import ContractFields from '@components/atoms/contract-ccee/contract-fields';

interface ShortDurationFieldsProps {
  loadingEnergyContracts: boolean;
  handleScrollComboBox: () => void;
  handleChangeComboBox: (serachItem: string | null) => void;
  contracts: EnergyContract[];
}

const ShortDurationFields = ({
  handleScrollComboBox,
  handleChangeComboBox,
  loadingEnergyContracts,
  contracts,
}: ShortDurationFieldsProps): JSX.Element => {
  const { traders } = useTraders();
  const { groups } = useCceeContract();
  const { watch } = useFormContext<CreateCceeContractPayload>();
  const groupId = watch('groupId');

  const contractType = [
    {
      optionLabel: 'CP Compra',
      value: 'SHORT_DURATION_PURCHASE',
    },
    {
      optionLabel: 'CP Venda',
      value: 'SHORT_DURATION_SALES',
    },
  ];

  const groupOptions = groups.length ? groups.map(({ id, name }) => ({ label: name, value: id })) : [];
  const traderOptions = traders.length ? traders.map(({ id, name }) => ({ label: name, value: id })) : [];
  const energyTypeOptions = Object.keys(EnergyTypeEnum).map((key) => ({
    optionLabel: EnergyTypeEnum[key as keyof typeof EnergyTypeEnum],
    value: key,
  }));
  const profilesGroup = () => {
    const unitsProfile = groups.find(({ id }) => groupId === id)?.units;

    if (unitsProfile) {
      const profiles = unitsProfile
        .filter(({ cceeProfile }) => cceeProfile)
        .map(({ cceeProfile: { id, name } }) => ({ optionLabel: name, value: id }));

      return uniqby(profiles, 'value');
    }

    return [];
  };

  return (
    <>
      <ContractFields
        contractTypeOptions={contractType}
        loadingEnergyContracts={loadingEnergyContracts}
        contracts={contracts}
        handleScrollComboBox={handleScrollComboBox}
        handleChangeComboBox={handleChangeComboBox}
      />
      <FormSection
        title="Sobre a transação"
        subtitle="Dados da energia, do comprador e do vendedor."
        className="mt-7"
      />
      <GroupProfileFields
        energyTypeOptions={energyTypeOptions}
        groupOptions={groupOptions}
        profileOptions={profilesGroup()}
      />
      <TraderFields traderOptions={traderOptions} />
      <VolumeFields />
      <DatesFields />
    </>
  );
};

export default ShortDurationFields;

import { Modal } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';

interface IModalGeneratedContract {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GeneratedContractModal = ({ isModalOpen, setIsModalOpen }: IModalGeneratedContract): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Modal
      open={isModalOpen !== undefined}
      setOpen={() => setIsModalOpen(false)}
      title="Contrato gerado"
      description="O contrato foi gerado e pode ser acessado por meio do Clarke Admin. Caso não tenha acesso, entre em contato com o time de Sales Ops."
      modalButtons={{
        primary: {
          label: 'Fechar',
          style: { backgroundColor: 'primary' },
          onClick: () => navigate(-1),
        },
      }}
    />
  );
};

export default GeneratedContractModal;

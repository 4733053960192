import dayjs from 'dayjs';
import { TagProps } from '@clarke-energia/foton';

import {
  DatetimeMeasurement,
  DatetimeMeasurementStatus,
  Source,
  UnitConsumptionMeasuresPerHour,
} from '@contexts/energy-audit-consumptions/types';
import { formatDateFromString } from '@utils/dayjs';

export const sumActiveConsumption = (
  unitConsumptions: UnitConsumptionMeasuresPerHour[] | undefined,
  source: 'clarke' | 'ccee',
) => {
  let sum = 0;

  if (!unitConsumptions) return sum;
  unitConsumptions.forEach((item) => {
    item.datetimeMeasurement.forEach((measurement) => {
      if (measurement.subType === 'L' && measurement.source === source) {
        sum += parseFloat(measurement.activeConsumption);
      }
    });
  });
  return sum;
};

export const diplayTagMissingHours = (
  totalMissingHours: number | undefined,
  consumptionProjected: number,
): undefined | TagProps => {
  if (totalMissingHours === 0 || consumptionProjected > 0) return undefined;

  return {
    color: 'yellow',
    kind: 'default',
    label: `${totalMissingHours ? `${totalMissingHours}h faltantes` : ''}`,
  };
};

export const getAllMonthDatetimeMeasurements = (unitConsumptionByHour: UnitConsumptionMeasuresPerHour[]) => {
  const allMonthDatetimeMeasurements: DatetimeMeasurement[] = [];
  unitConsumptionByHour.map((item) => item.datetimeMeasurement.map((item) => allMonthDatetimeMeasurements.push(item)));
  return allMonthDatetimeMeasurements;
};

export const createLineChartLabels = (allMonthDatetimeMeasurements: DatetimeMeasurement[]) => {
  const startDate = dayjs(allMonthDatetimeMeasurements[0]?.datetime);
  return allMonthDatetimeMeasurements.map((item) =>
    dayjs(item?.datetime) === startDate ? '1' : String(dayjs(item?.datetime).diff(startDate) / 3600000),
  );
};

export const createLineChartPrimaryValues = (allMonthDatetimeMeasurements: DatetimeMeasurement[]) => {
  return allMonthDatetimeMeasurements.map((item) =>
    item.status === DatetimeMeasurementStatus.HCC ? Number(item.activeConsumption) * 1000 : null,
  );
};

export const createLineChartSecondaryValues = (allMonthDatetimeMeasurements: DatetimeMeasurement[]) => {
  return allMonthDatetimeMeasurements.map((item) =>
    item.status !== DatetimeMeasurementStatus.HCC && item.source === Source.clarke
      ? Number(item.activeConsumption) * 1000
      : null,
  );
};

export const formatToMonthYear = (date: string) => {
  const monthYearName = formatDateFromString(date, 'MM/YYYY', 'MMMM YYYY');
  return monthYearName.charAt(0).toUpperCase() + monthYearName.slice(1);
};

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Table, ColumnOptions, Pagination } from '@clarke-energia/foton';
import { EnergyContractTable as EnergyContractTableType } from '@contexts/energy-contract/types';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

type PaginationProps = {
  page: number;
  total: number;
  limit: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export interface EnergyContractTableProps {
  isFetchingContracts: boolean;
  data: EnergyContractTableType[];
  columns: ColumnOptions<EnergyContractTableType>[];
  pagination: PaginationProps;
}

const EnergyContractTable: React.FC<EnergyContractTableProps> = ({
  isFetchingContracts,
  data,
  columns,
  pagination,
}) => {
  return (
    <div className="flex flex-col space-y-10">
      {isFetchingContracts ? (
        <RowSkeleton />
      ) : (
        <>
          <Table data={data} tableColumns={columns} className="-mb-[3.5rem]" />
          {pagination.total > pagination.limit ? (
            <Pagination
              totalRows={pagination.total}
              rowsByPage={pagination.limit}
              currentPage={pagination.page}
              setCurrentPage={pagination.setCurrentPage}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default EnergyContractTable;

import { formatDecimal } from '@utils/text';
import { MonthlyValues, Proinfa, ProinfaTableData } from './types';

export const months: Record<string, string> = {
  January: 'Janeiro',
  February: 'Fevereiro',
  March: 'Março',
  April: 'Abril',
  May: 'Maio',
  June: 'Junho',
  July: 'Julho',
  August: 'Agosto',
  September: 'Setembro',
  October: 'Outubro',
  November: 'Novembro',
  December: 'Dezembro',
};

export const parserTableData = (data: Proinfa[]): ProinfaTableData[] => {
  return data.map(({ unit: { name }, monthlyValues, year, id }) => ({
    year,
    id,
    unit: name,
    monthValue: getValuesByMonthName(monthlyValues),
  }));
};

export const getValuesByMonthName = (values: MonthlyValues[]) => {
  const monthlyValues: Record<string, string> = {};

  values.forEach((item) => {
    const month = months[item.month];
    monthlyValues[month] = formatDecimal(item.value, 3);
  });
  return monthlyValues;
};

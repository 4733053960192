import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_EDCS_LIST_QUERY } from './queries';
import { EDC, EDCListResponseGraphQL, State } from './types';
export * from './types';

export function useEdcs() {
  const [edcs, setEdcs] = useState<EDC[]>([]);
  useQuery<EDCListResponseGraphQL>(GET_EDCS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const parsedEDCs: EDC[] = data.edcs.data.map(
        ({
          id,
          name,
          legalName,
          label,
          state,
          tariffAdjustmentDate,
          removeIcms,
          fixedPriceGuaranteedDiscountRelation,
          createdAt,
          updatedAt,
        }) => ({
          id: parseInt(id),
          name,
          legalName: legalName || '',
          label,
          state: state as unknown as State,
          tariffAdjustmentDate,
          removeIcms,
          fixedPriceGuaranteedDiscountRelation,
          createdAt: new Date(createdAt),
          updatedAt: new Date(updatedAt),
        }),
      );
      setEdcs(parsedEDCs);
    },
    onError: () => {
      setEdcs([]);
    },
  });

  return edcs;
}

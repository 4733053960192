import React from 'react';

import { NumericFormField } from '@components/atoms/form';
import FormSectionHeader from '@components/molecules/general/section-form';
import { acrInputs } from '../helpers';
import { EconomyReportPayload } from '@contexts/economy-reports/types';

const SimulationAcrInputs = () => {
  return (
    <div className="grid grid-cols-3 gap-6 lg:mt-0">
      <FormSectionHeader
        title="Simulação Mercado Cativo"
        text="Seção para simular o custo no mercado cativo. Insira as informações obtidas na Fatura de Distribuição do cliente sobre o consumo dele no mês."
      />
      <div className="grid grid-cols-3 col-span-3 gap-6 lg:col-span-2">
        {acrInputs.map(
          ({ field, allowNegative, label, placeholder, required, decimalScale, suffix, prefix, tooltipContent }) => (
            <div className="col-span-3 lg:col-span-1" key={field}>
              <NumericFormField<EconomyReportPayload>
                id={field}
                label={label}
                field={field}
                tooltipContent={tooltipContent}
                formatProps={{
                  placeholder,
                  mask: '_',
                  allowNegative,
                  fixedDecimalScale: true,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale,
                  ...(suffix && { suffix }),
                  ...(prefix && { prefix }),
                }}
                options={{
                  valueAsNumber: false,
                  ...(required && { required: { value: true, message: 'Campo obrigatorio' } }),
                }}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default SimulationAcrInputs;

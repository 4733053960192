import React, { useState } from 'react';
import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { CceeProfileGraphQlResponse, CceeProfile, CreateCceeProfilePayload } from './types';
import { GET_CCEE_PROFILES_QUERY } from './queries';
import { CREATE_CCEE_PROFILE, DELETE_CCEE_PROFILE, UPDATE_CCEE_PROFILE } from './mutation';

export type CceeProfileContextType = {
  createCceeProfileHandler: (CceeProfile: CreateCceeProfilePayload) => Promise<{ id: string | null }>;
  updateCceeProfileHandler: (CceeProfile: CreateCceeProfilePayload) => Promise<{ id: string | null }>;
  deleteCceeProfileHandler: (
    CceeProfileId: string | null,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  cceeProfiles: CceeProfile[];
  loading: boolean;
};

export const CceeProfileContext = createContext({} as CceeProfileContextType);

interface Provider {
  children: React.ReactNode;
}

const CceeProfileProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [cceeProfiles, setCceeProfiles] = useState<Array<CceeProfile>>([]);
  const [getCceeProfiles, { called: calledCceeProfile, loading: listLoading }] =
    useLazyQuery<CceeProfileGraphQlResponse>(GET_CCEE_PROFILES_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCceeProfiles(data.cceeProfiles.data);
      },
      onError: () => {
        setCceeProfiles([]);
      },
    });

  const [createCceeProfile, { loading: creationLoading }] = useMutation(CREATE_CCEE_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const [updateCceeProfile, { loading: updateLoading }] = useMutation(UPDATE_CCEE_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const [deleteCceeProfile, { loading: deleteLoading }] = useMutation(DELETE_CCEE_PROFILE, {
    fetchPolicy: 'network-only',
  });

  if (!calledCceeProfile) getCceeProfiles();

  function createCceeProfileHandler(CceeProfile: CreateCceeProfilePayload) {
    return createCceeProfile({
      variables: {
        input: {
          name: CceeProfile.name,
          idCcee: CceeProfile.idCcee,
          units: CceeProfile.units,
        },
      },

      refetchQueries: [GET_CCEE_PROFILES_QUERY],
    })
      .then((response) => {
        return { id: response.data['createCceeProfile']['id'] };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function updateCceeProfileHandler(CceeProfile: CreateCceeProfilePayload) {
    return updateCceeProfile({
      variables: {
        input: {
          name: CceeProfile.name,
          idCcee: CceeProfile.idCcee,
          units: CceeProfile.units,
          id: CceeProfile.id,
        },
      },
      refetchQueries: [GET_CCEE_PROFILES_QUERY],
    })
      .then((response) => {
        return { id: response.data['createCceeProfile']['id'] };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function deleteCceeProfileHandler(CceeProfileId: string | null) {
    return deleteCceeProfile({
      variables: {
        input: {
          id: CceeProfileId,
        },
      },
      refetchQueries: [GET_CCEE_PROFILES_QUERY],
    });
  }

  return (
    <CceeProfileContext.Provider
      value={{
        createCceeProfileHandler,
        deleteCceeProfileHandler,
        updateCceeProfileHandler,
        cceeProfiles,
        loading: creationLoading || updateLoading || deleteLoading || listLoading,
      }}
    >
      {children}
    </CceeProfileContext.Provider>
  );
};

export default CceeProfileProvider;

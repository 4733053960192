import { HeroIcon } from '@clarke-energia/foton';
import React from 'react';

const getHumanReadableSize = (size: number): string =>
  size <= 1024 ? `${size} bytes` : `${(size / 1024).toFixed(1)} kB`;

interface LoadedFileDisplayProps {
  file: File;
  sizeLimit: number;
  onDeleteHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const LoadedFileDisplay: React.FC<LoadedFileDisplayProps> = ({ file, sizeLimit, onDeleteHandler }) => {
  const isFileTooLarge = file.size >= sizeLimit;
  const currentSize = getHumanReadableSize(file.size);
  const maxSize = getHumanReadableSize(sizeLimit);

  const fileURL = URL.createObjectURL(file);
  return (
    <div
      data-label={file.name}
      className="flex flex-1 justify-between py-3 px-6 mt-2 rounded-md border border-black border-solid"
    >
      <div className="flex justify-between items-center space-x-2">
        <HeroIcon icon="PaperClipIcon" extraClassNames="w-5 h-5" />
        <div className="flex flex-col">
          <p
            className={`text-paragraph-medium ${isFileTooLarge ? 'text-danger-60' : 'text-black'}`}
          >{`${file.name} (${currentSize})`}</p>
          {isFileTooLarge && (
            <p className="text-paragraph-small text-danger-60">{`Arquivo grande demais. Tamanho máximo: ${maxSize}`}</p>
          )}
        </div>
        <a href={fileURL} download={file.name}>
          <HeroIcon icon="ArrowDownTrayIcon" extraClassNames="w-5 h-5" />
        </a>
        <button
          type="button"
          onClick={(e) => {
            URL.revokeObjectURL(fileURL);
            onDeleteHandler(e);
          }}
        >
          <HeroIcon icon="TrashIcon" extraClassNames="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default LoadedFileDisplay;

import { useContextSelector } from 'use-context-selector';
import { CceeProfileContextType, CceeProfileContext } from '@contexts/ccee-profile';

export const useCceeProfiles = (): CceeProfileContextType => {
  const createCceeProfileHandler = useContextSelector(
    CceeProfileContext,
    (cceeProfile) => cceeProfile.createCceeProfileHandler,
  );
  const cceeProfiles = useContextSelector(CceeProfileContext, (cceeProfile) => cceeProfile.cceeProfiles);
  const loading = useContextSelector(CceeProfileContext, (cceeProfile) => cceeProfile.loading);
  const updateCceeProfileHandler = useContextSelector(
    CceeProfileContext,
    (cceeProfile) => cceeProfile.updateCceeProfileHandler,
  );
  const deleteCceeProfileHandler = useContextSelector(
    CceeProfileContext,
    (cceeProfile) => cceeProfile.deleteCceeProfileHandler,
  );

  return {
    loading,
    cceeProfiles,
    createCceeProfileHandler,
    updateCceeProfileHandler,
    deleteCceeProfileHandler,
  };
};

import React, { useState } from 'react';

import { Button } from '@clarke-energia/foton';

import './style.css';

interface IForm {
  onCancel: () => void;
  onConfirmation: () => void;
  text?: string;
}

function FormConfirmation({ onConfirmation, onCancel, text }: IForm) {
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex gap-4 justify-between pt-10 -mb-8">
      <Button kind="secondary" label="Cancelar" onClick={onCancel} className="w-full" />
      <Button
        kind="primary"
        label={text ?? 'Excluir'}
        loading={loading}
        className={`w-full ${text ? '' : ' bg-red-500 hover:bg-red-400'}`}
        onClick={() => {
          setLoading(true);
          onConfirmation();
        }}
      />
    </div>
  );
}

export default FormConfirmation;

import React from 'react';

import { CreateCceeContractPayload } from '@contexts/ccee-contract/types';
import { NumericFormField } from '@components/atoms/form';

const DatesFields = (): JSX.Element => {
  const dateInputFormatProps = {
    placeholder: 'DD/MM/AAAA',
    format: '##/##/####',
    mask: '_',
    allowNegative: false,
    fixedDecimalScale: false,
    decimalSeparator: undefined,
    thousandSeparator: undefined,
    decimalScale: 0,
    isNumericString: true,
  };

  return (
    <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
      <NumericFormField<CreateCceeContractPayload>
        id="startDate"
        field="startDate"
        label="Início da vigencia"
        formatProps={dateInputFormatProps}
      />
      <NumericFormField<CreateCceeContractPayload>
        id="endDate"
        field="endDate"
        label="Fim da vigencia"
        formatProps={dateInputFormatProps}
      />
    </div>
  );
};

export default DatesFields;

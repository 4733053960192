import React from 'react';
import { Button } from '@clarke-energia/foton';
import moment from 'moment';

import { FinancialEventsBatch, FinancialEventType } from '@contexts/financial-events';
import { FinancialEventTypeTranslated, formatMonthAndYearDate } from '@utils/text';
import useFinancialEvents from '@hooks/use-financial-events';

interface IFormConfirmationFinancialEventsBatchSending {
  onCancel: () => void;
  onConfirmation: () => void;
  financialEventsBatch: FinancialEventsBatch;
}

const FormConfirmationFinancialEventsBatchSending: React.FC<IFormConfirmationFinancialEventsBatchSending> = ({
  onConfirmation,
  onCancel,
  financialEventsBatch,
}: IFormConfirmationFinancialEventsBatchSending) => {
  const { loading } = useFinancialEvents();
  const commercialGroups = financialEventsBatch.financialEvents
    ? financialEventsBatch.financialEvents.map((item) => item.commercialGroup?.name)
    : [];

  return (
    <div>
      <div className="py-4 border-y-[1px] border-neutral-30 text-paragraph-medium">
        <div className="flex gap-2 font-bold">
          <span>Evento:</span>
          <span>{FinancialEventTypeTranslated[financialEventsBatch.type as FinancialEventType]}</span>
        </div>
        <div className="flex gap-2">
          <span>Referência:</span>
          <span>{formatMonthAndYearDate(financialEventsBatch.referencePeriod as string)}</span>
        </div>
        <div className="flex gap-2">
          <span>Validade:</span>
          <span>{moment(financialEventsBatch.paymentDeadline).format('DD/MM/YYYY')}</span>
        </div>
        <div className="flex gap-2">
          <span>Grupos Comerciais:</span>
          <span>{commercialGroups.join(', ')}</span>
        </div>
      </div>
      <div className="flex gap-4 justify-between pt-10 -mb-8">
        <Button kind="primary" label={'Enviar evento'} className="w-full" onClick={onConfirmation} loading={loading} />
        <Button kind="secondary" label="Cancelar" onClick={onCancel} className="w-full" />
      </div>
    </div>
  );
};

export default FormConfirmationFinancialEventsBatchSending;

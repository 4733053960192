import React from 'react';
import HeaderLogoClarke from '@components/molecules/general/header-logo';

import CustomerAndHighlightsSection from '@components/molecules/economy-report/unit-report/first-section';
import FreeMktSection from '@components/molecules/economy-report/unit-report/free-marke-section';
import ConventionalMarketSection from '@components/molecules/economy-report/unit-report/conventional-market-section';
import useUnitEconomyReports from '@hooks/use-unit-economy-report';
import { useParams } from 'react-router-dom';
import { clarkeInfos } from '@utils/constants/common';
import Footer from '@components/molecules/general/footer';

const UnitEconomyReportPage: React.FC = () => {
  const { reportId } = useParams<string>();
  const {
    loading,
    getUnitEconomyReportHandler,
    unitReport: {
      currentSavings,
      energyTotalConsumption,
      energyTotalCost,
      savingsPercent,
      date,
      groupName,
      unitName,
      edcName,
      subgroup,
      tariffModality,
      contractedDemandOffPeak,
      contractedDemandPeak,
      cityAndState,
      freeMarketTableData,
      conventionalMarketTableData,
    },
  } = useUnitEconomyReports();

  React.useEffect(() => {
    if (reportId) getUnitEconomyReportHandler(reportId);
  }, []);

  const headerMobileTxt = `${groupName},aqui está o seu Relatório de Economia de ${date} para a ${unitName}`;
  return (
    <div className="px-5 mt-8 mb-14 xl:px-6 xl:mt-6 xl:mb-10">
      <HeaderLogoClarke
        text={`Relatório de Economia – ${date}`}
        mobileText={headerMobileTxt}
        isFecthingData={loading}
      />
      <div className="mt-14 lg:mt-36 xl:flex xl:gap-6">
        <CustomerAndHighlightsSection
          customerInfo={{
            groupName,
            unitName,
            edcName,
            subgroup,
            tariffModality,
            contractedDemandPeak,
            contractedDemandOffPeak,
            cityAndState,
          }}
          highlightsInfo={{
            currentSavings,
            energyTotalConsumption,
            energyTotalCost,
            savingsPercent,
            date,
          }}
          isFetchingData={loading}
        />

        <FreeMktSection freeMarketTableData={freeMarketTableData} isFetchingData={loading} />
        <div className="2xl:grow">
          <ConventionalMarketSection
            conventionalMarketTableData={conventionalMarketTableData}
            isFetchingData={loading}
          />
          {clarkeInfos.map((text) => (
            <div key={text} className="w-full xl:ml-auto xl:w-1/2">
              <Footer text={text} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnitEconomyReportPage;

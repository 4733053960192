import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query AllUsers {
    allUsers {
      id
      name
      email
      temporaryPassword
      createdAt
      phone
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GetUser($userId: ID!) {
    user(id: $userId) {
      id
      name
      email
      temporaryPassword
      createdAt
      phone
    }
  }
`;

export const GET_EMAILS_LOG_QUERY = gql`
  query GetLogsFromEmail($email: String!) {
    getLogsFromEmail(email: $email) {
      id
      name
      sender
      recipient
      cc
      bcc
      subject
      unitId
      templateName
      templateVersion
      serviceMetadata
      hasSent
      createdAt
    }
  }
`;

export const GET_GROUP_BY_USER = gql`
  query GetGroupByUser($getGroupByUserId: ID!) {
    getGroupByUser(id: $getGroupByUserId) {
      group {
        id
        name
      }
      id
      createdAt
    }
  }
`;

export const GET_ALL_USER_ROLES = gql`
  query GetAllRolesFromUser($getRolesFromUserId: ID!) {
    getAllRolesFromUser(id: $getRolesFromUserId) {
      roles
      appKey
    }
  }
`;

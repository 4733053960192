import { EnergyTypeEnum } from '@contexts/unit';
import { SubmarketEnum } from '@utils/translators/proposal';

export interface GetEnergyContractsParams {
  page: number;
  groupId?: string;
  term: string | null;
}

export enum EnergyTypeTableEnum {
  CONVENTIONAL = 'Convencional',
  INCENTIVIZED_0 = 'I0',
  INCENTIVIZED_50 = 'I5',
  INCENTIVIZED_80 = 'I8',
  INCENTIVIZED_100 = 'I1',
}

export enum ContractType {
  LONG_DURATION = 'Longo Prazo',
  SHORT_DURATION_SALES = 'Curto Prazo Venda',
  SHORT_DURATION_PURCHASE = 'Curto Prazo Compra',
}

export enum ContractTypeResponse {
  LONG_DURATION = 'Longo Prazo',
  SHORT_DURATION_SALES = 'CP Venda',
  SHORT_DURATION_PURCHASE = 'CP Compra',
}

export interface CceeProfile {
  name: string;
  id: string;
  units: {
    submarket: keyof typeof SubmarketEnum | null;
  }[];
}

export interface CceeContract {
  id: string;
  contractNumber: number;
  groupName: string;
  profileName: string;
  submarket: string;
  energyType: EnergyTypeTableEnum;
  trader: string;
  startDate: string;
  duration: ContractTypeResponse;
}

export interface CceeContractResponse {
  id: string;
  contractCceeId: number;
  profileCcee: CceeProfile;
  submarket?: string;
  energyType: keyof typeof EnergyTypeEnum;
  contractType: keyof typeof ContractType;
  startDate: string | null;
  endDate?: string;
  energyContract: {
    id: string;
    startSupplyDate: string;
    units: {
      submarket: keyof typeof SubmarketEnum | null;
    }[];
    profilesCcee?: {
      name: string;
    }[];
  };
  trader?: {
    id: string;
    name: string;
    cceeTraderId?: number;
    cceeTraderProfile?: string;
  };
  group?: {
    id: string;
    name: string;
  };

  energyPrice?: number;
  tradedVolume?: number;
}
export interface Group {
  id: string;
  name: string;
  units: {
    cceeProfile: {
      id: string;
      name: string;
    };
  }[];
}

export interface EnergyContractGraphQl {
  id: string;
  startSupplyDate: string;
  profilesCcee: {
    id: string;
    name: string;
  }[];
  units: {
    group: {
      id: string;
      name: string;
    };
  }[];
  trader: { id: string };
}

export interface EnergyContract {
  id: string;
  profilesCcee: {
    optionLabel: string;
    value: string;
  }[];
  group: {
    id: string;
    name: string;
  };
  retailTraderId: string;
}

export interface CceeContractGraphQlResponse {
  total: number;
  limit: number;
  cceeContracts: { data: CceeContractResponse[] };
}

export interface CceeContractGroupsFilterGraphQlResponse {
  total: number;
  limit: number;
  cceeContracts: { data: { group: { name: string; id: string } }[] };
}

export interface GroupsGraphQlResponse {
  groups: { data: Group[] };
}

export interface EnergyContractGraphQlResponse {
  total: number;
  limit: number;
  energyContracts: { data: EnergyContractGraphQl[] };
}

export interface EnergyContractParsed {
  totalPage: number;
  energyContracts: EnergyContract[];
}
export interface GroupFilterParsed {
  totalPage: number;
  groupsFilter: { value: string; label: string }[];
}

export interface CceeInputList {
  page: number;
  groupId: string | null;
  term: string | null;
  contractType: keyof typeof ContractType | null;
}

export interface CreateCceeContractPayload {
  duration?: 'SHORT' | 'LONG';
  id?: string;
  groupId: string | null;
  contractCceeId: number | null;
  idProfileCcee: string | null;
  energyType: keyof typeof EnergyTypeEnum | null;
  startDate: string | null;
  endDate: string | null;
  retailTraderId: string | null;
  energyContractId: string | null;
  energyPrice: number | null;
  tradedVolume: number | null;
  cceeTraderId: number | null;
  cceeTraderProfile: string | null;
  contractType: keyof typeof ContractType | null;
}

export interface DeleteCceeContractPayload {
  id: string;
}

export const EMPTY_CCEE_CONTRACT: CreateCceeContractPayload = {
  groupId: null,
  contractCceeId: null,
  idProfileCcee: null,
  energyType: null,
  startDate: null,
  endDate: null,
  retailTraderId: null,
  energyContractId: null,
  energyPrice: null,
  tradedVolume: null,
  cceeTraderId: null,
  cceeTraderProfile: null,
  contractType: null,
};

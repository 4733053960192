import { formatCurrency } from '@utils/text';
import React from 'react';

export interface FinanciaalEventsTableEERExpandedContentProps {
  eerFeePerProfiles: Record<string, number>;
}

const FinanciaalEventsTableEERExpandedContent: React.FC<FinanciaalEventsTableEERExpandedContentProps> = ({
  eerFeePerProfiles,
}) => {
  const eerFeePerProfilesParsed = Object.entries(eerFeePerProfiles).map((item) => {
    return {
      profile: item[0],
      value: formatCurrency(item[1] as number),
    };
  });

  return (
    <div className={'pl-9 pb-6'}>
      <div className="flex py-4 font-bold text-paragraph-small">
        <div className="w-[11rem] mr-[4.7rem] truncate">Perfil:</div>
        <div>Valor do aporte:</div>
      </div>
      {eerFeePerProfilesParsed.map((item, index) => {
        return (
          <div key={`FinanciaalEventsTableEERExpandedContent-${index}`} className="flex gap-2 text-paragraph-small">
            <div className="w-[11rem] mr-[4.8rem] truncate">{item.profile}</div>
            <div>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FinanciaalEventsTableEERExpandedContent;

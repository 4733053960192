import ContractSectionFieldsContainer from '@components/atoms/form/contract/contract-section-fields-container';
import { ContractUnitControlsSubsection } from '@components/atoms/form/contract/simulator-units-section/contract-unit-controls-subsection';
import { ContractUnitGeneralSubsection } from '@components/atoms/form/contract/simulator-units-section/contract-unit-general-subsection';

export interface IContractFormUnitSection {
  children?: JSX.Element;
  fieldsController?: Record<string, boolean>;
  removeUnit?: (unitNum: number) => void;
  appendUnit?: () => void;
  unitNum: number;
  unitsLength?: number;
}

function ContractFormUnitSection({
  fieldsController,
  unitNum,
  unitsLength,
  removeUnit,
  appendUnit,
}: IContractFormUnitSection) {
  return (
    <ContractSectionFieldsContainer>
      <>
        <ContractUnitGeneralSubsection {...{ unitNum, fieldsController }} />
        <ContractUnitControlsSubsection {...{ unitNum, appendUnit, removeUnit, unitsLength }} />
      </>
    </ContractSectionFieldsContainer>
  );
}

export default ContractFormUnitSection;

import React, { useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { FileField } from '@components/atoms/form/file/file-input';

import { Button } from '@clarke-energia/foton';

import { DocType, Trader, TraderTypeEnum, UploadTraderLogoData } from '@contexts/trader';
import useTraders from '@hooks/use-trader';

import { FormField, NumericFormField, SelectFormField } from '@components/atoms/form';

import './style.css';

interface IForm {
  dataToUpdate: Trader | null;
  onFormSuccess: () => void;
  onFormFail: () => void;
}

function FormUpsertTrader({ onFormSuccess, onFormFail, dataToUpdate }: IForm) {
  const methods = useForm<Trader>({
    defaultValues: dataToUpdate ? dataToUpdate : {},
  });
  const { updateTraderHandler, createTraderHandler, uploadLogoTraderHandler } = useTraders();
  const [loading, setLoading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState<File>();

  const onSubmit: SubmitHandler<Trader> = async (values) => {
    setLoading(true);
    try {
      const dataHandler = dataToUpdate ? updateTraderHandler : createTraderHandler;
      const response = await dataHandler(values);
      if (response?.id && uploadLogo) {
        const dataUploadLogo: UploadTraderLogoData = {
          entityId: response.id,
          entity: 'trader',
          label: uploadLogo.name,
          file: uploadLogo,
          docType: DocType.LOGO_IMAGE,
        };
        await uploadLogoTraderHandler(dataUploadLogo);
      }
      setLoading(false);
      onFormSuccess();
    } catch (error) {
      onFormFail();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        <FormField<Trader>
          id="name"
          label="Nome"
          field="name"
          placeholder="Fornecedora Exemplo"
          options={{ required: { value: true, message: 'O nome é obrigatório' } }}
        />
        <SelectFormField<Trader>
          id="traderType"
          label="Categoria"
          field="traderType"
          inputOptions={Object.entries(TraderTypeEnum).map((traderTypeEntry) => ({
            value: traderTypeEntry[0],
            optionLabel: traderTypeEntry[1],
          }))}
          options={{ required: { value: true, message: 'O tipo da fornecedora é obrigatório' } }}
        />
        <FormField<Trader>
          id="description"
          label="Descrição"
          field="description"
          placeholder="A fornecedora exemplo possui..."
        />
        <FormField<Trader>
          id="website"
          label="Website"
          field="website"
          placeholder="https://www.fornecedoraexemplo.com.br"
        />
        <FormField<Trader>
          id="assets"
          label="Ativos sob Gestão"
          field="assets"
          placeholder="Lista de ativos sobre gestão"
        />
        <NumericFormField<Trader>
          id="companyFoundationYear"
          field="companyFoundationYear"
          label="Ano de Fundação"
          formatProps={{
            placeholder: '2003',
            format: '####',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
          }}
        />
        <NumericFormField<Trader>
          id="risckScore"
          field="score"
          label="Score de Risco"
          formatProps={{
            placeholder: 'Digite o número do score',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 2,
          }}
        />
        <FileField<Trader>
          field="logo"
          label="Logo da Fornecedora"
          id="logo"
          description={dataToUpdate?.logoName || 'Envie imagem do logo em SVG ou PNG'}
          maximumFileSizeInMB={8}
          allowedFileTypes={['.svg', '.png']}
          onChangeFiles={(file) => setUploadLogo(file as File)}
        />
        <Button
          loading={loading}
          label={`${dataToUpdate ? 'Atualizar' : 'Criar'} fornecedora`}
          kind={'primary'}
          className="mt-5"
        />
      </form>
    </FormProvider>
  );
}

export default FormUpsertTrader;

import React from 'react';
import { Table } from '@clarke-energia/foton';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
} from '@contexts/unit-economy-report/types';

interface UnitReportTable {
  type: 'ACL' | 'ACR';
  tables: IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>[];
  totals: {
    totalValueWithoutTax: string;
    totalValue: string;
  };
}

const ReportTables = ({ tables, totals, type }: UnitReportTable): JSX.Element => {
  return (
    <>
      {tables &&
        Object.values(tables).map((table, index) => (
          <div key={index} className="border-t-2 border-black">
            {
              <Table<IGeneralReportTable | IMinimalGeneralReportTable>
                data={table.data}
                tableColumns={table.columnDefinitions}
                superHeader={table.superheader}
                lastRow={{
                  fontBold: true,
                }}
                className="border-t border-black"
              />
            }
          </div>
        ))}
      <div className="flex justify-between py-3 pr-5 pl-5 font-bold border-t-2 border-black bg-primary-60 text-paragraph-small">
        <div>{`Total Mercado ${type === 'ACL' ? 'Livre' : 'Cativo'}`} (s/impostos)</div>
        <div>{totals.totalValueWithoutTax}</div>
      </div>
      <div className="flex justify-between py-3 pr-5 pl-5 font-bold border-b-2 border-t border-black bg-primary-60 text-paragraph-small">
        <div>{`Total Mercado ${type === 'ACL' ? 'Livre' : 'Cativo'}`} (c/impostos)</div>
        <div>{totals.totalValue}</div>
      </div>
    </>
  );
};

export default ReportTables;

import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Button, HeroIcon, IconProps } from '@clarke-energia/foton';

import { IKickOffQuizInputs, KickOffProfileType, KickOffQuizStatus, KickOffStatus } from '@contexts/kick-off/types';
import { KickOffNotificationFeedbackContents } from '@utils/constants/common';
import useKickOff from '@hooks/use-kick-off';

import KickOffPanelMessagesIndexer from '@components/molecules/kick-off/helper';

import EmojiStatus from './emoji-status';

interface IKickOffNotAnsweredQuiz {
  kickOffStatus: keyof typeof KickOffStatus;
  isQuizUnavailableByKickOffStatus: boolean;
  isQuizAvailableByKickOffStatus: boolean;
  isQuizBeingAnsweredByKickOffStatus: boolean;
  isQuizReleasedByKickOffStatus: boolean;
  setOpenSendQuizEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  copyQuizLinkToClipboard: () => void;
  quizEmailSentAt?: string;
}

export const KickOffNotAnsweredQuiz = ({
  kickOffStatus,
  isQuizUnavailableByKickOffStatus,
  isQuizAvailableByKickOffStatus,
  isQuizBeingAnsweredByKickOffStatus,
  isQuizReleasedByKickOffStatus,
  quizEmailSentAt,
  setOpenSendQuizEmailModal,
  copyQuizLinkToClipboard,
}: IKickOffNotAnsweredQuiz) => {
  const parsedQuizEmailSentAt = quizEmailSentAt && moment(quizEmailSentAt);
  const quizEmailSentTime = parsedQuizEmailSentAt && parsedQuizEmailSentAt.format('H:m:s');
  const quizEmailSentDate = parsedQuizEmailSentAt && parsedQuizEmailSentAt.format('DD/MM/YYYY');

  const quizSectionMessageContent = KickOffPanelMessagesIndexer.QUIZ_SECTION[kickOffStatus];

  return (
    <div className="flex gap-5 w-full">
      {isQuizUnavailableByKickOffStatus && (
        <EmojiStatus icon={'FaceFrownIcon'} backgroundColorClassName="bg-danger-10" />
      )}
      <div className="flex flex-col gap-4">
        <p className="text-heading-small md:text-heading-xsmall">{quizSectionMessageContent?.title}</p>
        <p className="text-paragraph-small md:text-paragraph-medium">{quizSectionMessageContent?.description}</p>
        {isQuizAvailableByKickOffStatus && (
          <div className="flex gap-4">
            <Button
              kind="primary"
              label="Enviar email"
              size="small"
              onClick={() => setOpenSendQuizEmailModal(true)}
              className="mt-8 w-fit"
            />
            <Button
              kind="secondary"
              label="Copiar Link"
              size="small"
              onClick={copyQuizLinkToClipboard}
              className="mt-8 w-fit"
            />
          </div>
        )}
        {(isQuizBeingAnsweredByKickOffStatus || isQuizReleasedByKickOffStatus) && (
          <div className="flex gap-2 mt-8 text-paragraph-small text-neutral-50 md:text-paragraph-medium">
            {quizEmailSentTime && quizEmailSentDate && (
              <div className="inline">
                Email enviado em {quizEmailSentDate} às {quizEmailSentTime}.
              </div>
            )}
            <div className="inline underline cursor-pointer" onClick={() => setOpenSendQuizEmailModal(true)}>
              Reenviar email
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IQuizInputItem {
  index: number;
  question: string;
  answer?: JSX.Element | string;
}

const QuizInputItem = ({ index, question, answer }: IQuizInputItem) => (
  <div className="flex flex-col text-paragraph-small md:text-paragraph-medium">
    <div className="py-2 px-4 bg-neutral-10">
      Pergunta {index}: {question}
    </div>
    <div className="py-2 px-4 font-bold">Resposta: {answer}</div>
  </div>
);

interface IKickOffAnsweredQuiz {
  quizInputs?: IKickOffQuizInputs;
}

export const KickOffAnsweredQuiz = ({ quizInputs }: IKickOffAnsweredQuiz) => {
  const profileTypeIndexer: Record<keyof typeof KickOffProfileType, string> = {
    CONSERVATIVE_PLUS: '1',
    CONSERVATIVE: '2',
    MODERATE: '3',
    BOLD: '4',
    BOLD_PLUS: '5',
  };

  const getFirstInputAnswer = () => {
    if (quizInputs?.managementType) {
      return quizInputs?.managementType === 'SOPHISTICATED' ? 'Sim' : 'Não';
    }
  };

  const getSecondInputAnswer = () => {
    if (quizInputs?.profileType) {
      return quizInputs?.profileType && profileTypeIndexer[quizInputs.profileType];
    }
  };

  const getThirdInputAnswer = () => {
    if (quizInputs?.projectedPercentageChangeInConsumptionPerYear) {
      return (
        <div className="flex flex-col gap-2">
          {Object.entries(quizInputs?.projectedPercentageChangeInConsumptionPerYear).map((entry, index) => {
            const year = entry[0];
            const percentageChange = entry[1];
            return (
              <div key={index}>
                {year}: {numeral(percentageChange).format('% 0.0')}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const getFourthInputAnswer = () => {
    if (quizInputs?.iRecCertification) {
      return quizInputs?.iRecCertification === true
        ? 'Sim'
        : quizInputs?.iRecCertification === false
        ? 'Não'
        : undefined;
    }
  };

  return (
    <div className="flex flex-col w-full">
      <QuizInputItem
        index={1}
        question="Você está disposto a ter de 5% a 10% a mais de economia em troca de ter que abrir uma nova conta corrente no banco e ter uma carga operacional maior no financeiro da sua companhia?"
        answer={getFirstInputAnswer()}
      />
      <QuizInputItem
        index={2}
        question="Numa escala de 1 a 5, 1: ter 10% de economia totalmente segura; 5: assumir riscos e ter uma economia que pode variar de 5% a 40%."
        answer={getSecondInputAnswer()}
      />
      <QuizInputItem
        index={3}
        question="Nos próximos 3 anos, você consegue projetar alguma mudança no seu consumo?"
        answer={getThirdInputAnswer()}
      />
      <QuizInputItem
        index={4}
        question="Você está disposto a comprar energia de 1% a 5% mais cara em troca de um certificado internacionalmente aceito de consumidor de energia 100% renovável?"
        answer={getFourthInputAnswer()}
      />
    </div>
  );
};

interface IMoreOptionsPopUpOption {
  icon: IconProps['icon'];
  label: string;
  disabled?: boolean;
  onClick: () => void;
}
const MoreOptionsPopUpOption = ({ icon, label, disabled, onClick }: IMoreOptionsPopUpOption) => (
  <div
    className={`flex items-center py-3 pr-10 pl-5 w-full gap-[10px] bg-neutral-10 ${
      disabled ? 'cursor-not-allowed text-neutral-50' : 'cursor-pointer hover:bg-neutral-20 '
    }`}
    onClick={onClick}
  >
    <HeroIcon icon={icon} className="h-5" />
    <div>{label}</div>
  </div>
);

interface IKickOffQuizSectionMoreOptionsPopUp {
  quizLink?: string;
  quizStatus?: keyof typeof KickOffQuizStatus;
  setOpenMoreOptionsPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  copyQuizLinkToClipboard: () => void;
}

export const KickOffQuizSectionMoreOptionsPopUp = ({
  quizLink,
  quizStatus,
  setOpenMoreOptionsPopUp,
  copyQuizLinkToClipboard,
}: IKickOffQuizSectionMoreOptionsPopUp) => {
  const { updateKickOffQuizAvailabilityHandler, getKickOffByGroupIDHandler, setNotificationFeedBackContent } =
    useKickOff();

  const isQuizActive = quizStatus === 'ACTIVE';
  const updateQuizAvailability = () => {
    setNotificationFeedBackContent(KickOffNotificationFeedbackContents.QUIZ_AVAILABILITY_UPDATE_IN_PROGRESS);
    updateKickOffQuizAvailabilityHandler(isQuizActive ? 'INACTIVE' : 'ACTIVE')
      .then((response) =>
        setNotificationFeedBackContent(
          response.data?.updateKickOffQuizAvailability
            ? KickOffNotificationFeedbackContents.QUIZ_AVAILABILITY_UPDATE_SUCCESS
            : KickOffNotificationFeedbackContents.QUIZ_AVAILABILITY_UPDATE_ERROR,
        ),
      )
      .catch(() => {
        setNotificationFeedBackContent(KickOffNotificationFeedbackContents.QUIZ_AVAILABILITY_UPDATE_ERROR);
      })
      .finally(() => getKickOffByGroupIDHandler());
  };

  return (
    <div
      className="flex absolute right-5 top-10 z-10 flex-col items-start bg-neutral-10"
      onClick={() => setOpenMoreOptionsPopUp(false)}
    >
      <MoreOptionsPopUpOption
        icon="EllipsisHorizontalCircleIcon"
        label={isQuizActive ? 'Desligar link' : 'Ligar link'}
        onClick={() => {
          updateQuizAvailability();
          copyQuizLinkToClipboard();
        }}
      />
      <MoreOptionsPopUpOption
        icon="LinkIcon"
        label="Copiar link"
        disabled={!quizLink}
        onClick={copyQuizLinkToClipboard}
      />
    </div>
  );
};

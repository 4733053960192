import React from 'react';
import { ButtonIcon } from '@clarke-energia/foton';

import { FinancialEvent } from '@contexts/financial-events';
import HeroIcon from '../../hero-icon';

interface IFinancialEventMoreOptionsTableButton {
  entry: FinancialEvent;
  selectedFinancialEvent?: FinancialEvent;
  setSelectedFinancialEvent: React.Dispatch<React.SetStateAction<FinancialEvent | undefined>>;
  setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FinancialEventMoreOptionsTableButton = ({
  entry,
  selectedFinancialEvent,
  setSelectedFinancialEvent,
  setOpenConfirmationModal,
}: IFinancialEventMoreOptionsTableButton) => {
  const [openMoreOptionsPopUp, setOpenMoreOptionsPopUp] = React.useState<boolean>(false);
  const moreOptionsPopUpRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        ref === moreOptionsPopUpRef && setOpenMoreOptionsPopUp(false);
      }
    };

    document.addEventListener('click', (event) => handleOutsideClick(event, moreOptionsPopUpRef));
    return () => {
      document.removeEventListener('click', (event) => handleOutsideClick(event, moreOptionsPopUpRef));
    };
  }, [moreOptionsPopUpRef]);

  const emailSendingAllowed =
    entry.status === 'PROCESSED' ||
    entry.status === 'SENDING_FAILED' ||
    entry.status === 'NOT_SENT' ||
    entry.status === 'SENT';
  return (
    <div ref={moreOptionsPopUpRef} className="relative">
      <ButtonIcon
        className="hover:bg-neutral-20"
        icon="EllipsisVerticalIcon"
        kind="ghost"
        shape="square"
        disabled={!emailSendingAllowed}
        style={{ color: `${emailSendingAllowed ? '' : '#9C9C9B'}` }}
        onClick={() => {
          setSelectedFinancialEvent(entry);
          setOpenMoreOptionsPopUp(!openMoreOptionsPopUp);
        }}
      />
      {openMoreOptionsPopUp && entry.id === selectedFinancialEvent?.id && (
        <div
          className="flex absolute right-0 top-8 z-10 gap-2 items-center h-7 cursor-pointer bg-neutral-10 w-[10rem] pl-[0.63rem]"
          onClick={() => {
            setOpenConfirmationModal(true);
            setOpenMoreOptionsPopUp(false);
          }}
        >
          <div className="w-5">
            <HeroIcon icon={'PaperAirplaneIcon'} extraClassNames={'-rotate-90'} />
          </div>
          <span className="text-paragraph-medium">{`${entry.status === 'SENT' ? 'Reenviar' : 'Enviar'} evento`}</span>
        </div>
      )}
    </div>
  );
};

export default FinancialEventMoreOptionsTableButton;

import { Table } from '@clarke-energia/foton';
import { IContractBase } from '@pages/sales-contracts';
import salesContractTableTableColumns from './columns';
interface ISalesContractTable {
  contractsData: Array<IContractBase>;
}

export const SalesContractTable = ({ contractsData }: ISalesContractTable) => {
  return (
    <div>
      {contractsData && <Table<IContractBase> data={contractsData} tableColumns={salesContractTableTableColumns()} />}
    </div>
  );
};

import { GeneralGadget } from '@clarke-energia/foton';
import React, { useEffect, useState } from 'react';
import LineChartComponent from '../chart/line-chart-component';
import {
  getAllMonthDatetimeMeasurements,
  createLineChartLabels,
  createLineChartPrimaryValues,
  createLineChartSecondaryValues,
} from '@pages/energy-audit/helper';
import { UnitConsumptionMeasuresPerHour } from '@contexts/energy-audit-consumptions/types';

interface Subtitle {
  color: 'green' | 'black';
  text: string;
}

interface ChartGadgetProps {
  unitConsumptionMeasuresPerHour?: Array<UnitConsumptionMeasuresPerHour>;
  gadgetContent: {
    title: string;
    subtitles: Array<Subtitle>;
  };
}

const ChartGadget: React.FC<ChartGadgetProps> = ({ gadgetContent, unitConsumptionMeasuresPerHour }) => {
  const [lineChartLabels, setLineChartLabels] = useState<Array<string>>([]);
  const [lineChartPrimaryValues, setLineChartPrimaryValues] = useState<Array<number | null>>([]);
  const [lineChartSecondaryValues, setLineChartSecondaryValues] = useState<Array<number | null>>([]);

  useEffect(() => {
    if (unitConsumptionMeasuresPerHour) {
      const allMonthDatetimeMeasurements = getAllMonthDatetimeMeasurements(unitConsumptionMeasuresPerHour);

      setLineChartLabels(createLineChartLabels(allMonthDatetimeMeasurements));
      setLineChartPrimaryValues(createLineChartPrimaryValues(allMonthDatetimeMeasurements));
      setLineChartSecondaryValues(createLineChartSecondaryValues(allMonthDatetimeMeasurements));
    }
  }, [unitConsumptionMeasuresPerHour]);

  return (
    <GeneralGadget title={gadgetContent.title}>
      <div className="overflow-y-auto h-[27rem]">
        <div className="flex gap-7 mb-8">
          {gadgetContent.subtitles.map(({ color, text }) => (
            <div className="flex gap-4 items-center" key={text}>
              <div className={`w-5 h-5 rounded-full ${color === 'green' ? 'bg-primary-70' : 'bg-black'}`}></div>
              <div>{text}</div>
            </div>
          ))}
        </div>
        <LineChartComponent
          className="h-full"
          chartLabels={lineChartLabels}
          chartValuesPrimary={lineChartPrimaryValues}
          chartValuesSecondary={lineChartSecondaryValues}
        />
      </div>
    </GeneralGadget>
  );
};

export default ChartGadget;

import { useContextSelector } from 'use-context-selector';
import { GroupEconomyReportContext } from '@contexts/economy-reports';

const useEconomyReportForm = () => {
  const loadingGroupData = useContextSelector(GroupEconomyReportContext, (group) => group.loadingGroupData);
  const groupReportFormData = useContextSelector(GroupEconomyReportContext, (group) => group.groupReportFormData);
  const upsertEconomyReportHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.upsertEconomyReportHandler,
  );
  const upsertGroupEconomyReportHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.upsertGroupEconomyReportHandler,
  );

  return {
    loadingGroupData,
    groupReportFormData,
    upsertEconomyReportHandler,
    upsertGroupEconomyReportHandler,
  };
};

export default useEconomyReportForm;

import { IManagementContractPayload } from '@contexts/management-contract/types';
import { UseFormSetValue } from 'react-hook-form';

export const initializeFormWithDefaults = (
  formDefaultValues: IManagementContractPayload | undefined,
  setValue: UseFormSetValue<IManagementContractPayload>,
) => {
  if (formDefaultValues) {
    setValue('inputs', formDefaultValues.inputs);
    setValue('inputs.units', formDefaultValues?.inputs?.units ? Object.values(formDefaultValues?.inputs?.units) : []);
    setValue(
      'inputs.signatories.customerSignatories',
      formDefaultValues?.inputs?.signatories?.customerSignatories
        ? Object.values(formDefaultValues?.inputs?.signatories?.customerSignatories)
        : [],
    );
    setValue('type', formDefaultValues.type);
    setValue('thirtyDayTrial', { thirtyDayTrial: formDefaultValues.thirtyDayTrial! }['thirtyDayTrial']);
    setValue('additionalServices', formDefaultValues.additionalServices);
    setValue('attachments', formDefaultValues.attachments ?? []);
    setValue('lead', formDefaultValues.lead);
    setValue('proposalId', formDefaultValues.proposalId);
    setValue('product', formDefaultValues.product);
  }
};

import React, { useState } from 'react';
import { Table, PopUpList, ButtonInline, ColumnOptions } from '@clarke-energia/foton';
import {
  DatetimeMeasurement,
  DatetimeMeasurementStatus,
  MonthlyControlMeasurementByUnitId,
} from '@contexts/energy-audit-consumptions/types';
import { formatDateFromString } from '@utils/dayjs';
import { getHourByDateTime } from '@utils/text';
import FormUpdateMeasurementHour from '../form/form-update-measurement-hour';
import EnergyAuditAccordionTitle from './energy-audit-accordion-title';

interface SingleDayConsumptionsPerHourProps {
  accordionLabel: string;
  monthlyControlConsumptions?: Array<MonthlyControlMeasurementByUnitId>;
  onClick: (item: MonthlyControlMeasurementByUnitId) => void;
  tableData: Array<DatetimeMeasurement>;
}
type renderMeasurementByDayFunction = ColumnOptions<DatetimeMeasurement>['renderAsElement'];

const SingleDayConsumptionsPerHour: React.FC<SingleDayConsumptionsPerHourProps> = ({
  accordionLabel,
  monthlyControlConsumptions,
  onClick,
  tableData,
}) => {
  const [measurementByHourToEdit, setMeasurementByHourToEdit] = useState<DatetimeMeasurement>();
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  const renderHour: renderMeasurementByDayFunction = (entry) => getHourByDateTime(entry?.datetime);
  const renderConsumption: renderMeasurementByDayFunction = (entry) => {
    const activeConsumptionFormatted = Number(entry.activeConsumption).toFixed(3);
    if (measurementByHourToEdit?.datetime === entry?.datetime) {
      return (
        <FormUpdateMeasurementHour
          entry={entry}
          onFormSuccess={() => setMeasurementByHourToEdit(undefined)}
          onFormFail={() => setMeasurementByHourToEdit(undefined)}
          setMeasurementByHourToEdit={setMeasurementByHourToEdit}
        />
      );
    }
    if (entry.status !== DatetimeMeasurementStatus.HCC) {
      return (
        <>
          <div className="flex gap-4 items-center">
            <div className={'text-primary-60'}>{activeConsumptionFormatted}</div>
            <ButtonInline
              className="w-6"
              kind={'secondary'}
              icon={'PencilIcon'}
              size={'small'}
              onClick={() => setMeasurementByHourToEdit(entry)}
            />
          </div>
        </>
      );
    }
    return <div>{activeConsumptionFormatted}</div>;
  };

  const measurementByDayColumnOptions: Array<ColumnOptions<DatetimeMeasurement>> = [
    {
      accessor: 'datetime',
      header: 'Hora',
      renderAsElement: renderHour,
    },
    {
      accessor: 'activeConsumption',
      header: 'Consumo (KWh)',
      renderAsElement: renderConsumption,
      fixedWidthInRem: 13,
    },
  ];

  return (
    <div className="pr-6">
      <div>
        <EnergyAuditAccordionTitle
          label={accordionLabel}
          isOpen={showPopUp}
          onClick={() => {
            setShowPopUp(!showPopUp);
          }}
        >
          {monthlyControlConsumptions && (
            <PopUpList
              className="overflow-y-auto right-[2rem] w-[14rem] h-[21rem]"
              items={monthlyControlConsumptions.map((measurement) => ({
                label: formatDateFromString(measurement.date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
                onClick: () => {
                  setShowPopUp(!showPopUp);
                  onClick(measurement);
                },
              }))}
              show={showPopUp}
            />
          )}
        </EnergyAuditAccordionTitle>
        <Table className="mt-7" data={tableData} tableColumns={measurementByDayColumnOptions} />
      </div>
    </div>
  );
};

export default SingleDayConsumptionsPerHour;

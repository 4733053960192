import React from 'react';
import { Button } from '@clarke-energia/foton';
import numeral from 'numeral';

import { IKickOff } from '@contexts/kick-off/types';
import { useEdcs } from '@hooks/edcs';

import { IKickOffPanelSectionMessageContent } from '@components/molecules/kick-off/helper';

interface IKickOffUnavailableUnitsSummary {
  navigateToUnitsList: () => void;
  unitsSummarySectionMessageContent?: IKickOffPanelSectionMessageContent;
}

export const KickOffUnavailableUnitsSummary = ({
  navigateToUnitsList,
  unitsSummarySectionMessageContent,
}: IKickOffUnavailableUnitsSummary) => (
  <div className="flex flex-col gap-4">
    <p className="text-heading-small md:text-heading-xsmall">{unitsSummarySectionMessageContent?.title}</p>
    <p className="text-paragraph-small md:text-paragraph-medium">{unitsSummarySectionMessageContent?.description}</p>
    <Button
      kind="primary"
      label="Cadastrar unidades"
      size="small"
      onClick={navigateToUnitsList}
      className="mt-8 w-fit"
    />
  </div>
);

interface IUnitInfoRow {
  infoName: string;
  infoValue?: string;
  darkerBackground?: boolean;
}

const UnitInfoRow = ({ infoName, infoValue, darkerBackground }: IUnitInfoRow) => (
  <div
    className={`flex jusitfy-between w-full py-1 px-4 text-paragraph-small md:text-paragraph-medium ${
      darkerBackground ? 'bg-[#FAFAFA]' : 'bg-white'
    }`}
  >
    <div className="w-full font-bold text-left">{infoName}</div>
    <div className="w-full font-normal text-right">{infoValue ?? '-'}</div>
  </div>
);

interface IKickOffAvailableUnitsSummary {
  unitsSummaryList: IKickOff['unitsSummary'];
}

export const KickOffAvailableUnitsSummary = ({ unitsSummaryList }: IKickOffAvailableUnitsSummary) => {
  const edcs = useEdcs();
  const getUnitEDCName = (edcId?: number) => edcs.find((edc) => edc.id === edcId)?.name;
  return (
    <div
      className={`flex flex-col gap-5 ${
        unitsSummaryList && unitsSummaryList.length > 3 ? 'h-[24rem] overflow-y-auto' : 'h-fit'
      }`}
    >
      {unitsSummaryList?.map((unitSummary, index) => (
        <div key={index} className="flex flex-col gap-4 w-full h-fit">
          <div className="text-heading-small md:text-heading-xsmall">{unitSummary.name}</div>
          <div className="flex flex-col gap-1">
            <UnitInfoRow
              infoName="Demanda contratada"
              infoValue={numeral(unitSummary.higherContractedDemandInKw).format('0.00').concat(' kW')}
              darkerBackground
            />
            <UnitInfoRow
              infoName="Consumo médio"
              infoValue={numeral(unitSummary.averageConsumptionInMwm).format('0.0000').concat(' MWm')}
            />
            <UnitInfoRow
              infoName="Valor mensal da fatura"
              infoValue={numeral(unitSummary.averageEnergyBill).format('$ 0,0.00')}
              darkerBackground
            />
            <UnitInfoRow infoName="Distribuidora" infoValue={getUnitEDCName(unitSummary.edcId)} />
          </div>
        </div>
      ))}
    </div>
  );
};

import React from 'react';
import HeaderLogoClarke from '@components/molecules/general/header-logo';

import { useParams } from 'react-router-dom';
import { ColumnOptions } from '@clarke-energia/foton';
import SecondSection from '@components/molecules/economy-report/group-report/second-section';
import FooterContainer from '@components/molecules/economy-report/group-report/footer-container';
import useGroupEconomyReport from '@hooks/use-group-economy-report ';
import FirstSection from '@components/molecules/economy-report/group-report/first-section';
import { UnitsReportsTableData } from '@contexts/economy-reports/types';

type renderFunction = ColumnOptions<UnitsReportsTableData>['renderAsElement'];

const renderUnit: renderFunction = (entry) => entry.unit;
const renderSaving: renderFunction = (entry) => entry.saved;
const renderEnergyCost: renderFunction = (entry) => entry.energyCost;
const renderEnergyConsumption: renderFunction = (entry) => entry.energyConsumption;

const GroupEconomyReportPage: React.FC = () => {
  const { loadingGroupReport, getGroupEconomyReportByReportId, groupReport } = useGroupEconomyReport();
  const { id } = useParams<string>();

  React.useEffect(() => {
    if (id) getGroupEconomyReportByReportId(id);
  }, []);

  const {
    name,
    date,
    totalMonthlyEnergyConsumption,
    totalMonthlyEnergyCost,
    totalMonthlySavings,
    totalMonthlySavingsPercent,
    edcs,
    contractType,
    traders,
    unitsAmount,
    contractedDemandAmount,
    unitsTableData,
  } = groupReport;

  const reportTableColumns: ColumnOptions<UnitsReportsTableData>[] = [
    { accessor: 'unit', header: 'Unidade', renderAsElement: renderUnit },
    { accessor: 'saved', header: 'Economia', renderAsElement: renderSaving },
    { accessor: 'energyCost', header: 'Custo Energia', renderAsElement: renderEnergyCost },
    { accessor: 'energyConsumption', header: 'Consumo de Energia', renderAsElement: renderEnergyConsumption },
  ];

  return (
    <div className="px-5 xl:px-8">
      <HeaderLogoClarke
        text={`${name} - ${date}\nRelatorio de Economia Compilado`}
        mobileText={`${name}-${date}`}
        isFecthingData={loadingGroupReport}
      />
      <div className="grid grid-cols-1 mt-14 lg:mt-36 xl:grid-cols-3 xl:gap-6">
        <FirstSection
          customerData={{ name, unitsAmount, edcs, contractedDemandAmount, traders, contractType }}
          isFetchingData={loadingGroupReport}
        />
        <SecondSection
          isFetchingData={loadingGroupReport}
          tableData={{
            columns: reportTableColumns,
            data: unitsTableData,
          }}
          highlightsInfo={{
            date,
            currentSavings: totalMonthlySavings,
            energyTotalConsumption: totalMonthlyEnergyConsumption,
            energyTotalCost: totalMonthlyEnergyCost,
            savingsPercent: totalMonthlySavingsPercent,
          }}
        />
        <FooterContainer />
      </div>
    </div>
  );
};

export default GroupEconomyReportPage;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ColumnOptions, Pagination, Table } from '@clarke-energia/foton';

import { EnergyAuditReportFormated } from '@contexts/energy-audit-report/types';

const TableSkeleton = (): JSX.Element => {
  return (
    <>
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </>
  );
};

interface EnergyAuditReportTable {
  tableData: Array<EnergyAuditReportFormated>;
  loading: boolean;
  total: number;
  itemPerpage?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const EnergyAuditReportsTable = ({
  tableData,
  loading,
  total,
  itemPerpage,
  currentPage,
  setCurrentPage,
}: EnergyAuditReportTable): JSX.Element => {
  const energyAuditsColumns: Array<ColumnOptions<EnergyAuditReportFormated>> = [
    {
      accessor: 'group',
      header: 'Grupo Comercial',
      renderAsElement: (entry) => entry.group,
    },
    {
      accessor: 'unit',
      header: 'Unidade',
      renderAsElement: (entry) => entry.unit,
    },
    {
      accessor: 'contractCceeNumber',
      header: 'Contracto CCEE',
      renderAsElement: (entry) => entry.contractCceeNumber,
    },
    {
      accessor: 'profileCustomer',
      header: 'Perfil do cliente',
      fixedWidthInRem: 10,
      ellipsisStyle: { position: 'left-[5rem] md:left-[2rem] top-[-1rem]' },
      renderAsElement: (entry) => entry.profileCustomer,
    },
    {
      accessor: 'profileTrader',
      header: 'Perfil do fornecedor',
      renderAsElement: (entry) => entry.profileTrader,
    },
    {
      accessor: 'submarket',
      header: 'Submercado',
      renderAsElement: (entry) => entry.submarket,
    },
    {
      accessor: 'energyType',
      header: 'Tipo de energia',
      renderAsElement: (entry) => entry.energyType,
    },
    {
      accessor: 'volume',
      header: 'Volume',
      renderAsElement: (entry) => entry.volume,
    },
  ];

  if (loading) return <TableSkeleton />;

  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-visible">
        <div>
          <Table<EnergyAuditReportFormated>
            className="border-top"
            data={tableData}
            tableColumns={energyAuditsColumns}
          />
          {total >= 10 && (
            <Pagination
              totalRows={total as number}
              rowsByPage={itemPerpage as number}
              currentPage={currentPage as number}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EnergyAuditReportsTable;

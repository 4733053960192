import React from 'react';
import moment from 'moment';
import { GeneralGadget } from '@clarke-energia/foton';

import { IKickOff } from '@contexts/kick-off/types';
import { isOnMobileScreen } from '@utils/graphics';

import { KickOffAvailableDiagnosis, KickOffUnavailableDiagnosis } from '@components/atoms/kick-off/diagnosis-section';

interface IKickOffDiagnosisSection {
  kickOff: IKickOff;
  setOpenUpdateDiagnosisModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const KickOffDiagnosisSection = ({ kickOff, setOpenUpdateDiagnosisModal }: IKickOffDiagnosisSection) => {
  const kickOffStatus = kickOff.status;

  const isDiagnosisUnavailableByKickOffStatus =
    kickOffStatus === 'STARTED' ||
    kickOffStatus === 'INFORMATION_REGISTRATION_PENDING' ||
    kickOffStatus === 'INFORMATION_REGISTRATION_CONCLUDED' ||
    kickOffStatus === 'DIAGNOSTIC_QUIZ_RELEASED' ||
    kickOffStatus === 'DIAGNOSTIC_QUIZ_BEING_COMPLETED';
  const isDiagnosisAvailableByKickOffStatus = kickOffStatus === 'DIAGNOSTIC_QUIZ_SENT' && kickOff.diagnosis;

  const diagnosticQuizSubmittedAt = moment(kickOff.quiz?.submittedAt);
  const diagnosisDoneAt =
    !isOnMobileScreen() && isDiagnosisAvailableByKickOffStatus
      ? `Concluído em ${diagnosticQuizSubmittedAt.format('DD/MM/YYYY')} às ${diagnosticQuizSubmittedAt.format('H:m:s')}`
      : undefined;

  return (
    <GeneralGadget
      title="Características do produto"
      subtitle={diagnosisDoneAt}
      actionButtons={
        isDiagnosisAvailableByKickOffStatus
          ? [
              {
                buttonType: 'buttonIcon',
                icon: 'PencilIcon',
                shape: 'square',
                kind: 'ghost',
                onClick: () => setOpenUpdateDiagnosisModal(true),
              },
            ]
          : []
      }
      className="pb-6 h-fit"
    >
      {isDiagnosisUnavailableByKickOffStatus ? (
        <KickOffUnavailableDiagnosis kickOffStatus={kickOffStatus} />
      ) : (
        isDiagnosisAvailableByKickOffStatus &&
        kickOff.diagnosis && <KickOffAvailableDiagnosis diagnosis={kickOff.diagnosis} />
      )}
    </GeneralGadget>
  );
};

export default KickOffDiagnosisSection;

import axios, { AxiosProgressEvent } from 'axios';

import { ProcessProinfaFormData } from '@contexts/proinfa-contract/types';
import { UploadTraderLogoData } from '@contexts/trader';
import { IFinancialEventsBatchPayload } from '@contexts/financial-events';
import { IEnergyContractAttachmentsPayload } from '@contexts/energy-contract/types';
import { ClarkeContractAttachmentsPayload } from '@contexts/clarke-contract/types';

import configuration from '../../config';
import financialEventsBatchDataParser, {
  clarkeContractAttachmentsDataParser,
  energyContractAttachmentsDataParser,
  proinfaDataParser,
  uploadTraderLogoParser,
} from './parser';

export const customerApi = axios.create({
  baseURL: configuration.CUSTOMER_API_HOST,
});

export async function createFinancialEventsBatch(data: IFinancialEventsBatchPayload, accessToken: string) {
  const formData = financialEventsBatchDataParser(data);
  return customerApi.post('/api/v1/financial-events/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function uploadTraderLogo(data: UploadTraderLogoData, accessToken: string) {
  const formData = uploadTraderLogoParser(data);
  return customerApi.post('/api/v1/documents/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export const processProinfaFile = (
  data: ProcessProinfaFormData,
  accessToken: string,
  onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
) => {
  const formData = proinfaDataParser(data);
  return customerApi.post('/api/v1/proinfa/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    onUploadProgress,
  });
};

export async function createEnergyContractAttachments(data: IEnergyContractAttachmentsPayload, accessToken: string) {
  const formData = energyContractAttachmentsDataParser(data);
  return customerApi.post('/api/v1/energy-contract-attachments/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createClarkeContractAttachments(data: ClarkeContractAttachmentsPayload, accessToken: string) {
  const formData = clarkeContractAttachmentsDataParser(data);
  return customerApi.post('/api/v1/clarke-contract-attachments/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

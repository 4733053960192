import React from 'react';

import { FormSection } from '@components/atoms/form';
import { FileField } from '@components/atoms/form/file/file-input';
import { IEnergyContractAttachmentsPayload } from '@contexts/energy-contract/types';
import { useEnergyContracts } from '@hooks/use-energy-contract';

interface AttachmentsSectionProps {
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>;
}

const FormAttachmentsSection = ({ setUploadedFiles }: AttachmentsSectionProps): JSX.Element => {
  const { deleteEnergyContractAttachmentsHandler, storedFiles, loading } = useEnergyContracts();

  return (
    <div>
      <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
        <FormSection title="Documentos" subtitle="Vincule documentos como o CCEAL e anexos contratuais." />
        <div className="flex flex-col w-full lg:w-[200%] gap-4">
          <FileField<IEnergyContractAttachmentsPayload>
            field={'energyContractAttachments'}
            id={'energyContractAttachments'}
            label={'Anexar documentos'}
            description={'Envie documentos referente ao CCEAL desse grupo comercial. PDF de até 5MB'}
            maximumFileSizeInMB={5}
            allowedFileTypes={['.pdf']}
            onChangeFiles={(files) => setUploadedFiles(files as File[])}
            storedFiles={storedFiles}
            handleRemoveStoredFile={deleteEnergyContractAttachmentsHandler}
            loading={loading}
            multipleFiles
          />
        </div>
      </div>
    </div>
  );
};

export default FormAttachmentsSection;

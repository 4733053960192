import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation Register($input: RegisterInput) {
    register(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation Mutation($input: UpdateUserInput) {
    updateUser(input: $input)
  }
`;

export const DELETE_USER = gql`
  mutation Mutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const SEND_WELCOME_MIGRATION_EMAIL = gql`
  mutation ($input: sendWelcomeMigrationEmailForUserInput) {
    sendWelcomeMigrationEmailForUser(input: $input)
  }
`;

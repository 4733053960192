import React from 'react';

interface TabTypesProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  disabled: boolean;
}

const Tab: React.FC<TabTypesProps> = ({ label, isActive, onClick, disabled }) => {
  const generalClassname = 'px-5 h-[2rem] flex items-center justify-center mb-6 text-paragraph-medium rounded-t-medium';
  const disabledClassname = disabled ? 'cursor-normal text-neutral-30' : 'cursor-pointer text-black ';
  const hoverClassname = !isActive && !disabled ? 'hover:border-b-2 hover:border-neutral-20' : '';
  const activeClassname = isActive ? 'border-b-2 border-black font-bold' : '';
  const finalClassname = [generalClassname, disabledClassname, hoverClassname, activeClassname].join(' ');

  return (
    <div className={finalClassname} onClick={() => !disabled && onClick()}>
      {label}
    </div>
  );
};

export default Tab;

import { CceeProfile } from '@contexts/ccee-profile/types';

export const formatUnitsName = (units: any[]) => (units.length ? units.map(({ name }) => name).join(',') : '-');
export const formatGroupName = (units: any[]) => (units.length ? units[0]?.group?.name : '-');
export const dataToUpdate = ({ units, id, idCcee, name }: CceeProfile) => ({
  id,
  idCcee,
  name,
  groupId: units[0].group.id,
  units: units.map(({ id }) => id),
});

import React from 'react';
import { createContext } from 'use-context-selector';
import { useLazyQuery, useMutation } from '@apollo/client';

import { INotificationFeedbackContent } from '@utils/constants/common';

import {
  ICreateKickOffByGroupIDMutationData,
  ICreateKickOffByGroupIDMutationVariables,
  IGetKickOffByGroupIDQueryData,
  IGetKickOffByGroupIDQueryVariables,
  IKickOff,
  IKickOffContext,
  IKickOffDiagnosis,
  ISendKickOffQuizEmailMutationData,
  ISendKickOffQuizEmailMutationVariables,
  IUpdateKickOffDiagnosisMutationData,
  IUpdateKickOffDiagnosisMutationVariables,
  IUpdateKickOffQuizAvailabilityMutationData,
  IUpdateKickOffQuizAvailabilityMutationVariables,
  KickOffQuizStatus,
} from './types';
import { CreateKickOffByGroupIDManager, GetKickOffByGroupIDManager } from './manager';
import { GET_KICK_OFF_BY_GROUP_ID_QUERY } from './queries';
import {
  CREATE_KICK_OFF_BY_GROUP_ID_MUTATION,
  SEND_KICK_OFF_QUIZ_EMAIL_MUTATION,
  UPDATE_KICK_OFF_DIAGNOSIS_MUTATION,
  UPDATE_KICK_OFF_QUIZ_AVAILABILITY_MUTATION,
} from './mutations';

export const KickOffContext = createContext({} as IKickOffContext);

interface Provider {
  children: React.ReactNode;
}

const KickOffProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [groupId, setGroupId] = React.useState<string>();
  const [kickOff, setKickOff] = React.useState<IKickOff>();
  const [notificationFeedBackContent, setNotificationFeedBackContent] = React.useState<INotificationFeedbackContent>();

  const [getKickOffByGroupID, { loading: loadingGetKickOffByGroupID }] = useLazyQuery<
    IGetKickOffByGroupIDQueryData,
    IGetKickOffByGroupIDQueryVariables
  >(GET_KICK_OFF_BY_GROUP_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new GetKickOffByGroupIDManager(data);
      setKickOff(manager.kickOff);
    },
  });

  const [createKickOffByGroupID, { loading: loadingCreateKickOffByGroupID }] = useMutation<
    ICreateKickOffByGroupIDMutationData,
    ICreateKickOffByGroupIDMutationVariables
  >(CREATE_KICK_OFF_BY_GROUP_ID_MUTATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new CreateKickOffByGroupIDManager(data);
      setKickOff(manager.kickOff);
    },
  });

  const [updateKickOffQuizAvailability, { loading: loadingUpdateKickOffQuizAvailability }] = useMutation<
    IUpdateKickOffQuizAvailabilityMutationData,
    IUpdateKickOffQuizAvailabilityMutationVariables
  >(UPDATE_KICK_OFF_QUIZ_AVAILABILITY_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const [sendKickOffQuizEmail, { loading: loadingSendKickOffQuizEmail }] = useMutation<
    ISendKickOffQuizEmailMutationData,
    ISendKickOffQuizEmailMutationVariables
  >(SEND_KICK_OFF_QUIZ_EMAIL_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const [updateKickOffDiagnosis, { loading: loadingUpdateKickOffDiagnosis }] = useMutation<
    IUpdateKickOffDiagnosisMutationData,
    IUpdateKickOffDiagnosisMutationVariables
  >(UPDATE_KICK_OFF_DIAGNOSIS_MUTATION, {
    fetchPolicy: 'network-only',
  });

  function getKickOffByGroupIDHandler() {
    return getKickOffByGroupID({
      variables: {
        groupId,
      },
    });
  }

  function createKickOffByGroupIDHandler() {
    return createKickOffByGroupID({
      variables: {
        groupId,
      },
    });
  }

  function updateKickOffQuizAvailabilityHandler(quizStatus: keyof typeof KickOffQuizStatus) {
    return updateKickOffQuizAvailability({
      variables: {
        kickOffId: kickOff?.id as string,
        quizStatus,
      },
    });
  }

  function sendKickOffQuizEmailHandler() {
    return sendKickOffQuizEmail({
      variables: {
        kickOffId: kickOff?.id as string,
      },
    });
  }

  function updateKickOffDiagnosisHandler(diagnosisData: IKickOffDiagnosis) {
    return updateKickOffDiagnosis({
      variables: {
        kickOffId: kickOff?.id as string,
        diagnosisData,
      },
    });
  }

  React.useEffect(() => {
    if (groupId) getKickOffByGroupIDHandler();
  }, [groupId]);

  return (
    <KickOffContext.Provider
      value={{
        kickOff,
        loading:
          loadingGetKickOffByGroupID ||
          loadingCreateKickOffByGroupID ||
          loadingUpdateKickOffQuizAvailability ||
          loadingSendKickOffQuizEmail ||
          loadingUpdateKickOffDiagnosis,
        setGroupId,
        getKickOffByGroupIDHandler,
        createKickOffByGroupIDHandler,
        updateKickOffQuizAvailabilityHandler,
        sendKickOffQuizEmailHandler,
        updateKickOffDiagnosisHandler,
        notificationFeedBackContent,
        setNotificationFeedBackContent,
      }}
    >
      {children}
    </KickOffContext.Provider>
  );
};

export default KickOffProvider;

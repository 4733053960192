import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Table, ColumnOptions } from '@clarke-energia/foton';
import { UnitsReportsTableData } from '@contexts/economy-reports/types';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface GroupReportTableProps {
  isFetchingReports: boolean;
  data: UnitsReportsTableData[];
  columns: ColumnOptions<UnitsReportsTableData>[];
}

const GroupReportTable: React.FC<GroupReportTableProps> = ({ isFetchingReports, data, columns }) => {
  return (
    <div className="mt-7 border-t-2 border-black">
      <div className="overflow-x-auto">
        {isFetchingReports ? (
          <RowSkeleton />
        ) : (
          <Table
            data={data}
            tableColumns={columns}
            lastRow={{ fontBold: true, highlight: true }}
            superHeader="Resultado por Unidade"
            onRowClickAction={(entry) => {
              if (entry.unitReportLink) window.open(entry.unitReportLink, '_blank');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GroupReportTable;

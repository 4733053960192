/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

export function formatBR(value: number): string {
  return new Intl.NumberFormat('pt-BR').format(value);
}

export const isObjectEmpty = (object?: Record<any, any> | null) => {
  // @typescript-eslint/no-explicit-any
  if (object === undefined || !object) return true;

  const objectValuesArray = Object.values(object);
  if (objectValuesArray?.length === 0) return true;
  else if (objectValuesArray.filter((value) => value !== undefined && value !== null).length === 0) return true;
  else return false;
};

export const isArrayEmpty = (array?: Array<any>) => {
  const filteredArray = array?.filter((item) => item);
  if (filteredArray === undefined || !filteredArray) return true;
  else if (filteredArray.length > 0) return false;
  else return true;
};

export const calculateObjectValuesAverage = (object?: Record<number, number | null>): number | undefined => {
  if (!object) return undefined;

  const objectValuesArray = Object.values(object);
  const validObjectValuesArray = objectValuesArray.filter(
    (number) => !Number.isNaN(number) && number !== null && number !== undefined,
  ) as Array<number>;

  if (validObjectValuesArray.length > 0) {
    const objectValuesSum = _.sum(validObjectValuesArray);
    const objectValuesCount = validObjectValuesArray.length;
    return Math.round(objectValuesSum / objectValuesCount);
  }
};

export const transformEntityRecursively = (
  entity: Record<string, any> | Array<any>,
  transformKey: (key: string) => string,
) => {
  const restartRecursionForChildEntity = (childEntity: any) => {
    if (_.isPlainObject(childEntity) || _.isArray(childEntity)) transformEntityRecursively(childEntity, transformKey);
  };

  const transformList = (entityAsList: Array<any>) => {
    entityAsList.forEach((childEntity) => restartRecursionForChildEntity(childEntity));
  };

  const transformObject = (entityAsObject: Record<string, any>) => {
    Object.keys(entityAsObject).forEach((key) => {
      const transformedKey = transformKey(key);

      entityAsObject[transformedKey] = entityAsObject[key];
      if (key !== transformedKey) delete entityAsObject[key];

      restartRecursionForChildEntity(entityAsObject[transformedKey]);
    });
  };

  if (_.isArray(entity)) transformList(entity);
  else if (_.isPlainObject(entity)) transformObject(entity);

  return entity;
};

export const transformObjectKeysToSnakeCase = (object: Record<string, any>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.snakeCase);
};

export const transformObjectKeysToCamelCase = (object: Record<string, any>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.camelCase);
};

export const thisOrZero = (value?: number) => value ?? 0;

export const parseStringToBoolean = (string: string) => {
  if (string === 'true') return true;
  else if (string === 'false') return false;
  else return undefined;
};

export const isValidDate = (valor: string) => {
  if (isStringEmpty(valor)) return true;
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
  return regex.test(valor);
};

export const isValidEmail = (value: string): boolean => {
  /* eslint-disable no-useless-escape  */
  if (isStringEmpty(value)) return true;
  /* eslint-disable @typescript-eslint/no-inferrable-types */
  const emailPattern: RegExp = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(value);
};

export const isStringEmpty = (value?: string) => {
  if (!value || value === '') return true;
  else return false;
};

import { useContextSelector } from 'use-context-selector';

import { MigrationReportContext } from '@contexts/migration-report';
import { MigrationReportContextType } from '@contexts/migration-report/types';

export const useMigrationReport = (): MigrationReportContextType => {
  const getCommercialGroups = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.getCommercialGroups,
  );
  const getCommercialGroupsFilter = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.getCommercialGroupsFilter,
  );
  const getMigrationReports = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.getMigrationReports,
  );
  const syncMigrationReportsBatchHandler = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.syncMigrationReportsBatchHandler,
  );

  const sendMigrationsReportsBatchHandler = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.sendMigrationsReportsBatchHandler,
  );

  const createTaskHandler = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.createTaskHandler,
  );
  const commercialGroupsFilters = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.commercialGroupsFilters,
  );

  const commercialGroups = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.commercialGroups,
  );
  const migrationReports = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.migrationReports,
  );
  const loading = useContextSelector(MigrationReportContext, (migrationReport) => migrationReport.loading);

  const mutationLoading = useContextSelector(
    MigrationReportContext,
    (migrationReport) => migrationReport.mutationLoading,
  );

  return {
    getCommercialGroupsFilter,
    getCommercialGroups,
    getMigrationReports,
    sendMigrationsReportsBatchHandler,
    syncMigrationReportsBatchHandler,
    createTaskHandler,
    commercialGroupsFilters,
    commercialGroups,
    migrationReports,
    mutationLoading,
    loading,
  };
};

import React from 'react';

import { NavLink } from 'react-router-dom';
import { NavigationLinkProps, HeroIcon, IconProps } from '@clarke-energia/foton';

interface IMenuItemContentGenerator {
  iconName: IconProps['icon'];
  label: string;
}

const MenuItemContentGenerator = ({ iconName, label }: IMenuItemContentGenerator) => {
  return (
    <div className="flex items-center">
      <HeroIcon icon={iconName} extraClassNames="mr-[10px] w-[18px] h-[18px] inline" /> {label}
    </div>
  );
};

interface MobileNavigationLinkProps extends NavigationLinkProps {
  closeSidebar: () => void;
}

const NavigationLink: React.FC<MobileNavigationLinkProps> = ({
  label,
  path,
  iconName,
  linkAsButton,
  external = false,
  closeSidebar,
}) => {
  const baseClass = 'flex flex-nowrap items-center justify-start p-2 text-paragraph-medium rounded my-2 w-full';
  const activeLinkClass = 'bg-neutral-10';

  return external ? (
    <a className={baseClass} href={path} target="_blank" rel="noreferrer" onClick={closeSidebar}>
      <MenuItemContentGenerator iconName={iconName as IconProps['icon']} label={label} />
    </a>
  ) : linkAsButton?.active ? (
    <button
      onClick={() => {
        linkAsButton.onClick();
        closeSidebar();
      }}
      className={baseClass}
    >
      <MenuItemContentGenerator iconName={iconName as IconProps['icon']} label={label} />
    </button>
  ) : (
    <NavLink
      to={path ?? '#'}
      className={({ isActive }) => (isActive ? `s${[baseClass, activeLinkClass].concat()}` : `${baseClass}`)}
      onClick={closeSidebar}
    >
      <MenuItemContentGenerator iconName={iconName as IconProps['icon']} label={label} />
    </NavLink>
  );
};

interface NavigationLinksCollectionProps {
  links: NavigationLinkProps[];
  closeSidebar: () => void;
}

export const NavigationLinksCollection: React.FC<NavigationLinksCollectionProps> = ({ links, closeSidebar }) => {
  return (
    <>
      {links.map((link, index) => (
        <NavigationLink key={index} {...link} closeSidebar={closeSidebar} />
      ))}
    </>
  );
};

const MobileNavigationLinks: React.FC<NavigationLinksCollectionProps> = ({
  links,
  closeSidebar,
}: NavigationLinksCollectionProps) => {
  return (
    <nav className="flex flex-col ml-4 w-full">
      <NavigationLinksCollection links={links} closeSidebar={closeSidebar} />
    </nav>
  );
};

export default MobileNavigationLinks;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const MeasurementByUnitSkeleton: React.FC = () => (
  <>
    <div className="col-span-full h-1/2">
      <Skeleton className="mb-5" width="60%" />
      <Skeleton height="70%" />
    </div>
    <div className="col-span-full -mt-[14rem]">
      <Skeleton width="100%" height="100%" />
    </div>
  </>
);

export enum RolesTypes {
  CONTATO_PRINCIPAL = 'MAIN_CONTACT',
  REPRESENTANTE_LEGAL = 'LEGAL_REPRESENTATIVE',
  EVENTO_FINANCEIRO = 'FINANCIAL_EVENT',
  FATURAMENTO = 'INVOICING',
  VENDAS = 'SALES',
}

export const ClientRolesType = [
  { label: 'Contato Principal', value: 'MAIN_CONTACT' },
  { label: 'Representante Legal', value: 'LEGAL_REPRESENTATIVE' },
  { label: 'Evento Financeiro', value: 'FINANCIAL_EVENT' },
  { label: 'Faturamento', value: 'INVOICING' },
];

export const TraderRolesType = [
  { label: 'Vendas', value: 'SALES' },
  { label: 'Faturamento', value: 'INVOICING' },
];

export const ClientRolesHandleParser = (roleValue: string | undefined) => {
  const rightRole = ClientRolesType.filter((role) => roleValue === role.value);
  return rightRole[0];
};

export const TraderRolesHandleParser = (roleValue: string | undefined) => {
  const rightRole = TraderRolesType.filter((role) => roleValue === role.value);
  return rightRole[0];
};

import { gql } from '@apollo/client';

export const GET_USER_GROUPS_QUERY = gql`
  query GroupsUsers($groupId: ID!) {
    group(id: $groupId) {
      id
      name
      users {
        user {
          id
        }
        roles
      }
      units {
        id
        name
      }
    }
  }
`;

import React, { useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { CardSimple, Layout } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { useUnits } from '@hooks/use-units';
import { HOME_PATH } from '@routers/constants';

import { hubCardsInfo } from '@components/molecules/commercial-group/hub-commercial-group-helper-list';

const CommercialGroupHub: React.FC = () => {
  const { groupName, setGroupId, loading } = useUnits();
  const { groupId } = useParams<any>();

  useEffect(() => {
    trackEvent(eventLabels.GROUP_PAGE_VIEWED);
  }, []);

  useEffect(() => {
    if (groupId) {
      setGroupId(groupId);
    }
  }, []);

  return (
    <Layout
      title={groupName}
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: groupName,
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="grid grid-cols-12 col-span-full gap-6 items-center h-[12.75rem]">
        {hubCardsInfo.map(({ icon, label, path }) => (
          <NavLink key={label} to={path} className="col-span-4">
            <CardSimple icon={icon} label={label} />
          </NavLink>
        ))}
      </div>
    </Layout>
  );
};

export default CommercialGroupHub;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import { useProinfa } from '@hooks/use-proinfa';
import { ProcessProinfaFormData } from '@contexts/proinfa-contract/types';
import { CONTRACTS_PATH } from '@routers/constants';
import { proinfaStepTabs } from '@pages/contracts/helper';

import StepTab from '@components/atoms/step-tab';
import LoadingProcessFile from '@components/molecules/proinfa/loading-process-file';

import FirstStep from './first-step';
import SecondStep from './second-step';

const Form = () => {
  const { processProinfaFileHandler, fileProcessingProgress, proinfaList, setFileProcessingProgress } = useProinfa();

  const methods = useForm<ProcessProinfaFormData>({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const [stepForm, setStepForm] = useState<number>(1);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [openConfimationForm, setOpenConfimationForm] = useState<boolean>(false);
  const [isProcessingTable, setIsProcessingTable] = useState<boolean>(false);

  const renderFormSteps = () => {
    if (stepForm === 1 && !isProcessingTable) return <FirstStep setUploadedFile={setUploadedFile} />;

    if (isProcessingTable && stepForm !== 2)
      return <LoadingProcessFile processingPercentage={fileProcessingProgress} />;
    if (stepForm === 2 && fileProcessingProgress === 100) {
      return (
        <SecondStep
          openConfirmationModal={openConfimationForm}
          setOpenConfirmationModal={setOpenConfimationForm}
          data={proinfaList}
          isFetchingContracts={isProcessingTable}
        />
      );
    }

    return null;
  };

  const onSubmit: SubmitHandler<ProcessProinfaFormData> = async (values) => {
    try {
      setIsProcessingTable(true);
      const year = values.referenceYear;
      if (uploadedFile) {
        await processProinfaFileHandler({ file: uploadedFile, referenceYear: year });
        setStepForm(2);
        setIsProcessingTable(false);
      }
    } catch (error) {
      setStepForm(1);
      setIsProcessingTable(false);
    }
  };
  return (
    <div className="w-[78vw]">
      <div className="grid grid-cols-2 w-full">
        {proinfaStepTabs.map((stepTab) => (
          <StepTab key={stepTab.step} step={stepTab.step} label={stepTab.label} isActive={stepForm === stepTab.step} />
        ))}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
          <div>
            {renderFormSteps()}
            {!isProcessingTable && (
              <div className="flex gap-4">
                <Button
                  label={'Voltar'}
                  kind={'secondary'}
                  size={'small'}
                  onClick={() => {
                    if (stepForm === 1) return navigate(CONTRACTS_PATH);
                    setFileProcessingProgress(0);
                    setStepForm(1);
                  }}
                  type={'button'}
                  disabled={false}
                />
                {stepForm === 1 ? (
                  <Button
                    loading={false}
                    disabled={false}
                    label="Próximo passo"
                    kind="primary"
                    size="small"
                    type="submit"
                  />
                ) : (
                  <Button
                    loading={false}
                    disabled={false}
                    label="Enviar Proinfa"
                    kind="primary"
                    size="small"
                    type="button"
                    onClick={() => setOpenConfimationForm(!openConfimationForm)}
                  />
                )}
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;

import { UserInfo } from '@contexts/user/types';

export interface TradersListGraphQLData {
  traders: { data: Trader[] };
}

export interface Document {
  label: string;
  uri: string;
  docType: keyof typeof DocType;
}

export interface Trader {
  id: string;
  name: string;
  description: string;
  created_at: string;
  traderType: keyof typeof TraderTypeEnum;
  assets: string;
  companyFoundationYear: number;
  score?: number;
  website: string;
  documents?: Document[];
  logo: File;
  logoName: string;
  users: UserInfo[];
}

export enum TraderTypeEnum {
  RETAIL_TRADER = 'Varejista',
  WHOLESALE_TRADER = 'Atacadista',
  RETAIL_AND_WHOLESALE_TRADER = 'Atacadista e Varejista',
}

export enum DocType {
  PROPOSAL = '0',
  FINAL_PROPOSAL = '1',
  SIGNED_PROPOSAL = '2',
  OTHERS = '3',
  LOGO_IMAGE = '4',
}

export interface UploadTraderLogoData {
  entityId: string;
  entity: string;
  label: string;
  file: File;
  docType: DocType.LOGO_IMAGE;
}

import React from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { NumericFormField } from '@components/atoms/form';

interface ContractedVolumeSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
  isFixedContractedVolume: 'false' | 'true' | null;
  setValue: UseFormSetValue<FieldValues>;
}

const ContractedVolumeFields = ({
  yearsFields,
  startYear,
  isFixedContractedVolume,
  setValue,
}: ContractedVolumeSectionProps): JSX.Element => {
  const propsVolume = {
    placeholder: 'MWm',
    suffix: ' MWm',
    allowNegative: false,
    fixedDecimalScale: true,
    decimalScale: 3,
  };

  return (
    <>
      {isFixedContractedVolume === 'true' ? (
        <NumericFormField<CreateEnergyContractPayload>
          field="fixedContractedVolume"
          id="fixedContractedVolume"
          label="Volume contratado"
          formatProps={propsVolume}
          options={{
            required: { value: true, message: 'Campo é obrigatório' },
          }}
        />
      ) : (
        yearsFields.map((_, idx) => (
          <NumericFormField<CreateEnergyContractPayload>
            key={`volume-year-field-${idx}`}
            field={`contractedVolumePerYear.${idx}.amount`}
            id={`contractedVolumePerYear${idx}.amount`}
            label={`${startYear + idx}`}
            formatProps={propsVolume}
            options={{
              required: { value: true, message: 'Campo é obrigatório' },
              onChange: () => {
                setValue(`contractedVolumePerYear.${idx}.year`, startYear + idx);
              },
            }}
          />
        ))
      )}
    </>
  );
};

export default ContractedVolumeFields;

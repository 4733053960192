import React from 'react';

import { NumericFormField } from '@components/atoms/form';
import FormSectionHeader from '@components/molecules/general/section-form';
import { EconomyReportPayload } from '@contexts/economy-reports/types';
import { inputsFilteredByRetailServiceAndContract } from '../helpers';
import { ComboBoxFormField } from '@components/atoms/form/combobox';
import useTraders from '@hooks/use-trader';
import { ContractTypeEnum } from '@utils/translators/proposal';

interface ProvidedAclProps {
  retailService: boolean;
  chargesForRetailService: boolean;
  contractType: keyof typeof ContractTypeEnum;
}

const ProvidedAclInputs: React.FC<ProvidedAclProps> = ({ retailService, chargesForRetailService, contractType }) => {
  const { traders } = useTraders();

  return (
    <div className="grid grid-cols-3 gap-6 xl:mt-0">
      <FormSectionHeader
        title="Fornecimento e Encargos"
        text="Seção sobre os fornecimento e encargos do mercado livre. Insira as informações obtidas na Fatura de Fornecimento do cliente."
      />
      <div className="grid grid-cols-3 col-span-3 gap-6 lg:col-span-2">
        <div className="col-span-3 lg:col-span-1">
          <ComboBoxFormField
            id="traderId"
            label="Nome do Fornecedor"
            field="traderId"
            placeholder="Fornecedores"
            inputOptions={traders.map(({ name, id }) => ({
              value: id,
              label: name,
            }))}
            options={{ required: { value: true, message: 'Fornecedor é obrigatório' } }}
          />
        </div>

        {inputsFilteredByRetailServiceAndContract(contractType, retailService, chargesForRetailService).map(
          ({ field, allowNegative, label, placeholder, required, decimalScale, suffix, prefix }) => (
            <div className="col-span-3 lg:col-span-1" key={field}>
              <NumericFormField<EconomyReportPayload>
                id={field}
                label={label}
                field={field}
                formatProps={{
                  placeholder,
                  mask: '_',
                  allowNegative,
                  fixedDecimalScale: true,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale,
                  ...(suffix && { suffix }),
                  ...(prefix && { prefix }),
                }}
                options={{
                  valueAsNumber: false,
                  ...(required && { required: { value: true, message: 'Campo obrigatório' } }),
                }}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default ProvidedAclInputs;

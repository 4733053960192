import Footer from '@components/molecules/general/footer';
import React from 'react';

const FooterContainer: React.FC = () => {
  return (
    <div className="grid grid-cols-2 col-span-3 mt-7 xl:grid-cols-4 xl:mt-13">
      {['Clarke Energia', 'cnpj 35.472.171/0001-06', 'Rua dos Pinheiros, 610 – SP', 'operacoes@clarke.com.br'].map(
        (text) => (
          <Footer text={text} key={text} />
        ),
      )}
    </div>
  );
};

export default FooterContainer;

import { gql } from '@apollo/client';

const UNITS_FIELDS = gql`
  fragment UnitsFields on EnergyAuditUnits {
    proinfa
    consumptionAmount
    lostsAmount
    neededAmount
    overplusOrExposureAmount
    freeMarketContractsAmount
    missingHours

    unit {
      id
      name
      cceeProfile {
        name
        idCcee
      }
    }
  }
`;

const ENERGY_CONTRACT_FIELDS = gql`
  fragment ContractFields on EnergyContract {
    id
    idCceeTrader
    perfilCceeTrader
    lowerFlexibility
    upperFlexibility
    trader {
      id
      name
      users {
        id
      }
    }
  }
`;

const GROUP_FIELDS = gql`
  fragment GroupFields on CommercialGroup {
    name
    id
  }
`;

export const GET_ALL_ENERGY_AUDITS_QUERY = gql`
  ${GROUP_FIELDS}
  query GetAllEnergyAudits($page: Int!, $groupId: ID, $status: EnergyAuditStatus, $date: String) {
    getAllEnergyAudits(page: $page, groupId: $groupId, status: $status, date: $date) {
      page
      total
      limit
      data {
        id
        consumptionTotal
        overplusOrExposureTotal
        neededTotal
        status
        numberOfConcludedMeasurements
        totalNumberOfMeasurements
        energyContract {
          id
          trader {
            id
          }
        }
        group {
          ...GroupFields
        }
      }
    }
  }
`;
export const GET_EMAIL_DATA_ENERGY_AUDIT_QUERY = gql`
  ${GROUP_FIELDS}
  ${ENERGY_CONTRACT_FIELDS}
  ${UNITS_FIELDS}
  query EnergyAuditById($energyAuditId: ID!) {
    energyAudit(id: $energyAuditId) {
      id
      consumptionTotal
      overplusOrExposureTotal
      date
      neededTotal
      seasonality
      billedFlex
      upperFlexTotal
      lowerFlexTotal
      freeMarketContractsTotal
      status
      units {
        ...UnitsFields
      }
      energyContract {
        energyType
        ...ContractFields
      }
      group {
        ...GroupFields
      }
    }
  }
`;
export const GET_GROUP_ENERGY_AUDITS_QUERY = gql`
  query GetAllEnergyAudits($page: Int!, $groupId: ID) {
    getAllEnergyAudits(page: $page, groupId: $groupId) {
      data {
        id
        date
        status
        energyContract {
          id
        }
      }
    }
  }
`;

export const GET_ENERGY_AUDIT_BY_ID_QUERY = gql`
  ${GROUP_FIELDS}
  ${UNITS_FIELDS}
  query EnergyAuditById($energyAuditId: ID!) {
    energyAudit(id: $energyAuditId) {
      id
      date
      status
      neededTotal
      freeMarketContractsTotal
      overplusOrExposureTotal
      notifiedAt
      units {
        ...UnitsFields
      }
      energyContract {
        id
        idCceeTrader
        perfilCceeTrader
        lowerFlexibility
        upperFlexibility
        energyType
        trader {
          id
          name
        }
      }
      group {
        ...GroupFields
      }
    }
  }
`;
export const GET_ENERGY_AUDIT_BY_ENERGY_CONTRACT_ID_STATUS_AND_DATE_QUERY = gql`
  ${GROUP_FIELDS}
  ${ENERGY_CONTRACT_FIELDS}
  ${UNITS_FIELDS}
  query EnergyAuditByEnergyContractStatusAndDate($energyContractId: ID!, $status: EnergyAuditStatus!, $date: String!) {
    energyAuditByEnergyContractStatusAndDate(energyContractId: $energyContractId, status: $status, date: $date) {
      id
      date
      status
      neededTotal
      freeMarketContractsTotal
      overplusOrExposureTotal
      seasonality
      billedFlex
      notifiedAt
      upperFlexTotal
      lowerFlexTotal
      units {
        ...UnitsFields
      }
      energyContract {
        ...ContractFields
      }
      group {
        ...GroupFields
      }
    }
  }
`;

export const GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY = gql`
  query getMonthlyControlMeasurementByUnitId($input: GetControlMeasurementInput!) {
    getMonthlyControlMeasurementByUnitId(input: $input) {
      id
      groupId
      unitId
      consolidatedMeasurementDocId
      monthYear
      date
      totalMissingHours
      totalConsumption
      createdAt
    }
  }
`;

export const GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY = gql`
  query GetConsolidatedMeasurementById($getConsolidatedMeasurementByIdId: ID!) {
    getConsolidatedMeasurementById(id: $getConsolidatedMeasurementByIdId) {
      id
      profileCcee
      measuringPoint
      weekday
      monthYear
      datetimeMeasurement {
        datetime
        subType
        status
        activeGeneration
        reactiveGeneration
        activeConsumption
        reactiveConsumption
        source
      }
      createdAt
    }
  }
`;

export const GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY = gql`
  query GetUnitConsumptionMeasuresPerHour($input: GetMonthlyConsumptionMeasuresPerUnitInput!) {
    getUnitConsumptionMeasuresPerHour(input: $input) {
      id
      datetimeMeasurement {
        datetime
        subType
        status
        activeConsumption
        source
      }
    }
  }
`;

import { useContextSelector } from 'use-context-selector';
import { FilesContext, FilesContextType } from '@contexts/files';

export const useFile = (): FilesContextType => {
  const setGroupId = useContextSelector(FilesContext, (files) => files.setGroupId);
  const insertFilePathHandler = useContextSelector(FilesContext, (files) => files.insertFilePathHandler);
  const getGroupFilesHandler = useContextSelector(FilesContext, (files) => files.getGroupFilesHandler);
  const uploadFileHandler = useContextSelector(FilesContext, (files) => files.uploadFileHandler);
  const groupFiles = useContextSelector(FilesContext, (files) => files.groupFiles);
  const groupName = useContextSelector(FilesContext, (files) => files.groupName);
  const isFetchingFiles = useContextSelector(FilesContext, (files) => files.isFetchingFiles);

  return {
    setGroupId,
    insertFilePathHandler,
    getGroupFilesHandler,
    uploadFileHandler,
    groupFiles,
    groupName,
    isFetchingFiles,
  };
};

import { GroupsListGraphQLData, CommercialGroup } from './types';

export class CommercialGroupsManager {
  rawData: GroupsListGraphQLData;
  commercialGroups: CommercialGroup[];

  constructor(rawData: GroupsListGraphQLData) {
    this.rawData = rawData;
    this.commercialGroups = rawData.groups.data;
  }
}

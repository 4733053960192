import numeral from 'numeral';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon, ButtonProps, ColumnOptions, FeedbackNotification, Layout, Modal } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { overflowMenuIcons } from '@pages/contracts/helper';
import { COMMERCIAL_GROUP_PATH, HOME_PATH } from '@routers/constants';
import { UnitNotificationFeedbackContents } from '@utils/constants/common';
import { formatDate, parseStringToDate } from '@utils/dayjs';

import { UnitPayload } from '@contexts/unit/types';

import { useEdcs } from '@hooks/edcs';
import { useUnits } from '@hooks/use-units';

import OverflowMenuTable from '@components/atoms/contracts/overflow-table';
import { UnitsTable } from '@components/molecules/commercial-group/table';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import UnitViewDrawer from '@components/organisms/commercial-group/unit-view-drawer';

type renderFunction = ColumnOptions<UnitPayload>['renderAsElement'];

const CommercialGroupUnits: React.FC = () => {
  const edcs = useEdcs();
  const navigate = useNavigate();
  const {
    units,
    getUnits,
    loading,
    setGroupId,
    groupName,
    deleteUnitHandler,
    setNotificationResponse,
    messageNotificationFeedBack,
    setOpenNotificationFeedback,
    openNotificationFeedBack,
  } = useUnits();

  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const [selectedUnit, setSelectedUnit] = React.useState<UnitPayload | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
  const [openTableMenu, setOpenTableMenu] = React.useState<boolean>(false);
  const [openUnitViewDrawer, setOpenUnitViewDrawer] = React.useState<boolean>(false);
  const { groupId } = useParams<any>();
  const filteredUnits: UnitPayload[] =
    typedSearchTerm?.length > 0
      ? units?.filter((unit) => unit.name.toLowerCase().includes(typedSearchTerm.toLowerCase()))
      : units;

  const renderGroupName: renderFunction = (entry) => entry.name;
  const renderAverageInvoiceAmount: renderFunction = (entry) => {
    const totalVolume = entry.totalConsumptionVolume ? entry.totalConsumptionVolume / 1000 : 0;
    return numeral(totalVolume).format('0.000').concat(' MWm');
  };
  const renderEdcName: renderFunction = (entry) => edcs?.find((edc) => edc.id === entry.edc)?.name ?? '-';
  const renderMigrationDate: renderFunction = (entry) =>
    entry.migrationDate ? formatDate(parseStringToDate(entry.migrationDate), 'DD/MM/YYYY') : '-';
  const renderExpectedMigrationDate: renderFunction = (entry) =>
    entry.expectedMigrationDate ? formatDate(parseStringToDate(entry.expectedMigrationDate), 'DD/MM/YYYY') : '-';

  const unitsTableColumns: ColumnOptions<UnitPayload>[] = [
    { accessor: 'name', header: 'Unidade', renderAsElement: renderGroupName },
    { accessor: 'edc', header: 'Distribuidora', renderAsElement: renderEdcName },
    { accessor: 'averageInvoiceAmount', header: 'Volume médio', renderAsElement: renderAverageInvoiceAmount },
    { accessor: 'migrationDate', header: 'Data da migração', renderAsElement: renderMigrationDate },
    {
      accessor: 'expectedMigrationDate',
      header: 'Data da migração prevista',
      renderAsElement: renderExpectedMigrationDate,
    },
    {
      fixedWidthInRem: 4,
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'EyeIcon'}
            shape="square"
            onClick={() => {
              setSelectedUnit(entry as UnitPayload);
              setOpenUnitViewDrawer(true);
            }}
          />
        );
      },
    },
    {
      fixedWidthInRem: 5,
      renderAsElement: (entry) => {
        return (
          <OverflowMenuTable
            show={openTableMenu && selectedUnit?.id === entry.id}
            itens={overflowMenuIcons}
            buttonClick={() => {
              setOpenTableMenu(!openTableMenu);
              setOpenNotificationFeedback(false);
              setSelectedUnit(entry as UnitPayload);
            }}
            menuClick={(selectedIdx: number) => {
              setOpenTableMenu(false);
              if (selectedIdx === 0) return navigate(`editar/${entry.id}`);
              setOpenNotificationFeedback(false);
              setOpenConfirmationModal(true);
            }}
          />
        );
      },
    },
  ];

  React.useEffect(() => {
    if (groupId) {
      setGroupId(groupId);
    }
  }, [groupId]);

  React.useEffect(() => {
    trackEvent(eventLabels.GROUP_PAGE_VIEWED);
  }, []);

  const headerButtons: Array<ButtonProps> = [
    {
      kind: 'primary',
      label: '+ Nova Unidade',
      size: 'small',
      onClick: () => {
        return navigate('criar/nova-unidade');
      },
    },
  ];

  return (
    <Layout
      title="Unidades Consumidoras"
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: groupName,
          url: COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string),
        },
        {
          label: 'Unidades Consumidoras',
          url: '#',
        },
      ]}
      headerButtons={headerButtons}
      searchBar={{
        label: '',
        placeholder: 'Filtrar por nome da unidade',
        onTypedTermChangedCallback: (term) => setTypedSearchTerm(term),
        onBlur: () => trackEvent(eventLabels.GROUP_PAGE_SEARCH_UNIT),
      }}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full h-full">
        <UnitViewDrawer
          title={selectedUnit ? selectedUnit.name : 'Nome da Unidade'}
          subtitle={groupName}
          cancelBtnClick={() => setOpenUnitViewDrawer(false)}
          openDrawer={openUnitViewDrawer}
          selectedUnit={selectedUnit}
          headerButtonClick={() => navigate(`editar/${selectedUnit?.id}`)}
        />
        <UnitsTable data={filteredUnits} isLoading={loading} columnDefinitions={unitsTableColumns} />
      </div>

      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar unidade"
        description="Tem certeza que deseja excluir essa unidade?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedUnit &&
              deleteUnitHandler(selectedUnit)
                .then(() => {
                  getUnits();
                  setOpenConfirmationModal(false);
                  setNotificationResponse(UnitNotificationFeedbackContents.DELETING_UNIT_SUCCESS);
                  trackEvent(eventLabels.DELETE_COMMERCIAL_UNIT_BUTTON_CLICKED);
                })
                .catch(() => {
                  setOpenConfirmationModal(false);
                  setNotificationResponse(UnitNotificationFeedbackContents.DELETING_UNIT_ERROR);
                });
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default CommercialGroupUnits;

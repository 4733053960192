import React from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import Layout from '@components/templates/main-layout';
import CommercialGroupListPage from '@pages/commercial-groups/list';

import {
  HOME_PATH,
  COMMERCIAL_GROUP_PATH,
  TRADERS_PATH,
  DETAILED_TRADER_PATH,
  USERS_PATH,
  COMMERCIAL_GROUPS_USERS_PATH,
  UNITS_PATH,
  USERS_EMAIL_LOG_PATH,
  CCEE_PROFILES_PATH,
  ECONOMY_REPORT_PATH,
  DOCUMENTS_PATH,
  UNIT_REPORT_PATH,
  GROUP_REPORT_PATH,
  FINANCIAL_EVENTS_PATH,
  NEW_FINANCIAL_EVENT_PATH,
  CONTRACTS_PATH,
  CREATE_ENERGRY_CONTRACT_PATH,
  EDIT_ENERGRY_CONTRACT_PATH,
  CREATE_PROINFA_CONTRACT_PATH,
  ENERGY_AUDIT_PATH,
  DETAILED_ENERGY_AUDIT_PATH,
  KICK_OFF_PATH,
  MEASUREMENT_BY_UNIT_PATH,
  CREATE_CCEE_CONTRACT_PATH,
  EDIT_CCEE_CONTRACT_PATH,
  UPSERT_UNIT_PATH,
  CREATE_CLARKE_CONTRACT_PATH,
  EDIT_CLARKE_CONTRACT_PATH,
  MANAGEMENT_CONTRACTS_PATH,
  PRODUCT_ONE_PATH,
  PRODUCT_TWO_PATH,
  UPDATE_MANAGEMENT_CONTRACT_PATH,
  MIGRATION_REPORT_PATH,
} from './constants';

import CommercialGroupsProvider from '@contexts/commercial-group';
import UnitsProvider from '@contexts/unit';
import EnergyContractProvider from '@contexts/energy-contract';
import TraderUsersProvider from '@contexts/user-trader';
import GroupUsersProvider from '@contexts/user-group';
import TradersProvider from '@contexts/trader';
import CceeProfileProvider from '@contexts/ccee-profile';
import GroupEconomyReportProvider from '@contexts/economy-reports';
import { FilesProvider } from '@contexts/files';
import { UsersProvider } from '@contexts/user';
import UnitEconomyReportProvider from '@contexts/unit-economy-report';
import FinancialEventsProvider from '@contexts/financial-events';
import { NotificationProvider } from '@contexts/notification';
import ProinfaProvider from '@contexts/proinfa-contract';
import EnergyAuditsProvider from '@contexts/energy-audit';
import KickOffProvider from '@contexts/kick-off';
import ClarkeContractProvider from '@contexts/clarke-contract';
import CceeContractProvider from '@contexts/ccee-contract';
import EnergyAuditConsumptionProvider from '@contexts/energy-audit-consumptions';
import ManagementContractProvider from '@contexts/management-contract';
import MigrationReportProvider from '@contexts/migration-report';

import NotFound404 from '@pages/404';
import UnitsList from '@pages/commercial-groups/units';
import CommercialGroupUsers from '@pages/commercial-groups/users-commercial-group';
import CommercialGroupHub from '@pages/commercial-groups/hub-commercial-group';
import TradersListPage from '@pages/traders/list';
import DetailedTraderPage from '@pages/traders/detailed-trader';
import EmailsLogPage from '@pages/users/emails-log';
import Contracts from '@pages/contracts';
import EnergyContractFormPage from '@pages/contracts/energy-contract-form';
import CceeProfilesPage from '@pages/ccee-profile/list';
import EconomyReportsPage from '@pages/commercial-groups/economy-reports-list';
import UnitEconomyReportPage from '@pages/economyReport/unit-report';
import CommercialGroupDocuments from '@pages/commercial-groups/files-commercial-group';
import GroupEconomyReportPage from '@pages/economyReport/group-report';
import CreateFinancialEventsBatch from '@pages/financial-events/create-financial-events-batch';
import FinancialEventsListPage from '@pages/financial-events/list';
import ProinfaForm from '@pages/contracts/proinfa-form';
import EnergyAuditListPage from '@pages/energy-audit/tabs-list';
import CommercialGroupKickOff from '@pages/commercial-groups/kick-off';
import PrivateRoute from './private-route';
import DetailedEnergyAudit from '@pages/energy-audit/detailed-energy-audit';
import MeasurementByUnit from '@pages/energy-audit/measurement-by-unit';
import CceeContractFormPage from '@pages/contracts/ccee-contract-form';
import ClarkeContractFormPage from '@pages/contracts/clarke-contract-form';
import UpsertUnit from '@pages/commercial-groups/upsert-unit';
import { ProductOneSalesContracts } from '@pages/sales-contracts/product-one';
import { ProductTwoSalesContracts } from '@pages/sales-contracts/product-two';
import ProductOneContractGenerator from '@pages/contracts/management/product-one';
import ProductTwoContractGenerator from '@pages/contracts/management/product-two';
import MigrationReports from '@pages/migration-report/migration-reports';

const MainRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<Navigate to={HOME_PATH} />} />
        <Route
          path={HOME_PATH}
          element={
            <CommercialGroupsProvider>
              <CommercialGroupListPage />
            </CommercialGroupsProvider>
          }
        />
        <Route path={COMMERCIAL_GROUP_PATH} element={<Outlet />}>
          <Route
            index
            element={
              <UnitsProvider>
                <GroupUsersProvider>
                  <CommercialGroupHub />
                </GroupUsersProvider>
              </UnitsProvider>
            }
          />
          <Route
            path={UNITS_PATH}
            element={
              <NotificationProvider>
                <UnitsProvider>
                  <UnitsList />
                </UnitsProvider>
              </NotificationProvider>
            }
          />
          <Route
            path={UPSERT_UNIT_PATH}
            element={
              <NotificationProvider>
                <UnitsProvider>
                  <UpsertUnit />
                </UnitsProvider>
              </NotificationProvider>
            }
          />
          <Route
            path={COMMERCIAL_GROUPS_USERS_PATH}
            element={
              <GroupUsersProvider>
                <UsersProvider>
                  <CommercialGroupUsers />
                </UsersProvider>
              </GroupUsersProvider>
            }
          />
          <Route
            path={ECONOMY_REPORT_PATH}
            element={
              <GroupUsersProvider>
                <NotificationProvider>
                  <GroupEconomyReportProvider>
                    <EconomyReportsPage />
                  </GroupEconomyReportProvider>
                </NotificationProvider>
              </GroupUsersProvider>
            }
          />
          <Route
            path={DOCUMENTS_PATH}
            element={
              <FilesProvider>
                <GroupUsersProvider>
                  <CommercialGroupDocuments />
                </GroupUsersProvider>
              </FilesProvider>
            }
          />
          <Route
            path={KICK_OFF_PATH}
            element={
              <KickOffProvider>
                <CommercialGroupKickOff />
              </KickOffProvider>
            }
          />
        </Route>
        <Route
          path={TRADERS_PATH}
          element={
            <TradersProvider>
              <TradersListPage />
            </TradersProvider>
          }
        />
        <Route
          path={DETAILED_TRADER_PATH}
          element={
            <TradersProvider>
              <TraderUsersProvider>
                <UsersProvider>
                  <DetailedTraderPage />
                </UsersProvider>
              </TraderUsersProvider>
            </TradersProvider>
          }
        />
        <Route path={USERS_PATH}>
          <Route
            path={USERS_EMAIL_LOG_PATH}
            element={
              <UsersProvider>
                <EmailsLogPage />
              </UsersProvider>
            }
          />
        </Route>
        <Route
          index
          path={CONTRACTS_PATH}
          element={
            <NotificationProvider>
              <CommercialGroupsProvider>
                <Contracts />
              </CommercialGroupsProvider>
            </NotificationProvider>
          }
        />
        <Route
          index
          path={`${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_ONE_PATH}`}
          element={
            <NotificationProvider>
              <CommercialGroupsProvider>
                <ManagementContractProvider>
                  <ProductOneSalesContracts />
                </ManagementContractProvider>
              </CommercialGroupsProvider>
            </NotificationProvider>
          }
        />
        <Route
          index
          path={`${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_TWO_PATH}`}
          element={
            <NotificationProvider>
              <CommercialGroupsProvider>
                <ManagementContractProvider>
                  <ProductTwoSalesContracts />
                </ManagementContractProvider>
              </CommercialGroupsProvider>
            </NotificationProvider>
          }
        />

        <Route
          path={CREATE_ENERGRY_CONTRACT_PATH}
          element={
            <EnergyContractProvider>
              <NotificationProvider>
                <EnergyContractFormPage />
              </NotificationProvider>
            </EnergyContractProvider>
          }
        />
        <Route
          path={CREATE_CLARKE_CONTRACT_PATH}
          element={
            <NotificationProvider>
              <ClarkeContractProvider>
                <ClarkeContractFormPage />
              </ClarkeContractProvider>
            </NotificationProvider>
          }
        />
        <Route
          path={EDIT_CLARKE_CONTRACT_PATH}
          element={
            <NotificationProvider>
              <ClarkeContractProvider>
                <ClarkeContractFormPage />
              </ClarkeContractProvider>
            </NotificationProvider>
          }
        />
        <Route
          path={CREATE_CCEE_CONTRACT_PATH}
          element={
            <CceeContractProvider>
              <NotificationProvider>
                <CceeContractFormPage />
              </NotificationProvider>
            </CceeContractProvider>
          }
        />
        <Route
          path={EDIT_CCEE_CONTRACT_PATH}
          element={
            <CceeContractProvider>
              <NotificationProvider>
                <CceeContractFormPage />
              </NotificationProvider>
            </CceeContractProvider>
          }
        />
        <Route
          path={EDIT_ENERGRY_CONTRACT_PATH}
          element={
            <EnergyContractProvider>
              <NotificationProvider>
                <EnergyContractFormPage />
              </NotificationProvider>
            </EnergyContractProvider>
          }
        />
        <Route
          path={`${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_ONE_PATH}${UPDATE_MANAGEMENT_CONTRACT_PATH}`} //obs*
          element={
            <NotificationProvider>
              <ManagementContractProvider>
                <ProductOneContractGenerator />
              </ManagementContractProvider>
            </NotificationProvider>
          }
        />
        <Route
          path={`${MANAGEMENT_CONTRACTS_PATH}${PRODUCT_TWO_PATH}${UPDATE_MANAGEMENT_CONTRACT_PATH}`} //obs*
          element={
            <NotificationProvider>
              <ManagementContractProvider>
                <ProductTwoContractGenerator />
              </ManagementContractProvider>
            </NotificationProvider>
          }
        />

        <Route
          path={`${MANAGEMENT_CONTRACTS_PATH}`}
          element={
            <NotificationProvider>
              <Routes>
                <Route
                  path={`${PRODUCT_ONE_PATH}${UPDATE_MANAGEMENT_CONTRACT_PATH}`}
                  element={<ProductOneContractGenerator />}
                />
                <Route
                  path={`${PRODUCT_TWO_PATH}${UPDATE_MANAGEMENT_CONTRACT_PATH}`}
                  element={<ProductTwoContractGenerator />}
                />
              </Routes>
            </NotificationProvider>
          }
        />

        <Route
          path={CREATE_PROINFA_CONTRACT_PATH}
          element={
            <ProinfaProvider>
              <NotificationProvider>
                <ProinfaForm />
              </NotificationProvider>
            </ProinfaProvider>
          }
        />
        <Route
          path={CCEE_PROFILES_PATH}
          element={
            <CceeProfileProvider>
              <CommercialGroupsProvider>
                <NotificationProvider>
                  <UnitsProvider>
                    <CceeProfilesPage />
                  </UnitsProvider>
                </NotificationProvider>
              </CommercialGroupsProvider>
            </CceeProfileProvider>
          }
        />
        <Route
          path={FINANCIAL_EVENTS_PATH}
          element={
            <FinancialEventsProvider>
              <CommercialGroupsProvider>
                <FinancialEventsListPage />
              </CommercialGroupsProvider>
            </FinancialEventsProvider>
          }
        />
        <Route
          path={NEW_FINANCIAL_EVENT_PATH}
          element={
            <FinancialEventsProvider>
              <CreateFinancialEventsBatch />
            </FinancialEventsProvider>
          }
        />
        <Route
          path={ENERGY_AUDIT_PATH}
          element={
            <CommercialGroupsProvider>
              <NotificationProvider>
                <EnergyAuditListPage />
              </NotificationProvider>
            </CommercialGroupsProvider>
          }
        />
        <Route
          path={DETAILED_ENERGY_AUDIT_PATH}
          element={
            <GroupUsersProvider>
              <TraderUsersProvider>
                <EnergyAuditsProvider>
                  <DetailedEnergyAudit />
                </EnergyAuditsProvider>
              </TraderUsersProvider>
            </GroupUsersProvider>
          }
        />
        <Route
          path={MEASUREMENT_BY_UNIT_PATH}
          element={
            <EnergyAuditConsumptionProvider>
              <NotificationProvider>
                <MeasurementByUnit />
              </NotificationProvider>
            </EnergyAuditConsumptionProvider>
          }
        />
        <Route
          path={MIGRATION_REPORT_PATH}
          element={
            <MigrationReportProvider>
              <NotificationProvider>
                <MigrationReports />
              </NotificationProvider>
            </MigrationReportProvider>
          }
        />
      </Route>
      <Route
        path={UNIT_REPORT_PATH}
        element={
          <UnitEconomyReportProvider>
            <UnitEconomyReportPage />
          </UnitEconomyReportProvider>
        }
      />
      <Route
        path={GROUP_REPORT_PATH}
        element={
          <GroupEconomyReportProvider>
            <GroupEconomyReportPage />
          </GroupEconomyReportProvider>
        }
      />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;

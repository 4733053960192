import React, { useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { ContractType, FeedbackType } from '@pages/contracts/helper';
import { formatUuid } from '@utils/text';
import {
  CceeContractGraphQlResponse,
  CreateCceeContractPayload,
  CceeContract,
  CceeInputList,
  Group,
  EnergyContractParsed,
  CceeContractGroupsFilterGraphQlResponse,
  GroupFilterParsed,
  GroupsGraphQlResponse,
  GetEnergyContractsParams,
} from './types';
import {
  GET_CCEE_CONTRACT_BY_ID_QUERY,
  GET_CCEE_CONTRACT_QUERY,
  GET_CCEE_GROUPS_FILTER_QUERY,
  GET_ENERGY_CONTRACTS_QUERY,
  GET_GROUPS_DATA_QUERY,
} from './queries';
import { CREATE_CCEE_CONTRACT, DELETE_CCEE_CONTRACT, UPDATE_CCEE_CONTRACT } from './mutation';
import { parseContractToUpdate, parseEnergyContracts, parserCceeContractsTableData } from './parser';

export type CceeContractContextType = {
  getEnergyContractsHandler(params: GetEnergyContractsParams): Promise<QueryResult<any, OperationVariables>>;
  getGroupsListHandler(): Promise<QueryResult<any, OperationVariables>>;
  getCceeContractsHandler(
    inputParams: CceeInputList,
  ): Promise<QueryResult<CceeContractGraphQlResponse, OperationVariables>>;
  getCceeContractByIdHandler(
    energyContractId: string,
  ): Promise<QueryResult<CceeContractGraphQlResponse, OperationVariables>>;
  createCceeContractHandler: (CceeContract: CreateCceeContractPayload) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  updateCceeContractHandler: (CceeContract: CreateCceeContractPayload) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  deleteCceeContractHandler: (CceeContractId: string | null) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  cceeContracts: CceeContract[];
  loading: boolean;
  selectedContract: CreateCceeContractPayload | null;
  setSelectedContract: React.Dispatch<React.SetStateAction<CreateCceeContractPayload | null>>;
  openCreateContract: boolean;
  setOpenCreateContract: React.Dispatch<React.SetStateAction<boolean>>;
  pagination: Record<string, number>;
  groups: Group[];
  energyContracts: EnergyContractParsed;
  cceeContractToUpdate: CreateCceeContractPayload;
  loadingEnergyContracts: boolean;
  loadingMutation: boolean;
  groupsFilter: GroupFilterParsed;
  getGroupsFilterHandler(
    page: number,
  ): Promise<QueryResult<CceeContractGroupsFilterGraphQlResponse, OperationVariables>>;
};

export const CceeContractContext = createContext({} as CceeContractContextType);

interface Provider {
  children: React.ReactNode;
}

const CceeContractProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [selectedContract, setSelectedContract] = useState<CreateCceeContractPayload | null>(null);
  const [openCreateContract, setOpenCreateContract] = useState(false);
  const [cceeContracts, setCceeContracts] = useState<Array<CceeContract>>([]);
  const [pagination, setPagination] = useState<Record<string, number>>({ total: 0, limit: 0 });
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [energyContracts, setEnergyContracts] = useState<EnergyContractParsed>({ totalPage: 0, energyContracts: [] });
  const [cceeContractToUpdate, setCceeContractToUpdate] = useState<CreateCceeContractPayload>(
    {} as CreateCceeContractPayload,
  );
  const [groupsFilter, setGroupsFilter] = useState<GroupFilterParsed>({ totalPage: 0, groupsFilter: [] });

  const [getCceeContracts, { loading }] = useLazyQuery<CceeContractGraphQlResponse>(GET_CCEE_CONTRACT_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dataParsed = parserCceeContractsTableData(data.cceeContracts.data);
      setCceeContracts(dataParsed);
      setPagination({ total: data.total, limit: data.limit });
    },
    onError: () => {
      setCceeContracts([]);
      setPagination({ total: 0, limit: 0 });
    },
  });

  const [getGroupsFilter, { loading: loadingGroupsFilter }] = useLazyQuery<CceeContractGroupsFilterGraphQlResponse>(
    GET_CCEE_GROUPS_FILTER_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const groupsOptions = data.cceeContracts.data.map(({ group }) => ({
          value: group.id,
          label: group.name,
        }));
        const totalPage = data.total / data.limit;
        setGroupsFilter((prevValue) => ({
          totalPage: Math.ceil(totalPage),
          groupsFilter: [...prevValue.groupsFilter, ...groupsOptions],
        }));
      },
      onError: () => {
        setGroupsFilter({ totalPage: 0, groupsFilter: [] });
      },
    },
  );

  const [getCceeContract, { loading: cceeToUpdateLoading }] = useLazyQuery(GET_CCEE_CONTRACT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const response = data.cceeContract;
      const dataParsed = parseContractToUpdate(response);
      if (response.contractType === 'LONG_DURATION') {
        const contract: EnergyContractParsed = {
          totalPage: NaN,
          energyContracts: Array(1).fill({
            id: response.energyContract.id,
            group: { name: `${response.group.name}(${formatUuid(response.energyContract.id)})`, id: response.group.id },
            retailTraderId: response.trader.id,
            profilesCcee: response.energyContract.profilesCcee.length
              ? response.energyContract.profilesCcee.map(({ id, name }: Record<string, string>) => ({
                  optionLabel: name,
                  value: id,
                }))
              : [],
          }),
        };
        setEnergyContracts(contract);
      } else {
        const group: Group[] = Array(1).fill({
          id: response.group.id,
          name: response.group.name,
          units: [{ cceeProfile: { id: response.profileCcee.id, name: response.profileCcee.name } }],
        });
        setGroups(group);
      }
      setCceeContractToUpdate(dataParsed);
    },
    onError: () => {
      setCceeContractToUpdate({} as CreateCceeContractPayload);
    },
  });

  const [getGroups, { loading: loadingGroups }] = useLazyQuery<GroupsGraphQlResponse>(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const groupsSorted = data.groups.data.sort((groupA, groupB) => (groupA.name >= groupB.name ? 1 : -1));

      setGroups(groupsSorted);
    },
    onError: () => {
      setGroups([]);
    },
  });

  const [getEnergyContracts, { loading: loadingList }] = useLazyQuery(GET_ENERGY_CONTRACTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dataParsed = parseEnergyContracts(data.energyContracts.data);
      const totalPage = data.energyContracts.total / data.energyContracts.limit;
      setEnergyContracts((prevValue) => ({
        totalPage: Math.ceil(totalPage),
        energyContracts: [...prevValue.energyContracts, ...dataParsed],
      }));
    },
    onError: () => {
      setEnergyContracts({ totalPage: 0, energyContracts: [] });
    },
  });

  const [createCceeContract, { loading: creationLoading }] = useMutation(CREATE_CCEE_CONTRACT, {
    fetchPolicy: 'network-only',
  });
  const [updateCceeContract, { loading: updateLoading }] = useMutation(UPDATE_CCEE_CONTRACT, {
    fetchPolicy: 'network-only',
  });
  const [deleteCceeContract, { loading: deleteLoading }] = useMutation(DELETE_CCEE_CONTRACT, {
    fetchPolicy: 'network-only',
  });

  async function getCceeContractsHandler(inputParams: CceeInputList) {
    return await getCceeContracts({
      variables: {
        input: inputParams,
      },
    });
  }

  async function getCceeContractByIdHandler(cceeContractId: string) {
    return await getCceeContract({
      variables: {
        cceeContractId,
      },
    });
  }

  async function getGroupsListHandler() {
    return await getGroups();
  }

  async function getEnergyContractsHandler(params: GetEnergyContractsParams) {
    return await getEnergyContracts({
      variables: { input: params },
    });
  }

  async function getGroupsFilterHandler(page: number) {
    return await getGroupsFilter({
      variables: {
        input: { page, contractType: null, groupId: null, term: null },
      },
    });
  }

  function createCceeContractHandler(payload: CreateCceeContractPayload) {
    return createCceeContract({
      variables: {
        input: payload,
      },
    })
      .then((response) => {
        return {
          id: response.data['createCceeContract']['id'],
          feedback: { message: 'CREATING_CONTRACT_SUCCESS' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'CREATING_CONTRACT_ERROR' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      });
  }

  function updateCceeContractHandler(payload: CreateCceeContractPayload) {
    return updateCceeContract({
      variables: {
        input: { ...payload },
      },
    })
      .then((response) => {
        return {
          id: response.data['createCceeContract']['id'],
          feedback: { message: 'UPDATING_CONTRACT_SUCCESS' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'UPDATING_CONTRACT_ERROR' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      });
  }

  function deleteCceeContractHandler(CceeContractId: string | null) {
    return deleteCceeContract({
      variables: {
        input: {
          id: CceeContractId,
        },
      },
      refetchQueries: [GET_CCEE_CONTRACT_QUERY],
    })
      .then((response) => {
        return {
          id: response.data['deleteCceeContract']['id'],
          feedback: { message: 'DELETING_CONTRACT_SUCCESS' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'DELETING_CONTRACT_ERROR' as FeedbackType, tab: 'CCEE' as ContractType },
        };
      });
  }

  return (
    <CceeContractContext.Provider
      value={{
        getGroupsListHandler,
        getCceeContractsHandler,
        getCceeContractByIdHandler,
        createCceeContractHandler,
        deleteCceeContractHandler,
        updateCceeContractHandler,
        cceeContracts,
        loadingEnergyContracts: loadingList,
        loading: loading || loadingGroups || cceeToUpdateLoading || loadingGroupsFilter,
        loadingMutation: creationLoading || updateLoading || deleteLoading,
        selectedContract,
        setSelectedContract,
        openCreateContract,
        setOpenCreateContract,
        pagination,
        groups,
        energyContracts,
        getEnergyContractsHandler,
        cceeContractToUpdate,
        groupsFilter,
        getGroupsFilterHandler,
      }}
    >
      {children}
    </CceeContractContext.Provider>
  );
};

export default CceeContractProvider;

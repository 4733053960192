import React from 'react';
import CustomerInfo from '../customer-data';
import { displayGroupCustomerInfo } from './helper';

interface FirstSectionProps {
  customerData: {
    name: string;
    unitsAmount: number;
    edcs: string;
    contractedDemandAmount: string;
    traders: string;
    contractType: string;
  };
  isFetchingData: boolean;
}

const FirstSection = ({ customerData, isFetchingData }: FirstSectionProps): JSX.Element => {
  return (
    <div className="hidden xl:block xl:col-span-1 self-end xl:pb-[10%] 2xl:pb-[12%]">
      <CustomerInfo
        header="Dados Gerais"
        isFetchingData={isFetchingData}
        infos={displayGroupCustomerInfo(customerData)}
      />
    </div>
  );
};

export default FirstSection;

import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import { FileField } from '@components/atoms/form/file/file-input';
import { useFormContext } from 'react-hook-form';

const ContractDocumentsSection = () => {
  const { setValue } = useFormContext<IManagementContractPayload>();

  const onChangeFiles = (files: Array<File>) => {
    setValue('attachments', files);
  };

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection title="Documentos" subtitle="Vincule documentos a esse contrato." />
        <FileField<IManagementContractPayload>
          field="attachments"
          id="attachments"
          label={'Anexar documentos'}
          description={'Envie documentos referente ao contrato de gestão desse grupo comercial. PDF de até 5MB'}
          maximumFileSizeInMB={5}
          allowedFileTypes={['.pdf']}
          onChangeFiles={(files) => onChangeFiles(files as Array<File>)}
          multipleFiles
        />
      </>
    </ContractSectionContainer>
  );
};

export default ContractDocumentsSection;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import AccordionItem from '@components/atoms/commercial-groups/accordion-item';
import { Table, ColumnOptions } from '@clarke-energia/foton';
import { CommercialGroup } from '@contexts/commercial-group/types';
import { UnitPayload } from '@contexts/unit';
import PageSection from '../general/page-section';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface CommercialGroupsTableProps {
  isLoading: boolean;
  data: CommercialGroup[];
  columnDefinitions: ColumnOptions<CommercialGroup>[];
}

export interface UnitsTableProps {
  isLoading: boolean;
  data: UnitPayload[];
  columnDefinitions: ColumnOptions<UnitPayload>[];
}

export const CommercialGroupsTable: React.FC<CommercialGroupsTableProps> = ({ isLoading, data, columnDefinitions }) => {
  return (
    <div className="flex flex-col space-y-10">
      <AccordionItem label="Empresas" labelSubscript={`${data.length}`} testId="data-accordion">
        <div className="overflow-x-auto">
          {isLoading ? <RowSkeleton /> : <Table data={data} tableColumns={columnDefinitions} />}
        </div>
      </AccordionItem>
    </div>
  );
};

export const UnitsTable: React.FC<UnitsTableProps> = ({ isLoading, data, columnDefinitions }) => {
  return (
    <div className="flex overflow-visible flex-col space-y-10 h-full">
      <PageSection title="Unidades" className="h-full">
        <div className="overflow-x-auto h-full">
          {isLoading ? <RowSkeleton /> : <Table data={data} tableColumns={columnDefinitions} />}
        </div>
      </PageSection>
    </div>
  );
};

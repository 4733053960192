import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

export interface CheckboxFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  id: string;
  options?: RegisterOptions<T, FieldPath<T>>;
}

export function CheckboxFormField<T extends FieldValues>({ field, label, id, options }: CheckboxFormFieldProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="flex flex-col space-y-1">
          <Checkbox
            id={id}
            name={name}
            label={label}
            required={(options?.required as boolean) || false}
            onBlur={onBlur}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
        </div>
      )}
    />
  );
}

import { useContextSelector } from 'use-context-selector';
import { CommercialGroupContext, CommercialGroupContextType } from '@contexts/commercial-group';

const useCommercialGroups = (): CommercialGroupContextType => {
  const getCommercialGroups = useContextSelector(CommercialGroupContext, (groups) => groups.getCommercialGroups);
  const createCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.createCommercialGroupHandler,
  );
  const updateCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.updateCommercialGroupHandler,
  );
  const deleteCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.deleteCommercialGroupHandler,
  );
  const commercialGroups = useContextSelector(CommercialGroupContext, (groups) => groups.commercialGroups);
  const loading = useContextSelector(CommercialGroupContext, (groups) => groups.loading);

  return {
    createCommercialGroupHandler,
    updateCommercialGroupHandler,
    deleteCommercialGroupHandler,
    getCommercialGroups,
    commercialGroups,
    loading,
  };
};

export default useCommercialGroups;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Drawer, Tabs } from '@clarke-energia/foton';

import { EnergyAuditFormated } from '@contexts/energy-audit/types';
import CustomerEmailTemplate from '@components/atoms/energy-audit/customer-email-template';
import RecipientsTab from '@components/atoms/energy-audit/recipients-tab';
import TraderEmailTemplate from '@components/atoms/energy-audit/trader-email-template';

interface Emails {
  group: Array<string>;
  trader: Array<string>;
  isLoading: boolean;
}

interface EmailDrawerProps {
  subtitle: string;
  actionBtnClick: () => void;
  cancelBtnClick: () => void;
  openDrawer: boolean;
  emails: Emails;
  energyAudit: EnergyAuditFormated | null;
}

const EmailDrawer: React.FC<EmailDrawerProps> = ({
  subtitle,
  actionBtnClick,
  cancelBtnClick,
  openDrawer,
  emails,
  energyAudit,
}) => {
  return (
    <Drawer
      className="max-w-xl"
      title={energyAudit ? 'Enviar balanço energético' : ''}
      subtitle={energyAudit ? subtitle : ''}
      description={
        energyAudit
          ? 'Confira os resultados e o conteúdo dos emails do cliente e da fornecedora abaixo. Se tudo estiver certo, envie o balanço energético.'
          : ''
      }
      drawerButtons={
        energyAudit
          ? {
              primary: {
                kind: 'primary',
                label: 'Enviar balanço',
                onClick: () => actionBtnClick(),
              },
              secondary: {
                kind: 'secondary',
                label: 'Cancelar',
                onClick: () => cancelBtnClick(),
              },
            }
          : undefined
      }
      open={openDrawer}
      onClose={() => cancelBtnClick()}
      drawerWidth={'w-[40rem]'}
    >
      <div className="mt-1">
        {!energyAudit ? (
          <Skeleton height={800} />
        ) : (
          <Tabs
            tabs={[
              {
                label: 'Email Cliente',
                children: <CustomerEmailTemplate energyAudit={energyAudit} />,
              },
              {
                label: 'Email Fornecedora',
                children: <TraderEmailTemplate energyAudit={energyAudit} />,
              },
              {
                label: 'Destinatários',
                children: (
                  <RecipientsTab
                    customerRecipients={emails.group}
                    tradersRecipients={emails.trader}
                    isLoadingEmails={emails.isLoading}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
    </Drawer>
  );
};

export default EmailDrawer;

import { IconProps } from '@clarke-energia/foton';
import {
  COMMERCIAL_GROUPS_USERS_PATH,
  DOCUMENTS_PATH,
  UNITS_PATH,
  ECONOMY_REPORT_PATH,
  KICK_OFF_PATH,
} from '@routers/constants';
type hubCards = {
  icon: IconProps['icon'];
  label: string;
  path: string;
};

export const hubCardsInfo: hubCards[] = [
  {
    icon: 'HomeIcon',
    label: 'Unidades Consumidoras',
    path: UNITS_PATH,
  },
  {
    icon: 'AdjustmentsVerticalIcon',
    label: 'Kick Off',
    path: KICK_OFF_PATH,
  },
  {
    icon: 'DocumentChartBarIcon',
    label: 'Relatórios de Economia',
    path: ECONOMY_REPORT_PATH,
  },
  {
    icon: 'UserGroupIcon',
    label: 'Usuários',
    path: COMMERCIAL_GROUPS_USERS_PATH,
  },
  {
    icon: 'DocumentIcon',
    label: 'Documentos',
    path: DOCUMENTS_PATH,
  },
];

import { z } from 'zod';
import { validateUnitPayload } from './validation';

const cceeProfileSchema = z
  .object({
    id: z.string().optional(),
    idCcee: z.number().optional(),
  })
  .nullable()
  .optional();

const unitTypeEnum = z.enum(['COMMERCIAL', 'RURAL', 'INDUSTRIAL']).optional();

const subgroupEnum = z.enum(['A2', 'A3', 'A4', 'AS']).optional();

const tariffModalityEnum = z.enum(['GREEN', 'BLUE']).optional();

const unitDocTypeEnum = z.enum(['CNPJ', 'CPF']);

const docTypeSchema = z.union([unitDocTypeEnum, z.literal(1), z.literal(2)]);

const energyTypeEnum = z.enum([
  'CONVENTIONAL',
  'INCENTIVIZED_0',
  'INCENTIVIZED_50',
  'INCENTIVIZED_80',
  'INCENTIVIZED_100',
]);

const submarketEnum = z.enum(['NORTH', 'NORTHEAST', 'SOUTH', 'SOUTHEAST']).optional();

export const parsedConsumptionHistoryEntrySchema = z.object({
  id: z.string(),
  generator: z.number().nullable().optional(),
  peak: z.number().nullable().optional(),
  offPeak: z.number().nullable().optional(),
  yearMonth: z.string().nullable().optional(),
});

const month = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const historySchema = z.object(
  Object.fromEntries(month.map((month) => [month, parsedConsumptionHistoryEntrySchema])),
);

export const unitPayloadBaseSchema = z.object({
  id: z.string(),
  edc: z.number().optional(),
  name: z.string(),
  legalName: z.string(),
  group: z.object({ id: z.string().optional() }).optional(),
  cceeProfile: cceeProfileSchema,
  unitType: unitTypeEnum,
  tariffSubgroup: subgroupEnum,
  tariffModality: tariffModalityEnum,
  contractedDemandOffPeak: z.number().nullable().optional(),
  contractedDemandPeak: z.number().nullable().optional(),
  contractedDemandOffPeakBeforeMigration: z.number().nullable().optional(),
  contractedDemandPeakBeforeMigration: z.number().nullable().optional(),
  averageInvoiceAmount: z.number().nullable().optional(),
  docType: docTypeSchema,
  docNumber: z.string(),
  energyType: z.union([energyTypeEnum, z.string().length(0)]).optional(),
  state: z.string().nullable().optional(),
  submarket: submarketEnum,
  migrationDate: z.string().nullable().optional(),
  expectedMigrationDate: z.string().nullable().optional(),
  powerGenerator: z.boolean(),
  assetCode: z.string().optional(),
  measuringPoint: z.string().optional(),
  history: historySchema,
});

export const unitPayloadSchema = unitPayloadBaseSchema.superRefine((unit, ctx) => {
  validateUnitPayload(unit, ctx);
});

import { UseFieldArrayRemove } from 'react-hook-form';
import { GenericFormField } from '../../fields/generic-form-field';
import { IManagementContractPayload } from '@contexts/management-contract/types';

interface ISimulatorFormUnitSection {
  children?: JSX.Element;
  equalDemandAsDefault?: boolean;
  fieldsController?: Record<string, boolean>;
  removeUnit?: UseFieldArrayRemove;
  unitary?: boolean;
  unitNum: number;
  unitsLength?: number;
  unifiedConsumption?: boolean;
}
interface IContractUnitGeneralSubsection extends ISimulatorFormUnitSection {
  unitNickname?: string;
}

export function ContractUnitGeneralSubsection({ unitNum }: IContractUnitGeneralSubsection) {
  return (
    <>
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.name`}
        field={`inputs.units.${unitNum}.name`}
        label="Nome da UC"
      />
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.address`}
        field={`inputs.units.${unitNum}.address`}
        label="Endereço da UC"
      />
      <GenericFormField<IManagementContractPayload>
        id={`inputs.units.${unitNum}.installationNumber`}
        field={`inputs.units.${unitNum}.installationNumber`}
        label="Número da Instalação elétrica da UC"
      />
    </>
  );
}

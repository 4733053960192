import React from 'react';
import { NuggetInfo, NuggetInfoProps } from '@clarke-energia/foton';

export interface UnitDetailedInfoSectionProps {
  unitInfosParsed: NuggetInfoProps[];
}

const UnitDetailedInfoSection = ({ unitInfosParsed }: UnitDetailedInfoSectionProps) => {
  return (
    <div className="col-span-full">
      <h1 className="mb-5">Informações da unidade</h1>
      <div className="grid grid-cols-2 gap-5 sm:grid-cols-3">
        {unitInfosParsed.map((nuggetInfo) => (
          <NuggetInfo key={nuggetInfo.id} label={nuggetInfo.label} textInfo={nuggetInfo.textInfo} />
        ))}
      </div>
    </div>
  );
};

export default UnitDetailedInfoSection;

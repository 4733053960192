import { FeedbackNotificationProps } from '@clarke-energia/foton';

import { FINANCIAL_EVENTS_PATH, HOME_PATH } from '@routers/constants';

import { EmailsTabTypes } from '@contexts/energy-audit';
import { FinancialEventType, IFinancialEventsSendingStatus } from '@contexts/financial-events/types';

export interface IFinancialEventsTab {
  id: string;
  type: FinancialEventType;
  title: string;
  disabled: boolean;
}

export interface IFinancialEventsBatchCreationStep {
  id: string;
  label: string;
}

export interface ISendingBatchStatusType {
  id: string;
  label: string;
  description: string;
  progress: number;
}

export interface IEmailTabs {
  id: string;
  label: string;
  type: EmailsTabTypes;
}

export const financialEventsTabs: IFinancialEventsTab[] = [
  {
    id: '1',
    type: FinancialEventType.FINANCIAL_GUARANTEE,
    title: 'Garantia Financeira',
    disabled: false,
  },
  {
    id: '2',
    type: FinancialEventType.FINANCIAL_SETTLEMENT,
    title: 'Liquidação Financeira',
    disabled: false,
  },
  {
    id: '3',
    type: FinancialEventType.RESERVE_ENERGY_CHARGE,
    title: 'EER',
    disabled: false,
  },
  {
    id: '4',
    type: FinancialEventType.ASSOCIATIVE_CONTRIBUTION,
    title: 'Contribuição Associativa',
    disabled: false,
  },
];

export const confirmationDataStatusDescription =
  'Os status mostram o sucesso da importação da tabela. Caso haja algum erro, o evento financeiro não será enviado e precisará ser feito posteriormente.';

export const financialEventsBatchCreationSteps: IFinancialEventsBatchCreationStep[] = [
  {
    id: 'sendData',
    label: 'Enviar Dados',
  },
  { id: 'attachments', label: 'Relacionar Anexos' },
  {
    id: 'review',
    label: 'Confirmar dados',
  },
];

export const sendingBatchStatusType: ISendingBatchStatusType[] = [
  {
    id: '1',
    label: 'Enviando tabela',
    description: 'Carregando tabela no sistema....',
    progress: 0.1,
  },
  {
    id: '2',
    label: 'Entendendo dados',
    description: 'Conferindo informações da tabela...',
    progress: 0.2,
  },
  {
    id: '3',
    label: 'Relacionando informações',
    description: 'Estabelecendo conexões entre as células...',
    progress: 0.4,
  },
  {
    id: '4',
    label: 'Conectando valores',
    description: 'Buscando no banco de dados...',
    progress: 0.6,
  },
  {
    id: '5',
    label: 'Quase lá!',
    description: 'Trazendo visualização...',
    progress: 0.8,
  },
];

export const getSendFinancialEventsByBatchIDFeedbackContent = (
  onCloseNotification: () => void,
): Record<IFinancialEventsSendingStatus, FeedbackNotificationProps> => ({
  SENDING: {
    kind: 'neutral',
    message: 'Preparando eventos para envio...',
    label: 'Aguarde',
    onCloseNotification: onCloseNotification,
  },
  SENDING_FAILED: {
    kind: 'error',
    message: 'Houve um erro ao enviar os eventos.',
    label: 'Erro',
    onCloseNotification: onCloseNotification,
  },
  SENDING_SUCCESS: {
    kind: 'primary',
    message: 'Os eventos estão sendo enviados',
    label: 'Sucesso',
    onCloseNotification: onCloseNotification,
  },
});

export const energyAuditsEmailsTabs: IEmailTabs[] = [
  {
    id: '1',
    label: 'Email Cliente',
    type: EmailsTabTypes.CLIENT,
  },
  {
    id: '2',
    label: 'Email Fornecedora',
    type: EmailsTabTypes.TRADER,
  },
  {
    id: '3',
    label: 'Destinatários',
    type: EmailsTabTypes.RECIPIENTS,
  },
];

export const financialEventsCreationPageNavigationHistoryLinks = [
  {
    label: 'Início',
    url: HOME_PATH,
  },
  {
    label: 'Eventos Financeiros',
    url: FINANCIAL_EVENTS_PATH,
  },
  {
    label: 'Novo evento',
    url: '#',
  },
];

export const financialEventsListPageNavigationHistoryLinks = [
  {
    label: 'Início',
    url: HOME_PATH,
  },
  {
    label: 'Eventos Financeiros',
    url: '#',
  },
];

import { gql } from '@apollo/client';

import { KICK_OFF_GRAPHQL_SCHEMA } from './queries';

export const CREATE_KICK_OFF_BY_GROUP_ID_MUTATION = gql`
  mutation CreateKickOffByGroupID($groupId: ID!) {
    createKickOffByGroupID(groupId: $groupId) {
      ${KICK_OFF_GRAPHQL_SCHEMA}
    }
  }
`;

export const UPDATE_KICK_OFF_QUIZ_AVAILABILITY_MUTATION = gql`
  mutation UpdateKickOffQuizAvailability($kickOffId: ID!, $quizStatus: KickOffQuizStatus) {
    updateKickOffQuizAvailability(kickOffId: $kickOffId, quizStatus: $quizStatus)
  }
`;

export const SEND_KICK_OFF_QUIZ_EMAIL_MUTATION = gql`
  mutation SendKickOffQuizEmail($kickOffId: ID!) {
    sendKickOffQuizEmail(kickOffId: $kickOffId)
  }
`;

export const UPDATE_KICK_OFF_DIAGNOSIS_MUTATION = gql`
  mutation UpdateKickOffDiagnosis($kickOffId: ID!, $diagnosisData: KickOffDiagnosisInput!) {
    updateKickOffDiagnosis(kickOffId: $kickOffId, diagnosisData: $diagnosisData) {
      ${KICK_OFF_GRAPHQL_SCHEMA}
    }
  }
`;

import { gql } from '@apollo/client';

export const KICK_OFF_GRAPHQL_SCHEMA = `
  id
  createdAt
  status
  unitsSummary {
    name
    averageConsumptionInMwm
    edcId
    higherContractedDemandInKw
    tariffModality
    tariffSubgroup
  }
  commercialGroup {
    id
    name
  }
  diagnosis {
    profileType
    managementType
    suggestedProduct {
      averageConsumptionInMwm
      contractTermInYears
      lowerFlexibility
      productType
      traderType
      upperFlexibility
      warrantyType
    }
  }
  quiz {
    link
    status
    submittedAt
    inputs {
      managementType
      profileType
      projectedPercentageChangeInConsumptionPerYear
      iRecCertification
    }
  }
`;

export const GET_KICK_OFF_BY_GROUP_ID_QUERY = gql`
  query GetKickOffByGroupID($groupId: ID!) {
    getKickOffByGroupID(groupId: $groupId) {
      ${KICK_OFF_GRAPHQL_SCHEMA}
    }
  }
`;

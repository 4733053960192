import { INotificationFeedbackContent } from '@utils/constants/common';
import { IconName } from '@components/atoms/hero-icon';

export type ContractType = 'ENERGY' | 'CCEE' | 'PROINFA' | 'CLARKE_CONTRACT';

export type FeedbackType =
  | 'CREATING_CONTRACT_SUCCESS'
  | 'UPDATING_CONTRACT_SUCCESS'
  | 'DELETING_CONTRACT_SUCCESS'
  | 'DELETING_CONTRACT_ATTACHMENT_SUCCESS'
  | 'CREATING_CONTRACT_ERROR'
  | 'UPDATING_CONTRACT_ERROR'
  | 'DELETING_CONTRACT_ATTACHMENT_ERROR'
  | 'DELETING_CONTRACT_ERROR';

interface ContractsTabsTypes {
  title: string;
  type: ContractType;
}

interface FormTab {
  step: 1 | 2;
  label: string;
}

export interface PopUpItems {
  label: string;
  iconName: IconName | null;
  iconPath?: string;
  onClick: (item: any) => void;
}

export interface OverflowMenu {
  label: string;
  icon: IconName;
  specificClassName: string;
}

enum TabType {
  ENERGY = 'CCEAL',
  CCEE = 'CCEE',
  PROINFA = 'Proinfa',
  CLARKE_CONTRACT = 'Clarke',
}

export const proinfaStepTabs: FormTab[] = [
  {
    step: 1,
    label: 'Enviar Dados',
  },
  {
    step: 2,
    label: 'Confirmar dados',
  },
];

export const contractsTabs: ContractsTabsTypes[] = [
  {
    title: 'CCEAL',
    type: 'ENERGY',
  },
  {
    title: 'Contratos CCEE',
    type: 'CCEE',
  },
  {
    title: 'PROINFA',
    type: 'PROINFA',
  },
  {
    title: 'Contrato Clarke',
    type: 'CLARKE_CONTRACT',
  },
];

export const overflowMenuIcons: OverflowMenu[] = [
  {
    label: 'Editar',
    icon: 'PencilIcon',
    specificClassName: 'top-0',
  },
  {
    label: 'Deletar',
    icon: 'TrashIcon',
    specificClassName: 'top-7',
  },
];

export const contractFeedbackMessage = (contractType: ContractType, feedbackType: FeedbackType) => {
  const message: Record<FeedbackType, INotificationFeedbackContent> = {
    CREATING_CONTRACT_SUCCESS: {
      label: 'Sucesso',
      message: `Contrato ${TabType[contractType]} cadastrado com sucesso!`,
      kind: 'primary',
    },
    CREATING_CONTRACT_ERROR: {
      label: 'Erro',
      message: `Ocorreu um erro no cadastro do Contrato ${TabType[contractType]}. Revise os dados e tente novamente.`,
      kind: 'error',
    },
    UPDATING_CONTRACT_SUCCESS: {
      label: 'Sucesso',
      message: `Contrato ${TabType[contractType]} atualizado com sucesso!`,
      kind: 'primary',
    },
    UPDATING_CONTRACT_ERROR: {
      label: 'Erro',
      message: `Ocorreu um erro na atualização do Contrato ${TabType[contractType]}. Revise os dados e tente novamente.`,
      kind: 'error',
    },
    DELETING_CONTRACT_SUCCESS: {
      label: 'Sucesso',
      message: `Contrato ${TabType[contractType]} excluído com sucesso!`,
      kind: 'primary',
    },
    DELETING_CONTRACT_ATTACHMENT_SUCCESS: {
      label: 'Sucesso',
      message: `Arquivo excluído com sucesso!`,
      kind: 'primary',
    },
    DELETING_CONTRACT_ERROR: {
      label: 'Erro',
      message: `Ocorreu um erro na exclusão do Contrato ${TabType[contractType]}.`,
      kind: 'error',
    },
    DELETING_CONTRACT_ATTACHMENT_ERROR: {
      label: 'Erro',
      message: `Ocorreu um erro na exclusão do arquivo.`,
      kind: 'error',
    },
  };

  return message[feedbackType];
};

import React from 'react';

import { FinancialEventType } from '@contexts/financial-events';
import useFinancialEvents from '@hooks/use-financial-events';

import FinancialEventsBatchProcessingWait from '@components/organisms/financial-events/batch-processing-wait';
import FinancialEventsBatchProcessingConfirmation from '@components/organisms/financial-events/batch-processing-confirmation';
import FinancialEventsBatchAttachmentsUpdating from '@components/organisms/financial-events/batch-attachments-updating';

import CreateFinancialEventsBatchForm from './batch-creation-form';

interface ICreateFinancialEventBatchCore {
  financialEventsType?: keyof typeof FinancialEventType;
  financialEventStep: number;
  isProcessingBatch: boolean;
  setFinancialEventStep: React.Dispatch<React.SetStateAction<number>>;
  setFinancialEventsType: React.Dispatch<React.SetStateAction<keyof typeof FinancialEventType | undefined>>;
  setIsProcessingBatch: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNotificationFeedBack: (value: boolean) => void;
}

function CreateFinancialEventBatchCore({
  financialEventsType,
  financialEventStep,
  isProcessingBatch,
  setFinancialEventStep,
  setFinancialEventsType,
  setIsProcessingBatch,
  setOpenNotificationFeedBack,
}: ICreateFinancialEventBatchCore) {
  const {
    financialEventsBatch,
    refetchGetFinancialEventsBatchHandler,
    createFinancialEventsBatchHandler,
    setFinancialEventsBatchId,
    financialEventsBatchId,
  } = useFinancialEvents();

  if (financialEventStep === 1 && !isProcessingBatch) {
    return (
      <CreateFinancialEventsBatchForm
        createFinancialEventsBatchHandler={createFinancialEventsBatchHandler}
        setFinancialEventsBatchId={setFinancialEventsBatchId}
        onFormSuccess={() => {
          setFinancialEventStep(financialEventsType === 'ASSOCIATIVE_CONTRIBUTION' ? 2 : 3);
          setIsProcessingBatch(true);
        }}
        setFinancialEventsType={setFinancialEventsType}
        onFormFail={() => setOpenNotificationFeedBack(true)}
      />
    );
  } else if (isProcessingBatch) {
    return (
      <FinancialEventsBatchProcessingWait
        financialEventsBatchId={financialEventsBatchId}
        financialEventsBatch={financialEventsBatch}
        refetchGetFinancialEventsBatchHandler={refetchGetFinancialEventsBatchHandler}
        onSuccess={() => {
          setIsProcessingBatch(false);
        }}
      />
    );
  } else if (financialEventStep === 2) {
    return <FinancialEventsBatchAttachmentsUpdating setFinancialEventStep={setFinancialEventStep} />;
  } else {
    return (
      <FinancialEventsBatchProcessingConfirmation
        setFinancialEventStep={setFinancialEventStep}
        currentStep={financialEventsType === 'ASSOCIATIVE_CONTRIBUTION' ? 3 : 2}
      />
    );
  }
}

export default CreateFinancialEventBatchCore;

import { gql } from '@apollo/client';

export const GET_CCEE_CONTRACT_QUERY = gql`
  query GetCceeContracts($input: GetCceeContractInput) {
    cceeContracts(input: $input) {
      total
      limit
      data {
        id
        contractCceeId
        startDate
        energyType
        contractType
        energyContract {
          startSupplyDate
          profilesCcee {
            name
          }
          units {
            submarket
          }
        }
        group {
          name
        }
        trader {
          name
        }
        profileCcee {
          id
          name
          units {
            submarket
          }
        }
      }
    }
  }
`;

export const GET_CCEE_CONTRACT_BY_ID_QUERY = gql`
  query CceeContract($cceeContractId: ID!) {
    cceeContract(id: $cceeContractId) {
      id
      contractCceeId
      group {
        id
        name
      }
      trader {
        id
        name
        cceeTraderId
        cceeTraderProfile
      }
      tradedVolume
      energyPrice
      contractType
      startDate
      endDate
      energyType
      energyContract {
        id
        profilesCcee {
          id
          name
        }
      }
      profileCcee {
        id
        name
      }
    }
  }
`;

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        units {
          cceeProfile {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ENERGY_CONTRACTS_QUERY = gql`
  query EnergyContracts($input: GetEnergyContractsInput) {
    energyContracts(input: $input) {
      total
      limit
      data {
        id
        profilesCcee {
          id
          name
        }
        units {
          group {
            name
            id
          }
        }
        trader {
          id
        }
      }
    }
  }
`;
export const GET_CCEE_GROUPS_FILTER_QUERY = gql`
  query GetCceeContracts($input: GetCceeContractInput) {
    cceeContracts(input: $input) {
      total
      limit
      data {
        group {
          name
          id
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const CREATE_GROUPS_USER = gql`
  mutation CreateGroupsUser($input: UpsertGroupsUsersInput) {
    createGroupsUser(input: $input) {
      createdAt
    }
  }
`;

export const DELETE_GROUPS_USER = gql`
  mutation ($input: UpsertGroupsUsersInput) {
    deleteGroupsUser(input: $input)
  }
`;

export const CATEGORIZE_GROUP_USER_ROLES = gql`
  mutation CategorizeGroupUserRoles($input: categorizeGroupUserRolesInput) {
    categorizeGroupUserRoles(input: $input)
  }
`;

import numeral from 'numeral';

import { EnergyTypeTableEnum } from '@contexts/ccee-contract/types';
import { SubmarketEnum } from '@utils/translators/proposal';
import { EnergyAuditReport, EnergyAuditReportFormated } from './types';

export const formatEnergyAuditReports = (reports: Array<EnergyAuditReport>): EnergyAuditReportFormated[] => {
  return reports.map(
    ({ energyType, cceeContractNumber, submarket, traderProfileName, group, unit, profile, volumeMwm }) => ({
      contractCceeNumber: cceeContractNumber ? cceeContractNumber.toString() : '-',
      energyType: energyType ? EnergyTypeTableEnum[energyType] : '-',
      submarket: submarket ? SubmarketEnum[submarket] : '-',
      profileTrader: traderProfileName?.length ? traderProfileName : '-',
      group: group.name,
      unit: unit?.name ?? '-',
      profileCustomer: profile?.name ?? '-',
      volume: numeral(volumeMwm).format('0.000000').concat(' MWm'),
    }),
  );
};

import React from 'react';
import { User } from '@auth0/auth0-react';
import { FetchResult, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { UserInfo } from '@contexts/user';
import { useAuth } from '@src/ApolloWrapper';
import { uploadTraderLogo } from '@services/customerAPI';
import { GET_TRADERS_QUERY } from './queries';
import { Trader, UploadTraderLogoData } from './types';
import { TradersManager } from './manager';
import { CREATE_TRADER, DELETE_TRADER, UPDATE_TRADER } from './mutation';
import { Proposal } from '@contexts/commercial-group';
import { finalProposal } from '@contexts/energy-audit/parser';

export * from './types';

export type TraderContextType = {
  getTradersHandler: () => Promise<QueryResult<any, { traderId: string }>>;
  updateTraderHandler: (values: Trader) => Promise<{ id: string | null } | null>;
  deleteTraderHandler: (id: string) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  createTraderHandler: (values: Trader) => Promise<{ id: any } | null>;
  setTraderId: React.Dispatch<React.SetStateAction<string>>;
  traders: Trader[];
  traderUsers: UserInfo[];
  getTradersUser: (proposal: Proposal[]) => void;
  loading: boolean;
  uploadLogoTraderHandler(data: UploadTraderLogoData): Promise<boolean>;
};

export const TraderContext = createContext({} as TraderContextType);

interface Provider {
  children: React.ReactNode;
}

const TradersProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    auth0ApiService,
    authStatus: { accessToken },
  } = useAuth();
  const [traders, setTraders] = React.useState<Array<Trader>>([]);
  const [traderUsers, setTraderUsers] = React.useState<Array<UserInfo>>([]);
  const [traderId, setTraderId] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);

  const [getTraders, { called: calledGetTraders }] = useLazyQuery(GET_TRADERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new TradersManager(data);
      const parsedData = manager.parseTraders();
      setTraders(parsedData);
      setLoading(false);
    },
    onError: () => {
      setTraders([]);
    },
    variables: {
      traderId,
    },
  });

  const getTradersUser = (proposals: Proposal[]) => {
    const traderUsersId = finalProposal(proposals).bid.trader.usersIds;
    if (traderUsersId?.length > 0) {
      auth0ApiService?.getUsersByIdList(traderUsersId).then((response) => {
        const parsedUsersList: User[] = response.data;
        setTraderUsers(
          parsedUsersList.map(
            (user) =>
              ({
                id: user.app_metadata.uuid as string,
                authId: user.user_id,
                email: user.email,
                isAdmin: /.*@clarke.com(.br)?/.test(user.email as string),
                name: user.name,
                phone: user.phone_number,
                trader: null,
                pictureUrl: user.picture,
              } as UserInfo),
          ),
        );
      });
    }
  };

  const [createTrader] = useMutation(CREATE_TRADER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [updateTrader] = useMutation(UPDATE_TRADER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  const [deleteTrader] = useMutation(DELETE_TRADER, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false),
  });

  function getTradersHandler() {
    setLoading(true);
    return getTraders();
  }

  function createTraderHandler(values: Trader) {
    setLoading(true);
    return createTrader({
      variables: {
        input: {
          name: values?.name,
          description: values?.description,
          traderType: values?.traderType,
          assets: values?.assets,
          website: values?.website,
          companyFoundationYear: parseInt(values?.companyFoundationYear?.toString()),
          score: values?.score,
        },
      },
    })
      .then((response) => {
        return { id: response.data.createTrader.id };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function updateTraderHandler(values: Trader) {
    setLoading(true);
    return updateTrader({
      variables: {
        input: {
          id: values?.id,
          name: values?.name,
          description: values?.description,
          traderType: values?.traderType,
          assets: values?.assets,
          website: values?.website,
          companyFoundationYear: parseInt(values?.companyFoundationYear?.toString()),
          score: values?.score,
        },
      },
    })
      .then(() => {
        return { id: values.id };
      })
      .catch(() => {
        return { id: null };
      });
  }

  function deleteTraderHandler(id: string) {
    setLoading(true);
    return deleteTrader({
      variables: {
        input: {
          id,
        },
      },
    });
  }

  async function uploadLogoTraderHandler(data: UploadTraderLogoData) {
    setLoading(true);
    try {
      await uploadTraderLogo(data, accessToken);
      return true;
    } catch (error) {
      return false;
    }
  }

  if (!calledGetTraders) getTradersHandler();

  return (
    <TraderContext.Provider
      value={{
        setTraderId,
        getTradersHandler,
        deleteTraderHandler,
        updateTraderHandler,
        createTraderHandler,
        traders,
        traderUsers,
        getTradersUser,
        loading,
        uploadLogoTraderHandler,
      }}
    >
      {children}
    </TraderContext.Provider>
  );
};

export default TradersProvider;

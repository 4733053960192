import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, Layout } from '@clarke-energia/foton';

import { CONTRACTS_PATH, HOME_PATH } from '@routers/constants';

import { CreateCceeContractPayload, EMPTY_CCEE_CONTRACT } from '@contexts/ccee-contract/types';
import FormContainer from '@components/molecules/form/form-upsert-ccee-contract';
import { parsePayloadToCreation } from '@contexts/ccee-contract/parser';
import { useCceeContract } from '@hooks/use-cce-contract';

const CceeContractFormPage: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const {
    createCceeContractHandler,
    updateCceeContractHandler,
    getCceeContractByIdHandler,
    loading,
    loadingMutation,
    cceeContractToUpdate,
    getGroupsListHandler,
  } = useCceeContract();

  React.useEffect(() => {
    if (id) getCceeContractByIdHandler(id);
  }, []);

  React.useEffect(() => {
    getGroupsListHandler();
  }, []);

  const methods = useForm<CreateCceeContractPayload>({
    defaultValues: EMPTY_CCEE_CONTRACT,
    mode: 'all',
  });

  React.useEffect(() => {
    if (cceeContractToUpdate) methods.reset(cceeContractToUpdate);
  }, [cceeContractToUpdate]);

  const onSubmit: SubmitHandler<CreateCceeContractPayload> = async (values) => {
    const formatedPayload = parsePayloadToCreation(values);

    const response = id
      ? await updateCceeContractHandler(formatedPayload)
      : await createCceeContractHandler(formatedPayload);

    navigate(CONTRACTS_PATH, { replace: true, state: response.feedback });
  };
  const duration = methods.watch('duration');

  return (
    <Layout
      title={`${id ? 'Editar' : 'Criar'} contrato CCEE`}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos',
          url: CONTRACTS_PATH,
        },
        {
          label: `${id ? 'Editar' : 'Criar'} contrato CCEE`,
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full h-full">
          {loading ? (
            <Skeleton width="100%" className="mb-7 h-screen xl:mb-10" />
          ) : (
            <FormContainer durationType={duration} />
          )}
          <div className="flex gap-5 mb-5 w-fit">
            <Button
              loading={loadingMutation}
              label={`${id ? 'Atualizar' : 'Criar'} contrato`}
              type="submit"
              kind={'primary'}
            />
            <Link to={CONTRACTS_PATH}>
              <Button label="Cancelar" type="button" onClick={() => undefined} kind={'secondary'} />
            </Link>
          </div>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default CceeContractFormPage;

import { useContextSelector } from 'use-context-selector';
import { ProinfaContext, ProinfaContractContextType } from '@contexts/proinfa-contract';

export const useProinfa = (): ProinfaContractContextType => {
  const getProinfaListHandler = useContextSelector(ProinfaContext, (proinfa) => proinfa.getProinfaListHandler);
  const getProinfaYearOptionsListHandler = useContextSelector(
    ProinfaContext,
    (proinfa) => proinfa.getProinfaYearOptionsListHandler,
  );
  const proinfaList = useContextSelector(ProinfaContext, (proinfa) => proinfa.proinfaList);
  const loading = useContextSelector(ProinfaContext, (proinfa) => proinfa.loading);
  const processProinfaFileHandler = useContextSelector(ProinfaContext, (proinfa) => proinfa.processProinfaFileHandler);
  const updateConfirmationStatusHandler = useContextSelector(
    ProinfaContext,
    (proinfa) => proinfa.updateConfirmationStatusHandler,
  );
  const fileProcessingProgress = useContextSelector(ProinfaContext, (proinfa) => proinfa.fileProcessingProgress);
  const totalOfProinfas = useContextSelector(ProinfaContext, (proinfa) => proinfa.totalOfProinfas);
  const setFileProcessingProgress = useContextSelector(ProinfaContext, (proinfa) => proinfa.setFileProcessingProgress);
  const commercialGroups = useContextSelector(ProinfaContext, (proinfa) => proinfa.commercialGroups);
  const wasCreated = useContextSelector(ProinfaContext, (proinfa) => proinfa.wasCreated);

  return {
    getProinfaListHandler,
    getProinfaYearOptionsListHandler,
    proinfaList,
    loading,
    updateConfirmationStatusHandler,
    processProinfaFileHandler,
    fileProcessingProgress,
    totalOfProinfas,
    setFileProcessingProgress,
    commercialGroups,
    wasCreated,
  };
};

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Table, ColumnOptions, Pagination } from '@clarke-energia/foton';
import { PROINFA_LIST_ITEMS_BY_PAGE } from '@utils/constants/common';
import { ProinfaTableData } from '@contexts/proinfa-contract/types';
import { getMonthColumns } from './helper';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export type renderFunction = ColumnOptions<ProinfaTableData>['renderAsElement'];

export interface ProinfaTableProps {
  isFetchingContracts: boolean;
  data: ProinfaTableData[];
  totalItems?: number;
  itemsByPage?: number;
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

const proinfaTableColumns: ColumnOptions<ProinfaTableData>[] = [
  { accessor: 'unit', header: 'Unidade', renderAsElement: (entry) => entry.unit },
  ...getMonthColumns(),
];

const ProinfaTable: React.FC<ProinfaTableProps> = ({
  isFetchingContracts,
  data,
  totalItems = 0,
  itemsByPage = 0,
  currentPage = 0,
  setCurrentPage,
}) => {
  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-auto">
        {isFetchingContracts ? (
          <RowSkeleton />
        ) : (
          <>
            <Table data={data} tableColumns={proinfaTableColumns} className="border-top" />
            {setCurrentPage && totalItems > PROINFA_LIST_ITEMS_BY_PAGE && (
              <Pagination
                totalRows={totalItems}
                rowsByPage={itemsByPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProinfaTable;

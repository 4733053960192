import React from 'react';

import { ButtonIcon, OverflownMenu } from '@clarke-energia/foton';
import { OverflowMenu } from '@pages/contracts/helper';

interface OverflowMenuTableProps {
  show: boolean;
  buttonClick: () => void;
  menuClick: (idx: number) => void;
  itens: OverflowMenu[];
}

const OverflowMenuTable: React.FC<OverflowMenuTableProps> = ({ buttonClick, show, menuClick, itens }) => {
  return (
    <div className="relative">
      <ButtonIcon kind="ghost" icon="EllipsisVerticalIcon" onClick={buttonClick} shape="square" className="p-px" />
      {show ? (
        <div className="absolute right-0 top-6 z-10 h-10 w-[8.5rem]">
          {itens.map((item, idx) => (
            <OverflownMenu
              key={`icone-${idx}`}
              label={item.label}
              className={`w-full ${item.specificClassName}`}
              extraClassNames="h-5"
              icon={item.icon}
              onClick={() => {
                menuClick(idx);
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default OverflowMenuTable;

import React from 'react';
import { EnergyAuditFormated } from '@contexts/energy-audit';
import { formatAsMWh } from '@utils/text';

interface CustomerEmailTemplateProps {
  energyAudit: EnergyAuditFormated;
}

const CustomerEmailTemplate: React.FC<CustomerEmailTemplateProps> = ({ energyAudit }) => {
  const unitsNames = energyAudit.units.map((item) => item.unit.name);

  return (
    <div>
      {
        <main>
          <header>
            <h1 className="mb-5 font-bold text-heading-medium">{`Balanço Energético de ${energyAudit.group.name} – ${energyAudit.date}`}</h1>
            <section className="mb-5 text-paragraph-medium">
              <h2 className="mb-6">{`Olá, ${energyAudit.group?.name}`}</h2>
              <p>
                <span>
                  {'Abaixo é possível conferir o balanço energético das unidades '}
                  <strong>{unitsNames.join(', ')}</strong>
                  {' de '}
                  <strong>
                    {energyAudit.date}
                    {'. '}
                  </strong>
                </span>
                <span>
                  Esse é o momento do mês que conferimos o volume contratado de energia pela
                  <strong>{`${energyAudit?.energyContract?.trader?.name ?? ''}`}</strong>e avaliamos se houve exposição
                  ou sobra de energia.
                </span>
              </p>
            </section>
          </header>
          <section>
            {energyAudit.units.map((item) => (
              <div key={item.unit.id} className="pt-4 mb-5 border-black border-t-[1px]">
                <div className="font-bold text-paragraph-medium">
                  <span>{item.unit.name}</span>
                </div>
                <div className="flex justify-between text-paragraph-small">
                  <span>Consumo (A)</span>
                  <span>{item.consumptionAmount}</span>
                </div>
                <div className="flex justify-between text-paragraph-small">
                  <span>Perdas (B)</span>
                  <span>{item.lostsAmount}</span>
                </div>
                <div className="flex justify-between text-paragraph-small">
                  <span>PROINFA (C)</span>
                  <span>{item.proinfa}</span>
                </div>
                <div className="flex justify-between text-paragraph-small">
                  <span>Necessidade (A+B-C)</span>
                  <span>{item.neededAmount}</span>
                </div>
                <section>
                  <div className="flex justify-between text-paragraph-small">
                    <span>Contrato do mercado livre</span>
                    <span>{item.freeMarketContractsAmount}</span>
                  </div>
                  <div className="flex justify-between text-paragraph-small">
                    <span>Sobra / Exposição</span>
                    <span className={item.overplusOrExposureAmount.includes('-') ? 'text-red-600' : 'text-black'}>
                      {item.overplusOrExposureAmount}
                    </span>
                  </div>
                </section>
              </div>
            ))}
            <section className="pt-4 mb-5 border-black border-t-[1px]">
              <div className="flex justify-between font-bold">
                <span className="text-paragraph-large">Necessidade Total</span>
                <span className="text-heading-medium">
                  {formatAsMWh(energyAudit.neededTotal ? energyAudit.neededTotal : 0)}
                </span>
              </div>
            </section>
            <section className="pt-4 mb-5 border-black border-t-[1px]">
              <div className="flex justify-between font-bold">
                <span className="text-paragraph-large max-w-[10rem]">Total de contratos do Mercado Livre</span>
                <span className="text-heading-medium">
                  {formatAsMWh(energyAudit.freeMarketContractsTotal ? energyAudit.freeMarketContractsTotal : 0)}
                </span>
              </div>
            </section>
            <section className="pt-4 mb-5 border-black border-t-[1px]">
              <div className="flex justify-between font-bold">
                <span className="text-paragraph-large max-w-[10rem]">Sobra / Exposição Total</span>
                <span
                  className={`text-heading-medium ${
                    energyAudit.overplusOrExposureTotal
                      ? energyAudit.overplusOrExposureTotal < 0
                        ? 'text-danger-60'
                        : ''
                      : ''
                  }`}
                >
                  {formatAsMWh(energyAudit.overplusOrExposureTotal ? energyAudit.overplusOrExposureTotal : 0)}
                </span>
              </div>
            </section>
          </section>
        </main>
      }
    </div>
  );
};

export default CustomerEmailTemplate;

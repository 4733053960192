import { gql } from '@apollo/client';

export const GET_ALL_ENERGY_AUDITS_REPORTS_QUERY = gql`
  query GetAllEnergyAuditReports($input: GetEnergyAuditReportInput) {
    getAllEnergyAuditReports(input: $input) {
      page
      total
      limit
      data {
        traderProfileName
        cceeContractNumber
        submarket
        volumeMwm
        energyType
        group {
          name
        }
        unit {
          name
        }
        profile {
          name
        }
      }
    }
  }
`;

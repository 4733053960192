import { Button } from '@clarke-energia/foton';
import { IContractFormCustomerSignatorySection } from '@components/molecules/contracts/sections/contract-form-customers-section/contract-form-customer-section';
type IContractSignatoryControlSubsection = IContractFormCustomerSignatorySection;

export function ContractCustomerControlsSubsection({
  customerSignatoryNum,
  removeCustomerSignatory,
  appendCustomerSignatory,
  customerSignatoryLength = 1,
}: IContractSignatoryControlSubsection) {
  const isLastCustomerSignatory = customerSignatoryLength - 1 === customerSignatoryNum;
  const isOnlyOneCustomerSignatory = customerSignatoryLength === 1;

  return (
    <div className="relative mt-3 md:col-span-2">
      <div className="flex absolute inset-0 items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="flex relative gap-8 justify-center">
        {!isOnlyOneCustomerSignatory && removeCustomerSignatory && (
          <Button
            kind="chip"
            label="Remover procurador"
            size="small"
            type="button"
            onClick={() => removeCustomerSignatory(customerSignatoryNum)}
          />
        )}
        {isLastCustomerSignatory && (
          <Button
            kind="secondary"
            label="+ Adicionar procurador"
            size="small"
            type="button"
            onClick={appendCustomerSignatory}
          />
        )}
      </div>
    </div>
  );
}

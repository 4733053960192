import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralGadget } from '@clarke-energia/foton';

import { IKickOff } from '@contexts/kick-off/types';
import { COMMERCIAL_GROUP_PATH, UNITS_PATH } from '@routers/constants';

import {
  KickOffAvailableUnitsSummary,
  KickOffUnavailableUnitsSummary,
} from '@components/atoms/kick-off/units-summary-section';

import KickOffPanelMessagesIndexer from './helper';

interface IKickOffUnitsSummarySection {
  kickOff: IKickOff;
}

const KickOffUnitsSummarySection = ({ kickOff }: IKickOffUnitsSummarySection) => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const kickOffStatus = kickOff.status;
  const unitsSummarySectionMessageContent = KickOffPanelMessagesIndexer.UNITS_SUMMARY_SECTION[kickOffStatus];
  const isUnitsSummaryUnavailableByKickOffStatus =
    kickOffStatus === 'STARTED' || kickOffStatus === 'INFORMATION_REGISTRATION_PENDING';
  const isUnitsSummaryAvailableByKickOffStatus =
    kickOffStatus === 'INFORMATION_REGISTRATION_CONCLUDED' ||
    kickOffStatus === 'DIAGNOSTIC_QUIZ_RELEASED' ||
    kickOffStatus === 'DIAGNOSTIC_QUIZ_BEING_COMPLETED' ||
    kickOffStatus === 'DIAGNOSTIC_QUIZ_SENT';

  const unitsSummaryList = kickOff.unitsSummary;
  const unitsNumber = unitsSummaryList?.length;

  const navigateToUnitsList = () => {
    if (groupId) {
      const commercialGroupHubPath = COMMERCIAL_GROUP_PATH.replace(':groupId', groupId);
      navigate(`${commercialGroupHubPath}/${UNITS_PATH}`);
    }
  };

  return (
    <GeneralGadget
      title={`Informações das unidades ${unitsNumber ? `(${unitsNumber})` : ''}`}
      actionButtons={
        isUnitsSummaryAvailableByKickOffStatus
          ? [
              {
                buttonType: 'buttonIcon',
                icon: 'EyeIcon',
                shape: 'square',
                kind: 'ghost',
                onClick: () => navigateToUnitsList(),
              },
            ]
          : []
      }
      className="pb-6 h-fit"
    >
      {isUnitsSummaryUnavailableByKickOffStatus ? (
        <KickOffUnavailableUnitsSummary
          unitsSummarySectionMessageContent={unitsSummarySectionMessageContent}
          navigateToUnitsList={navigateToUnitsList}
        />
      ) : (
        isUnitsSummaryAvailableByKickOffStatus && <KickOffAvailableUnitsSummary unitsSummaryList={unitsSummaryList} />
      )}
    </GeneralGadget>
  );
};

export default KickOffUnitsSummarySection;

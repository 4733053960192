import ClarkeLogo from '@assets/clarke-logo.svg';
import ClarkeSpin from '@assets/spin.svg';

const FinancialEventSendFormUpdatingTargetUsers = () => {
  return (
    <div className="flex flex-col justify-center mt-4 -mb-4 w-full">
      <div className="flex relative justify-center content-center items-center h-12">
        <img className="absolute h-10 animate-spin" src={ClarkeSpin} alt="Clarke Spin" />
        <img className="absolute h-8" src={ClarkeLogo} alt="Clarke logo" />
      </div>
    </div>
  );
};

export default FinancialEventSendFormUpdatingTargetUsers;

import React from 'react';
import { Card } from '@clarke-energia/foton';
import { cardsInfo } from './unit-report/report-helpers-method';
import Skeleton from 'react-loading-skeleton';

export type highlightsCard = {
  currentSavings: string;
  savingsPercent: string;
  energyTotalCost: string;
  energyTotalConsumption: string;
  date: string;
};
interface CardsContainerProps extends highlightsCard {
  isFetchingData: boolean;
}

const CardSkeleton = () => (
  <div>
    <Skeleton height={150} />
  </div>
);

const HighLightCards: React.FC<CardsContainerProps> = ({
  currentSavings,
  energyTotalConsumption,
  energyTotalCost,
  savingsPercent,
  date,
  isFetchingData,
}) => {
  return (
    <div>
      {cardsInfo(currentSavings, savingsPercent, energyTotalCost, energyTotalConsumption).map(
        ({ altColor, icon, label, sublabel, altLabel }, idx) => {
          return (
            <div key={`${label}-${idx}`} className={idx === 1 ? 'mt-2 mb-4' : ' '}>
              {isFetchingData ? (
                <CardSkeleton />
              ) : (
                <Card
                  altColor={altColor}
                  altLabel={altLabel}
                  label={label}
                  sublabel={`${sublabel} - ${date}`}
                  icon={icon}
                />
              )}
            </div>
          );
        },
      )}
    </div>
  );
};

export default HighLightCards;

import React from 'react';
import { Checkbox, CheckboxProps, Table } from '@clarke-energia/foton';
import { mainTableColumns } from '@components/molecules/migration-progress/helper';
import { MigrationReport } from '@contexts/migration-report/types';

export interface SyncMigrationModalContentProps {
  selectedGroups: MigrationReport[];
  selectBoxes: Array<CheckboxProps>;
}

const SyncMigrationModalContent = ({ selectedGroups, selectBoxes }: SyncMigrationModalContentProps): JSX.Element => {
  return (
    <>
      <div className="overflow-y-auto max-h-36">
        <Table data={selectedGroups} tableColumns={mainTableColumns.slice(0, 2)} />
      </div>
      <div className="mt-8">
        {selectBoxes.map(({ label, checked, onChange }) => (
          <Checkbox key={label} label={label} checked={checked} onChange={onChange} />
        ))}
      </div>
    </>
  );
};

export default SyncMigrationModalContent;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import clarkeLogo from '@assets/new-clarke-logo.svg';

import { string2HTMLParser } from '@utils/text';

interface HeaderLogoClarkeProps {
  text: string;
  isFecthingData: boolean;
  mobileText: string;
}

const HeaderSkeleton = () => (
  <>
    <div className="hidden lg:block">
      <Skeleton width="100%" />
    </div>
    <div className="block mt-4 lg:hidden">
      <Skeleton width="100%" height={100} />
    </div>
  </>
);

const HeaderLogoClarke: React.FC<HeaderLogoClarkeProps> = ({ text, isFecthingData, mobileText }) => {
  return (
    <div className="col-span-3 xl:flex xl:gap-56">
      <div className="mb-5 xl:mb-0">
        <img src={clarkeLogo} alt="Logo da Clarke" className="w-36 xl:w-48" />
      </div>
      {isFecthingData ? (
        <HeaderSkeleton />
      ) : (
        <div className="">
          <p className="hidden font-black lg:block text-heading-2xlarge">{string2HTMLParser(text)}</p>
          <p className="block mt-4 lg:hidden text-heading-medium margin-top">{string2HTMLParser(mobileText)}</p>
        </div>
      )}
    </div>
  );
};

export default HeaderLogoClarke;

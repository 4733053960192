import { Moment } from 'moment';
import { FetchResult, OperationVariables, QueryResult } from '@apollo/client';
import { PopUpListProps } from '@clarke-energia/foton';
import { Proposal } from '@contexts/commercial-group';
import { EnergyTypeEnum } from '@contexts/unit';

export type EnergyAuditsContextType = {
  getAllEnergyAuditsHandler: (params: EnergyAuditFilters) => Promise<QueryResult<AllEnergyAudits, EnergyAuditFilters>>;
  getEnergyAuditByIdHandler: () => Promise<
    QueryResult<
      EnergyAudit,
      {
        energyAuditId: string | undefined;
      }
    >
  >;
  getEmailDataHandler: (energyAuditId: string) => Promise<QueryResult<AllEnergyAudits, OperationVariables>>;
  lastSync: Moment | undefined;
  setEnergyAuditId: React.Dispatch<React.SetStateAction<string | undefined>>;
  energyAudits: AllEnergyAudits['getAllEnergyAudits'];
  energyAuditById: EnergyAuditFormated | undefined;
  openNotificationFeedBack: boolean;
  setOpenNotificationFeedBack: (value: boolean) => void;
  openNotificationFeedBackSync: boolean;
  setOpenNotificationFeedBackSync: (value: boolean) => void;
  loading: boolean;
  prepareEnergyAuditToBeSentHandler: (payload: PrepareEnergyAuditToBeSent) => false | Promise<FetchResult<any>>;
  openPeriodSelector: boolean;
  setOpenPeriodSelector: React.Dispatch<React.SetStateAction<boolean>>;
  energyAuditsPeriods: PopUpListProps['items'] | undefined;
  setEnergyAuditsPeriods: React.Dispatch<React.SetStateAction<PopUpListProps['items'] | undefined>>;
  selectedEnergyAudit: EnergyAuditFormated | null;
  setSelectedEnergyAudit: React.Dispatch<React.SetStateAction<EnergyAuditFormated | null>>;
  rawDate: string;
  getEnergyAuditByEnergyContractIdStatusAndDateHandler: (
    filters: DetailEnergyAuditFilters,
  ) => Promise<QueryResult<any, OperationVariables>>;
  groupPeriods: Record<string, string>[];
  loadingEmailData: boolean;
};

export type IEnergyAuditSendingStatus =
  | 'UPDATING'
  | 'UPDATED_FAILED'
  | 'UPDATED_SUCCESS'
  | 'EXCEL_DONWLOAD_SUCCESS'
  | 'EXCEL_DONWLOAD_ERROR';

export type NotifyByEmailStatusType = 'IN_PROGRESS' | 'DONE' | 'NOT_STARTED';

export interface AllEnergyAuditsGraphQLData {
  getAllEnergyAudits: {
    page: number;
    total: number;
    limit: number;
    data: Array<EnergyAudit>;
  };
}

export interface AllEnergyAudits {
  getAllEnergyAudits: {
    page: number;
    total: number;
    limit: number;
    data: Array<EnergyAudits>;
  };
}

export interface EnergyAuditByIdGraphQLData {
  energyAudit: EnergyAudit;
}
export interface EnergyAuditByEnergyContractIdStatusAndDateGraphQLData {
  energyAuditByEnergyContractStatusAndDate: EnergyAudit;
}

export interface EnergyAudit {
  id?: string;
  consumptionTotal?: number;
  freeMarketContractsTotal?: number;
  neededTotal?: number;
  overplusOrExposureTotal?: number;
  numberOfConcludedMeasurements?: number;
  totalNumberOfMeasurements?: number;
  seasonality?: number;
  billedFlex?: number;
  upperFlexTotal?: number;
  lowerFlexTotal?: number;
  energyContract: {
    id: string;
    energyType?: keyof typeof EnergyTypeEnum;
    idCceeTrader?: string;
    perfilCceeTrader?: string;
    upperFlexibility?: number;
    lowerFlexibility?: number;
    trader: {
      id: string;
      name: string;
      users: Array<string>;
    };
  };
  units: Array<EnergyAuditUnits>;
  group: EnergyAuditCommercialGroup;
  date: string;
  lostsTotal?: number;
  notifiedAt?: string;
  status: EnergyAuditStatus;
}

export interface EnergyAudits {
  id: string;
  consumptionTotal: number;
  neededTotal: number;
  overplusOrExposureTotal: number;
  numberOfConcludedMeasurements: number;
  totalNumberOfMeasurements: number;
  energyContract: {
    id: string;
    trader?: {
      id: string;
    };
  };
  group: EnergyAuditCommercialGroup;
  notifiedAt?: string;
  status: EnergyAuditStatus;
}

export enum EnergyAuditStatus {
  NOT_READY_TO_SEND = 'NOT_READY_TO_SEND',
  READY_TO_SEND = 'READY_TO_SEND',
  SENT = 'SENT',
}

export interface EnergyAuditFilters {
  group?: EnergyAuditCommercialGroup;
  date?: string;
  status?: EnergyAuditStatus;
}

export interface EnergyAuditCommercialGroup {
  id: string;
  name: string;

  proposals?: Proposal[];
}

export interface EnergyAuditUnits {
  consumptionAmount: number;
  freeMarketContractsAmount: number;
  lostsAmount: number;
  neededAmount: number;
  overplusOrExposureAmount: number;
  proinfa: number;
  missingHours?: number;
  unit: {
    id: string;
    name: string;
    cceeProfile: {
      name: string;
      idCcee: string;
    };
  };
}

export interface EnergyContract {
  rawId: string;
  id: string;
  energyType: EnergyTypeEnum | null;
  idCceeTrader?: string;
  perfilCceeTrader?: string;
  lowerFlexibility: string;
  upperFlexibility: string;
  upperFlexTotal: string;
  lowerFlexTotal: string;
  seasonality?: string;
  billedFlex?: string;
  trader: EnergyAuditTrader;
}

export interface EnergyAuditTrader {
  id?: string;
  name?: string;
  profileCcee?: string;
  idCcee?: string;
}

export enum EmailsTabTypes {
  TRADER = 'TRADER',
  CLIENT = 'CLIENT',
  RECIPIENTS = 'RECIPIENTS',
}

export interface IEnergyAuditStatusMapper {
  id: string;
  label: string;
  statusType: EnergyAuditStatus;
}
export interface EnergyAuditFormated {
  id?: string;
  consumptionTotal?: number;
  numberOfConcludedMeasurements?: number;
  totalNumberOfMeasurements?: number;
  neededTotal?: number;
  freeMarketContractsTotal?: number;
  overplusOrExposureTotal?: number;
  seasonality?: number;
  energyContract: EnergyContract;
  units: Array<EnergyAuditUnitsFormated>;
  group: EnergyAuditCommercialGroup;
  date: string;
  lostsTotal?: number;
  notifiedAt?: string;
  status: EnergyAuditStatus;
}

export interface EnergyAuditUnitsFormated {
  consumptionAmount: string;
  freeMarketContractsAmount: string;
  lostsAmount: string;
  neededAmount: string;
  overplusOrExposureAmount: string;
  proinfa: string;
  missingHours: number;
  unit: {
    id: string;
    name: string;
    cceeProfile: {
      name: string;
      idCcee: string;
    };
  };
}

export interface PrepareEnergyAuditToBeSent {
  auditId: string;
  groupEmails: Array<string>;
  trader: {
    name: string;
    emails: Array<string>;
  };
}

export interface DetailEnergyAuditFilters {
  energyContractId: string;
  status: EnergyAuditStatus;
  date: string;
}

import { ReportsDataTable } from '@contexts/economy-reports/types';

export type ModalType = 'CREATE_TASK' | 'SYNC_AND_NOTIFY';

const monthsOrder = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
const sortedReportsByDate = (reports: ReportsDataTable[]) => {
  return reports.sort((a, b) => {
    const [aMonth, aYear] = a.date.split('  ');
    const [bMonth, bYear] = b.date.split('  ');

    const aMonthIndex = monthsOrder.indexOf(aMonth);
    const bMonthIndex = monthsOrder.indexOf(bMonth);

    if (aYear !== bYear) {
      return parseInt(bYear) - parseInt(aYear);
    }

    return bMonthIndex - aMonthIndex;
  });
};

export default sortedReportsByDate;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import AccordionItem from '@components/atoms/commercial-groups/accordion-item';
import { Table, ColumnOptions } from '@clarke-energia/foton';
import { UserInfo } from '@contexts/user/types';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface TraderUsersTableProps {
  isFetchingItems: boolean;
  data: UserInfo[];
  columnDefinitions: ColumnOptions<UserInfo>[];
}

export const TraderUsersTable: React.FC<TraderUsersTableProps> = ({ isFetchingItems, data, columnDefinitions }) => {
  return (
    <div className="flex flex-col space-y-10">
      <AccordionItem label="Usuários" labelSubscript={`${data.length}`} testId="data-accordion">
        <div className="overflow-x-auto">
          {isFetchingItems ? <RowSkeleton /> : <Table data={data} tableColumns={columnDefinitions} />}
        </div>
      </AccordionItem>
    </div>
  );
};

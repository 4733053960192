import React from 'react';

import { NumericFormField, SelectFormField } from '@components/atoms/form';
import FormSectionHeader from '@components/molecules/general/section-form';
import { ComboBoxFormField } from '@components/atoms/form/combobox';
import { EconomyReportPayload, GroupEconomyReportPayload, UnitFormData } from '@contexts/economy-reports/types';
import { useFormContext } from 'react-hook-form';
import CreateGroupEconomyReport from '../form-group';

interface CustomerBlockProps {
  groups: { id: string; name: string }[];
  units: UnitFormData[];
  dataToUpdate: EconomyReportPayload | GroupEconomyReportPayload | null;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerInputs: React.FC<CustomerBlockProps> = ({ groups, units, dataToUpdate, setShowForm }) => {
  const { watch } = useFormContext();
  const groupIdValue = watch('groupId');
  const dateValue = watch('date');

  return (
    <div className="grid grid-cols-3 gap-6 xl:mt-0">
      <FormSectionHeader
        title="Informações do Cliente"
        text="Qual é o cliente e unidade que você deseja emitir o relatório?"
      />
      <div className="grid grid-cols-3 col-span-3 gap-6 lg:col-span-2">
        <div className="col-span-3 lg:col-span-1">
          <NumericFormField<EconomyReportPayload | GroupEconomyReportPayload>
            id="date"
            field="date"
            label="Mês / Ano do relatório"
            formatProps={{
              placeholder: 'mm/aaaa',
              format: '##/####',
              mask: '_',
              allowNegative: false,
              fixedDecimalScale: false,
              decimalSeparator: undefined,
              thousandSeparator: undefined,
              decimalScale: 0,
              isNumericString: true,
            }}
            options={{ required: { value: true, message: 'Data é obrigatório' } }}
          />
        </div>
        <div className="col-span-3 lg:col-span-1">
          <ComboBoxFormField<EconomyReportPayload | GroupEconomyReportPayload>
            id="groupId"
            label="Grupo comercial"
            field="groupId"
            placeholder="Grupo comercial"
            inputOptions={
              groups?.length > 0
                ? groups.map(({ name, id }) => ({
                    value: id,
                    label: name,
                  }))
                : []
            }
            options={{ required: { value: true, message: 'Grupo comercial é obrigatório' } }}
          />
        </div>
        <div className="col-span-3 lg:col-span-1">
          <SelectFormField<EconomyReportPayload>
            id="unitId"
            label="Unidade consumidora"
            field="unitId"
            inputOptions={
              units?.length > 0
                ? units.map(({ id, name }) => ({
                    value: id,
                    optionLabel: name,
                  }))
                : []
            }
            options={{ required: { value: true, message: 'Unidade é obrigatório' } }}
          />
        </div>
        <div className="col-span-3 lg:col-span-2">
          <CreateGroupEconomyReport
            disabledBtn={!dateValue}
            groupId={groupIdValue}
            date={dateValue}
            dataToUpdate={dataToUpdate}
            setShowForm={setShowForm}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerInputs;

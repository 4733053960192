import React, { useState } from 'react';
import { GeneralGadget } from '@clarke-energia/foton';

import { IKickOff } from '@contexts/kick-off/types';

import {
  KickOffAnsweredQuiz,
  KickOffNotAnsweredQuiz,
  KickOffQuizSectionMoreOptionsPopUp,
} from '@components/atoms/kick-off/quiz-section';

interface IKickOffQuizSection {
  kickOff: IKickOff;
  setOpenSendQuizEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const KickOffQuizSection = ({ kickOff, setOpenSendQuizEmailModal }: IKickOffQuizSection) => {
  const [openMoreOptionsPopUp, setOpenMoreOptionsPopUp] = useState<boolean>(false);

  const kickOffStatus = kickOff.status;
  const isQuizUnavailableByKickOffStatus =
    kickOffStatus === 'STARTED' || kickOffStatus === 'INFORMATION_REGISTRATION_PENDING';
  const isQuizAvailableByKickOffStatus = kickOffStatus === 'INFORMATION_REGISTRATION_CONCLUDED';
  const isQuizReleasedByKickOffStatus = kickOffStatus === 'DIAGNOSTIC_QUIZ_RELEASED';
  const isQuizAnsweredByKickOffStatus = kickOffStatus === 'DIAGNOSTIC_QUIZ_SENT';
  const isQuizBeingAnsweredByKickOffStatus = kickOffStatus === 'DIAGNOSTIC_QUIZ_BEING_COMPLETED';

  const copyQuizLinkToClipboard = () => {
    kickOff.quiz?.link && navigator.clipboard.writeText(kickOff.quiz?.link);
  };

  return (
    <GeneralGadget
      title="Questionário"
      actionButtons={
        !isQuizUnavailableByKickOffStatus
          ? [
              {
                buttonType: 'buttonIcon',
                icon: 'EllipsisVerticalIcon',
                shape: 'square',
                kind: 'ghost',
                onClick: () => setOpenMoreOptionsPopUp((previous) => !previous),
              },
            ]
          : []
      }
      className="relative pb-6 h-fit"
    >
      {!isQuizAnsweredByKickOffStatus ? (
        <KickOffNotAnsweredQuiz
          quizEmailSentAt={kickOff.quiz?.emailSentAt}
          {...{
            kickOffStatus,
            isQuizAvailableByKickOffStatus,
            isQuizBeingAnsweredByKickOffStatus,
            isQuizReleasedByKickOffStatus,
            isQuizUnavailableByKickOffStatus,
            setOpenSendQuizEmailModal,
            copyQuizLinkToClipboard,
          }}
        />
      ) : (
        isQuizAnsweredByKickOffStatus && <KickOffAnsweredQuiz quizInputs={kickOff.quiz?.inputs} />
      )}
      {openMoreOptionsPopUp && (
        <KickOffQuizSectionMoreOptionsPopUp
          quizStatus={kickOff.quiz?.status}
          quizLink={kickOff.quiz?.link}
          {...{ setOpenMoreOptionsPopUp, copyQuizLinkToClipboard }}
        />
      )}
    </GeneralGadget>
  );
};

export default KickOffQuizSection;

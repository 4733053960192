import { EMPTY_UNIT, UnitPayload, UnitsListGraphQLData, MeasuringPointGraphQLData, IMeasuringPoint } from './types';

export class UnitsManager {
  rawData: UnitsListGraphQLData;
  units: UnitPayload[];

  constructor(rawData: UnitsListGraphQLData) {
    const parsedUnits: UnitPayload[] = rawData.group.units.map((unit): UnitPayload => {
      const parsedHistory: UnitPayload['history'] = { ...EMPTY_UNIT.history };
      (Object.keys(parsedHistory) as (keyof UnitPayload['history'])[]).forEach((key, index) => {
        const historyEntry = unit.history
          ? unit.history.find((history) => history.yearMonth && new Date(history.yearMonth).getMonth() === index)
          : undefined;
        parsedHistory[key] = {
          id: historyEntry?.id ?? '',
          peak: historyEntry?.consumptionPeak ?? 0,
          offPeak: historyEntry?.consumptionOffPeak ?? 0,
          generator: historyEntry?.generator ?? 0,
          yearMonth: historyEntry?.yearMonth ?? '',
        };
      });

      return {
        ...unit,
        edc: Number(unit.edc.id),
        history: parsedHistory,
      };
    });

    this.rawData = rawData;
    this.units = parsedUnits;
  }
}

export class MeasuringPointManager {
  rawData: MeasuringPointGraphQLData;
  getMeasuringPointByGroupId: IMeasuringPoint[];
  units: UnitPayload[];

  constructor(rawData: MeasuringPointGraphQLData, rawUnits: UnitPayload[]) {
    rawUnits.forEach((unit) => {
      rawData.getMeasuringPointByGroupId.forEach((measuringPoint) => {
        if (unit.id === measuringPoint.unitId) {
          unit.measuringPoint = measuringPoint.measuringPoint;
        }
      });
    });

    this.rawData = rawData;
    this.getMeasuringPointByGroupId = rawData.getMeasuringPointByGroupId;
    this.units = rawUnits;
  }
}

const configuration = {
  GATEWAY_API_HOST: import.meta.env.VITE_GATEWAY_API_HOST,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  FILES_API_HOST: import.meta.env.VITE_FILES_API_HOST,
  CUSTOMER_API_HOST: import.meta.env.VITE_CUSTOMER_API_HOST,
  SALES_API_HOST: import.meta.env.VITE_SALES_API_HOST,
  AMPLITUDE_INITIALIZED: false,
  SENTRY_DSN: '',
  DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  CCEE_API_HOST: import.meta.env.VITE_CCEE_API_HOST,
  REPORT_API_HOST: import.meta.env.VITE_REPORT_API_HOST,
  IS_IN_PRODUCTION: import.meta.env.VITE_IS_IN_PRODUCTION,
};

if (!configuration.GATEWAY_API_HOST) {
  throw `Invalid GATEWAY_API_HOST! value found: ${configuration.GATEWAY_API_HOST}`;
} else if (!configuration.DOMAIN) {
  throw `Invalid DOMAIN! value found: ${configuration.DOMAIN}`;
} else if (!configuration.CLIENT_ID) {
  throw `Invalid CLIENT_ID! value found: ${configuration.CLIENT_ID}`;
}

export default configuration;

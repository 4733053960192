import { z } from 'zod';
import { historySchema, parsedConsumptionHistoryEntrySchema, unitPayloadSchema } from './schema';

type UnitPayloadSchema = z.infer<typeof unitPayloadSchema>;
type ConsumptionHistory = z.infer<typeof historySchema>;
type UnitMonthHistory = z.infer<typeof parsedConsumptionHistoryEntrySchema>;

export const addIssue = (ctx: z.RefinementCtx, path: Array<string | number>, message: string) => {
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path,
    message,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateRequiredField = (value: any, path: Array<string | number>, message: string, ctx: z.RefinementCtx) => {
  if (!value) {
    addIssue(ctx, path, message);
  }
};

const validateHistory = (history: ConsumptionHistory, powerGenerator: boolean, ctx: z.RefinementCtx) => {
  Object.keys(history).forEach((key) => {
    const month = history[key as keyof typeof history] as UnitMonthHistory;
    const { generator, peak, offPeak } = month;

    const requiredMonthFields = [
      { value: peak, path: ['history', key, 'peak'], message: 'Campo obrigatório.' },
      { value: offPeak, path: ['history', key, 'offPeak'], message: 'Campo obrigatório.' },
    ];

    requiredMonthFields.forEach(({ value, path, message }) => {
      validateRequiredField(value, path, message, ctx);
    });

    if (powerGenerator) {
      const requiredGeneratorField = {
        value: generator,
        path: ['history', key, 'generator'],
        message: 'Campo obrigatório.',
      };
      validateRequiredField(generator, requiredGeneratorField.path, requiredGeneratorField.message, ctx);
    }
  });
};

export function validateUnitPayload(unit: UnitPayloadSchema, ctx: z.RefinementCtx) {
  const {
    name,
    legalName,
    docType,
    docNumber,
    unitType,
    submarket,
    edc,
    tariffSubgroup,
    tariffModality,
    contractedDemandOffPeak,
    averageInvoiceAmount,
    powerGenerator,
    history,
  } = unit;

  const requiredFields = [
    { value: name, path: ['name'], message: 'Nome é obrigatório' },
    { value: legalName, path: ['legalName'], message: 'Razão Social é obrigatório' },
    { value: docType, path: ['docType'], message: 'Selecione um tipo de documento' },
    { value: docNumber, path: ['docNumber'], message: 'Número de documento é obrigatório' },
    { value: unitType, path: ['unitType'], message: 'Classificação é obrigatório' },
    { value: submarket, path: ['submarket'], message: 'Submercado é obrigatório' },
    { value: edc, path: ['edc'], message: 'Distribuidora é obrigatório' },
    { value: tariffSubgroup, path: ['tariffSubgroup'], message: 'Subgrupo tarifário é obrigatório' },
    { value: tariffModality, path: ['tariffModality'], message: 'Modalidade tarifária é obrigatório' },
    { value: contractedDemandOffPeak, path: ['contractedDemandOffPeak'], message: 'Demanda contratada FP obrigatório' },
    { value: averageInvoiceAmount, path: ['averageInvoiceAmount'], message: 'Valor mensal da fátura é obrigatório' },
  ];

  requiredFields.forEach(({ value, path, message }) => {
    validateRequiredField(value, path, message, ctx);
  });

  if (tariffModality === 'BLUE') {
    validateRequiredField(
      unit.contractedDemandPeak,
      ['contractedDemandPeak'],
      'Demanda contratada P é obrigatória',
      ctx,
    );
  }

  validateHistory(history, powerGenerator, ctx);
}

import { DownloadExcelPayload } from '@contexts/energy-audit-report/types';

const downloadExcelParser = ({ referenceDate }: DownloadExcelPayload): FormData => {
  const formData = new FormData();
  formData.append('reference_date', referenceDate);

  return formData;
};

export default downloadExcelParser;

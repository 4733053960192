import { gql } from '@apollo/client';

export const CREATE_TRADER = gql`
  mutation CreateTrader($input: CreateTraderInput) {
    createTrader(input: $input) {
      id
    }
  }
`;

export const UPDATE_TRADER = gql`
  mutation UpdateTrader($input: UpdateTraderInput) {
    updateTrader(input: $input)
  }
`;
export const DELETE_TRADER = gql`
  mutation DeleteTrader($input: DeleteTraderInput) {
    deleteTrader(input: $input)
  }
`;

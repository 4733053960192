import { useContextSelector } from 'use-context-selector';
import { TraderContext, TraderContextType } from '@contexts/trader';

const useTraders = (): TraderContextType => {
  const loading = useContextSelector(TraderContext, (traders) => traders.loading);
  const traders = useContextSelector(TraderContext, (traders) => traders.traders);
  const setTraderId = useContextSelector(TraderContext, (traders) => traders.setTraderId);
  const getTradersHandler = useContextSelector(TraderContext, (traders) => traders.getTradersHandler);
  const updateTraderHandler = useContextSelector(TraderContext, (trader) => trader.updateTraderHandler);
  const deleteTraderHandler = useContextSelector(TraderContext, (trader) => trader.deleteTraderHandler);
  const createTraderHandler = useContextSelector(TraderContext, (trader) => trader.createTraderHandler);
  const traderUsers = useContextSelector(TraderContext, (trader) => trader.traderUsers);
  const getTradersUser = useContextSelector(TraderContext, (trader) => trader.getTradersUser);
  const uploadLogoTraderHandler = useContextSelector(TraderContext, (trader) => trader.uploadLogoTraderHandler);

  return {
    setTraderId,
    getTradersHandler,
    updateTraderHandler,
    deleteTraderHandler,
    createTraderHandler,
    traderUsers,
    getTradersUser,
    traders,
    loading,
    uploadLogoTraderHandler,
  };
};

export default useTraders;

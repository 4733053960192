import { upperFirstLetter } from '@utils/text';
import React from 'react';

interface FolderNavigationTreeProps {
  index: number;
  localFilePath: string;
  title: string;
  setFilePath: (value: React.SetStateAction<string>) => void;
}

const FolderNavigationTree: React.FC<FolderNavigationTreeProps> = ({ index, localFilePath, title, setFilePath }) => {
  function handleNavigationTreeClick(arr: any[], index: number) {
    const removeFrom = index + 1;
    const itensToBeRemoved = arr.length - removeFrom;
    arr.splice(removeFrom, itensToBeRemoved);
    arr.push('');
    const newFilePath = arr.join('/');
    setFilePath(newFilePath);
  }
  return (
    <>
      {index <= localFilePath.split('/').length - 2 && (
        <button key={index} onClick={() => handleNavigationTreeClick(localFilePath.split('/'), index)}>
          {index === localFilePath.split('/').length - 2 ? (
            <strong>{upperFirstLetter(title)}</strong>
          ) : (
            upperFirstLetter(title)
          )}
        </button>
      )}
      {index < localFilePath.split('/').length - 2 && <span className="mr-2 ml-2">{'>'}</span>}
    </>
  );
};

export default FolderNavigationTree;

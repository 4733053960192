import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Button, FeedbackNotification } from '@clarke-energia/foton';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import useManagementContract, { IFeedbackData } from '@hooks/use-management-contract';
import validateContractFormFields from './validate-contract-form-fields';

interface IContractFooter {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ContractFormFooter({ setIsModalOpen }: IContractFooter) {
  const navigate = useNavigate();

  const [feedbackData, setFeedbackData] = React.useState<IFeedbackData | undefined>();
  const { handleUpdateManagementContract, isLoading } = useManagementContract();

  const { watch, setError } = useFormContext<IManagementContractPayload>();
  const formValues = watch();

  const forwardToUpdateContract = (
    setFeedbackData: React.Dispatch<React.SetStateAction<any>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const validationResponse = validateContractFormFields(formValues, setError);
    if (!validationResponse) {
      setFeedbackData({
        kind: 'error',
        label: 'Erro',
        message: 'Formulário incompleto. Favor preencher os campos obrigatórios.',
      });
    } else {
      handleUpdateManagementContract(formValues, setFeedbackData, setIsModalOpen);
    }
  };

  React.useEffect(() => {
    isLoading && setFeedbackData({ kind: 'neutral', label: 'Aguarde', message: 'Estamos processando a solicitação.' });
  }, [isLoading]);

  return (
    <div className="relative">
      <div className="absolute right-0 bottom-0">
        {feedbackData && feedbackData.message && feedbackData.kind && (
          <FeedbackNotification
            kind={feedbackData.kind}
            label={feedbackData.label}
            message={feedbackData.message}
            onCloseNotification={() => setFeedbackData(undefined)}
          />
        )}
      </div>
      <div className="flex flex-row gap-4 mt-20">
        <Button
          kind="primary"
          type="submit"
          label="Editar contrato"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => forwardToUpdateContract(setFeedbackData, setIsModalOpen)}
        />

        <Button kind="secondary" type="submit" label="Cancelar" onClick={() => navigate(-1)} />
      </div>
    </div>
  );
}

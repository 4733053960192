import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import {
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  FieldName,
  FieldPath,
  RegisterOptions,
  useFormContext,
  FieldValues,
} from 'react-hook-form';

import FileInputField, { FileInputFieldProps } from './file-input-field';
import { FieldHeaderProps } from './field-header';

interface FileFormFieldProps<T extends FieldValues> {
  name: FieldPath<T>;
  label: FieldHeaderProps['label'];
  sublabel?: FieldHeaderProps['sublabel'];
  fieldDisplayOptions: FileInputFieldProps['innerDisplayProps'];
  id: string;
  fileSizeLimitInBytes: number;
  options?: RegisterOptions<T, FieldPath<T>>;
  multiple?: FileInputFieldProps['multiple'];
  className?: string;
}

export function FileFormField<T extends FieldValues>({
  name,
  label,
  sublabel,
  fileSizeLimitInBytes,
  id,
  options,
  fieldDisplayOptions,
  multiple,
  className = 'space-y-1',
}: FileFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <div className={className}>
      <Controller
        control={control}
        name={name}
        rules={{
          ...options,
          validate: (files) =>
            (files as File[]).every((file) => file.size <= fileSizeLimitInBytes) ||
            `Cada arquivo deve possuir menos que ${fileSizeLimitInBytes / 1024} MB`,
        }}
        render={({ field }) => (
          <FileInputField
            id={id}
            name={field.name}
            fileSizeLimit={fileSizeLimitInBytes}
            label={label}
            sublabel={sublabel}
            innerDisplayProps={fieldDisplayOptions}
            initialFiles={field.value as File[]}
            onChange={field.onChange}
            inputRef={field.ref}
            multiple={multiple}
            required={Boolean(options?.required)}
          />
        )}
      />
      <ErrorMessage
        name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
      />
    </div>
  );
}

import { State } from '@hooks/edcs';
import { TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';
import { StringLiteralOrEmpty } from '@utils/types';

export type Subgroup = 'A2' | 'A3' | 'A4' | 'AS';

export const UNIT_DOC_TYPE_CNPJ = 2;
export const UNIT_DOC_TYPE_CPF = 1;

type EnergyType = 'CONVENTIONAL' | 'INCENTIVIZED_0' | 'INCENTIVIZED_50' | 'INCENTIVIZED_80' | 'INCENTIVIZED_100';
export enum EnergyTypeEnum {
  CONVENTIONAL = 'Convencional',
  INCENTIVIZED_0 = 'Incentivada 0%',
  INCENTIVIZED_50 = 'Incentivada 50%',
  INCENTIVIZED_80 = 'Incentivada 80%',
  INCENTIVIZED_100 = 'Incentivada 100%',
}

export enum UnitDocTypeEnum {
  CNPJ = 'CNPJ',
  CPF = 'CPF',
}
type Submarket = 'NORTH' | 'NORTHEAST' | 'SOUTH' | 'SOUTHEAST';
export interface ParsedConsumptionHistoryEntry {
  id: string;
  generator?: number;
  peak?: number;
  offPeak?: number;
  yearMonth?: string;
}
interface RawConsumptionHistoryEntry {
  id: string;
  generator: number;
  consumptionPeak: number;
  consumptionOffPeak: number;
  demandPeak: number;
  demandOffPeak: number;
  yearMonth?: string;
}

export interface Unit {
  id: string;
  edc: { id: number; name: string; state: string };
  name: string;
  legalName: string;
  unitType: keyof typeof UnitTypeEnum;
  cceeProfile?: { id: string | undefined };
  tariffSubgroup: Subgroup | undefined;
  tariffModality: keyof typeof TariffModeEnum | undefined;
  contractedDemandOffPeak: number;
  contractedDemandPeak: number;
  averageInvoiceAmount: number;
  companyName?: string | undefined;
  docType: typeof UNIT_DOC_TYPE_CNPJ | typeof UNIT_DOC_TYPE_CPF;
  docNumber: string;
  energyType: StringLiteralOrEmpty<EnergyType> | undefined;
  submarket: StringLiteralOrEmpty<Submarket> | undefined;
  monthlyInvoice?: number | undefined;
  migrationDate: string | undefined;
  expectedMigrationDate: string | undefined;
  powerGenerator: boolean | undefined;
  monthlyGeneratorValue?: number | undefined;
  history: Array<RawConsumptionHistoryEntry>;
  files?: File[] | undefined;
  assetCode?: string | undefined;
}

export interface UnitPayload {
  id: string;
  edc?: number;
  name: string;
  legalName: string;
  group?: { id: string | undefined };
  cceeProfile?: { id: string | undefined; idCcee?: number | undefined };
  unitType?: keyof typeof UnitTypeEnum;
  tariffSubgroup: Subgroup | undefined;
  tariffModality: keyof typeof TariffModeEnum | undefined;
  contractedDemandOffPeak?: number;
  contractedDemandPeak?: number;
  contractedDemandOffPeakBeforeMigration?: number;
  contractedDemandPeakBeforeMigration?: number;
  averageInvoiceAmount?: number;
  companyName?: string | undefined;
  docType: keyof typeof UnitDocTypeEnum | 2 | 1;
  docNumber?: string;
  energyType?: StringLiteralOrEmpty<EnergyType> | undefined;
  state?: StringLiteralOrEmpty<State> | undefined;
  submarket: StringLiteralOrEmpty<Submarket> | undefined;
  monthlyInvoice?: number | undefined;
  migrationDate?: string | undefined;
  expectedMigrationDate?: string | undefined;
  powerGenerator: boolean | undefined;
  monthlyGeneratorValue?: number | undefined;
  assetCode?: string | undefined;
  measuringPoint?: string | undefined;
  totalConsumptionVolume?: number;
  history: {
    january: ParsedConsumptionHistoryEntry;
    february: ParsedConsumptionHistoryEntry;
    march: ParsedConsumptionHistoryEntry;
    april: ParsedConsumptionHistoryEntry;
    may: ParsedConsumptionHistoryEntry;
    june: ParsedConsumptionHistoryEntry;
    july: ParsedConsumptionHistoryEntry;
    august: ParsedConsumptionHistoryEntry;
    september: ParsedConsumptionHistoryEntry;
    october: ParsedConsumptionHistoryEntry;
    november: ParsedConsumptionHistoryEntry;
    december: ParsedConsumptionHistoryEntry;
  };
  files?: File[] | undefined;
}

export const EMPTY_UNIT: UnitPayload = {
  id: '',
  name: '',
  companyName: '',
  docType: UnitDocTypeEnum.CNPJ,
  docNumber: '',
  unitType: undefined,
  energyType: '',
  submarket: undefined,
  legalName: '',
  edc: undefined,
  group: { id: '' },
  cceeProfile: { id: '', idCcee: 0 },
  tariffSubgroup: undefined,
  tariffModality: undefined,
  contractedDemandOffPeak: undefined,
  contractedDemandPeak: undefined,
  averageInvoiceAmount: undefined,
  monthlyInvoice: undefined,
  powerGenerator: false,
  monthlyGeneratorValue: 0,
  assetCode: '',
  history: {
    january: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    february: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    march: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    april: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    may: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    june: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    july: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    august: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    september: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    october: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    november: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
    december: { id: '', peak: undefined, offPeak: undefined, generator: undefined },
  },
  files: undefined,
};

interface CreateUnitInvoiceEntry {
  unitId: string;
  yearMonth: string;
  consumptionPeak: number;
  consumptionOffPeak: number;
  generator: number;
}
export interface CreateUnitConsumptionHistoryGraphQLInput {
  history: CreateUnitInvoiceEntry[];
}

export interface EditUnitConsumptionHistoryGraphQLInput {
  history: EditUnitInvoiceEntry[];
}

interface EditUnitInvoiceEntry extends CreateUnitInvoiceEntry {
  id: string;
}

export interface UpsertUnitConsumptionHistoryGraphQLResponse {
  createHistory: {
    id: string;
  }[];
}

export interface UnitsListGraphQLData {
  group: { name: string; units: Unit[] };
}

export interface MeasuringPointGraphQLData {
  getMeasuringPointByGroupId: IMeasuringPoint[];
}

export interface IMeasuringPoint {
  id: string;
  unitId: string;
  measuringPoint: string;
}

export interface UnitsResume {
  totalVolume: number;
  totalAmount: number;
  totalContractedDemandPeak: number;
  totalContractedDemandOffPeak: number;
}

export interface IUnitsManagement {
  unitId: string;
  name: string;
  groupId: string;
}

export interface IUnitsManagementMutationVariables {
  input: { unitId: string; name: string; groupId: string }[];
}
export interface IUnitsManagementMutationResult {
  id: string;
}

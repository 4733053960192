import { ParsedConsumptionHistoryEntry, UnitPayload } from '@contexts/unit';

export const getUserInputsCountForUnitHistory = (unit: UnitPayload) => {
  return Object.values(unit.history).reduce(
    (acc, entry) => ({
      peak: (acc.peak || 0) + (entry.peak ? 1 : 0),
      offPeak: (acc.offPeak || 0) + (entry.offPeak ? 1 : 0),
      generator: (acc.generator || 0) + (entry.generator ? 1 : 0),
    }),
    { peak: 0, offPeak: 0, generator: 0 },
  );
};

export const calculateYearAveragesForUnitHistory = (unit: UnitPayload) => {
  const inputCounts = getUserInputsCountForUnitHistory(unit);
  const yearSum = Object.values(unit.history).reduce(
    (acc, entry) => ({
      peak: (acc.peak || 0) + (entry.peak || 0),
      offPeak: (acc.offPeak || 0) + (entry.offPeak || 0),
      generator: (acc.generator || 0) + (entry.generator || 0),
    }),
    { peak: 0, offPeak: 0, generator: 0 },
  );
  return {
    peak: inputCounts.peak ? (yearSum.peak || 0) / inputCounts.peak : 0,
    offPeak: inputCounts.offPeak ? (yearSum.offPeak || 0) / inputCounts.offPeak : 0,
    generator: inputCounts.generator ? (yearSum.generator || 0) / inputCounts.generator : 0,
  };
};

export type PaddedUnitHistory = {
  [Property in keyof UnitPayload['history']]: { peak: number; offPeak: number; generator: number };
};

export const buildPaddedUnitHistory = (unit: UnitPayload): PaddedUnitHistory => {
  const yearAverages = calculateYearAveragesForUnitHistory(unit);

  const result: PaddedUnitHistory = {
    january: { ...yearAverages },
    february: { ...yearAverages },
    march: { ...yearAverages },
    april: { ...yearAverages },
    may: { ...yearAverages },
    june: { ...yearAverages },
    july: { ...yearAverages },
    august: { ...yearAverages },
    september: { ...yearAverages },
    october: { ...yearAverages },
    november: { ...yearAverages },
    december: { ...yearAverages },
  };

  (Object.keys(result) as (keyof UnitPayload['history'])[]).forEach((key) => {
    result[key] = {
      peak: unit.history[key]?.peak ? unit.history[key]?.peak || 0 : result[key]?.peak || 0,
      offPeak: unit.history[key]?.offPeak ? unit.history[key]?.offPeak || 0 : result[key]?.offPeak || 0,
      generator: unit.history[key]?.generator ? unit.history[key]?.generator || 0 : result[key]?.generator || 0,
    };
  });

  return result;
};

export const parsePaddedUnitHistoryIntoGraphQLInput = (unitHistory: PaddedUnitHistory, now: Date, unitId: string) => {
  return (Object.keys(unitHistory) as (keyof typeof unitHistory)[]).map((month, index) => {
    const peak = unitHistory[month].peak ?? NaN;
    const offPeak = unitHistory[month].offPeak ?? NaN;
    const generator = unitHistory[month].generator ?? NaN;

    const monthNumber = index + 1;
    const yearForHistory = monthNumber > now.getMonth() ? now.getFullYear() - 1 : now.getFullYear();

    return {
      unitId,
      yearMonth: `${yearForHistory}-${monthNumber.toString().padStart(2, '0')}-01`,
      consumptionOffPeak: isNaN(offPeak) ? 0 : offPeak,
      consumptionPeak: isNaN(peak) ? 0 : peak,
      generator: isNaN(generator) ? 0 : generator,
    };
  });
};

export const checkHistoryToUpdateIsEmpty = (unitHistoryValues: ParsedConsumptionHistoryEntry[]) => {
  return unitHistoryValues.every(({ id }) => id);
};

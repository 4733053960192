import React from 'react';
import { Layout } from '@clarke-energia/foton';

import { CONTRACTS_PATH, HOME_PATH } from '@routers/constants';

import FormProinfa from '@components/molecules/form/form-create-proinfa/form';

const CreateProinfa: React.FC = () => {
  return (
    <Layout
      title="Enviar PROINFA"
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos',
          url: CONTRACTS_PATH,
        },
        {
          label: 'Enviar PROINFA',
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <FormProinfa />
    </Layout>
  );
};

export default CreateProinfa;

import dayjs from 'dayjs';
import uniqby from 'lodash.uniqby';
import groupBy from 'lodash.groupby';

import { formatMonthAndYearDate, formatAsMWh } from '@utils/text';
import { formatDateFromString } from '@utils/dayjs';
import numeral from '@utils/numeral';
import { devecStates } from '@utils/translators/report';

import {
  EconomyPerYear,
  EconomyReport,
  FinancialEventsInput,
  GroupData,
  GroupEconomyReport,
  GroupFormData,
  GroupReport,
  IReportStatus,
  ReportsDataTable,
  Subgroup,
  Tariff,
  TariffModality,
  UnitConsumption,
  UnitConsumptionAndFinancialAmounts,
  UnitReport,
  UnitsReportsTableData,
} from './types';

export type DevecObejectKey = keyof typeof devecStates;

const getYear = () => {
  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();

  return currentMonth === 1 ? currentYear - 1 : currentYear;
};

const fomartTableInfo = (
  id: string,
  groupName: string,
  date: string,
  unitName: string,
  savingTotal: number,
  savingPercentage: number,
  status: keyof typeof IReportStatus,
) => ({
  id,
  date: formatMonthAndYearDate(date),
  groupName,
  unitName,
  status,
  economy: `${numeral(savingTotal).format('$ 0,0.00')} (${savingPercentage.toFixed(2).replace('.', ',')}%)`,
});

export const reportsTableData = (reports: EconomyReport[], groupName: string): ReportsDataTable[] => {
  const groupData = reports
    .filter(({ groupReport }) => groupReport)
    .map(({ groupReport }) => ({
      ...fomartTableInfo(
        groupReport.id,
        groupName,
        groupReport?.date,
        'Geral',
        groupReport.totalMonthlySavings,
        groupReport.totalMonthlySavingsPercent,
        groupReport.status,
      ),
      formDataToUpdate: {
        id: groupReport?.id,
        groupId: groupReport.group.id,
        date: formatDateFromString(groupReport?.date, 'YYYYMMDD', 'MMYYYY'),
      },

      createdAt: groupReport.createdAt,
    }));

  const unitData = reports?.map(
    ({
      id,
      date,
      currentSavings,
      savingsPercent,
      unit,
      groupId,
      trader: { id: traderId },
      devecDeclaration,
      status,
      createdAt,
      freeMarket: {
        delays,
        distributionAdjustmentValue,
        energyConsumptionOffPeak,
        energyConsumptionPeak,
        reactiveDemandOffPeak,
        reactiveDemandPeak,
        reactiveEnergyCost,
        reactiveEnergyOffPeak,
        reactiveEnergyPeak,
        readDemandOffPeak,
        othersCosts,
        energyProvidedInput,
        pisCofins,
        readDemandPeak,
        tusdDiscount,
        loadFactor,
        energyDistribution: { covidTariffCost },
        energyProvided: { energyProvidedCost, energyProvidedAdjustmentValue },
        others: { cceeCost, clarkeManagementCost, eerCost, ercapCost, essCost },
      },
      conventionalMarket: { others: acrOthersCosts, tariffFlagCost: acrTariffFlagCost },
    }) => ({
      ...fomartTableInfo(id, groupName, date, unit.name, currentSavings, savingsPercent, status),
      createdAt,
      formDataToUpdate: {
        id,
        date: formatDateFromString(date, 'YYYYMMDD', 'MMYYYY'),
        groupId,
        unitId: unit.id,
        traderId,
        devecDeclaration,
        aclDelays: delays,
        aclDistributionAdjustmentValue: distributionAdjustmentValue,
        aclEnergyConsumptionPeak: energyConsumptionPeak,
        aclEnergyConsumptionOffPeak: energyConsumptionOffPeak,
        aclReactiveDemandOffPeak: reactiveDemandOffPeak,
        aclReactiveDemandPeak: reactiveDemandPeak,
        aclReactiveEnergyCost: reactiveEnergyCost,
        aclReactiveEnergyOffPeak: reactiveEnergyOffPeak,
        aclReactiveEnergyPeak: reactiveEnergyPeak,
        aclReadDemandOffPeak: readDemandOffPeak,
        aclOthersCosts: othersCosts,
        aclEnergyProvided: energyProvidedInput,
        aclEnergyProvidedCost: energyProvidedCost,
        aclPisCofins: pisCofins ? pisCofins * 100 : null,
        aclReadDemandPeak: readDemandPeak,
        aclTusdDiscount: tusdDiscount,
        aclCovidCost: covidTariffCost,
        aclLoadFactor: loadFactor ? loadFactor * 100 : null,
        aclEerCost: eerCost,
        aclErcapCost: ercapCost,
        aclEssCost: essCost,
        aclCceeCost: cceeCost,
        aclEnergyProvidedAdjustmentValue: energyProvidedAdjustmentValue,
        clarkeManagementCost,
        acrTariffFlagCost,
        acrOthersCosts,
      },
    }),
  );
  const groupDataWithoutDuplicated = uniqby(groupData, 'date');

  const dataSorted = [...groupDataWithoutDuplicated, ...unitData].sort((a, b) => {
    const dateB = formatDateFromString(b.formDataToUpdate.date, 'MMYYYY', 'YYYY-MM-DD');
    const dateA = formatDateFromString(a.formDataToUpdate.date, 'MMYYYY', 'YYYY-MM-DD');
    return new Date(dateB).getTime() - new Date(dateA).getTime();
  });

  return dataSorted;
};

const sumAllDemandOffPeakContractedUnits = (unitsReports: UnitReport[]): number => {
  return unitsReports.reduce((acc, { unit: { contractedDemandOffPeak } }) => {
    return acc + contractedDemandOffPeak;
  }, 0);
};

const unitsReportTableData = (
  unitsReports: UnitReport[],
  totalMonthlySavings: string,
  totalMonthlyEnergyConsumption: string,
  totalMonthlyEnergyCost: string,
): UnitsReportsTableData[] => {
  const unitsTotal = {
    unitReportLink: '',
    unit: 'Total',
    saved: totalMonthlySavings,
    energyCost: totalMonthlyEnergyCost,
    energyConsumption: totalMonthlyEnergyConsumption,
  };

  const unitsData = unitsReports.map(
    ({ id, currentSavings, energyTotalConsumption, energyTotalCost, unit: { name } }, idx) => ({
      unitReportLink: `/relatorio-unidade/${id}`,
      unit: `${idx + 1}.${name} ↗`,
      saved: numeral(currentSavings).format('$ 0,0.00'),
      energyCost: numeral(energyTotalCost).format('$ 0,0.00'),
      energyConsumption: formatAsMWh(energyTotalConsumption),
    }),
  );
  return [...unitsData, unitsTotal];
};

export const reportGroupParsed = ({
  date,
  economyReports,
  totalMonthlySavings,
  totalMonthlySavingsPercent,
  totalMonthlyEnergyConsumption,
  totalMonthlyEnergyCost,
  group: { name },
}: GroupEconomyReport): GroupReport => {
  const demandOffPeakTotal = sumAllDemandOffPeakContractedUnits(economyReports);
  const totalMonthlySavingsCurrencyFormat = numeral(totalMonthlySavings).format('$ 0,0.00');
  const totalMonthlyEnergyConsumptionCurrencyFormat =
    totalMonthlyEnergyConsumption !== undefined ? formatAsMWh(totalMonthlyEnergyConsumption) : '0';
  const totalMonthlyEnergyCostCurrencyFormat = numeral(totalMonthlyEnergyCost).format('$ 0,0.00');

  const unitsTableData = unitsReportTableData(
    economyReports,
    totalMonthlySavingsCurrencyFormat,
    totalMonthlyEnergyConsumptionCurrencyFormat,
    totalMonthlyEnergyCostCurrencyFormat,
  );

  return {
    name,
    date: formatMonthAndYearDate(date),
    contractType: '-',
    unitsAmount: economyReports.length,
    traders: uniqby(economyReports, 'trader.name')
      .map(({ trader }) => trader?.name)
      .join(','),
    edcs: uniqby(economyReports, 'unit.edc.name')
      .map(({ unit: { edc } }) => edc?.name)
      .join(','),
    totalMonthlySavings: totalMonthlySavingsCurrencyFormat,
    totalMonthlySavingsPercent: `${totalMonthlySavingsPercent.toFixed(2).replace('.', ',')}%`,
    totalMonthlyEnergyConsumption: totalMonthlyEnergyConsumptionCurrencyFormat,
    totalMonthlyEnergyCost: totalMonthlyEnergyCostCurrencyFormat,
    contractedDemandAmount: `${demandOffPeakTotal} kW`,
    unitsTableData,
  };
};

const taxPercentageValue = (taxType: string, taxesList: any[]): number => {
  return taxesList.reduce((acc, tax) => {
    if (tax.taxType === taxType) {
      return tax.percentageValue * 100;
    }
    return acc;
  }, 0);
};

const getCurrentEconomyAmount = (economyYearsList: EconomyPerYear[], currentYear: number): number => {
  return economyYearsList.filter(({ year }) => year === currentYear)[0]?.amount;
};

const checkSubgroupAndTariff = (subgroup: Subgroup, tariff: Tariff): keyof typeof TariffModality => {
  if (subgroup === 'A4' && tariff === 'BLUE') {
    return 'A4_BLUE';
  }
  if (subgroup === 'A4' && tariff === 'GREEN') {
    return 'A4_GREEN';
  }
  if (subgroup === 'AS' && tariff === 'GREEN') {
    return 'AS_GREEN';
  }
  if (subgroup === 'AS' && tariff === 'BLUE') {
    return 'AS_BLUE';
  }

  return 'A3_BLUE';
};

export const parseDataToReportForm = ({ units, id, name }: GroupData): GroupFormData => {
  return {
    groupId: id,
    name,
    units: units?.map(
      ({
        contractedDemandOffPeak,
        contractedDemandOffPeakBeforeMigration,
        contractedDemandPeak,
        edc,
        contractedDemandPeakBeforeMigration,
        id,
        name,
        tariffModality,
        tariffSubgroup,
        unitType,
        energyContract,
        clarkeContract,
        docNumber,
      }) => {
        const icmsType = unitType === 'COMMERCIAL' ? 'ICMS_COMERCIAL' : 'ICMS_INDUSTRIAL';
        const hasCommercialDistributitionIcms = edc?.taxes.some((tax) => tax.taxType === 'ICMS_COMERCIAL_DISTRIBUICAO');
        const aclIcmsDistribution = hasCommercialDistributitionIcms ? 'ICMS_COMERCIAL_DISTRIBUICAO' : icmsType;
        const tariff = checkSubgroupAndTariff(tariffSubgroup, tariffModality);

        return {
          name,
          id,
          tariffType: TariffModality[tariff],
          retailService: energyContract ? energyContract?.trader?.type === 'RETAIL_TRADER' : null,
          displayChargesForRetail: energyContract ? energyContract.includeCceeCharges : undefined,
          traderId: energyContract?.trader?.id ?? '',
          aclContractType: energyContract?.contractType ?? null,
          aclEnergyProvidedCost:
            energyContract?.contractType === 'GUARANTEED_SAVING'
              ? getCurrentEconomyAmount(energyContract?.economy, getYear())
              : null,
          clarkeManagementCost: clarkeContract?.clarkeMagmentAmount ?? null,
          edcId: edc?.id || null,
          aclIcmsDistribution: aclIcmsDistribution
            ? edc?.taxes && taxPercentageValue(aclIcmsDistribution, edc?.taxes)
            : 0,
          aclIcmsSupply: aclIcmsDistribution ? edc?.taxes && taxPercentageValue(icmsType, edc?.taxes) : 0,
          aclContractedDemandOffPeak: contractedDemandOffPeak,
          aclContractedDemandPeak: contractedDemandPeak,
          acrContractedDemandPeak: contractedDemandPeakBeforeMigration || contractedDemandPeak,
          acrContractedDemandOffPeak: contractedDemandOffPeakBeforeMigration || contractedDemandOffPeak,
          devecState: (devecStates[edc.state as DevecObejectKey] as DevecObejectKey) || null,
          docNumber,
        };
      },
    ),
  };
};

const toggleSignOfNumber = (num: number) => num * -1;

const sumUnitsConsumptionsByProfile = (profiles: Record<string, UnitConsumption[]>) => {
  return Object.fromEntries(
    Object.entries(profiles).map(([key, profile]) => [
      key,
      profile.reduce((total, item) => total + item.consumptionAmount, 0),
    ]),
  );
};

export const calcFinancialAmountsFromUnitsConsumption = ({
  totalConsumption,
  totalCceeFee,
  totalLiquidationFee,
  totalEerFeeByProfile,
  units,
}: FinancialEventsInput): UnitConsumptionAndFinancialAmounts[] => {
  const liquidationAmountConverted = toggleSignOfNumber(totalLiquidationFee);
  const unitsPerProfile = groupBy(units, 'profileName');
  const profileConsumptions = sumUnitsConsumptionsByProfile(unitsPerProfile);
  const unitsResult = units.map(({ consumptionAmount, id, profileName }) => {
    const totalEerFeeProfile = profileName ? totalEerFeeByProfile[profileName] : 0;
    const totalConsumptioProfile = profileName ? profileConsumptions[profileName] : 0;

    return {
      id,
      consumptionAmount,
      liquidationAmount: (liquidationAmountConverted / totalConsumption) * consumptionAmount,
      cceeAmount: (totalCceeFee / totalConsumption) * consumptionAmount,
      eerAmount: (totalEerFeeProfile / totalConsumptioProfile) * consumptionAmount,
    };
  });
  return unitsResult;
};

import { formatDateStr, formatUuid } from '@utils/text';
import { englishFormatDateFromString, formatDateFromString } from '@utils/dayjs';
import { SubmarketEnum } from '@utils/translators/proposal';
import {
  CceeContract,
  CceeContractGraphQlResponse,
  CceeContractResponse,
  ContractType,
  ContractTypeResponse,
  CreateCceeContractPayload,
  EnergyContract,
  EnergyContractGraphQlResponse,
  EnergyTypeTableEnum,
} from './types';

const getSubmarketValue = (units: Record<'submarket', keyof typeof SubmarketEnum | null>[] | undefined): string => {
  if (!units?.length) return '-';
  const firstSubmarket = units[0].submarket;
  if (!firstSubmarket) return '-';
  const foo = units.every(({ submarket }) => submarket === firstSubmarket);
  return foo ? SubmarketEnum[firstSubmarket] : '-';
};

export const parserCceeContractsTableData = (
  cceeContracts: CceeContractGraphQlResponse['cceeContracts']['data'],
): CceeContract[] => {
  return cceeContracts.map(
    ({ contractCceeId, startDate, contractType, energyType, profileCcee, group, trader, id, energyContract }) => {
      const isLongDurationContract = contractType === 'LONG_DURATION';
      const unitsSubmarket = isLongDurationContract ? energyContract?.units : profileCcee.units;
      const date = startDate ?? energyContract?.startSupplyDate;

      const groupName = group?.name ?? '-';
      const traderName = trader?.name ?? '-';
      const startDateValue = date ? formatDateFromString(date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY') : '-';

      return {
        id,
        groupName,
        profileName: profileCcee?.name ?? '-',
        contractNumber: contractCceeId,
        duration: ContractTypeResponse[contractType],
        energyType: EnergyTypeTableEnum[energyType],
        startDate: startDateValue,
        trader: traderName,
        submarket: getSubmarketValue(unitsSubmarket),
      };
    },
  );
};

export const parsePayloadToCreation = ({
  startDate,
  endDate,
  duration,
  contractType,
  ...restPayload
}: CreateCceeContractPayload): CreateCceeContractPayload => {
  return {
    ...restPayload,
    contractType: duration === 'LONG' ? 'LONG_DURATION' : contractType,
    startDate: startDate ? englishFormatDateFromString(startDate, 'DDMMYYYY', 'YYYY-MM-DD') : null,
    endDate: endDate ? englishFormatDateFromString(endDate, 'DDMMYYYY', 'YYYY-MM-DD') : null,
  };
};

export const parseEnergyContracts = (
  contracts: EnergyContractGraphQlResponse['energyContracts']['data'],
): EnergyContract[] => {
  return contracts.map(({ id, trader, units, profilesCcee }) => {
    const group = { name: `${units[0].group.name} (${formatUuid(id)})`, id: units[0].group.id };
    const retailTraderId = trader ? trader?.id : '';
    const profiles = profilesCcee.length ? profilesCcee.map(({ id, name }) => ({ optionLabel: name, value: id })) : [];
    return {
      id,
      group,
      retailTraderId,
      profilesCcee: profiles,
    };
  });
};

export const parseContractToUpdate = (contract: CceeContractResponse): CreateCceeContractPayload => {
  const {
    id,
    contractType,
    profileCcee,
    startDate,
    endDate,
    energyContract,
    group,
    trader,
    contractCceeId,
    energyType,
    energyPrice,
    tradedVolume,
  } = contract;

  const contractTypeValue = Object.keys(ContractType).find((key) => key === contractType) as keyof typeof ContractType;

  return {
    id,
    contractCceeId,
    energyType,
    cceeTraderId: trader?.cceeTraderId ?? null,
    cceeTraderProfile: trader?.cceeTraderProfile ?? null,
    energyPrice: energyPrice ?? null,
    tradedVolume: tradedVolume ?? null,
    groupId: group?.id ?? null,
    retailTraderId: trader?.id ?? null,
    startDate: startDate?.length ? formatDateStr(startDate) : null,
    endDate: endDate?.length ? formatDateStr(endDate) : null,
    energyContractId: energyContract?.id,
    idProfileCcee: profileCcee?.id ?? null,
    contractType: contractTypeValue ?? null,
    duration: contractTypeValue === 'LONG_DURATION' ? 'LONG' : 'SHORT',
  };
};

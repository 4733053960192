import React, { createContext, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { User } from '@auth0/auth0-react';

import { useAuth } from '@src/ApolloWrapper';

import { UsersContextType, EmailLog, SendWelcomeMigrationEmailPayload, UserInfo } from './types';

import { GET_EMAILS_LOG_QUERY, GET_GROUP_BY_USER } from './queries';
import { SEND_WELCOME_MIGRATION_EMAIL } from './mutation';

interface Provider {
  children: React.ReactNode;
}

export * from './types';

export const UsersContext = createContext<UsersContextType>({} as UsersContextType);

export const UsersProvider: React.FC<Provider> = ({ children }: Provider) => {
  const { user: rawUser, loading, auth0ApiService } = useAuth();
  const [user, setUser] = useState<UserInfo>();
  const [users, setUsers] = useState<Array<UserInfo>>();
  const [logEmails, setLogEmails] = useState<EmailLog[]>([]);
  const [getEmailsLog, { loading: loadingEmailLog }] = useLazyQuery(GET_EMAILS_LOG_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      return setLogEmails(data.getLogsFromEmail);
    },
    variables: {
      email: user?.email ? user.email : '',
    },
  });
  const [getGroupByUser] = useLazyQuery(GET_GROUP_BY_USER, {
    fetchPolicy: 'network-only',
    variables: {
      getGroupByUserId: user?.id,
    },
  });
  const [sendWelcomeMigrationEmail] = useMutation(SEND_WELCOME_MIGRATION_EMAIL, {
    fetchPolicy: 'network-only',
  });

  function getUsersHandler(userAttribute?: string) {
    auth0ApiService?.getUsers(userAttribute).then((response) => {
      const parsedUsersList: User[] = response.data;
      setUsers(
        parsedUsersList.map(
          (user) =>
            ({
              id: user.app_metadata.uuid as string,
              authId: user.user_id,
              email: user.email,
              isAdmin: /.*@clarke.com(.br)?/.test(user.email as string),
              name: user.name,
              phone: user.phone_number,
              trader: null,
              pictureUrl: user.picture,
            } as UserInfo),
        ),
      );
    });
  }

  function getUserHandler(userId: string) {
    auth0ApiService?.getUserById(userId).then((response) => {
      const parsedUser: User = [...response.data][0];
      setUser({
        id: parsedUser.app_metadata.uuid as string,
        authId: parsedUser.user_id,
        email: parsedUser.email,
        isAdmin: /.*@clarke.com(.br)?/.test(parsedUser.email as string),
        name: parsedUser.name,
        phone: parsedUser.phone_number,
        trader: null,
      } as UserInfo);
    });
  }

  function sendWelcomeMigrationEmailHandler(values: SendWelcomeMigrationEmailPayload) {
    return sendWelcomeMigrationEmail({
      variables: {
        input: {
          groupId: values.groupId,
          userId: values.userId,
          email: values.email,
        },
      },
    });
  }

  React.useEffect(() => {
    getUsersHandler();
  }, []);

  React.useEffect(() => {
    rawUser &&
      setUser({
        id: rawUser.id,
        email: rawUser.email,
        isAdmin: /.*@clarke.com(.br)?/.test(rawUser.email as string),
        name: rawUser.name,
        phone: rawUser.phone,
      } as UserInfo);
  }, [rawUser]);

  return (
    <UsersContext.Provider
      value={{
        user,
        users,
        getUserHandler,
        getUsersHandler,
        getEmailsLog,
        getGroupByUser,
        logEmails,
        sendWelcomeMigrationEmailHandler,
        loadingEmailLog,
        loading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

import { useContextSelector } from 'use-context-selector';
import { EnergyAuditsReportContextType } from '@contexts/energy-audit-report/types';
import { EnergyAuditsReportContext } from '@contexts/energy-audit-report';

const useEnergyAuditReports = (): EnergyAuditsReportContextType => {
  const energyAuditReports = useContextSelector(EnergyAuditsReportContext, (item) => item.energyAuditReports);
  const getAllEnergyReports = useContextSelector(EnergyAuditsReportContext, (item) => item.getAllEnergyReports);
  const loading = useContextSelector(EnergyAuditsReportContext, (item) => item.loading);
  const handleDownloadExcel = useContextSelector(EnergyAuditsReportContext, (item) => item.handleDownloadExcel);

  return { energyAuditReports, getAllEnergyReports, loading, handleDownloadExcel };
};

export default useEnergyAuditReports;

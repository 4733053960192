import { useContextSelector } from 'use-context-selector';
import { ManagementContractContext } from '@contexts/management-contract';
import {
  IManagementContract,
  IManagementContractContext,
  IManagementContractPayload,
} from '@contexts/management-contract/types';
import { useState } from 'react';

interface IUseManagementContract extends IManagementContractContext {
  handleUpdateManagementContract: (
    formValues: IManagementContractPayload | IManagementContract,
    setFeedbackData: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  isLoading: boolean;
}

export interface IFeedbackData {
  message: string;
  kind: 'neutral' | 'primary' | 'error';
  label: string;
}
const getFeedbackData = (): Record<string, IFeedbackData> => {
  return {
    UPDATE_CONTRACT_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Erro na criação de contrato.',
    },
    UPDATE_CONTRACT_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Contrato criado com sucesso.',
    },
  };
};

const useManagementContract = (): IUseManagementContract => {
  const updateManagementContract = useContextSelector(
    ManagementContractContext,
    (contract) => contract.updateManagementContract,
  );
  const getAllManagementContracts = useContextSelector(
    ManagementContractContext,
    (contract) => contract.getAllManagementContracts,
  );
  const getManagementContractById = useContextSelector(
    ManagementContractContext,
    (contract) => contract.getManagementContractById,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleUpdateManagementContract(
    formValues: IManagementContractPayload | IManagementContract,
    setFeedbackData: React.Dispatch<React.SetStateAction<any>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ): void {
    setIsLoading(true);
    updateManagementContract(formValues as IManagementContract)
      .then((response) => {
        if (response.data.message[0].success) {
          setIsModalOpen(true);
        } else {
          setFeedbackData(getFeedbackData().UPDATE_CONTRACT_FAILURE);
        }
      })
      .catch((e) => {
        setFeedbackData(getFeedbackData().UPDATE_CONTRACT_FAILURE);
      })
      .finally(() => setIsLoading(false));
  }

  return {
    getAllManagementContracts,
    updateManagementContract,
    getManagementContractById,
    handleUpdateManagementContract,
    isLoading,
  };
};
export default useManagementContract;

import { ButtonIcon, Button } from '@clarke-energia/foton';
import React from 'react';

interface MissingAndRedirectButtons {
  buttonIcon: {
    label: string;
    onClick: () => void;
  };
  button: {
    label: string;
    disabled: boolean;
    kind: 'secondary' | 'primary';
    onClick: () => void;
  };
}

const MissingRedirectButtons: React.FC<MissingAndRedirectButtons> = ({ button, buttonIcon }) => {
  return (
    <div className="flex gap-4 justify-between mb-6">
      <div className="flex items-center">
        <ButtonIcon kind="ghost" icon="ArrowLeftIcon" onClick={buttonIcon.onClick} />
        <h1 className="text-heading-large">{buttonIcon.label}</h1>
      </div>
      <Button disabled={button.disabled} label={button.label} kind={button.kind} onClick={button.onClick} />
    </div>
  );
};

export default MissingRedirectButtons;

import React from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { CommissionModelType, CommissionType, CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { NumericFormField } from '@components/atoms/form';

interface EnergyContractCommissionSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
  commissionModel: CommissionModelType;
  setValue: UseFormSetValue<FieldValues>;
}

const EnergyContractCommissionFields = ({
  yearsFields,
  startYear,
  commissionModel,
  setValue,
}: EnergyContractCommissionSectionProps): JSX.Element => {
  const propsCommission = {
    placeholder: 'R$',
    prefix: 'R$',
    suffix: '/MWh',
    allowNegative: true,
    fixedDecimalScale: true,
    decimalScale: 2,
  };

  return (
    <>
      {commissionModel === CommissionModelType.FIXED ? (
        <NumericFormField<CreateEnergyContractPayload>
          field="fixedCommission"
          id="fixedCommission"
          label="Comissão da Clarke"
          formatProps={propsCommission}
          options={{
            required: { value: true, message: 'Campo é obrigatório' },
            onChange: (e) => {
              yearsFields.forEach((_, idx) => {
                setValue(`energyContractCommissions.${idx}.year`, startYear + idx);
                setValue(`energyContractCommissions.${idx}.commissionType`, CommissionType.MONETARY);
                setValue(`energyContractCommissions.${idx}.commissionAmount`, e.target.value);
              });
            },
          }}
        />
      ) : (
        yearsFields.map((_, idx) => (
          <NumericFormField<CreateEnergyContractPayload>
            key={`commission-year-field-${idx}`}
            field={`energyContractCommissions.${idx}.commissionAmount`}
            id={`energyContractCommissions${idx}.commissionAmount`}
            label={`${startYear + idx}`}
            formatProps={propsCommission}
            options={{
              required: { value: true, message: 'Campo é obrigatório' },
              onChange: () => {
                setValue(`energyContractCommissions.${idx}.year`, startYear + idx);
                setValue(`energyContractCommissions.${idx}.commissionType`, CommissionType.MONETARY);
              },
            }}
          />
        ))
      )}
    </>
  );
};

export default EnergyContractCommissionFields;

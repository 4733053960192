import { GeneralGadget, Table, GeneralGadgetProps, ButtonIcon, ColumnOptions } from '@clarke-energia/foton';
import { MonthlyControlMeasurementByUnitId } from '@contexts/energy-audit-consumptions/types';
import { formatDateFromString } from '@utils/dayjs';
import React from 'react';

interface MonthlyConsumptionsTableProps {
  tableGadgetInfo: GeneralGadgetProps;
  tableData: {
    data?: Array<MonthlyControlMeasurementByUnitId>;
    openMissingHoursAction: (entry: MonthlyControlMeasurementByUnitId) => void;
  };
}

const MonthlyConsumptionTable: React.FC<MonthlyConsumptionsTableProps> = ({ tableGadgetInfo, tableData }) => {
  type renderEnergyAuditUnitsFunction = ColumnOptions<MonthlyControlMeasurementByUnitId>['renderAsElement'];
  const renderDate: renderEnergyAuditUnitsFunction = (entry) =>
    formatDateFromString(entry.date, 'YYYY-MM-DD', 'DD/MM/YYYY');
  const renderHoursLeft: renderEnergyAuditUnitsFunction = (entry) => String(entry.totalMissingHours);
  const renderTotalConsumptionInMWh: renderEnergyAuditUnitsFunction = (entry) =>
    String(entry.totalConsumption.toFixed(3)).replace('.', ',');
  const renderActionIcon: renderEnergyAuditUnitsFunction = (entry) => {
    return (
      <ButtonIcon
        className="h-full hover:bg-neutral-20"
        kind="ghost"
        icon="ArrowRightIcon"
        shape="square"
        onClick={() => tableData.openMissingHoursAction(entry)}
      />
    );
  };

  const consumptionsColumnDefinitions: Array<ColumnOptions<MonthlyControlMeasurementByUnitId>> = [
    {
      accessor: 'monthYear',
      header: 'Data',
      renderAsElement: renderDate,
    },
    {
      accessor: 'totalMissingHours',
      header: 'Horas faltantes',
      renderAsElement: renderHoursLeft,
    },
    {
      accessor: 'totalConsumption',
      header: 'Consumo (MWh)',
      renderAsElement: renderTotalConsumptionInMWh,
    },
    {
      renderAsElement: renderActionIcon,
      fixedWidthInRem: 5,
    },
  ];

  return (
    <div className="mb-6">
      <GeneralGadget {...tableGadgetInfo}>
        <div className="overflow-y-auto max-h-[31.625rem]">
          {tableData.data && <Table data={tableData.data} tableColumns={consumptionsColumnDefinitions} />}
        </div>
      </GeneralGadget>
    </div>
  );
};

export default MonthlyConsumptionTable;

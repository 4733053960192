import React from 'react';
import { Layout, LayoutProps } from '@clarke-energia/foton';
import { HOME_PATH } from '@routers/constants';
import { IManagementContractPayload } from '@contexts/management-contract/types';

enum ContractGeneratorTypeEnum {
  PRODUCT_ONE = 'Clarke gestão',
  PRODUCT_TWO = 'Clarke varejo',
}

interface IContractLayoutBase<T> extends LayoutProps {
  children: React.ReactNode;
  contractType: keyof typeof ContractGeneratorTypeEnum;
  contractFlow?: 'CONTRACT_UPDATE_FORM';
  formValues?: IManagementContractPayload;
  setFormValues?: (formValues: IManagementContractPayload) => void;
  hidePasteValuesButtons?: boolean;
}

const ContractLayoutBase = <T,>({
  children,
  contractType,
  contractFlow,
  formValues,
  setFormValues,
  hidePasteValuesButtons,
  ...layoutProps
}: IContractLayoutBase<T>) => {
  const baseLinks = [
    {
      label: 'Início',
      url: HOME_PATH,
    },
    {
      label: ContractGeneratorTypeEnum[contractType],
      url: '#',
    },
  ];

  const contractFormLinks =
    contractFlow === 'CONTRACT_UPDATE_FORM'
      ? [
          {
            label: ' Contrato',
            url: '#',
          },
        ]
      : [];

  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks, ...contractFormLinks];

  return (
    <Layout
      {...layoutProps}
      navigationHistoryLinks={navigationHistoryLinks}
      darkerBackground
      className="overflow-y-hidden pb-16 h-full"
    >
      <div className="overflow-y-auto col-span-full p-2 h-full md:p-5">{children}</div>
    </Layout>
  );
};

export default ContractLayoutBase;

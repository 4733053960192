import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, Layout, FeedbackNotification } from '@clarke-energia/foton';

import { CONTRACTS_PATH, HOME_PATH } from '@routers/constants';

import { ClarkeContractPayload, EMPTY_CLARKE_CONTRACT } from '@contexts/clarke-contract/types';
import { useClarkeContract } from '@hooks/use-clarke-contract';
import { useNotification } from '@hooks/use-notification';

import FormContainer from '@components/molecules/form/form-upsert-clarke-contract';
import { parsePayloadToCreation } from '@contexts/clarke-contract/parser';

const ClarkeContractFormPage: React.FC = () => {
  const { id } = useParams<string>();
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  const { openNotificationFeedBack, setOpenNotificationFeedback, messageNotificationFeedBack } = useNotification();
  const {
    getGroupsListHandler,
    createClarkeContractHandler,
    updateClarkeContractHandler,
    getClarkeContractByIdHandler,
    createClarkeContractAttachmentsHandler,
    contractToUpdate,
    loading,
  } = useClarkeContract();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>();

  React.useEffect(() => {
    if (id) getClarkeContractByIdHandler(id);
  }, []);

  React.useEffect(() => {
    getGroupsListHandler();
  }, []);

  React.useEffect(() => {
    if (contractToUpdate) methods.reset(contractToUpdate);
  }, [contractToUpdate]);

  const methods = useForm<ClarkeContractPayload>({
    defaultValues: EMPTY_CLARKE_CONTRACT,
    mode: 'all',
  });

  const onSubmit: SubmitHandler<ClarkeContractPayload> = async (values) => {
    setSubmitLoading(true);
    const dataFormated = parsePayloadToCreation(values);

    const response = id
      ? await updateClarkeContractHandler(id, dataFormated)
      : await createClarkeContractHandler(dataFormated);

    if (response.id && uploadedFiles) {
      await createClarkeContractAttachmentsHandler({
        clarkeContractAttachments: uploadedFiles,
        clarkeContractId: response.id,
      });
    }

    setSubmitLoading(false);

    navigate(CONTRACTS_PATH, { replace: true, state: response.feedback });
  };

  return (
    <Layout
      title={`${id ? 'Editar' : 'Criar'} contrato Clarke`}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos',
          url: CONTRACTS_PATH,
        },
        {
          label: `${id ? 'Editar' : 'Criar'} contrato Clarke`,
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full z-100 lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => {
              setOpenNotificationFeedback(false);
            }}
          />
        )}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full h-full">
          {loading ? (
            <Skeleton width="100%" className="mb-7 h-screen xl:mb-10" />
          ) : (
            <FormContainer
              setUploadedFiles={setUploadedFiles}
              storedFiles={contractToUpdate.clarkeContractAttachments}
            />
          )}
          <div className="flex gap-5 mb-5 w-fit">
            <Button
              loading={submitLoading}
              label={`${id ? 'Atualizar' : 'Criar'} contrato`}
              type="submit"
              kind={'primary'}
            />
            <Link to={CONTRACTS_PATH}>
              <Button label="Cancelar" type="button" onClick={() => undefined} kind={'secondary'} />
            </Link>
          </div>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default ClarkeContractFormPage;

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnOptions, Modal } from '@clarke-energia/foton';

import { CceeContract, ContractType } from '@contexts/ccee-contract/types';
import { useCceeContract } from '@hooks/use-cce-contract';
import { useNotification } from '@hooks/use-notification';

import { contractFeedbackMessage, overflowMenuIcons } from '@pages/contracts/helper';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import CceeContractTable from '@components/molecules/ccee-contract/table';
import OverflowMenuTable from '@components/atoms/contracts/overflow-table';

import Filters from '../contracts/filters-table';
import { getKeyContractType } from './helper';

interface CceeContractProps {
  typedSearchTerm: string;
}

interface Filters {
  group: {
    label: string | null;
    value: string | null;
  };
  contractType: {
    label: ContractType | null;
    value: keyof typeof ContractType | null;
  };
}

type renderFunction = ColumnOptions<CceeContract>['renderAsElement'];

const renderGroupName: renderFunction = (entry) => entry.groupName;
const renderProfile: renderFunction = (entry) => entry.profileName;
const renderContractCceeId: renderFunction = (entry) => entry.contractNumber.toString();
const renderSubmarket: renderFunction = (entry) => entry.submarket;
const renderEnergyType: renderFunction = (entry) => entry.energyType;
const renderTrader: renderFunction = (entry) => entry.trader;
const renderStartDate: renderFunction = (entry) => entry.startDate;
const renderContractDuration: renderFunction = (entry) => entry.duration;

const CceeContractList: React.FC<CceeContractProps> = ({ typedSearchTerm }) => {
  const navigate = useNavigate();

  const {
    cceeContracts,
    loading,
    deleteCceeContractHandler,
    getCceeContractsHandler,
    getGroupsFilterHandler,
    groupsFilter: { totalPage, groupsFilter },
    pagination,
  } = useCceeContract();
  const { setNotificationResponse } = useNotification();

  const [page, setPage] = useState<number>(1);
  const [groupPage, setGroupPage] = useState<number>(1);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openFilterOptions, setOpenFilterOptions] = useState<'TYPE' | 'GROUP' | null>(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState<Filters>({
    group: { value: null, label: null },
    contractType: { value: null, label: null },
  });

  React.useEffect(() => {
    const params = {
      page,
      contractType: selectedFilterOption.contractType.value,
      groupId: selectedFilterOption.group.value,
      term: typedSearchTerm,
    };
    getCceeContractsHandler(params);
  }, [page, selectedFilterOption, typedSearchTerm]);

  React.useEffect(() => {
    if (groupPage !== 1 && groupPage > totalPage) {
      return;
    }
    getGroupsFilterHandler(groupPage);
  }, [groupPage]);

  const cceeProfileTableColumns: ColumnOptions<CceeContract>[] = [
    { accessor: 'contractNumber', header: 'Contrato CCEE', renderAsElement: renderContractCceeId },
    { accessor: 'groupName', header: 'Grupo comercial', renderAsElement: renderGroupName },
    {
      accessor: 'profileName',
      header: 'Perfil CCEE',
      renderAsElement: renderProfile,
      fixedWidthInRem: 10,
      ellipsisStyle: { position: 'left-[1.5rem] -top-[1rem]' },
    },
    { accessor: 'submarket', header: 'Submercado', renderAsElement: renderSubmarket },
    { accessor: 'energyType', header: 'Tipo de Energia', renderAsElement: renderEnergyType },
    { accessor: 'trader', header: 'Fornecedora', renderAsElement: renderTrader },
    { accessor: 'startDate', header: 'Inicio da vigencia', renderAsElement: renderStartDate },
    { accessor: 'duration', header: 'Duracao do contrato', renderAsElement: renderContractDuration },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <OverflowMenuTable
            show={openTableMenu && selectedId === entry.id}
            itens={overflowMenuIcons}
            buttonClick={() => {
              setSelectedId(entry.id);
              setOpenTableMenu(!openTableMenu);
            }}
            menuClick={(selectedIdx) => {
              const isEditMode = selectedIdx === 0;
              if (isEditMode) {
                navigate(`/editar-contrato-ccee/${entry.id}`);
              } else {
                setOpenConfirmationModal(true);
                setSelectedId(entry.id);
              }
              setOpenTableMenu(!openTableMenu);
            }}
          />
        );
      },
    },
  ];

  const contractTypeOptions = Object.values(ContractType);

  const groupOptions = groupsFilter.map(({ label }) => label);

  const groupFilter = useMemo(() => {
    return {
      label: selectedFilterOption.group?.label || 'Grupo Comercial',
      filterSelected: selectedFilterOption.group?.label !== null,
      options: groupOptions,
      openOptions: () => {
        if (openFilterOptions === 'GROUP') {
          setOpenFilterOptions(null);
          return;
        }
        setOpenFilterOptions('GROUP');
      },
      onClick: (option: string) => {
        const groupValue = groupsFilter.find(({ label }) => label === option)?.value || null;
        setSelectedFilterOption((prevState) => ({ ...prevState, group: { label: option, value: groupValue } }));
        setOpenFilterOptions(null);
      },
      showOptions: openFilterOptions === 'GROUP',
    };
  }, [selectedFilterOption.group, openFilterOptions]);

  const contractTypeFilter = useMemo(() => {
    return {
      label: selectedFilterOption.contractType?.label || 'Contrato',
      filterSelected: selectedFilterOption.contractType?.label !== null,
      options: contractTypeOptions,
      openOptions: () => {
        if (openFilterOptions === 'TYPE') {
          setOpenFilterOptions(null);
          return;
        }
        setOpenFilterOptions('TYPE');
      },
      onClick: (option: ContractType) => {
        setSelectedFilterOption((prevState) => ({
          ...prevState,
          contractType: { label: option, value: getKeyContractType(option) },
        }));
        setOpenFilterOptions(null);
      },
      showOptions: openFilterOptions === 'TYPE',
    };
  }, [selectedFilterOption.contractType, openFilterOptions]);

  const confirmationDelete = async () => {
    const {
      feedback: { message, tab },
    } = await deleteCceeContractHandler(selectedId);
    setNotificationResponse(contractFeedbackMessage(tab, message));
    setOpenConfirmationModal(false);
    setOpenTableMenu(false);
  };

  return (
    <>
      <div className="col-span-full">
        <Filters
          filters={[groupFilter, contractTypeFilter]}
          isLoading={loading}
          onScrollToBotton={() => {
            setGroupPage((prevPage) => prevPage + 1);
          }}
          clearFilter={() =>
            setSelectedFilterOption({
              group: { value: null, label: null },
              contractType: { value: null, label: null },
            })
          }
        />
        <CceeContractTable
          columns={cceeProfileTableColumns}
          data={cceeContracts}
          isFetchingContracts={loading}
          pagination={{
            page,
            total: pagination.total,
            setCurrentPage: setPage,
            limit: pagination.limit,
          }}
        />
      </div>

      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar contrato CCEE"
        description="Tem certeza que deseja excluir esse contrato?"
      >
        <FormConfirmation
          onConfirmation={confirmationDelete}
          onCancel={() => {
            setSelectedId(null);
            setOpenConfirmationModal(false);
            setOpenTableMenu(false);
          }}
        />
      </Modal>
    </>
  );
};

export default CceeContractList;

import React, { useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { AxiosPromise } from 'axios';

import { FinancialEventType, IFinancialEventsBatchPayload } from '@contexts/financial-events';

import { FormSection } from '@components/atoms/form';
import FinancialEventsBatchCreationFormDataSection from '@components/molecules/financial-events/batch-creation/form-data-section';
import FinancialEventsBatchCreationFormActionsSection from '@components/molecules/financial-events/batch-creation/form-actions-section';
import FinancialEventsBatchCreationFormFilesSection from '@components/molecules/financial-events/batch-creation/form-files-section';

interface ICreateFinancialEventsBatchForm {
  onFormSuccess: () => void;
  onFormFail: () => void;
  createFinancialEventsBatchHandler: (financialEvent: IFinancialEventsBatchPayload) => AxiosPromise<any>;
  setFinancialEventsBatchId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFinancialEventsType: React.Dispatch<React.SetStateAction<keyof typeof FinancialEventType | undefined>>;
}

function CreateFinancialEventsBatchForm({
  onFormSuccess,
  onFormFail,
  createFinancialEventsBatchHandler,
  setFinancialEventsBatchId,
  setFinancialEventsType,
}: ICreateFinancialEventsBatchForm) {
  const formController = useForm<IFinancialEventsBatchPayload>();
  const financialEventsType = formController.watch('type');

  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadedAttachments, setUploadedAttachments] = useState<Array<File>>();
  const [loadingCreateFinancialEventsBatchHandler, setLoadingCreateFinancialEventsBatchHandler] =
    useState<boolean>(false);

  const onSubmit: SubmitHandler<IFinancialEventsBatchPayload> = async (values) => {
    if (uploadedFile) {
      setLoadingCreateFinancialEventsBatchHandler(true);
      createFinancialEventsBatchHandler({
        ...values,
        spreadsheetToUpload: uploadedFile,
        attachmentsToUpload: uploadedAttachments,
      })
        .then((response) => {
          setFinancialEventsBatchId(response.data.data.id);
          onFormSuccess();
        })
        .catch(() => {
          onFormFail();
        })
        .finally(() => setLoadingCreateFinancialEventsBatchHandler(false));
    } else {
      onFormFail();
    }
  };

  React.useEffect(() => {
    setFinancialEventsType(financialEventsType);
  }, [financialEventsType]);

  return (
    <FormProvider {...formController}>
      <form onSubmit={formController.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 mb-8 w-full">
        <div className="flex gap-6 mb-10 w-full">
          <FormSection
            title="1. Enviar dados"
            subtitle="Selecione o evento financeiro que será emitido e envie a tabela de dados."
            className="w-1/4"
          />
          <div className="flex flex-col gap-6 w-3/4">
            <FinancialEventsBatchCreationFormDataSection />
            <FinancialEventsBatchCreationFormFilesSection
              setUploadedAttachments={setUploadedAttachments}
              setUploadedFile={setUploadedFile}
            />
          </div>
        </div>
        <FinancialEventsBatchCreationFormActionsSection isLoading={loadingCreateFinancialEventsBatchHandler} />
      </form>
    </FormProvider>
  );
}

export default CreateFinancialEventsBatchForm;

import React, { useState } from 'react';

import { ButtonIcon, ColumnOptions, FeedbackNotification, Layout, Modal } from '@clarke-energia/foton';

import { CCEEProfileNotificationFeedbackContents } from '@utils/constants/common';
import { HOME_PATH } from '@routers/constants';
import { CceeProfile, CreateCceeProfilePayload } from '@contexts/ccee-profile/types';
import { useCceeProfiles } from '@hooks/use-cce-profile';
import useCommercialGroups from '@hooks/use-commercial-group';
import { useNotification } from '@hooks/use-notification';

import FormConfirmation from '@components/molecules/form/form-confirmation';
import CceeProfileTable from '@components/molecules/ccee-profile/table';
import FormUpsertCceeProfile from '@components/molecules/form/form-upsert-ccee-profile';

import { dataToUpdate, formatGroupName, formatUnitsName } from './helper';

type renderFunction = ColumnOptions<CceeProfile>['renderAsElement'];

const renderCceeProfileName: renderFunction = (entry) => entry.name || '-';
const renderGroupName: renderFunction = (entry) => formatGroupName(entry.units);
const renderUnits: renderFunction = (entry) => formatUnitsName(entry.units);

const CceeProfilesPage: React.FC = () => {
  const { cceeProfiles, loading, deleteCceeProfileHandler } = useCceeProfiles();
  const { getCommercialGroups } = useCommercialGroups();
  const {
    messageNotificationFeedBack,
    openNotificationFeedBack,
    setNotificationResponse,
    setOpenNotificationFeedback,
  } = useNotification();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [typedSearchTerm, setTypedSearchTerm] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<CreateCceeProfilePayload | null>(null);
  const [openCreateProfile, setOpenCreateProfile] = useState(false);

  const cceeProfileTableColumns: ColumnOptions<any>[] = [
    { accessor: 'name', header: 'Perfil CCEE', renderAsElement: renderCceeProfileName },
    { accessor: 'groupName', header: 'Grupo comercial', renderAsElement: renderGroupName },
    { accessor: 'units', header: 'Unidades consumidoras', renderAsElement: renderUnits },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'PencilIcon'}
            onClick={() => {
              const dataToUpdateParsed = dataToUpdate(entry);
              setSelectedProfile(dataToUpdateParsed);
              setOpenCreateProfile(true);
              setOpenNotificationFeedback(false);
            }}
          />
        );
      },
    },
    {
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'TrashIcon'}
            onClick={() => {
              setSelectedProfile(entry);
              setOpenConfirmationModal(true);
              setOpenNotificationFeedback(false);
            }}
          />
        );
      },
    },
  ];

  const filteredCceeProfiles: CceeProfile[] =
    cceeProfiles?.length > 0
      ? cceeProfiles?.filter((cceeProfile) =>
          cceeProfile.units[0].group.name.toLowerCase().includes(typedSearchTerm.toLowerCase()),
        )
      : cceeProfiles;

  return (
    <Layout
      title="Perfis CCEE"
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Perfis CCEE',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Novo perfil CCEE',
          onClick: () => {
            setSelectedProfile(null);
            setOpenCreateProfile(true);
            getCommercialGroups();
          },
          size: 'small',
        },
      ]}
      searchBar={{ onTypedTermChangedCallback: (term) => setTypedSearchTerm(term), placeholder: 'Buscar' }}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full h-full">
        <h2 className="mb-6 font-bold text-heading-medium">Perfis CCEE vigentes</h2>
        <CceeProfileTable columns={cceeProfileTableColumns} data={filteredCceeProfiles} isFetchingProfiles={loading} />
      </div>
      <Modal
        open={openCreateProfile}
        setOpen={() => setOpenCreateProfile(!openCreateProfile)}
        title={`${selectedProfile ? 'Atualizar' : 'Criar novo'} Perfil CCEE`}
        description="Formulário de criação de perfil CCEE"
      >
        <FormUpsertCceeProfile
          dataToUpdate={selectedProfile}
          onFormSuccess={() => {
            setOpenCreateProfile(false);
            setNotificationResponse(
              CCEEProfileNotificationFeedbackContents[
                selectedProfile ? 'UPDATING_CCEE_PROFILE_SUCCESS' : 'CREATING_CCEE_PROFILE_SUCCESS'
              ],
            );
          }}
          onFormFail={() => {
            setOpenCreateProfile(false);
            setNotificationResponse(
              CCEEProfileNotificationFeedbackContents[
                selectedProfile ? 'UPDATING_CCEE_PROFILE_ERROR' : 'CREATING_CCEE_PROFILE_ERROR'
              ],
            );
          }}
        />
      </Modal>
      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar perfil CCEE"
        description="Tem certeza que deseja excluir esse perfil?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedProfile &&
              deleteCceeProfileHandler(selectedProfile.id)
                .then(() => {
                  getCommercialGroups();
                  setNotificationResponse(CCEEProfileNotificationFeedbackContents['DELETING_CCEE_PROFILE_SUCCESS']);
                  setOpenConfirmationModal(false);
                })
                .catch(() => {
                  setOpenConfirmationModal(false);
                  setNotificationResponse(CCEEProfileNotificationFeedbackContents['DELETING_CCEE_PROFILE_ERROR']);
                });
          }}
          onCancel={() => {
            setSelectedProfile(null);
            setOpenConfirmationModal(false);
          }}
        />
      </Modal>
    </Layout>
  );
};

export default CceeProfilesPage;

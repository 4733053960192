import { LazyQueryExecFunction } from '@apollo/client';

import { ComercialGroupTypeEnum } from '@contexts/commercial-group/types';
import { RolesTypes } from '@utils/translators/users-roles';

export type MigrationReportContextType = {
  getCommercialGroupsFilter: () => void;
  getCommercialGroups: () => void;
  getMigrationReports: LazyQueryExecFunction<MigrationReportGraphQlResponse, GetMutationReportsInput>;
  syncMigrationReportsBatchHandler: (payload: Array<SyncMutationReportsInput>) => Promise<boolean | undefined>;
  sendMigrationsReportsBatchHandler: (payload: Array<SendBatchMutationReportsInput>) => Promise<boolean | undefined>;
  createTaskHandler: ({ groupId, thirdPartyId }: SyncMutationReportsInput) => Promise<boolean | undefined>;
  commercialGroupsFilters: CommercialGroup[];
  commercialGroups: Omit<CommercialGroup, 'thridPartyId'>[];
  migrationReports: MigrationReportResponse;
  loading: boolean;
  mutationLoading: boolean;
};

export type SelectedFilter = {
  value: string;
  label: string;
};

export type GetMutationReportsInput = {
  page: number;
  groupName: string | null;
  thirdPartyIds?: string[] | null;
  progress?: keyof typeof ProgressEnum | null;
};

export type SyncMutationReportsInput = {
  thirdPartyId: string;
  groupId: string;
};

export type SyncMutationReportsPayload = {
  input: SyncMutationReportsInput | Array<SyncMutationReportsInput>;
};

export type SendBatchMutationReportsInput = {
  id: string;
  recipients: Array<string>;
};

export type SendMutationReportsPayload = {
  input: Array<SendBatchMutationReportsInput>;
};

export enum ManualSyncEnum {
  NONE = 'Nenhum',
  PROCESSING = 'Processando',
  PROCESSED = 'Processado',
  ERROR = 'Erro',
}

export enum ProgressEnum {
  CANCELED = 'Cancelado',
  DONE = 'Concluido',
}

export type MigrationUnit = {
  nickname: string;
  conclusionPercentage: number;
  migrationDate?: string;
  expectedMigrationDate?: string;
};

type User = {
  id: string;
  roles: Array<RolesTypes>;
  user: {
    id: string;
  };
};

export type Group = {
  id?: string;
  type: keyof typeof ComercialGroupTypeEnum;
  users: Array<User>;
};

export type MigrationReport = {
  id: string;
  group: Group;
  commercialGroup: string;
  thirdPartyId: string;
  conclusionPercentage: number;
  units: Array<MigrationUnit>;
  emails: Array<string>;
  manualSyncStatus: keyof typeof ManualSyncEnum;
};
export type MigrationReportResponse = {
  page: number;
  limit: number;
  total: number;
  data: Array<MigrationReport>;
};

export type MigrationReportGraphQlResponse = {
  getMigrationReportsByPage: MigrationReportResponse;
};

export type BatchMigrationReportGraphQlResponse = {
  [key: string]: {
    success: boolean;
  };
};

export type SendEmailReportGraphQlResponse = {
  sendBatchMigrationReport: boolean;
};

export type CommercialGroup = {
  id: string;
  name: string;
  thirdPartyId: string;
};

export type CommercialGroupOptions = Omit<CommercialGroup, 'thirdPartyId'>;

export type GroupsMigrationReportResponse = {
  getGroupsManagement: {
    thirdPartyId: string;
    group: {
      id: string;
      name: string | null;
    };
  }[];
};

export type MigrationReportNotificationType =
  | 'CREATING_TASK'
  | 'CREATE_TASK_SUCCESS'
  | 'SYNCING'
  | 'SYNC_SUCCESS'
  | 'SENDING_EMAIL'
  | 'EMAIL_SUCCESS'
  | 'NOTIFYING_CUSTOMER_AREA'
  | 'NOTIFY_CUSTOMER_AREASUCCESS'
  | 'ERROR_TO_NOTIFY_CUSTOMER_AREA'
  | 'ERROR_TO_SYNC'
  | 'ERROR_TO_SEND_EMAIL'
  | 'ERROR';

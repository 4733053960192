import React, { useState } from 'react';
import uniqby from 'lodash.uniqby';

import { useClarkeContract } from '@hooks/use-clarke-contract';
import { Attachments } from '@contexts/clarke-contract/types';

import GroupAndEnergyContractSection from '@components/atoms/contract-clarke/group-contract-section';
import ManagmentSection from '@components/atoms/contract-clarke/managment-section';
import AttachmentsSection from '@components/atoms/contract-clarke/attachments-section';

interface FormClarkeContractProps {
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  storedFiles?: Array<Attachments>;
}

const FormContainer = ({ setUploadedFiles, storedFiles }: FormClarkeContractProps): JSX.Element => {
  const {
    groups,
    getEnergyContractsHandler,
    loadingFormActions,
    energyContracts: { totalPage, energyContracts },
  } = useClarkeContract();
  const [contractPage, setContracPage] = useState<number>(0);

  React.useEffect(() => {
    if (contractPage <= totalPage) {
      getEnergyContractsHandler({ page: contractPage, term: null });
    }
  }, [contractPage, totalPage]);

  const energyContractsSorted = React.useMemo(() => {
    const contratcts = uniqby(energyContracts, 'id');
    return contratcts.sort((contractA, contractB) => (contractA.group >= contractB.group ? 1 : -1));
  }, [energyContracts]);

  const handleChangeComboBox = (serachItem: string | null) => {
    if (serachItem) getEnergyContractsHandler({ page: contractPage, term: serachItem });
  };

  return (
    <div className="gap-11 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
      <GroupAndEnergyContractSection
        loadingEnergyContracts={loadingFormActions}
        contracts={energyContractsSorted}
        groups={groups}
        handleScrollComboBox={() => setContracPage((prevPage) => prevPage + 1)}
        handleChangeComboBox={handleChangeComboBox}
      />

      <ManagmentSection />

      <AttachmentsSection setUploadedFiles={setUploadedFiles} storedFiles={storedFiles} />
    </div>
  );
};

export default FormContainer;

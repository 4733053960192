import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';
import { ClientRolesType } from '@utils/translators/users-roles';
import { MultiSelectFormField } from '@components/atoms/form';
import useGroupUsers from '@hooks/use-user-groups';
import { UserInfo } from '@contexts/user';

interface IFormCreateCategoryForUser {
  user: UserInfo | null;
  onFormSuccess: () => void;
  onFormFail: () => void;
  onCancel: () => void;
}

interface ICreateCategoryForUser {
  id: string;
  categoryRoles: string[] | null;
}

const EMPTY_ROLE: ICreateCategoryForUser = {
  id: '',
  categoryRoles: null,
};

function FormCreateCategoryForUser({ user, onFormSuccess, onFormFail, onCancel }: IFormCreateCategoryForUser) {
  const { categorizeGroupsUserRolesHandler, loadingRolesForClientUsers } = useGroupUsers();

  const methods = useForm<ICreateCategoryForUser>({
    defaultValues: user ? { id: user.id, categoryRoles: user.categoryRoles } : EMPTY_ROLE,
  });

  const userRoles = ClientRolesType.filter((role) => {
    return user ? user?.categoryRoles?.filter((userRole) => userRole === role.value) : [];
  });

  const rolesOptions = user?.categoryRoles?.length ? userRoles : ClientRolesType;

  const onSubmit: SubmitHandler<ICreateCategoryForUser> = async (values) => {
    return (
      user &&
      values.categoryRoles &&
      categorizeGroupsUserRolesHandler(user.id, values.categoryRoles)
        .then(() => {
          onFormSuccess();
        })
        .catch(() => {
          onFormFail();
        })
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 justify-between pt-4 -mb-8 h-48 overflow-clip"
      >
        <div className="overflow-visible w-full h-26">
          <div className="flex flex-col gap-2 w-full">
            <MultiSelectFormField<ICreateCategoryForUser>
              id="roles"
              inputOptions={
                rolesOptions
                  ? rolesOptions
                      .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
                      .map((rolesEntry) => ({
                        value: rolesEntry.value,
                        label: rolesEntry.label,
                      }))
                  : [{ value: '', label: '' }]
              }
              placeholder="Selecione as categorias"
              label="Categorias"
              field="categoryRoles"
              options={{ required: { value: true, message: 'Selecione uma categoria' } }}
            />
          </div>
        </div>
        <div className="flex gap-6">
          <Button
            label="Adicionar categorias"
            kind={'primary'}
            loading={loadingRolesForClientUsers}
            className="w-full"
          />
          <Button kind={'secondary'} label="Cancelar" onClick={onCancel} className="w-full" />
        </div>
      </form>
    </FormProvider>
  );
}

export default FormCreateCategoryForUser;

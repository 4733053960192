import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Layout,
  ColumnOptions,
  ButtonIcon,
  Tag,
  FeedbackNotification,
  Modal,
  Checkbox,
  Tooltip,
} from '@clarke-energia/foton';
import uniqby from 'lodash.uniqby';

import {
  EconomyReportPayload,
  GroupEconomyReportPayload,
  ReportsDataTable,
  IReportStatus,
  IHandleUpdateReportsStatus,
} from '@contexts/economy-reports/types';

import useGroupUsers from '@hooks/use-user-groups';
import useGroupEconomyReports from '@hooks/use-economy-report';
import { useNotification } from '@hooks/use-notification';
import { CustomerStatus } from '@contexts/notification/types';

import { HOME_PATH } from '@routers/constants';
import { EconomyNotificationFeedbackContents, INotificationFeedbackContent } from '@utils/constants/common';

import PageSection from '@components/molecules/general/page-section';
import ButtonsFilter from '@components/molecules/economy-report/filters-table';
import EconomyReportsTable from '@components/molecules/economy-report/table';
import EconomyReportForm from '@components/molecules/form/form-upsert-economy-report';
import ReportsList from '@components/molecules/economy-report/reports-list';
import sortedReportsByDate from './helper';

type renderFunction = ColumnOptions<ReportsDataTable>['renderAsElement'];

const renderReportDate: renderFunction = (entry) => entry.date;
const renderGroupName: renderFunction = (entry) => entry.groupName;
const renderUnit: renderFunction = (entry) => entry.unitName;
const renderEconomy: renderFunction = (entry) => entry.economy;
const renderVersion: renderFunction = (entry) => (
  <Tag label={IReportStatus[entry.status]} color={entry.status === 'FINAL' ? 'green' : 'gray'} kind="default" />
);

const EconomyReportsPage: React.FC = () => {
  const { setCommercialGroupId, group, groupUsers } = useGroupUsers();
  const {
    handleCreateNotification,
    messageNotificationFeedBack,
    openNotificationFeedBack,
    setNotificationResponse,
    setOpenNotificationFeedback,
  } = useNotification();
  const { groupId } = useParams<string>();

  const {
    loading,
    setGroupId,
    reportsTable,
    handleUpdateReportsStatus,
    handleNotifyReportsByEmail,
    initFormData,
    getGroupDataHandler,
    getEnergyContractByGroupIdHandler,
  } = useGroupEconomyReports();

  const [draftReports, setDraftReports] = useState<ReportsDataTable[] | null>(null);
  const [finalReports, setFinalReports] = useState<ReportsDataTable[] | null>(null);
  const [openPublishReportModal, setOpenPublishReportModal] = useState<boolean>(false);
  const [notificateByClientArea, setNotificateByClientArea] = useState<boolean>(false);
  const [notifyByEmail, setNotifyByEmail] = useState<boolean>(false);
  const [showFeedbackEmail, setShowFeedbackEmail] = useState<boolean>(true);
  const [feedbackMessage, setFeedbackMessage] = useState<INotificationFeedbackContent | null>(null);

  const [selectedMonthFilter, setSelectedMonthFilter] = useState<string | null>(null);
  const [openMonthFilterOptions, setOpenMonthFilterOptions] = useState<boolean>(false);

  const [selectedUnitFilter, setSelectedUnitFilter] = useState<string | null>(null);
  const [openFilterUnit, setOpenFilterUnit] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<EconomyReportPayload | GroupEconomyReportPayload | null>(null);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [reportsToNotify, setReportsToNotify] = useState<Array<ReportsDataTable>>([]);

  if (groupId) {
    setGroupId(groupId);
    setCommercialGroupId(groupId);
  }

  const months: ReportsDataTable[] = uniqby(reportsTable, 'date');
  const unitsNames: ReportsDataTable[] = uniqby(reportsTable, 'unitName');
  const formTitle = dataToUpdate ? 'Atualizar Relatório de Economia' : 'Emitir Relatório de Economia';

  const economyReportsTableColumns: ColumnOptions<ReportsDataTable>[] = [
    { accessor: 'date', header: 'Mês / Ano', renderAsElement: renderReportDate },
    { accessor: 'groupName', header: 'Grupo comercial', renderAsElement: renderGroupName },
    { accessor: 'unitName', header: 'Unidade', renderAsElement: renderUnit },
    { accessor: 'economy', header: 'Economia', renderAsElement: renderEconomy },
    { accessor: 'status', header: 'Status', renderAsElement: renderVersion },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            icon="PencilIcon"
            kind="ghost"
            onClick={() => {
              setShowForm(true);
              setDataToUpdate(entry.formDataToUpdate);
            }}
          />
        );
      },
    },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <Link
            to={entry.unitName === 'Geral' ? `/relatorio-grupo/${entry.id}` : `/relatorio-unidade/${entry.id}`}
            target="_blank"
          >
            <ButtonIcon icon="EyeIcon" kind="ghost" />
          </Link>
        );
      },
    },
  ];

  const filteredReports = (): ReportsDataTable[] => {
    const allFiltersSelected = selectedMonthFilter && selectedUnitFilter ? true : false;

    if (reportsTable?.length > 0 && selectedUnitFilter && !allFiltersSelected) {
      return reportsTable?.filter(({ unitName }) => unitName === selectedUnitFilter);
    }
    if (reportsTable?.length > 0 && selectedMonthFilter && !allFiltersSelected) {
      return reportsTable?.filter(({ date }) => date === selectedMonthFilter);
    }

    if (reportsTable?.length > 0 && allFiltersSelected) {
      return reportsTable?.filter(
        ({ unitName, date }) => unitName === selectedUnitFilter && date === selectedMonthFilter,
      );
    }

    return reportsTable;
  };

  const handleSendReportStatus = (selectedReports: ReportsDataTable[]) => {
    setOpenNotificationFeedback(true);
    setNotificationResponse(EconomyNotificationFeedbackContents.UPDATING_ECONOMY_REPORTS_STATUS);

    const targetUsersIds = groupUsers.map((groupUser) => groupUser.id);
    const moment: CustomerStatus =
      finalReports && finalReports.length > 0 ? 'MONTHLY_ECONOMY_REPORT' : 'FIRST_ECONOMY_REPORT';

    const parsedDraftReportsListToUpdate: IHandleUpdateReportsStatus['reports'] = selectedReports.map((report) => ({
      reportId: report.id,
      reportType: report.unitName.toLowerCase().includes('geral') ? 'GROUP' : 'UNIT',
    }));

    handleUpdateReportsStatus({ reports: parsedDraftReportsListToUpdate, status: 'FINAL' })
      .then((response) => {
        if (notificateByClientArea) handleCreateNotification(moment, targetUsersIds);
        setNotificationResponse(
          response
            ? EconomyNotificationFeedbackContents.SUCCESS_ON_UPDATE_ECONOMY_REPORTS_STATUS
            : EconomyNotificationFeedbackContents.ERROR_ON_UPDATE_ECONOMY_REPORTS_STATUS,
        );
      })
      .catch((e) => {
        setNotificationResponse(EconomyNotificationFeedbackContents.ERROR_ON_UPDATE_ECONOMY_REPORTS_STATUS);
      });
    setReportsToNotify([]);
  };

  const hasGeneralReport = draftReports ? draftReports?.some(({ unitName }) => unitName === 'Geral') : false;

  const handleNotifyByEmail = async (reportId: string) => {
    setShowFeedbackEmail(true);
    setFeedbackMessage(EconomyNotificationFeedbackContents.NOTIFYING_ECONOMY_REPORT);

    const groupEmails = groupUsers
      .filter((user) => user.categoryRoles && user.categoryRoles.includes('MAIN_CONTACT'))
      .map((user) => user.email);
    const groupInfo = { name: group?.name || '', emails: groupEmails };

    const response = await handleNotifyReportsByEmail({ group: groupInfo, reportId }, hasGeneralReport);

    if (response) {
      setFeedbackMessage(EconomyNotificationFeedbackContents.SUCCESS_ON_NOTIFY_ECONOMY_REPORT);
    } else {
      setFeedbackMessage(EconomyNotificationFeedbackContents.ERROR_ON_NOTIFYING_ECONOMY_REPORT);
    }
    setReportsToNotify([]);
  };

  const handlePublishReportsButtonClick = () => {
    const finalReportsList = reportsTable.filter((report) => report.status === 'FINAL');
    const draftReportsList = reportsTable.filter((report) => report.status === 'DRAFT');
    setFinalReports(finalReportsList);
    setDraftReports(draftReportsList);

    if (draftReportsList && draftReportsList?.length > 0) {
      setOpenPublishReportModal(true);
    } else {
      setOpenNotificationFeedback(true);
      setNotificationResponse(EconomyNotificationFeedbackContents.NO_ECONOMY_REPORTS_TO_SEND);
    }
  };

  const handlePublishReportModalButtonClick = () => {
    setOpenPublishReportModal(false);
    if (!reportsToNotify.length) return;
    const reportSorted = sortedReportsByDate(reportsToNotify);
    const reportId = reportSorted[0].id;

    handleSendReportStatus(reportSorted);

    if (notifyByEmail && group) {
      handleNotifyByEmail(reportId);
    }
  };

  const enableNotificationByEmail = (): boolean => {
    if (group?.units) {
      const reportWithOneUnit = group.units.length === 1 && !hasGeneralReport;
      const generalReport = group.units.length > 1 && hasGeneralReport;

      if (reportWithOneUnit || generalReport) return true;
    }
    return false;
  };

  useEffect(() => {
    const shouldNotifyByEmail = enableNotificationByEmail();
    setNotifyByEmail(shouldNotifyByEmail);
  }, [group, draftReports]);

  const disableNotifyClientAreaCheckBox = useMemo(() => {
    if (draftReports && draftReports.length === 0) return true;
    return false;
  }, [draftReports, notificateByClientArea]);

  useEffect(() => {
    if (groupId) {
      getGroupDataHandler(groupId);
      getEnergyContractByGroupIdHandler(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (clearForm) {
      setClearForm(false);
    }
  }, [clearForm]);

  return (
    <Layout
      loading={showForm ? false : loading}
      title={showForm ? formTitle : 'Relatórios de economia'}
      navigationHistoryLinks={[
        { label: 'Início', url: HOME_PATH },
        { label: group?.name || '', url: './' },
        { label: 'Relatórios de economia', url: '' },
      ]}
      headerButtons={
        showForm
          ? [
              {
                kind: 'secondary',
                label: 'Limpar campos',
                size: 'small',
                shape: 'square',
                onClick: () => setClearForm(true),
              },
            ]
          : [
              {
                kind: 'secondary',
                label: 'Publicar relatórios',
                size: 'small',
                onClick: () => handlePublishReportsButtonClick(),
              },
              {
                kind: 'primary',
                label: 'Novo Relatório de economia',
                size: 'small',
                onClick: () => {
                  setShowForm(true);
                  setDataToUpdate(null);
                },
              },
            ]
      }
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>

      <div className="fixed right-0 bottom-10 my-6 mx-6 max-w-full lg:w-fit">
        {feedbackMessage && showFeedbackEmail && (
          <FeedbackNotification
            label={feedbackMessage.label}
            message={feedbackMessage.message}
            kind={feedbackMessage.kind}
            onCloseNotification={() => setShowFeedbackEmail(false)}
          />
        )}
      </div>

      <Modal
        title={`Publicar relatórios de ${group?.name}`}
        description="Ao publicar, os relatórios marcados como rascunho estarão disponíveis para o cliente na área do cliente. Você pode escolher como notificá-lo abaixo."
        open={openPublishReportModal}
        setOpen={() => setOpenPublishReportModal(!openPublishReportModal)}
        modalButtons={{
          primary: {
            kind: 'primary',
            label: 'Publicar',
            disabled: !reportsToNotify.length,
            onClick: () => handlePublishReportModalButtonClick(),
          },
          secondary: {
            kind: 'secondary',
            label: 'Cancelar',
            onClick: () => setOpenPublishReportModal(false),
          },
        }}
      >
        {draftReports && (
          <ReportsList
            reports={draftReports}
            reportsToNotify={reportsToNotify}
            setReportsToNotify={setReportsToNotify}
          />
        )}
        <div className="mb-4 text-paragraph-medium">
          O cliente receberá somente um email. A notificação será somente referente ao relatório do mês mais recente
          selecionado.
        </div>
        <div className="flex flex-col gap-4">
          <Checkbox
            label={'Notificar via Área do Cliente'}
            disabled={disableNotifyClientAreaCheckBox}
            checked={notificateByClientArea}
            onChange={() => setNotificateByClientArea(!notificateByClientArea)}
          />
          <div className="flex gap-2">
            <Checkbox
              label={'Notificar via email'}
              disabled={!enableNotificationByEmail()}
              checked={notifyByEmail}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                setNotifyByEmail(target.checked);
              }}
            />
            <Tooltip content="Caso seja emitido relatórios para o grupo, a opção de notificar via email ficará disponivel apos a geração do mesmo." />
          </div>
        </div>
      </Modal>
      <div className="hidden absolute right-0 w-full md:block top-[100px]" />
      {showForm ? (
        <EconomyReportForm
          initDataForm={dataToUpdate ?? initFormData}
          setShowForm={setShowForm}
          closeFormAction={() => {
            setShowForm(false);
            setClearForm(false);
          }}
          shouldResetForm={clearForm}
          onFormSuccess={() => {
            setClearForm(false);
            setShowForm(false);
            setNotificationResponse(
              EconomyNotificationFeedbackContents[dataToUpdate ? 'UPDATING_ECONOMY_REPORT' : 'CREATING_ECONOMY_REPORT'],
            );
          }}
          onFormFail={() => {
            setClearForm(false);
            setShowForm(false);
            setNotificationResponse(
              EconomyNotificationFeedbackContents[
                dataToUpdate ? 'UPDATING_ECONOMY_REPORT_ERROR' : 'CREATING_ECONOMY_REPORT_ERROR'
              ],
            );
          }}
        />
      ) : (
        <>
          <PageSection title={group?.name ? `Relatórios de ${group?.name}` : ''} className="font-bold -mt-[20px]">
            <>
              <ButtonsFilter
                clearFilter={() => {
                  setSelectedMonthFilter(null);
                  setSelectedUnitFilter(null);
                }}
                filters={[
                  {
                    label: selectedMonthFilter ?? 'Mês',
                    filterSelected: selectedMonthFilter ? true : false,
                    options: months.map(({ date }) => date),
                    openOptions: () => setOpenMonthFilterOptions(!openMonthFilterOptions),
                    onClick: (option: string) => {
                      setSelectedMonthFilter(option);
                      setOpenMonthFilterOptions(false);
                    },
                    showOptions: openMonthFilterOptions,
                  },
                  {
                    label: selectedUnitFilter ?? 'Unidade',
                    options: unitsNames.map(({ unitName }) => unitName),
                    filterSelected: selectedUnitFilter ? true : false,
                    openOptions: () => setOpenFilterUnit(!openFilterUnit),
                    onClick: (option: string) => {
                      setSelectedUnitFilter(option);
                      setOpenFilterUnit(false);
                    },
                    showOptions: openFilterUnit,
                  },
                ]}
              />
              <EconomyReportsTable
                columns={economyReportsTableColumns}
                data={filteredReports()}
                isFetchingReports={loading}
              />
            </>
          </PageSection>
        </>
      )}
    </Layout>
  );
};

export default EconomyReportsPage;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface SectionProps {
  title: string;
  sectionNumber: string;
  isLoading: boolean;
}

const SectionSkeleton = () => (
  <>
    <p className="mb-1 text-caption-medium lg:text-paragraph-small">
      <Skeleton width={20} />
    </p>
    <p className="font-black leading-6 lg:leading-7 text-heading-xsmall lg:text-heading-medium" data-cy="section-name">
      <Skeleton width={300} />
    </p>
  </>
);

const Section: React.FC<SectionProps> = ({ title, sectionNumber, isLoading }) => {
  return (
    <div className="pt-2 pb-6 border-t-2 border-black lg:pt-4 lg:pb-12">
      {isLoading ? (
        <SectionSkeleton />
      ) : (
        <>
          <p className="mb-1 text-caption-medium lg:text-paragraph-small">{sectionNumber}</p>
          <p
            className="font-black leading-6 lg:leading-7 text-heading-xsmall lg:text-heading-medium"
            data-cy="section-name"
          >
            {title}
          </p>
        </>
      )}
    </div>
  );
};

export default Section;

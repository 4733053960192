import { NumericFormField } from '@components/atoms/form';

import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { IManagementContractPayload } from '@contexts/management-contract/types';

function ContractFormRemunerationSection() {
  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection title="Remuneração" subtitle="Dados sobre a gestão da Clarke." />

        <div className="grid grid-cols-2 col-span-1 gap-6">
          <NumericFormField<IManagementContractPayload>
            id="inputs.payment.managementPricePerUnit"
            field="inputs.payment.managementPricePerUnit"
            label="Custo de Gestão por unidade"
            formatProps={{
              prefix: 'R$ ',
              decimalScale: 2,
              fixedDecimalScale: true,
              allowNegative: false,
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              placeholder: 'Digite aqui',
            }}
          />
          <NumericFormField<IManagementContractPayload>
            id="inputs.payment.totalMigrationPrice"
            field="inputs.payment.totalMigrationPrice"
            label="Custo de Migração total"
            formatProps={{
              prefix: 'R$ ',
              decimalScale: 2,
              fixedDecimalScale: true,
              allowNegative: false,
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              placeholder: 'Digite aqui',
            }}
          />
        </div>
      </>
    </ContractSectionContainer>
  );
}

export default ContractFormRemunerationSection;

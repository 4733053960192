import ContractAdditionalServicesSection from '@components/molecules/contracts/sections/contract-additional-services-section';
import ContractDocumentsSection from '@components/molecules/contracts/sections/contract-documents-section';
import ContractFormContractBondSection from '@components/molecules/contracts/sections/contract-form-contract-bond-section';
import ContractFormContractTypeSection from '@components/molecules/contracts/sections/contract-form-contract-type-section';
import ContractFormUnitsSection from '@components/molecules/contracts/sections/contract-form-units-section';
import React from 'react';
import { IManagementContractProduct } from '@contexts/management-contract/types';
import ContractFormRemunerationSection from '@components/molecules/contracts/sections/contract-remuneration-section';
import ContractFormForumSection from '@components/molecules/contracts/sections/contract-form-forum-section';
import ContractFormProcurationsSection from '@components/molecules/contracts/sections/contract-form-customers-section';
interface IContractFormCore {
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  contractProduct: IManagementContractProduct;
}

const ContractFormCore = ({ formRef, contractProduct }: IContractFormCore) => {
  return (
    <form ref={formRef} className="flex flex-col flex-wrap gap-3 justify-start w-full">
      <ContractFormContractTypeSection contractProduct={contractProduct} />
      <ContractFormContractBondSection />
      <ContractFormUnitsSection />
      <ContractFormRemunerationSection />
      <ContractFormForumSection />
      <ContractFormProcurationsSection />
      <ContractAdditionalServicesSection />
      <ContractDocumentsSection />
    </form>
  );
};

export default ContractFormCore;

import { ContractType } from '@contexts/ccee-contract/types';

export const getKeyContractType = (value: ContractType): keyof typeof ContractType => {
  const keyIndex = Object.values(ContractType).indexOf(value);

  return Object.keys(ContractType)[keyIndex] as keyof typeof ContractType;
};

export const mockedListGroup = [
  {
    label: 'GROUP 1',
    value: 'value',
  },
];

import React from 'react';
import { ButtonIcon, OverflownMenu } from '@clarke-energia/foton';

import { EnergyAuditOverflowMenuOptions } from '@pages/energy-audit/static-data';
import { IconName } from '../hero-icon';

interface IEnergyAuditMoreOptionsTableButton {
  onClick: (moreIconName: IconName | null) => void;
  iconName: 'EllipsisVerticalIcon' | 'EyeIcon';
}

const EnergyAuditMoreOptionsTableButton = ({ onClick, iconName }: IEnergyAuditMoreOptionsTableButton) => {
  const [openMoreOptionsPopUp, setOpenMoreOptionsPopUp] = React.useState<boolean>(false);
  const moreOptionsPopUpRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        ref === moreOptionsPopUpRef && setOpenMoreOptionsPopUp(false);
      }
    };

    document.addEventListener('click', (event) => handleOutsideClick(event, moreOptionsPopUpRef));
    return () => {
      document.removeEventListener('click', (event) => handleOutsideClick(event, moreOptionsPopUpRef));
    };
  }, [moreOptionsPopUpRef]);

  return (
    <div ref={moreOptionsPopUpRef} className="relative">
      <ButtonIcon
        kind={'ghost'}
        icon={iconName}
        onClick={() => {
          if (iconName === 'EllipsisVerticalIcon') {
            setOpenMoreOptionsPopUp(true);
          } else {
            setOpenMoreOptionsPopUp(false);
            onClick(null);
          }
        }}
      />
      {openMoreOptionsPopUp && (
        <div
          className="flex absolute right-0 top-8 z-10 flex-col gap-2 h-10 bg-neutral-10 w-[10rem]"
          onClick={() => {
            setOpenMoreOptionsPopUp(false);
          }}
        >
          {EnergyAuditOverflowMenuOptions.map((item) => (
            <OverflownMenu
              key={item.id}
              className={`w-full ${item.specificClassName}`}
              extraClassNames={item.iconClassName}
              icon={item.icon}
              label={item.label}
              onClick={() => {
                onClick(item.icon as IconName);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EnergyAuditMoreOptionsTableButton;

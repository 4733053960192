import React from 'react';
import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { File } from './types';
import { GET_GROUP_QUERY, QUERY_GET_GROUP_FILES } from './queries';
import { MUTATION_INSERT_FILE_PATH } from './mutations';
import { uploadFile } from '@services/filesApi';
import { AxiosPromise } from 'axios';
import { UpsertFileForm } from '@components/molecules/form/form-upsert-document';
import { useAuth } from '@src/ApolloWrapper';

export interface FilesContextType {
  setGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  insertFilePathHandler: (
    fileId: string,
    filePath: string,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  getGroupFilesHandler: () => void;
  uploadFileHandler: (file: UpsertFileForm) => AxiosPromise<any>;
  groupFiles: File[];
  groupName: string;
  isFetchingFiles: boolean;
}

export const FilesContext = createContext<FilesContextType>({} as FilesContextType);

export const FilesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const [groupId, setGroupId] = React.useState<string>();
  const [groupName, setGroupName] = React.useState<string>('');
  const [groupFiles, setGroupFiles] = React.useState<File[]>([]);
  const [GetGroupFiles, { loading: loadingGetGroupFiles }] = useLazyQuery(QUERY_GET_GROUP_FILES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const parsedFiles = data.files.map((file: File) => {
        if (!file.filePath) {
          return {
            ...file,
            filePath: `documentos/Outros/${file.id}.uuid`,
          };
        } else {
          return file;
        }
      });

      setGroupFiles(parsedFiles);
    },
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  const [InsertFilePath, { loading: loadingInsertFilePath }] = useMutation(MUTATION_INSERT_FILE_PATH, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  const [getGroup, { loading: loadingGetGroup }] = useLazyQuery(GET_GROUP_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setGroupName(data.group.name),
    onError: () => {
      setGroupName('Documentos');
    },
    variables: {
      groupId,
    },
  });

  function uploadFileHandler(file: UpsertFileForm) {
    return uploadFile(file, accessToken);
  }

  function getGroupFilesHandler() {
    GetGroupFiles({
      variables: {
        commercialGroupId: groupId,
      },
    });
  }

  function insertFilePathHandler(fileId: string, filePath: string) {
    return InsertFilePath({
      variables: {
        input: {
          id: fileId,
          filePath,
        },
      },
    });
  }

  React.useEffect(() => {
    if (groupId) {
      getGroupFilesHandler();
      getGroup();
    }
  }, [groupId]);

  return (
    <FilesContext.Provider
      value={{
        setGroupId,
        insertFilePathHandler,
        getGroupFilesHandler,
        uploadFileHandler,
        groupFiles,
        groupName,
        isFetchingFiles: loadingInsertFilePath || loadingGetGroupFiles || loadingGetGroup,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

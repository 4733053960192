import React, { useEffect, useState } from 'react';

import { ButtonProps, FeedbackNotification, Layout } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';
import { MigrationReport, ProgressEnum, SelectedFilter } from '@contexts/migration-report/types';
import { useMigrationReport } from '@hooks/use-migration-report';
import { useNotification } from '@hooks/use-notification';

import {
  parseGroupsOptions,
  parsePayloadSendBatch,
  parsePayloadSyncBatch,
  parseSelectedGroupsFilter,
} from '@contexts/migration-report/parser';

import { migrationReportFeedbackContent } from '@components/molecules/migration-progress/helper';
import MigrationProgressTable from '@components/molecules/migration-progress/expanded-table';
import MigrationModal from '@components/molecules/migration-progress/modal';
import { ModalType } from '../commercial-groups/helper';

const MigrationReports: React.FC = () => {
  const {
    getCommercialGroups,
    getCommercialGroupsFilter,
    getMigrationReports,
    syncMigrationReportsBatchHandler,
    sendMigrationsReportsBatchHandler,
    loading,
    mutationLoading,
    migrationReports,
    commercialGroupsFilters,
    commercialGroups,
  } = useMigrationReport();
  const {
    messageNotificationFeedBack,
    openNotificationFeedBack,
    setOpenNotificationFeedback,
    setNotificationResponse,
    handleCreateNotification,
  } = useNotification();

  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [selectedItensTable, setSelectedItensTable] = React.useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<MigrationReport[]>([]);
  const [notificationCheckbox, setNotificationCheckbox] = useState({
    email: true,
    customerArea: false,
  });
  const [filterGroups, setFilterGroups] = useState<SelectedFilter[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<SelectedFilter | null>(null);
  const [showFilterOptions, setShowFilterOptions] = useState<'GROUP' | 'STATUS' | null>(null);

  useEffect(() => {
    getCommercialGroups();
    getCommercialGroupsFilter();
  }, []);

  useEffect(() => {
    if (filterGroups.length || selectedStatus) {
      setPage(1);
    }
  }, [selectedStatus, filterGroups]);

  useEffect(() => {
    const thirdPartyIdsFilters = filterGroups.length ? parseSelectedGroupsFilter(filterGroups) : null;

    getMigrationReports({
      variables: {
        groupName: typedSearchTerm,
        page,
        thirdPartyIds: thirdPartyIdsFilters,
        progress: selectedStatus?.value as keyof typeof ProgressEnum,
      },
    });
  }, [page, typedSearchTerm, selectedStatus, filterGroups]);

  const headerButtons: Array<ButtonProps> = [
    {
      kind: 'primary',
      label: 'Vincular Task',
      size: 'small',
      onClick: () => setModalType('CREATE_TASK'),
    },
    {
      kind: 'secondary',
      label: 'Sincronizar e Atualizar',
      size: 'small',
      disabled: !selectedGroups.length,
      onClick: () => setModalType('SYNC_AND_NOTIFY'),
    },
  ];

  const handleConfirmationModal = async () => {
    if (modalType === 'SYNC_AND_NOTIFY') {
      try {
        setNotificationResponse(migrationReportFeedbackContent.SYNCING);
        setModalType(null);

        const parsedPayload = parsePayloadSyncBatch(selectedGroups);
        const response = await syncMigrationReportsBatchHandler(parsedPayload);
        const message = response
          ? migrationReportFeedbackContent.SYNC_SUCCESS
          : migrationReportFeedbackContent.ERROR_TO_SYNC;
        setNotificationResponse(message);
      } catch (error) {
        setModalType(null);
        setSelectedGroups([]);
        setNotificationResponse(migrationReportFeedbackContent.ERROR_TO_SYNC);
      }

      try {
        if (notificationCheckbox.email) {
          setNotificationResponse(migrationReportFeedbackContent.SENDING_EMAIL);
          const parsedEmailPayload = parsePayloadSendBatch(selectedGroups);
          const emailNotificationResponse = await sendMigrationsReportsBatchHandler(parsedEmailPayload);
          const message = emailNotificationResponse
            ? migrationReportFeedbackContent.EMAIL_SUCCESS
            : migrationReportFeedbackContent.ERROR_TO_SEND_EMAIL;
          setNotificationResponse(message);
        }
      } catch (error) {
        setModalType(null);
        setSelectedGroups([]);
        setNotificationResponse(migrationReportFeedbackContent.ERROR_TO_SEND_EMAIL);
      }

      try {
        if (notificationCheckbox.customerArea) {
          const targetUsersIds = selectedGroups.flatMap(({ group }) => group.users.map(({ id }) => id));
          await handleCreateNotification('MIGRATION_IN_PROGRESS', targetUsersIds);
        }
      } catch (error) {
        setModalType(null);
        setSelectedGroups([]);
        setNotificationResponse(migrationReportFeedbackContent.ERROR_TO_NOTIFY_CUSTOMER_AREA);
      }

      setShowFilterOptions(null);
      setFilterGroups([]);
      setSelectedGroups([]);
      setSelectedItensTable([]);
    }
    setModalType(null);

    return;
  };

  const handleContenModal = () => {
    if (modalType === 'CREATE_TASK') {
      return { closeAction: setModalType, options: parseGroupsOptions(commercialGroups) };
    }

    return {
      selectedGroups,
      selectBoxes: [
        {
          label: 'Notificar via Área do Cliente',
          checked: notificationCheckbox.customerArea,
          onChange: () => setNotificationCheckbox((prev) => ({ ...prev, customerArea: !prev.customerArea })),
        },
        {
          label: 'Notificar por email',
          checked: notificationCheckbox.email,
          onChange: () => setNotificationCheckbox((prev) => ({ ...prev, email: !prev.email })),
        },
      ],
    };
  };

  const selectedGroupLabel = (selectedGroups: SelectedFilter[]) => {
    if (selectedGroups.length === 1) {
      return selectedGroups[0].label;
    }

    if (selectedGroups.length > 1) {
      const firstGroup = selectedGroups[0].label;
      const secondGroup = selectedGroups[1].label.slice(0, 3);
      return `${firstGroup}, ${secondGroup}...`;
    }

    return 'Grupo comercial';
  };

  const handleFilterVisibility = (filterType: 'GROUP' | 'STATUS') => {
    if (showFilterOptions === filterType) return setShowFilterOptions(null);
    setShowFilterOptions(filterType);
  };

  const groupFilter = React.useMemo(() => {
    const options = commercialGroupsFilters.length
      ? commercialGroupsFilters
          .filter(({ name }) => name)
          .map(({ thirdPartyId, name }) => ({ value: thirdPartyId, label: name ?? '' }))
      : [];

    return {
      options,
      label: selectedGroupLabel(filterGroups),
      selectedFilter: filterGroups.length,
      setSelectedFilter: setFilterGroups,
      handleFilterVisibility: () => handleFilterVisibility('GROUP'),
      showOptions: showFilterOptions === 'GROUP',
      isMultiSelect: true,
    };
  }, [showFilterOptions, filterGroups]);

  const statusFilter = React.useMemo(() => {
    const label = selectedStatus?.label ?? 'Status';
    const options = Object.keys(ProgressEnum).map((key) => ({
      label: ProgressEnum[key as keyof typeof ProgressEnum],
      value: key,
    }));

    return {
      label,
      options,
      selectedFilter: selectedStatus,
      setSelectedFilter: setSelectedStatus,
      handleFilterVisibility: () => handleFilterVisibility('STATUS'),
      showOptions: showFilterOptions === 'STATUS',
    };
  }, [showFilterOptions, selectedStatus]);

  const handleSelectRow = (groupName: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedItensTable((prev) => [...prev, groupName]);
      const selectedGroup = migrationReports.data.find(({ commercialGroup }) => commercialGroup === groupName);
      if (selectedGroup) {
        setSelectedGroups((prevSelectedGroups) => [...prevSelectedGroups, selectedGroup]);
      }
    } else {
      const groupsFiltered = selectedItensTable.filter((name) => name !== groupName);
      setSelectedItensTable(groupsFiltered);

      const removeNonCheckSelectedGroup = selectedGroups.filter(({ commercialGroup }) => commercialGroup !== groupName);
      setSelectedGroups(removeNonCheckSelectedGroup);
    }
  };

  return (
    <Layout
      title="Relatórios de Migração"
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Relatórios de Migração',
          url: '',
        },
      ]}
      headerButtons={headerButtons}
      searchBar={{
        label: '',
        placeholder: 'Filtrar por nome do grupo',
        onTypedTermChangedCallback: () => {
          return;
        },
        onBlur: (event) => setTypedSearchTerm(event.target.value),
      }}
      className="relative"
      darkerBackground
    >
      <div className="col-span-full h-full">
        <MigrationProgressTable
          loading={loading}
          pageInfo={{
            total: migrationReports.total,
            itemPerpage: migrationReports.limit,
            currentPage: page,
            setCurrentPage: setPage,
          }}
          filters={{
            btnsFilters: [groupFilter, statusFilter],
            clearOptions: () => {
              setFilterGroups([]);
              setSelectedStatus(null);
              setShowFilterOptions(null);
            },
          }}
          data={migrationReports.data}
          onSelectRow={handleSelectRow}
          selectedTableRow={selectedItensTable}
        />
      </div>
      <MigrationModal
        type={modalType}
        cancelOnClick={() => setModalType(null)}
        confirmationOnClick={handleConfirmationModal}
        content={handleContenModal()}
      />
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() =>
              mutationLoading ? setOpenNotificationFeedback(true) : setOpenNotificationFeedback(false)
            }
            timeToCloseAutomaticallyInSeconds={mutationLoading ? undefined : 5}
          />
        )}
      </div>
    </Layout>
  );
};

export default MigrationReports;

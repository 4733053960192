import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ColumnOptions, Pagination, Table, Tag } from '@clarke-energia/foton';

import { EnergyAuditStatus, EnergyAudits } from '@contexts/energy-audit';
import useEnergyAudits from '@hooks/use-energy-audit';
import useGroupUsers from '@hooks/use-user-groups';
import useTraderUsers from '@hooks/use-user-trader';
import { formatAsMWh, formatUuid } from '@utils/text';

import EnergyAuditMoreOptionsTableButton from '@components/atoms/energetic-balances/energetic-balance-more-options-table-button';
import { EnergyAuditStatusTagPropsSelector } from '@pages/energy-audit/static-data';
import { IconName } from '@components/atoms/hero-icon';

interface IEnergyAuditsTable {
  energyAuditsData: Array<EnergyAudits>;
  isFetchingEnergyAudits?: boolean;
  totalEnergyAuditsCount: number;
  energyAuditsByPage?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setOpenEmailDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}
type renderFunction = ColumnOptions<EnergyAudits>['renderAsElement'];

const EnergyAuditsTable: React.FC<IEnergyAuditsTable> = ({
  energyAuditsData,
  isFetchingEnergyAudits,
  totalEnergyAuditsCount,
  energyAuditsByPage,
  currentPage,
  setCurrentPage,
  setOpenEmailDrawer,
}: IEnergyAuditsTable) => {
  const { setCommercialGroupId } = useGroupUsers();
  const { setTraderId } = useTraderUsers();
  const { getEmailDataHandler } = useEnergyAudits();
  const navigate = useNavigate();

  const renderGroupNameAndContract: renderFunction = (entry) =>
    `${entry.group.name} (${formatUuid(entry.energyContract.id)})`;
  const renderConsumptionTotal: renderFunction = (entry) =>
    entry.status === EnergyAuditStatus.NOT_READY_TO_SEND
      ? '-'
      : formatAsMWh(entry.consumptionTotal ? entry.consumptionTotal : 0);
  const renderNeededTotal: renderFunction = (entry) =>
    entry.status === EnergyAuditStatus.NOT_READY_TO_SEND ? '-' : formatAsMWh(entry.neededTotal ? entry.neededTotal : 0);
  const renderOverPlusOrExposureTotal: renderFunction = (entry) =>
    entry.status === EnergyAuditStatus.NOT_READY_TO_SEND
      ? '-'
      : formatAsMWh(entry.overplusOrExposureTotal ? entry.overplusOrExposureTotal : 0);
  const renderStatus: renderFunction = (entry) => {
    const uncompletedStatusLabel =
      entry.status === EnergyAuditStatus.NOT_READY_TO_SEND
        ? `${entry.numberOfConcludedMeasurements}/${entry.totalNumberOfMeasurements} Medições Completas`
        : undefined;
    const tagProps = entry.status
      ? EnergyAuditStatusTagPropsSelector[entry.status]
      : EnergyAuditStatusTagPropsSelector['NOT_READY_TO_SEND'];
    return (
      <Tag
        color={tagProps.color}
        kind={'default'}
        label={uncompletedStatusLabel ? uncompletedStatusLabel : tagProps.label}
      />
    );
  };
  const renderActionIcon: renderFunction = (entry) => {
    return (
      <EnergyAuditMoreOptionsTableButton
        onClick={(moreOptionIcon: IconName | null) => {
          if (entry.status === EnergyAuditStatus.READY_TO_SEND) {
            if (moreOptionIcon === 'EyeIcon') {
              navigate(`/balanco-energetico/${entry.id}`);
              return;
            }

            getEmailDataHandler(entry.id);
            setCommercialGroupId(entry.group.id);
            if (entry.energyContract.trader) {
              setTraderId(entry.energyContract?.trader.id);
            }

            setOpenEmailDrawer(true);
          } else {
            navigate(`/balanco-energetico/${entry.id}`);
          }
        }}
        iconName={entry.status === EnergyAuditStatus.READY_TO_SEND ? 'EllipsisVerticalIcon' : 'EyeIcon'}
      />
    );
  };

  const energyAuditsColumns: Array<ColumnOptions<EnergyAudits>> = [
    {
      accessor: 'group',
      header: 'Grupo / Contrato',
      renderAsElement: renderGroupNameAndContract,
    },
    {
      accessor: 'consumptionTotal',
      header: 'Consumo',
      renderAsElement: renderConsumptionTotal,
    },
    {
      accessor: 'neededTotal',
      header: 'Necessidade',
      renderAsElement: renderNeededTotal,
    },
    {
      accessor: 'overplusOrExposureTotal',
      header: 'Sobra / Exposição',
      renderAsElement: renderOverPlusOrExposureTotal,
    },
    {
      accessor: 'status',
      header: 'Status',
      sortableColumn: true,
      renderAsElement: renderStatus,
      fixedWidthInRem: 9.5,
    },
    {
      renderAsElement: renderActionIcon,
      fixedWidthInRem: 3,
    },
  ];

  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-auto">
        {isFetchingEnergyAudits ? (
          <div className="flex flex-col space-y-5">
            {Array(3)
              .fill({ w: '100%', h: '100%' })
              .map((skeleton, index) => (
                <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
              ))}
          </div>
        ) : (
          energyAuditsData && (
            <div>
              <Table<EnergyAudits> className="border-top" data={energyAuditsData} tableColumns={energyAuditsColumns} />
              {totalEnergyAuditsCount >= 10 && (
                <Pagination
                  totalRows={totalEnergyAuditsCount as number}
                  rowsByPage={energyAuditsByPage as number}
                  currentPage={currentPage as number}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EnergyAuditsTable;

import { useContextSelector } from 'use-context-selector';
import { ClarkeContractContextType, ClarkeContractContext } from '@contexts/clarke-contract';
export const useClarkeContract = (): ClarkeContractContextType => {
  const getClarkeContractsHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.getClarkeContractsHandler,
  );
  const getClarkeContractByIdHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.getClarkeContractByIdHandler,
  );
  const getGroupsFilterHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.getGroupsFilterHandler,
  );
  const getGroupsListHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.getGroupsListHandler,
  );
  const getEnergyContractsHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.getEnergyContractsHandler,
  );

  const createClarkeContractHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.createClarkeContractHandler,
  );
  const updateClarkeContractHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.updateClarkeContractHandler,
  );
  const deleteClarkeContractHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.deleteClarkeContractHandler,
  );
  const createClarkeContractAttachmentsHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.createClarkeContractAttachmentsHandler,
  );

  const deleteAttachmentClarkeContractHandler = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.deleteAttachmentClarkeContractHandler,
  );

  const energyContracts = useContextSelector(ClarkeContractContext, (clarkeContract) => clarkeContract.energyContracts);
  const groups = useContextSelector(ClarkeContractContext, (clarkeContract) => clarkeContract.groups);
  const clarkeContracts = useContextSelector(ClarkeContractContext, (clarkeContract) => clarkeContract.clarkeContracts);
  const groupsFilter = useContextSelector(ClarkeContractContext, (clarkeContract) => clarkeContract.groupsFilter);
  const loading = useContextSelector(ClarkeContractContext, (clarkeContract) => clarkeContract.loading);
  const loadingFormActions = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.loadingFormActions,
  );
  const contractToUpdate = useContextSelector(
    ClarkeContractContext,
    (clarkeContract) => clarkeContract.contractToUpdate,
  );

  return {
    loading,
    loadingFormActions,
    energyContracts,
    groups,
    clarkeContracts,
    groupsFilter,
    contractToUpdate,
    getGroupsListHandler,
    getEnergyContractsHandler,
    getClarkeContractsHandler,
    getClarkeContractByIdHandler,
    getGroupsFilterHandler,
    createClarkeContractHandler,
    deleteClarkeContractHandler,
    updateClarkeContractHandler,
    createClarkeContractAttachmentsHandler,
    deleteAttachmentClarkeContractHandler,
  };
};

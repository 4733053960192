import React, { useState } from 'react';
import { FeedbackNotification, Layout } from '@clarke-energia/foton';

import useFinancialEvents from '@hooks/use-financial-events';

import { FinancialEventType } from '@contexts/financial-events';
import CreateFinancialEventBatchCore from '@components/organisms/financial-events/batch-creation-core';
import FinancialEventsBatchCreationFormStepsTabs from '@components/molecules/financial-events/batch-creation/form-steps-tabs';

import { financialEventsCreationPageNavigationHistoryLinks as navigationHistoryLinks } from './static-data';

const CreateFinancialEventsBatch: React.FC = () => {
  const [financialEventStep, setFinancialEventStep] = useState<number>(1);
  const [financialEventsType, setFinancialEventsType] = useState<keyof typeof FinancialEventType>();
  const [isProcessingBatch, setIsProcessingBatch] = useState<boolean>(false);
  const { openNotificationFeedBack, setOpenNotificationFeedBack } = useFinancialEvents();

  return (
    <Layout
      title="Eventos Financeiros"
      navigationHistoryLinks={navigationHistoryLinks}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {openNotificationFeedBack && (
          <FeedbackNotification
            label="Erro"
            message="Houve um erro ao processar eventos. Revise os dados do formulário e tente novamente."
            kind="error"
            onCloseNotification={() => setOpenNotificationFeedBack(false)}
          />
        )}
      </div>
      <div className="col-span-full">
        <FinancialEventsBatchCreationFormStepsTabs
          financialEventsType={financialEventsType}
          financialEventStep={financialEventStep}
        />
        <CreateFinancialEventBatchCore
          financialEventsType={financialEventsType}
          financialEventStep={financialEventStep}
          isProcessingBatch={isProcessingBatch}
          setFinancialEventStep={setFinancialEventStep}
          setFinancialEventsType={setFinancialEventsType}
          setIsProcessingBatch={setIsProcessingBatch}
          setOpenNotificationFeedBack={setOpenNotificationFeedBack}
        />
      </div>
    </Layout>
  );
};

export default CreateFinancialEventsBatch;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Modal } from '@clarke-energia/foton';
import { ColumnOptions, Tag } from '@clarke-energia/foton';
import { EmailsLogTable } from '@components/molecules/user/users-table';
import FormSendWelcomeMigrationEmail from '@components/molecules/form/form-welcome-migration';

import { EmailLog } from '@contexts/user/types';

import { useUsers } from '@hooks/users';
import { useAuth } from '@src/ApolloWrapper';
import { HOME_PATH, TRADERS_PATH } from '@routers/constants';

type renderFunction = ColumnOptions<EmailLog>['renderAsElement'];

const renderError: renderFunction = (entry) =>
  entry.serviceMetadata ? (
    <Tag
      kind="default"
      color={entry.hasSent ? 'green' : 'red'}
      label={entry.hasSent ? 'Sim' : 'Não'}
      className="w-10 text-center"
    />
  ) : (
    '-'
  );

const EmailsLogPage: React.FC = () => {
  const [modalSendWelcomeMigration, setModalSendWelcomeMigration] = useState<boolean>(false);
  const { user, logEmails, getEmailsLog, getUserHandler, loadingEmailLog } = useUsers();
  const { userId } = useParams();
  const {
    authStatus: { accessToken },
  } = useAuth();

  const [typedSearchTerm, setTypedSearchTerm] = useState<string>('');
  const emailLogsTableProps: ColumnOptions<EmailLog>[] = [
    { accessor: 'id', header: 'ID' },
    { accessor: 'subject', header: 'Assunto' },
    { accessor: 'templateName', header: 'Template' },
    { accessor: 'templateVersion', header: 'Template Version' },
    { header: 'Sucesso?', renderAsElement: renderError },
    { accessor: 'createdAt', header: 'Data de Criação' },
  ];

  const filteredEmails =
    typedSearchTerm.length > 0
      ? logEmails.filter((email) => email?.subject.toLowerCase().includes(typedSearchTerm.toLowerCase()))
      : logEmails;

  useEffect(() => {
    if (accessToken && userId) getUserHandler(userId);
  }, [accessToken, userId]);

  useEffect(() => {
    if (user?.id == userId) getEmailsLog();
  }, [user]);

  return (
    <Layout
      title={`Log de Emails: ${user?.email}`}
      loading={loadingEmailLog}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Fornecedoras',
          url: TRADERS_PATH,
        },
        {
          label: 'Log de Emails',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Novo Email',
          onClick: () => setModalSendWelcomeMigration(true),
          size: 'small',
        },
      ]}
      searchBar={{
        onTypedTermChangedCallback: (term) => setTypedSearchTerm(term),
        placeholder: 'Filtrar por título do email',
      }}
      className="relative"
      darkerBackground
    >
      <div className="col-span-full mb-8 h-full">
        <EmailsLogTable
          data={filteredEmails}
          isFetchingItems={loadingEmailLog}
          columnDefinitions={emailLogsTableProps}
        />
      </div>
      {user && (
        <Modal
          open={modalSendWelcomeMigration}
          setOpen={() => setModalSendWelcomeMigration(false)}
          title="Enviar email | Módulo de Migração"
          description="Tem certeza que deseja enviar o email de acesso à plataforma de migração?"
        >
          <FormSendWelcomeMigrationEmail
            user={user}
            onFormSuccess={() => {
              setModalSendWelcomeMigration(false);
            }}
          />
        </Modal>
      )}
    </Layout>
  );
};

export default EmailsLogPage;

import { financialEventsTabs } from '@pages/financial-events/static-data';

import { SelectFormField } from '@components/atoms/form';
import { DateFormField } from '@components/atoms/form/date';

import { IFinancialEventsBatchPayload } from '@contexts/financial-events';

const FinancialEventsBatchCreationFormDataSection = () => {
  return (
    <div className="flex flex-wrap gap-6 md:flex-nowrap">
      <DateFormField<IFinancialEventsBatchPayload>
        field="referencePeriod"
        id="referencePeriod"
        label="Mês / Ano referente"
        placeholder="MM/YYYY"
        options={{ required: { value: true, message: 'O mês de referência é obrigatório' } }}
      />
      <SelectFormField<IFinancialEventsBatchPayload>
        field="type"
        id="type"
        label="Evento financeiro"
        inputOptions={financialEventsTabs
          .filter((financialEventTab) => !financialEventTab.disabled)
          .map((item) => ({
            optionLabel: item.title,
            value: item.type,
          }))}
        options={{ required: { value: true, message: 'O tipo do evento financeiro é obrigatório' } }}
      />
      <DateFormField<IFinancialEventsBatchPayload>
        field="paymentDeadline"
        id="paymentDeadline"
        label="Validade"
        placeholder="DD/MM/YYYY"
        options={{ required: { value: true, message: 'A validade do pagamento é obrigatório' } }}
      />
    </div>
  );
};

export default FinancialEventsBatchCreationFormDataSection;

import React from 'react';
import { useParams } from 'react-router-dom';

import {
  SearchBar,
  ColumnOptions,
  FeedbackNotification,
  Layout,
  Modal,
  Tag,
  ThreeDotsMenu,
} from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { COMMERCIAL_GROUP_PATH, HOME_PATH } from '@routers/constants';
import {
  BindUserNotificationFeedbackContents,
  INotificationFeedbackContent,
  CreateRoleCategoryUserNotificationFeedbackContents,
} from '@utils/constants/common';
import { UserInfo } from '@contexts/user/types';
import useGroupUsers from '@hooks/use-user-groups';
import { useUsers } from '@hooks/users';

import { GroupUsersTable } from '@components/molecules/commercial-group/user-group-table';
import FormUpsertGroupUser from '@components/molecules/form/form-upsert-user-group';
import FormCreateCategoryForUser from '@components/molecules/form/form-create-category-for-user';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import AvatarWithText from '@components/atoms/avatarWithText';
import { ClientRolesHandleParser } from '@utils/translators/users-roles';

type renderFunction = ColumnOptions<UserInfo>['renderAsElement'];
const renderUserName: renderFunction = (entry) => <AvatarWithText entry={entry} />;
const renderUserEmail: renderFunction = (entry) => entry?.email;
const renderCategoryTag: renderFunction = (entry) => (
  <div className="flex items-center flex-column">
    {entry
      ? entry?.categoryRoles?.map((role: string, index: number) => {
          const parsedRole = ClientRolesHandleParser(role);
          const color =
            parsedRole.label === 'Contato Principal'
              ? 'blue'
              : parsedRole.label === 'Representante Legal'
              ? 'green'
              : parsedRole.label === 'Evento Financeiro'
              ? 'yellow'
              : 'red';
          return <Tag key={index} kind="default" color={color} label={parsedRole.label} />;
        })
      : '-'}
  </div>
);

const CommercialGroupUsers: React.FC = () => {
  const { groupId } = useParams();

  const [selectedGroupUser, setSelectedGroupUser] = React.useState<UserInfo | null>(null);
  const [openConfirmationDeletionModal, setOpenConfirmationDeletionModal] = React.useState(false);
  const [openConfirmationCategoryModal, setOpenConfirmationCategoryModal] = React.useState(false);
  const [modalCreateGroupUserOpen, setModalCreateGroupUserOpen] = React.useState<boolean>(false);
  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();
  const { getUsersHandler } = useUsers();
  const { getGroupHandler, group, groupUsers, setCommercialGroupId, deleteGroupsUserHandler, loadingGetGroup } =
    useGroupUsers();

  const userGroupUsersTableColumns: ColumnOptions<UserInfo>[] = [
    { accessor: 'name', header: 'Nome', renderAsElement: renderUserName },
    { accessor: 'email', header: 'Email', renderAsElement: renderUserEmail },
    { accessor: 'categoryRoles', header: 'Categorias', renderAsElement: renderCategoryTag },
    {
      renderAsElement: (entry) => {
        return (
          <ThreeDotsMenu
            extraClasses={'md:mr-8 lg:mr-10'}
            linkElement={[
              { icon: 'Bars4Icon', url: `/usuarios/${entry.id}/emails`, label: 'Histórico de notificações' },
            ]}
            menuOptions={[
              {
                icon: 'TagIcon',
                onClick: () => {
                  setSelectedGroupUser(entry);
                  setOpenConfirmationCategoryModal(true);
                  setOpenNotificationFeedback(false);
                },
                label: 'Adicionar categoria',
              },
              {
                icon: 'TrashIcon',
                onClick: () => {
                  setSelectedGroupUser(entry);
                  setOpenConfirmationDeletionModal(true);
                  setOpenNotificationFeedback(false);
                },
                label: 'Desvincular usuário',
              },
            ]}
          />
        );
      },
    },
  ];

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  React.useEffect(() => {
    if (groupId) setCommercialGroupId(groupId);
  }, [groupId]);

  React.useEffect(() => {
    trackEvent(eventLabels.DETAILED_GROUP_USERS_PAGE_VIEWED);
  }, []);

  return (
    <Layout
      title="Usuários"
      loading={loadingGetGroup}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: group?.name as string,
          url: COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string),
        },
        {
          label: 'Usuários',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Vincular usuário',
          onClick: () => {
            setSelectedGroupUser(null);
            setModalCreateGroupUserOpen(true);
            getUsersHandler();
          },
          size: 'small',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full h-full">
        <div className="mb-8 w-full md:w-1/2 lg:w-1/4">
          <SearchBar
            label=""
            placeholder="Filtrar por nome do usuário"
            onTypedTermChangedCallback={(term) => setTypedSearchTerm(term)}
            onBlur={() => trackEvent(eventLabels.DETAILED_GROUP_PAGE_SEARCH_TRADER_USER)}
            className="bg-white w-[20rem]"
          />
        </div>
        <GroupUsersTable
          data={
            typedSearchTerm.length > 0
              ? groupUsers?.filter((groupUser) => groupUser?.name.toLowerCase().includes(typedSearchTerm.toLowerCase()))
              : groupUsers
          }
          isLoading={loadingGetGroup}
          columnDefinitions={userGroupUsersTableColumns}
        />
      </div>
      <Modal
        className="p-10"
        open={modalCreateGroupUserOpen}
        setOpen={() => setModalCreateGroupUserOpen(!modalCreateGroupUserOpen)}
        title="Adicionar usuários ao grupo comercial"
        description="Formulário para a criação de relação entre usuários e grupo comercial."
      >
        <FormUpsertGroupUser
          onFormSuccess={() => {
            getGroupHandler();
            setModalCreateGroupUserOpen(false);
            setNotificationResponse(BindUserNotificationFeedbackContents.BIDING_USER_SUCCESS);
            trackEvent(eventLabels.CREATE_GROUP_USER_BUTTON_CLICKED);
          }}
          onFormFail={() => setNotificationResponse(BindUserNotificationFeedbackContents.BIDING_USER_ERROR)}
          onCancel={() => setModalCreateGroupUserOpen(!modalCreateGroupUserOpen)}
        />
      </Modal>
      <Modal
        open={openConfirmationDeletionModal}
        setOpen={() => setOpenConfirmationDeletionModal(!openConfirmationDeletionModal)}
        title="Deletar usuário"
        description="Tem certeza que deseja excluir esse usuário desse grupo comercial?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedGroupUser &&
              deleteGroupsUserHandler(selectedGroupUser.id)
                .then(() => {
                  setOpenConfirmationDeletionModal(false);
                  setNotificationResponse(BindUserNotificationFeedbackContents.DELETING_USER_SUCCESS);
                  trackEvent(eventLabels.DELETE_GROUP_USER_BUTTON_CLICKED);
                })
                .catch(() => {
                  setOpenConfirmationDeletionModal(false);
                  setNotificationResponse(BindUserNotificationFeedbackContents.DELETING_USER_ERROR);
                })
                .finally(() => getGroupHandler());
          }}
          onCancel={() => setOpenConfirmationDeletionModal(false)}
        />
      </Modal>
      <Modal
        open={openConfirmationCategoryModal}
        setOpen={() => setOpenConfirmationCategoryModal(!openConfirmationCategoryModal)}
        title="Adicionar categorias"
        description="Selecione a categoria que o usuário pertence para receber os e-mails corretos"
      >
        <FormCreateCategoryForUser
          user={selectedGroupUser}
          onFormSuccess={() => {
            getGroupHandler();
            setOpenConfirmationCategoryModal(false);
            setNotificationResponse(CreateRoleCategoryUserNotificationFeedbackContents.ROLE_USER_SUCCESS);
          }}
          onFormFail={() => setNotificationResponse(CreateRoleCategoryUserNotificationFeedbackContents.ROLE_USER_ERROR)}
          onCancel={() => setOpenConfirmationCategoryModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default CommercialGroupUsers;

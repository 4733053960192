import React from 'react';
interface BoxBorderProps {
  text: string;
  extraClassName?: string;
}

const BoxTextBorder: React.FC<BoxBorderProps> = ({ text, extraClassName }) => {
  return (
    <div className={`bg-gray-100 border-t-2 border-black  ${extraClassName ?? ''}`}>
      <p className="pt-2 pb-3 font-bold text-paragraph-medium">{text}</p>
      <div className="mb-2 border-t border-black" />
    </div>
  );
};

export default BoxTextBorder;

import React from 'react';
import { HeroIcon } from '@clarke-energia/foton';

interface LoadingProcessFileProps {
  processingPercentage: number;
}

const LoadingProcessFile: React.FC<LoadingProcessFileProps> = ({ processingPercentage }) => {
  const percentage = `${processingPercentage}%`;

  return (
    <>
      <div className="flex justify-center items-center h-4/5">
        <div className="flex flex-col items-center">
          <div className="flex justify-center items-center mb-5 w-8 h-8 rounded-medium bg-neutral-10">
            <HeroIcon icon={'TableCellsIcon'} extraClassNames={'w-5'} />
          </div>
          <div className="mb-5 h-4 rounded-full w-[10.5rem] bg-neutral-10">
            <div className="h-4 rounded-full bg-primary-60" style={{ width: percentage }}></div>
          </div>
          <h2 className="font-bold text-heading-medium">
            {processingPercentage === 100 ? 'Tabela Enviada' : 'Enviando tabela'}
          </h2>
          <span className="text-paragraph-medium">
            {processingPercentage === 100 ? 'Dados carregados' : 'Carregando tabela no sistema'}
          </span>
        </div>
      </div>
    </>
  );
};

export default LoadingProcessFile;

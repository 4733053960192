import React from 'react';

interface FormSectionHeaderProps {
  title: string;
  text: string;
}

const FormSectionHeader = ({ title, text }: FormSectionHeaderProps): JSX.Element => {
  return (
    <div className="col-span-3 pr-5 lg:col-span-1">
      <p className="my-0 font-bold text-heading-xsmall">{title}</p>
      <p className="mt-1 text-paragraph-medium text-neutral-70">{text}</p>
    </div>
  );
};

export default FormSectionHeader;

import { gql } from '@apollo/client';

export const CREATE_CCEE_PROFILE = gql`
  mutation CreateCceeProfile($input: CreateCceeProfileInput) {
    createCceeProfile(input: $input) {
      id
    }
  }
`;

export const DELETE_CCEE_PROFILE = gql`
  mutation DeleteCceeProfile($input: DeleteCceeProfileInput) {
    deleteCceeProfile(input: $input) {
      id
    }
  }
`;

export const UPDATE_CCEE_PROFILE = CREATE_CCEE_PROFILE;

export const eventLabels = {
  GROUPS_PAGE_VIEWED: 'GROUPS_PAGE_VIEWED',
  GROUPS_PAGE_SEARCH_GROUP: 'GROUPS_PAGE_SEARCH_GROUP',
  CREATE_COMMERCIAL_GROUP_BUTTON_CLICKED: 'CREATE_COMMERCIAL_GROUP_BUTTON_CLICKED',
  UPDATE_COMMERCIAL_GROUP_BUTTON_CLICKED: 'UPDATE_COMMERCIAL_GROUP_BUTTON_CLICKED',
  DELETE_COMMERCIAL_GROUP_BUTTON_CLICKED: 'DELETE_COMMERCIAL_GROUP_BUTTON_CLICKED',

  GROUP_PAGE_VIEWED: 'GROUP_PAGE_VIEWED',
  GROUP_PAGE_SEARCH_UNIT: 'GROUP_PAGE_SEARCH_UNIT',
  CREATE_COMMERCIAL_UNIT_BUTTON_CLICKED: 'CREATE_COMMERCIAL_UNIT_BUTTON_CLICKED',
  UPDATE_COMMERCIAL_UNIT_BUTTON_CLICKED: 'UPDATE_COMMERCIAL_UNIT_BUTTON_CLICKED',
  DELETE_COMMERCIAL_UNIT_BUTTON_CLICKED: 'DELETE_COMMERCIAL_UNIT_BUTTON_CLICKED',

  TRADERS_PAGE_VIEWED: 'TRADERS_PAGE_VIEWED',
  TRADERS_PAGE_SEARCH_TRADER: 'TRADERS_PAGE_SEARCH_TRADER',
  CREATE_TRADER_BUTTON_CLICKED: 'CREATE_TRADER_BUTTON_CLICKED',
  UPDATE_TRADER_BUTTON_CLICKED: 'UPDATE_TRADER_BUTTON_CLICKED',
  DELETE_TRADER_BUTTON_CLICKED: 'DELETE_TRADER_BUTTON_CLICKED',
  VISIT_TRADER_SITE_BUTTON_CLICKED: 'VISIT_TRADER_SITE_BUTTON_CLICKED',

  DETAILED_TRADER_PAGE_VIEWED: 'DETAILED_TRADER_PAGE_VIEWED',
  DETAILED_TRADER_PAGE_SEARCH_TRADER_USER: 'DETAILED_TRADER_PAGE_SEARCH_TRADER_USER',
  CREATE_TRADER_USER_BUTTON_CLICKED: 'CREATE_TRADER_USER_BUTTON_CLICKED',
  DELETE_TRADER_USER_BUTTON_CLICKED: 'DELETE_TRADER_USER_BUTTON_CLICKED',

  DETAILED_GROUP_PAGE_VIEWED: 'DETAILED_GROUP_PAGE_VIEWED',
  DETAILED_GROUP_USERS_PAGE_VIEWED: 'DETAILED_GROUP_USERS_PAGE_VIEWED',
  DETAILED_GROUP_PAGE_SEARCH_TRADER_USER: 'DETAILED_GROUP_PAGE_SEARCH_TRADER_USER',
  CREATE_GROUP_USER_BUTTON_CLICKED: 'CREATE_GROUP_USER_BUTTON_CLICKED',
  DELETE_GROUP_USER_BUTTON_CLICKED: 'DELETE_GROUP_USER_BUTTON_CLICKED',

  USERS_PAGE_VIEWED: 'USERS_PAGE_VIEWED',
  USERS_PAGE_SEARCH_USER: 'USERS_PAGE_SEARCH_USER',
  CREATE_USER_BUTTON_CLICKED: 'CREATE_USER_BUTTON_CLICKED',
  UPDATE_USER_BUTTON_CLICKED: 'UPDATE_USER_BUTTON_CLICKED',
  DELETE_USER_BUTTON_CLICKED: 'DELETE_USER_BUTTON_CLICKED',

  GROUP_FILES_PAGE_VIEWED: 'GROUP_FILES_PAGE_VIEWED',

  KICK_OFF_PAGE_VIEWED: 'KICK_OFF_PAGE_VIEWED',

  ADD_UNIT_BUTTON_CLICKED: 'ADD_UNIT_BUTTON_CLICKED',
};

import { ColumnOptions, HeroIcon, Tag } from '@clarke-energia/foton';

import { FinancialEvent, FinancialEventType } from '@contexts/financial-events';
import { formatCurrency, formatMonthAndYearDate } from '@utils/text';

import FinancialEventMoreOptionsTableButton from '@components/atoms/financial-events/list/more-options-table-button';
import FinanciaalEventsTableEERExpandedContent from '@components/atoms/financial-events/list/eer-table-expanded-content';

import { FinancialEventStatusTagPropsSelector, columnStatusDescription } from './static-data';
import { parseStringToDate, formatDate } from '@utils/dayjs';

type renderFunction = ColumnOptions<FinancialEvent>['renderAsElement'];

export class FinancialEventsTableHelper {
  setSelectedFinancialEvent: React.Dispatch<React.SetStateAction<FinancialEvent | undefined>>;
  setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOnConfirmationScreen?: boolean;
  selectedFinancialEvent?: FinancialEvent;

  constructor(
    setSelectedFinancialEvent: React.Dispatch<React.SetStateAction<FinancialEvent | undefined>>,
    setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>,
    isOnConfirmationScreen?: boolean,
    selectedFinancialEvent?: FinancialEvent,
  ) {
    this.selectedFinancialEvent = selectedFinancialEvent;
    this.setSelectedFinancialEvent = setSelectedFinancialEvent;
    this.setOpenConfirmationModal = setOpenConfirmationModal;
    this.isOnConfirmationScreen = isOnConfirmationScreen;
  }

  private renderReferencePeriod: renderFunction = (entry) =>
    formatMonthAndYearDate(entry.referencePeriod ? entry.referencePeriod.toString() : '');
  private renderGroupName: renderFunction = (entry) => (entry.commercialGroup ? entry.commercialGroup.name : '');
  private renderAgent: renderFunction = (entry) => (entry.agent ? entry.agent : '');
  private renderAmountToPay: renderFunction = (entry) => formatCurrency(entry.amountToPay ? entry.amountToPay : 0);
  private renderLiquidationFee: renderFunction = (entry) =>
    formatCurrency(entry.liquidationFee ? entry.liquidationFee : 0);
  private renderLiquidationGuarantee: renderFunction = (entry) =>
    formatCurrency(entry.liquidationGuarantee ? entry.liquidationGuarantee : 0);
  private renderSentOn: renderFunction = (entry) =>
    entry.sentOn ? formatDate(parseStringToDate(entry.sentOn), 'DD/MM/YYYY') : '';
  private renderAttachment: renderFunction = (entry) => {
    if (entry.attachment) {
      return (
        <div className="flex gap-2 py-3 px-5">
          <HeroIcon icon="PaperClipIcon" className="w-5 h-5" />
          <p className="w-24 truncate">{entry.attachment.filename}</p>
        </div>
      );
    } else return '-';
  };
  private renderStatus: renderFunction = (entry) => {
    const tagProps = entry.status
      ? FinancialEventStatusTagPropsSelector[entry.status]
      : FinancialEventStatusTagPropsSelector['UNPROCESSED'];
    return <Tag color={tagProps.color} kind={tagProps.kind} label={tagProps.label} icon={tagProps.icon} />;
  };
  private renderSendEmail: renderFunction = (entry) => (
    <FinancialEventMoreOptionsTableButton
      entry={entry}
      selectedFinancialEvent={this.selectedFinancialEvent}
      setSelectedFinancialEvent={this.setSelectedFinancialEvent}
      setOpenConfirmationModal={this.setOpenConfirmationModal}
    />
  );

  getTableColumnsDefinition = (activeFinancialEventType: FinancialEventType) => {
    const agentColumnSet: Array<ColumnOptions<FinancialEvent>> = [
      { accessor: 'agent', header: 'Agente', renderAsElement: this.renderAgent },
    ];

    const attachmentColumnSet: Array<ColumnOptions<FinancialEvent>> = [
      { accessor: 'attachment', header: 'Anexo', renderAsElement: this.renderAttachment },
    ];

    const financialSettlementColumnsSet: Array<ColumnOptions<FinancialEvent>> = [
      {
        accessor: 'liquidationGuarantee',
        header: 'Garantia Financeira',
        renderAsElement: this.renderLiquidationGuarantee,
      },
      {
        accessor: 'liquidationFee',
        header: 'Valor Liquidação',
        renderAsElement: this.renderLiquidationFee,
      },
    ];

    const firstCommonColumnsDefinitionSet: Array<ColumnOptions<FinancialEvent>> = [
      {
        accessor: 'referencePeriod',
        header: 'Mês / Ano referência',
        renderAsElement: this.renderReferencePeriod,
        sortableColumn: true,
      },
      { accessor: 'commercialGroup', header: 'Grupo Comercial', renderAsElement: this.renderGroupName },
      ...(activeFinancialEventType !== FinancialEventType.ASSOCIATIVE_CONTRIBUTION ? agentColumnSet : []),
    ];

    const secondCommonColumnsDefinitionSet: Array<ColumnOptions<FinancialEvent>> = [
      { accessor: 'amountToPay', header: 'Valor', renderAsElement: this.renderAmountToPay },
      ...(activeFinancialEventType === FinancialEventType.ASSOCIATIVE_CONTRIBUTION ? attachmentColumnSet : []),
      {
        accessor: 'status',
        header: 'Status',
        tooltipText: columnStatusDescription,
        renderAsElement: this.renderStatus,
      },
    ];

    const moreOptionsButtonColumnDefinition = { renderAsElement: this.renderSendEmail, fixedWidthInRem: 5 };
    const sentOnColumnDefinition: ColumnOptions<FinancialEvent> = {
      accessor: 'sentOn',
      header: 'Data de envio',
      renderAsElement: this.renderSentOn,
    };

    const finalTableColumnsDefinition = [
      ...firstCommonColumnsDefinitionSet,
      ...(activeFinancialEventType == FinancialEventType.FINANCIAL_SETTLEMENT ? financialSettlementColumnsSet : []),
      ...secondCommonColumnsDefinitionSet,
      ...(!this.isOnConfirmationScreen ? [moreOptionsButtonColumnDefinition] : []),
    ];

    if (!this.isOnConfirmationScreen) finalTableColumnsDefinition.splice(-2, 0, sentOnColumnDefinition);

    return finalTableColumnsDefinition as Array<ColumnOptions<FinancialEvent>>;
  };

  generateEERExpansibleTableData = (financialEventsData?: Array<FinancialEvent>) => {
    if (!financialEventsData) return [];
    else
      return financialEventsData.map((financialEvent) => {
        return {
          data: { ...financialEvent },
          expandedContent: (
            <FinanciaalEventsTableEERExpandedContent
              eerFeePerProfiles={financialEvent.eerFeePerProfiles as Record<string, number>}
            />
          ),
        };
      });
  };
}

import { gql } from '@apollo/client';

export const CREATE_CCEE_CONTRACT = gql`
  mutation CreateCceeContract($input: CreateCceeContractInput) {
    createCceeContract(input: $input) {
      id
    }
  }
`;

export const DELETE_CCEE_CONTRACT = gql`
  mutation DeleteCceeContract($input: DeleteCceeContractInput) {
    deleteCceeContract(input: $input) {
      id
    }
  }
`;

export const UPDATE_CCEE_CONTRACT = CREATE_CCEE_CONTRACT;

import React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import { SendWelcomeMigrationEmailPayload, UserInfo } from '@contexts/user';
import { CommercialGroup } from '@contexts/commercial-group';
import { useUsers } from '@hooks/users';

import { FormField } from '@components/atoms/form';

interface IFormSendWelcomeMigrationEmail {
  onFormSuccess: () => void;
  user: UserInfo;
}

function FormSendWelcomeMigrationEmail({ onFormSuccess, user }: IFormSendWelcomeMigrationEmail) {
  const [loading, setLoading] = React.useState(true);
  const [group, setGroup] = React.useState<CommercialGroup>();

  const { sendWelcomeMigrationEmailHandler, getGroupByUser } = useUsers();
  const methods = useForm<SendWelcomeMigrationEmailPayload>();

  React.useEffect(() => {
    setLoading(true);
    getGroupByUser().then((data: any) => {
      setGroup(data.data['getGroupByUser']['group']);
      setLoading(false);
    });
  }, [user]);

  const onSubmit: SubmitHandler<SendWelcomeMigrationEmailPayload> = async () => {
    setLoading(true);
    const values: SendWelcomeMigrationEmailPayload = {
      groupId: group ? group.id : '',
      email: user.email,
      userId: user.id,
    };
    return await sendWelcomeMigrationEmailHandler(values)
      .then(() => {
        setLoading(false);
        onFormSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        {!loading && group ? (
          <>
            <FormField<SendWelcomeMigrationEmailPayload>
              id="groupId"
              label="Cliente"
              field="groupId"
              placeholder={group?.name}
              disabled={true}
            />
            <Button loading={loading} label="Enviar email" kind={'primary'} className="mt-5" />
          </>
        ) : loading && !group ? (
          <p className="text-paragraph-medium">Buscando cliente...</p>
        ) : (
          <p className="text-paragraph-medium">Não foi possível obter os dados deste cliente.</p>
        )}
      </form>
    </FormProvider>
  );
}

export default FormSendWelcomeMigrationEmail;

import { EnergyContractContextType, EnergyContractContext } from '@contexts/energy-contract';
import { useContextSelector } from 'use-context-selector';

export const useEnergyContracts = (): EnergyContractContextType => {
  const createEnergyContractHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.createEnergyContractHandler,
  );
  const energyContracts = useContextSelector(
    EnergyContractContext,
    (energyContracts) => energyContracts.energyContracts,
  );
  const loading = useContextSelector(EnergyContractContext, (energyContract) => energyContract.loading);
  const updateEnergyContractHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.updateEnergyContractHandler,
  );
  const deleteEnergyContractHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.deleteEnergyContractHandler,
  );
  const getGroupsListHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.getGroupsListHandler,
  );
  const groups = useContextSelector(EnergyContractContext, (energyContract) => energyContract.groups);
  const getEnergyContractByIdHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.getEnergyContractByIdHandler,
  );
  const energyContractToUpdate = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.energyContractToUpdate,
  );
  const proposalsSelectField = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.proposalsSelectField,
  );
  const proposalsFormated = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.proposalsFormated,
  );
  const loadingProposals = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.loadingProposals,
  );
  const getProposalsHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.getProposalsHandler,
  );

  const pagination = useContextSelector(EnergyContractContext, (energyContract) => energyContract.pagination);
  const getEnergyContractsHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.getEnergyContractsHandler,
  );

  const feedbackType = useContextSelector(EnergyContractContext, (energyContract) => energyContract.feedbackType);
  const setFeedbackType = useContextSelector(EnergyContractContext, (energyContract) => energyContract.setFeedbackType);
  const createEnergyContractAttachmentsHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.createEnergyContractAttachmentsHandler,
  );
  const deleteEnergyContractAttachmentsHandler = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.deleteEnergyContractAttachmentsHandler,
  );
  const storedFiles = useContextSelector(EnergyContractContext, (energyContract) => energyContract.storedFiles);
  const totalProposalsPage = useContextSelector(
    EnergyContractContext,
    (energyContract) => energyContract.totalProposalsPage,
  );

  const mutationLoading = useContextSelector(EnergyContractContext, (energyContract) => energyContract.mutationLoading);

  return {
    feedbackType,
    setFeedbackType,
    getProposalsHandler,
    loadingProposals,
    proposalsSelectField,
    proposalsFormated,
    getEnergyContractByIdHandler,
    energyContractToUpdate,
    groups,
    getGroupsListHandler,
    createEnergyContractHandler,
    energyContracts,
    loading,
    updateEnergyContractHandler,
    deleteEnergyContractHandler,
    pagination,
    getEnergyContractsHandler,
    createEnergyContractAttachmentsHandler,
    deleteEnergyContractAttachmentsHandler,
    storedFiles,
    totalProposalsPage,
    mutationLoading,
  };
};

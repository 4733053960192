import React, { useState } from 'react';
import { Button } from '@clarke-energia/foton';

type Options = {
  options: string[];
  onClick: (option: string) => void;
};

interface FiltersProinfaProps {
  filters: {
    label: string;
    options: string[];
    openOptions: () => void;
    onClick: (option: string) => void;
    showOptions: boolean;
    filterSelected: boolean;
  }[];
  clearFilter: () => boolean | void;
}

const FiltersOptions: React.FC<Options> = ({ options, onClick }) => {
  return (
    <ul className="absolute z-50 p-3 bg-white border-black shadow-xl max-h-max border-[1px] rounded-large h-fit md:w-[12rem]">
      {options.map((option) => (
        <li
          key={option}
          className="py-2 px-4 w-full hover:cursor-pointer rounded-medium hover:bg-neutral-10"
          onClick={() => onClick(option)}
        >
          {option}
        </li>
      ))}
    </ul>
  );
};

const FiltersProinfa: React.FC<FiltersProinfaProps> = ({ filters, clearFilter }) => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  return (
    <div className="flex gap-x-4 mb-[37px]">
      {filters.map(({ label, options, onClick, showOptions, openOptions, filterSelected }, idx) => {
        return (
          <div className="relative" key={`${label}-${idx}`}>
            {filterSelected ? (
              <Button
                kind="chip"
                label={label}
                icon={showOptions ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                iconPosition="right"
                size="small"
                onClick={openOptions}
              />
            ) : (
              <Button
                kind="secondary"
                label={label}
                icon={showOptions ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                iconPosition="right"
                size="small"
                onClick={() => {
                  openOptions();
                  setSelectedIdx(idx);
                }}
              />
            )}
            {showOptions && <FiltersOptions options={options} onClick={onClick} />}
          </div>
        );
      })}
      <Button
        kind="ghost"
        label="Limpar filtros"
        disabled={!filters[selectedIdx].filterSelected}
        size="small"
        onClick={clearFilter}
      />
    </div>
  );
};

export default FiltersProinfa;

import { EnergyAuditTrader } from '@contexts/energy-audit';
import React from 'react';

interface IEnergyAuditsTraderSummary {
  energyAuditTrader: EnergyAuditTrader;
}

const EnergyAuditsTraderSummary: React.FC<IEnergyAuditsTraderSummary> = ({
  energyAuditTrader,
}: IEnergyAuditsTraderSummary) => {
  return (
    <div>
      <div className={'flex justify-between text-paragraph-medium px-4 py-1 bg-[#FAFAFA]'}>
        <span className="font-bold">{'Fornecedor'}</span>
        <span>{energyAuditTrader?.name}</span>
      </div>
      <div className={'flex justify-between text-paragraph-medium px-4 py-1'}>
        <span className="font-bold">{'Perfil'}</span>
        <span>{energyAuditTrader?.profileCcee}</span>
      </div>
      <div className={'flex justify-between text-paragraph-medium px-4 py-1 bg-[#FAFAFA]'}>
        <span className="font-bold">{'ID'}</span>
        <span>{energyAuditTrader?.idCcee}</span>
      </div>
    </div>
  );
};

export default EnergyAuditsTraderSummary;

import React from 'react';
import { Button } from '@clarke-energia/foton';

interface ConfirmationProinfaProps {
  onCancel: () => void;
  onConfirmation: () => void;
  loading: boolean;
  data: {
    year: string;
    unitsName: string[];
  };
}

const ConfirmationProinfaForm: React.FC<ConfirmationProinfaProps> = ({
  onConfirmation,
  onCancel,
  data,
  loading,
}: ConfirmationProinfaProps) => {
  return (
    <div>
      <div className="py-4 border-y-[1px] border-neutral-30 text-paragraph-medium">
        <div className="flex gap-2">
          <span>Referência:</span>
          <span>{data.year}</span>
        </div>
        <div className="flex gap-2">
          <span>Unidades:</span>
          <span>{data.unitsName.join(', ')}</span>
        </div>
      </div>
      <div className="flex gap-4 justify-between pt-10 -mb-8">
        <Button
          kind="primary"
          label={'Enviar PROINFA'}
          className="w-full"
          onClick={onConfirmation}
          loading={loading}
          type="submit"
        />
        <Button kind="secondary" label="Cancelar" onClick={onCancel} className="w-full" />
      </div>
    </div>
  );
};

export default ConfirmationProinfaForm;

import { NotificationContext, NotificationContextType } from '@contexts/notification';
import { useContextSelector } from 'use-context-selector';

export const useNotification = (): NotificationContextType => {
  const handleCreateNotification = useContextSelector(NotificationContext, (files) => files.handleCreateNotification);
  const isFetchingNotifications = useContextSelector(NotificationContext, (files) => files.isFetchingNotifications);
  const setNotificationResponse = useContextSelector(NotificationContext, (files) => files.setNotificationResponse);
  const openNotificationFeedBack = useContextSelector(NotificationContext, (files) => files.openNotificationFeedBack);
  const messageNotificationFeedBack = useContextSelector(
    NotificationContext,
    (files) => files.messageNotificationFeedBack,
  );
  const setOpenNotificationFeedback = useContextSelector(
    NotificationContext,
    (files) => files.setOpenNotificationFeedback,
  );

  return {
    handleCreateNotification,
    isFetchingNotifications,
    setNotificationResponse,
    openNotificationFeedBack,
    messageNotificationFeedBack,
    setOpenNotificationFeedback,
  };
};

import { useContextSelector } from 'use-context-selector';
import {
  CreateUnitConsumptionHistoryGraphQLInput,
  EditUnitConsumptionHistoryGraphQLInput,
  UnitContext,
  UnitContextType,
  UnitPayload,
  UpsertUnitConsumptionHistoryGraphQLResponse,
} from '@contexts/unit';
import { useMutation } from '@apollo/client';
import { UPSERT_UNIT_CONSUMPTION_HISTORY } from '@contexts/unit/mutation';
import {
  buildPaddedUnitHistory,
  PaddedUnitHistory,
  parsePaddedUnitHistoryIntoGraphQLInput,
} from '@components/molecules/form/form-upsert-unit/unit-history-helper-methods';

export const useUnits = (): UnitContextType => {
  const setGroupId = useContextSelector(UnitContext, (units) => units.setGroupId);
  const getUnits = useContextSelector(UnitContext, (units) => units.getUnits);
  const createUnitHandler = useContextSelector(UnitContext, (unit) => unit.createUnitHandler);
  const upsertMeasuringPointHandler = useContextSelector(UnitContext, (unit) => unit.upsertMeasuringPointHandler);
  const units = useContextSelector(UnitContext, (units) => units.units);
  const groupName = useContextSelector(UnitContext, (units) => units.groupName);
  const loading = useContextSelector(UnitContext, (units) => units.loading);
  const updateUnitHandler = useContextSelector(UnitContext, (unit) => unit.updateUnitHandler);
  const deleteUnitHandler = useContextSelector(UnitContext, (unit) => unit.deleteUnitHandler);
  const setNotificationResponse = useContextSelector(UnitContext, (unit) => unit.setNotificationResponse);
  const setMessageNotificationFeedback = useContextSelector(UnitContext, (unit) => unit.setMessageNotificationFeedback);
  const messageNotificationFeedBack = useContextSelector(UnitContext, (unit) => unit.messageNotificationFeedBack);
  const setOpenNotificationFeedback = useContextSelector(UnitContext, (unit) => unit.setOpenNotificationFeedback);
  const openNotificationFeedBack = useContextSelector(UnitContext, (unit) => unit.openNotificationFeedBack);

  return {
    setGroupId,
    getUnits,
    createUnitHandler,
    upsertMeasuringPointHandler,
    updateUnitHandler,
    deleteUnitHandler,
    groupName,
    units,
    setNotificationResponse,
    setMessageNotificationFeedback,
    messageNotificationFeedBack,
    setOpenNotificationFeedback,
    openNotificationFeedBack,
    loading,
  };
};

const bindHistoryIdentifiers = (
  unitHistory: PaddedUnitHistory,
  now: Date,
  unitId: string,
  existingUnitHistory: UnitPayload['history'],
): EditUnitConsumptionHistoryGraphQLInput['history'] => {
  return (Object.keys(unitHistory) as (keyof typeof unitHistory)[]).map((month, index) => {
    const id: string = existingUnitHistory[month].id;
    const peak: number = unitHistory[month].peak;
    const offPeak: number = unitHistory[month].offPeak;
    const generator: number = unitHistory[month].generator;

    const monthNumber = index + 1;
    const yearForHistory = monthNumber > now.getMonth() ? now.getFullYear() - 1 : now.getFullYear();

    return {
      id,
      unitId,
      yearMonth: `${yearForHistory}-${monthNumber.toString().padStart(2, '0')}-01`,
      consumptionOffPeak: isNaN(offPeak) ? 0 : offPeak,
      consumptionPeak: isNaN(peak) ? 0 : peak,
      generator: isNaN(generator) ? 0 : generator,
    };
  });
};

export function useCreateUnitConsumptionHistory() {
  const [createUnitHistoryMutation] = useMutation<
    UpsertUnitConsumptionHistoryGraphQLResponse,
    { upsertUnitHistoryInput: CreateUnitConsumptionHistoryGraphQLInput }
  >(UPSERT_UNIT_CONSUMPTION_HISTORY);

  const createUnitConsumptionHistory = async (unit: UnitPayload, now: Date, unitId: string) => {
    const paddedUnitHistory = buildPaddedUnitHistory(unit);
    const historyInput = parsePaddedUnitHistoryIntoGraphQLInput(paddedUnitHistory, now, unitId);

    const { data, errors } = await createUnitHistoryMutation({
      variables: { upsertUnitHistoryInput: { history: historyInput } },
    });

    if (errors !== undefined || !data) return { id: '' };

    const result = data.createHistory;
    return result;
  };
  return createUnitConsumptionHistory;
}

export function useEditUnitConsumptionHistory() {
  const [mutationFunction] = useMutation<
    UpsertUnitConsumptionHistoryGraphQLResponse,
    { upsertUnitHistoryInput: EditUnitConsumptionHistoryGraphQLInput }
  >(UPSERT_UNIT_CONSUMPTION_HISTORY);

  const editUnitConsumptionHistory = async (
    unit: UnitPayload,
    now: Date,
    unitId: string,
    existingUnitHistory: UnitPayload['history'],
  ) => {
    const paddedUnitHistory = buildPaddedUnitHistory(unit);
    const historyInput = bindHistoryIdentifiers(paddedUnitHistory, now, unitId, existingUnitHistory);

    const { data, errors } = await mutationFunction({
      variables: { upsertUnitHistoryInput: { history: historyInput } },
    });

    if (errors !== undefined || !data) throw new Error(`Falha ao editar o histórico de consumo da unidade ${unitId}.`);

    const result = data.createHistory;
    return result;
  };
  return editUnitConsumptionHistory;
}

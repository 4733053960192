import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { FeedbackNotification, Layout, PopUpListWithIcon } from '@clarke-energia/foton';

import {
  HOME_PATH,
  CREATE_ENERGRY_CONTRACT_PATH,
  CREATE_PROINFA_CONTRACT_PATH,
  CREATE_CCEE_CONTRACT_PATH,
  CREATE_CLARKE_CONTRACT_PATH,
} from '@routers/constants';

import EnergyContractProvider from '@contexts/energy-contract';
import ProinfaProvider from '@contexts/proinfa-contract';
import CceeContractProvider from '@contexts/ccee-contract';
import ClarkeContractProvider from '@contexts/clarke-contract';
import { useNotification } from '@hooks/use-notification';

import ClarkeIcon from '@assets/clarke-white-and-black-logo.svg';
import CCEEIcon from '@assets/ccee-logo.svg';

import Tab from '@components/molecules/general/tab';
import EnergyContractsList from '@components/molecules/energy-contract/list';
import CceeContractList from '@components/molecules/ccee-contract/list';
import ProinfaList from '@components/molecules/proinfa/list';
import ClarkeContractList from '@components/molecules/clarke-contract/list';
import { contractFeedbackMessage, contractsTabs, ContractType, PopUpItems } from './helper';

const Contracts: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedback = location?.state?.message;
  const currentTab = location?.state?.tab;

  const {
    openNotificationFeedBack,
    setOpenNotificationFeedback,
    messageNotificationFeedBack,
    setNotificationResponse,
  } = useNotification();

  const [typedSearchTerm, setTypedSearchTerm] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<ContractType>('ENERGY');
  const [openContractSelector, setOpenContractSelector] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (currentTab && feedback) {
      setSelectedTab(currentTab);
      setNotificationResponse(contractFeedbackMessage(currentTab, feedback));
    }
    navigate(location.pathname, { state: null, replace: true });
  }, [feedback, currentTab]);

  const popUpItems: PopUpItems[] = [
    {
      label: 'CCEAL',
      iconName: 'DocumentTextIcon',
      onClick: () => {
        navigate(CREATE_ENERGRY_CONTRACT_PATH);
      },
    },
    {
      label: 'Contrato CCEE',
      iconPath: CCEEIcon,
      iconName: null,
      onClick: () => navigate(CREATE_CCEE_CONTRACT_PATH),
    },
    {
      label: 'PROINFA',
      iconName: 'BoltIcon',
      onClick: () => navigate(CREATE_PROINFA_CONTRACT_PATH),
    },
    {
      label: 'Contrato Clarke',
      iconPath: ClarkeIcon,
      iconName: null,
      onClick: () => navigate(CREATE_CLARKE_CONTRACT_PATH),
    },
  ];

  const renderList = () => {
    if (selectedTab === 'ENERGY') {
      return (
        <EnergyContractProvider>
          <EnergyContractsList typedSearchTerm={typedSearchTerm} />
        </EnergyContractProvider>
      );
    }

    if (selectedTab === 'CCEE') {
      return (
        <CceeContractProvider>
          <CceeContractList typedSearchTerm={typedSearchTerm} />
        </CceeContractProvider>
      );
    }

    if (selectedTab === 'PROINFA')
      return (
        <ProinfaProvider>
          <ProinfaList typedSearchTerm={typedSearchTerm} />
        </ProinfaProvider>
      );

    return (
      <ClarkeContractProvider>
        <ClarkeContractList typedSearchTerm={typedSearchTerm} />
      </ClarkeContractProvider>
    );
  };

  return (
    <Layout
      title="Contratos"
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Contratos',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Adicionar',
          icon: openContractSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
          iconPosition: 'right',
          size: 'small',
          onClick: () => setOpenContractSelector(!openContractSelector),
        },
      ]}
      searchBar={{ onTypedTermChangedCallback: (term) => setTypedSearchTerm(term), placeholder: 'Buscar' }}
      className="relative"
      darkerBackground
    >
      <PopUpListWithIcon
        items={popUpItems}
        show={openContractSelector}
        className="absolute -mt-8 left-[10%] lg:left-[82%] sm:top-32"
      />
      <div className="absolute right-0 bottom-0 my-6 mx-6 max-w-full z-100 lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full h-full">
        <div className="flex">
          {contractsTabs.map(({ title, type }) => (
            <Tab
              label={title}
              isActive={type === selectedTab}
              onClick={() => setSelectedTab(type)}
              disabled={false}
              key={title}
            />
          ))}
        </div>
        {renderList()}
      </div>
    </Layout>
  );
};

export default Contracts;

import { gql } from '@apollo/client';

export const CREATE_ENERGY_CONTRACT = gql`
  mutation Mutation($input: CreateEnergyContractInput) {
    createEnergyContract(input: $input) {
      id
    }
  }
`;

export const DELETE_ENERGY_CONTRACT = gql`
  mutation DeleteEnergyContract($input: DeleteEnergyContractInput) {
    deleteEnergyContract(input: $input) {
      id
    }
  }
`;

export const DELETE_ENERGY_CONTRACT_ATTACHMENT = gql`
  mutation Mutation($input: DeleteEnergyContractAttachmentsInput) {
    deleteEnergyContractAttachments(input: $input)
  }
`;

export const UPDATE_ENERGY_CONTRACT = CREATE_ENERGY_CONTRACT;

import { useContextSelector } from 'use-context-selector';
import { UnitEconomyReportContextType, UnitEconomyReportContext } from '@contexts/unit-economy-report';

const useUnitEconomyReports = (): UnitEconomyReportContextType => {
  const loading = useContextSelector(UnitEconomyReportContext, (report) => report.loading);
  const unitReport = useContextSelector(UnitEconomyReportContext, (report) => report.unitReport);
  const getUnitEconomyReportHandler = useContextSelector(
    UnitEconomyReportContext,
    (report) => report.getUnitEconomyReportHandler,
  );

  return {
    loading,
    unitReport,
    getUnitEconomyReportHandler,
  };
};

export default useUnitEconomyReports;

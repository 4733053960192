import React from 'react';

import { useFormContext } from 'react-hook-form';

import { TraderTypeEnum } from '@contexts/trader';
import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { ContractTypeEnum, EnergyTypeEnum } from '@utils/translators/proposal';

import { CheckboxFormFieldContract } from '@components/atoms/form/fields/checkbox-form-field-contract';
import { FormField, SelectFormField, NumericFormField, FormSection } from '@components/atoms/form';
import { ComboBoxFormField } from '@components/atoms/form/combobox';
import { inputOptions } from '@contexts/energy-contract';

interface ContractSectionProps {
  proposalsSelectField: inputOptions[];
  isLoadingProposals: boolean;
  setProposalPage: React.Dispatch<React.SetStateAction<number>>;
  traderOptions: {
    optionLabel: string;
    value: string;
  }[];
}

const FormContractSection = ({
  proposalsSelectField,
  isLoadingProposals,
  setProposalPage,
  traderOptions,
}: ContractSectionProps): JSX.Element => {
  const { watch } = useFormContext();
  const contractId = watch('id');
  const coverMeterSystem = watch('coverMeterSystem');
  const traderType = watch('traderType');
  const includeCceeCharges = watch('includeCceeCharges');

  const isRetailTrader = TraderTypeEnum[traderType as keyof typeof TraderTypeEnum] === TraderTypeEnum.RETAIL_TRADER;

  const importProposalsFieldOptions = [{ label: 'Não', value: 'NONE' }, ...proposalsSelectField];
  const loadingOption = [{ label: 'Buscando propostas...', value: '' }];
  const dateInputFormatProps = {
    placeholder: 'DD/MM/AAAA',
    format: '##/##/####',
    mask: '_',
    allowNegative: false,
    fixedDecimalScale: false,
    decimalSeparator: undefined,
    thousandSeparator: undefined,
    decimalScale: 0,
    isNumericString: true,
  };

  const traderTypeList = [
    {
      value: 'RETAIL_TRADER',
      optionLabel: 'Varejista',
    },
    { value: 'WHOLESALE_TRADER', optionLabel: 'Atacadista' },
  ];

  const handleScrollComboBox = () => setProposalPage((prevPage) => prevPage + 1);

  return (
    <>
      <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
        <FormSection
          title="Características contratuais"
          subtitle="Informações sobre o contrato de energia assinado pelo cliente."
        />
        <ComboBoxFormField<CreateEnergyContractPayload>
          label="Importar de proposta existente?"
          field="proposalId"
          id="proposalId"
          inputOptions={isLoadingProposals ? loadingOption : importProposalsFieldOptions}
          isLoading={isLoadingProposals}
          onMenuScrollToBottom={handleScrollComboBox}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />
        {contractId && (
          <FormField<CreateEnergyContractPayload>
            id="id"
            label="ID do CCEAL"
            field="id"
            disabled
            options={{ value: true }}
            placeholder="ID gerado automaticamente"
          />
        )}
        <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
          <SelectFormField<CreateEnergyContractPayload>
            label="Fornecedora"
            field="retailTraderId"
            id="retailTraderId"
            inputOptions={traderOptions}
            options={{ required: { value: true, message: 'Campo é obrigatório' } }}
          />
          <SelectFormField<CreateEnergyContractPayload>
            label="Tipo de fornecedora"
            field="traderType"
            id="traderType"
            inputOptions={traderTypeList}
            options={{ required: { value: true, message: 'Tipo do fornecedor é obrigatório' } }}
          />
          <SelectFormField
            label="Tipo de contrato"
            field="contractType"
            id="contractType"
            inputOptions={Object.keys(ContractTypeEnum).map((key) => ({
              optionLabel: ContractTypeEnum[key as keyof typeof ContractTypeEnum],
              value: key,
            }))}
            options={{ required: { value: true, message: 'Tipo de é obrigatório' } }}
          />

          <NumericFormField<CreateEnergyContractPayload>
            id="startSupplyDate"
            field="startSupplyDate"
            label="Início do suprimento"
            formatProps={dateInputFormatProps}
            options={{
              required: { value: true, message: 'Início do suprimento é obrigatório' },
            }}
          />
          <NumericFormField<CreateEnergyContractPayload>
            id="endSupplyDate"
            field="endSupplyDate"
            label="Fim do suprimento"
            formatProps={dateInputFormatProps}
            options={{ required: { value: true, message: 'Fim do suprimento é obrigatório' } }}
          />
          <NumericFormField<CreateEnergyContractPayload>
            id="lowerFlexibility"
            label="Flexibilidade inferior (%)"
            field="lowerFlexibility"
            formatProps={{
              placeholder: '%',
              suffix: '%',
              prefix: '-',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 0,
            }}
            options={{ required: { value: true, message: 'Defina o valor da flexibilidade inferior' } }}
          />
          <NumericFormField<CreateEnergyContractPayload>
            id="upperFlexibility"
            label="Flexibilidade superior (%)"
            field="upperFlexibility"
            formatProps={{
              placeholder: '%',
              suffix: '%',
              prefix: '+',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 0,
            }}
            options={{ required: { value: true, message: 'Defina o valor da flexibilidade superior' } }}
          />
          <SelectFormField<CreateEnergyContractPayload>
            label="Tipo de energia"
            field="energyType"
            id="energyType"
            inputOptions={Object.keys(EnergyTypeEnum).map((key) => ({
              optionLabel: EnergyTypeEnum[key as keyof typeof EnergyTypeEnum],
              value: key,
            }))}
            options={{ required: { value: true, message: 'Tipo de energia é obrigatório' } }}
          />
          <SelectFormField<CreateEnergyContractPayload>
            label="Cobertura da adequação do SMF"
            field="coverMeterSystem"
            id="coverMeterSystem"
            inputOptions={[
              { optionLabel: 'Sim', value: 'true' },
              { optionLabel: 'Não', value: 'false' },
            ]}
            options={{ required: { value: true, message: 'Cobertura SMF é obrigatório' } }}
          />
          {coverMeterSystem === 'true' && (
            <NumericFormField<CreateEnergyContractPayload>
              field="meterSystemAmount"
              id="meterSystemAmount"
              label="Valor adequação do SMF"
              formatProps={{
                placeholder: 'Insira valor da taxa',
                prefix: 'R$',
                allowNegative: true,
                fixedDecimalScale: true,
                decimalScale: 2,
              }}
              options={{
                required: {
                  value: coverMeterSystem === 'false',
                  message: 'Valor cobertura SMF é obrigatório',
                },
              }}
            />
          )}
          <NumericFormField<CreateEnergyContractPayload>
            id="losts"
            label="Perdas"
            field="losts"
            formatProps={{
              placeholder: '%',
              suffix: ' %',
              allowNegative: false,
              fixedDecimalScale: true,
              decimalScale: 2,
            }}
          />
          {isRetailTrader && (
            <CheckboxFormFieldContract<CreateEnergyContractPayload>
              inputOptions={[
                {
                  id: 'includeCceeCharges',
                  optionLabel: 'Repasse de encargos CCEE',
                  value: 'true',
                  defaultChecked: includeCceeCharges,
                },
              ]}
              field="includeCceeCharges"
              label=""
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FormContractSection;

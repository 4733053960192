import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Table, ColumnOptions } from '@clarke-energia/foton';
import { CceeProfile } from '@contexts/ccee-profile/types';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface CceeProfileTableProps {
  isFetchingProfiles: boolean;
  data: any[];
  columns: ColumnOptions<CceeProfile>[];
}

const CceeProfileTable: React.FC<CceeProfileTableProps> = ({ isFetchingProfiles, data, columns }) => {
  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-auto">
        {isFetchingProfiles ? <RowSkeleton /> : <Table data={data} tableColumns={columns} className="border-top" />}
      </div>
    </div>
  );
};

export default CceeProfileTable;

import { gql } from '@apollo/client';
import { FINANCIAL_EVENT_SCHEMA } from './queries';

export const MUTATION_SEND_FINANCIAL_EVENTS_BY_BATCH_ID = gql`
  mutation SendFinancialEventsByBatchID($id: ID!) {
    sendFinancialEventsByBatchID(id: $id)
  }
`;

export const MUTATION_SEND_FINANCIAL_EVENTS_BY_IDS_LIST = gql`
  mutation SendFinancialEventsByIDsList($idsList: [ID]!) {
    sendFinancialEventsByIDsList(idsList: $idsList)
  }
`;

export const MUTATION_UPDATE_FINANCIAL_EVENT_ATTACHMENT = gql`
  mutation UpdateFinancialEventAttachment($financialEventId: ID!, $attachmentId: ID) {
    updateFinancialEventAttachment(financialEventId: $financialEventId, attachmentId: $attachmentId)
  }
`;

export const MUTATION_UPDATE_FINANCIAL_EVENT_TARGET_USERS = gql`
  mutation UpdateFinancialEventTargetUsers($id: ID!) {
    updateFinancialEventTargetUsers(id: $id) ${FINANCIAL_EVENT_SCHEMA}
  }
`;

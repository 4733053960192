import React from 'react';
import { EnergyAuditFormated } from '@contexts/energy-audit';
import { formatAsMWh } from '@utils/text';

interface CustomerEmailTemplateProps {
  energyAudit: EnergyAuditFormated;
}

const TraderEmailTemplate: React.FC<CustomerEmailTemplateProps> = ({ energyAudit }) => {
  const unitsNames = energyAudit.units.map((item) => item.unit.name);

  return (
    <div>
      <main>
        <header>
          <h1 className="mb-5 font-bold text-heading-medium">{`Balanço Energético de ${energyAudit.group.name} – ${energyAudit.date}`}</h1>
          <section className="mb-5 text-paragraph-medium">
            <h2 className="mb-6">{`Olá, ${energyAudit.energyContract.trader.name ?? 'fornecedor'}`}</h2>
            <p>
              <span>
                Abaixo é possível conferir o balanço energético das unidades
                <strong>{unitsNames.join(', ')}</strong> de <strong>{energyAudit.date}.</strong>
              </span>
            </p>
          </section>
        </header>
        <section>
          {energyAudit.units.map((item) => (
            <div key={item.unit.id} className="pt-4 mb-5 border-black border-t-[1px]">
              <div className="font-bold text-paragraph-medium">
                <span>{item.unit.name}</span>
              </div>
              <section className="flex gap-2 text-paragraph-medium">
                <span className="font-bold">Perfil:</span>
                <span>{item.unit.cceeProfile.name}</span>
                <span>{'|'}</span>
                <span className="font-bold">ID:</span>
                <span>{item.unit.cceeProfile.idCcee}</span>
              </section>
              <div className="flex justify-between text-paragraph-small">
                <span>Consumo (A)</span>
                <span>{item.consumptionAmount}</span>
              </div>
              <div className="flex justify-between text-paragraph-small">
                <span>Perdas (B)</span>
                <span>{item.lostsAmount}</span>
              </div>
              <div className="flex justify-between text-paragraph-small">
                <span>PROINFA (C)</span>
                <span>{item.proinfa}</span>
              </div>
              <div className="flex justify-between text-paragraph-small">
                <span>Necessidade (A+B-C)</span>
                <span>{item.neededAmount}</span>
              </div>
            </div>
          ))}
          <section className="pt-4 mb-5 border-black border-t-[1px]">
            <div className="flex justify-between font-bold">
              <span className="text-paragraph-large">Necessidade Total</span>
              <span className="text-heading-medium">
                {formatAsMWh(energyAudit.neededTotal ? energyAudit.neededTotal : 0)}
              </span>
            </div>
          </section>
          <section className="pt-4 mb-5 border-black border-t-[1px]">
            <section>
              <div className="flex justify-between">
                <span className="font-bold text-paragraph-large">{`Sazo ${energyAudit.date}`}</span>
                <span className="text-heading-xsmall">{energyAudit.energyContract.seasonality}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-bold text-paragraph-large">{`Limite Superior (+${energyAudit.energyContract.upperFlexibility})`}</span>
                <span className="text-heading-xsmall">{energyAudit.energyContract.upperFlexTotal}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-bold text-paragraph-large">{`Limite Superior (-${energyAudit.energyContract.lowerFlexibility})`}</span>
                <span className="text-heading-xsmall">{energyAudit.energyContract.lowerFlexTotal}</span>
              </div>
            </section>
          </section>
          <section className="pt-4 mb-5 border-black border-t-[1px]">
            <div className="flex justify-between font-bold">
              <span className="text-paragraph-large max-w-[10rem]">Flex faturada</span>
              <span className="text-heading-medium">{energyAudit.energyContract.billedFlex}</span>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
};

export default TraderEmailTemplate;

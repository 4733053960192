import React from 'react';
import { Button } from '@clarke-energia/foton';

import { SelectedFilter } from '@components/molecules/energy-audit-report/list';
import PopUpFilter from './pop-filter';

interface ButtonFilter {
  label: string;
  handleFilterVisibility: () => void;
  setSelectedFilter: any;
  selectedFilter: any;
  showOptions: boolean;
  isMultiSelect?: boolean;
  options: {
    value: any;
    label: string;
  }[];
}

export interface FiltersBarProps {
  btnsFilters: ButtonFilter[];
  clearOptions: () => void;
}

const FiltersBar: React.FC<FiltersBarProps> = ({ btnsFilters, clearOptions }) => {
  const handlePopupOnchange = (targetButton: ButtonFilter, item: SelectedFilter | SelectedFilter[]) => {
    targetButton.handleFilterVisibility();

    if (targetButton?.isMultiSelect && Array.isArray(item)) {
      targetButton.setSelectedFilter((prev: any[]) => {
        if (Array.isArray(prev)) {
          return [...prev, ...item];
        } else {
          return [prev, ...item];
        }
      });
    } else {
      targetButton.setSelectedFilter(item);
    }
  };

  return (
    <div className="flex mb-4 lg:mb-7 w-fit">
      {btnsFilters.map((item) => (
        <div className="relative" key={item.label}>
          <Button
            kind={item.selectedFilter ? 'chip' : 'secondary'}
            label={item.label}
            icon={item.showOptions ? 'ChevronUpIcon' : 'ChevronDownIcon'}
            iconPosition="right"
            size="small"
            onClick={item.handleFilterVisibility}
            className="mr-4"
          />
          <PopUpFilter
            options={item.options}
            isMultiSelect={item?.isMultiSelect}
            onChange={(e) => handlePopupOnchange(item, e)}
            show={item.showOptions}
            value={item.selectedFilter?.value ?? ''}
          />
        </div>
      ))}
      <Button kind="ghost" label="Limpar filtros" size="small" onClick={clearOptions} />
    </div>
  );
};

export default FiltersBar;

import { TradersListGraphQLData, Trader, Document } from './types';

export class TradersManager {
  rawData: TradersListGraphQLData;
  traders: Trader[];

  constructor(rawData: TradersListGraphQLData) {
    this.rawData = rawData;
    this.traders = rawData.traders.data;
  }

  parseTraders(): Trader[] {
    const parsedTradersData = this.traders.map(({ documents, logoName, ...rest }) => ({
      logoName: documents?.length ? this.filterLogoInDocList(documents) : '',
      ...rest,
    }));

    return parsedTradersData;
  }

  filterLogoInDocList(docs: Document[]): string {
    return docs.filter(({ docType }) => docType === 'LOGO_IMAGE')[0].label;
  }
}

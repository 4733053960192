import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  ButtonIcon,
  ColumnOptions,
  FeedbackNotification,
  GeneralGadget,
  HorizontalGadget,
  Layout,
  PopUpList,
  Table,
  Tag,
  TagProps,
} from '@clarke-energia/foton';

import { ENERGY_AUDIT_PATH, HOME_PATH } from '@routers/constants';

import { EnergyAuditStatus, EnergyAuditUnitsFormated, IEnergyAuditSendingStatus } from '@contexts/energy-audit';
import { filterEmailsByCategorie, formatGadgetContent } from '@contexts/energy-audit/parser';

import useGroupUsers from '@hooks/use-user-groups';
import useTraderUsers from '@hooks/use-user-trader';
import useEnergyAudits from '@hooks/use-energy-audit';
import { RolesTypes } from '@utils/translators/users-roles';

import { EnergyAuditDetailSkeleton } from '@components/molecules/skeleton/energy-detailed';
import EmailDrawer from '@components/molecules/energy-audit/email-drawer';
import EnergyAuditsTraderSummary from '@components/molecules/energy-audit/energy-audit-trader-summary';
import EnergyAuditsContractSummary from '@components/molecules/energy-audit/energy-audit-contract-summary';
import { getPrepareAuditToBeSentFeedbackContent } from './static-data';

type renderFunction = ColumnOptions<EnergyAuditUnitsFormated>['renderAsElement'];
const renderUnitName: renderFunction = (entry) => entry.unit.name;
const renderConsumption: renderFunction = (entry) => entry.consumptionAmount;
const renderLosts: renderFunction = (entry) => entry.lostsAmount;
const renderProinfa: renderFunction = (entry) => entry.proinfa;
const renderOverplusOrExposureAmount: renderFunction = (entry) => entry.overplusOrExposureAmount;

const DetailedEnergyAudit: React.FC = () => {
  const navigate = useNavigate();
  const { setCommercialGroupId, groupUsers } = useGroupUsers();
  const { setTraderId, traderUsers } = useTraderUsers();
  const { energyAuditId } = useParams();
  const {
    energyAuditById,
    setEnergyAuditId,
    loading,
    prepareEnergyAuditToBeSentHandler,
    setOpenNotificationFeedBack,
    openNotificationFeedBack,
    openPeriodSelector,
    setOpenPeriodSelector,
    groupPeriods,
    getEnergyAuditByEnergyContractIdStatusAndDateHandler,
    rawDate,
  } = useEnergyAudits();

  const [openEmailDrawer, setOpenEmailDrawer] = useState<boolean>(false);
  const [feedbackNotificationStatus, setFeedbackNotificationStatus] = useState<IEnergyAuditSendingStatus>('UPDATING');

  const renderStatus: renderFunction = (entry) => {
    const color = entry.consumptionAmount === '-' && entry.missingHours ? 'yellow' : 'green';
    const label = entry.missingHours ? `${entry.missingHours}h horas faltantes` : 'Completo';

    return <Tag color={color} kind={'default'} label={label} />;
  };
  const renderActionIcon: renderFunction = (entry) => {
    return (
      <ButtonIcon
        kind={'ghost'}
        icon={'EyeIcon'}
        onClick={() =>
          navigate({
            pathname: `/balanco-energetico/${energyAuditById?.id}/medicoes/${entry?.unit?.id}`,
            search: `?date=${rawDate}`,
          })
        }
      />
    );
  };

  const energyAuditUnitsColumnDefinitions: Array<ColumnOptions<EnergyAuditUnitsFormated>> = [
    {
      accessor: 'unit',
      header: 'Unidade',
      renderAsElement: renderUnitName,
    },
    {
      accessor: 'consumptionAmount',
      header: 'Consumo',
      renderAsElement: renderConsumption,
    },
    {
      accessor: 'lostsAmount',
      header: 'Perdas',
      renderAsElement: renderLosts,
    },
    {
      accessor: 'proinfa',
      header: 'PROINFA',
      renderAsElement: renderProinfa,
    },
    {
      accessor: 'overplusOrExposureAmount',
      header: 'Sobra / Exposição',
      renderAsElement: renderOverplusOrExposureAmount,
    },
    {
      header: 'Status',
      renderAsElement: renderStatus,
      fixedWidthInRem: 8.5,
    },
    {
      renderAsElement: renderActionIcon,
      fixedWidthInRem: 5,
    },
  ];

  if (energyAuditId) setEnergyAuditId(energyAuditId);

  const periods = useMemo(() => {
    return groupPeriods
      .filter((period) => period.contractId === energyAuditById?.energyContract.rawId)
      .map((item) => ({
        label: item.label,
        onClick: () => {
          getEnergyAuditByEnergyContractIdStatusAndDateHandler({
            date: item.value,
            status: EnergyAuditStatus[item.status as keyof typeof EnergyAuditStatus],
            energyContractId: item.contractId,
          });
          setOpenPeriodSelector(false);
        },
      }));
  }, [groupPeriods, energyAuditById]);

  const emails = React.useMemo(() => {
    let groupEmails: string[] = [],
      traderEmails: string[] = [];
    let isLoading = true;

    const groupUser = groupUsers && groupUsers.length;
    const traderUser = traderUsers && traderUsers.length;

    if (groupUser) {
      groupEmails = filterEmailsByCategorie(groupUsers, RolesTypes.CONTATO_PRINCIPAL);
    }
    if (traderUser) {
      traderEmails = filterEmailsByCategorie(traderUsers, RolesTypes.FATURAMENTO);
    }

    isLoading = false;

    return { group: groupEmails, trader: traderEmails, isLoading };
  }, [groupUsers, traderUsers]);

  const dispatchEnergyAuditNotification = async () => {
    const response = await prepareEnergyAuditToBeSentHandler({
      auditId: energyAuditById?.id || '',
      groupEmails: emails.group,
      trader: {
        name: energyAuditById?.energyContract?.trader?.name || '',
        emails: emails.trader,
      },
    });
    if (!response) {
      setFeedbackNotificationStatus('UPDATED_FAILED');
      return;
    }
    setFeedbackNotificationStatus('UPDATED_SUCCESS');
  };

  const prepareAuditToBeSentFeedbackContent = getPrepareAuditToBeSentFeedbackContent(() =>
    setOpenNotificationFeedBack(false),
  );

  const incompleteAuditTag: TagProps =
    energyAuditById?.neededTotal === 0
      ? { color: 'red', kind: 'default', label: 'Balanço Incompleto', size: 'small' }
      : { color: 'green', kind: 'default', label: 'Balanço Completo', size: 'small' };

  if (loading || !energyAuditById) {
    return (
      <Layout darkerBackground loading>
        <EnergyAuditDetailSkeleton />
      </Layout>
    );
  }

  return (
    <Layout
      loading={loading}
      title={`${energyAuditById?.group?.name} (${energyAuditById?.energyContract?.id})`}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Balanço Energético',
          url: ENERGY_AUDIT_PATH,
        },
        {
          label: `${energyAuditById?.group?.name} (${energyAuditById?.energyContract?.id})`,
          url: '#',
        },
      ]}
      headerButtons={[
        {
          icon: openPeriodSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
          iconPosition: 'right',
          kind: 'secondary',
          label: `${energyAuditById.date}`,
          size: 'small',
          onClick: () => setOpenPeriodSelector(!openPeriodSelector),
        },
        {
          kind: 'primary',
          label: 'Enviar balanço',
          size: 'small',
          disabled: energyAuditById.status !== EnergyAuditStatus.READY_TO_SEND,
          className: energyAuditById.status !== EnergyAuditStatus.SENT ? 'bg-[#F2F2F2] hover:bg-[#F2F2F2]' : '',
          onClick: () => {
            setOpenEmailDrawer(true);
            if (energyAuditById && Object.keys(energyAuditById).length) {
              const { group, energyContract } = energyAuditById;
              setCommercialGroupId(group.id);
              if (energyContract.trader.id) {
                setTraderId(energyContract.trader.id);
              }
            }
          },
        },
      ]}
      className="relative"
      darkerBackground
    >
      {periods && (
        <PopUpList
          items={periods}
          show={openPeriodSelector}
          className="absolute mr-8 -mt-8 sm:top-32 sm:right-[8rem]"
        />
      )}
      <div className="col-span-full mb-6 h-full">
        <div className="flex justify-between">
          <h1 className="px-5 mb-5 sm:px-0 text-heading-large">{`Balanço de ${energyAuditById?.date}`}</h1>
          <p className="self-center text-sm text-neutral-50">{energyAuditById.notifiedAt}</p>
        </div>
        <HorizontalGadget
          title="Balanço total"
          gadgetContent={formatGadgetContent(energyAuditById)}
          tagProps={incompleteAuditTag}
        />
      </div>
      <GeneralGadget className="col-span-full mb-8" title={'Resultado por unidade'}>
        <div className="overflow-y-auto w-full h-fit max-h-[15rem]">
          <Table<EnergyAuditUnitsFormated>
            tableColumns={energyAuditUnitsColumnDefinitions}
            data={energyAuditById?.units || []}
          />
        </div>
      </GeneralGadget>
      <h1 className="col-span-full px-5 mb-5 sm:px-0 text-heading-large">{`Contratos de energia`}</h1>
      <div className="grid grid-cols-1 col-span-full gap-6 mb-6 h-full sm:grid-cols-2">
        <GeneralGadget className="mb-8" title={`Contrato ${energyAuditById?.energyContract?.id}`}>
          <div className="overflow-y-auto mb-7 w-full h-fit max-h-[15rem]">
            <h2 className="col-span-full mb-4 text-heading-xsmall">{'Dados da fornecedora'}</h2>
            <EnergyAuditsTraderSummary energyAuditTrader={energyAuditById?.energyContract?.trader} />
          </div>
          <div className="overflow-y-auto mb-6 w-full h-fit max-h-[15rem]">
            <h2 className="col-span-full mb-4 text-heading-xsmall">{'Dados do contrato'}</h2>
            <EnergyAuditsContractSummary energyContract={energyAuditById?.energyContract} />
          </div>
        </GeneralGadget>
        <EmailDrawer
          subtitle={`${energyAuditById?.date} – ${energyAuditById?.group.name} (${energyAuditById?.energyContract?.id})`}
          actionBtnClick={() => {
            if (energyAuditById.id) {
              setOpenNotificationFeedBack(true);
              dispatchEnergyAuditNotification();
              setOpenEmailDrawer(false);
            }
          }}
          cancelBtnClick={() => setOpenEmailDrawer(false)}
          openDrawer={openEmailDrawer}
          emails={emails}
          energyAudit={energyAuditById}
        />
      </div>
      <div className="fixed bottom-0 right-6 z-20 my-6 mx-6 min-w-[35rem] lg:w-fit">
        {openNotificationFeedBack && (
          <FeedbackNotification
            label={prepareAuditToBeSentFeedbackContent[feedbackNotificationStatus].label}
            message={prepareAuditToBeSentFeedbackContent[feedbackNotificationStatus].message}
            kind={prepareAuditToBeSentFeedbackContent[feedbackNotificationStatus].kind}
            onCloseNotification={prepareAuditToBeSentFeedbackContent[feedbackNotificationStatus].onCloseNotification}
          />
        )}
      </div>
    </Layout>
  );
};

export default DetailedEnergyAudit;

import React, { useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';
import { AxiosProgressEvent } from 'axios';

import { useAuth } from '@src/ApolloWrapper';
import { processProinfaFile } from '@services/customerAPI';
import { CommercialGroup } from '@contexts/commercial-group/types';
import {
  ProcessProinfaFormData,
  ProinfaConfirmationStatusPayload,
  ProinfaParams,
  ProinfaYearOptionsParams,
  ProinfaRest,
  ProinfaTableData,
} from './types';
import { GET_ALL_PROINFAS_QUERY, GET_YEAR_OPTIONS_PROINFAS_QUERY, GET_GROUPS_DATA_QUERY } from './queries';
import { UPDATE_PROINFA_CONFIMATION_STATUS } from './mutation';
import { parserTableData } from './parser';

export type ProinfaContractContextType = {
  processProinfaFileHandler: (data: ProcessProinfaFormData) => Promise<boolean>;
  updateConfirmationStatusHandler(data: ProinfaConfirmationStatusPayload): Promise<boolean>;
  getProinfaListHandler(params: ProinfaParams): Promise<QueryResult<any, OperationVariables>>;
  getProinfaYearOptionsListHandler(params: ProinfaYearOptionsParams): Promise<QueryResult<any, OperationVariables>>;
  proinfaList: ProinfaTableData[];
  fileProcessingProgress: number;
  loading: boolean;
  totalOfProinfas: number;
  setFileProcessingProgress: React.Dispatch<React.SetStateAction<number>>;
  commercialGroups: CommercialGroup[];
  wasCreated: boolean;
};

export const ProinfaContext = createContext({} as ProinfaContractContextType);

interface ProviderProps {
  children: React.ReactNode;
}

const ProinfaProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const [commercialGroups, setCommercialGroups] = useState<Array<CommercialGroup>>([]);
  const [proinfaList, setProinfaList] = useState<ProinfaTableData[]>([]);
  const [fileProcessingProgress, setFileProcessingProgress] = useState<number>(0);
  const [totalOfProinfas, setTotalOfProinfas] = useState<number>(0);
  const [wasCreated, setWasCreated] = useState<boolean>(false);

  const [updateConfirmationStatusProinfa, { loading: loadingUpdateStatus }] = useMutation(
    UPDATE_PROINFA_CONFIMATION_STATUS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getAllProinfas, { loading: loadingList }] = useLazyQuery(GET_ALL_PROINFAS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const parsedData = parserTableData(data.getProinfas.data);
      setProinfaList(parsedData);
      setTotalOfProinfas(data.getProinfas.total);
    },
    onError: () => {
      setProinfaList([]);
    },
  });

  const [getYearOptionsProinfas] = useLazyQuery(GET_YEAR_OPTIONS_PROINFAS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getCommercialGroups, { called: calledCommercialGroups }] = useLazyQuery(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCommercialGroups(data.groups.data);
    },
    onError: () => {
      setCommercialGroups([]);
    },
  });

  if (!calledCommercialGroups) getCommercialGroups();

  async function processProinfaFileHandler(data: ProcessProinfaFormData) {
    try {
      const requestProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = (progressEvent) => {
        const progressPercentage = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));

        setFileProcessingProgress(progressPercentage);

        return fileProcessingProgress;
      };

      const processedFile = await processProinfaFile(data, accessToken, requestProgress);
      const dataResponse: ProinfaRest[] = processedFile.data.data;
      const formattedToCamelCaseKeys = dataResponse.map((item) => ({
        monthlyValues: item.monthly_values,
        year: item.year,
        id: item.id,
        unit: item.unit,
      }));

      const parserData = parserTableData(formattedToCamelCaseKeys);
      setProinfaList(parserData);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function updateConfirmationStatusHandler(data: ProinfaConfirmationStatusPayload) {
    try {
      await updateConfirmationStatusProinfa({
        variables: {
          input: data,
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  function getProinfaListHandler(params: ProinfaParams) {
    return getAllProinfas({
      variables: {
        input: params,
      },
    });
  }

  async function getProinfaYearOptionsListHandler(params: ProinfaYearOptionsParams) {
    return await getYearOptionsProinfas({
      variables: {
        input: params,
      },
    });
  }

  return (
    <ProinfaContext.Provider
      value={{
        processProinfaFileHandler,
        setFileProcessingProgress,
        totalOfProinfas,
        getProinfaListHandler,
        getProinfaYearOptionsListHandler,
        updateConfirmationStatusHandler,
        proinfaList,
        loading: loadingUpdateStatus || loadingList,
        fileProcessingProgress,
        wasCreated,
        commercialGroups,
      }}
    >
      {children}
    </ProinfaContext.Provider>
  );
};

export default ProinfaProvider;

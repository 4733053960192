import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Layout, Modal, Drawer, FeedbackNotification, PopUpList } from '@clarke-energia/foton';

import { ENERGY_AUDIT_PATH, HOME_PATH } from '@routers/constants';
import { DatetimeMeasurement, MonthlyControlMeasurementByUnitId, Source } from '@contexts/energy-audit-consumptions';
import useEnergyAuditConsumption from '@hooks/use-energy-audit-consumptions';
import { useNotification } from '@hooks/use-notification';

import { formatAsMWh, formatMonthAndYearDate, formatUuid } from '@utils/text';
import { formatDateFromString } from '@utils/dayjs';

import { MeasurementByUnitSkeleton } from '@components/molecules/skeleton/measurement-by-unit';
import MissingRedirectButtons from '@components/molecules/energy-audit/missing-buttons-consumption';
import ConsumptionsGagdegts from '@components/molecules/energy-audit/energy-audit-gadget-consumptions';
import MonthlyConsumptionTable from '@components/molecules/energy-audit/monthly-consumptions-table';
import ChartGadget from '@components/molecules/energy-audit/consumption-chart-gadget';
import SingleDayConsumptionsPerHour from '@components/molecules/energy-audit/day-per-hour-consumptions-drawer';

import { measurementsFeedbackContent } from './static-data';
import { diplayTagMissingHours, formatToMonthYear, sumActiveConsumption } from './helper';

const MeasurementByUnit: React.FC = () => {
  const navigate = useNavigate();
  const { energyAuditId, unitId } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  const {
    updateMeasurementAutomaticallyHandler,
    updateDailyConsumptionOfUnitsManuallyHandler,
    getMonthlyControlMeasurementByUnitIdHandler,
    getAllControlMeasurementByUnitIdHandler,
    getUnitConsumptionMeasuresPerHourHandler,
    monthlyControlMeasurementByUnitId,
    setConsolidatedMeasurementDocId,
    consolidatedMeasurementById,
    loading,
    handleDownloadExcel,
    unitConsumptionMeasuresPerHour,
    mutationLoading,
    consumptionsPeriods,
  } = useEnergyAuditConsumption();

  const {
    openNotificationFeedBack,
    setOpenNotificationFeedback,
    messageNotificationFeedBack,
    setNotificationResponse,
  } = useNotification();

  const initalDate = date ? formatMonthAndYearDate(date) : '';

  const [openFillLeftHoursAutomaticallyModal, setOpenFillLeftHoursAutomaticallyModal] = useState(false);
  const [openFillLeftHoursDrawer, setOpenFillLeftHoursDrawer] = useState<boolean>(false);

  const [measurementSelected, setMeasurementSelected] = useState<MonthlyControlMeasurementByUnitId>();
  const [dateTimeMeasurementFilteredBySubtype, setDateTimeMeasurementFilteredBySubtype] = useState<
    Array<DatetimeMeasurement>
  >([]);
  const [currentDate, setCurrentDate] = useState<string>(initalDate);

  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);

  const monthYear = date ? formatDateFromString(date, 'YYYY-MM-DD 00:00:00', 'MM/YYYY') : '';

  const unitName = monthlyControlMeasurementByUnitId ? monthlyControlMeasurementByUnitId[0]?.unit?.name : '';

  const groupName = monthlyControlMeasurementByUnitId
    ? `${monthlyControlMeasurementByUnitId[0].unit?.group?.name} (${formatUuid(
        monthlyControlMeasurementByUnitId[0].unit?.energyContract?.id,
      )})`
    : '';

  const downloadExcel = async () => {
    if (unitId && unitName) {
      const response = await handleDownloadExcel({ unitId, monthYear: monthYear, unitName });
      setNotificationResponse(measurementsFeedbackContent[response]);
    }
  };

  useEffect(() => {
    if (unitId) {
      getAllControlMeasurementByUnitIdHandler(unitId);
      getMonthlyControlMeasurementByUnitIdHandler(unitId, monthYear);
      getUnitConsumptionMeasuresPerHourHandler(unitId, monthYear);
    }
  }, [unitId]);

  useEffect(() => {
    if (consolidatedMeasurementById && !loading) {
      const filterBySubTypeLInKWh = consolidatedMeasurementById.datetimeMeasurement
        .filter((item) => item.subType === 'L')
        .map(({ activeConsumption, ...rest }) => ({
          ...rest,
          activeConsumption: `${parseFloat(activeConsumption) * 1000}`,
        }));
      setDateTimeMeasurementFilteredBySubtype(filterBySubTypeLInKWh);
    }
  }, [consolidatedMeasurementById]);

  const showMeasurementCard = useMemo(() => {
    let shouldShowCard = false;

    if (!unitConsumptionMeasuresPerHour) return shouldShowCard;

    unitConsumptionMeasuresPerHour.forEach((unit) => {
      const hasClarkeSource = unit.datetimeMeasurement.some(({ source }) => source === Source.clarke);
      if (hasClarkeSource) {
        shouldShowCard = true;
        return;
      }
    });

    return shouldShowCard;
  }, [unitConsumptionMeasuresPerHour]);

  const totalConsumption = useMemo(() => {
    const cceConsumption = sumActiveConsumption(unitConsumptionMeasuresPerHour, 'ccee');
    const projectedConsumption = sumActiveConsumption(unitConsumptionMeasuresPerHour, 'clarke');

    let totalProjectedConsumption = 0;

    if (projectedConsumption > 0) {
      totalProjectedConsumption = cceConsumption + projectedConsumption;
    }

    return {
      totalRead: cceConsumption,
      projected: totalProjectedConsumption,
    };
  }, [unitConsumptionMeasuresPerHour, monthlyControlMeasurementByUnitId]);

  const totalMissingHours = useMemo(() => {
    if (monthlyControlMeasurementByUnitId) {
      return monthlyControlMeasurementByUnitId.reduce((accumulator, object) => {
        return accumulator + object.totalMissingHours;
      }, 0);
    }
  }, [monthlyControlMeasurementByUnitId]);

  const consumptionsGagdegts = useMemo(() => {
    const gadgetContents = [
      {
        title: 'Consumo total lido',
        tagProps: diplayTagMissingHours(totalMissingHours, totalConsumption.projected),
        gadgetContent: [{ title: '', value: formatAsMWh(totalConsumption.totalRead) }],
      },
    ];

    if (showMeasurementCard) {
      gadgetContents.push({
        title: 'Consumo total projetado',
        tagProps: { color: 'gray', kind: 'default', label: `${totalMissingHours}h projetadas` },
        gadgetContent: [{ title: '', value: formatAsMWh(totalConsumption.projected) }],
      });
    }

    return gadgetContents;
  }, [showMeasurementCard, totalMissingHours, totalConsumption]);

  const syncConsumption = async () => {
    if (unitId) {
      setNotificationResponse(measurementsFeedbackContent.UPDATING_DAILY_CONSUMPTIONS);

      const response = await updateDailyConsumptionOfUnitsManuallyHandler(unitId, monthYear);

      setNotificationResponse(measurementsFeedbackContent[response]);
    }
  };

  const updateAutomaticConsumption = async () => {
    setOpenFillLeftHoursAutomaticallyModal(false);
    setNotificationResponse(measurementsFeedbackContent.UPDATING_MISSING_HOURS);
    if (unitId) {
      const response = await updateMeasurementAutomaticallyHandler(unitId, monthYear);
      setNotificationResponse(measurementsFeedbackContent[response]);
    }
  };

  if (loading) {
    return (
      <Layout darkerBackground loading>
        <MeasurementByUnitSkeleton />
      </Layout>
    );
  }

  return (
    <Layout
      title={groupName}
      loading={loading}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Balanço Energético',
          url: ENERGY_AUDIT_PATH,
        },
        {
          label: groupName,
          url: '#',
        },
      ]}
      headerButtons={[
        {
          icon: openPeriodSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
          iconPosition: 'right',
          kind: 'secondary',
          label: currentDate,
          size: 'small',
          onClick: () => setOpenPeriodSelector(!openPeriodSelector),
        },
      ]}
      className="relative"
      darkerBackground
    >
      {consumptionsPeriods && (
        <PopUpList
          items={consumptionsPeriods.map((period) => {
            const monthYearName = formatToMonthYear(period);
            return {
              label: monthYearName,
              onClick: () => {
                if (unitId) {
                  setCurrentDate(monthYearName);
                  getMonthlyControlMeasurementByUnitIdHandler(unitId, period);
                  getUnitConsumptionMeasuresPerHourHandler(unitId, period);
                  setOpenPeriodSelector(!openPeriodSelector);
                }
              },
            };
          })}
          show={openPeriodSelector}
          className="absolute mr-8 -mt-8 sm:right-0 sm:top-32"
        />
      )}
      <div className="fixed bottom-0 right-6 z-20 my-6 mx-6 min-w-[35rem] lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() =>
              mutationLoading ? setOpenNotificationFeedback(true) : setOpenNotificationFeedback(false)
            }
            timeToCloseAutomaticallyInSeconds={mutationLoading ? undefined : 5}
          />
        )}
      </div>
      <div className="col-span-full mb-8 h-full">
        <MissingRedirectButtons
          buttonIcon={{
            label: `Medição de ${unitName}`,
            onClick: () => navigate(`/balanco-energetico/${energyAuditId}`),
          }}
          button={{
            label: 'Preencher horas faltante',
            disabled: totalMissingHours === 0 || totalConsumption.projected > 0,
            kind: totalMissingHours === 0 || totalConsumption.projected > 0 ? 'secondary' : 'primary',
            onClick: () => setOpenFillLeftHoursAutomaticallyModal(true),
          }}
        />
        <ConsumptionsGagdegts hasMissingHours={totalMissingHours === 0} gadgets={consumptionsGagdegts} />
        <MonthlyConsumptionTable
          tableData={{
            data: monthlyControlMeasurementByUnitId,
            openMissingHoursAction: (entry: MonthlyControlMeasurementByUnitId) => {
              setOpenFillLeftHoursDrawer(true);
              setMeasurementSelected(entry);
              setConsolidatedMeasurementDocId(entry.consolidatedMeasurementDocId);
            },
          }}
          tableGadgetInfo={{
            title: 'Medição por dia',
            actionButtons: [
              {
                buttonType: 'button',
                label: 'Sincronizar',
                kind: 'secondary',
                size: 'medium',
                onClick: () => syncConsumption(),
              },
              {
                buttonType: 'button',
                label: 'Baixar Excel',
                kind: 'secondary',
                disabled:
                  monthlyControlMeasurementByUnitId && monthlyControlMeasurementByUnitId?.length > 0 ? false : true,
                onClick: () => downloadExcel(),
              },
            ],
          }}
        />

        <ChartGadget
          gadgetContent={{
            title: 'Gráfico hora x medição',
            subtitles: [
              { color: 'black', text: 'Medição lida' },
              { color: 'green', text: 'Medição projetada' },
            ],
          }}
          unitConsumptionMeasuresPerHour={unitConsumptionMeasuresPerHour}
        />
      </div>
      <Modal
        className="md:w-[432px]"
        title={`Preencher horas faltantes de ${unitName}`}
        description={`Foram encontradas ${totalMissingHours} horas faltantes, deseja preencher com a média das horas dos dias?`}
        open={openFillLeftHoursAutomaticallyModal}
        setOpen={() => setOpenFillLeftHoursAutomaticallyModal(!openFillLeftHoursAutomaticallyModal)}
        modalButtons={{
          primary: {
            kind: 'primary',
            label: 'Preencher horas faltantes',
            onClick: () => updateAutomaticConsumption(),
          },
          secondary: {
            kind: 'secondary',
            label: 'Cancelar',
            onClick: () => setOpenFillLeftHoursAutomaticallyModal(false),
          },
        }}
      />
      {measurementSelected ? (
        <Drawer className="h-full" open={openFillLeftHoursDrawer} onClose={() => setOpenFillLeftHoursDrawer(false)}>
          <SingleDayConsumptionsPerHour
            accordionLabel={`Medições de ${formatDateFromString(measurementSelected.date, 'YYYY-MM-DD', 'DD/MM/YYYY')}`}
            tableData={dateTimeMeasurementFilteredBySubtype}
            monthlyControlConsumptions={monthlyControlMeasurementByUnitId}
            onClick={(measurement) => {
              setConsolidatedMeasurementDocId(measurement.consolidatedMeasurementDocId);
              setMeasurementSelected(measurement);
            }}
          />
        </Drawer>
      ) : null}
    </Layout>
  );
};

export default MeasurementByUnit;

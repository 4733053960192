import ContractSectionFieldsContainer from '@components/atoms/form/contract/contract-section-fields-container';
import { ContractCustomerGeneralSubsection } from '@components/atoms/form/contract/simulator-customer-section/contract-customer-general-subsection';
import { ContractCustomerControlsSubsection } from '@components/atoms/form/contract/simulator-customer-section/contract-customer-subsection';

export interface IContractFormCustomerSignatorySection {
  children?: JSX.Element;
  fieldsController?: Record<string, boolean>;
  removeCustomerSignatory?: (customerSignatoryNum: number) => void;
  appendCustomerSignatory?: () => void;
  customerSignatoryNum: number;
  customerSignatoryLength?: number;
}

export function ContractFormCustomerSignatorySection({
  fieldsController,
  customerSignatoryNum,
  customerSignatoryLength,
  removeCustomerSignatory,
  appendCustomerSignatory,
}: IContractFormCustomerSignatorySection) {
  return (
    <ContractSectionFieldsContainer>
      <>
        <ContractCustomerGeneralSubsection {...{ customerSignatoryNum, fieldsController }} />
        <ContractCustomerControlsSubsection
          {...{ customerSignatoryNum, customerSignatoryLength, removeCustomerSignatory, appendCustomerSignatory }}
        />
      </>
    </ContractSectionFieldsContainer>
  );
}

import '@wdyr';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import App from './App';
import './index.css';
import errorTracker from '@services/error-tracking';
import * as EventTracking from '@services/eventTracking';

errorTracker.initializeService();
EventTracking.initEventTrackingSystem();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.Fragment>,
);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Modal } from '@clarke-energia/foton';

import { useProinfa } from '@hooks/use-proinfa';
import { useNotification } from '@hooks/use-notification';
import { INotificationFeedbackContent } from '@utils/constants/common';
import { ProinfaTableData } from '@contexts/proinfa-contract/types';
import { CONTRACTS_PATH } from '@routers/constants';
import ProinfaTable from '@components/molecules/proinfa/table';
import ConfirmationProinfaForm from './confimation';

interface SecondStepProps {
  openConfirmationModal: boolean;
  setOpenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: ProinfaTableData[];
  isFetchingContracts: boolean;
}

const SecondStep: React.FC<SecondStepProps> = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  data,
  isFetchingContracts,
}) => {
  const { updateConfirmationStatusHandler, loading } = useProinfa();
  const { setNotificationResponse } = useNotification();
  const { getValues } = useFormContext();
  const year = getValues('referenceYear');

  const sucessNotification: INotificationFeedbackContent = {
    label: 'Sucesso',
    message: `Contratos PROINFA ${year} processados com sucesso!`,
    kind: 'primary',
  };
  const errorNotification: INotificationFeedbackContent = {
    label: 'Erro',
    message: 'Ocorreu um erro no processamento do Contrato PROINFA. Revise os dados e tente novamente.',
    kind: 'error',
  };

  const navigate = useNavigate();

  const unitsNames = data.length ? data.map(({ unit }) => unit) : [];

  return (
    <div>
      <div className="flex gap-6 mb-10">
        <div className="max-w-[16.5rem]">
          <h1 className="font-bold text-heading-xsmall">2. Confirmar os dados</h1>
          <span className="text-paragraph-medium text-neutral-60">
            Sincronização com a tabela realizada. Confira os dados abaixo.
          </span>
        </div>
      </div>
      <div className="mb-10">
        <ProinfaTable data={data} isFetchingContracts={isFetchingContracts} />
      </div>

      <Modal
        title="Enviar PROINFA"
        description="Ao confirmar o envio, os contratos de PROINFA para o decorrer do ano serão computados."
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(false)}
      >
        <ConfirmationProinfaForm
          data={{ year: year, unitsName: unitsNames }}
          loading={loading}
          onConfirmation={async () => {
            const ids = data.length ? data.map((item) => item.id) : [];
            updateConfirmationStatusHandler({ ids: ids, status: 'APPROVED', year: parseInt(year) })
              .then(() => {
                setOpenConfirmationModal(false);
                setNotificationResponse(sucessNotification);
                navigate(CONTRACTS_PATH);
              })
              .catch(() => {
                setOpenConfirmationModal(false);
                setNotificationResponse(errorNotification);
                navigate(CONTRACTS_PATH);
              });
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      </Modal>
    </div>
  );
};

export default SecondStep;

import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Modal, Pagination, Table } from '@clarke-energia/foton';

import { FinancialEvent, FinancialEventType } from '@contexts/financial-events';
import useFinancialEvents from '@hooks/use-financial-events';

import FormConfirmationSendFinancialEventsByIdsList from '@components/molecules/form/form-confirmation-send-financial-events-by-ids-list';

import { FinancialEventsTableHelper } from './helper';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

interface IFinancialEventsTable {
  activeFinancialEventType?: FinancialEventType;
  financialEventsData?: Array<FinancialEvent>;
  isFetchingFinancialEvents?: boolean;
  totalEventsCount?: number;
  eventsByPage?: number;
  currentPage?: number;
  isOnConfirmationScreen?: boolean;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setOpenNotificationFeedBack?: (value: boolean) => void;
}

const FinancialEventsTable: React.FC<IFinancialEventsTable> = ({
  financialEventsData,
  activeFinancialEventType,
  isFetchingFinancialEvents,
  isOnConfirmationScreen,
  totalEventsCount = 0,
  eventsByPage = 0,
  currentPage = 0,
  setCurrentPage,
  setOpenNotificationFeedBack,
}: IFinancialEventsTable) => {
  const { handleSendFinancialEventsByIdsList } = useFinancialEvents();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [selectedFinancialEvent, setSelectedFinancialEvent] = useState<FinancialEvent>();

  const tableHelper = new FinancialEventsTableHelper(
    setSelectedFinancialEvent,
    setOpenConfirmationModal,
    isOnConfirmationScreen,
    selectedFinancialEvent,
  );

  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-visible">
        {isFetchingFinancialEvents ? (
          <RowSkeleton />
        ) : (
          activeFinancialEventType && (
            <div>
              <Table<FinancialEvent>
                className="border-top"
                data={
                  activeFinancialEventType == FinancialEventType.RESERVE_ENERGY_CHARGE
                    ? tableHelper.generateEERExpansibleTableData(financialEventsData)
                    : financialEventsData ?? []
                }
                tableColumns={tableHelper.getTableColumnsDefinition(activeFinancialEventType)}
                expandable={activeFinancialEventType == FinancialEventType.RESERVE_ENERGY_CHARGE}
              />
              {setCurrentPage && totalEventsCount >= 10 && (
                <Pagination
                  totalRows={totalEventsCount}
                  rowsByPage={eventsByPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          )
        )}
      </div>
      {selectedFinancialEvent && (
        <Modal
          title="Enviar eventos financeiros"
          description="Ao confirmar o envio, serão disparados emails aos clientes. Certifique que as informações estejam corretas."
          open={openConfirmationModal as boolean}
          setOpen={() => setOpenConfirmationModal(false)}
        >
          <FormConfirmationSendFinancialEventsByIdsList
            onConfirmation={() => {
              handleSendFinancialEventsByIdsList([selectedFinancialEvent.id as string]);
              setOpenConfirmationModal(false);
              setOpenNotificationFeedBack && setOpenNotificationFeedBack(true);
            }}
            onCancel={() => setOpenConfirmationModal(false)}
            financialEvent={selectedFinancialEvent}
          />
        </Modal>
      )}
    </div>
  );
};

export default FinancialEventsTable;

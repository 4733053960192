import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';

import { CreateEnergyContractPayload, EMPTY_CONTRACT } from '@contexts/energy-contract/types';
import { useEnergyContracts } from '@hooks/use-energy-contract';
import { useCceeProfiles } from '@hooks/use-cce-profile';
import useTraders from '@hooks/use-trader';
import { orderListbyParameter } from '@utils/index';
import { englishFormatDateFromString } from '@utils/dayjs';

import FormContractSection from './contract-section';
import FormPriceSection from './price-section';
import FormFinancialConditionsSection from './financial-conditions-sections';
import FormContractLinkSection from './contract-link-section';
import FormContractedVolumeSection from './contracted-volume-section';
import FormEnergyContractCommissionSection from './energy-contract-commission-section';
import FormAttachmentsSection from './attachments-section';

interface FormEnergyContractProps {
  dataToUpdate: CreateEnergyContractPayload | undefined;
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>;
}

const FormEnergyContract = ({ dataToUpdate, setUploadedFiles }: FormEnergyContractProps) => {
  const {
    proposalsSelectField,
    proposalsFormated,
    getProposalsHandler,
    loadingProposals,
    loading,
    totalProposalsPage,
  } = useEnergyContracts();
  const { traders } = useTraders();
  const { cceeProfiles } = useCceeProfiles();

  const { watch, control, reset, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name: 'economy', control });

  const [previousProposalIdFieldValue, setPreviousProposalIdFieldValue] = useState<string | null>(null);
  const [proposalPage, setProposalPage] = useState<number>(1);

  const startSupplyDate = watch('startSupplyDate');
  const endSupplyDate = watch('endSupplyDate');
  const proposalIdFieldValue = watch('proposalId');
  const contractType = watch('contractType');
  const manageFieldsValue = useCallback(() => {
    setPreviousProposalIdFieldValue(proposalIdFieldValue);
    if (!dataToUpdate && previousProposalIdFieldValue === null) return;

    const proposal = proposalsFormated.find(({ proposalId }) => proposalId === proposalIdFieldValue);
    const fieldsValue = proposal ?? EMPTY_CONTRACT;
    const fieldsValueToUpdate = proposal ?? dataToUpdate;
    const proposalFieldNone = 'NONE';

    if (dataToUpdate) {
      if (proposalIdFieldValue === proposalFieldNone) {
        reset(dataToUpdate);
        setValue('proposalId', proposalFieldNone);
        return;
      }
      if (dataToUpdate?.proposalId !== proposalIdFieldValue) {
        reset(fieldsValueToUpdate);
        return;
      }
      reset(dataToUpdate);
      return;
    }

    if (proposalIdFieldValue === proposalFieldNone) {
      reset(EMPTY_CONTRACT);
      setValue('proposalId', proposalFieldNone);
    }

    reset(fieldsValue);
  }, [dataToUpdate, proposalIdFieldValue]);

  useMemo(() => {
    const endSupplyDateFormated = englishFormatDateFromString(endSupplyDate, 'DDMMYYYY', 'YYYY-MM-DD');
    const startSupplyDateFormated = englishFormatDateFromString(startSupplyDate, 'DDMMYYYY', 'YYYY-MM-DD');
    const startSupplyYear = dayjs(startSupplyDateFormated).year();
    const endSupplyYear = dayjs(endSupplyDateFormated).year();

    const amountYears = endSupplyYear - startSupplyYear + 1;
    const oldValueContractDurationField = fields.length;

    if (amountYears > oldValueContractDurationField) {
      for (let i = oldValueContractDurationField; i < amountYears; i++) {
        append({ year: NaN, amount: null });
      }
    } else {
      for (let i = oldValueContractDurationField; i > amountYears; i--) {
        remove(i - 1);
      }
    }
  }, [startSupplyDate, endSupplyDate]);

  useEffect(() => {
    manageFieldsValue();
  }, [manageFieldsValue]);

  useEffect(() => {
    if (proposalPage !== 1 && proposalPage >= totalProposalsPage) return;
    getProposalsHandler(proposalPage);
  }, [proposalPage]);

  const traderList = useMemo(() => {
    return traders.map(({ id, name }) => ({ optionLabel: name, value: id }));
  }, [traders]);

  const profiles = useMemo(() => {
    return orderListbyParameter(cceeProfiles, 'name').map((profile) => ({
      value: profile.id,
      label: profile.name,
    }));
  }, [cceeProfiles]);

  return (
    <div>
      {dataToUpdate && loading ? (
        <Skeleton width="100%" className="mb-7 h-screen xl:mb-10" />
      ) : (
        <div>
          <FormContractLinkSection profiles={profiles} />
          <FormContractSection
            proposalsSelectField={proposalsSelectField}
            isLoadingProposals={loadingProposals}
            setProposalPage={setProposalPage}
            traderOptions={traderList}
          />
          <FormContractedVolumeSection
            yearsFields={fields}
            startYear={startSupplyDate ? Number(startSupplyDate.slice(-4)) : 0}
          />
          {startSupplyDate && endSupplyDate ? (
            <FormPriceSection
              yearsFields={fields}
              startYear={Number(startSupplyDate.slice(-4))}
              isFixedContractType={contractType === 'FIXED_PRICE'}
            />
          ) : null}
          <FormFinancialConditionsSection />
          <FormEnergyContractCommissionSection
            yearsFields={fields}
            startYear={startSupplyDate ? Number(startSupplyDate.slice(-4)) : 0}
          />
          <FormAttachmentsSection setUploadedFiles={setUploadedFiles} />
        </div>
      )}
    </div>
  );
};

export default FormEnergyContract;

export function parseMultipartPayload(data: Record<string, any>, fileFieldsKeys?: Array<string>) {
  const multipartPayload: Record<string, any> = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];
    let serializedKey = '';

    if (fileFieldsKeys?.includes(key)) serializedKey = `${key}[]`;
    else if (typeof value === 'object') serializedKey = `${key}{}`;
    else serializedKey = key;

    multipartPayload[serializedKey] = value;
  });

  return multipartPayload;
}

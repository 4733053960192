import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const EnergyAuditDetailSkeleton: React.FC = () => (
  <>
    <div className="col-span-full mb-6 h-full">
      <Skeleton className="mb-5" width="40%" />
      <Skeleton height="70%" />
    </div>
    <div className="col-span-full mb-8">
      <Skeleton width="100%" height="50%" />
    </div>
    <Skeleton width="100%" />
    <div className="grid grid-cols-2 col-span-full">
      <Skeleton height="50%" />
    </div>
  </>
);

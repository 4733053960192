import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ColumnOptions, Pagination, Table } from '@clarke-energia/foton';
import { MigrationReport, MigrationUnit } from '@contexts/migration-report/types';

type UnitTable = Array<MigrationUnit>;

export type ExpandableTable = {
  data: Omit<MigrationReport, 'units'>;
  expandedContent: JSX.Element;
};

type Pagination = {
  total?: number;
  itemPerpage?: number;
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
};

const TableSkeleton = (): JSX.Element => {
  return (
    <>
      {Array(3)
        .fill({ w: '100%', h: '3%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </>
  );
};

export interface MigrationReportsTableProps {
  expandable: boolean;
  data: UnitTable | Array<ExpandableTable>;
  columns: Array<ColumnOptions<any>>;
  loading: boolean;
  pageInfo?: Pagination;
  onSelectRow?: (name: string, isChecked: boolean) => void;
  selectedTableRow?: string[];
}

const MigrationTable = ({
  expandable,
  data,
  columns,
  loading,
  pageInfo,
  selectedTableRow,
  onSelectRow,
}: MigrationReportsTableProps): JSX.Element => {
  const rowSelection = {
    visible: true,
    accessor: 'commercialGroup',
    checkedItens: expandable && selectedTableRow ? selectedTableRow : [],
    onChangeSelection: (e: React.ChangeEvent<HTMLInputElement>) => {
      const checkValue = e.target.checked;
      const groupName = e.target.value;

      if (onSelectRow) onSelectRow(groupName, checkValue);
    },
  };

  if (loading) return <TableSkeleton />;

  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-visible">
        <Table<any>
          expandable={expandable}
          data={data}
          tableColumns={columns}
          rowSelection={expandable ? rowSelection : undefined}
        />
        {pageInfo?.total && pageInfo?.setCurrentPage && pageInfo?.total >= 10 && (
          <Pagination
            totalRows={pageInfo?.total as number}
            rowsByPage={pageInfo?.itemPerpage as number}
            currentPage={pageInfo?.currentPage as number}
            setCurrentPage={pageInfo?.setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default MigrationTable;

import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { useAuth } from '@src/ApolloWrapper';

import { RolesTypes } from '@utils/translators/users-roles';
import { GET_GROUPS_DATA_QUERY, GET_MIGRATION_GROUPS_DATA_QUERY, GET_MIGRATION_REPORTS_QUERY } from './queries';
import { CREATE_TASK, SEND_BATCH_MIGRATION_REPORT_EMAIL, SYNC_BATCH_MIGRATION_REPORTS } from './mutation';
import {
  MigrationReportContextType,
  CommercialGroup,
  MigrationReportResponse,
  GetMutationReportsInput,
  MigrationReportGraphQlResponse,
  BatchMigrationReportGraphQlResponse,
  SyncMutationReportsInput,
  SyncMutationReportsPayload,
  SendBatchMutationReportsInput,
  SendMutationReportsPayload,
  SendEmailReportGraphQlResponse,
  GroupsMigrationReportResponse,
} from './types';

export const MigrationReportContext = createContext({} as MigrationReportContextType);

interface Provider {
  children: React.ReactNode;
}

const MigrationReportProvider: React.FC<Provider> = ({ children }: Provider) => {
  const { auth0ApiService } = useAuth();
  const [commercialGroupsFilters, setCommercialGroupsFilter] = useState<Array<CommercialGroup>>([]);
  const [commercialGroups, setCommercialGroups] = useState<Array<Omit<CommercialGroup, 'thridPartyId'>>>([]);
  const [migrationReports, setMigrationReports] = useState<MigrationReportResponse>({
    page: 0,
    total: 0,
    limit: 0,
    data: [],
  });

  const getMigrationReporEmails = async (usersIds: string[]) => {
    try {
      const usersResponse = await auth0ApiService?.getUsersByIdList(usersIds);

      if (usersResponse?.data.length) {
        const responseMapped = usersResponse.data.reduce((emailsMap: any, { email, app_metadata: { uuid } }: any) => {
          emailsMap[uuid] = email;
          return emailsMap;
        }, {});
        return responseMapped;
      }

      return {};
    } catch (error) {
      return {};
    }
  };

  const [getCommercialGroupsFilter, { loading: loadingGroupsFilter }] = useLazyQuery<GroupsMigrationReportResponse>(
    GET_MIGRATION_GROUPS_DATA_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const groupsParsed = data.getGroupsManagement.map(({ group, thirdPartyId }) => ({
          id: group?.id,
          name: group?.name,
          thirdPartyId,
        }));
        setCommercialGroupsFilter(groupsParsed as CommercialGroup[]);
      },
      onError: () => {
        setCommercialGroupsFilter([]);
      },
    },
  );

  const [getCommercialGroups, { loading: loadingGroups }] = useLazyQuery(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCommercialGroups(data.groups.data);
    },
    onError: () => {
      setCommercialGroups([]);
    },
  });

  const [getMigrationReports, { loading: loadingMigrationReports }] = useLazyQuery<
    MigrationReportGraphQlResponse,
    GetMutationReportsInput
  >(GET_MIGRATION_REPORTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getMigrationReportsByPage }) => {
      const userIdsSet = new Set<string>();

      getMigrationReportsByPage.data.forEach(({ group }) => {
        if (group.users && group.users.length) {
          group.users
            .filter(({ roles }) => roles && roles.includes(RolesTypes.CONTATO_PRINCIPAL))
            .forEach(({ user: { id } }) => {
              userIdsSet.add(id);
            });
        }
      });

      const userIds = Array.from(userIdsSet);

      getMigrationReporEmails(userIds)
        .then((response) => {
          const reportsWithEmails = getMigrationReportsByPage.data.map(({ group, ...rest }) => {
            const emails =
              group.users && group.users.filter(({ user }) => response[user.id]).map(({ user }) => response[user.id]);

            return {
              ...rest,
              group,
              emails: emails || [],
            };
          });
          setMigrationReports({
            page: getMigrationReportsByPage.page,
            total: getMigrationReportsByPage.total,
            limit: getMigrationReportsByPage.limit,
            data: reportsWithEmails,
          });
        })
        .catch(() => {
          setMigrationReports({
            page: 0,
            total: 0,
            limit: 0,
            data: [],
          });
        });
    },
    onError: () => {
      setMigrationReports({
        page: 0,
        total: 0,
        limit: 0,
        data: [],
      });
    },
  });

  const [createTask, { loading: createTaskLoading }] = useMutation<
    BatchMigrationReportGraphQlResponse,
    SyncMutationReportsPayload
  >(CREATE_TASK, {
    fetchPolicy: 'network-only',
    refetchQueries: [GET_MIGRATION_REPORTS_QUERY],
  });

  const [syncBatchMigrationReports, { loading: syncBatchMigrationReportLoading }] = useMutation<
    BatchMigrationReportGraphQlResponse,
    SyncMutationReportsPayload
  >(SYNC_BATCH_MIGRATION_REPORTS, {
    fetchPolicy: 'network-only',
    refetchQueries: [GET_MIGRATION_REPORTS_QUERY],
  });

  const [sendBatchMigrationReportEmails, { loading: emailsBatchLoading }] = useMutation<
    SendEmailReportGraphQlResponse,
    SendMutationReportsPayload
  >(SEND_BATCH_MIGRATION_REPORT_EMAIL, {
    fetchPolicy: 'network-only',
  });

  const createTaskHandler = async ({ groupId, thirdPartyId }: SyncMutationReportsInput) => {
    try {
      const { data } = await createTask({
        variables: { input: { groupId, thirdPartyId } },
      });
      return data?.syncMigrationReport?.success;
    } catch (error) {
      return false;
    }
  };

  const syncMigrationReportsBatchHandler = async (payload: Array<SyncMutationReportsInput>) => {
    try {
      const { data } = await syncBatchMigrationReports({
        variables: { input: payload },
      });
      return data?.syncBatchMigrationReport.success;
    } catch (error) {
      return false;
    }
  };

  const sendMigrationsReportsBatchHandler = async (payload: Array<SendBatchMutationReportsInput>) => {
    try {
      const { data } = await sendBatchMigrationReportEmails({
        variables: { input: payload },
      });
      return data?.sendBatchMigrationReport;
    } catch (error) {
      return false;
    }
  };

  return (
    <MigrationReportContext.Provider
      value={{
        loading: loadingGroups || loadingGroupsFilter || loadingMigrationReports,
        mutationLoading: syncBatchMigrationReportLoading || emailsBatchLoading || createTaskLoading,
        commercialGroupsFilters,
        commercialGroups,
        migrationReports,
        getMigrationReports,
        getCommercialGroups,
        getCommercialGroupsFilter,
        syncMigrationReportsBatchHandler,
        sendMigrationsReportsBatchHandler,
        createTaskHandler,
      }}
    >
      {children}
    </MigrationReportContext.Provider>
  );
};

export default MigrationReportProvider;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Layout } from '@clarke-energia/foton';

import FormUpsertUnit from '@components/molecules/form/form-upsert-unit';
import { UnitPayload } from '@contexts/unit';
import { useUnits } from '@hooks/use-units';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { trackEvent } from '@services/eventTracking';
import { COMMERCIAL_GROUP_PATH, HOME_PATH, UNITS_PATH } from '@routers/constants';
import { UpsertUnitFormSkeleton } from '@components/molecules/form/form-upsert-unit/upsert-unit-form-skeleton';
import { UnitNotificationFeedbackContents } from '@utils/constants/common';

const UpsertUnit: React.FC = () => {
  const navigate = useNavigate();
  const { unitId, groupId } = useParams();
  const { units, getUnits, setGroupId, groupName, setNotificationResponse } = useUnits();

  const [selectedUnit, setSelectedUnit] = useState<UnitPayload | null>(null);

  const navigateToUnitsListPage = () => {
    if (groupId) {
      const commercialGroupHubPath = COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string);
      navigate(`${commercialGroupHubPath}/${UNITS_PATH}`);
    }
  };

  useEffect(() => {
    if (groupId) setGroupId(groupId as string);
  }, [units]);

  useEffect(() => {
    const filteredUnit = units.filter((item) => item.id === unitId);
    if (filteredUnit.length > 0) setSelectedUnit(filteredUnit[0]);
  }, [unitId]);

  return (
    <Layout
      title={unitId === 'nova-unidade' ? 'Criar unidade' : `Editar ${selectedUnit?.name}`}
      className="relative"
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: groupName,
          url: COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string),
        },
        {
          label: 'Unidades Consumidoras',
          url: `${COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string)}/${UNITS_PATH}`,
        },
        {
          label: `${unitId === 'nova-unidade' ? 'Criar' : 'Editar'} unidade`,
          url: '#',
        },
      ]}
      darkerBackground
    >
      <div className="col-span-full h-full">
        {unitId !== 'nova-unidade' && !selectedUnit ? (
          <UpsertUnitFormSkeleton />
        ) : (
          <FormUpsertUnit
            dataToUpdate={selectedUnit}
            onFormSuccess={(failToUpsertUnit) => {
              getUnits();
              navigateToUnitsListPage();
              if (failToUpsertUnit)
                return setNotificationResponse(
                  UnitNotificationFeedbackContents[selectedUnit ? 'UPDATING_UNIT_ERROR' : 'CREATING_UNIT_ERROR'],
                );
              setNotificationResponse(
                UnitNotificationFeedbackContents[selectedUnit ? 'UPDATING_UNIT_SUCCESS' : 'CREATING_UNIT_SUCCESS'],
              );
              trackEvent(
                selectedUnit
                  ? eventLabels.UPDATE_COMMERCIAL_UNIT_BUTTON_CLICKED
                  : eventLabels.CREATE_COMMERCIAL_UNIT_BUTTON_CLICKED,
              );
            }}
            onFormFail={() =>
              setNotificationResponse(
                UnitNotificationFeedbackContents[selectedUnit ? 'UPDATING_UNIT_ERROR' : 'CREATING_UNIT_ERROR'],
              )
            }
          />
        )}
      </div>
    </Layout>
  );
};

export default UpsertUnit;

import React from 'react';

import { NumericFormField, RadioFormField } from '@components/atoms/form';
import FormSectionHeader from '@components/molecules/general/section-form';
import { inputsFilteredByTariffModality } from '../helpers';
import { EconomyReportPayload, TariffModality } from '@contexts/economy-reports/types';
import { DevecObejectKey } from '@contexts/economy-reports/parser';
import { useFormContext } from 'react-hook-form';

interface DistribuitionAclProps {
  tariffModality: TariffModality;
  devecState: DevecObejectKey | null;
}

const DistributionAclInputs: React.FC<DistribuitionAclProps> = ({ tariffModality, devecState }) => {
  const { getValues } = useFormContext();
  const devecDeclaration = getValues('devecDeclaration');

  return (
    <div className="grid grid-cols-3 gap-6 xl:mt-0">
      <FormSectionHeader
        title="Distribuição Mercado Livre"
        text="Seção sobre os custos no mercado livre. Insira as informações obtidas na Fatura de Distribuição do cliente. "
      />
      <div className="grid grid-cols-3 col-span-3 gap-6 lg:col-span-2">
        {inputsFilteredByTariffModality(tariffModality).map(
          ({ field, allowNegative, label, placeholder, required, decimalScale, suffix, prefix }) => (
            <div className="col-span-3 lg:col-span-1" key={field}>
              <NumericFormField<EconomyReportPayload>
                id={field}
                label={label}
                field={field}
                formatProps={{
                  placeholder,
                  mask: '_',
                  allowNegative,
                  fixedDecimalScale: true,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale,
                  ...(suffix && { suffix }),
                  ...(prefix && { prefix }),
                }}
                options={{
                  valueAsNumber: false,
                  ...(required && { required: { value: true, message: 'Campo obrigatório' } }),
                }}
              />
            </div>
          ),
        )}
        {devecState && (
          <RadioFormField<EconomyReportPayload>
            label="Cliente emitiu a Devec?"
            field="devecDeclaration"
            inputOptions={[
              {
                id: 'true',
                optionLabel: 'Sim',
                value: 'true',
                ...(devecDeclaration && { defaultChecked: devecDeclaration }),
              },
              {
                id: 'false',
                optionLabel: 'Não',
                value: 'false',
                ...(devecDeclaration && { defaultChecked: !devecDeclaration }),
              },
            ]}
            options={{
              validate: (value) => value !== typeof Boolean,
            }}
            valueAsBoolean
          />
        )}
      </div>
    </div>
  );
};

export default DistributionAclInputs;

import React, { useEffect } from 'react';
import { Drawer } from '@clarke-energia/foton';
import { UnitPayload } from '@contexts/unit';
import UnitDetailedInfoSection from '../../molecules/commercial-group/unit-detailed-info-section';
import { parseUnitToNuggetInfo } from '@contexts/unit/parser';
import { useEdcs } from '@hooks/edcs';
import UnitHistoricSection from '@components/molecules/commercial-group/unit-historic-section';
import UnitExtraInformationSection from '@components/molecules/commercial-group/unit-extra-information-section';

interface UnitViewDrawerProps {
  subtitle: string;
  title: string;
  cancelBtnClick: () => void;
  headerButtonClick: () => void;
  openDrawer: boolean;
  selectedUnit?: UnitPayload | null;
}

const UnitViewDrawer: React.FC<UnitViewDrawerProps> = ({
  subtitle,
  title,
  cancelBtnClick,
  headerButtonClick,
  openDrawer,
  selectedUnit,
}) => {
  const edcs = useEdcs();

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) cancelBtnClick();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [cancelBtnClick]);

  return (
    <Drawer
      className="max-w-xl"
      title={title}
      subtitle={subtitle}
      open={openDrawer}
      onClose={() => cancelBtnClick()}
      drawerWidth={'sm:w-[40rem]'}
      headerButton={{
        buttonType: 'buttonInline',
        label: 'Editar',
        icon: 'PencilIcon',
        iconPosition: 'left',
        kind: 'secondary',
        size: 'xSmall',
        onClick: () => headerButtonClick(),
      }}
    >
      <div className="flex flex-col gap-7">
        {selectedUnit && (
          <>
            <UnitDetailedInfoSection unitInfosParsed={parseUnitToNuggetInfo(selectedUnit, edcs)} />
            <UnitHistoricSection selectedUnit={selectedUnit} />
            <UnitExtraInformationSection selectedUnit={selectedUnit} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default UnitViewDrawer;

import { gql } from '@apollo/client';

export const UPSERT_ECONOMY_REPORT_MUTATION = gql`
  mutation UpsertEconomyReport($input: CreateEconomyReportInput) {
    createEconomyReport(input: $input) {
      id
      createdAt
    }
  }
`;
export const UPSERT_GROUP_ECONOMY_REPORT_MUTATION = gql`
  mutation CreateGroupEconomyReport($input: CreateGroupEconomyReportInput) {
    createGroupEconomyReport(input: $input) {
      id
      createdAt
    }
  }
`;

export const UPDATE_BATCH_UNIT_ECONOMY_REPORTS_STATUS = gql`
  mutation UpdateBatchEconomyReportsStatus($input: UpdateBatchEconomyReportsStatusInput) {
    updateBatchEconomyReportsStatus(input: $input)
  }
`;

export const UPDATE_BATCH_GROUP_ECONOMY_REPORTS_STATUS = gql`
  mutation UpdateBatchGroupEconomyReportsStatus($input: UpdateBatchGroupEconomyReportsStatusInput) {
    updateBatchGroupEconomyReportsStatus(input: $input)
  }
`;
export const NOTIFY_UNIT_REPORT_BY_EMAIL = gql`
  mutation NotifyUnitReport($input: NotifyEconomyReportInput) {
    notifyUnitEconomyReport(input: $input) {
      errors
    }
  }
`;
export const NOTIFY_GROUP_REPORT_BY_EMAIL = gql`
  mutation NotifyGroupReport($input: NotifyEconomyReportInput) {
    notifyGroupEconomyReport(input: $input) {
      errors
    }
  }
`;

import React from 'react';
import { string2HTMLParser } from '@utils/text';

interface HeaderLogoClarkeProps {
  text: string;
}

const Footer: React.FC<HeaderLogoClarkeProps> = ({ text }) => {
  return (
    <div className="py-3 border-t border-black">
      <p className="text-paragraph-small">{string2HTMLParser(text)}</p>
    </div>
  );
};

export default Footer;

import { DownloadExcelPayload } from '@contexts/energy-audit-consumptions';

const downloadExcelParser = ({ monthYear, unitId, unitName }: DownloadExcelPayload): FormData => {
  const formData = new FormData();
  formData.append('unit_id', unitId);
  formData.append('month_year', monthYear);
  formData.append('unit_name', unitName);

  return formData;
};

export default downloadExcelParser;

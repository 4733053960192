import React, { useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, TextInput } from '@clarke-energia/foton';

import { DocTypes } from '@utils/translators/files';
import { File } from '@contexts/files/types';
import { useFile } from '@hooks/use-file';
import useGroupUsers from '@hooks/use-user-groups';

import { FileFormField, FormField, SelectFormField } from '@components/atoms/form';

import './style.css';

interface IForm {
  dataToUpdate?: File;
  localFilePath?: string;
  isCreatingFolder: boolean;
  tableData: File[];
  onFormSuccess: () => void;
  setModalUpsertFileOpen: (value: boolean) => void;
  setTableData: (value: File[]) => void;
}

export interface UpsertFileForm extends File {
  fileToUpload?: any[];
}

function FormUpsertFile({
  onFormSuccess,
  dataToUpdate,
  localFilePath,
  isCreatingFolder,
  tableData,
  setTableData,
  setModalUpsertFileOpen,
}: IForm) {
  const methods = useForm<UpsertFileForm>({
    defaultValues: { ...dataToUpdate, filePath: localFilePath, fileName: dataToUpdate?.filePath } ?? undefined,
  });
  const params = useParams();
  const { insertFilePathHandler } = useFile();
  const { setCommercialGroupId, group } = useGroupUsers();
  const { uploadFileHandler } = useFile();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [filePath, setFilePath] = useState<string | null>(null);

  const onSubmit: SubmitHandler<UpsertFileForm> = async (values) => {
    setLoading(true);
    setError(null);

    const parsedFilePath = values.filePath
      ? `${values.filePath.replaceAll(' ', '_')}${(values.fileName as string).replaceAll(' ', '_')}`
      : (values.fileName as string).replaceAll(' ', '_');

    if (dataToUpdate?.id) {
      return insertFilePathHandler(dataToUpdate.id, parsedFilePath)
        .then(() => {
          setLoading(false);
          onFormSuccess();
        })
        .catch(() => {
          setLoading(false);
          setError('Ocorreu um erro ao atualizar o caminho do documento.');
        });
    } else {
      if (values.fileToUpload) {
        const parsedFile = {
          fileToUpload: values.fileToUpload,
          commercialGroupId: group?.id,
          unitId: values.unitId,
          filePath: parsedFilePath,
          documentType: values.documentType,
        };
        uploadFileHandler(parsedFile)
          .then((response) => {
            if (response.data.success) {
              setLoading(false);
              onFormSuccess();
            }
          })
          .catch(() => {
            setLoading(false);
            setError('Ocorreu um erro ao enviar o arquivo. Tente novamente.');
          });
      }
    }
  };

  React.useEffect(() => {
    if (!dataToUpdate && params.groupId) setCommercialGroupId(params.groupId);
  }, [dataToUpdate, params]);

  return (
    <FormProvider {...methods}>
      {!isCreatingFolder ? (
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-10">
          <FormField<UpsertFileForm>
            id="fileName"
            label="Nome do Arquivo"
            field="fileName"
            placeholder="Contrato de gestão"
            options={{ required: { value: true, message: 'Insira um nome para o arquivo.' } }}
          />
          <FormField<UpsertFileForm>
            id="filePath"
            label="Localização de Pasta"
            field="filePath"
            placeholder="documentos/"
          />
          {!dataToUpdate && (
            <>
              {group && (
                <>
                  <SelectFormField<UpsertFileForm>
                    id="unitId"
                    label="Unidade"
                    field="unitId"
                    options={{ required: { value: true, message: 'Insira uma unidade.' } }}
                    inputOptions={
                      group.units?.map((unit) => ({
                        value: unit.id,
                        optionLabel: unit.name,
                      })) ?? []
                    }
                  />
                  <SelectFormField<UpsertFileForm>
                    id="documentType"
                    label="Tipo de Documento"
                    field="documentType"
                    options={{ required: { value: true, message: 'Insira um tipo de documento.' } }}
                    inputOptions={DocTypes.map((docType) => ({
                      value: docType.value,
                      optionLabel: docType.label,
                    }))}
                  />
                </>
              )}
              <FileFormField<UpsertFileForm>
                name="fileToUpload"
                id="signed-proposal-files"
                label={{ text: 'Anexe o arquivo desejado' }}
                options={{ required: { value: true, message: 'É preciso selecionar ao menos um arquivo para enviar' } }}
                fileSizeLimitInBytes={30 * 1020 * 1024}
                fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
                multiple={false}
              />
            </>
          )}
          <Button
            loading={loading}
            label={`${dataToUpdate ? 'Atualizar' : 'Enviar'} documento`}
            kind={'primary'}
            className="mt-5 -mb-5"
          />
          <span className="col-span-4 pt-5 text-center rounded-md focus:outline-none text-danger-60 border-danger-30 sm:text-paragraph-medium focus:border-danger-30 focus:ring-danger-60">
            {error}
          </span>
        </form>
      ) : (
        <div className="-mb-8">
          <TextInput
            className="text-black"
            label="Nome da pasta"
            value={filePath as string}
            placeholder="nova pasta"
            required
            error={error as string}
            onChange={(e) => {
              setFilePath(e.target.value);
            }}
          />
          <Button
            className="mt-5 w-full"
            label="Criar Pasta"
            kind="primary"
            onClick={() => {
              if (!filePath) {
                setError('Insira um nome para a nova pasta');
              } else {
                const localTableData: File[] = [
                  ...tableData,
                  {
                    id: `${tableData.length + 1}`,
                    filePath: filePath.replaceAll(' ', '_'),
                  },
                ];
                setTableData(localTableData);
                setModalUpsertFileOpen(false);
              }
            }}
          />
        </div>
      )}
    </FormProvider>
  );
}

export default FormUpsertFile;

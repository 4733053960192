import React, { useState } from 'react';

import { Pagination } from '@clarke-energia/foton';
import { transformObjectKeysToCamelCase } from '@src/common';
import { SalesContractTable } from '@components/molecules/sales-contracts/sales-contracts-table';
import useManagementContract from '@hooks/use-management-contract';
import { IContractBase, IContractProduct, IContractStatus } from '@pages/sales-contracts';

export interface ISalesContractTableContainer {
  status: IContractStatus;
  product: IContractProduct;
}

interface IResponseData {
  data: IContractBase[];
}
interface IContractsData {
  data: IResponseData;
  page: number;
  limit: number;
  total: number;
}

export const SalesContractTableContainer = ({ status, product }: ISalesContractTableContainer) => {
  const { getAllManagementContracts } = useManagementContract();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [contracts, setContracts] = useState<IContractsData | IContractBase[]>();

  const fetchData = async () => {
    const response = await getAllManagementContracts(status, currentPage, product);
    const parsedContractResponse = transformObjectKeysToCamelCase(response.data.data);
    setContracts(parsedContractResponse as IContractsData | IContractBase[]);
  };

  React.useEffect(() => {
    fetchData();
  }, [status, currentPage]);

  const contractsData = (contracts as IContractsData)?.data;
  contractsData?.data?.map((contract) => contract.product === product);

  return (
    <div>
      {contracts && (
        <div className="w-ful">
          <SalesContractTable contractsData={contractsData as unknown as IContractBase[]} />
          <Pagination
            currentPage={(contracts as IContractsData).page}
            rowsByPage={(contracts as IContractsData).limit}
            totalRows={(contracts as IContractsData).total}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

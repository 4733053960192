import { KickOffStatus } from '@contexts/kick-off/types';

export type KickOffPanelSections = 'QUIZ_SECTION' | 'DIAGNOSIS_SECTION' | 'UNITS_SUMMARY_SECTION';
export interface IKickOffPanelSectionMessageContent {
  title: string;
  description: string;
}
type IKickOffPanelSection = Record<keyof typeof KickOffStatus, IKickOffPanelSectionMessageContent | undefined>;
type IKickOffPanelMessagesIndexer = Record<KickOffPanelSections, IKickOffPanelSection>;

const unavailableQuizContent = {
  title: 'Questionário indisponível.',
  description:
    'O formulário para o cliente fica apenas disponível quando as informações da unidade estiverem cadastradas e completas.',
};

const releasedQuizContent = {
  title: 'Cliente ainda não respondeu o formulário.',
  description: 'Compartilhe o link com o cliente, sua resposta será disponibilizada aqui.',
};

const unansweredQuizContent = {
  title: 'Cliente acessou o formulário mas ainda não respondeu.',
  description: 'Cobre o cliente para responder o formulário. Sem ele não teremos a definição do produto.',
};

const unavailableDiagnosis = {
  title: 'Produto ainda não foi definido.',
  description:
    'Temos informações cruciais faltantes. Certifique de que o formulário tenha sido respondido e os dados das unidades estejam completo.',
};

const unavailableUnitsSummary = {
  title: 'Não encontramos os dados das unidades.',
  description:
    'Sem as informações completas das unidades não é possível liberar o formulário para o cliente. Preencha os dados imediatamente.',
};

const KickOffPanelMessagesIndexer: IKickOffPanelMessagesIndexer = {
  QUIZ_SECTION: {
    STARTED: unavailableQuizContent,
    INFORMATION_REGISTRATION_PENDING: unavailableQuizContent,
    INFORMATION_REGISTRATION_CONCLUDED: releasedQuizContent,
    DIAGNOSTIC_QUIZ_RELEASED: releasedQuizContent,
    DIAGNOSTIC_QUIZ_BEING_COMPLETED: unansweredQuizContent,
    DIAGNOSTIC_QUIZ_SENT: undefined,
  },
  DIAGNOSIS_SECTION: {
    STARTED: unavailableDiagnosis,
    INFORMATION_REGISTRATION_PENDING: unavailableDiagnosis,
    INFORMATION_REGISTRATION_CONCLUDED: unavailableDiagnosis,
    DIAGNOSTIC_QUIZ_RELEASED: unavailableDiagnosis,
    DIAGNOSTIC_QUIZ_BEING_COMPLETED: unavailableDiagnosis,
    DIAGNOSTIC_QUIZ_SENT: undefined,
  },
  UNITS_SUMMARY_SECTION: {
    STARTED: unavailableUnitsSummary,
    INFORMATION_REGISTRATION_PENDING: unavailableUnitsSummary,
    INFORMATION_REGISTRATION_CONCLUDED: undefined,
    DIAGNOSTIC_QUIZ_RELEASED: undefined,
    DIAGNOSTIC_QUIZ_BEING_COMPLETED: undefined,
    DIAGNOSTIC_QUIZ_SENT: undefined,
  },
};

export default KickOffPanelMessagesIndexer;

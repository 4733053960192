import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateCceeContractPayload, EnergyContract } from '@contexts/ccee-contract/types';
import DatesFields from '@components/atoms/contract-ccee/dates-fields';
import { NumericFormField, SelectFormField } from '@components/atoms/form';
import { ComboBoxFormField } from '@components/atoms/form/combobox';

interface LongDurationFieldsProps {
  loadingEnergyContracts: boolean;
  handleScrollComboBox: () => void;
  handleChangeComboBox: (serachItem: string | null) => void;
  contracts: EnergyContract[];
}

const LongDurationFields = ({
  handleScrollComboBox,
  handleChangeComboBox,
  loadingEnergyContracts,
  contracts,
}: LongDurationFieldsProps): JSX.Element => {
  const { setValue, watch } = useFormContext<CreateCceeContractPayload>();

  const contractId = watch('energyContractId');
  const targetContract = contracts.find(({ id }) => id === contractId);
  const profiles = contracts.find(({ id }) => id === contractId)?.profilesCcee ?? [];

  const energyContractOptions = contracts.length
    ? contracts.map(({ group, id }) => ({ label: group?.name, value: id }))
    : [];
  const loadingOption = [{ label: 'Buscando contratos...', value: '' }];

  return (
    <>
      <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
        <NumericFormField<CreateCceeContractPayload>
          label="Numero do contrato CCEE"
          id="contractCceeId"
          field="contractCceeId"
          formatProps={{
            placeholder: '123459',
            format: '#######',
            mask: '',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
          }}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />
        <ComboBoxFormField<CreateCceeContractPayload>
          label="CCEAL vinculado"
          field="energyContractId"
          id="energyContractId"
          inputOptions={loadingEnergyContracts ? loadingOption : energyContractOptions}
          isLoading={loadingEnergyContracts}
          onMenuScrollToBottom={handleScrollComboBox}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
          onInputChange={(value) => {
            if (value) handleChangeComboBox(value);

            if (targetContract) {
              setValue('groupId', targetContract.group.id);
              setValue('retailTraderId', targetContract.retailTraderId);
            }
          }}
        />

        <SelectFormField<CreateCceeContractPayload>
          label="Perfil CCEE"
          field="idProfileCcee"
          id="idProfileCcee"
          inputOptions={profiles}
        />
      </div>
      <DatesFields />
    </>
  );
};

export default LongDurationFields;

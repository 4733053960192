import React, { useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { AxiosResponse } from 'axios';
import { createContext } from 'use-context-selector';

import { createClarkeContractAttachments } from '@services/customerAPI';
import { useAuth } from '@src/ApolloWrapper';
import { ContractType, FeedbackType, contractFeedbackMessage } from '@pages/contracts/helper';
import {
  GET_ALL_CLARKE_CONTRACTS_QUERY,
  GET_CLARKE_CONTRACT_BY_ID_QUERY,
  GET_CLARKE_GROUPS_FILTER_QUERY,
  GET_ENERGY_CONTRACTS_QUERY,
  GET_GROUPS_DATA_QUERY,
} from './queries';
import {
  ClarkeContract,
  ClarkeContractAttachmentsPayload,
  ClarkeContractGraphQlResponse,
  ClarkeContractGroupsFilterGraphQlResponse,
  ClarkeContractPayload,
  EnergyContractParsed,
  GetClarkeContractParams,
  GetEnergyContractsParams,
  GroupFilterParsed,
  GroupsGraphQlResponse,
} from './types';
import { handleContractToUpdate, handleParserData, parseEnergyContracts } from './parser';
import {
  CREATE_CLARKE_CONTRACT,
  DELETE_ATTACHMENT_CLARKE_CONTRACT,
  DELETE_CLARKE_CONTRACT,
  EDIT_CLARKE_CONTRACT,
} from './mutation';
import { useNotification } from '@hooks/use-notification';

export type ClarkeContractContextType = {
  getClarkeContractsHandler(
    inputParams: GetClarkeContractParams,
  ): Promise<QueryResult<ClarkeContractGraphQlResponse, OperationVariables>>;
  getClarkeContractByIdHandler(cceeContractId: string): Promise<QueryResult<any, OperationVariables>>;
  getGroupsFilterHandler(
    page: number,
  ): Promise<QueryResult<ClarkeContractGroupsFilterGraphQlResponse, OperationVariables>>;
  getGroupsListHandler(): Promise<QueryResult<GroupsGraphQlResponse, OperationVariables>>;
  getEnergyContractsHandler(params: GetEnergyContractsParams): Promise<QueryResult<any, OperationVariables>>;
  createClarkeContractHandler: (CceeContract: ClarkeContractPayload) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  updateClarkeContractHandler: (
    CceeContractId: string,
    payload: ClarkeContractPayload,
  ) => Promise<{
    id: string | null;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;
  deleteClarkeContractHandler: (CceeContractId: string) => Promise<{
    success: boolean;
    feedback: {
      message: FeedbackType;
      tab: ContractType;
    };
  }>;

  deleteAttachmentClarkeContractHandler: (attachment: string) => Promise<{
    success: boolean;
  }>;
  createClarkeContractAttachmentsHandler(paylaod: ClarkeContractAttachmentsPayload): Promise<AxiosResponse<any, any>>;

  clarkeContracts: ClarkeContract;
  loading: boolean;
  loadingFormActions: boolean;
  groupsFilter: GroupFilterParsed;
  groups: GroupsGraphQlResponse['groups']['data'];
  energyContracts: EnergyContractParsed;
  contractToUpdate: ClarkeContractPayload;
};

export const ClarkeContractContext = createContext({} as ClarkeContractContextType);

interface Provider {
  children: React.ReactNode;
}

const ClarkeContractProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const { setOpenNotificationFeedback, setNotificationResponse } = useNotification();

  const [clarkeContracts, setClarkeContracts] = useState<ClarkeContract>({ total: 0, limit: 0, data: [] });
  const [groupsFilter, setGroupsFilter] = useState<GroupFilterParsed>({ totalPage: 0, groupsFilter: [] });
  const [groups, setGroups] = useState<GroupsGraphQlResponse['groups']['data']>([]);
  const [energyContracts, setEnergyContracts] = useState<EnergyContractParsed>({
    totalPage: 0,
    energyContracts: [],
  });
  const [contractToUpdate, setContractToUpdate] = useState<ClarkeContractPayload>({} as ClarkeContractPayload);

  const [getClarkeContracts, { loading }] = useLazyQuery<ClarkeContractGraphQlResponse>(
    GET_ALL_CLARKE_CONTRACTS_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const dataParsed = handleParserData(data.getAllClarkeContracts.data);
        setClarkeContracts({
          total: data.total,
          limit: data.limit,
          data: dataParsed,
        });
      },
      onError: () => {
        setClarkeContracts({ total: 0, limit: 0, data: [] });
      },
    },
  );

  const [getClarkeContract, { loading: contractToUpdateLoading }] = useLazyQuery(GET_CLARKE_CONTRACT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const response = data.getClarkeContractById;
      const dataParsed = handleContractToUpdate(response);
      const energyContract = parseEnergyContracts(response.energyContracts);
      const groups = Array(1).fill({
        id: response.group.id,
        name: response.group.name,
        units: response.units,
      });
      setEnergyContracts({
        totalPage: NaN,
        energyContracts: energyContract,
      });
      setGroups(groups);
      setContractToUpdate(dataParsed);
    },
    onError: () => {
      setContractToUpdate({} as ClarkeContractPayload);
    },
  });

  const [getGroupsFilter, { loading: loadingGroupsFilter }] = useLazyQuery<ClarkeContractGroupsFilterGraphQlResponse>(
    GET_CLARKE_GROUPS_FILTER_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const groupsOptions = data.getAllClarkeContracts.data.map(({ group }) => ({
          value: group.id,
          label: group.name,
        }));
        const totalPage = data.total / data.limit;
        setGroupsFilter((prevValue) => ({
          totalPage: Math.ceil(totalPage),
          groupsFilter: [...prevValue.groupsFilter, ...groupsOptions],
        }));
      },
      onError: () => {
        setGroupsFilter({ totalPage: 0, groupsFilter: [] });
      },
    },
  );

  const [getGroups, { loading: loadingGroups }] = useLazyQuery<GroupsGraphQlResponse>(GET_GROUPS_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const groupsSorted = data.groups.data.sort((groupA, groupB) => (groupA.name >= groupB.name ? 1 : -1));

      setGroups(groupsSorted);
    },
    onError: () => {
      setGroups([]);
    },
  });

  const [getEnergyContracts, { loading: loadingList }] = useLazyQuery(GET_ENERGY_CONTRACTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dataParsed = parseEnergyContracts(data.energyContracts.data);
      const totalPage = data.energyContracts.total / data.energyContracts.limit;
      setEnergyContracts((prevValue) => ({
        totalPage: Math.ceil(totalPage),
        energyContracts: [...prevValue.energyContracts, ...dataParsed],
      }));
    },
    onError: () => {
      setEnergyContracts({ totalPage: 0, energyContracts: [] });
    },
  });

  const [createClarkeContract] = useMutation(CREATE_CLARKE_CONTRACT, {
    fetchPolicy: 'network-only',
  });

  const [updateClarkeContract] = useMutation(EDIT_CLARKE_CONTRACT, {
    fetchPolicy: 'network-only',
  });

  const [deleteClarkeContract, { loading: deleteLoading }] = useMutation(DELETE_CLARKE_CONTRACT, {
    fetchPolicy: 'network-only',
  });

  const [deleteAttachmentClarkeContract, { loading: deleteAttachmentLoading }] = useMutation(
    DELETE_ATTACHMENT_CLARKE_CONTRACT,
    {
      fetchPolicy: 'network-only',
    },
  );

  async function getClarkeContractsHandler(inputParams: GetClarkeContractParams) {
    return await getClarkeContracts({
      variables: {
        input: inputParams,
      },
    });
  }

  async function getClarkeContractByIdHandler(contractId: string) {
    return await getClarkeContract({
      variables: {
        contractId,
      },
    });
  }

  async function getGroupsFilterHandler(page: number) {
    return await getGroupsFilter({
      variables: {
        input: { page, groupId: null, term: null },
      },
    });
  }

  async function getGroupsListHandler() {
    return await getGroups();
  }

  async function getEnergyContractsHandler(params: GetEnergyContractsParams) {
    return await getEnergyContracts({
      variables: { input: params },
    });
  }

  function createClarkeContractHandler(payload: ClarkeContractPayload) {
    return createClarkeContract({
      variables: {
        input: { ...payload },
      },
    })
      .then((response) => {
        return {
          id: response.data['createClarkeContract']['id'],
          feedback: { message: 'CREATING_CONTRACT_SUCCESS' as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'CREATING_CONTRACT_ERROR' as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      });
  }

  function updateClarkeContractHandler(contractId: string, payload: ClarkeContractPayload) {
    return updateClarkeContract({
      variables: { contractId, input: { ...payload } },
    })
      .then((response) => {
        const wasUpdated = response.data['updateClarkeContract'];
        const messageTxt = wasUpdated ? 'UPDATING_CONTRACT_SUCCESS' : 'UPDATING_CONTRACT_ERROR';
        return {
          id: wasUpdated ? contractId : null,
          feedback: { message: messageTxt as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      })
      .catch(() => {
        return {
          id: null,
          feedback: { message: 'UPDATING_CONTRACT_ERROR' as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      });
  }

  function deleteClarkeContractHandler(contractId: string) {
    return deleteClarkeContract({
      variables: { contractId },
      refetchQueries: [GET_ALL_CLARKE_CONTRACTS_QUERY],
    })
      .then((response) => {
        const wasDeleted = response.data['deleteClarkeContract'];
        const messageTxt = wasDeleted ? 'DELETING_CONTRACT_SUCCESS' : 'DELETING_CONTRACT_ERROR';
        return {
          success: wasDeleted,
          feedback: { message: messageTxt as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      })
      .catch(() => {
        return {
          success: false,
          feedback: { message: 'DELETING_CONTRACT_ERROR' as FeedbackType, tab: 'CLARKE_CONTRACT' as ContractType },
        };
      });
  }

  function deleteAttachmentClarkeContractHandler(attachmentId: string) {
    return deleteAttachmentClarkeContract({
      variables: { attachmentId },
      refetchQueries: [GET_CLARKE_CONTRACT_BY_ID_QUERY],
    })
      .then((response) => {
        setOpenNotificationFeedback(true);
        const success = response.data['deleteClarkeContractAttachment'];
        const messageNotificationType = success
          ? 'DELETING_CONTRACT_ATTACHMENT_SUCCESS'
          : 'DELETING_CONTRACT_ATTACHMENT_ERROR';
        setNotificationResponse(contractFeedbackMessage('CLARKE_CONTRACT', messageNotificationType));
        return {
          success,
        };
      })
      .catch(() => {
        setOpenNotificationFeedback(true);
        setNotificationResponse(contractFeedbackMessage('CLARKE_CONTRACT', 'DELETING_CONTRACT_ATTACHMENT_ERROR'));
        return {
          success: false,
        };
      });
  }

  function createClarkeContractAttachmentsHandler(paylaod: ClarkeContractAttachmentsPayload) {
    return createClarkeContractAttachments(paylaod, accessToken);
  }

  const isLoading = React.useMemo(() => {
    const updatePageLoad = contractToUpdateLoading && loadingGroups;
    const createPageLoad = !contractToUpdateLoading && loadingGroups;

    return loading || loadingGroupsFilter || updatePageLoad || createPageLoad || deleteLoading;
  }, [loading, loadingGroupsFilter, deleteLoading, contractToUpdateLoading, loadingGroups]);

  return (
    <ClarkeContractContext.Provider
      value={{
        loading: isLoading,
        loadingFormActions: deleteAttachmentLoading || loadingList,
        groups,
        energyContracts,
        clarkeContracts,
        groupsFilter,
        contractToUpdate,
        getClarkeContractsHandler,
        getClarkeContractByIdHandler,
        getGroupsFilterHandler,
        getGroupsListHandler,
        getEnergyContractsHandler,
        createClarkeContractHandler,
        deleteClarkeContractHandler,
        updateClarkeContractHandler,
        createClarkeContractAttachmentsHandler,
        deleteAttachmentClarkeContractHandler,
      }}
    >
      {children}
    </ClarkeContractContext.Provider>
  );
};

export default ClarkeContractProvider;

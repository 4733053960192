import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EnergyContract } from '@contexts/clarke-contract/types';
import { FormField, FormSection, MultiSelectFormField, NumericFormField } from '@components/atoms/form';
import { ClarkeContractPayload, GroupsGraphQlResponse } from '@contexts/clarke-contract/types';
import { ComboBoxFormField } from '../form/combobox';

interface GroupAndEnergyContractProps {
  groups: GroupsGraphQlResponse['groups']['data'];
  loadingEnergyContracts: boolean;
  handleScrollComboBox: () => void;
  handleChangeComboBox: (serachItem: string | null) => void;
  contracts: EnergyContract[];
}

const GroupAndEnergyContractSection = ({
  loadingEnergyContracts,
  contracts,
  handleChangeComboBox,
  handleScrollComboBox,
  groups,
}: GroupAndEnergyContractProps): JSX.Element => {
  const { watch } = useFormContext<ClarkeContractPayload>();
  const contractId = watch('id');
  const groupId = watch('groupId');

  const groupOptions = groups.length ? groups.map(({ id, name }) => ({ label: name, value: id })) : [];
  const groupUnits = groups.find(({ id }) => groupId === id)?.units;
  const unitsOptions = groupUnits?.length ? groupUnits.map(({ id, name }) => ({ value: id, label: name })) : [];

  const energyContractOptions = contracts.length ? contracts.map(({ group, id }) => ({ label: group, value: id })) : [];
  const loadingContractsOption = [{ label: 'Buscando contratos...', value: '' }];

  return (
    <>
      <FormSection title="Cliente e Contrato de energia" subtitle="Cliente e CCEALs sobre esse contrato de gestão." />
      <div className="col-end-5 gap-x-6 gap-y-6 mb-7 w-full lg:grid lg:grid-cols-2 lg:col-start-2 lg:mb-0 xl:grid-cols-3">
        {contractId ? (
          <FormField<ClarkeContractPayload>
            id="id"
            label="ID do contrato de gestão"
            field="id"
            disabled
            options={{ value: true }}
            placeholder="ID gerado automaticamente"
          />
        ) : null}
        <ComboBoxFormField<ClarkeContractPayload>
          id="groupId"
          label="Grupo Comercial"
          field="groupId"
          placeholder="Selecione o grupo comercial"
          inputOptions={groupOptions}
          options={{ required: { value: true, message: 'O grupo é obrigatório' } }}
        />

        <MultiSelectFormField<ClarkeContractPayload>
          id="unitsIds"
          inputOptions={unitsOptions}
          placeholder="Selecione as unidades "
          label="Unidades consumidoras"
          field="unitsIds"
          options={{ required: { value: true, message: 'Selecione as unidades' } }}
        />

        <MultiSelectFormField<ClarkeContractPayload>
          id="energyContractsIds"
          inputOptions={loadingEnergyContracts ? loadingContractsOption : energyContractOptions}
          placeholder="Selecione os CCEALs "
          label="CCEALs"
          field="energyContractsIds"
          isLoading={loadingEnergyContracts}
          onMenuScrollToBottom={handleScrollComboBox}
          options={{
            required: { value: true, message: 'Selecione os CCEAls' },
            onChange: (value) => {
              if (typeof value !== 'object') handleChangeComboBox(value);
            },
          }}
        />

        <NumericFormField<ClarkeContractPayload>
          id="signatureDate"
          field="signatureDate"
          label="Data da assinatura"
          formatProps={{
            placeholder: 'DD/MM/AAAA',
            format: '##/##/####',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
            isNumericString: true,
          }}
        />
      </div>
    </>
  );
};

export default GroupAndEnergyContractSection;

import { Tooltip } from '@clarke-energia/foton';
import React from 'react';

interface FormSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  tooltipContent?: string;
}
export const FormSection: React.FC<FormSectionProps> = ({ title, subtitle, tooltipContent, ...attrs }) => {
  const { className } = attrs;
  return (
    <div className={`flex flex-col space-y-1 ${className}`}>
      <div className="">
        <span className="mr-4 font-bold">{title}</span>
        {tooltipContent && <Tooltip className="inline top-[0.15rem]" content={tooltipContent} />}
      </div>
      <p className="text-neutral-60 text-paragraph-medium">{subtitle}</p>
    </div>
  );
};

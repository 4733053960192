import React, { useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import { CreateCceeProfilePayload, EMPTY_CCEE_PROFILE } from '@contexts/ccee-profile/types';
import useCommercialGroups from '@hooks/use-commercial-group';
import { useCceeProfiles } from '@hooks/use-cce-profile';
import { useUnits } from '@hooks/use-units';

import { FormField, MultiSelectFormField, NumericFormField, SelectFormField } from '@components/atoms/form';

import './style.css';

interface Form {
  dataToUpdate: CreateCceeProfilePayload | null;
  onFormSuccess: () => void;
  onFormFail: () => void;
}

function FormUpsertCceeProfile({ onFormSuccess, onFormFail, dataToUpdate }: Form) {
  const { commercialGroups, getCommercialGroups } = useCommercialGroups();
  const { createCceeProfileHandler, updateCceeProfileHandler, loading } = useCceeProfiles();
  const { setGroupId, units } = useUnits();

  const methods = useForm<CreateCceeProfilePayload>({
    defaultValues: dataToUpdate ? dataToUpdate : EMPTY_CCEE_PROFILE,
  });
  const groupId = methods.watch('groupId');

  React.useEffect(() => {
    if (groupId) setGroupId(groupId);
  }, [groupId]);

  useEffect(() => {
    getCommercialGroups();
  }, []);

  const unitsWithoutProfile = units ? units.filter(({ cceeProfile }) => !cceeProfile) : '';

  const unitsOptions = dataToUpdate ? units : unitsWithoutProfile;

  const onSubmit: SubmitHandler<CreateCceeProfilePayload> = async (values) => {
    const { groupId, ...restValues } = values;

    const dataHandler = dataToUpdate ? updateCceeProfileHandler : createCceeProfileHandler;
    const profileResponse = await dataHandler({
      ...restValues,
    });
    if (profileResponse.id) {
      onFormSuccess();
    } else {
      onFormFail();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        <NumericFormField<CreateCceeProfilePayload>
          id="idCcee"
          label="ID CCEE"
          field="idCcee"
          formatProps={{
            placeholder: 'Insira o id do perfil da CCEE.',
            format: '#######',
            mask: '',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
          }}
          options={{
            required: { value: true, message: 'O campo é obrigatório' },
            valueAsNumber: false,
          }}
        />
        <FormField<CreateCceeProfilePayload>
          id="name"
          label="Perfil CCEE"
          field="name"
          options={{
            required: { value: true, message: 'O campo é obrigatório' },
          }}
        />
        <SelectFormField<CreateCceeProfilePayload>
          id="groupId"
          label="Grupo Comercial"
          field="groupId"
          inputOptions={commercialGroups
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((group) => ({
              value: group.id,
              optionLabel: group.name,
            }))}
          options={{ required: { value: true, message: 'O campo é obrigatório' } }}
        />
        <MultiSelectFormField<CreateCceeProfilePayload>
          id="units"
          inputOptions={
            unitsOptions
              ? unitsOptions
                  .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                  .map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                  }))
              : [{ value: '', label: '' }]
          }
          placeholder="Selecione as unidades "
          label="Unidades consumidoras"
          field="units"
          options={{ required: { value: true, message: 'Selecione uma unidade' } }}
        />
        <Button
          loading={loading}
          label={`${dataToUpdate ? 'Atualizar' : 'Criar'} perfil CCEE`}
          kind={'primary'}
          className="mt-5"
        />
      </form>
    </FormProvider>
  );
}

export default FormUpsertCceeProfile;

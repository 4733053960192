import React from 'react';
import Skeleton from 'react-loading-skeleton';

import BoxTextBorder from '@components/atoms/economy-report/box-border-top';

interface CustomerInfoProps {
  infos: { title: string | null; value: string | number | null; style?: string }[];
  header?: string;
  isFetchingData: boolean;
}

const CustomerInfo = ({ infos, header, isFetchingData }: CustomerInfoProps): JSX.Element => {
  return (
    <div>
      {header && <BoxTextBorder text={header} />}
      <div className="grid grid-cols-3 gap-x-4 gap-y-4 lg:gap-x-6">
        {infos &&
          infos.map(({ title, value, style }) => (
            <div className={`${style ? style : ''} col-span-1`} key={title}>
              <p className="text-paragraph-small text-neutral-50" data-cy="item-name">
                {title}
              </p>
              <p className="text-black text-paragraph-medium" data-cy="item-content">
                {isFetchingData ? <Skeleton /> : value}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomerInfo;

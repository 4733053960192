import { ProinfaTableData } from '@contexts/proinfa-contract/types';

export const currentYearStr = new Date().getFullYear().toString();

export const months: string[] = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const getMonthColumns = () => {
  return months.map((month: any) => {
    return {
      accessor: month,
      header: month,
      renderAsElement: (entry: ProinfaTableData) => entry.monthValue[month],
    };
  });
};

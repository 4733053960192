import React from 'react';
import numeral from 'numeral';
import { IconProps } from '@clarke-energia/foton';

import { convertAverageConsumptionFromMwmToMwh } from '@utils/energy';

import {
  IKickOffDiagnosis,
  IKickOffSuggestedProduct,
  KickOffProductType,
  KickOffStatus,
  KickOffTraderType,
  KickOffWarrantyType,
} from '@contexts/kick-off/types';

import KickOffPanelMessagesIndexer from '@components/molecules/kick-off/helper';

import EmojiStatus from './emoji-status';

interface IKickOffUnavailableDiagnosis {
  kickOffStatus: keyof typeof KickOffStatus;
}

export const KickOffUnavailableDiagnosis = ({ kickOffStatus }: IKickOffUnavailableDiagnosis) => {
  const diagnosisSectionMessageContent = KickOffPanelMessagesIndexer.DIAGNOSIS_SECTION[kickOffStatus];

  return (
    <div className="flex gap-5 w-full lg:w-3/5">
      <EmojiStatus icon={'FaceFrownIcon'} backgroundColorClassName="bg-danger-10" />
      <div className="flex flex-col gap-4">
        <p className="text-heading-small md:text-heading-xsmall">{diagnosisSectionMessageContent?.title}</p>
        <p className="text-paragraph-small md:text-paragraph-medium">{diagnosisSectionMessageContent?.description}</p>
      </div>
    </div>
  );
};

interface IKickOffAvailableDiagnosis {
  diagnosis: IKickOffDiagnosis;
}

export const KickOffAvailableDiagnosis = ({ diagnosis }: IKickOffAvailableDiagnosis) => {
  const suggestedProduct = diagnosis.suggestedProduct as IKickOffSuggestedProduct;

  const parseFlexibilityValue = () => {
    const parsedUpperFlexibility = numeral(Math.abs(suggestedProduct.upperFlexibility as number)).format('% 0.0');
    const parsedLowerFlexibility = numeral(Math.abs(suggestedProduct.lowerFlexibility as number)).format('% 0.0');
    return `-${parsedLowerFlexibility}/+${parsedUpperFlexibility}`;
  };

  const parseAverageConsumptionValue = () => {
    if (!suggestedProduct.averageConsumptionInMwm) return '';

    const averageConsumptionInMwm = numeral(suggestedProduct.averageConsumptionInMwm).format('0.0000').concat(' MWm');
    const averageConsumptionInMwh = numeral(
      convertAverageConsumptionFromMwmToMwh(suggestedProduct.averageConsumptionInMwm),
    )
      .format('0.00')
      .concat(' MWh');

    return `${averageConsumptionInMwm} (${averageConsumptionInMwh})`;
  };
  const parseContractTermInYearsValue = () => `${suggestedProduct.contractTermInYears} anos`;

  const suggestedProductContentIndexer: Array<{ label: string; icon: IconProps['icon']; value?: string }> = [
    {
      label: 'Produto',
      icon: 'PuzzlePieceIcon',
      value: KickOffProductType[suggestedProduct.productType],
    },
    {
      label: 'Modalidade de contratação',
      icon: 'NewspaperIcon',
      value: KickOffTraderType[suggestedProduct.traderType],
    },
    {
      label: 'Consumo médio mensal',
      icon: 'LightBulbIcon',
      value: parseAverageConsumptionValue(),
    },
    {
      label: 'Flexibilidade',
      icon: 'AdjustmentsVerticalIcon',
      value: parseFlexibilityValue(),
    },
    {
      label: 'Duração',
      icon: 'ClockIcon',
      value: parseContractTermInYearsValue(),
    },
    {
      label: 'Garantia',
      icon: 'BriefcaseIcon',
      value: KickOffWarrantyType[suggestedProduct.warrantyType],
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-y-5 gap-x-6 md:grid-cols-3">
      {suggestedProductContentIndexer.map((content, index) => (
        <div key={index} className="flex gap-4 items-center">
          <EmojiStatus icon={content.icon} backgroundColorClassName="bg-primary-10" />
          <div className="flex overflow-x-hidden flex-col">
            <div className="text-paragraph-small text-neutral-50 truncate md:text-paragraph-medium">
              {content.label}
            </div>
            <div className="text-paragraph-medium truncate md:text-heading-xsmall">{content.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

import { useContextSelector } from 'use-context-selector';
import { GroupUserContext, GroupUserContextType } from '@contexts/user-group';

const useGroupUsers = (): GroupUserContextType => {
  const getGroupHandler = useContextSelector(GroupUserContext, (groups) => groups.getGroupHandler);
  const setCommercialGroupId = useContextSelector(GroupUserContext, (groups) => groups.setCommercialGroupId);
  const groupUsers = useContextSelector(GroupUserContext, (groups) => groups.groupUsers);
  const group = useContextSelector(GroupUserContext, (group) => group.group);
  const loading = useContextSelector(GroupUserContext, (groups) => groups.loading);
  const createGroupsUserHandler = useContextSelector(GroupUserContext, (group) => group.createGroupsUserHandler);
  const deleteGroupsUserHandler = useContextSelector(GroupUserContext, (group) => group.deleteGroupsUserHandler);
  const categorizeGroupsUserRolesHandler = useContextSelector(
    GroupUserContext,
    (group) => group.categorizeGroupsUserRolesHandler,
  );
  const loadingRolesForClientUsers = useContextSelector(GroupUserContext, (group) => group.loadingRolesForClientUsers);
  const loadingGetGroup = useContextSelector(GroupUserContext, (group) => group.loadingGetGroup);

  return {
    getGroupHandler,
    groupUsers,
    loading,
    setCommercialGroupId,
    group,
    createGroupsUserHandler,
    deleteGroupsUserHandler,
    categorizeGroupsUserRolesHandler,
    loadingRolesForClientUsers,
    loadingGetGroup,
  };
};

export default useGroupUsers;

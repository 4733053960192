import { gql } from '@apollo/client';

export const CREATE_TASK = gql`
  mutation CreateTask($input: SyncMigrationReportInput) {
    syncMigrationReport(input: $input) {
      success
    }
  }
`;

export const SYNC_BATCH_MIGRATION_REPORTS = gql`
  mutation SynBatchMigrationReports($input: [SyncMigrationReportInput]) {
    syncBatchMigrationReport(input: $input) {
      success
    }
  }
`;

export const SEND_BATCH_MIGRATION_REPORT_EMAIL = gql`
  mutation SendBatchMigrationReport($input: [SendBatchMigrationReportInput]) {
    sendBatchMigrationReport(input: $input)
  }
`;

import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { INotificationFeedbackContent } from '@utils/constants/common';

import { CustomerStatus } from './types';
import { NotificationMessagesByCustomerStatus } from './constants';
import { MUTATION_CREATE_NOTIFICATION_INPUT } from './mutations';

export interface NotificationContextType {
  handleCreateNotification: (
    customerStatus: CustomerStatus,
    targetUsersIds: string[],
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  isFetchingNotifications: boolean;
  setNotificationResponse: (content: INotificationFeedbackContent) => void;
  setOpenNotificationFeedback: (value: boolean) => void;
  openNotificationFeedBack: boolean;
  messageNotificationFeedBack: INotificationFeedbackContent | undefined;
}

export const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();

  const handleCreateNotification = (customerStatus: CustomerStatus, targetUsersIds: string[]) => {
    return CreateNotification({
      variables: {
        input: {
          title: NotificationMessagesByCustomerStatus[customerStatus].title,
          description: NotificationMessagesByCustomerStatus[customerStatus].description,
          topic: NotificationMessagesByCustomerStatus[customerStatus].topic,
          type: NotificationMessagesByCustomerStatus[customerStatus].type,
          targetUsersIds: targetUsersIds,
        },
      },
    });
  };

  const [CreateNotification, { loading: loadingCreateNotification }] = useMutation(MUTATION_CREATE_NOTIFICATION_INPUT, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      throw `ERROR: ${e}`;
    },
  });

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  return (
    <NotificationContext.Provider
      value={{
        handleCreateNotification,
        isFetchingNotifications: loadingCreateNotification,
        openNotificationFeedBack,
        messageNotificationFeedBack,
        setNotificationResponse,
        setOpenNotificationFeedback,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import uniqby from 'lodash.uniqby';
import { createContext } from 'use-context-selector';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '@src/ApolloWrapper';
import { donwloadExcel } from '@services/ccee-api';

import {
  MonthlyControlMeasurementByUnitIdManager,
  UpdateMeasurementAutomaticallyManager,
  ConsolidatedMeasurementByIdManager,
  UpdateMeasurementManuallyManager,
  UnitConsumptionMeasuresPerHourManager,
  UpdateDailyConsumptionOfUnitsManuallyManager,
} from './manager';
import {
  GET_ALL_CONTROL_MEASUREMENT_BY_UNIT,
  GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY,
  GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY,
  GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY,
} from './queries';
import {
  EnergyAuditConsumptionContextType,
  MonthlyControlMeasurementByUnitId,
  ConsolidatedMeasurement,
  UnitConsumptionMeasuresPerHour,
  DownloadExcelPayload,
} from './types';
import {
  MUTATION_UPDATE_MEASUREMENT_AUTOMATICALLY,
  MUTATION_UPDATE_MEASUREMENT_MANUALLY,
  MUTATION_UPDATE_DAILY_CONSUMPTION_OF_UNITS_MANUALLY,
} from './mutations';

export * from './types';

export const EnergyAuditsConsumptionContext = createContext({} as EnergyAuditConsumptionContextType);

interface Provider {
  children: React.ReactNode;
}

const EnergyAuditConsumptionProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const [consolidatedMeasurementDocId, setConsolidatedMeasurementDocId] = useState<string | undefined>('');
  const [monthlyControlMeasurementByUnitId, setMonthlyControlMeasurementByUnitId] =
    useState<Array<MonthlyControlMeasurementByUnitId>>();
  const [unitConsumptionMeasuresPerHour, setUnitConsumptionMeasuresPerHour] =
    useState<Array<UnitConsumptionMeasuresPerHour>>();
  const [consolidatedMeasurementById, setConsolidatedMeasurementById] = useState<ConsolidatedMeasurement | undefined>();
  const [consumptionsPeriods, setConsumptionsPeriods] = useState<string[]>([]);

  const [getConsolidatedMeasurementById, { loading: loadingGetConsolidatedMeasurementById }] = useLazyQuery(
    GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new ConsolidatedMeasurementByIdManager(data);
        setConsolidatedMeasurementById(manager.consolidateMeasurementById);
      },
      onError: () => {
        setConsolidatedMeasurementById({} as ConsolidatedMeasurement);
      },
      variables: {
        getConsolidatedMeasurementByIdId: consolidatedMeasurementDocId as string,
      },
    },
  );

  const [getMonthlyControlMeasurementByUnitId, { loading: loadinggetMonthlyControlMeasurementByUnitId }] = useLazyQuery(
    GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new MonthlyControlMeasurementByUnitIdManager(data);
        setMonthlyControlMeasurementByUnitId(manager.monthlyControlMeasurementByUnitId);
      },
      onError: () => {
        setMonthlyControlMeasurementByUnitId([]);
      },
    },
  );

  const [getUnitConsumptionMeasuresPerHour, { loading: loadinggetUnitConsumptionMeasuresPerHour }] = useLazyQuery(
    GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new UnitConsumptionMeasuresPerHourManager(data);
        setUnitConsumptionMeasuresPerHour(manager.unitConsumptionMeasuresPerHour);
      },
      onError: () => {
        setUnitConsumptionMeasuresPerHour([]);
      },
    },
  );

  const [getAllControlMeasurementByUnitId, { loading: loadingControlMeasurementsByUnit }] = useLazyQuery(
    GET_ALL_CONTROL_MEASUREMENT_BY_UNIT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const dates = uniqby(data.getAllControlMeasurementByUnitId, 'monthYear')
          .map((date: any) => date.monthYear)
          .reverse();
        setConsumptionsPeriods(dates);
      },
      onError: () => {
        setUnitConsumptionMeasuresPerHour([]);
      },
    },
  );

  const [updateMeasurementAutomatically, { loading: loadingUpdateMeasurementAutomatically }] = useMutation(
    MUTATION_UPDATE_MEASUREMENT_AUTOMATICALLY,
    { fetchPolicy: 'network-only' },
  );

  const [updateMeasurementManually, { loading: loadingUpdateMeasurementManually }] = useMutation(
    MUTATION_UPDATE_MEASUREMENT_MANUALLY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [updateDailyConsumptionOfUnitsManually, { loading: loadingUpdateDailyConsumptionOfUnitsManually }] =
    useMutation(MUTATION_UPDATE_DAILY_CONSUMPTION_OF_UNITS_MANUALLY, {
      fetchPolicy: 'network-only',
    });

  const getMonthlyControlMeasurementByUnitIdHandler = (unitId: string, monthYear: string) => {
    return getMonthlyControlMeasurementByUnitId({
      variables: {
        input: {
          unitId: unitId as string,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getUnitConsumptionMeasuresPerHourHandler = (unitId: string, monthYear: string) => {
    return getUnitConsumptionMeasuresPerHour({
      variables: {
        input: {
          unitId: unitId as string,
          monthYear: monthYear as string,
        },
      },
    });
  };

  const getAllControlMeasurementByUnitIdHandler = (unitId: string) => {
    return getAllControlMeasurementByUnitId({
      variables: { unitId },
    });
  };

  const getConsolidatedMeasurementByIdHandler = () => {
    return getConsolidatedMeasurementById();
  };

  const updateMeasurementAutomaticallyHandler = async (unitId: string, monthYear: string) => {
    try {
      const response = await updateMeasurementAutomatically({
        variables: {
          input: {
            unitId,
            monthYear,
          },
        },
        refetchQueries: [GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY, GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY],
      });

      const manager = new UpdateMeasurementAutomaticallyManager(response.data);
      return manager.success ? 'MISSING_HOURS_UPDATED_SUCCESS' : 'MISSING_HOURS_UPDATED_FAILED';
    } catch (error) {
      return 'MISSING_HOURS_UPDATED_FAILED';
    }
  };

  const updateDailyConsumptionOfUnitsManuallyHandler = async (unitId: string, monthYear: string) => {
    try {
      const response = await updateDailyConsumptionOfUnitsManually({
        variables: {
          input: {
            unitId,
            monthYear,
          },
        },
        refetchQueries: [GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY, GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY],
      });

      const manager = new UpdateDailyConsumptionOfUnitsManuallyManager(response.data);
      return manager.success ? 'DAILY_CONSUMPTIONS_UPDATED_SUCCESS' : 'DAILY_CONSUMPTIONS_UPDATED_FAILED';
    } catch (error) {
      return 'DAILY_CONSUMPTIONS_UPDATED_FAILED';
    }
  };

  const updateMeasurementManuallyHandler = async (measurementValue: string, hour: string) => {
    try {
      const response = await updateMeasurementManually({
        variables: {
          input: {
            id: consolidatedMeasurementDocId as string,
            measurementValue,
            hour,
          },
        },
        refetchQueries: [GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY, GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY],
      });

      const manager = new UpdateMeasurementManuallyManager(response.data);
      setConsolidatedMeasurementById(manager.consolidatedMeasurementUpdated);
      return true;
    } catch (error) {
      setConsolidatedMeasurementById({} as ConsolidatedMeasurement);
    }
  };

  const handleDownloadExcel = async (data: DownloadExcelPayload) => {
    try {
      const response = await donwloadExcel(data, accessToken);
      const contentDisposition = response.headers['content-disposition'];
      const match = contentDisposition.match(/filename=(.+)/);
      const fileName = match ? match[1] : 'unknown';
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      FileSaver.saveAs(blob, fileName);
      return 'EXCEL_DONWLOAD_SUCCESS';
    } catch (error) {
      return 'EXCEL_DONWLOAD_ERROR';
    }
  };

  useEffect(() => {
    if (consolidatedMeasurementDocId) getConsolidatedMeasurementByIdHandler();
  }, [consolidatedMeasurementDocId]);

  return (
    <EnergyAuditsConsumptionContext.Provider
      value={{
        setMonthlyControlMeasurementByUnitId,
        monthlyControlMeasurementByUnitId,
        unitConsumptionMeasuresPerHour,
        getMonthlyControlMeasurementByUnitIdHandler,
        getUnitConsumptionMeasuresPerHourHandler,
        getAllControlMeasurementByUnitIdHandler,
        setConsolidatedMeasurementDocId,
        consolidatedMeasurementById,
        updateMeasurementAutomaticallyHandler,
        updateMeasurementManuallyHandler,
        updateDailyConsumptionOfUnitsManuallyHandler,
        loading:
          loadinggetMonthlyControlMeasurementByUnitId ||
          loadinggetUnitConsumptionMeasuresPerHour ||
          loadingControlMeasurementsByUnit,
        mutationLoading:
          loadingUpdateDailyConsumptionOfUnitsManually ||
          loadingUpdateMeasurementAutomatically ||
          loadingUpdateMeasurementManually,
        handleDownloadExcel,
        consumptionsPeriods,
      }}
    >
      {children}
    </EnergyAuditsConsumptionContext.Provider>
  );
};

export default EnergyAuditConsumptionProvider;

import { ReadjustmentFrequencyEnum, ReadjustmentIndexEnum } from '@utils/translators/energy-contract';

export interface ClarkeContractAttachmentsPayload {
  clarkeContractAttachments: File[];
  clarkeContractId: string;
}

export interface GroupsGraphQlResponse {
  groups: { data: { id: string; name: string; units: { id: string; name: string }[] }[] };
}

export interface GetEnergyContractsParams {
  page: number;
  groupId?: string;
  term: string | null;
}

export interface GetClarkeContractParams {
  page: number;
  groupId: string | null;
  term: string | null;
}

export interface ClarkeContractData {
  contractId: string;
  groupName: string;
  managmentAmount: string;
  signatureDate: string;
}

export interface ClarkeContract {
  total: number;
  limit: number;
  data: Array<ClarkeContractData>;
}

interface GroupClarkeContract {
  id: string;
  name: string;
}

interface UnitClarkeContract {
  id: string;
  name: string;
}

export interface EnergyContract {
  id: string;
  group: string;
}

export interface Attachments {
  id: string;
  filename: string;
  url: string;
  fileSize: number;
}

export interface ClarkeContractResponse {
  id: string;
  group?: GroupClarkeContract;
  units: Array<UnitClarkeContract>;
  signatureDate?: string;
  energyContracts: Array<EnergyContract>;
  clarkeMagmentAmount: number;
  readjustmentIndex?: keyof typeof ReadjustmentIndexEnum;
  readjustmentBaseDate: string;
  readjustmentFrequency?: keyof typeof ReadjustmentFrequencyEnum;
  attachments: Array<Attachments>;
}

export interface EnergyContractGraphQl {
  id: string;
  group: {
    id: string;
    name: string;
  };
}

export interface ClarkeContractGraphQlResponse {
  total: number;
  limit: number;
  getAllClarkeContracts: { data: ClarkeContractResponse[] };
}

export interface ClarkeContractGroupsFilterGraphQlResponse {
  total: number;
  limit: number;
  getAllClarkeContracts: { data: { group: { name: string; id: string } }[] };
}

export interface EnergyContractGraphQlResponse {
  total: number;
  limit: number;
  energyContracts: { data: EnergyContractGraphQl[] };
}

export interface EnergyContractParsed {
  totalPage: number;
  energyContracts: EnergyContract[];
}

export interface GroupFilterParsed {
  totalPage: number;
  groupsFilter: { value: string; label: string }[];
}

export interface ClarkeContractPayload {
  id?: string;
  groupId: string;
  signatureDate: string | null;
  unitsIds: string[];
  energyContractsIds: string[];
  clarkeMagmentAmount: number | null;
  readjustmentIndex: keyof typeof ReadjustmentIndexEnum | null;
  readjustmentFrequency: keyof typeof ReadjustmentFrequencyEnum | null;
  readjustmentBaseDate: string | null;
  clarkeContractAttachments?: any[];
}

export interface DeleteCceeContractPayload {
  id: string;
}

export const EMPTY_CLARKE_CONTRACT: ClarkeContractPayload = {
  groupId: '',
  signatureDate: null,
  unitsIds: [],
  energyContractsIds: [],
  clarkeMagmentAmount: null,
  readjustmentIndex: null,
  readjustmentFrequency: null,
  readjustmentBaseDate: null,
  clarkeContractAttachments: [],
};

export const DocTypes = [
  { label: 'Contrato Clarke', value: 'CLARKE_CONTRACT' },
  { label: 'Contrato Fornecedor', value: 'SUPPLIER_CONTRACT' },
  { label: 'Procuração', value: 'POWER_OF_ATTORNEY' },
  { label: 'Termo de Gestão', value: 'MANAGEMENT_TERM' },
  { label: 'Fatura de Conta de Luz', value: 'ELECTRICITY_BILL_INVOICE' },
  { label: 'Termo de Pactuação', value: 'AGREEMENT_TERM' },
  { label: 'Contrato de Fornecimento de Energia', value: 'ENERGY_SUPPLY_CONTRACT' },
  { label: 'Carta Denúncia', value: 'LETTER_OF_COMPLAINT' },
  { label: 'Balanço Energético', value: 'ENERGY_BALANCE' },
  { label: 'Contrato de Uso de Sistema de Distribuição', value: 'DISTRIBUTION_SYSTEM_USE_CONTRACT' },
  { label: 'Documentos CCEE', value: 'CCEE_DOCUMENTS' },
  { label: 'Kit Bradesco', value: 'BRADESCO_KIT' },
];

export const translateDocumentType = (documentType: string) => {
  if (DocTypes.some((type: Record<string, string>) => type.value === documentType.replaceAll(' ', '_'))) {
    const doc = DocTypes.find((type) => type.value === documentType.replaceAll(' ', '_'));
    return doc?.label ?? '';
  }
};

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button } from '@clarke-energia/foton';

import { capitalize } from '@utils/text';
import { FinancialEventsFilters } from '@contexts/financial-events';
import useCommercialGroups from '@hooks/use-commercial-group';

import PopUpSetPeriod from '@components/molecules/popup-set-period';
import CommercialGroupPopUpFilter from '@components/molecules/commercial-group-popup-filter';

interface FinancialEventsFilterBarProps {
  title: string;
  filters: FinancialEventsFilters | undefined;
  setFilters: (value: React.SetStateAction<FinancialEventsFilters | undefined>) => void;
  someFilterUsed: boolean;
  setSomeFilterUsed: (value: React.SetStateAction<boolean>) => void;
}

interface GroupFilterElementsProps {
  onClick: (item: any) => void;
  name: string;
  id: string;
}

const FinancialEventsFilterBar: React.FC<FinancialEventsFilterBarProps> = ({
  title,
  setFilters,
  filters,
  someFilterUsed,
  setSomeFilterUsed,
}) => {
  const [openGroupFilterPopUp, setOpenGroupFilterPopUp] = useState(false);
  const [openDateFilterPopUp, setOpenDateFilterPopUp] = useState(false);
  const [groupFilterElements, setGroupFilterElements] = React.useState<GroupFilterElementsProps[]>();

  const generalLabelParser = (label: string) => capitalize(label?.replaceAll('_', ' '));
  const { commercialGroups, getCommercialGroups } = useCommercialGroups();

  const createGroupFilterElements = () => {
    const elements = commercialGroups?.map((group) => ({
      name: generalLabelParser(group.name),
      id: group.id,
      onClick: () => {
        setOpenGroupFilterPopUp(false);
        setSomeFilterUsed(true);
      },
    }));

    setGroupFilterElements(elements);
  };

  const clearFilters = () => {
    setFilters(undefined);
    setSomeFilterUsed(false);
    setOpenGroupFilterPopUp(false);
    setOpenDateFilterPopUp(false);
  };

  useMemo(() => {
    createGroupFilterElements();
  }, [openGroupFilterPopUp]);

  const dateFilterPopupRef = useRef<HTMLDivElement>(null);
  const groupFilterPopupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        ref === dateFilterPopupRef && setOpenDateFilterPopUp(false);
        ref === groupFilterPopupRef && setOpenGroupFilterPopUp(false);
      }
    };

    document.addEventListener('click', (event) => handleOutsideClick(event, dateFilterPopupRef));
    document.addEventListener('click', (event) => handleOutsideClick(event, groupFilterPopupRef));
    return () => {
      document.removeEventListener('click', (event) => handleOutsideClick(event, dateFilterPopupRef));
      document.removeEventListener('click', (event) => handleOutsideClick(event, groupFilterPopupRef));
    };
  }, [dateFilterPopupRef, groupFilterPopupRef]);

  useEffect(() => {
    getCommercialGroups();
  }, []);

  return (
    <div className="flex flex-col gap-5 mb-7 w-fit">
      <h1 className="font-normal text-heading-medium">{title}</h1>
      <div className="flex flex-row flex-wrap gap-4 justify-start w-full">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="relative" ref={dateFilterPopupRef}>
            <Button
              kind={filters?.month && filters?.year ? 'chip' : 'secondary'}
              label={
                filters?.month && filters?.year
                  ? `${filters?.month.toString().padStart(2, '0')}/${filters?.year}`
                  : 'Mês / Ano'
              }
              icon={openDateFilterPopUp ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              size="small"
              onClick={() => {
                setOpenDateFilterPopUp(!openDateFilterPopUp);
                setOpenGroupFilterPopUp(false);
              }}
            />
            <PopUpSetPeriod
              show={openDateFilterPopUp}
              className="absolute top-9 w-[8rem] md:w-[15rem]"
              setFilters={setFilters}
              filters={filters}
              setSomeFilterUsed={setSomeFilterUsed}
            />
          </div>
          <div className="relative" ref={groupFilterPopupRef}>
            <Button
              kind={filters?.group?.name ? 'chip' : 'secondary'}
              label={
                filters?.group?.name ? generalLabelParser(filters?.group?.name) ?? 'Grupo comercial' : 'Grupo comercial'
              }
              icon={openGroupFilterPopUp ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              size="small"
              onClick={() => {
                setOpenGroupFilterPopUp(!openGroupFilterPopUp);
                setOpenDateFilterPopUp(false);
              }}
            />
            {commercialGroups && (
              <CommercialGroupPopUpFilter
                className="overflow-y-visible absolute top-9 w-[8rem] md:w-[15rem]"
                setFilters={setFilters}
                setSomeFilterUsed={setSomeFilterUsed}
                setOpenGroupFilterPopUp={setOpenGroupFilterPopUp}
                groupFilterElements={groupFilterElements}
                show={openGroupFilterPopUp}
              />
            )}
          </div>
        </div>
        <div>
          <Button kind="ghost" label="Limpar filtros" disabled={!someFilterUsed} size="small" onClick={clearFilters} />
        </div>
      </div>
    </div>
  );
};

export default FinancialEventsFilterBar;

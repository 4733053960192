import React from 'react';
import { ColumnOptions, Tag } from '@clarke-energia/foton';

import { MigrationReport } from '@contexts/migration-report/types';
import MigrationTable from '@components/atoms/migration-report/table';
import FiltersBar, { FiltersBarProps } from '@components/atoms/energy-audit/filters';
import { MigrationReportsTableProps } from '@components/atoms/migration-report/table';

import { SyncManualTagStatusPropsSelector, expandableTableColumns, mainTableColumns } from './helper';

type TableData = Omit<MigrationReportsTableProps, 'columns' | 'expandable' | 'data'>;

interface MigrationProgressTableProps extends TableData {
  data: MigrationReport[];
  filters: FiltersBarProps;
}

const MigrationProgressTable = ({ data, loading, filters, ...props }: MigrationProgressTableProps): JSX.Element => {
  const mainTableColumnsWithSyncStatus: Array<ColumnOptions<MigrationReport>> = [
    ...mainTableColumns,
    {
      accessor: 'manualSyncStatus',
      header: 'Sincronização manual',
      tooltipText: 'Última sincronização manual feita a partir do disparo do botao "Sincronizar e Atualizar"',
      renderAsElement: (entry) => {
        const tag = SyncManualTagStatusPropsSelector[entry.manualSyncStatus];
        return <Tag color={tag.color} kind={tag.kind} label={tag.label} icon={tag.icon} />;
      },
    },
  ];

  const expandedTableContent = data.map(({ units, ...restContent }) => {
    return {
      data: {
        ...restContent,
      },
      expandedContent: (
        <MigrationTable data={units} columns={expandableTableColumns} loading={false} expandable={false} />
      ),
    };
  });

  return (
    <>
      <FiltersBar {...filters} />
      <MigrationTable
        {...props}
        loading={loading}
        data={expandedTableContent}
        columns={mainTableColumnsWithSyncStatus}
        expandable
      />
    </>
  );
};

export default MigrationProgressTable;

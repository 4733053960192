import { gql } from '@apollo/client';

export const GET_CCEE_PROFILES_QUERY = gql`
  query GetCceeProfiles {
    cceeProfiles {
      data {
        id
        name
        idCcee
        units {
          name
          id
          group {
            name
            id
          }
        }
      }
    }
  }
`;

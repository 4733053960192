import React, { useState, useRef } from 'react';
import { Button } from '@clarke-energia/foton';

type Options = {
  options: string[];
  onClick: (option: string) => void;
  isLoading?: boolean;
  onScrollToBotton?: () => void;
};

interface FiltersTableProps {
  filters: {
    label: string;
    options: string[];
    openOptions: () => void;
    onClick: (option: any) => void;
    showOptions: boolean;
    filterSelected: boolean;
  }[];
  clearFilter: () => boolean | void;
  isLoading?: boolean;
  onScrollToBotton?: () => void;
}

const FiltersOptions: React.FC<Options> = ({ options, onClick, isLoading, onScrollToBotton }) => {
  const ulRef = useRef<HTMLUListElement | null>(null);

  const handleScroll = () => {
    if (ulRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = ulRef.current;
      if (scrollTop + clientHeight === scrollHeight && onScrollToBotton) {
        onScrollToBotton();
      }
    }
  };

  return (
    <div className="absolute z-50 p-3 bg-white border-black shadow-xl border-[1px] rounded-large">
      <ul className="overflow-auto max-h-[17rem] md:w-[12rem]" ref={ulRef} onScroll={handleScroll}>
        {isLoading ? (
          <li className="py-2 px-4 w-full hover:cursor-pointer rounded-medium hover:bg-neutral-10">Buscando...</li>
        ) : (
          options.map((option, idx) => (
            <li
              key={`${option}-${idx}`}
              className="py-2 px-4 w-full hover:cursor-pointer rounded-medium hover:bg-neutral-10"
              onClick={() => onClick(option)}
            >
              {option}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

const Filters: React.FC<FiltersTableProps> = ({ filters, clearFilter, onScrollToBotton, isLoading }) => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  return (
    <div className="flex gap-x-4 mb-[37px]">
      {filters.map(({ label, options, onClick, showOptions, openOptions, filterSelected }, idx) => {
        return (
          <div className="relative" key={`${label}-${idx}`}>
            {filterSelected ? (
              <Button
                kind="chip"
                label={label}
                icon={showOptions ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                iconPosition="right"
                size="small"
                onClick={openOptions}
              />
            ) : (
              <Button
                kind="secondary"
                label={label}
                icon={showOptions ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                iconPosition="right"
                size="small"
                onClick={() => {
                  openOptions();
                  setSelectedIdx(idx);
                }}
              />
            )}
            {showOptions && (
              <FiltersOptions
                options={options}
                onClick={onClick}
                onScrollToBotton={onScrollToBotton}
                isLoading={isLoading}
              />
            )}
          </div>
        );
      })}
      <Button
        kind="ghost"
        label="Limpar filtros"
        disabled={!filters[selectedIdx].filterSelected}
        size="small"
        onClick={clearFilter}
      />
    </div>
  );
};

export default Filters;

import React from 'react';
import { NuggetInfo, NuggetInfoProps } from '@clarke-energia/foton';
import { UnitPayload } from '@contexts/unit';

export interface UnitExtraInformationSectionProps {
  selectedUnit: UnitPayload;
}

const UnitExtraInformationSection = ({ selectedUnit }: UnitExtraInformationSectionProps) => {
  const nuggetInfoData: NuggetInfoProps[] = [
    {
      id: '1',
      label: 'Código do ativo',
      textInfo: selectedUnit.assetCode ?? '-',
    },
    {
      id: '2',
      label: 'Ponto de medição',
      textInfo: selectedUnit.measuringPoint ?? '-',
    },
  ];

  return (
    <div className="col-span-full">
      <h1 className="mb-5">Informações extras</h1>
      <div className="flex">
        {nuggetInfoData.map((item) => (
          <NuggetInfo key={item.id} label={item.label} textInfo={item.textInfo} />
        ))}
      </div>
    </div>
  );
};

export default UnitExtraInformationSection;

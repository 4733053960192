import { useContextSelector } from 'use-context-selector';
import { GroupEconomyReportContext } from '@contexts/economy-reports';

const useGroupEconomyReport = () => {
  const loadingGroupReport = useContextSelector(GroupEconomyReportContext, (report) => report.loadingGroupReport);
  const getGroupEconomyReportByReportId = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getGroupEconomyReportByReportId,
  );

  const groupReport = useContextSelector(GroupEconomyReportContext, (report) => report.groupReport);

  return {
    getGroupEconomyReportByReportId,
    loadingGroupReport,
    groupReport,
  };
};

export default useGroupEconomyReport;

import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import useGroupUsers from '@hooks/use-user-groups';
import { useUsers } from '@hooks/users';

import { MultiSelectFormField } from '@components/atoms/form';

import './style.css';
import { UserInfo } from '@contexts/user';

interface IFormUpsertGroupUser {
  onFormSuccess: () => void;
  onFormFail: () => void;
  onCancel: () => void;
}

interface IUpsertGroupUserFormPayload {
  usersId: string[];
}

function FormUpsertGroupUser({ onFormSuccess, onFormFail, onCancel }: IFormUpsertGroupUser) {
  const [loading, setLoading] = React.useState(false);
  const [searchedUserEmail, setSearchedUserEmail] = React.useState<string>('');
  const { createGroupsUserHandler } = useGroupUsers();
  const { users, getUsersHandler } = useUsers();
  const [cloneUsers, setCloneUsers] = React.useState<UserInfo[]>([]);

  const methods = useForm<IUpsertGroupUserFormPayload>();
  const onSubmit: SubmitHandler<IUpsertGroupUserFormPayload> = async (values) => {
    setLoading(true);
    return createGroupsUserHandler(values.usersId)
      .then(() => {
        setLoading(false);
        onFormSuccess();
      })
      .catch(() => {
        setLoading(false);
        onFormFail();
      });
  };

  const handleSearchEmail = (typedTerm?: string) => {
    setLoading(true);
    const typeUsers = typedTerm && cloneUsers?.find((user) => user.email.includes(typedTerm));

    !typeUsers && typedTerm && typedTerm.length >= 3 && getUsersHandler(typedTerm);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  React.useEffect(() => {
    setTimeout(() => {
      handleSearchEmail(searchedUserEmail);
    }, 1000);
  }, [searchedUserEmail]);

  React.useEffect(() => {
    if (users?.length === 1) {
      const newUsersList = [...cloneUsers, ...users];
      setCloneUsers(newUsersList);
      return;
    }
    if (users) {
      setCloneUsers(users);
    }
  }, [users]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-4 justify-between pt-4 -mb-8">
        <div>
          <div className="flex flex-col gap-2 w-full">
            <MultiSelectFormField<IUpsertGroupUserFormPayload>
              id="usersId"
              inputOptions={
                cloneUsers
                  ? cloneUsers
                      .sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0))
                      .map((userEntry) => ({
                        value: userEntry.id,
                        label: userEntry.email,
                      }))
                  : [{ value: '', label: '' }]
              }
              placeholder="Selecione usuários..."
              label="Usuários"
              field="usersId"
              isLoading={loading}
              options={{
                onChange: (e) => setSearchedUserEmail(e),
                required: { value: true, message: 'Selecione usuários' },
              }}
            />
          </div>

          <div className="flex flex-col gap-4 mt-6 xl:flex-row xl:gap-8">
            <Button label="Adicionar usuários" kind={'primary'} loading={loading} className="w-full" />
            <Button label="Cancelar" kind={'secondary'} onClick={onCancel} className="w-full" type="button" />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
export default FormUpsertGroupUser;

import { useContextSelector } from 'use-context-selector';
import { GroupEconomyReportContext } from '@contexts/economy-reports';

const useGroupEconomyReports = () => {
  const groupName = useContextSelector(GroupEconomyReportContext, (group) => group.groupName);
  const setGroupId = useContextSelector(GroupEconomyReportContext, (group) => group.setGroupId);
  const loading = useContextSelector(GroupEconomyReportContext, (group) => group.loading);
  const reportsTable = useContextSelector(GroupEconomyReportContext, (report) => report.reportsTable);
  const handleUpdateReportsStatus = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.handleUpdateReportsStatus,
  );
  const setMessageNotificationFeedback = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.setMessageNotificationFeedback,
  );
  const setOpenNotificationFeedback = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.setOpenNotificationFeedback,
  );
  const messageNotificationFeedBack = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.messageNotificationFeedBack,
  );
  const openNotificationFeedBack = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.openNotificationFeedBack,
  );

  const handleNotifyReportsByEmail = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.handleNotifyReportsByEmail,
  );

  const getClarkeContractByGroupIdHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getClarkeContractByGroupIdHandler,
  );

  const getEnergyContractByGroupIdHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getEnergyContractByGroupIdHandler,
  );

  const getEnergyAuditsByGroupIdHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getEnergyAuditsByGroupIdHandler,
  );
  const getAllFinancialEventsByGroupIdHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getAllFinancialEventsByGroupIdHandler,
  );
  const getSupplyInvoiceHandler = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.getSupplyInvoiceHandler,
  );

  const initFormData = useContextSelector(GroupEconomyReportContext, (report) => report.initFormData);
  const unitsConsumptionFinancialEvent = useContextSelector(
    GroupEconomyReportContext,
    (report) => report.unitsConsumptionFinancialEvent,
  );
  const supplySection = useContextSelector(GroupEconomyReportContext, (report) => report.supplySection);

  const getGroupDataHandler = useContextSelector(GroupEconomyReportContext, (report) => report.getGroupDataHandler);

  return {
    reportsTable,
    groupName,
    setGroupId,
    loading,
    handleUpdateReportsStatus,
    setMessageNotificationFeedback,
    setOpenNotificationFeedback,
    messageNotificationFeedBack,
    openNotificationFeedBack,
    handleNotifyReportsByEmail,
    getClarkeContractByGroupIdHandler,
    initFormData,
    getEnergyContractByGroupIdHandler,
    getEnergyAuditsByGroupIdHandler,
    getAllFinancialEventsByGroupIdHandler,
    getGroupDataHandler,
    getSupplyInvoiceHandler,
    unitsConsumptionFinancialEvent,
    supplySection,
  };
};

export default useGroupEconomyReports;

import numeral from 'numeral';
import { englishFormatDateFromString, formatDateFromString } from '@utils/dayjs';
import {
  ClarkeContract,
  ClarkeContractGraphQlResponse,
  ClarkeContractPayload,
  ClarkeContractResponse,
  EnergyContract,
  EnergyContractGraphQlResponse,
} from './types';
import { formatDateStr, formatUuid } from '@utils/text';

export const handleParserData = (
  data: ClarkeContractGraphQlResponse['getAllClarkeContracts']['data'],
): ClarkeContract['data'] => {
  return data.map(({ id, signatureDate, clarkeMagmentAmount, group }) => {
    return {
      contractId: id,
      groupName: group?.name ?? '-',
      signatureDate: signatureDate ? formatDateFromString(signatureDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY') : '-',
      managmentAmount: numeral(clarkeMagmentAmount).format('$ 0,0.00'),
    };
  });
};

export const parseEnergyContracts = (
  contracts: EnergyContractGraphQlResponse['energyContracts']['data'],
): EnergyContract[] => {
  return contracts.map(({ id, group }) => ({
    id,
    group: `${group.name} (${formatUuid(id)})`,
  }));
};
export const parsePayloadToCreation = ({
  id,
  clarkeContractAttachments,
  signatureDate,
  readjustmentBaseDate,
  ...restPayload
}: ClarkeContractPayload): ClarkeContractPayload => {
  return {
    ...restPayload,
    readjustmentBaseDate: readjustmentBaseDate
      ? englishFormatDateFromString(readjustmentBaseDate, 'DDMMYYYY', 'YYYY-MM-DD')
      : null,
    signatureDate: signatureDate ? englishFormatDateFromString(signatureDate, 'DDMMYYYY', 'YYYY-MM-DD') : null,
  };
};

export const handleContractToUpdate = (contract: ClarkeContractResponse): ClarkeContractPayload => {
  const {
    id,
    clarkeMagmentAmount,
    readjustmentBaseDate,
    readjustmentFrequency,
    readjustmentIndex,
    signatureDate,
    group,
    units,
    energyContracts,
    attachments,
  } = contract;

  return {
    id: formatUuid(id),
    signatureDate: signatureDate ? formatDateStr(signatureDate) : null,
    readjustmentBaseDate: readjustmentBaseDate ? formatDateStr(readjustmentBaseDate) : null,
    groupId: group?.id ?? '',
    energyContractsIds: energyContracts.length ? energyContracts.map(({ id }) => id) : [],
    unitsIds: units.length ? units.map(({ id }) => id) : [],
    readjustmentFrequency: readjustmentFrequency ?? null,
    readjustmentIndex: readjustmentIndex ?? null,
    clarkeContractAttachments: attachments.length
      ? attachments.map(({ filename, fileSize, url, id: attachmentId }) => ({
          id: attachmentId,
          filename,
          fileSize,
          url,
        }))
      : [],
    clarkeMagmentAmount,
  };
};

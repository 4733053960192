import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptbr from 'dayjs/locale/pt-br';
import { DiffParam } from './types';

dayjs.extend(customParseFormat);

/**
 * PT-BR configured dayjs module instance.
 * */
export const $dayjs = dayjs;

export const parseStringToDate = (str: string): Date => dayjs(str).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const englishFormatDateFromString = (
  dateStr: string,
  inputFormat: string,
  outputFormat = 'DD/MM/YYYY, HH:mm',
): string => dayjs(dateStr, inputFormat).format(outputFormat);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat, 'pt-br').format(outputFormat);

export const getDiffFromDateStr = (firstDate: string, secondDate: string, diffParam: DiffParam): number => {
  const date1 = dayjs(firstDate);
  const date2 = dayjs(secondDate);

  return date1.diff(date2, diffParam);
};

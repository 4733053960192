export const displayGroupCustomerInfo = ({
  name,
  unitsAmount,
  edcs,
  traders,
  contractedDemandAmount,
  contractType,
}: Record<string, string | number>) => {
  return [
    { title: 'Empresa', value: name },
    { title: 'Unidades no Livre', value: `${unitsAmount}` },
    { title: 'Distribuidora(s)', value: edcs },
    {
      title: 'Demanda Contratada Fora Ponta Total',
      value: contractedDemandAmount,
    },
    {
      title: 'Fornecedora de energia',
      value: traders,
    },
    {
      title: 'Tipo(s) de Contrato',
      value: contractType,
    },
  ];
};

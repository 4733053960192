import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface RecipientsTabProps {
  isLoadingEmails: boolean;
  tradersRecipients: string[];
  customerRecipients: string[];
}

const RecipientsTab: React.FC<RecipientsTabProps> = ({ tradersRecipients, customerRecipients, isLoadingEmails }) => {
  if (isLoadingEmails) {
    return <Skeleton height={500} />;
  }

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-heading-xsmall">Cliente</h2>
        <p className="text-paragraph-medium">{customerRecipients.join(', ')}</p>
      </div>
      <div className="mb-6">
        <h2 className="text-heading-xsmall">Fornecedora</h2>
        <p className="text-paragraph-medium">{tradersRecipients.join(', ')}</p>
      </div>
      <div>
        <p className="text-paragraph-medium">
          Para alterar ou editar os contatos que receberão o email do balanço energético, é preciso acessar os usuarios
          do grupo/fornecedor dentro da plataforma Admin.
        </p>
      </div>
    </div>
  );
};

export default RecipientsTab;

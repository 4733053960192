import React from 'react';
import { useParams } from 'react-router-dom';

import { ColumnOptions, FeedbackNotification, Layout, Modal, Tag, ThreeDotsMenu } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { useAuth } from '@src/ApolloWrapper';
import { HOME_PATH, TRADERS_PATH } from '@routers/constants';
import {
  BindUserNotificationFeedbackContents,
  CreateRoleCategoryUserNotificationFeedbackContents,
  INotificationFeedbackContent,
} from '@utils/constants/common';
import { TraderRolesHandleParser } from '@utils/translators/users-roles';
import { UserInfo } from '@contexts/user/types';
import useTraderUsers from '@hooks/use-user-trader';
import { useUsers } from '@hooks/users';

import FormUpsertTraderUser from '@components/molecules/form/form-upsert-user-trader';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import { TraderUsersTable } from '@components/molecules/trader/user-traders-table';
import AvatarWithText from '@components/atoms/avatarWithText';
import FormCreateCategoryForTraderUser from '@components/molecules/form/form-create-category-for-trader-users';

type renderFunction = ColumnOptions<UserInfo>['renderAsElement'];

const renderUserName: renderFunction = (entry) => <AvatarWithText entry={entry} />;
const renderUserEmail: renderFunction = (entry) => entry?.email;
const renderCategoryTag: renderFunction = (entry) => (
  <div className="flex items-center flex-column">
    {entry
      ? entry?.categoryRoles?.map((role: string, index: number) => {
          const parsedRole = TraderRolesHandleParser(role);
          const color = parsedRole.label === 'Vendas' ? 'blue' : 'red';
          return <Tag key={index} kind="default" color={color} label={parsedRole.label} />;
        })
      : '-'}
  </div>
);

const DetailedTraderPage: React.FC = () => {
  const params = useParams();

  const [openConfirmationDeletionModal, setOpenConfirmationDeletionModal] = React.useState(false);
  const [modalCreateTraderUserOpen, setModalCreateTraderUserOpen] = React.useState<boolean>(false);
  const [openConfirmationCategoryModal, setOpenConfirmationCategoryModal] = React.useState(false);
  const [selectedTraderUser, setSelectedTraderUser] = React.useState<UserInfo | null>(null);
  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();
  const { getUsersHandler } = useUsers();
  const { getTraderHandler, trader, traderUsers, loadingGetTrader, setTraderId, deleteTraderUserHandler } =
    useTraderUsers();
  const {
    authStatus: { accessToken },
  } = useAuth();

  const userTraderUsersTableColumns: ColumnOptions<UserInfo>[] = [
    { accessor: 'name', header: 'Nome', renderAsElement: renderUserName },
    { accessor: 'email', header: 'Email', renderAsElement: renderUserEmail },
    { accessor: 'categoryRoles', header: 'Categorias', renderAsElement: renderCategoryTag },
    {
      renderAsElement: (entry) => {
        return (
          <ThreeDotsMenu
            extraClasses={'md:mr-8 lg:mr-10'}
            linkElement={[
              { icon: 'Bars4Icon', url: `/usuarios/${entry.id}/emails`, label: 'Histórico de notificações' },
            ]}
            menuOptions={[
              {
                icon: 'TagIcon',
                onClick: () => {
                  setSelectedTraderUser(entry);
                  setOpenConfirmationCategoryModal(true);
                  setOpenNotificationFeedback(false);
                },
                label: 'Adicionar categoria',
              },
              {
                icon: 'TrashIcon',
                onClick: () => {
                  setSelectedTraderUser(entry);
                  setOpenConfirmationDeletionModal(true);
                  setOpenNotificationFeedback(false);
                },
                label: 'Desvincular usuário',
              },
            ]}
          />
        );
      },
    },
  ];

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  React.useEffect(() => {
    if (params.traderId && accessToken) {
      setTraderId(params.traderId);
    }
  }, [params.traderId, accessToken]);

  React.useEffect(() => {
    trackEvent(eventLabels.DETAILED_TRADER_PAGE_VIEWED);
  }, []);

  return (
    <Layout
      title={trader?.name}
      loading={loadingGetTrader}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Fornecedores',
          url: TRADERS_PATH,
        },
        {
          label: trader?.name as string,
          url: '#',
        },
      ]}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Vincular Usuário',
          onClick: () => {
            setSelectedTraderUser(null);
            setModalCreateTraderUserOpen(true);
            getUsersHandler();
          },
          size: 'small',
        },
      ]}
      searchBar={{
        onTypedTermChangedCallback: (term) => setTypedSearchTerm(term),
        placeholder: 'Filtrar por nome do usuário',
      }}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full mb-8 h-full">
        <TraderUsersTable
          data={
            typedSearchTerm.length > 0
              ? traderUsers.filter((traderUser) =>
                  traderUser?.name.toLowerCase().includes(typedSearchTerm.toLowerCase()),
                )
              : traderUsers
          }
          isFetchingItems={loadingGetTrader}
          columnDefinitions={userTraderUsersTableColumns}
        />
      </div>
      <Modal
        open={modalCreateTraderUserOpen}
        setOpen={() => setModalCreateTraderUserOpen(false)}
        title="Adicionar usuários à fornecedora"
        description="Formulário para a criação de relação entre usuários e fornecedora."
      >
        <FormUpsertTraderUser
          onFormSuccess={() => {
            getTraderHandler();
            setModalCreateTraderUserOpen(false);
            setNotificationResponse(BindUserNotificationFeedbackContents.BIDING_USER_SUCCESS);
            trackEvent(eventLabels.CREATE_TRADER_USER_BUTTON_CLICKED);
          }}
          onFormFail={() => setNotificationResponse(BindUserNotificationFeedbackContents.BIDING_USER_ERROR)}
          onCancel={() => setModalCreateTraderUserOpen(false)}
        />
      </Modal>
      <Modal
        open={openConfirmationDeletionModal}
        setOpen={() => setOpenConfirmationDeletionModal(false)}
        title="Deletar usuário"
        description="Tem certeza que deseja excluir esse usuário dessa fornecedora?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedTraderUser &&
              deleteTraderUserHandler(selectedTraderUser.id)
                .then(() => {
                  getTraderHandler();
                  setOpenConfirmationDeletionModal(false);
                  setNotificationResponse(BindUserNotificationFeedbackContents.DELETING_USER_SUCCESS);
                  trackEvent(eventLabels.DELETE_TRADER_USER_BUTTON_CLICKED);
                })
                .catch(() => {
                  setOpenConfirmationDeletionModal(false);
                  setNotificationResponse(BindUserNotificationFeedbackContents.DELETING_USER_ERROR);
                });
          }}
          onCancel={() => setOpenConfirmationDeletionModal(false)}
        />
      </Modal>
      <Modal
        open={openConfirmationCategoryModal}
        setOpen={() => setOpenConfirmationCategoryModal(!openConfirmationCategoryModal)}
        title="Adicionar categorias"
        description="Selecione a categoria que o usuário pertence para receber os e-mails corretos"
        className="relative"
      >
        <FormCreateCategoryForTraderUser
          user={selectedTraderUser}
          onFormSuccess={() => {
            getTraderHandler();
            setOpenConfirmationCategoryModal(false);
            setNotificationResponse(CreateRoleCategoryUserNotificationFeedbackContents.ROLE_USER_SUCCESS);
          }}
          onFormFail={() => setNotificationResponse(CreateRoleCategoryUserNotificationFeedbackContents.ROLE_USER_ERROR)}
          onCancel={() => setOpenConfirmationCategoryModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default DetailedTraderPage;

import numeral from 'numeral';
import { ColumnOptions, TagProps } from '@clarke-energia/foton';
import {
  ManualSyncEnum,
  MigrationReport,
  MigrationReportNotificationType,
  MigrationUnit,
} from '@contexts/migration-report/types';
import { INotificationFeedbackContent } from '@utils/constants/common';

export const mainTableColumns: Array<ColumnOptions<MigrationReport>> = [
  {
    accessor: 'commercialGroup',
    header: 'Grupo Comercial',
    fixedWidthInRem: 16,
    renderAsElement: (entry) => entry.commercialGroup,
  },
  {
    accessor: 'conclusionPercentage',
    header: 'Progresso',
    renderAsElement: (entry) => numeral(entry.conclusionPercentage).format('%'),
  },
  {
    accessor: 'emails',
    header: 'emails',
    renderAsElement: (entry) => (entry.emails.length ? entry.emails.join(',') : '-'),
    ellipsisStyle: { position: 'left-[12rem] md:left-[12rem] top-[-1rem]' },
    fixedWidthInRem: 15,
  },
];

export const expandableTableColumns: Array<ColumnOptions<MigrationUnit>> = [
  {
    accessor: null,
    header: undefined,
    fixedWidthInRem: 4,
  },
  {
    accessor: 'nickname',
    header: '',
    fixedWidthInRem: 16,
    renderAsElement: (entry) => entry.nickname,
  },
  {
    accessor: 'conclusionPercentage',
    header: '',
    renderAsElement: (entry) => numeral(entry.conclusionPercentage).format('%'),
  },
];

export const migrationReportFeedbackContent: Record<MigrationReportNotificationType, INotificationFeedbackContent> = {
  CREATING_TASK: {
    kind: 'neutral',
    message: 'Criando nova task...',
    label: 'Aguarde',
  },
  CREATE_TASK_SUCCESS: {
    kind: 'primary',
    message: 'Task criada com sucesso!',
    label: 'Sucesso',
  },
  SYNCING: {
    kind: 'neutral',
    message: 'Sincronizando...',
    label: 'Aguarde',
  },
  SYNC_SUCCESS: {
    kind: 'primary',
    message: 'Sincronização inicializadas com sucesso!',
    label: 'Sucesso',
  },
  ERROR_TO_SYNC: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao realizar a sincronização com o Asana.',
  },
  SENDING_EMAIL: {
    kind: 'neutral',
    message: 'Enviando emails...',
    label: 'Aguarde',
  },
  EMAIL_SUCCESS: {
    kind: 'primary',
    message: 'Emails enviados com sucesso!',
    label: 'Sucesso',
  },
  ERROR_TO_SEND_EMAIL: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao enviar email.',
  },
  NOTIFYING_CUSTOMER_AREA: {
    kind: 'neutral',
    message: 'Notificando area do cliente...',
    label: 'Aguarde',
  },
  NOTIFY_CUSTOMER_AREASUCCESS: {
    kind: 'primary',
    message: 'Area do cliente notificada com sucesso!',
    label: 'Sucesso',
  },
  ERROR_TO_NOTIFY_CUSTOMER_AREA: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao notificar área do cliente.',
  },
  ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao realizar a ação solicitada.',
  },
};

export const SyncManualTagStatusPropsSelector: Record<keyof typeof ManualSyncEnum, TagProps> = {
  NONE: {
    color: 'gray',
    label: 'Não inicializado',
    kind: 'default',
  },
  PROCESSING: {
    color: 'gray',
    label: 'Sincronizando...',
    kind: 'icon',
    icon: 'ArrowPathIcon',
  },
  PROCESSED: {
    color: 'green',
    label: 'Sincronizado',
    kind: 'icon',
    icon: 'CheckIcon',
  },
  ERROR: {
    color: 'red',
    label: 'Erro',
    kind: 'icon',
    icon: 'XMarkIcon',
  },
};

import React from 'react';
import * as OutlineIcons from '@heroicons/react/24/outline';

export type IconName = keyof typeof OutlineIcons;
interface IconProps {
  icon: IconName;
  extraClassNames: string;
  onClick?: React.DOMAttributes<SVGSVGElement>['onClick'];
}

const HeroIcon: React.FC<IconProps> = ({ icon, extraClassNames, onClick }) => {
  const Icon = OutlineIcons[icon];
  return <Icon onClick={onClick} className={extraClassNames} />;
};

export default HeroIcon;

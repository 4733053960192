import { CustomerStatus, INotification } from './types';

export const NotificationMessagesByCustomerStatus: Record<CustomerStatus, INotification> = {
  MIGRATION_IN_PROGRESS: {
    title: 'Atualização no progresso na migração para o Mercado Livre.',
    description:
      'Confira as atualizações no andamento da migração para o mercado livre da(s) sua(s) unidade consumidora. ',
    topic: 'MIGRATION',
    type: 'PASSIVE',
  },
  MIGRATION_NOT_STARTED: {
    title: 'Sua jornada de migração para o Mercado Livre está começando!',
    description: 'Foi iniciada a sua migração! Confira todas as etapas e fique por dentro de todo processo.',
    topic: 'MIGRATION',
    type: 'ACTIVE',
  },
  MIGRATION_CONCLUDED: {
    title: 'Migração Concluída',
    description: 'Parabéns! Sua migração foi concluída com sucesso. Está chegando a hora de economizar!',
    topic: 'MIGRATION',
    type: 'PASSIVE',
  },
  FIRST_ECONOMY_REPORT: {
    title: 'Chegou o seu primeiro Relatório de Econmia!',
    description:
      'Chegou a hora de economizar! Confira o desempenho na economia e nas contas de luz da(s) sua(s) unidade consumidora.',
    topic: 'ECONOMY',
    type: 'PASSIVE',
  },
  MONTHLY_ECONOMY_REPORT: {
    title: 'Novo relatório de economia disponível.',
    description: 'Confira o desempenho na economia e nas contas de luz da(s) sua(s) unidade consumidora.',
    topic: 'ECONOMY',
    type: 'PASSIVE',
  },
};

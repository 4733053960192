import React, { useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { GET_ECONOMY_REPORT_BY_ID_QUERY } from './queries';
import { UnitReportParsed } from './types';
import { unitReportDataParsed } from './parser';

export type UnitEconomyReportContextType = {
  loading: boolean;
  unitReport: UnitReportParsed;
  getUnitEconomyReportHandler(id: string): Promise<QueryResult<any, OperationVariables>>;
};

export const UnitEconomyReportContext = createContext({} as UnitEconomyReportContextType);

interface Provider {
  children: React.ReactNode;
}

const UnitEconomyReportProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [unitReport, setUnitReport] = useState<UnitReportParsed>({} as UnitReportParsed);

  const [getUnitEconomyReportById, { loading }] = useLazyQuery(GET_ECONOMY_REPORT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      const reportParsed = unitReportDataParsed(data.economyReport);
      setUnitReport(reportParsed);
    },
    onError: () => {
      setUnitReport({} as UnitReportParsed);
    },
  });

  function getUnitEconomyReportHandler(id: string) {
    return getUnitEconomyReportById({
      variables: {
        economyReportId: id,
      },
    });
  }

  return (
    <UnitEconomyReportContext.Provider
      value={{
        loading,
        unitReport,
        getUnitEconomyReportHandler,
      }}
    >
      {children}
    </UnitEconomyReportContext.Provider>
  );
};

export default UnitEconomyReportProvider;

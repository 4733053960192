import React from 'react';

import { CreateCceeContractPayload } from '@contexts/ccee-contract/types';
import { NumericFormField } from '@components/atoms/form';

const VolumeFields = (): JSX.Element => {
  return (
    <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
      <NumericFormField<CreateCceeContractPayload>
        label="Volume transacionado"
        id="tradedVolume"
        field="tradedVolume"
        formatProps={{
          placeholder: 'MWh',
          suffix: ' MWh',
          allowNegative: false,
          fixedDecimalScale: true,
          decimalScale: 3,
        }}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
      <NumericFormField<CreateCceeContractPayload>
        label="Preço da energia"
        id="energyPrice"
        field="energyPrice"
        formatProps={{
          placeholder: 'R$/MWh',
          prefix: 'R$',
          suffix: ' MWh',
          allowNegative: false,
          fixedDecimalScale: true,
          decimalScale: 2,
        }}
        options={{ required: { value: true, message: 'Este campo é obrigatório' } }}
      />
    </div>
  );
};

export default VolumeFields;

import React from 'react';
import Select from 'react-select';

interface Options {
  label: string;
  value: any;
}

export interface PopUpFilter extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  onChange: (value: any) => void;
  options: Options[];
  show?: boolean;
  isMultiSelect?: boolean;
}

const PopUpFilter = ({ onChange, options, show, value, isMultiSelect, ...attrs }: PopUpFilter) => {
  const { className } = attrs;

  return (
    <div
      className={`flex flex-col gap-2 absolute shadow-3 p-5 h-fit bg-white z-10 border-black border-[1px] rounded-medium w-[8rem] md:w-[15rem] ${className} ${
        !show && 'hidden'
      }`}
    >
      <Select
        value={value}
        maxMenuHeight={300}
        isDisabled={false}
        placeholder={'Selecione...'}
        options={options as []}
        onChange={onChange}
        isMulti={isMultiSelect}
        styles={{
          control: (base) => ({
            ...base,
            '&:hover': { borderColor: 'black' },
            border: '1px solid black',
            boxShadow: 'none',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#00DF7C' : 'none',
            '&:hover': { backgroundColor: state.isSelected ? 'none' : '#F1FFEB' },
          }),
        }}
      />
    </div>
  );
};

export default PopUpFilter;

import React from 'react';

export interface FieldHeaderProps {
  label: { text: string; className?: string };
  sublabel?: { text: string; className?: string };
  required?: boolean;
}
const FieldHeader: React.FC<FieldHeaderProps> = ({ label, sublabel, required }) => {
  const labelClass = label.className ? label.className : 'mb-2 text-paragraph-medium font-normal text-black';
  const sublabelClass = sublabel?.className
    ? sublabel.className
    : 'text-paragraph-medium font-semibold text-neutral-50';

  return (
    <div className="flex flex-col order-first">
      <p className={labelClass}>
        {label.text}
        {required && <span className="text-danger-60">*</span>}
      </p>

      {sublabel && <p className={sublabelClass}>{sublabel.text}</p>}
    </div>
  );
};

export default FieldHeader;

import dayjs from 'dayjs';
import { englishFormatDateFromString } from '@utils/dayjs';
import numeral from 'numeral';
import { formatDateStr } from '@utils/text';
import {
  Bid,
  CreateEnergyContractPayload,
  Proposal,
  EnergyContract,
  Supply,
  CommissionModelType,
  EnergyContractsGraphqlResponse,
  EnergyContractTable,
  EMPTY_CONTRACT,
  PayDayType,
} from './types';

type SupplyDates = {
  newestStartDate: string | null;
  oldestEndDate: string | null;
};

const getOldestAndNewestSupplyDate = (supply: Supply[]): SupplyDates => {
  if (supply.length === 0) {
    return { newestStartDate: null, oldestEndDate: null };
  }

  return supply.reduce(
    (result: SupplyDates, current: Supply) => {
      if (result.newestStartDate === null || current.startDate > result.newestStartDate) {
        result.newestStartDate = current.startDate;
      }
      if (result.oldestEndDate === null || current.endDate < result.oldestEndDate) {
        result.oldestEndDate = current.endDate;
      }
      return result;
    },
    {
      newestStartDate: null,
      oldestEndDate: null,
    },
  );
};

export const finalProposals = (proposals: Proposal[]): Proposal[] => {
  if (!proposals.length) return [];

  const filteredProposals = proposals.filter(
    ({ bids, round }) => bids && round !== 1 && bids.some(({ status }) => status === 7),
  );
  const mappedProposals = filteredProposals.map(({ bids, ...rest }) => {
    const bidWithWinnerStatus = bids.find(({ status }) => status === 7);
    if (!bidWithWinnerStatus) return null;
    return {
      ...rest,
      bids: [bidWithWinnerStatus],
    };
  });
  const sortedProposals = mappedProposals
    .filter((proposal) => proposal)
    .sort((a, b) => {
      const createdAtA = a?.createdAt ? new Date(a.createdAt).getTime() : 0;
      const createdAtB = b?.createdAt ? new Date(b.createdAt).getTime() : 0;
      return createdAtB - createdAtA;
    });

  return sortedProposals as Proposal[];
};

export const proposalsFormatedToEnergyContractForm = (proposals: Proposal[]): CreateEnergyContractPayload[] => {
  if (!proposals.length) return [];

  const proposalsParsed = proposals.map(
    ({
      id,
      bids,
      contractType,
      guaranteeMonths,
      guaranteeType,
      lowerFlexibility,
      upperFlexibility,
      otherGuarantee: otherGuaranteeType,
      group,
      supply,
    }) => {
      const supplyDate = getOldestAndNewestSupplyDate(supply);
      const { coverCceeTaxes, coverMeterSystem, economy, meterSystemAmount, trader, payDay, retailService } =
        bids[0] as Bid;

      const economyValue = economy.length
        ? economy[0].economyPerYear.map(({ year, amount }) => ({ year, amount }))
        : [{ year: NaN, amount: NaN }];

      return {
        idCceeTrader: NaN,
        lowerFlexibility,
        upperFlexibility,
        perfilCceeTrader: '',
        cceeProfiles: [],
        groupId: group.id,
        unitsId: supply.length ? supply.map(({ unit }) => unit.id) : [],
        contractType,
        signatureDate: null,
        startSupplyDate: supplyDate?.newestStartDate
          ? englishFormatDateFromString(supplyDate?.newestStartDate, 'YYYY-MM-DD', 'DDMMYYYY')
          : '',
        endSupplyDate: supplyDate?.oldestEndDate
          ? englishFormatDateFromString(supplyDate?.oldestEndDate, 'YYYY-MM-DD', 'DDMMYYYY')
          : '',
        energyType: '',
        coverCceeTaxes: coverCceeTaxes ? 'true' : 'false',
        coverMeterSystem: coverMeterSystem ? 'true' : 'false',
        coverCceeAmount: NaN,
        meterSystemAmount,
        payDayType: PayDayType.WORKDAY,
        payDay,
        guaranteeType,
        otherGuaranteeType,
        guaranteeMonths,
        clarkeMagmentAmount: NaN,
        readjustmentIndex: null,
        readjustmentBaseDate: null,
        readjustmentFrequency: null,
        cceeAgent: null,
        proposalId: id,
        economy: economyValue,
        retailTraderId: trader.id,
        traderType: retailService ? 'RETAIL_TRADER' : 'WHOLESALE_TRADER',
        losts: null,
        advanceCommission: false,
        includeCceeCharges: false,
      };
    },
  );

  return proposalsParsed;
};

export const parseContractToUpdate = (contract: EnergyContract) => {
  const {
    units,
    profilesCcee,
    trader,
    startSupplyDate,
    endSupplyDate,
    proposalId,
    coverCceeTaxes,
    coverMeterSystem,
    economy,
    guaranteeType,
    cceeAgent,
    isFixedContractedVolume,
    upperFlexibility,
    lowerFlexibility,
    contractType,
    coverCceeAmount,
    energyType,
    guaranteeMonths,
    id,
    idCceeTrader,
    meterSystemAmount,
    otherGuaranteeType,
    payDayType,
    payDay,
    perfilCceeTrader,
    contractedVolumePerYear,
    fixedContractedVolume,
    group,
    commissionModel,
    energyContractCommissions,
    advanceCommission,
    energyContractAttachments,
    losts,
    readjustmentBaseDate,
    readjustmentFrequency,
    readjustmentIndex,
    includeCceeCharges,
  } = contract;

  const checkFixedContractVolume = isFixedContractedVolume ? 'true' : 'false';
  const proposalValue = upperFlexibility && !proposalId;
  const contractedVolumePerYearValue = contractedVolumePerYear
    ? contractedVolumePerYear?.map(({ year, amount }) => ({ year, amount }))
    : null;
  const energyContractCommissionsValue = energyContractCommissions?.length
    ? energyContractCommissions
        .map(({ id, year, commissionAmount, commissionType }) => ({
          id,
          year,
          commissionAmount,
          commissionType,
        }))
        .sort((commissionA, commissionB) => commissionA.year - commissionB.year)
    : EMPTY_CONTRACT['energyContractCommissions'];

  const energyContractAttachmentsValue = energyContractAttachments?.length
    ? energyContractAttachments?.map(({ id, filename, fileSize, ...restAttributes }) => ({
        id,
        filename,
        fileSize,
        ...restAttributes,
      }))
    : [];

  const fixedCommissionValue =
    energyContractCommissions?.length && commissionModel === CommissionModelType.FIXED
      ? energyContractCommissions[0].commissionAmount
      : null;

  return {
    includeCceeCharges,
    upperFlexibility,
    lowerFlexibility,
    contractType,
    coverCceeAmount,
    energyType,
    guaranteeMonths,
    id,
    idCceeTrader,
    meterSystemAmount,
    otherGuaranteeType,
    payDay,
    payDayType,
    perfilCceeTrader,
    fixedContractedVolume,
    contractedVolumePerYear: contractedVolumePerYearValue,
    unitsId: units.map(({ id }) => id),
    groupId: group.id ?? '',
    traderType: trader?.type,
    retailTraderId: trader?.id,
    cceeProfiles: profilesCcee.map(({ id }) => id),
    startSupplyDate: startSupplyDate ? formatDateStr(startSupplyDate) : '',
    endSupplyDate: endSupplyDate ? formatDateStr(endSupplyDate) : '',
    coverCceeTaxes: coverCceeTaxes ? 'true' : 'false',
    coverMeterSystem: coverMeterSystem ? 'true' : 'false',
    isFixedContractedVolume: isFixedContractedVolume !== null ? checkFixedContractVolume : null,
    proposalId: proposalValue ? 'NONE' : proposalId,
    guaranteeType: guaranteeType || '',
    economy: economy?.map(({ year, amount }) => ({ year, amount })),
    cceeAgent: cceeAgent ?? '',
    commissionModel: commissionModel ?? undefined,
    energyContractCommissions: energyContractCommissionsValue,
    energyContractAttachments: energyContractAttachmentsValue,
    fixedCommission: fixedCommissionValue,
    advanceCommission: advanceCommission !== null ? advanceCommission : false,
    readjustmentBaseDate: readjustmentBaseDate ? formatDateStr(readjustmentBaseDate) : null,
    readjustmentFrequency: readjustmentFrequency ?? null,
    readjustmentIndex: readjustmentIndex ?? null,
    losts,
  };
};

export const parseContractToCreate = (payload: CreateEnergyContractPayload): CreateEnergyContractPayload => {
  const {
    proposalId,
    endSupplyDate,
    startSupplyDate,
    coverCceeAmount,
    meterSystemAmount,
    fixedContractedVolume,
    contractedVolumePerYear,
    energyContractCommissions,
    readjustmentBaseDate,
    id,
    ...energyContractData
  } = payload;

  const coverCceeTaxes = Boolean(payload.coverCceeTaxes === 'true');
  const coverMeterSystem = Boolean(payload.coverMeterSystem === 'true');
  const isFixedContractedVolume = Boolean(payload.isFixedContractedVolume === 'true');

  delete energyContractData.fixedCommission;

  return {
    ...energyContractData,
    coverCceeTaxes,
    coverMeterSystem,
    isFixedContractedVolume,
    includeCceeCharges:
      id && energyContractData.traderType === 'WHOLESALE_TRADER' ? false : energyContractData.includeCceeCharges,
    proposalId: proposalId === 'NONE' ? null : proposalId,
    endSupplyDate: endSupplyDate ? englishFormatDateFromString(endSupplyDate, 'DDMMYYYY', 'YYYY-MM-DD') : '',
    startSupplyDate: startSupplyDate ? englishFormatDateFromString(startSupplyDate, 'DDMMYYYY', 'YYYY-MM-DD') : '',
    readjustmentBaseDate: readjustmentBaseDate
      ? englishFormatDateFromString(readjustmentBaseDate, 'DDMMYYYY', 'YYYY-MM-DD')
      : null,
    meterSystemAmount: !coverMeterSystem ? meterSystemAmount : null,
    coverCceeAmount: coverCceeTaxes ? coverCceeAmount : null,
    fixedContractedVolume: isFixedContractedVolume ? fixedContractedVolume : null,
    contractedVolumePerYear: !isFixedContractedVolume ? contractedVolumePerYear : null,
    energyContractCommissions,
  };
};

export const createYearArray = (initialDate: string, finalDate: string) => {
  const initialYear = dayjs(initialDate).year();
  const finalYear = dayjs(finalDate).year();
  const years: number[] = [];
  for (let year = initialYear; year <= finalYear; year++) {
    years.push(year);
  }
  return years;
};

export const parserEnergyContractsTableData = (
  energyContracts: EnergyContractsGraphqlResponse['data'],
): EnergyContractTable[] => {
  return energyContracts.map(
    ({
      gmvAmount,
      takeRateAmount,
      carbonDioxideTonAmount,
      units,
      contractedVolumePerYear,
      fixedContractedVolume,
      isFixedContractedVolume,
      id,
    }) => ({
      id,
      groupName: units.length ? units[0].group.name : '-',
      contractedVolume: getContractVolumeTable(contractedVolumePerYear, isFixedContractedVolume, fixedContractedVolume),
      gmvAmount: gmvAmount ? numeral(gmvAmount).format('$ 0,0.00') : '-',
      takeRateAmount: takeRateAmount ? numeral(takeRateAmount).format('$ 0,0.00') : '-',
      carbon: carbonDioxideTonAmount ? numeral(carbonDioxideTonAmount).format('0,0.00') : '-',
    }),
  );
};

const getContractVolumeTable = (
  contractedVolumePerYear: Record<'amount', number>[] | undefined,
  isFixedContractedVolume: boolean | undefined,
  fixedContractedVolume: number | undefined,
) => {
  if (isFixedContractedVolume === null) return '-';

  if (isFixedContractedVolume) return numeral(fixedContractedVolume).format('0.000').concat(' MWm');

  const avarageVolumePerYear =
    contractedVolumePerYear &&
    contractedVolumePerYear.reduce((sum, item) => sum + item.amount, 0) / contractedVolumePerYear.length;

  return numeral(avarageVolumePerYear).format('0.000').concat(' MWm');
};

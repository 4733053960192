import { FinancialEventType } from '@contexts/financial-events';
import { financialEventsBatchCreationSteps } from '@pages/financial-events/static-data';

import StepTab from '../../../atoms/step-tab';

interface IFinancialEventsBatchCreationFormStepsTabs {
  financialEventsType?: keyof typeof FinancialEventType;
  financialEventStep: number;
}

const FinancialEventsBatchCreationFormStepsTabs = ({
  financialEventsType,
  financialEventStep,
}: IFinancialEventsBatchCreationFormStepsTabs) => {
  return (
    <div className="flex w-full">
      {financialEventsBatchCreationSteps.map((stepTab, index) => {
        if (stepTab.id === 'attachments' && financialEventsType !== 'ASSOCIATIVE_CONTRIBUTION') return;

        const stepIndex = index + 1;
        return (
          <StepTab
            key={`financial-events-batch-creation-${stepIndex}`}
            step={stepIndex}
            label={stepTab.label}
            isActive={financialEventStep === stepIndex}
            isConcluded={financialEventStep > stepIndex}
          />
        );
      })}
    </div>
  );
};

export default FinancialEventsBatchCreationFormStepsTabs;

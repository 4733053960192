import React from 'react';

import {
  FinancialEvent,
  FinancialEventAttachmentOption,
  FinancialEventsBatch,
  FinancialEventsBatchGraphQLData,
} from '@contexts/financial-events';
import { FinancialEventsBatchManager } from '@contexts/financial-events/manager';
import useFinancialEvents from '@hooks/use-financial-events';

import { FormSection } from '@components/atoms/form';
import FinancialEventsBatchCreationFormActionsSection from '@components/molecules/financial-events/batch-creation/form-actions-section';
import FinancialEventAttachmentRelationRow from '@components/atoms/financial-events/batch-creation/attachment-relation-row';

interface IFinancialEventsBatchAttachmentsUpdating {
  setFinancialEventStep: React.Dispatch<React.SetStateAction<number>>;
}

function FinancialEventsBatchAttachmentsUpdating({ setFinancialEventStep }: IFinancialEventsBatchAttachmentsUpdating) {
  const {
    financialEventsBatch,
    getFinancialEventsBatchHandler,
    updateFinancialEventAttachmentHandler,
    setOpenNotificationFeedBack,
  } = useFinancialEvents();

  const [attachmentOptions, setAttachmentOptions] = React.useState<Array<FinancialEventAttachmentOption>>();
  const [batchFinancialEvents, setBatchFinancialEvents] = React.useState<Array<FinancialEvent>>();

  const onChangeAttachment = async (financialEventId: string, attachmentId?: string) => {
    try {
      if (financialEventId) {
        await updateFinancialEventAttachmentHandler(financialEventId, attachmentId);
        const getFinancialEventsBatchResponse = await getFinancialEventsBatchHandler();

        const manager = new FinancialEventsBatchManager(
          getFinancialEventsBatchResponse.data as FinancialEventsBatchGraphQLData,
        );

        manager.financialEventsBatch && filterAttachmentOptions(manager.financialEventsBatch);
      }
    } catch (error) {
      setOpenNotificationFeedBack(true);
      throw Error(`Financial event attachment could not be updated: ${error}`);
    }
  };

  const filterAttachmentOptions = (financialEventsBatch: FinancialEventsBatch) => {
    const batchAttachments = financialEventsBatch.attachments;
    const financialEventsAttachments = financialEventsBatch.financialEvents?.map(
      (financialEvent) => financialEvent.attachment,
    );
    const attachmentOptions = batchAttachments?.map((batchAttachment): FinancialEventAttachmentOption => {
      const isAttachmentRelationed =
        financialEventsAttachments?.find(
          (financialEventAttachment) => financialEventAttachment?.id === batchAttachment.id,
        ) !== undefined;

      return {
        ...batchAttachment,
        isRelationed: isAttachmentRelationed,
      };
    });

    setBatchFinancialEvents(financialEventsBatch.financialEvents);
    setAttachmentOptions(attachmentOptions);
  };

  React.useEffect(() => {
    financialEventsBatch && filterAttachmentOptions(financialEventsBatch);
  }, [financialEventsBatch]);

  React.useEffect(() => {
    getFinancialEventsBatchHandler();
  }, []);

  return (
    <div>
      <div className="flex gap-6 mb-10 w-full">
        <FormSection
          title="2. Relacionar Anexos"
          subtitle="Para cada grupo comercial, selecione o anexo correspondente enviado no passo anterior."
          className="w-1/4"
        />
        <div className="flex flex-col gap-6 w-3/4">
          {batchFinancialEvents?.map((financialEvent, index) => (
            <FinancialEventAttachmentRelationRow
              key={`financial-event-attachment-update-${index}`}
              financialEvent={financialEvent}
              attachments={attachmentOptions}
              onChangeAttachment={onChangeAttachment}
            />
          ))}
        </div>
      </div>
      <FinancialEventsBatchCreationFormActionsSection
        onNavigatePrevious={() => setFinancialEventStep(1)}
        onNavigateNext={() => setFinancialEventStep((previous) => previous + 1)}
      />
    </div>
  );
}

export default FinancialEventsBatchAttachmentsUpdating;

import React, { useState } from 'react';
import groupBy from 'lodash.groupby';

import { ReportsDataTable } from '@contexts/economy-reports/types';
import { Checkbox, HeroIcon } from '@clarke-energia/foton';

interface ReportsListProps {
  reports: ReportsDataTable[];
  reportsToNotify: ReportsDataTable[];
  setReportsToNotify: React.Dispatch<React.SetStateAction<ReportsDataTable[]>>;
}

const ReportsList: React.FC<ReportsListProps> = ({ reports, setReportsToNotify, reportsToNotify }) => {
  const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
  const reportsGroupedByDate = groupBy(reports, 'date');
  const reportDates = Object.keys(reportsGroupedByDate);
  return (
    <div className="py-4 mb-6 border text-paragraph-medium border-t-black border-b-black border-r-white border-l-white">
      <h2 className="font-bold">Escolha relatórios que serão publicados:</h2>
      <div className="flex flex-col font-normal">
        {reportDates.map((keyName, idx) => {
          return (
            <div key={`report-${keyName}${idx}`} className="mt-2">
              <Checkbox
                label={keyName}
                checked={selectedDates.includes(keyName)}
                onChange={() => {
                  if (selectedDates.includes(keyName)) {
                    setSelectedDates(selectedDates.filter((item) => item !== keyName));
                    setReportsToNotify(reportsToNotify.filter((item) => !reportsGroupedByDate[keyName].includes(item)));
                  } else {
                    setSelectedDates([...selectedDates, keyName]);
                    setReportsToNotify([...reportsToNotify, ...reportsGroupedByDate[keyName]]);
                  }
                }}
              />
              {reportsGroupedByDate[keyName].map((report) => (
                <div key={report.id} className="flex ml-6">
                  {selectedDates.includes(keyName) ? (
                    <span>{<HeroIcon icon="CheckIcon" className="h-5 text-primary-80" />}</span>
                  ) : null}
                  {`${report.unitName}`}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportsList;

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnOptions, Modal } from '@clarke-energia/foton';

import { useClarkeContract } from '@hooks/use-clarke-contract';
import { useNotification } from '@hooks/use-notification';
import { ClarkeContractData } from '@contexts/clarke-contract/types';
import { formatUuid } from '@utils/text';
import { contractFeedbackMessage, overflowMenuIcons } from '@pages/contracts/helper';

import OverflowMenuTable from '@components/atoms/contracts/overflow-table';
import Filters from '../contracts/filters-table';
import ClarkeContractTable from './table';
import FormConfirmation from '../form/form-confirmation';

interface ClarkeContractProps {
  typedSearchTerm: string;
}

interface Filters {
  group: {
    label: string | null;
    value: string | null;
  };
}

type renderFunction = ColumnOptions<ClarkeContractData>['renderAsElement'];

const renderClarkeContractId: renderFunction = (entry) => formatUuid(entry.contractId);
const renderGroupName: renderFunction = (entry) => entry.groupName;
const renderManagmentAmount: renderFunction = (entry) => entry.managmentAmount;
const renderSignatureDate: renderFunction = (entry) => entry.signatureDate;

const ClarkeContractList: React.FC<ClarkeContractProps> = ({ typedSearchTerm }) => {
  const {
    clarkeContracts: { data, total, limit },
    loading,
    getClarkeContractsHandler,
    getGroupsFilterHandler,
    deleteClarkeContractHandler,
    groupsFilter: { totalPage, groupsFilter },
  } = useClarkeContract();
  const { setNotificationResponse } = useNotification();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [groupPage, setGroupPage] = useState<number>(1);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>('oo');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openFilterOptions, setOpenFilterOptions] = useState<'GROUP' | null>(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState<Filters>({
    group: { label: null, value: null },
  });

  React.useEffect(() => {
    const params = {
      page,
      groupId: selectedFilterOption?.group?.value,
      term: typedSearchTerm,
    };
    getClarkeContractsHandler(params);
  }, [page, selectedFilterOption, typedSearchTerm]);

  React.useEffect(() => {
    if (groupPage !== 1 && groupPage > totalPage) {
      return;
    }
    getGroupsFilterHandler(groupPage);
  }, [groupPage]);

  const confirmationDelete = async () => {
    if (selectedId) {
      const {
        feedback: { message, tab },
      } = await deleteClarkeContractHandler(selectedId);
      setNotificationResponse(contractFeedbackMessage(tab, message));
      setOpenConfirmationModal(false);
      setOpenTableMenu(false);
    }
  };

  const clarkeTableColumns: ColumnOptions<ClarkeContractData>[] = [
    { accessor: 'contractId', header: 'Contrato Clarke', renderAsElement: renderClarkeContractId, fixedWidthInRem: 15 },
    { accessor: 'groupName', header: 'Grupo comercial', renderAsElement: renderGroupName, fixedWidthInRem: 20 },
    {
      accessor: 'managmentAmount',
      header: 'Valor de Gestão',
      renderAsElement: renderManagmentAmount,
      fixedWidthInRem: 20,
    },
    {
      accessor: 'signatureDate',
      header: 'Data de assinatura',
      renderAsElement: renderSignatureDate,
      fixedWidthInRem: 7,
    },
    {
      accessor: null,
      header: '',
      fixedWidthInRem: 2,
      renderAsElement: (entry) => {
        return (
          <OverflowMenuTable
            show={openTableMenu && selectedId === entry.contractId}
            itens={overflowMenuIcons}
            buttonClick={() => {
              setSelectedId(entry.contractId);
              setOpenTableMenu(!openTableMenu);
            }}
            menuClick={(selectedIdx) => {
              const isEditMode = selectedIdx === 0;
              if (isEditMode) {
                navigate(`/editar-contrato-clarke/${entry.contractId}`);
              } else {
                setOpenConfirmationModal(true);
                setSelectedId(entry.contractId);
              }
              setOpenTableMenu(!openTableMenu);
            }}
          />
        );
      },
    },
  ];

  const groupOptions = groupsFilter.map(({ label }) => label);

  const groupFilter = useMemo(() => {
    return {
      label: selectedFilterOption.group?.label || 'Grupo Comercial',
      filterSelected: selectedFilterOption.group?.label !== null,
      options: groupOptions,
      openOptions: () => {
        if (openFilterOptions === 'GROUP') {
          setOpenFilterOptions(null);
          return;
        }
        setOpenFilterOptions('GROUP');
      },
      onClick: (option: string) => {
        const groupValue = groupsFilter.find(({ label }) => label === option)?.value || null;
        setSelectedFilterOption((prevState) => ({ ...prevState, group: { label: option, value: groupValue } }));
        setOpenFilterOptions(null);
      },
      showOptions: openFilterOptions === 'GROUP',
    };
  }, [selectedFilterOption.group, openFilterOptions]);

  return (
    <>
      <div className="col-span-full">
        <Filters
          filters={[groupFilter]}
          isLoading={false}
          onScrollToBotton={() => {
            setGroupPage((prevPage) => prevPage + 1);
          }}
          clearFilter={() =>
            setSelectedFilterOption({
              group: { value: null, label: null },
            })
          }
        />
        <ClarkeContractTable
          columns={clarkeTableColumns}
          data={data}
          isFetchingContracts={loading}
          pagination={{
            page,
            total,
            setCurrentPage: setPage,
            limit,
          }}
        />
      </div>

      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar contrato Clarke"
        description="Tem certeza que deseja excluir esse contrato?"
      >
        <FormConfirmation
          onConfirmation={confirmationDelete}
          onCancel={() => {
            setSelectedId(null);
            setOpenConfirmationModal(false);
            setOpenTableMenu(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ClarkeContractList;

import { gql } from '@apollo/client';

export const CREATE_CLARKE_CONTRACT = gql`
  mutation CreateClarkeContract($input: UpsertClarkeContractInput) {
    createClarkeContract(input: $input) {
      id
    }
  }
`;

export const EDIT_CLARKE_CONTRACT = gql`
  mutation UpdateClarkeContract($contractId: ID!, $input: UpsertClarkeContractInput) {
    updateClarkeContract(contractId: $contractId, input: $input)
  }
`;

export const DELETE_CLARKE_CONTRACT = gql`
  mutation DeleteClarkeContract($contractId: ID!) {
    deleteClarkeContract(contractId: $contractId)
  }
`;

export const DELETE_ATTACHMENT_CLARKE_CONTRACT = gql`
  mutation DeleteClarkeContractAttachment($attachmentId: ID!) {
    deleteClarkeContractAttachment(attachmentId: $attachmentId)
  }
`;

import { useNavigate } from 'react-router';
import { Button } from '@clarke-energia/foton';

import { FINANCIAL_EVENTS_PATH } from '@routers/constants';

interface IFinancialEventsBatchCreationFormActionsSection {
  isLoading?: boolean;
  onNavigateNext?: () => void;
  onNavigatePrevious?: () => void;
}

const FinancialEventsBatchCreationFormActionsSection = ({
  isLoading,
  onNavigateNext,
  onNavigatePrevious,
}: IFinancialEventsBatchCreationFormActionsSection) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-4">
      <Button
        disabled={isLoading}
        kind="secondary"
        label="Voltar"
        onClick={() => {
          onNavigatePrevious ? onNavigatePrevious() : navigate(FINANCIAL_EVENTS_PATH);
        }}
        size="small"
        type="button"
      />
      <Button
        disabled={isLoading}
        kind="primary"
        label="Próximo passo"
        loading={isLoading}
        onClick={onNavigateNext}
        size="small"
        type={onNavigateNext !== undefined ? 'button' : 'submit'}
      />
    </div>
  );
};

export default FinancialEventsBatchCreationFormActionsSection;

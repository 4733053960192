import {
  FinancialEventsGraphQLData,
  FinancialEvent,
  FinancialEventsBatch,
  FinancialEventsBatchGraphQLData,
  SendFinancialEventsByBatchIDGraphQLData,
  SendFinancialEventsByIDsListGraphQLData,
  UpdateFinancialEventAttachmentGraphQLData,
} from './types';

export class FinancialEventsManager {
  rawData: FinancialEventsGraphQLData;
  financialEvents: Array<FinancialEvent>;

  constructor(rawData: FinancialEventsGraphQLData) {
    this.rawData = rawData;
    this.financialEvents = rawData.getFinancialEvents.data;
  }
}

export class FinancialEventsBatchManager {
  rawData: FinancialEventsBatchGraphQLData;
  financialEventsBatch: FinancialEventsBatch;

  constructor(rawData: FinancialEventsBatchGraphQLData) {
    this.rawData = rawData;
    this.financialEventsBatch = rawData.getFinancialEventsBatch;
  }
}

export class SendFinancialEventsByBatchIDManager {
  rawData: SendFinancialEventsByBatchIDGraphQLData;
  success: boolean;

  constructor(rawData: SendFinancialEventsByBatchIDGraphQLData) {
    this.rawData = rawData;
    this.success = rawData.sendFinancialEventsByBatchID;
  }
}

export class SendFinancialEventsByIDsListManager {
  rawData: SendFinancialEventsByIDsListGraphQLData;
  success: boolean;

  constructor(rawData: SendFinancialEventsByIDsListGraphQLData) {
    this.rawData = rawData;
    this.success = rawData.sendFinancialEventsByIDsList;
  }
}

export class UpdateFinancialEventAttachmentManager {
  rawData: UpdateFinancialEventAttachmentGraphQLData;
  success: boolean;

  constructor(rawData: UpdateFinancialEventAttachmentGraphQLData) {
    this.rawData = rawData;
    this.success = rawData.updateFinancialEventAttachment;
  }
}

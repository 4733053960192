import React from 'react';

import { ReadjustmentIndexEnum, ReadjustmentFrequencyEnum } from '@utils/translators/energy-contract';
import { ClarkeContractPayload } from '@contexts/clarke-contract/types';

import { SelectFormField, NumericFormField, FormSection } from '@components/atoms/form';

const ManagmentSection = () => {
  return (
    <>
      <FormSection title="Condições financeiras" subtitle="Dados sobre gestão da Clarke." />
      <div className="col-end-5 gap-x-6 gap-y-6 mb-7 w-full lg:grid lg:grid-cols-2 lg:col-start-2 lg:mb-0 xl:grid-cols-3">
        <NumericFormField<ClarkeContractPayload>
          field="clarkeMagmentAmount"
          id="clarkeMagmentAmount"
          label="Valor da gestão da Clarke"
          tooltipContent="O valor de gestão registrado aqui é por unidade."
          formatProps={{
            placeholder: 'R$',
            prefix: 'R$',
            allowNegative: true,
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          options={{ required: { value: true, message: 'valor de gestao é obrigatório' } }}
        />

        <SelectFormField<ClarkeContractPayload>
          label="Índice de reajuste"
          field="readjustmentIndex"
          id="readjustmentIndex"
          inputOptions={Object.keys(ReadjustmentIndexEnum).map((key) => ({
            value: key,
            optionLabel: key,
          }))}
        />
        <NumericFormField<ClarkeContractPayload>
          id="readjustmentBaseDate"
          field="readjustmentBaseDate"
          label="Data base do reajuste"
          formatProps={{
            placeholder: 'DD/MM/AAAA',
            format: '##/##/####',
            mask: '_',
            allowNegative: false,
            fixedDecimalScale: false,
            decimalSeparator: undefined,
            thousandSeparator: undefined,
            decimalScale: 0,
            isNumericString: true,
          }}
        />
        <SelectFormField<ClarkeContractPayload>
          label="Periodicidade do reajuste"
          field="readjustmentFrequency"
          id="readjustmentFrequency"
          inputOptions={Object.keys(ReadjustmentFrequencyEnum).map((key) => ({
            value: key,
            optionLabel: ReadjustmentFrequencyEnum[key as keyof typeof ReadjustmentFrequencyEnum],
          }))}
        />
      </div>
    </>
  );
};

export default ManagmentSection;

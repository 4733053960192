import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FeedbackNotification, Layout, Modal, TagProps } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { COMMERCIAL_GROUP_PATH, HOME_PATH } from '@routers/constants';
import { KickOffStatus } from '@contexts/kick-off/types';
import useKickOff from '@hooks/use-kick-off';
import { KickOffNotificationFeedbackContents } from '@utils/constants/common';

import { isOnMobileScreen } from '@utils/graphics';

import FormUpdateKickOffDiagnosis from '@components/molecules/form/form-update-kick-off-diagnosis';
import KickOffPageSkeleton from '@components/molecules/kick-off/kick-off-page-skeleton';
import KickOffPageContent from '@components/molecules/kick-off/kick-off-page-content';
import KickOffPageEmpty from '@components/molecules/kick-off/kick-off-page-empty';

const CommercialGroupKickOff: React.FC = () => {
  const { groupId } = useParams();

  const [openSendQuizEmailModal, setOpenSendQuizEmailModal] = useState<boolean>(false);
  const [openUpdateDiagnosisModal, setOpenUpdateDiagnosisModal] = useState<boolean>(false);

  const {
    kickOff,
    loading,
    setGroupId,
    createKickOffByGroupIDHandler,
    sendKickOffQuizEmailHandler,
    getKickOffByGroupIDHandler,
    notificationFeedBackContent,
    setNotificationFeedBackContent,
  } = useKickOff();

  React.useEffect(() => {
    trackEvent(eventLabels.KICK_OFF_PAGE_VIEWED);
  }, []);

  const kickOffStatusHeaderTagIndexer: Record<keyof typeof KickOffStatus, TagProps> = {
    STARTED: {
      kind: 'default',
      color: 'yellow',
      label: 'Iniciado',
    },
    INFORMATION_REGISTRATION_PENDING: {
      kind: 'default',
      color: 'yellow',
      label: 'Faltam dados',
    },
    INFORMATION_REGISTRATION_CONCLUDED: {
      kind: 'default',
      color: 'yellow',
      label: 'Em andamento',
    },
    DIAGNOSTIC_QUIZ_RELEASED: {
      kind: 'default',
      color: 'yellow',
      label: 'Aguardando resposta',
    },
    DIAGNOSTIC_QUIZ_BEING_COMPLETED: {
      kind: 'default',
      color: 'yellow',
      label: 'Aguardando resposta',
    },
    DIAGNOSTIC_QUIZ_SENT: {
      kind: 'default',
      color: 'green',
      label: 'Finalizado',
    },
  };

  const sendQuizEmail = () => {
    setNotificationFeedBackContent(KickOffNotificationFeedbackContents.QUIZ_EMAIL_SEND_IN_PROGRESS);
    setOpenSendQuizEmailModal(false);
    sendKickOffQuizEmailHandler()
      .then((response) => {
        setNotificationFeedBackContent(
          response.data?.sendKickOffQuizEmail
            ? KickOffNotificationFeedbackContents.QUIZ_EMAIL_SEND_SUCCESS
            : KickOffNotificationFeedbackContents.QUIZ_EMAIL_SEND_ERROR,
        );
      })
      .catch(() => {
        setNotificationFeedBackContent(KickOffNotificationFeedbackContents.QUIZ_EMAIL_SEND_ERROR);
      })
      .finally(() => {
        getKickOffByGroupIDHandler();
      });
  };

  const createKickOffButtonClickHandler = () => {
    setNotificationFeedBackContent(KickOffNotificationFeedbackContents.KICK_OFF_CREATION_IN_PROGRESS);
    createKickOffByGroupIDHandler()
      .then(() => {
        getKickOffByGroupIDHandler();
        setNotificationFeedBackContent(KickOffNotificationFeedbackContents.KICK_OFF_CREATION_SUCCESS);
      })
      .catch(() => KickOffNotificationFeedbackContents.KICK_OFF_CREATION_ERROR);
  };

  React.useEffect(() => {
    groupId && setGroupId(groupId);
  }, [groupId]);

  return (
    <Layout
      title="Kick Off"
      loading={loading}
      headerTags={kickOff && [kickOffStatusHeaderTagIndexer[kickOff.status]]}
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: kickOff?.commercialGroup?.name ?? 'KickOff',
          url: COMMERCIAL_GROUP_PATH.replace(':groupId', groupId as string),
        },
        {
          label: 'Kick Off',
          url: '#',
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="fixed right-0 bottom-0 m-3 md:m-6 w-[90%] max-w-full lg:w-fit">
        {notificationFeedBackContent && (
          <FeedbackNotification
            label={notificationFeedBackContent.label}
            message={notificationFeedBackContent.message}
            kind={notificationFeedBackContent.kind}
            onCloseNotification={() => setNotificationFeedBackContent(undefined)}
          />
        )}
      </div>
      {loading ? (
        <KickOffPageSkeleton />
      ) : kickOff ? (
        <KickOffPageContent
          kickOff={kickOff}
          setOpenSendQuizEmailModal={setOpenSendQuizEmailModal}
          setOpenUpdateDiagnosisModal={setOpenUpdateDiagnosisModal}
        />
      ) : (
        <KickOffPageEmpty createKickOffButtonClickHandler={createKickOffButtonClickHandler} />
      )}
      <Modal
        open={openSendQuizEmailModal}
        setOpen={() => setOpenSendQuizEmailModal(!openSendQuizEmailModal)}
        title="Enviar email"
        description="Garanta que o link do questionário esteja ligado. O email com o formulário será enviado para os usuários do grupo comercial."
        modalButtons={{
          primary: {
            kind: 'primary',
            label: 'Enviar email',
            onClick: () => sendQuizEmail(),
            size: isOnMobileScreen() ? 'small' : 'medium',
          },
          secondary: {
            kind: 'secondary',
            label: 'Cancelar',
            onClick: () => setOpenSendQuizEmailModal(false),
            size: isOnMobileScreen() ? 'small' : 'medium',
          },
        }}
        className="self-center"
      />
      <Modal
        open={openUpdateDiagnosisModal}
        setOpen={() => setOpenUpdateDiagnosisModal(!openUpdateDiagnosisModal)}
        title="Editar produto"
        description="Lembrando que o produto foi definido através das respostas do cliente ao formulário."
        className="self-center"
      >
        <FormUpdateKickOffDiagnosis
          dataToUpdate={kickOff?.diagnosis?.suggestedProduct}
          onFormFail={() => {
            setOpenUpdateDiagnosisModal(false);
            setNotificationFeedBackContent(KickOffNotificationFeedbackContents.DIAGNOSIS_UPDATE_ERROR);
          }}
          onFormSuccess={() => {
            setOpenUpdateDiagnosisModal(false);
            setNotificationFeedBackContent(KickOffNotificationFeedbackContents.DIAGNOSIS_UPDATE_SUCCESS);
          }}
          onFormCancel={() => setOpenUpdateDiagnosisModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default CommercialGroupKickOff;

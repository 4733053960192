import { ButtonIcon, ColumnOptions, ThreeDotsMenu } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';
import { IContractBase } from '@pages/sales-contracts';
import { ManagementeContractTypeEnum } from '@components/molecules/contracts/sections/contract-form-contract-type-section';
import moment from 'moment';

interface ISimulationsListRequester {
  pictureUrl: string;
}

const SimulationsListRequester = ({ pictureUrl }: ISimulationsListRequester): JSX.Element => {
  return (
    <td>
      <div className="flex items-center">
        <img className="object-cover w-6 h-6 rounded-full" src={pictureUrl} alt="picture-seller" />
      </div>
    </td>
  );
};

const SimulationsListViewButton = ({ externalUrl }: { externalUrl: string }) => (
  <ButtonIcon
    kind="primary"
    icon={'EyeIcon'}
    onClick={() => {
      window.open(`${externalUrl}`);
    }}
  />
);

const salesContractTableTableColumns = (): Array<ColumnOptions<IContractBase>> => {
  const navigate = useNavigate();

  const onSubmit = (type?: string, contract?: IContractBase) => {
    if (type === 'edit') {
      navigate(`atualizar/?contractId=${contract?.id}`);
    }
    if (type === 'view') {
      window.open(`${contract?.metadata.externalUrl}`);
    }
    if (type === 'download') {
      window.open(`${contract?.metadata.downloadUrl}`);
    }
  };

  return [
    {
      accessor: 'lead',
      header: 'Grupo Comercial',
      renderAsElement: (entry): string => entry.lead.name,
    },
    {
      header: 'Data da Solicitação',
      renderAsElement: (entry): string => moment(entry.createdAt).format('DD/MM/YYYY'),
    },
    {
      header: 'Solicitante',
      renderAsElement: (entry) => <SimulationsListRequester pictureUrl={entry.user.pictureUrl as string} />,
    },
    {
      header: 'Tipo de contrato',
      renderAsElement: (entry): string =>
        ManagementeContractTypeEnum[entry.type as keyof typeof ManagementeContractTypeEnum],
    },
    {
      header: 'Visualizar',
      renderAsElement: (entry) => <SimulationsListViewButton externalUrl={entry.metadata.externalUrl} />,
    },
    {
      renderAsElement: (entry) => {
        return (
          <ThreeDotsMenu
            extraClasses={'md:mr-8 lg:mr-10'}
            menuOptions={[
              {
                icon: 'EyeIcon',
                onClick: () => onSubmit('view', entry),
                label: 'Ver mais',
              },
              {
                icon: 'PencilIcon',
                onClick: () => onSubmit('edit', entry),
                label: 'Editar',
              },
              {
                icon: 'DocumentArrowDownIcon',
                onClick: () => onSubmit('download', entry),
                label: 'Download',
              },
            ]}
          />
        );
      },
    },
  ];
};

export default salesContractTableTableColumns;

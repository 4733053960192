import { useContextSelector } from 'use-context-selector';
import { TraderUserContext, TraderUserContextType } from '@contexts/user-trader';

const useTraderUsers = (): TraderUserContextType => {
  const traderUsers = useContextSelector(TraderUserContext, (traders) => traders.traderUsers);
  const trader = useContextSelector(TraderUserContext, (trader) => trader.trader);
  const loading = useContextSelector(TraderUserContext, (traders) => traders.loading);
  const setTraderId = useContextSelector(TraderUserContext, (traders) => traders.setTraderId);
  const getTraderHandler = useContextSelector(TraderUserContext, (traders) => traders.getTraderHandler);
  const createTraderUserHandler = useContextSelector(TraderUserContext, (trader) => trader.createTraderUserHandler);
  const deleteTraderUserHandler = useContextSelector(TraderUserContext, (trader) => trader.deleteTraderUserHandler);
  const categorizeTradersUserRolesHandler = useContextSelector(
    TraderUserContext,
    (trader) => trader.categorizeTradersUserRolesHandler,
  );
  const loadingRolesForTradersUsers = useContextSelector(
    TraderUserContext,
    (trader) => trader.loadingRolesForTradersUsers,
  );
  const loadingGetTrader = useContextSelector(TraderUserContext, (trader) => trader.loadingGetTrader);

  return {
    getTraderHandler,
    createTraderUserHandler,
    deleteTraderUserHandler,
    traderUsers,
    loading,
    setTraderId,
    trader,
    categorizeTradersUserRolesHandler,
    loadingRolesForTradersUsers,
    loadingGetTrader,
  };
};

export default useTraderUsers;

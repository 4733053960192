import React, { useState } from 'react';
import moment from 'moment';
import { ButtonIcon } from '@clarke-energia/foton';

import { CommercialGroup } from '@contexts/commercial-group';
import { filterEmailsByCategorie } from '@contexts/energy-audit/parser';
import { EnergyAuditFilters, EnergyAuditStatus } from '@contexts/energy-audit';
import useEnergyAudits from '@hooks/use-energy-audit';
import { useNotification } from '@hooks/use-notification';
import useGroupUsers from '@hooks/use-user-groups';
import useTraderUsers from '@hooks/use-user-trader';
import { RolesTypes } from '@utils/translators/users-roles';

import { energyAuditStatusPopupContent, generalAuditsFeedbackContent } from '@pages/energy-audit/static-data';
import EnergyAuditsTable from '@components/molecules/energy-audit/energy-audit-table';
import EmailDrawer from '@components/molecules/energy-audit/email-drawer';
import FiltersBar from '@components/atoms/energy-audit/filters';
import { SelectedFilter } from '../energy-audit-report/list';

interface EnergyAuditListProps {
  referenceDate: string;
  groups: CommercialGroup[];
}

const EnergyAuditList: React.FC<EnergyAuditListProps> = ({ referenceDate, groups }) => {
  const {
    energyAudits,
    loading,
    getAllEnergyAuditsHandler,
    lastSync,
    selectedEnergyAudit,
    setOpenNotificationFeedBack,
    prepareEnergyAuditToBeSentHandler,
    loadingEmailData,
  } = useEnergyAudits();

  const { groupUsers, setCommercialGroupId } = useGroupUsers();
  const { traderUsers, setTraderId } = useTraderUsers();

  const { setNotificationResponse } = useNotification();

  const [selectedGroup, setSelectedGroup] = useState<SelectedFilter | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<SelectedFilter | null>(null);
  const [showFilterOptions, setShowFilterOptions] = useState<'GROUP' | 'STATUS' | null>(null);
  const [page, setPage] = useState<number>(1);
  const [timeInMinutesToLastSync, setTimeInMinutesToLastSync] = useState<number>(0);
  const [openEmailDrawer, setOpenEmailDrawer] = useState<boolean>(false);

  setTimeout(() => {
    setTimeInMinutesToLastSync(moment().diff(lastSync) / 60000);
  }, 10000);

  React.useEffect(() => {
    const params = {
      page,
      date: referenceDate,
      ...(selectedStatus?.value && { status: selectedStatus.value as EnergyAuditStatus }),
      ...(selectedGroup?.value && { groupId: selectedGroup.value }),
    };
    getAllEnergyAuditsHandler(params);
  }, [referenceDate, selectedStatus, selectedGroup, page]);

  const emails = React.useMemo(() => {
    let groupEmails: string[] = [],
      traderEmails: string[] = [];
    let isLoading = true;

    const groupUser = groupUsers && groupUsers.length;
    const traderUser = traderUsers && traderUsers.length;

    if (groupUser) {
      groupEmails = filterEmailsByCategorie(groupUsers, RolesTypes.CONTATO_PRINCIPAL);
    }
    if (traderUser) {
      traderEmails = filterEmailsByCategorie(traderUsers, RolesTypes.FATURAMENTO);
    }
    isLoading = false;

    return { group: groupEmails, trader: traderEmails, isLoading };
  }, [groupUsers, traderUsers]);

  const dispatchEnergyAuditNotification = async () => {
    const response = await prepareEnergyAuditToBeSentHandler({
      auditId: selectedEnergyAudit?.id || '',
      groupEmails: emails.group,
      trader: {
        name: selectedEnergyAudit?.energyContract?.trader?.name || '',
        emails: emails.trader,
      },
    });

    if (response) {
      setNotificationResponse(generalAuditsFeedbackContent['SENDING_EMAIL_SUCCESS']);
      return;
    }
    setNotificationResponse(generalAuditsFeedbackContent['SENDING_EMAIL_FAILED']);
  };

  const groupFilter = React.useMemo(
    () => ({
      label: selectedGroup?.label ?? 'Grupo comercial',
      options: groups.length ? groups.map(({ id, name }) => ({ label: name, value: id })) : [],
      selectedFilter: selectedGroup,
      setSelectedFilter: setSelectedGroup,
      handleFilterVisibility: () => {
        if (showFilterOptions === 'GROUP') return setShowFilterOptions(null);
        setShowFilterOptions('GROUP');
      },
      showOptions: showFilterOptions === 'GROUP',
    }),
    [showFilterOptions, selectedGroup],
  );

  const statusFilter = React.useMemo(
    () => ({
      label: selectedStatus?.label ?? 'Status',
      options: Object.values(energyAuditStatusPopupContent).map((status) => ({
        label: status.label,
        value: status.statusType,
      })),
      selectedFilter: selectedStatus,
      setSelectedFilter: setSelectedStatus,
      handleFilterVisibility: () => {
        if (showFilterOptions === 'STATUS') return setShowFilterOptions(null);
        setShowFilterOptions('STATUS');
      },
      showOptions: showFilterOptions === 'STATUS',
    }),
    [showFilterOptions, selectedStatus],
  );

  return (
    <>
      <div className="md:flex md:justify-between">
        <FiltersBar
          btnsFilters={[groupFilter, statusFilter]}
          clearOptions={() => {
            setSelectedGroup(null);
            setSelectedStatus(null);
            setShowFilterOptions(null);
          }}
        />
        <div className="flex gap-2 items-center">
          <p className="text-paragraph-small text-neutral-50">{`Última sincronização há ${timeInMinutesToLastSync.toFixed(
            0,
          )} minutos`}</p>
          <ButtonIcon
            kind={'ghost'}
            icon={'ArrowPathIcon'}
            onClick={() => getAllEnergyAuditsHandler({ date: referenceDate, page } as EnergyAuditFilters)}
          />
        </div>
      </div>
      <EnergyAuditsTable
        isFetchingEnergyAudits={loading}
        energyAuditsData={energyAudits.data}
        totalEnergyAuditsCount={energyAudits.total}
        currentPage={page}
        setCurrentPage={setPage}
        energyAuditsByPage={energyAudits.limit}
        setOpenEmailDrawer={setOpenEmailDrawer}
      />
      <EmailDrawer
        subtitle={`${selectedEnergyAudit?.date} – ${selectedEnergyAudit?.group?.name} (${selectedEnergyAudit?.energyContract?.id})`}
        actionBtnClick={() => {
          dispatchEnergyAuditNotification();
          setOpenEmailDrawer(false);
          setOpenNotificationFeedBack(true);
        }}
        cancelBtnClick={() => {
          setCommercialGroupId('');
          setTraderId('');
          setOpenEmailDrawer(false);
        }}
        openDrawer={openEmailDrawer}
        energyAudit={loadingEmailData ? null : selectedEnergyAudit}
        emails={{ group: emails.group, trader: emails.trader, isLoading: emails.isLoading }}
      />
    </>
  );
};

export default EnergyAuditList;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@clarke-energia/foton';

import useFinancialEvents from '@hooks/use-financial-events';
import { FINANCIAL_EVENTS_PATH } from '@routers/constants';

import FormConfirmationFinancialEventsBatchSending from '@components/molecules/form/form-confirmation-financial-events-batch';
import FinancialEventsTable from '@components/molecules/financial-events/list/financial-events-table';
import FinancialEventsBatchCreationFormActionsSection from '@components/molecules/financial-events/batch-creation/form-actions-section';

interface IFinancialEventsBatchProcessingConfirmation {
  setFinancialEventStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
}

const FinancialEventsBatchProcessingConfirmation = ({
  setFinancialEventStep,
  currentStep,
}: IFinancialEventsBatchProcessingConfirmation) => {
  const navigate = useNavigate();

  const {
    financialEventsBatch,
    getFinancialEventsBatchForcedHandler,
    handleSendFinancialEventsByBatchId,
    setOpenNotificationFeedBack,
    loading,
    financialEventsBatchId,
  } = useFinancialEvents();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    getFinancialEventsBatchForcedHandler();
  }, [financialEventsBatchId]);

  return (
    <div>
      <div className="flex gap-6 mb-10">
        <div className="max-w-[16.5rem]">
          <h1 className="font-bold text-heading-xsmall">{currentStep}. Confirmar os dados</h1>
          <span className="text-paragraph-medium text-neutral-60">
            Sincronização com a tabela realizada. Confira os dados abaixo.
          </span>
        </div>
      </div>
      <div className="mb-10">
        <FinancialEventsTable
          activeFinancialEventType={financialEventsBatch?.type}
          financialEventsData={financialEventsBatch?.financialEvents}
          totalEventsCount={financialEventsBatch?.financialEvents?.length}
          isFetchingFinancialEvents={loading}
          isOnConfirmationScreen
        />
      </div>
      <FinancialEventsBatchCreationFormActionsSection
        onNavigatePrevious={() => setFinancialEventStep((previous) => previous - 1)}
        onNavigateNext={() => setOpenConfirmationModal(true)}
      />
      <Modal
        title="Enviar eventos financeiros"
        description="Ao confirmar o envio, serão disparados emails aos clientes. Certifique que as informações estejam corretas."
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(false)}
      >
        {financialEventsBatch && (
          <FormConfirmationFinancialEventsBatchSending
            onConfirmation={() => {
              handleSendFinancialEventsByBatchId(financialEventsBatch.id);
              setOpenConfirmationModal(false);
              setOpenNotificationFeedBack(true);
              navigate(FINANCIAL_EVENTS_PATH);
            }}
            onCancel={() => setOpenConfirmationModal(false)}
            financialEventsBatch={financialEventsBatch}
          />
        )}
      </Modal>
    </div>
  );
};

export default FinancialEventsBatchProcessingConfirmation;

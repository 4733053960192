import { gql } from '@apollo/client';
import { CREATE_UNIT } from '@components/molecules/form/form-upsert-unit/mutation';

export const UPDATE_UNIT = CREATE_UNIT;
export const UPSERT_UNIT_CONSUMPTION_HISTORY = gql`
  mutation UpsertUnitConsumptionHistory($upsertUnitHistoryInput: CreateHistoryInput!) {
    createHistory(input: $upsertUnitHistoryInput) {
      id
    }
  }
`;
export const UPSERT_MEASURING_POINT = gql`
  mutation UpsertMeasuringPoint($input: GroupsSourceInput) {
    upsertMeasuringPoint(input: $input) {
      id
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation DeleteUnit($unitId: ID!) {
    deleteUnit(id: $unitId)
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL_PROINFAS_QUERY = gql`
  query GetAllProinfas($input: GetProinfaInput) {
    getProinfas(input: $input) {
      total
      limit
      page
      data {
        id
        year
        monthlyValues {
          value
          month
        }
        unit {
          name
          id
        }
      }
    }
  }
`;

export const GET_YEAR_OPTIONS_PROINFAS_QUERY = gql`
  query GetYearOptionsProinfas($input: GetProinfaInput) {
    getProinfas(input: $input) {
      data {
        year
      }
    }
  }
`;

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        units {
          id
          name
          assetCode
        }
      }
    }
  }
`;

import React from 'react';
import KickOffDiagnosisSection from './kick-off-diagnosis-section';
import KickOffQuizSection from './kick-off-quiz-section';
import KickOffUnitsSummarySection from './kick-off-units-summary-section';
import { IKickOff } from '@contexts/kick-off/types';

interface IKickOffPageContent {
  kickOff: IKickOff;
  setOpenUpdateDiagnosisModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSendQuizEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const KickOffPageContent = ({
  kickOff,
  setOpenUpdateDiagnosisModal,
  setOpenSendQuizEmailModal,
}: IKickOffPageContent) => (
  <div className="flex flex-col col-span-full gap-6 pb-12">
    <KickOffDiagnosisSection kickOff={kickOff} setOpenUpdateDiagnosisModal={setOpenUpdateDiagnosisModal} />
    <div className="flex flex-col gap-6 w-full lg:flex-row">
      <KickOffQuizSection kickOff={kickOff} setOpenSendQuizEmailModal={setOpenSendQuizEmailModal} />
      <KickOffUnitsSummarySection kickOff={kickOff} />
    </div>
  </div>
);

export default KickOffPageContent;

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const isOnMobileScreen = () => {
  if (getWindowDimensions().width <= 768) return true;
  else return false;
};

import { FileField } from '@components/atoms/form/file/file-input';
import { IFinancialEventsBatchPayload } from '@contexts/financial-events';

interface IFinancialEventsBatchCreationFormFilesSection {
  setUploadedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setUploadedAttachments: React.Dispatch<React.SetStateAction<Array<File> | undefined>>;
}

const FinancialEventsBatchCreationFormFilesSection = ({
  setUploadedFile,
  setUploadedAttachments,
}: IFinancialEventsBatchCreationFormFilesSection) => {
  return (
    <div className="flex flex-col gap-6 w-[34.5rem]">
      <FileField<IFinancialEventsBatchPayload>
        field="spreadsheetToUpload"
        id="spreadsheetToUpload"
        label="Importar dados"
        description="Envie a tabela com as informações dos eventos financeiros em XLSX ou XLS."
        maximumFileSizeInMB={8}
        allowedFileTypes={['.xlsx', '.xls']}
        onChangeFiles={(file) => setUploadedFile(file as File)}
      />
      <FileField<IFinancialEventsBatchPayload>
        field="attachmentsToUpload"
        id="attachmentsToUpload"
        label="Anexar arquivos"
        description="Envie os arquivos do evento financeiro selecionado. PDF de até 2MB."
        maximumFileSizeInMB={2}
        allowedFileTypes={['.pdf']}
        onChangeFiles={(files) => setUploadedAttachments(files as Array<File>)}
        multipleFiles
      />
    </div>
  );
};

export default FinancialEventsBatchCreationFormFilesSection;

import { gql } from '@apollo/client';

export const FINANCIAL_EVENT_SCHEMA = `
  {
    id
    batchId
    type
    status
    commercialGroup {
      id
      name
      cnpj
    }
    agent
    targetUsers {
      id
      name
      email
    }
    attachment {
      id
      url
      createdAt
      thirdPartyName
      batchId
      filename
    }
    amountToPay
    liquidationFee
    liquidationGuarantee
    eerFeePerProfiles
    referencePeriod
    paymentDeadline
    createdAt
    sentOn
  }
`;

export const GET_FINANCIAL_EVENTS_QUERY = gql`
  query GetFinancialEvents($input: GetFinancialEventsInput) {
    getFinancialEvents(input: $input) {
      page
      total
      limit
      data ${FINANCIAL_EVENT_SCHEMA}
    }
  }
`;

export const GET_FINANCIAL_EVENTS_BATCH_QUERY = gql`
  query GetFinancialEventsBatch($financialEventsBatchId: ID!) {
    getFinancialEventsBatch(id: $financialEventsBatchId) {
      id
      type
      status
      processingPercentage
      rawData
      financialEvents ${FINANCIAL_EVENT_SCHEMA}
      attachments {
        id
        url
        createdAt
        thirdPartyName
        batchId
        filename
      }
      dataSourceFileUrl
      referencePeriod
      paymentDeadline
      createdAt
    }
  }
`;

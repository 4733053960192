import React from 'react';

import { useFormContext } from 'react-hook-form';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { FormSection, SelectFormField } from '@components/atoms/form';
import ContractedVolumeFields from '@components/atoms/energy-contract/volume-contract-fields';

interface ContractedVolumeSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
}

const FormContractedVolumeSection = ({ startYear, yearsFields }: ContractedVolumeSectionProps): JSX.Element => {
  const { setValue, watch } = useFormContext();
  const isFixedContractedVolume = watch('isFixedContractedVolume');

  return (
    <div>
      <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
        <FormSection title="Volume contratado" subtitle="Valor de volume contratado por ano ou fixo." />
        <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
          <SelectFormField<CreateEnergyContractPayload>
            label="Volume contratado varia ano a ano?"
            field="isFixedContractedVolume"
            id="isFixedContractedVolume"
            inputOptions={[
              { optionLabel: 'Sim, há variação do volume', value: 'false' },
              { optionLabel: 'Não', value: 'true' },
            ]}
            options={{ required: { value: true, message: 'Cobertura é obrigatório' } }}
          />
          {isFixedContractedVolume ? (
            <ContractedVolumeFields
              yearsFields={yearsFields}
              startYear={startYear}
              setValue={setValue}
              isFixedContractedVolume={isFixedContractedVolume}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormContractedVolumeSection;

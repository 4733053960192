import { HeroIcon } from '@clarke-energia/foton';
import { Transition } from '@headlessui/react';
import React from 'react';

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  label: string;
  isOpen: boolean;
  onClick: () => void;
  disabled?: boolean;
  customAttrs?: Record<string, string>;
}

const EnergyAuditAccordionTitle: React.FC<AccordionProps> = ({
  label,
  onClick,
  customAttrs,
  disabled,
  children,
  isOpen,
}) => {
  const iconBaseClass = 'cursor-pointer w-5 h-5 transition-transform ease-linear duration-300';
  const rotateClass = isOpen ? '-rotate-180' : '-rotate-0';
  const iconClass = `${iconBaseClass} ${rotateClass}`;
  const colorClass = disabled ? 'bg-neutral-10 text-neutral-60 border-neutral-20' : 'border-black';

  const dynamicClasses = [colorClass].join(' ');
  return (
    <div className="flex flex-col" {...customAttrs}>
      <button
        onClick={onClick}
        className={`flex justify-between items-center py-3 px-3 rounded hover:bg-neutral-10 ${dynamicClasses}`}
      >
        <div className="flex gap-6 items-center w-full text-start">
          <p className="font-bold text-heading-medium">{label}</p>
          <div className="flex items-center space-x-5 grow-0">
            <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
          </div>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-3">{children}</div>
      </Transition>
    </div>
  );
};

export default EnergyAuditAccordionTitle;

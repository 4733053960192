import React from 'react';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';

import configuration from '@config';
import errorTracker from '@services/error-tracking';
import Auth0ApiService from '@services/auth0';

export interface User {
  id: string;
  name: string;
  email: string;
  pictureURL: string;
  phone: string;
  roles?: ('admin' | 'supplier' | 'customer')[];
}

export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken: string;
}

export interface IAuthContext {
  authStatus: AuthenticationState;
  user: User;
  logout: () => void;
  auth0ApiService?: Auth0ApiService;
  loading: boolean;
}

export const authContext = React.createContext({} as IAuthContext);
export const useAuth = () => React.useContext<IAuthContext>(authContext);

interface ApolloWrapperProps {
  children: JSX.Element;
}

function ApolloWrapper({ children }: ApolloWrapperProps) {
  const { getAccessTokenSilently, isAuthenticated, user, logout, isLoading } = useAuth0();
  const [token, setToken] = React.useState<string>('');
  const [auth0ApiService, setAuth0ApiService] = React.useState<Auth0ApiService>();

  const httpLink = createHttpLink({
    uri: configuration.GATEWAY_API_HOST,
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!token) return { headers, ...rest };
    return {
      ...rest,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { variables, operationName } = operation;
    const context = { name: operationName, values: variables };

    if (networkError) {
      context.values = {
        ...context.values,
        errorType: 'network',
        rawErrorObject: JSON.stringify(networkError),
      };
      errorTracker.captureException(networkError, context);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        const err = new Error(`GraphQL error on ${operationName}`, { cause: graphQLError });
        context.values = {
          ...context.values,
          errorType: 'graphql',
          rawErrorObject: JSON.stringify(graphQLError),
        };
        errorTracker.captureException(err, context);
      });
    }
  });

  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token },
    user: {
      id: user?.['https://clarke.com.br/uuid'],
      name: user?.name ?? '',
      email: user?.email ?? '',
      pictureURL: user?.picture ?? '',
      roles: user?.['https://clarke.com.br/roles'] ?? undefined,
      phone: user?.phone_number ?? '',
    },
    logout,
    auth0ApiService,
    loading: isLoading,
  };

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  React.useEffect(() => {
    const getToken = async () => {
      const bearerToken = isAuthenticated ? await getAccessTokenSilently() : '';
      setToken(bearerToken);
      setAuth0ApiService(new Auth0ApiService(bearerToken));
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <ApolloProvider client={client}>
      <authContext.Provider value={contextValue}>{children}</authContext.Provider>
    </ApolloProvider>
  );
}

export default ApolloWrapper;

import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineChart from '@components/atoms/charts/line-chart';
import numeral from '@utils/numeral';

interface ILineChartComponent {
  chartLabels: string[];
  chartValuesPrimary: Array<number | null>;
  chartValuesSecondary: Array<number | null>;
  barThicknessInPixels?: number;
  currencyData?: boolean;
  className?: string;
}

const LineChartComponent: React.FC<ILineChartComponent> = ({
  chartLabels,
  chartValuesPrimary,
  chartValuesSecondary,
  barThicknessInPixels,
  currencyData,
  className,
}): JSX.Element => {
  const parseChartValuesPrimary = chartValuesPrimary.map((item) => (item ? item : 0));
  const maxChartYAxis = Math.max(...parseChartValuesPrimary) * 1.1;
  const chartTooltip = {
    xAlign: 'center',
    yAlign: 'bottom',
    backgroundColor: 'white',
    borderColor: 'black',
    borderWidth: 1,
    displayColors: false,
    bodyColor: 'black',
    titleColor: 'black',
    bodyAlign: 'center',
    cornerRadius: 15,
    titleFont: { size: 12, weight: 'bold' },
    caretSize: 0,
    caretPadding: 8,
    padding: {
      top: 6,
      bottom: 0,
    },
    callbacks: {
      title: (value: Record<string, any>[]) => {
        return `   ${numeral(value[0].raw).format(`${currencyData && '$'} 0,0.00`)}   `;
      },
      label: () => null,
    },
  };

  const xAxes = {
    display: true,
    grid: {
      display: false,
      tickColor: '#FFFFFF',
    },
    ticks: {
      color: 'black',
      font: {
        size: 10,
      },
    },
  };

  const yAxes = {
    display: true,
    max: maxChartYAxis,
    grid: {
      color: '#818181',
      tickColor: '#FFFFFF',
    },
    border: {
      dash: [5, 5],
    },
    ticks: {
      font: {
        size: 10,
      },
      color: 'black',
      stepSize: maxChartYAxis / 4,
      callback: function (value: number, index: number) {
        const valueHigherThenThousand = value > 1000;
        const reducedValue = valueHigherThenThousand ? value / 1000 : value;
        return index > 0 ? `${numeral(reducedValue).format('0')}${valueHigherThenThousand ? 'k' : ''}` : null;
      },
    },
  };

  const data = () => ({
    labels: chartLabels,
    options: {
      barThickness: barThicknessInPixels,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: xAxes,
        y: yAxes,
      },
      plugins: {
        datalabels: {
          padding: 12,
          borderRadius: 30,
          backgroundColor: 'white',
          color: 'black',
          borderWidth: 1,
          borderColor: 'black',
        },
        legend: {
          display: false,
        },
        tooltip: chartTooltip,
      },
    },
    datasets: [
      {
        type: 'line',
        borderColor: '#000',
        data: chartValuesPrimary,
        pointRadius: 1,
        tension: 0.3,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        type: 'line',
        borderColor: '#00BF6A',
        data: chartValuesSecondary,
        pointRadius: 1,
        tension: 0.3,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  });

  return (
    <div className="overflow-x-auto h-3/4">
      <div className={`h-full min-w-[20rem] ${className}`}>
        <LineChart
          plugins={[ChartDataLabels]}
          options={data().options}
          datasets={data().datasets}
          labels={data().labels}
        />
      </div>
    </div>
  );
};

export default LineChartComponent;

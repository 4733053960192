import React, { useState } from 'react';
import { createContext } from 'use-context-selector';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useAuth } from '@src/ApolloWrapper';
import { createFinancialEventsBatch } from '@services/customerAPI';

import {
  FinancialEventsBatchManager,
  FinancialEventsManager,
  SendFinancialEventsByBatchIDManager,
  SendFinancialEventsByIDsListManager,
  UpdateFinancialEventAttachmentManager,
} from './manager';
import { GET_FINANCIAL_EVENTS_BATCH_QUERY, GET_FINANCIAL_EVENTS_QUERY } from './queries';
import {
  MUTATION_SEND_FINANCIAL_EVENTS_BY_BATCH_ID,
  MUTATION_SEND_FINANCIAL_EVENTS_BY_IDS_LIST,
  MUTATION_UPDATE_FINANCIAL_EVENT_ATTACHMENT,
  MUTATION_UPDATE_FINANCIAL_EVENT_TARGET_USERS,
} from './mutations';
import {
  FinancialEvent,
  FinancialEventsBatch,
  FinancialEventsContextType,
  FinancialEventType,
  IFinancialEventsSendingStatus,
  IFinancialEventsBatchPayload,
} from './types';

export * from './types';

export const FinancialEventsContext = createContext({} as FinancialEventsContextType);

interface Provider {
  children: React.ReactNode;
}

const FinancialEventsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const [financialEvents, setFinancialEvents] = useState<Array<FinancialEvent>>();
  const [financialEventsBatch, setFinancialEventsBatch] = useState<FinancialEventsBatch>();
  const [financialEventsBatchId, setFinancialEventsBatchId] = useState<string>();

  const [page, setPage] = useState<number>(1);
  const [termFilter, setTermFilter] = useState<string>();
  const [referencePeriodFilter, setReferencePeriodFilter] = useState<Date>();
  const [commercialGroupIdFilter, setCommercialGroupIdFilter] = useState<string>();
  const [typeFilter, setTypeFilter] = useState<FinancialEventType>();
  const [sendFinancialEventsStatus, setSendFinancialEventsStatus] = useState<IFinancialEventsSendingStatus>();

  const [totalFinancialEvents, setTotalFinancialEvents] = useState<number>();

  const [openNotificationFeedBack, setOpenNotificationFeedBack] = useState<boolean>(false);

  const [getFinancialEvents, { loading: loadingGetFinancialEvents }] = useLazyQuery(GET_FINANCIAL_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new FinancialEventsManager(data);
      setFinancialEvents(manager.financialEvents);
      setTotalFinancialEvents(manager.rawData.getFinancialEvents.total);
    },
    onError: () => {
      setFinancialEvents([]);
    },
    variables: {
      input: {
        page: page,
        term: termFilter,
        referencePeriod: referencePeriodFilter,
        commercialGroupId: commercialGroupIdFilter,
        type: typeFilter,
      },
    },
  });

  const [
    getFinancialEventsBatch,
    { loading: loadingGetFinancialEventsBatch, refetch: refetchGetFinancialEventsBatch },
  ] = useLazyQuery(GET_FINANCIAL_EVENTS_BATCH_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new FinancialEventsBatchManager(data);
      setFinancialEventsBatch(manager.financialEventsBatch);
    },
    variables: {
      financialEventsBatchId,
    },
  });

  const [getFinancialEventsBatchForced, { loading: loadingGetFinancialEventsBatchForced }] = useLazyQuery(
    GET_FINANCIAL_EVENTS_BATCH_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new FinancialEventsBatchManager(data);
        setFinancialEventsBatch(manager.financialEventsBatch);
      },
      variables: {
        financialEventsBatchId,
      },
    },
  );

  const [sendFinancialEventsByBatchId, { loading: loadingSendFinancialEventsByBatchId }] = useMutation(
    MUTATION_SEND_FINANCIAL_EVENTS_BY_BATCH_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new SendFinancialEventsByBatchIDManager(data);
        setSendFinancialEventsStatus(manager.success ? 'SENDING_SUCCESS' : 'SENDING_FAILED');
      },
      onError: (e) => {
        setSendFinancialEventsStatus('SENDING_FAILED');
      },
    },
  );

  const [sendFinancialEventsByIdsList, { loading: loadingSendFinancialEventsByIdsList }] = useMutation(
    MUTATION_SEND_FINANCIAL_EVENTS_BY_IDS_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new SendFinancialEventsByIDsListManager(data);
        setSendFinancialEventsStatus(manager.success ? 'SENDING_SUCCESS' : 'SENDING_FAILED');
      },
      onError: () => {
        setSendFinancialEventsStatus('SENDING_FAILED');
      },
    },
  );

  const [updateFinancialEventAttachment, { loading: loadingUpdateFinancialEventAttachment }] = useMutation(
    MUTATION_UPDATE_FINANCIAL_EVENT_ATTACHMENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new UpdateFinancialEventAttachmentManager(data);
        if (manager.success !== true) setOpenNotificationFeedBack(true);
      },
      onError: () => {
        setOpenNotificationFeedBack(true);
      },
    },
  );

  const [updateFinancialEventTargetUsers, { loading: loadingUpdateFinancialEventTargetUsers }] = useMutation(
    MUTATION_UPDATE_FINANCIAL_EVENT_TARGET_USERS,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        throw Error(`An error occurred when updating financial event target users: ${error}`);
      },
    },
  );

  const handleSendFinancialEventsByBatchId = (id: string) => {
    setSendFinancialEventsStatus('SENDING');
    return sendFinancialEventsByBatchId({
      variables: {
        id,
      },
    });
  };

  const handleSendFinancialEventsByIdsList = (idsList: string[]) => {
    setSendFinancialEventsStatus('SENDING');
    return sendFinancialEventsByIdsList({
      variables: {
        idsList,
      },
    });
  };

  function getFinancialEventsHandler() {
    return getFinancialEvents();
  }

  function getFinancialEventsBatchHandler() {
    return getFinancialEventsBatch({
      variables: {
        financialEventsBatchId,
      },
    });
  }

  function getFinancialEventsBatchForcedHandler() {
    return getFinancialEventsBatchForced({
      variables: {
        financialEventsBatchId,
      },
    });
  }

  function refetchGetFinancialEventsBatchHandler() {
    return refetchGetFinancialEventsBatch({ financialEventsBatchId });
  }

  function createFinancialEventsBatchHandler(financialEvents: IFinancialEventsBatchPayload) {
    return createFinancialEventsBatch(financialEvents, accessToken);
  }

  function updateFinancialEventAttachmentHandler(financialEventId: string, attachmentId?: string) {
    return updateFinancialEventAttachment({
      variables: {
        financialEventId,
        attachmentId: attachmentId,
      },
    });
  }

  function updateFinancialEventTargetUsersHandler(id: string) {
    return updateFinancialEventTargetUsers({
      variables: {
        id,
      },
    });
  }

  React.useEffect(() => {
    setFinancialEvents([]);
    getFinancialEventsHandler();
  }, [page, termFilter, typeFilter]);

  return (
    <FinancialEventsContext.Provider
      value={{
        getFinancialEventsBatchForcedHandler,
        page,
        setPage,
        setTermFilter,
        referencePeriodFilter,
        setReferencePeriodFilter,
        commercialGroupIdFilter,
        setCommercialGroupIdFilter,
        typeFilter,
        setTypeFilter,
        setFinancialEventsBatchId,
        getFinancialEventsHandler,
        getFinancialEventsBatchHandler,
        createFinancialEventsBatchHandler,
        handleSendFinancialEventsByBatchId,
        handleSendFinancialEventsByIdsList,
        refetchGetFinancialEventsBatchHandler,
        updateFinancialEventAttachmentHandler,
        updateFinancialEventTargetUsersHandler,
        financialEvents,
        financialEventsBatch,
        financialEventsBatchId,
        totalFinancialEvents,
        loading:
          loadingGetFinancialEventsBatch ||
          loadingGetFinancialEvents ||
          loadingSendFinancialEventsByBatchId ||
          loadingSendFinancialEventsByIdsList ||
          loadingGetFinancialEventsBatchForced ||
          loadingUpdateFinancialEventAttachment ||
          loadingUpdateFinancialEventTargetUsers,
        sendFinancialEventsStatus,
        openNotificationFeedBack,
        setOpenNotificationFeedBack,
      }}
    >
      {children}
    </FinancialEventsContext.Provider>
  );
};

export default FinancialEventsProvider;

import { useContextSelector } from 'use-context-selector';
import { CceeContractContext, CceeContractContextType } from '@contexts/ccee-contract';

export const useCceeContract = (): CceeContractContextType => {
  const getGroupsListHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.getGroupsListHandler,
  );

  const getEnergyContractsHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.getEnergyContractsHandler,
  );

  const getCceeContractsHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.getCceeContractsHandler,
  );

  const createCceeContractHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.createCceeContractHandler,
  );

  const getCceeContractByIdHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.getCceeContractByIdHandler,
  );
  const cceeContracts = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.cceeContracts);
  const loading = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.loading);
  const updateCceeContractHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.updateCceeContractHandler,
  );
  const deleteCceeContractHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.deleteCceeContractHandler,
  );
  const selectedContract = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.selectedContract);
  const setSelectedContract = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.setSelectedContract,
  );
  const openCreateContract = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.openCreateContract);
  const setOpenCreateContract = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.setOpenCreateContract,
  );

  const pagination = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.pagination);
  const groups = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.groups);
  const energyContracts = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.energyContracts);
  const cceeContractToUpdate = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.cceeContractToUpdate,
  );
  const loadingMutation = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.loadingMutation);
  const loadingEnergyContracts = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.loadingEnergyContracts,
  );

  const groupsFilter = useContextSelector(CceeContractContext, (cceeContract) => cceeContract.groupsFilter);

  const getGroupsFilterHandler = useContextSelector(
    CceeContractContext,
    (cceeContract) => cceeContract.getGroupsFilterHandler,
  );

  return {
    loading,
    cceeContracts,
    getEnergyContractsHandler,
    getGroupsListHandler,
    getCceeContractsHandler,
    getCceeContractByIdHandler,
    createCceeContractHandler,
    updateCceeContractHandler,
    deleteCceeContractHandler,
    selectedContract,
    setSelectedContract,
    openCreateContract,
    setOpenCreateContract,
    pagination,
    groups,
    energyContracts,
    cceeContractToUpdate,
    loadingEnergyContracts,
    loadingMutation,
    groupsFilter,
    getGroupsFilterHandler,
  };
};

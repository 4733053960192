import { TraderTypeEnum } from '@contexts/trader';
import { ReadjustmentFrequencyEnum, ReadjustmentIndexEnum } from '@utils/translators/energy-contract';
import { ContractTypeEnum, EnergyTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';

export type AttachmentFeedbackType =
  | 'DELETING_CLARKE_CONTRACT_ATTACHMENT_SUCCESS'
  | 'DELETING_CLARKE_CONTRACT_ATTACHMENT_ERROR';

export enum PayDayType {
  WORKDAY = 'WORKDAY',
  CALENDAR_DAY = 'CALENDAR_DAY',
}

export interface GetEnergyContractsParams {
  page: number;
  groupId?: string;
  term: string | null;
}

export interface EnergyContractsGraphqlResponse {
  total: number;
  page: number;
  limit: number;
  data: {
    id: string;
    units: Unit[];
    gmvAmount?: number;
    carbonDioxideTonAmount?: number;
    takeRateAmount?: number;
    isFixedContractedVolume?: boolean;
    fixedContractedVolume?: number;
    contractedVolumePerYear?: {
      amount: number;
    }[];
  }[];
}

interface Unit {
  id: string;
  group: {
    id: string;
    name: string;
  };
}

interface ContractedVolmePerYear {
  year: number;
  amount: number;
}

interface Trader {
  id: string;
  type: TraderTypeEnum;
}
export interface EnergyContractTable {
  id: string;
  groupName: string;
  gmvAmount: string;
  carbon: string;
  contractedVolume: string;
  takeRateAmount: string;
}

export interface EnergyContract {
  id: string;
  idCceeTrader: number;
  perfilCceeTrader: string;
  group: {
    id: string | null;
  };
  profilesCcee: { id: string; name: string }[];
  lowerFlexibility: number;
  upperFlexibility: number;
  units: Unit[];
  contractType: null | ContractTypeEnum;
  startSupplyDate: string;
  endSupplyDate: string;
  energyType: null | EnergyTypeEnum;
  coverCceeTaxes: null | boolean;
  coverCceeAmount: number;
  coverMeterSystem: null;
  meterSystemAmount: number;
  payDayType: keyof typeof PayDayType;
  payDay: number;
  guaranteeType: null | GuaranteeTypeEnum;
  otherGuaranteeType: string;
  guaranteeMonths: number;
  cceeAgent: string;
  proposalId: string;
  economy: [{ year: number; amount: number }];
  trader: Trader;
  isFixedContractedVolume?: boolean;
  contractedVolumePerYear?: ContractedVolmePerYear[];
  fixedContractedVolume?: number;
  commissionModel?: CommissionModelType;
  fixedCommission?: number;
  energyContractCommissions?: EnergyContractCommission[];
  energyContractAttachments?: EnergyContractAttachment[];
  advanceCommission?: boolean;
  losts?: number;
  readjustmentIndex?: keyof typeof ReadjustmentIndexEnum;
  readjustmentFrequency?: keyof typeof ReadjustmentFrequencyEnum;
  readjustmentBaseDate?: string;
  includeCceeCharges: boolean;
}

export interface Supply {
  startDate: string;
  endDate: string;
  unit: {
    id: string;
    name: string;
  };
}

export interface Bid {
  payDay: number;
  status: number;
  coverCceeTaxes: boolean;
  coverMeterSystem: boolean;
  meterSystemAmount: null | number;
  retailService: boolean;
  trader: {
    id: string;
    name: string;
  };
  economy: {
    period: number;
    economyPerYear: {
      year: number;
      amount: number;
    }[];
  }[];
}

export interface Proposal {
  total: number;
  limit: number;
  id: string;
  group: {
    id: string;
    name: string;
  };
  contractType: keyof typeof ContractTypeEnum;
  guaranteeMonths: null | number;
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  otherGuarantee: null | string;
  lowerFlexibility: number;
  upperFlexibility: number;
  status: 1 | 2 | 5;
  round: number;
  bids: Bid[];
  supply: Supply[];
  createdAt: string;
}

export interface Groups {
  id: string;
  name: string;
  units: {
    id: string;
    name: string;
  }[];
}

export interface CreateEnergyContractPayload {
  groupId?: string;
  idCceeTrader?: number | null;
  lowerFlexibility: number | null;
  upperFlexibility: number | null;
  perfilCceeTrader?: string;
  cceeProfiles?: string[];
  id?: string;
  unitsId: string[];
  contractType: string | keyof typeof ContractTypeEnum;
  startSupplyDate: string;
  endSupplyDate: string;
  energyType: string | keyof typeof EnergyTypeEnum;
  coverCceeTaxes: string | boolean;
  coverCceeAmount: number | null;
  coverMeterSystem: string | boolean;
  meterSystemAmount: number | null;
  payDayType: keyof typeof PayDayType;
  payDay: number | null;
  guaranteeType: string | keyof typeof GuaranteeTypeEnum;
  otherGuaranteeType: string | null;
  guaranteeMonths: number | null;
  commissionModel?: CommissionModelType | string;
  advanceCommission: boolean;
  fixedCommission?: number;
  energyContractCommissions?: EnergyContractCommission[];
  cceeAgent: string | null;
  proposalId: string | null;
  economy: { year: number; amount: number | null }[];
  retailTraderId: string;
  traderType: string | keyof typeof TraderTypeEnum;
  isFixedContractedVolume?: boolean | string;
  fixedContractedVolume?: number | null;
  contractedVolumePerYear?: { year: number; amount: number | null }[] | null;
  energyContractAttachments?: EnergyContractAttachment[];
  losts: number | null;
  readjustmentBaseDate: null | string;
  readjustmentIndex: null | keyof typeof ReadjustmentIndexEnum;
  readjustmentFrequency: null | keyof typeof ReadjustmentFrequencyEnum;
  includeCceeCharges: boolean;
}

export enum CommissionModelType {
  VARIABLE = 'VARIABLE',
  FIXED = 'FIXED',
}

export interface EnergyContractCommission {
  id?: string;
  year: number;
  commissionType?: CommissionType;
  commissionAmount: number | null;
}

export interface EnergyContractAttachment {
  id: string;
  energyContractId: string;
  thirdPartyName: string;
  url: string;
  filename: string;
  fileSize: number;
  createdAt: string;
  updatedAt: string;
}

export enum CommissionType {
  MONETARY = 'MONETARY',
  PERCENTAGE = 'PERCENTAGE',
}

export interface DeleteEnergyContractPayload {
  id: string;
}

export const EMPTY_CONTRACT: CreateEnergyContractPayload = {
  idCceeTrader: NaN,
  lowerFlexibility: null,
  upperFlexibility: null,
  perfilCceeTrader: '',
  cceeProfiles: [],
  groupId: '',
  unitsId: [],
  contractType: '',
  startSupplyDate: '',
  endSupplyDate: '',
  energyType: '',
  coverCceeTaxes: '',
  coverCceeAmount: NaN,
  coverMeterSystem: '',
  meterSystemAmount: null,
  payDayType: PayDayType.WORKDAY,
  payDay: null,
  guaranteeType: '',
  otherGuaranteeType: null,
  guaranteeMonths: NaN,
  commissionModel: '',
  energyContractCommissions: [{ year: NaN, commissionAmount: null, commissionType: undefined }],
  cceeAgent: '',
  proposalId: null,
  economy: [{ year: NaN, amount: null }],
  retailTraderId: '',
  traderType: '',
  isFixedContractedVolume: '',
  fixedContractedVolume: null,
  contractedVolumePerYear: [{ year: NaN, amount: null }],
  advanceCommission: false,
  losts: null,
  readjustmentBaseDate: null,
  readjustmentIndex: null,
  readjustmentFrequency: null,
  includeCceeCharges: false,
};

export interface IEnergyContractAttachmentsPayload {
  energyContractAttachments: File[];
  energyContractId: string;
}

import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const UpsertUnitFormSkeleton: React.FC = () => (
  <div className="flex flex-col gap-12">
    <div className="grid grid-cols-4 gap-4">
      <div className="grid col-span-1">
        <Skeleton className="h-1/4" />
      </div>
      <div className="grid col-span-3 gap-4">
        <div className="grid grid-cols-3 gap-4">
          {Array(15)
            .fill({ w: '100%', h: '200%' })
            .map((skeleton, index) => (
              <Skeleton containerClassName="w-full mb-8" key={index} width={skeleton.w} height={skeleton.h} />
            ))}
        </div>
      </div>
    </div>
    <div className="grid grid-cols-4 gap-4">
      <div className="grid col-span-1">
        <Skeleton className="h-1/4" />
      </div>
      <div className="grid col-span-3 gap-4">
        <div className="grid grid-cols-3 gap-4">
          {Array(15)
            .fill({ w: '100%', h: '200%' })
            .map((skeleton, index) => (
              <Skeleton containerClassName="w-full mb-8" key={index} width={skeleton.w} height={skeleton.h} />
            ))}
        </div>
      </div>
    </div>
  </div>
);

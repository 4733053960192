import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';
import { IContractProduct, IContractStatus, IContracts } from '@pages/sales-contracts';
import { useAuth } from '@src/ApolloWrapper';
import config from '@config';
import { IManagementContract, IManagementContractContext } from './types';
import { parseContractPayload } from '@components/organisms/general/management-contract/parser';

export const ManagementContractContext = createContext({} as IManagementContractContext);

interface Provider {
  children: React.ReactNode;
}

const ManagementContractProvider: React.FC<Provider> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const salesApiClient = axios.create({
    baseURL: config.SALES_API_HOST,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    formSerializer: { metaTokens: false, indexes: null },
  });

  function updateManagementContract(contract: IManagementContract): AxiosPromise {
    return salesApiClient.post(`/api/v1/contract/${contract.id}`, parseContractPayload(contract));
  }

  function getAllManagementContracts(
    status: IContractStatus,
    page: number,
    product: IContractProduct,
  ): AxiosPromise<IContracts> {
    return salesApiClient.get('/api/v1/contract/', {
      params: {
        status: status,
        page: page,
        product: product,
      },
    });
  }

  function getManagementContractById(contractId: string): AxiosPromise<IManagementContract> {
    return salesApiClient.get(`/api/v1/contract/${contractId}`);
  }

  return (
    <ManagementContractContext.Provider
      value={{
        getAllManagementContracts,
        updateManagementContract,
        getManagementContractById,
      }}
    >
      {children}
    </ManagementContractContext.Provider>
  );
};

export default ManagementContractProvider;

import React, { useState, useEffect } from 'react';
import { Button, HeroIcon } from '@clarke-energia/foton';

import { ISendingBatchStatusType, sendingBatchStatusType } from '@pages/financial-events/static-data';
import {
  FinancialEventsBatch,
  FinancialEventsBatchGraphQLData,
  FinancialEventsBatchStatus,
} from '@contexts/financial-events';
import { FinancialEventsBatchManager } from '@contexts/financial-events/manager';

import { ApolloQueryResult } from '@apollo/client';

interface FinancialEventsBatchProcessingWaitProps {
  onSuccess: () => void;
  financialEventsBatch?: FinancialEventsBatch;
  financialEventsBatchId?: string;
  refetchGetFinancialEventsBatchHandler: () => Promise<ApolloQueryResult<any>>;
}

const FinancialEventsBatchProcessingWait: React.FC<FinancialEventsBatchProcessingWaitProps> = ({
  onSuccess,
  financialEventsBatch,
  financialEventsBatchId,
  refetchGetFinancialEventsBatchHandler,
}) => {
  const [sendingBatchStatus, setSendingBatchStatus] = useState<ISendingBatchStatusType>(sendingBatchStatusType[0]);

  useEffect(() => {
    financialEventsBatchId && pingForFinancialEventsBatchStatus();
  }, [financialEventsBatchId]);

  const pingForFinancialEventsBatchStatus = () => {
    setTimeout(() => {
      refetchGetFinancialEventsBatchHandler().then((response) => {
        const updatedBatch = parseGetFinancialEventsResponse(response);

        setProcessingPercentageProgressBar(updatedBatch);
        if (isFinancialEventsBatchProcessConcluded(updatedBatch)) {
          onSuccess();
        } else {
          pingForFinancialEventsBatchStatus();
        }
      });
    }, 300);
  };

  const setProcessingPercentageProgressBar = (financialEventsBatch: FinancialEventsBatch) => {
    sendingBatchStatusType.map(
      (item) => item.progress <= financialEventsBatch?.processingPercentage && setSendingBatchStatus(item),
    );
  };

  const isFinancialEventsBatchProcessConcluded = (financialEventsBatch: FinancialEventsBatch) => {
    if (
      financialEventsBatch?.status === FinancialEventsBatchStatus.UNPROCESSED ||
      financialEventsBatch?.status === FinancialEventsBatchStatus.PROCESSING ||
      Object.keys(financialEventsBatch)?.length === 0
    ) {
      return false;
    } else if (
      financialEventsBatch?.status === FinancialEventsBatchStatus.PROCESSING_FAILED ||
      financialEventsBatch?.status === FinancialEventsBatchStatus.PROCESSED ||
      financialEventsBatch?.status === FinancialEventsBatchStatus.PROCESSED_WITH_ERROR
    ) {
      return true;
    }
  };

  const parseGetFinancialEventsResponse = (response: ApolloQueryResult<any>) => {
    const manager = new FinancialEventsBatchManager(response.data as FinancialEventsBatchGraphQLData);
    return manager.financialEventsBatch;
  };
  const parsedFinancialEventsBatchProcessingPercentage = `${
    financialEventsBatch?.processingPercentage ? financialEventsBatch?.processingPercentage * 100 : 0
  }%`;

  return (
    <>
      <div className="flex flex-col gap-5 justify-between items-center h-4/5">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="flex justify-center items-center mb-5 w-8 h-8 rounded-medium bg-neutral-10">
            <HeroIcon icon={'TableCellsIcon'} extraClassNames={'w-5'} />
          </div>
          <div className="mb-5 h-4 rounded-full w-[10.5rem] bg-neutral-10">
            <div
              className="h-4 rounded-full bg-primary-60"
              style={{ width: parsedFinancialEventsBatchProcessingPercentage }}
            ></div>
          </div>
          <h2 className="font-bold text-heading-medium">{sendingBatchStatus.label}</h2>
          <span className="text-paragraph-medium">{sendingBatchStatus.description}</span>
        </div>
        <div>
          <Button
            label="Clique aqui em caso de instabilidades"
            kind="ghost"
            size="small"
            onClick={() => pingForFinancialEventsBatchStatus()}
          />
        </div>
      </div>
    </>
  );
};

export default FinancialEventsBatchProcessingWait;

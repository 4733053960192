import { TagProps } from '@clarke-energia/foton';
import { FinancialEventStatus } from '@contexts/financial-events';

export const FinancialEventStatusTagPropsSelector: Record<keyof typeof FinancialEventStatus, TagProps> = {
  UNPROCESSED: {
    color: 'gray',
    label: 'Não processado',
    kind: 'icon',
    icon: 'MinusCircleIcon',
  },
  PROCESSED: {
    color: 'green',
    label: 'Processado',
    kind: 'icon',
    icon: 'CheckIcon',
  },
  PROCESSING_FAILED: {
    color: 'red',
    label: 'Erro no processamento',
    kind: 'icon',
    icon: 'XMarkIcon',
  },
  NOT_SENT: {
    color: 'yellow',
    label: 'Email a enviar',
    kind: 'icon',
    icon: 'PencilSquareIcon',
  },
  SENDING_FAILED: {
    color: 'red',
    label: 'Erro no envio',
    kind: 'icon',
    icon: 'XMarkIcon',
  },
  SENT: {
    color: 'primary',
    label: 'Enviado',
    kind: 'icon',
    icon: 'InboxArrowDownIcon',
  },
};

export const columnStatusDescription =
  'Os eventos com status de erro precisarão ser realizados manualmente pois houve algum problema no processamento ou no email. Já os eventos que tiveram sucesso no processamento mas, ainda não foram enviados, é necessário clicar em ”enviar email” para realizar o disparo do evento financeiro.';

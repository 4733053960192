import { gql } from '@apollo/client';

export const GET_ALL_GROUP_ECONOMY_REPORTS = gql`
  query GetUnitsEconomyReportsByGroupId($groupId: ID!) {
    unitsEconomyReportsByGroupId(id: $groupId) {
      id
      date
      createdAt
      unit {
        name
        docNumber
        id
      }
      currentSavings
      savingsPercent
      groupId
      trader {
        id
      }
      devecDeclaration
      freeMarket {
        readDemandOffPeak
        readDemandPeak
        energyConsumptionPeak
        energyConsumptionOffPeak
        reactiveDemandOffPeak
        reactiveDemandPeak
        reactiveEnergyOffPeak
        reactiveEnergyPeak
        reactiveEnergyCost
        tusdDiscount
        delays
        pisCofins
        othersCosts
        energyProvidedInput
        distributionAdjustmentValue
        loadFactor

        energyDistribution {
          covidTariffCost
        }

        energyProvided {
          energyProvidedCost
          energyProvidedAdjustmentValue
        }

        others {
          clarkeManagementCost
          cceeCost
          eerCost
          ercapCost
          essCost
        }
      }
      conventionalMarket {
        others
        tariffFlagCost
      }
      status
      groupReport {
        createdAt
        date
        totalMonthlySavings
        totalMonthlySavingsPercent
        id
        status
        group {
          name
          id
        }
      }
    }
  }
`;

export const GET_GROUP_ECONOMY_REPORT_BY_ID = gql`
  query GroupEconomyReport($groupEconomyReportId: ID!) {
    groupEconomyReport(id: $groupEconomyReportId) {
      id
      group {
        name
      }
      economyReports {
        id
        trader {
          name
        }
        unit {
          name
          edc {
            name
          }
          contractedDemandOffPeak
        }
        energyTotalConsumption
        currentSavings
        energyTotalCost
        status
      }
      totalMonthlySavings
      totalMonthlySavingsPercent
      totalMonthlyEnergyCost
      totalMonthlyEnergyConsumption
      date
      status
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GroupById($groupId: ID!) {
    group(id: $groupId) {
      id
      name
      units {
        id
        name
        docNumber
        tariffModality
        tariffSubgroup
        contractedDemandOffPeak
        contractedDemandPeak
        contractedDemandOffPeakBeforeMigration
        contractedDemandPeakBeforeMigration
        unitType
        edc {
          id
          state
          taxes {
            percentageValue
            taxType
          }
        }
        clarkeContract {
          clarkeMagmentAmount
        }
        energyContract {
          trader {
            id
            name
            type
          }
          contractType
          includeCceeCharges
          economy {
            year
            amount
          }
        }
      }
    }
  }
`;

export const GET_CLARKE_CONTRACT_BY_GROUP_ID = gql`
  query GetClarkeContractById($groupId: ID!) {
    getClarkeContractByGroupId(groupId: $groupId) {
      clarkeMagmentAmount
    }
  }
`;

export const GET_ENERGY_CONTRACTS_BY_GROUP_ID = gql`
  query GetEnergyContractById($groupId: ID!) {
    energyContractsByGroupId(groupId: $groupId) {
      trader {
        id
        name
      }
    }
  }
`;

export const GET_ENERGY_AUDITS_BY_GROUP = gql`
  query GetEnergyAuditsByGroup($page: Int!, $groupId: ID, $status: EnergyAuditStatus, $date: String) {
    getAllEnergyAudits(page: $page, groupId: $groupId, status: $status, date: $date) {
      data {
        consumptionTotal
        units {
          unit {
            id
            cceeProfile {
              name
            }
          }
          consumptionAmount
        }
      }
    }
  }
`;
export const GET_LIQUIDATION_FEE_BY_GROUP = gql`
  query GetLiquidationFeeByGroup($input: GetFinancialEventsInput) {
    getFinancialEvents(input: $input) {
      data {
        liquidationFee
      }
    }
  }
`;
export const GET_CCEE_CONTRIBUTIONS_BY_GROUP = gql`
  query GetCCEEFeeByGroup($input: GetFinancialEventsInput) {
    getFinancialEvents(input: $input) {
      data {
        amountToPay
      }
    }
  }
`;
export const GET_EER_BY_GROUP = gql`
  query GetEerFeeByGroup($input: GetFinancialEventsInput) {
    getFinancialEvents(input: $input) {
      data {
        eerFeePerProfiles
      }
    }
  }
`;

export const GET_SUPPLY_INVOICE = gql`
  query GetSupplyInvoiceByDocNumberAndDate($date: String!, $docNumber: String!) {
    getSupplyInvoiceByDocNumberAndDate(date: $date, docNumber: $docNumber) {
      supplyAmountMwh
      price
      trader {
        docNumber
      }
    }
  }
`;
